// @ts-strict-ignore
/*
  워딩파일 주의사항
  1. 워딩파일은 상단의 해시값으로 깃처럼 버전관리를 하고 있습니다.
  2. 그래서 변경사항이 있어도 해시값을 바꾸지 않으면 다음 wdown/wup 때 변경사항이 사라집니다.
  3. 그러니 머지 컨플릭트가 생길 때에는 꼭 해당 브랜치의 해시값을(incoming) 적용해주세요.
*/
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-target-blank */
import { format } from 'date-fns'
import { Fragment } from 'react'
import { isMobile } from 'react-device-detect'
import DateComponent from '../../../../components/DateComponent'
import Div from '../../../../components/basic/Div'
import Span from '../../../../components/common/Span'
import urls from '../../../../modules/urls'
import { currencyFormat, financial, getMonthName } from '../../../../modules/utils'
import { DOLLARS_TO_KRW, RINGLE_DYNAMIC_LINK } from '../../../../modules/vars'
import LinkLocale from '../../LinkLocale'
export const s_landing_pricing = {
  makeup_lesson_title: [`당일수업권`, `Last-minute Credits`, 's_landing_pricing.makeup_lesson_title', ``, ``, ``, ``],
  times: [`회`, ``, 's_landing_pricing.times', ``, ``, ``, ``],
  ask_channeltalk: [`1:1 문의하기`, `Contact us`, 's_landing_pricing.ask_channeltalk', ``, ``, ``, ``],
  regular_lesson_title: [`정규수업권`, `Regular Credits`, 's_landing_pricing.regular_lesson_title', ``, ``, ``, ``],
  point: [
    `실제 결제한 수업권 금액의 3%를 적립해 드립니다. 적립된 수업 포인트는 결제 및 수강기간 연장에 사용할 수 있습니다. (매 수업 종료 7일 후 자동 적립 예정)`,
    `Additional points 3% of the purchased price can be awarded. Accumulated points can be used on purchasing lessons and extra validity period. (Lesson points are awarded 7 days after taking the lesson)`,
    's_landing_pricing.point',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_lesson_title: [`추가 수업`, `Extra Credits`, 's_landing_pricing.bonus_lesson_title', ``, ``, ``, ``],
  actual_payment_unit_price: [
    `실질 회당 단가`,
    `Final Price per Credit`,
    's_landing_pricing.actual_payment_unit_price',
    ``,
    ``,
    ``,
    ``,
  ],
  b2b_bonus_lesson_title: [
    `B2B 특별 추가 수업권`,
    `B2B Bonus Credits`,
    's_landing_pricing.b2b_bonus_lesson_title',
    ``,
    ``,
    ``,
    ``,
  ],
  time: [`회`, `Credit`, 's_landing_pricing.time', ``, ``, ``, ``],
}
export const s_pricing = {
  free: [
    <>
      언제 어디서나 <br />
      자유롭게 수강하세요
    </>,
    <>Learn whenever and wherever</>,
    's_pricing.free',
    ``,
    ``,
    ``,
    ``,
  ],
  credit: [`수강권`, `Credits`, 's_pricing.credit', ``, ``, ``, ``],
  credit_detail: [`수업권 상세`, `Credits Detail`, 's_pricing.credit_detail', ``, ``, ``, ``],
  time: [`운영시간`, `Hours of Operation`, 's_pricing.time', ``, ``, ``, ``],
  assure_title: [
    `링글이 보장합니다`,
    `We guarantee Ringle is worth your time`,
    's_pricing.assure_title',
    ``,
    ``,
    ``,
    ``,
  ],
  usePoint: [`보유 포인트`, `My points`, 's_pricing.usePoint', ``, ``, ``, ``],
  useAll: [`전부 사용`, `Use all`, 's_pricing.useAll', ``, ``, ``, ``],
  purchaseMethod: [`결제방식 선택`, ``, 's_pricing.purchaseMethod', ``, ``, ``, ``],
  domesticPayment: [`국내 카드 결제`, `Credit card`, 's_pricing.domesticPayment', ``, ``, ``, ``],
  internationalPayment: [`해외 카드 결제`, `Credit card`, 's_pricing.internationalPayment', ``, ``, ``, ``],
  normalPayment: [`카드 일반 결제`, `Credit card`, 's_pricing.normalPayment', ``, ``, ``, ``],
  directPayment: [`카드 직접 입력`, `Credit card (manual)`, 's_pricing.directPayment', ``, ``, ``, ``],
  kakaoPayment: [`카카오페이`, `KakaoPay`, 's_pricing.kakaoPayment', ``, ``, ``, ``],
  samsungPayment: [`삼성페이`, `SamsungPay`, 's_pricing.samsungPayment', ``, ``, ``, ``],
  paycoPayment: [`PAYCO`, `PAYCO`, 's_pricing.paycoPayment', ``, ``, ``, ``],
  dollarPayment: [`미국 달러로 결제`, `Pay in USD`, 's_pricing.dollarPayment', ``, ``, ``, ``],
  installmentPayment: [`분할 결제`, `Pay in installations`, 's_pricing.installmentPayment', ``, ``, ``, ``],
  paymentWithTwoCard: [`2개 카드 결제`, `Pay with two cards`, 's_pricing.paymentWithTwoCard', ``, ``, ``, ``],
  purchase: [`결제하기`, `Submit order`, 's_pricing.purchase', ``, ``, ``, ``],
  agreeValidateMessage: [
    `상품 구매 관련 환불 규정에 동의 후 결제를 진행할 수 있습니다.`,
    `Please check the confirmation box.`,
    's_pricing.agreeValidateMessage',
    ``,
    ``,
    ``,
    ``,
  ],
  selectPaymentType: [
    `결제 방식을 선택하지 않으셨습니다. 결제 방식을 선택해주세요`,
    `You haven't selected the purchase method. Please select your purchase method.`,
    's_pricing.selectPaymentType',
    ``,
    ``,
    ``,
    ``,
  ],
  first_benefit: {
    title: [
      <>
        첫 3회 수업권 구매하고, <br />
        <Div textPrimary clx="text-primary">
          포인트로 100% 돌려받으세요
        </Div>
      </>,
      <>
        <Div textPrimary clx="text-primary">
          100% Payback Offer
        </Div>{' '}
        for First Purchase Benefit
      </>,
      's_pricing.first_benefit.title',
      ``,
      ``,
      ``,
      ``,
    ],
    new_user_pay: (amount) => [
      <>
        <Div style={{ color: '#4e5b73' }} clx="h5 mb-0">
          첫 구매가
        </Div>
        <Div clx="ml-2 h3 mb-0 text-bold">{amount}</Div>
      </>,
      <>
        <Div style={{ color: '#4e5b73' }} clx="h5 mb-0">
          New usesr pay
        </Div>
        <Div clx="ml-2 h3 mb-0 text-bold">{amount}</Div>
      </>,
      's_pricing.first_benefit.new_user_pay',
      ``,
      ``,
      ``,
      ``,
    ],
    point_payback: (amount) => [
      <>첫 구매시 {amount} 포인트 즉시 지급!</>,
      <>${amount} given right away on your first purchase!</>,
      's_pricing.first_benefit.point_payback',
      ``,
      ``,
      ``,
      ``,
    ],
    purchase_now: [`구매하기`, `Purchase Now`, 's_pricing.first_benefit.purchase_now', ``, ``, ``, ``],
  },
  july_promotion: {
    badge: [<>얼리버드</>, <>Early Bird</>, 's_pricing.july_promotion.badge', ``, ``, ``, ``],
    sign_up_title: [
      <>
        하반기 프로모션
        <br />
        얼리버드
      </>,
      `2nd Half Promotion Early Bird`,
      's_pricing.july_promotion.sign_up_title',
      ``,
      ``,
      ``,
      ``,
    ],
    sign_up_subtitle: [
      <>
        최대{' '}
        <Div spanTag style={{ color: '#2A97FF' }}>
          50만원
        </Div>{' '}
        추가 할인
      </>,
      <>
        Additional discount up to{' '}
        <Div spanTag style={{ color: '#2A97FF' }}>
          $431.03
        </Div>
      </>,
      's_pricing.july_promotion.sign_up_subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  recommend: [`추천 수업권`, `Popular`, 's_pricing.recommend', ``, ``, ``, ``],
  ringle_guarantee: [`링글이 보장합니다`, `Ringle guarantees`, 's_pricing.ringle_guarantee', ``, ``, ``, ``],
  refund_all: [
    <Div textH3 textGray700>
      첫 수업 불만족 시<br />
      <Div textBlack spanTag fontBold textH2>
        100% 환불
      </Div>
    </Div>,
    <Div textH3 textGray700>
      <Div textBlack spanTag fontBold textH2>
        100% Money-back guarantee
      </Div>
    </Div>,
    's_pricing.refund_all',
    ``,
    ``,
    ``,
    ``,
  ],
  not_opened: [
    <Div textH3 textGray700>
      첫 수업 시작 전까지
      <br />
      <Div textBlack spanTag fontBold textH2>
        수강기간 미차감
      </Div>
    </Div>,
    <Div textH3 textGray700>
      <Div textBlack spanTag fontBold textH2>
        Start Whenever
      </Div>
    </Div>,
    's_pricing.not_opened',
    ``,
    ``,
    ``,
    ``,
  ],
  additional_extend: [
    `구매 1년 후 수강기간 자동 차감`,
    `Buy now and start later`,
    's_pricing.additional_extend',
    ``,
    ``,
    ``,
    ``,
  ],
  change_before_day: [
    <Div textH3 textGray700>
      수업 하루 전까지
      <br />
      <Div textBlack spanTag fontBold textH2>
        일정 변경 가능
      </Div>
    </Div>,
    <Div textBlack fontBold textH3>
      Flexible cancellation
    </Div>,
    's_pricing.change_before_day',
    ``,
    ``,
    ``,
    ``,
  ],
  provide_makeup_lesson: [
    `수업 시작 기준 2시간 이전까지 변경 시, 당일수업권 제공`,
    <>
      If you cancel 24 to 2 hours before the start of
      <br />
      the lesson, a Last-minute Credit will be issued.
    </>,
    's_pricing.provide_makeup_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  credit_info: [`수업권 선택 방법`, `Credits Detail`, 's_pricing.credit_info', ``, ``, ``, ``],
  duration: [`수업 길이`, `Lesson duration`, 's_pricing.duration', ``, ``, ``, ``],
  full_desc: [
    <Div ml16 textGray700 textH5>
      하나의 주제에 대해 심도 있게!
      <br />
      최신 시사부터 비즈니스 영어까지
      <br />
      지적 대화를 레벨업하는 40분 수업
    </Div>,
    <Div ml16 textGray700 textH5>
      <br />
      Best for advanced discussions
      <br />
    </Div>,
    's_pricing.full_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  half_desc: [
    <Div ml16 textGray700 textH5>
      바쁜 일상 속, 짧지만 확실하게!
      <br />
      부담스러운 예습 시간은 줄이고
      <br />
      일상 대화 능력을 향상시키고 싶으신 분
    </Div>,
    <Div ml16 textGray700 textH5>
      <br />
      Best for casual conversations
    </Div>,
    's_pricing.half_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  time_info: [`시간대`, `Times`, 's_pricing.time_info', ``, ``, ``, ``],
  base: [`수업 시작 기준`, `Lesson starting time`, 's_pricing.base', ``, ``, ``, ``],
  all_time: [`정규`, `Regular`, 's_pricing.all_time', ``, ``, ``, ``],
  early: [`새벽반`, `Early Bird`, 's_pricing.early', ``, ``, ``, ``],
  lunch: [`점심반`, `Matinee`, 's_pricing.lunch', ``, ``, ``, ``],
  night_owl: [`심야반`, `Night Owl`, 's_pricing.night_owl', ``, ``, ``, ``],
  for_purpose: [`수강 목적에 꼭 맞는`, `Select the right package for you`, 's_pricing.for_purpose', ``, ``, ``, ``],
  choose_your_credit: [<>&nbsp;수업권을 선택해보세요</>, ``, 's_pricing.choose_your_credit', ``, ``, ``, ``],
  pay_in_pieces: [`2개 카드 분할 결제 가능`, `Split payment available`, 's_pricing.pay_in_pieces', ``, ``, ``, ``],
  interest_free: [
    `최대 7개월 무이자 할부`,
    `Interest-free for up to 7 monthly installments`,
    's_pricing.interest_free',
    ``,
    ``,
    ``,
    ``,
  ],
  certificates: [`증명서 발급`, `Lesson Certificates & Receipts`, 's_pricing.certificates', ``, ``, ``, ``],
  cert_example: [`증명서 예시 상세보기`, `See sample`, 's_pricing.cert_example', ``, ``, ``, ``],
  can_provide_cert: [
    <>
      구매한 수업권 증명서 상시 발급 가능합니다.
      <br />
      수강증, 출석증, 영수증 (국/영문)
    </>,
    `You can issue lesson certificates, proof of attendance, or receipts.`,
    's_pricing.can_provide_cert',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit: [`수강 혜택`, `Benefits`, 's_pricing.benefit', ``, ``, ``, ``],
  benefit_detail: [
    <Div flex mt4 textGray700 textH5 whitespacePre trackingTighter flexCol sm="flex-row">
      <Div col>
        <Div flex mb4>
          1,000+종의 링글&nbsp;
          <Div fontSemibold textBlack>
            오리지널 콘텐츠
          </Div>
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            PDF 파일 / MP3 오디오북&nbsp;
          </Div>
          다운로드 지원
        </Div>
        <Div flex mb4>
          매주 진행되는&nbsp;
          <Div fontSemibold textBlack>
            튜터의 Live Class
          </Div>
        </Div>
        <Div flex>
          커리어, 영어학습 등&nbsp;
          <Div fontSemibold textBlack>
            380+편 VOD
          </Div>
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          수업 24시간 전까지&nbsp;
          <Div fontSemibold textBlack>
            무제한 일정 변경&nbsp;
          </Div>
          가능
        </Div>
        <Div flex mb4>
          2시간 전 수업 취소 시,&nbsp;
          <Div fontSemibold textBlack>
            100% 보충 수업&nbsp;
          </Div>
          제공
        </Div>
        <Div flex mb4>
          모든&nbsp;
          <Div fontSemibold textBlack>
            수업 녹음파일 무제한&nbsp;
          </Div>
          듣기
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            개별 첨삭 수업노트&nbsp;
          </Div>
          (Ringle docs) 별도 제공
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            튜터의 1:1 피드백 리포트&nbsp;
          </Div>
          매 수업 종료 후 제공
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            AI 기반 대화 스크립트와 말하기 패턴 분석 자료
          </Div>
        </Div>
        <Div flex>
          말하기, 듣기, 읽기, 쓰기&nbsp;
          <Div fontSemibold textBlack>
            영역별 학습 데이터
          </Div>
        </Div>
      </Div>
    </Div>,
    <Div flex mt4 textGray700 textH5 whitespacePre trackingTighter flexCol sm="flex-row">
      <Div col>
        <Div flex mb4>
          1,000+ Ringle's&nbsp;
          <Div fontSemibold textBlack>
            Original Content
          </Div>
        </Div>
        <Div flex mb4>
          Download&nbsp;
          <Div fontSemibold textBlack>
            PDF/MP3 Audiobook&
          </Div>
        </Div>
        <Div flex mb4>
          Weekly top tutor&nbsp;
          <Div fontSemibold textBlack>
            LIVE Webinar
          </Div>
        </Div>
        <Div flex>
          380+&nbsp;
          <Div fontSemibold textBlack>
            Video Courses
          </Div>
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Flexible cancellations&nbsp;
          </Div>
          up to 24hrs before the lesson
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Last-minute lessons&nbsp;
          </Div>
          for late cancellations
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Audio replay & transcript&nbsp;
          </Div>
          for all lessons
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            Lesson Notes&nbsp;
          </Div>
          after class
        </Div>
      </Div>
      <Div col>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            Tutor's Feedback&nbsp;
          </Div>
          report
        </Div>
        <Div flex mb4>
          <Div fontSemibold textBlack>
            AI Analysis&nbsp;
          </Div>
          on speech patterns
        </Div>
        <Div flex>
          <Div fontSemibold textBlack>
            Activities log&nbsp;
          </Div>
          on your Ringling
        </Div>
      </Div>
    </Div>,
    's_pricing.benefit_detail',
    ``,
    ``,
    ``,
    ``,
  ],
  notice: [`유의사항`, `Terms`, 's_pricing.notice', ``, ``, ``, ``],
  use_credit: [`수업권 사용 안내`, `Credits policy`, 's_pricing.use_credit', ``, ``, ``, ``],
  free_to_use: [
    `수업권은 구매한 횟수만큼, 수강기간 내 자유롭게 이용 가능합니다.`,
    `You can use your lesson credits before they expire.`,
    's_pricing.free_to_use',
    ``,
    ``,
    ``,
    ``,
  ],
  free_to_use_ex: [
    `(예: 수강기간 내, 하루 3회 연속 수업 신청도 가능합니다.)`,
    ``,
    's_pricing.free_to_use_ex',
    ``,
    ``,
    ``,
    ``,
  ],
  twenty_four_seven: [
    `수업 시간은 연중무휴로 한국 시간 기준 새벽 5시 ~ 오후 2시, 저녁 7시 ~ 새벽 3시까지 입니다.`,
    `Lessons are available 5AM - 2PM / 7PM - 3AM (KST) every day.`,
    's_pricing.twenty_four_seven',
    ``,
    ``,
    ``,
    ``,
  ],
  start_after_first_lesson: [
    `구매 후, 첫 수업 완료 전까지 수강기간은 시작되지 않습니다.`,
    `Expiration dates are calculated from the date you complete your first lesson using the purchased package.`,
    's_pricing.start_after_first_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  start_after_first_lesson_ex: [
    `(예: 구매 후 3개월이 지난 후 첫 수업을 완료하면, 해당 시점부터 수강기간이 시작됩니다.)`,
    ``,
    's_pricing.start_after_first_lesson_ex',
    ``,
    ``,
    ``,
    ``,
  ],
  extend_by_payment: [
    `수강기간은 포인트 사용/소액 결제를 통해 연장할 수 있습니다.`,
    `You can extend credit expiration dates with your points or by paying a small fee.`,
    's_pricing.extend_by_payment',
    ``,
    ``,
    ``,
    ``,
  ],
  refund_policy: [`수업권 환불 정책`, `Refund policy`, 's_pricing.refund_policy', ``, ``, ``, ``],
  refund_all_before_open: [
    `구매 후 사용하지 않은 수업권은 전액 환불 가능합니다.`,
    `Unused lesson credits are eligible for refunds.`,
    's_pricing.refund_all_before_open',
    ``,
    ``,
    ``,
    ``,
  ],
  refund_after_trial: [
    `체험 수업 포함 첫 수업 이후, 불만족 시 전액 환불 가능합니다.`,
    `We offer 100% money-back guarantee for your first lesson.`,
    's_pricing.refund_after_trial',
    ``,
    ``,
    ``,
    ``,
  ],
  check_policy: [
    `수강 이력이 있는 수업권은, 환불 시 이용약관(13조, 15조)에 의거하여 처리합니다.`,
    `For refunds on used credits, please refer to articles 13 and 15 of your terms of service.`,
    's_pricing.check_policy',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus: (bonus) => [`+${bonus}회 수업`, `+${bonus} Lessons`, 's_pricing.bonus', ``, ``, ``, ``],
  if_finish_all: (date, credit) => [
    `${date}일 내 ${credit}회 완강 시`,
    `After ${credit} lessons in ${date} days`,
    's_pricing.if_finish_all',
    ``,
    ``,
    ``,
    ``,
  ],
  free_subsidy: (free) => [
    `+${free}회 당일수업권`,
    `+${free} Last-minute Credits`,
    's_pricing.free_subsidy',
    ``,
    ``,
    ``,
    ``,
  ],
  count: (credit) => [`${credit}회 수업권`, `${credit} Credits`, 's_pricing.count', ``, ``, ``, ``],
  min: (duration) => [`${duration}분`, `${duration} min`, 's_pricing.min', ``, ``, ``, ``],
  day: (day) => [`${day}일`, `${day} days`, 's_pricing.day', ``, ``, ``, ``],
  by_one: [`/ 회`, `/ Credit`, 's_pricing.by_one', ``, ``, ``, ``],
  normal_price: [`정가`, `Regular price`, 's_pricing.normal_price', ``, ``, ``, ``],
  expected_point: [`예상 적립 포인트`, `Estimated points`, 's_pricing.expected_point', ``, ``, ``, ``],
  without_trial: [`체험없이 구매`, `Skip trial`, 's_pricing.without_trial', ``, ``, ``, ``],
  additional_lessons_and_discount: (count, discount) => [
    `${count ? `${count}회 수업 추가` : ''}${discount ? ` + ${discount}만원 할인` : ''}`,
    `${count ? `${count} Free lesson(s)` : ''}${discount ? ` + ${discount} Off` : ''}`,
    's_pricing.additional_lessons_and_discount',
    ``,
    ``,
    ``,
    ``,
  ],
  buy: [`구매하기`, `Purchase`, 's_pricing.buy', ``, ``, ``, ``],
  including_trial: [
    `체험 수업도 첫 수업에 포함됨`,
    `Includes trial lesson.`,
    's_pricing.including_trial',
    ``,
    ``,
    ``,
    ``,
  ],
  time_limit: [`시간한정`, `Limited Window`, 's_pricing.time_limit', ``, ``, ``, ``],
  final_price: [`프로모션 특가`, `Promotional Deal`, 's_pricing.final_price', ``, ``, ``, ``],
  final_credit: [`최종 수업권 횟수`, `Total lessons`, 's_pricing.final_credit', ``, ``, ``, ``],
  additional_lesson: [`추가 정규수업`, `Bonus lessons`, 's_pricing.additional_lesson', ``, ``, ``, ``],
  additional_benefit: (duration, name) => [
    `[${duration}분] ${name}회 수업권의 추가 혜택입니다.`,
    `Benefits for [${duration} min] ${name} Lessons Package`,
    's_pricing.additional_benefit',
    ``,
    ``,
    ``,
    ``,
  ],
  provide_additional_lesson: [
    `해당 수업권 구매 시 증정되는 추가 혜택입니다.`,
    `This is an additional benefit that comes with the purchase.`,
    's_pricing.provide_additional_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  about_additional_lesson: [
    `정규 수업권 구매 후 일괄로 지급될 예정입니다.`,
    `The additional benefit will be given after purchase.`,
    's_pricing.about_additional_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  considered_to_be_used: [
    `* 수강생의 수업 불참/2시간 이내 취소도 수업권 [사용]으로 인정됩니다.`,
    `*Student no-show and cancellations in less than 2 hours also count as completing a lesson.`,
    's_pricing.considered_to_be_used',
    ``,
    ``,
    ``,
    ``,
  ],
  for_long: [`장기 수업권 혜택`, `Size Benefits`, 's_pricing.for_long', ``, ``, ``, ``],
  long_benefit_list_1: [<>당일수업권</>, `Last-minute Credits`, 's_pricing.long_benefit_list_1', ``, ``, ``, ``],
  about_lesson_in_a_day: [
    <>
      매 정규수업권 4회 소진 시마다, 당일수업권 1회를 제공합니다. (지급일 기준 1년동안 사용 가능) 당일수업권은 예약 시점
      기준 24시간 이내에 시작하는 당일수업만 예약할 수 있는 수업권입니다.
    </>,
    `For every four completed lessons, one Last-minute Credit will be given. (1 year of validity period) Last-minute Credits can be used for lessons starting within 24 hours from the time of reservation.`,
    's_pricing.about_lesson_in_a_day',
    ``,
    ``,
    ``,
    ``,
  ],
  long_benefit_list_2: [`튜터 우선 예약`, `Early Booking Access`, 's_pricing.long_benefit_list_2', ``, ``, ``, ``],
  schedule_first_benefit: [
    `튜터의 1주일 뒤 스케쥴을 24시간 동안 우선 예약할 수 있는 권한을 드립니다.`,
    `You'll have 24-hour priority access to your favorite tutor's schedule on a weekly basis.`,
    's_pricing.schedule_first_benefit',
    ``,
    ``,
    ``,
    ``,
  ],
  long_benefit_list_3: [`80단어 답변 첨삭`, `Response proofreading`, 's_pricing.long_benefit_list_3', ``, ``, ``, ``],
  editing_service: [
    <>
      튜터가 매 수업 80단어를 수업 전 첨삭해 드립니다. 수업 24시간 전까지 예습 페이지에서 신청 가능합니다. [예습] {'>'}{' '}
      [답변] {'>'} [첨삭 신청]
    </>,
    `Have your tutor proofread your response up to 80 words. You can submit requests up to 24 hours before the lesson at Lesson Prep.`,
    's_pricing.editing_service',
    ``,
    ``,
    ``,
    ``,
  ],
  input_company_code: [
    `기업 맞춤 수업권을 확인해 보세요.`,
    `Please enter B2B Partner code.`,
    's_pricing.input_company_code',
    ``,
    ``,
    ``,
    ``,
  ],
  company_code: [`기업 맞춤 코드를 입력해 주세요.`, `B2B Partner Code`, 's_pricing.company_code', ``, ``, ``, ``],
  channel_talk: [`링글 기업 맞춤 제휴 1:1 문의하기`, `Contact Us`, 's_pricing.channel_talk', ``, ``, ``, ``],
  additional_discount: [`추가할인`, `Bonus Discount`, 's_pricing.additional_discount', ``, ``, ``, ``],
  recommended_product: [`추천 수업권`, `Popular`, 's_pricing.recommended_product', ``, ``, ``, ``],
  applied_price: [
    `첫구매 혜택이 반영된 가격입니다.`,
    `First purchase benefits applied`,
    's_pricing.applied_price',
    ``,
    ``,
    ``,
    ``,
  ],
  normal_customer: [`일반 수업권`, `Individuals`, 's_pricing.normal_customer', ``, ``, ``, ``],
  b2b_price: [`기업 맞춤 수업권`, `B2B`, 's_pricing.b2b_price', ``, ``, ``, ``],
  alltime_time: [
    `수업시간: 오전 5시~오후 2시 / 오후 19시~새벽 3시 (KST)`,
    `Lesson time: 5AM - 2PM / 7PM - 3AM (KST)`,
    's_pricing.alltime_time',
    ``,
    ``,
    ``,
    ``,
  ],
  early_time: [`오전 5시~ 7시(KST)`, `5AM - 7AM (KST)`, 's_pricing.early_time', ``, ``, ``, ``],
  lunch_time: [`오전 11시 ~ 오후 1시(KST)`, `11AM - 1PM (KST)`, 's_pricing.lunch_time', ``, ``, ``, ``],
  night_time: [`자정 00시 ~ 새벽 2시(KST)`, `12AM - 2AM (KST)`, 's_pricing.night_time', ``, ``, ``, ``],
  certi: [`증명서류`, `Certificate`, 's_pricing.certi', ``, ``, ``, ``],
  certi_example_desc: [
    `링글 증명서류 예시입니다. 1개월 이상 수강권의 경우 매월 분할 발급이 가능합니다.`,
    `Sample certificate. Can be issued monthly.`,
    's_pricing.certi_example_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  attendance_ex: [
    `- 출석증 증명서류 예시 -`,
    `- Sample Proof of Attendance -`,
    's_pricing.attendance_ex',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_certi_ex: [
    `- 수강증 증명서류 예시 -`,
    `- Sample Lesson Certificate -`,
    's_pricing.lesson_certi_ex',
    ``,
    ``,
    ``,
    ``,
  ],
  what_is_additional_lesson: [
    `당일수업이란?`,
    `What are [Last-minute Lessons]?`,
    's_pricing.what_is_additional_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  free_subsidy_description: [
    <>
      {' '}
      <Div>
        <Div fontBold>당일수업이란?</Div>
        <Div textGray700>
          - 예약 시점 기준 24시간 이내에 시작하는 수업
          <br />
          - 타 수강생이 수업을 취소할 경우에 형성
          <br />- &#91;1:1 수업&#93; - &#91;예약&#93; - &#91;당일수업&#93;에서 수업 예약 가능{' '}
        </Div>
        <br />
      </Div>
    </>,
    <>
      {' '}
      <Div>
        <Div fontBold>What are [Last-minute lessons]?</Div>
        <Div textGray700>
          - Lessons starting within 24 hours in real-time.
          <br />
          - For every four completed lessons, one Last-minute Credit will be given.
          <br />- Can be reserved at [1:1 Lessons] - [Reservation] - [Last-minute lessons]
        </Div>
      </Div>
      <Div mt16>
        <Div fontBold>What counts as used lesson credits?</Div>
        <Div textGray700>
          - Completed lessons - Canceled lessons - No-show lessons all count as using lesson credits
        </Div>
      </Div>
    </>,
    's_pricing.free_subsidy_description',
    ``,
    ``,
    ``,
    ``,
  ],
  refund_fee: [
    `환불시 은행, 카드사, Paypal 등의 결제 수단에 대한 수수료는 차감하고 환불될 수 있습니다.`,
    `In the case of refunds, any transaction fees incurred will be deducted from the refund amount.`,
    's_pricing.refund_fee',
    ``,
    ``,
    ``,
    ``,
  ],
  regular_price: (price, number) => [
    `(1회당 ${price} X ${number}회)`,
    `(${number} per Credit X ${number} lessons)`,
    's_pricing.regular_price',
    ``,
    ``,
    ``,
    ``,
  ],
  first_purchase_benefit: [`첫 구매 혜택`, `First Purchase`, 's_pricing.first_purchase_benefit', ``, ``, ``, ``],
  buy_btn: [`구매하기`, `Purchase`, 's_pricing.buy_btn', ``, ``, ``, ``],
  switch_to_won: [`원화 결제하기`, `Convert to KRW`, 's_pricing.switch_to_won', ``, ``, ``, ``],
  switch_to_other: [`달러 결제하기`, `Convert to Local Currency`, 's_pricing.switch_to_other', ``, ``, ``, ``],
  buy_credit: [`수업권 구매`, `Purchase`, 's_pricing.buy_credit', ``, ``, ``, ``],
  tab: {
    purchase: [`수업권`, `Purchase`, 's_pricing.tab.purchase', ``, ``, ``, ``],
    method: [`선택방법`, `Credits Detail`, 's_pricing.tab.method', ``, ``, ``, ``],
    guarantee: [`링글 보장제도`, `Guarantee Policy`, 's_pricing.tab.guarantee', ``, ``, ``, ``],
    benefit: [`수강 혜택`, `Benefits`, 's_pricing.tab.benefit', ``, ``, ``, ``],
    certificate: [`증명 서류`, `Certificate`, 's_pricing.tab.certificate', ``, ``, ``, ``],
    precaution: [`FAQ`, `FAQ`, 's_pricing.tab.precaution', ``, ``, ``, ``],
    guarantee_and_benefit: [
      `보장제도 & 혜택`,
      `Guarantee Policy`,
      's_pricing.tab.guarantee_and_benefit',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  credit_info_description: [
    `수업 진행 방식과 콘텐츠는 동일합니다`,
    `Lesson format and available materials are the same`,
    's_pricing.credit_info_description',
    ``,
    ``,
    ``,
    ``,
  ],
  guarantee: {
    desc1: [
      `첫 구매 혜택도 첫 수업에 포함됨`,
      `Bonus or free lessons may count as a first lesson`,
      's_pricing.guarantee.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  benefit_list_1: {
    title1: [`오리지널 콘텐츠`, `Original Content`, 's_pricing.benefit_list_1.title1', ``, ``, ``, ``],
    desc1: [`1,000+종의 링글`, `1,000+ Ringle's`, 's_pricing.benefit_list_1.desc1', ``, ``, ``, ``],
    title2: [`튜터의 Live Class`, `LIVE Webinar`, 's_pricing.benefit_list_1.title2', ``, ``, ``, ``],
    desc2: [`매주 진행되는`, `Weekly top tutor`, 's_pricing.benefit_list_1.desc2', ``, ``, ``, ``],
    title3: [`380+편 VOD`, `380+ Video Courses`, 's_pricing.benefit_list_1.title3', ``, ``, ``, ``],
    desc3: [`커리어, 영어학습 등`, `Career & other topical`, 's_pricing.benefit_list_1.desc3', ``, ``, ``, ``],
    caption1: [
      `PDF 파일 / MP3 오디오북 다운로드 지원`,
      `PDF/MP3 Audiobook provided`,
      's_pricing.benefit_list_1.caption1',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      `매일 업데이트 되는 최신 콘텐츠`,
      `New learning content updated daily`,
      's_pricing.benefit_list_1.title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  benefit_list_2: {
    title1: [`말하기 패턴 분석 자료`, `Speech pattern analysis`, 's_pricing.benefit_list_2.title1', ``, ``, ``, ``],
    desc1: [`AI 기반 분석 시스템`, `AI Review system's`, 's_pricing.benefit_list_2.desc1', ``, ``, ``, ``],
    title2: [`개별 첨삭 수업노트`, `Real-time lesson notes`, 's_pricing.benefit_list_2.title2', ``, ``, ``, ``],
    desc2: [`별도 제공`, `See after lesson`, 's_pricing.benefit_list_2.desc2', ``, ``, ``, ``],
    title3: [`튜터의 1:1 피드백 리포트`, `Tutor's Feedback report`, 's_pricing.benefit_list_2.title3', ``, ``, ``, ``],
    desc3: [`매 수업 종료 후 제공`, `Thorough and personalized`, 's_pricing.benefit_list_2.desc3', ``, ``, ``, ``],
    caption1: [
      `모든 수업 녹음파일 무제한 듣기`,
      `Exclusive Lesson Materials`,
      's_pricing.benefit_list_2.caption1',
      ``,
      ``,
      ``,
      ``,
    ],
    caption2: [
      `말하기, 듣기, 읽기, 쓰기 영역별 학습 데이터`,
      `Detailed feedback on vocabulary, fluency, grammar, and pronunciation`,
      's_pricing.benefit_list_2.caption2',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      `AI가 기록하는 복습 시스템`,
      `AI-supported review system`,
      's_pricing.benefit_list_2.title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn: {
    detail: [`상세보기`, `See details`, 's_pricing.btn.detail', ``, ``, ``, ``],
  },
  additional_lessons: (count) => [
    <>
      {count ? (
        <>
          <Div spanTag cond={[count >= 2 && 'text-primary']}>
            {count}회
          </Div>{' '}
          수업 추가
        </>
      ) : (
        ''
      )}
    </>,
    <>
      {count ? (
        <>
          <Div spanTag cond={[count >= 2 && 'text-primary']}>
            {count}
          </Div>{' '}
          Free lesson(s)
        </>
      ) : (
        ''
      )}
    </>,
    's_pricing.additional_lessons',
    ``,
    ``,
    ``,
    ``,
  ],
  additional_discounts: (discount) => [
    <>{discount ? `${discount}만원 할인` : ''}</>,
    <>{discount ? `${discount} Off` : ''}</>,
    's_pricing.additional_discounts',
    ``,
    ``,
    ``,
    ``,
  ],
  long_package: {
    benefit1: (count) => [
      <>{count ? `${count}회 수업 추가` : ''}</>,
      <>{count ? `${count} Free lesson(s)` : ''}</>,
      's_pricing.long_package.benefit1',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit2: (discount) => [
      <>{discount ? `${discount}만원 할인` : ''}</>,
      <>{discount ? `${discount} Off` : ''}</>,
      's_pricing.long_package.benefit2',
      ``,
      ``,
      ``,
      ``,
    ],
    free_subsidy: (free) => [
      `${free}회 당일수업권 제공`,
      `${free} Last-minute Credits`,
      's_pricing.long_package.free_subsidy',
      ``,
      ``,
      ``,
      ``,
    ],
    additional: [`장기권 추가 혜택`, `Size Benefit`, 's_pricing.long_package.additional', ``, ``, ``, ``],
    benefit: [`혜택`, `Benefits`, 's_pricing.long_package.benefit', ``, ``, ``, ``],
    benefit_1: {
      desc: [
        <>
          매 정규수업권 4회 소진 시마다, 당일수업권 1회를 제공합니다. (지급일 기준 1년동안 사용 가능)
          <br />
          당일수업권은 예약 시점 기준 24시간 이내에 시작하는 [당일수업]을 예약할 수 있습니다.
        </>,
        `For every four completed lessons, one Last-minute Credit will be given. (1 year of validity period) Last-minute Credits can be used for lessons starting within 24 hours from the time of reservation.`,
        's_pricing.long_package.benefit_1.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  actual_payment_unit_price_desc: [
    `정규수업권과 당일수업권을 포함한 총 수업 횟수를 합산하여 최종 결제 금액으로 나눈 단가입니다.`,
    `Final price divided by total credits (includes Last-minute Credit(s))`,
    's_pricing.actual_payment_unit_price_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  expected_point_desc: [
    `실제 결제한 전체 수업권 금액의 3%를 적립해 드립니다. (매 수업 종료 7일 후 적립 예정)`,
    `3% of paid amount will be awarded as points (within 7 days of purchase)`,
    's_pricing.expected_point_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  not_opened_1: [
    `첫 수업 시작 전까지`,
    `Lesson days are frozen until first lesson`,
    's_pricing.not_opened_1',
    ``,
    ``,
    ``,
    ``,
  ],
  not_opened_2: [`수강기간 미차감`, ``, 's_pricing.not_opened_2', ``, ``, ``, ``],
  refund_all_1: [
    `첫 수업 불만족 시`,
    <>
      100% Money-back
      <br />
      guarantee
    </>,
    's_pricing.refund_all_1',
    ``,
    ``,
    ``,
    ``,
  ],
  refund_all_2: [`100% 환불`, ``, 's_pricing.refund_all_2', ``, ``, ``, ``],
  change_before_day_1: [
    `수업 하루 전까지`,
    <>
      Change schedule up to 24 hours
      <br />
      before the lesson
    </>,
    's_pricing.change_before_day_1',
    ``,
    ``,
    ``,
    ``,
  ],
  change_before_day_2: [`일정 변경 가능`, ``, 's_pricing.change_before_day_2', ``, ``, ``, ``],
  hero: {
    benefit1: [
      `링글 첫 구매 수강생에게 드리는 혜택`,
      `First Purchase Benefits for New Ringlers`,
      's_pricing.hero.benefit1',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit2: (point) => [
      `최대 3회 수업 추가 & 1만원 할인`,
      <>
        Up to 3 extra credits{isMobile && <br />} & {point} off
      </>,
      's_pricing.hero.benefit2',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit3: [
      <>
        첫 1회 수업
        <br />
        불만족 시, 100% 환불
      </>,
      <>
        100% money-back guarantee
        <br />
        for your first lesson
      </>,
      's_pricing.hero.benefit3',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit4: [
      <>
        첫 수업 시작 전<br />
        수강기간 미차감
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      's_pricing.hero.benefit4',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit5: [
      <>
        수업 하루 전까지
        <br />
        스케줄 변경 가능
      </>,
      <>
        Reschedule up to
        <br />
        24hrs before the lesson
      </>,
      's_pricing.hero.benefit5',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit6: [
      <>
        매 수업 완료 시<br />
        링글 포인트 적립
      </>,
      <>
        Ringle points awarded
        <br />
        after each lesson
      </>,
      's_pricing.hero.benefit6',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit7: [
      <>
        검증된 퀄리티의 수업을
        <br />
        합리적인 가격으로
      </>,
      <>
        Verified premium lessons
        <br />
        at a reasonable price
      </>,
      's_pricing.hero.benefit7',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit8: [
      `2022년 영어는 제대로 시작하세요!`,
      `Learn English the right way in 2022!`,
      's_pricing.hero.benefit8',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit9: (point) => [
      <>
        친구초대 성공 시 <b>{point}포인트 제공</b>
      </>,
      <>Invite your friends and earn {point} </>,
      's_pricing.hero.benefit9',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit10: [
      <>
        수업권 구매 후 <b>원하는 시점 시작 가능</b>
      </>,
      `Buy now and start later`,
      's_pricing.hero.benefit10',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit11: [
      <>
        수업이 끝나면 <b>3% 포인트 적립</b>
      </>,
      `Get 3% points of the purchased price after each lesson`,
      's_pricing.hero.benefit11',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      `오직 한 달간 진행되는 최대 할인`,
      `Maximum Discount for One Month`,
      's_pricing.hero.title',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle1: [
      <>
        할인부터 영어 공부법까지 챙기는{' '}
        <Div spanTag style={{ fontWeight: 500 }}>
          링글 하반기 프로모션
        </Div>
      </>,
      <>
        Save with{' '}
        <Div spanTag style={{ fontWeight: 500 }}>
          Ringle's biannual promotion
        </Div>{' '}
        and perfect your English
      </>,
      's_pricing.hero.subtitle1',
      ``,
      ``,
      ``,
      ``,
    ],
    date: [
      `22.06.07 (Tue) - 07.07 (Thu)`,
      `June 07, 2022 (Tue) - July 07, 2022 (Thu)`,
      's_pricing.hero.date',
      ``,
      ``,
      ``,
      ``,
    ],
    date_mobile: [`~07.07 (목)`, `~July 07, 2022 (Thu)`, 's_pricing.hero.date_mobile', ``, ``, ``, ``],
    top: [`1년에 단 2번`, `Only Twice a Year`, 's_pricing.hero.top', ``, ``, ``, ``],
    label_new1: [`오직 단 한번!`, `One-time`, 's_pricing.hero.label_new1', ``, ``, ``, ``],
    label_new2: [`신규회원 첫 구매 이벤트`, `Newcomer Event`, 's_pricing.hero.label_new2', ``, ``, ``, ``],
    before_login: [
      <>
        지금 링글 가입하면
        <br />
        <span className="text-[#FF6712]">최대 20만원 </span> 즉시 할인!
      </>,
      <>
        Sign up now and
        <br />
        Get up to <span className="text-[#FF6712]">$153 off!</span>
      </>,
      's_pricing.hero.before_login',
      ``,
      ``,
      ``,
      ``,
    ],
    welcome: [
      <>
        최대 20만원 할인
        <br />
        <span className="text-[#FF6712]">혜택이 곧 사라져요.</span>
      </>,
      <>
        <span className="text-[#FF6712]">Discounts up to $153</span>
        <br />
        are waiting for you
      </>,
      's_pricing.hero.welcome',
      ``,
      ``,
      ``,
      ``,
    ],
    label_old1: [`잊지 않으셨죠?`, `Don't forget`, 's_pricing.hero.label_old1', ``, ``, ``, ``],
    label_old2: [`고객님께만 드린 혜택`, `to get our special offer`, 's_pricing.hero.label_old2', ``, ``, ``, ``],
    next: [
      <>
        최대 10만원 할인
        <br />
        혜택이 <span className="text-[#FF6712]">곧 사라져요.</span>
      </>,
      <>
        <span className="text-[#FF6712]">Discounts up to $77</span>
        <br />
        are waiting for you
      </>,
      's_pricing.hero.next',
      ``,
      ``,
      ``,
      ``,
    ],
    label_challenge1: [`이벤트 진행 중`, `Join Now!`, 's_pricing.hero.label_challenge1', ``, ``, ``, ``],
    label_challenge2: [
      `보스턴&뉴욕 챌린지`,
      `Boston & NYC Challenge`,
      's_pricing.hero.label_challenge2',
      ``,
      ``,
      ``,
      ``,
    ],
    challenge: [
      <>
        <span className="text-[#FF6712]">특별한 여행이</span> 기다립니다.
        <br />
        지금 링글을 시작하세요.
      </>,
      <>
        A special trip <span className="text-[#FF6712]">awaits you.</span>
        <br />
        Start ringling now!
      </>,
      's_pricing.hero.challenge',
      ``,
      ``,
      ``,
      ``,
    ],
    countdown_day: [`일 후 종료`, `Day(s) left`, 's_pricing.hero.countdown_day', ``, ``, ``, ``],
    countdown: [`할인 혜택 종료까지`, `Special offer expires in`, 's_pricing.hero.countdown', ``, ``, ``, ``],
  },
  no_split_payment: [`1년 수업권 결제`, `No split payments`, 's_pricing.no_split_payment', ``, ``, ``, ``],
  no_split_payment_desc: [
    `[카드 일반결제/실시간 계좌이체/가상계좌 발급]만 지원되고 있습니다. 분할 결제 또한 가능하지 않습니다.`,
    `Only [Full Credit/Debit card payments/Wire Transfer/Virtual Account] are accepted for year-long packages`,
    's_pricing.no_split_payment_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  welcome_coupon: {
    label: [
      `3만원 웰컴쿠폰이 적용되어 있습니다`,
      `$25 OFF WELCOME COUPON applied`,
      's_pricing.welcome_coupon.label',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  starter_coupon: {
    label: [
      `6만원 쿠폰팩이 적용되어 있어요`,
      `Starter Coupon Pack applied`,
      's_pricing.starter_coupon.label',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  repurchase_coupon: {
    label: [
      `6만원 쿠폰팩이 적용되어 있어요`,
      `Next Purchase Coupon Pack applied`,
      's_pricing.repurchase_coupon.label',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  popup: {
    starter_coupon: {
      title_1: [
        `링글 첫 시작 한정`,
        `For first-purchasers only`,
        's_pricing.popup.starter_coupon.title_1',
        ``,
        ``,
        ``,
        ``,
      ],
      title_2: [`스타터 쿠폰팩 발급`, `Starter Coupon Pack`, 's_pricing.popup.starter_coupon.title_2', ``, ``, ``, ``],
      footer: [
        <>
          *쿠폰 발급 후 35일 이내 사용 가능합니다.
          <br />
          *20만원 이상 정규수업권 구매 시 적용 가능한 쿠폰입니다.
        </>,
        <>
          * Valid for 35 days after issuance.
          <br />* Only redeemable toward regular packages worth $169 or more.
        </>,
        's_pricing.popup.starter_coupon.footer',
        ``,
        ``,
        ``,
        ``,
      ],
      btn_1: [`한 달간 보지않기`, `Remind me in a month`, 's_pricing.popup.starter_coupon.btn_1', ``, ``, ``, ``],
      btn_2: [`확인`, `Close`, 's_pricing.popup.starter_coupon.btn_2', ``, ``, ``, ``],
    },
    repurchase_coupon: {
      title_1: [`기존 회원 한정`, `Next Purchase`, 's_pricing.popup.repurchase_coupon.title_1', ``, ``, ``, ``],
      title_2: [`재구매 쿠폰 발급 안내`, `Coupon Pack`, 's_pricing.popup.repurchase_coupon.title_2', ``, ``, ``, ``],
      footer: [
        <>* 쿠폰은 수업권 금액에 따라 차등 적용됩니다.</>,
        <>* Discounts vary according to credit price.</>,
        's_pricing.popup.repurchase_coupon.footer',
        ``,
        ``,
        ``,
        ``,
      ],
      btn_1: [`일주일간 보지않기`, `Remind me in a week`, 's_pricing.popup.repurchase_coupon.btn_1', ``, ``, ``, ``],
      btn_2: [`확인`, `Close`, 's_pricing.popup.repurchase_coupon.btn_2', ``, ``, ``, ``],
    },
  },
  card_promo: [`프로모션 한정 판매`, `2022 Promo Only`, 's_pricing.card_promo', ``, ``, ``, ``],
  card_popular: [`인기 수업권`, `Most Popular`, 's_pricing.card_popular', ``, ``, ``, ``],
  promo_price: [`연중 최대 할인가`, `Best Deal All Year`, 's_pricing.promo_price', ``, ``, ``, ``],
  package_price: [`패키지 할인`, `Package discount`, 's_pricing.package_price', ``, ``, ``, ``],
  benefit_list1: (number) => [
    <>무료 수업권 {number}회 추가 증정</>,
    <>
      {number} extra regular {number > 1 ? 'credits' : 'credit'}{' '}
    </>,
    's_pricing.benefit_list1',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit_list2: (price) => [<>{price} 추가 할인</>, <>Extra {price} off</>, 's_pricing.benefit_list2', ``, ``, ``, ``],
  benefit_list3: (number) => [
    <>당일수업권 {number}회 추가 증정</>,
    <>{number} extra last-minute credits</>,
    's_pricing.benefit_list3',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit_list4: [`튜터 우선 예약`, `Early Booking Access`, 's_pricing.benefit_list4', ``, ``, ``, ``],
  benefit_list5: (number) => [
    <>{number}회 당일수업권 제공</>,
    <>{number} Last-minute Credits</>,
    's_pricing.benefit_list5',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_detail: [`자세히 보기`, `See details`, 's_pricing.btn_detail', ``, ``, ``, ``],
  qna: {
    title: [`자주 묻는 질문`, `Frequently Asked Questions`, 's_pricing.qna.title', ``, ``, ``, ``],
    list1: {
      main: [
        `어떤 수업권을 선택하면 될까요?`,
        `Which credit type should I buy?`,
        's_pricing.qna.list1.main',
        ``,
        ``,
        ``,
        ``,
      ],
      desc1: [
        `20분/40분 수업권은 수업 길이 차이만 있고 수업 진행 방식, 교재와 튜터 선택지는 모두 같습니다.`,
        `20 min and 40 min credits are only different in terms of time, everything else such as materials, tutors and lesson format are the same.`,
        's_pricing.qna.list1.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `20분 수업은 비교적 가벼운 주제로 꾸준한 영어 말하기 습관을 만드는데 추천하는 수업입니다.`,
        `20 minute lessons are recommended for those who wish to build English speaking habits on relatively lighter topics.`,
        's_pricing.qna.list1.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `40분 수업은 특정 주제에 대한 깊이 있는 대화, 인터뷰/Writing 첨삭 등에 추천하는 수업입니다.`,
        `40 minute lessons are recommended for in-depth conversations on a specific topic or for editing interviews/writing.`,
        's_pricing.qna.list1.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
      desc4: [
        `특히 영어 초·중급자의 경우 최소 3개월 이상 주 1-2회 수업을 수강했을 때 영어 실력이 향상됩니다. 20분/40분 24회 이상 수업권으로 시작하시는 것을 추천합니다.`,
        `For beginners/intermediates, we recommend credits more than 24 lessons. We suggest taking 1-2 lessons per week for at least 3 months to improve English proficiency.`,
        's_pricing.qna.list1.desc4',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    list2: {
      main: [
        `수업을 예약할 수 있는 시간/횟수가 정해져 있나요?`,
        `Is there a limit on the time/number of times I can book a lesson?`,
        's_pricing.qna.list2.main',
        ``,
        ``,
        ``,
        ``,
      ],
      desc1: [
        `링글 수업권은 횟수제로 제공되며, 구매하신 수업권 횟수만큼 수강기간 내 자유롭게 수강할 수 있습니다. 일 별 또는 주 별로 수강할 수 있는 수업 횟수에 제한이 없습니다.`,
        `You can take as many lessons as the number of 1:1 credits purchased before their expiration date. There is no limit to the number of lessons you can take per day or per week.`,
        's_pricing.qna.list2.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `정규수업은 예약 시점 기준 24시간 이후에 시작하는 수업으로, 튜터와 교재를 자유롭게 선택 가능합니다.`,
        `Regular lessons are lessons that start 24 hours after the time of reservation, and you can freely choose a tutor and materials.`,
        's_pricing.qna.list2.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `당일수업은 예약 시점 기준 24시간 이내에 시작하는 수업으로, 튜터와 교재를 자유롭게 선택 가능합니다.`,
        `Last-minute credits can be used to book lessons that start within 24 hours. You can choose any tutor or material for last-minute lessons.`,
        's_pricing.qna.list2.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
      desc4: [
        `[정규수업권]은 정규수업 및 당일수업 예약에 사용할 수 있습니다. (정규 시간 : 연중무휴 KST 기준 오전 5시 ~ 오후 14시, 저녁 19시 ~ 새벽 3시)`,
        `[Regular Credits] can be used freely during regular hours and can also be used for last-minute lessons. (Regular Hours: Open all year round, 5 am to 2 pm KST, 7 pm to 3 am KST)`,
        's_pricing.qna.list2.desc4',
        ``,
        ``,
        ``,
        ``,
      ],
      desc5: [
        `[시간한정수업권]은 정해진 시간대에 사용 가능한 수업권입니다. (새벽반: 오전 5시 ~ 오전 8시(KST), 심야반: 자정 00시 ~ 새벽 3시(KST))`,
        `[Limited Window Credits] are lesson credits that can be used during limited hours. (Morning hours: 5 am to 8 am (KST), Late night hours: midnight to 3 am (KST))`,
        's_pricing.qna.list2.desc5',
        ``,
        ``,
        ``,
        ``,
      ],
      desc6: [
        `[당일수업권]이란 예약 시점 기준 24시간 이내에 시작하는 당일수업을 예약할 수 있는 수업권으로,  [1:1 수업] > [예약] > [예약 가능한 당일수업]에서 사용하실 수 있습니다.`,
        `[Last-minute Credits] are lesson credits that can only be used to reserve [Last-minute Lessons] starting within 24 hours from the time of reservation, and can be reserved at [1:1 Lessons] > [Book] > [Last-minute Lessons].`,
        's_pricing.qna.list2.desc6',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    list3: {
      main: [`수업권은 어떻게 구매하나요?`, `How can I purchase credits?`, 's_pricing.qna.list3.main', ``, ``, ``, ``],
      desc1: [
        `카드 일반 결제, 실시간 계좌 이체, 가상 계좌 발급, 2개 카드 분할 결제, 카카오/네이버/삼성 페이 등의 결제 방식을 지원합니다. (월 단위 정기 결제 형식은 지원하지 않습니다.)`,
        `If you are using Korean payment, you can purchase credits through the following payment methods: credit card (split payment with two credit cards possible), real-time payment, bank transfer to a virtual account, and mobile payment services such as Kakao Pay, Naver Pay, and Samsung Pay. Please note that we do not offer a monthly subscription service.`,
        's_pricing.qna.list3.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `해외 결제의 경우 카드 결제, Paypal 결제가 가능하며 모두 미국 달러로 결제됩니다.`,
        `If you are not in Korea, you can purchase credits using a credit card or via Paypal, and all payments will be processed in US dollars.`,
        's_pricing.qna.list3.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    list4: {
      main: [
        `수업권의 수강기간이 궁금해요.`,
        `When do Lesson Credits expire?`,
        's_pricing.qna.list4.main',
        ``,
        ``,
        ``,
        ``,
      ],
      desc1: [
        `수강기간은 첫 수업을 완료하는 시점부터 차감됩니다. (수업권 구매 후 1년 내 원하는 시점에 수업 시작 가능)`,
        `Expiration date countdown will begin after the first lesson is completed.`,
        's_pricing.qna.list4.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `단, 수업권 구매 후 1년이 되는 시점까지 수업을 시작하지 않는 경우에는 수강기간이 자동 차감됩니다.`,
        `However, if you do not take a lesson within 1 year after your purchase, the credits will automatically expire. Therefore, lessons may begin at anytime within 1 year of your purchase.`,
        's_pricing.qna.list4.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `수강기간의 기준은 수업을 예약하는 시점이 아닌 수강 완료하는 시점입니다.`,
        `Expiration dates are calculated as of the time your first lesson is completed, not reserved.`,
        's_pricing.qna.list4.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
      desc4: [
        `수강기간 연장은 [프로필] > [수업권/증명서류] > [수강기간 연장]에서 포인트 사용 또는 소액 결제를 통해 가능합니다.`,
        `You can extend the expiration date at [Profile] > [Credits] > [Extend expiration dates] by using points or paying an extension fee.`,
        's_pricing.qna.list4.desc4',
        ``,
        ``,
        ``,
        ``,
      ],
      desc5: [
        `실결제가 20만원 이상의 수업권을 재구매하시면, 보유한 수업권의 처음 수강기간을 추가로 연장할 수 있는 [수강기간 연장 쿠폰]이 발급됩니다.`,
        `You will be issued an [Extension Coupon] for extending the lesson days on your existing credits upon repurchasing a credit package worth ₩200,000 or more.`,
        's_pricing.qna.list4.desc5',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    list5: {
      main: [
        `수업 취소 정책이 궁금해요.`,
        `What is the cancellation policy?`,
        's_pricing.qna.list5.main',
        ``,
        ``,
        ``,
        ``,
      ],
      desc1: [
        `수업 시작 기준 24시간 이전에 취소 시, 해당 수업권은 자동 복구됩니다.`,
        `If you cancel 24 hours before the start of the lesson, the credit will be automatically restored.`,
        's_pricing.qna.list5.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `수업 시작 기준 24~2시간 이전 취소 시, 해당 수업권 차감 후 [당일수업권]이 지급됩니다.`,
        `If you cancel 24 to 2 hours before the start of the lesson, the credit used to reserve the lesson will be deducted and a [Last-minute Credit] will be issued.`,
        's_pricing.qna.list5.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `수업 시작 전 2시간 이내 취소 시, 해당 수업권은 차감되며 [당일수업권]은 지급되지 않습니다.`,
        `If you cancel within 2 hours before the lesson starts, the credit used to reserve the lesson will be deducted and a [Last-minute Credit] will not be issued.`,
        's_pricing.qna.list5.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    list6: {
      main: [`수업권 환불 정책이 궁금해요.`, `How can I get a refund?`, 's_pricing.qna.list6.main', ``, ``, ``, ``],
      desc1: [
        `첫 수업 불만족 시 100% 환불을 보장합니다.`,
        `Credit packages are fully refundable before their lesson days have started to diminish if they have not been used. Full refunds are also available if you are unsatisfied with our service after your first lesson, including lessons taken using free credits and trial lessons.`,
        's_pricing.qna.list6.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `중도 환불 신청 시 잔여 수업권은 위약금 없이 100% 환불 가능합니다.`,
        <>
          Credit packages with less than half of remaining in lesson days or lessons are not refundable. Any other
          credit packages that have been used may be eligible for partial refunds based on{' '}
          <a href="https://www.ringleplus.com/ko/student/policy/terms" className="text-primary" target="_blank">
            Articles 13 and 15 of Ringle's Terms of Service.
          </a>
        </>,
        's_pricing.qna.list6.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `만료된 수업권 환불이 불가하나, 만료 전 기간 연장 요청 시 추가 기간 연장을 지원합니다.`,
        `Credits for which lesson days were extended or certificate(s) were issued are not refundable.`,
        's_pricing.qna.list6.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
      desc4: [
        `환불 금액 산정 시, 은행, 카드사, Paypal 등의 결제 수단에 대한 수수료가 차감될 수 있습니다.`,
        `In the case of refunds, any transaction fees incurred from your bank, credit card or Paypal will be deducted from the refund amount.`,
        's_pricing.qna.list6.desc4',
        ``,
        ``,
        ``,
        ``,
      ],
      desc5: [
        <>
          그 외 사용 이력이 있는 수업권은{' '}
          <a href="https://www.ringleplus.com/ko/student/policy/terms" className="text-primary" target="_blank">
            이용약관 13조 및 15조
          </a>
          에 의거하여 환불 처리합니다.{' '}
        </>,
        <>
          Used credits will be refunded according to{' '}
          <a href="https://www.ringleplus.com/ko/student/policy/terms" className="text-primary" target="_blank">
            articles 13 and 15 of your terms of service.
          </a>
        </>,
        's_pricing.qna.list6.desc5',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    list7: {
      main: [
        `수업 증명서를 발급 받을 수 있나요?`,
        `Can I get lesson certificates?`,
        's_pricing.qna.list7.main',
        ``,
        ``,
        ``,
        ``,
      ],
      desc1: [
        <>
          증명서 발급은{' '}
          <a className="text-primary" target="_blank" href="https://www.ringleplus.com/ko/student/portal/mypage/credit">
            [프로필] {'>'} [수업권/증명서류] {'>'} [증명서 발급]
          </a>
          에서 수강증, 출석증, 또는 영수증을 직접 발급하실 수 있습니다.{' '}
        </>,
        `To get certificates, go to [Profile] > [Credits] > [Issue Certificate] to directly issue a lesson certificate, attendance certificate, or receipt. (Link https://www.ringleplus.com/ko/student/portal/mypage/credit)`,
        's_pricing.qna.list7.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `수강증 및 출석증은 월별로 나누어 발급하실 수 있습니다.`,
        `Lesson certificates and attendance certificates can be downloaded on a monthly basis.`,
        's_pricing.qna.list7.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `증명서는 실제 결제한 수업권 및 정상 진행된 수업에 대해서만 발급하실 수 있습니다.`,
        `Certificates will only be issued for purchased credits and regular lessons.`,
        's_pricing.qna.list7.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
      text_btn: [
        `증명서류 예시 상세보기`,
        `See detailed preview of certificate`,
        's_pricing.qna.list7.text_btn',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  see_detail: {
    credit_info: [`수업권 정보`, `Credits detail`, 's_pricing.see_detail.credit_info', ``, ``, ``, ``],
    total_credits: [`최종 수업권 횟수`, `Total credits`, 's_pricing.see_detail.total_credits', ``, ``, ``, ``],
    regular: [`정규수업권`, `Regular Credits`, 's_pricing.see_detail.regular', ``, ``, ``, ``],
    sameday: [`당일수업권`, `Last-minute Credits`, 's_pricing.see_detail.sameday', ``, ``, ``, ``],
    credit_price: [`가격 상세 내역`, `Price detail`, 's_pricing.see_detail.credit_price', ``, ``, ``, ``],
    normal_price: [`정가`, `Regular price`, 's_pricing.see_detail.normal_price', ``, ``, ``, ``],
    discount_price: [`총 할인 금액`, `Total discount`, 's_pricing.see_detail.discount_price', ``, ``, ``, ``],
    discount_promo: [`프로모션 할인`, `Promo discount`, 's_pricing.see_detail.discount_promo', ``, ``, ``, ``],
    discount_secret: [`시크릿 쿠폰 할인`, `Secret offer`, 's_pricing.see_detail.discount_secret', ``, ``, ``, ``],
    discount_welcome: [`웰컴 쿠폰 할인`, `Welcome coupon`, 's_pricing.see_detail.discount_welcome', ``, ``, ``, ``],
    final_price: [`최종 결제 금액`, `Your total`, 's_pricing.see_detail.final_price', ``, ``, ``, ``],
    unit_price: [`실질 회당 단가`, `Final price per credit`, 's_pricing.see_detail.unit_price', ``, ``, ``, ``],
    my_points: [`사용 가능 포인트`, `My points`, 's_pricing.see_detail.my_points', ``, ``, ``, ``],
    discount_package: [`패키지 할인`, `Package discount`, 's_pricing.see_detail.discount_package', ``, ``, ``, ``],
    discount_first: [`첫 구매 혜택`, `First purchase benefit`, 's_pricing.see_detail.discount_first', ``, ``, ``, ``],
    discount_re: [`재구매 쿠폰 할인`, `Next purchase coupon`, 's_pricing.see_detail.discount_re', ``, ``, ``, ``],
  },
  label_first: [`첫 구매`, `First`, 's_pricing.label_first', ``, ``, ``, ``],
  label_size: [`장기권`, `Size`, 's_pricing.label_size', ``, ``, ``, ``],
  total_sale_rate: [`총 할인율`, `Total discount`, 's_pricing.total_sale_rate', ``, ``, ``, ``],
  by_month: [`월`, `mo`, 's_pricing.by_month', ``, ``, ``, ``],
  promo_best_price: [
    `프로모션 최대 할인 적용`,
    `Biggest discount appplied`,
    's_pricing.promo_best_price',
    ``,
    ``,
    ``,
    ``,
  ],
  welcome_best_price: [`웰컴 쿠폰 최대 혜택`, `Welcome Coupon`, 's_pricing.welcome_best_price', ``, ``, ``, ``],
  first_price: [`첫 구매 혜택`, `First Purchase`, 's_pricing.first_price', ``, ``, ``, ``],
  next_best_price: [`재구매 쿠폰 혜택`, `Next Purchase Coupon`, 's_pricing.next_best_price', ``, ``, ``, ``],
  total_best_price: [`최대 혜택 적용가`, `Final dicounted price`, 's_pricing.total_best_price', ``, ``, ``, ``],
  card_recommended: [`추천 수업권`, `Recommended`, 's_pricing.card_recommended', ``, ``, ``, ``],
  season_best_price: [`8월･9월 한정 수업권`, `Seasonal offer`, 's_pricing.season_best_price', ``, ``, ``, ``],
  label_season: [`기간한정`, `Season`, 's_pricing.label_season', ``, ``, ``, ``],
  card_size: [`장기권 혜택`, `Size Benefits`, 's_pricing.card_size', ``, ``, ``, ``],
  season_discount: [`8월･9월 한정 특가 할인`, `Seasonal offer`, 's_pricing.season_discount', ``, ``, ``, ``],
  notice_usd: [
    `결제는 미국 달러로 진행됩니다. 괄호안의 통화별 금액은 당일 환율이 반영됩니다.`,
    <>
      Payments will be processed in USD.
      <br />
      The price shown in your local currency reflects today's exchange rate.
    </>,
    's_pricing.notice_usd',
    ``,
    ``,
    ``,
    ``,
  ],
  notice_exchange: [
    `실결제 금액은 카드사 적용 환율 및 수수료에 따라 차이가 발생할 수 있습니다.`,
    <>
      The final transaction price may differ due to the currency exchange rate
      <br />
      and processing fee applied by your credit card company.
    </>,
    's_pricing.notice_exchange',
    ``,
    ``,
    ``,
    ``,
  ],
  newbie_best_price: [`신규회원 추가 수업권 혜택`, `For Newcomers`, 's_pricing.newbie_best_price', ``, ``, ``, ``],
  label_newbie: [`신규회원`, `Newcomer`, 's_pricing.label_newbie', ``, ``, ``, ``],
  benefit_newbie: [`0만원 추가 할인 적용`, `Additional ₩0 off`, 's_pricing.benefit_newbie', ``, ``, ``, ``],
  label_trial_event: [`체험 수업 이벤트`, `Special Trial Lesson`, 's_pricing.label_trial_event', ``, ``, ``, ``],
  newbie_discount: [`체험 특가 할인`, `Trial Discount`, 's_pricing.newbie_discount', ``, ``, ``, ``],
  label_trial: [`체험수업`, `Trial`, 's_pricing.label_trial', ``, ``, ``, ``],
  benefit_trial: [`1회 수업권 95% 할인`, `95% discount`, 's_pricing.benefit_trial', ``, ``, ``, ``],
  total_discount: [`총 할인율`, `Total discount`, 's_pricing.total_discount', ``, ``, ``, ``],
  welcome_new_comer_best_price: [
    `신규 회원 최대 혜택`,
    `Newcomer Offer`,
    's_pricing.welcome_new_comer_best_price',
    ``,
    ``,
    ``,
    ``,
  ],
  season_best_price_2: [`10월･11월 한정 판매`, `Seasonal offer`, 's_pricing.season_best_price_2', ``, ``, ``, ``],
  season_discount_2: [`10월･11월 한정 특가 할인`, `Seasonal offer`, 's_pricing.season_discount_2', ``, ``, ``, ``],
  note_usd: {
    purchase_now: [
      `최종 결제는 미국 달러로 결제됩니다.`,
      `Payments will be processed in USD.`,
      's_pricing.note_usd.purchase_now',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  label_secret: [`시크릿쿠폰`, `Secret`, 's_pricing.label_secret', ``, ``, ``, ``],
  secret: [`시크릿 쿠폰 할인`, `Secret Offer`, 's_pricing.secret', ``, ``, ``, ``],
  benefit_list_secret: (amount) => [
    `${amount} 추가 할인 적용`,
    `Extra ${amount} off`,
    's_pricing.benefit_list_secret',
    ``,
    ``,
    ``,
    ``,
  ],
  input_company_code_verified: [
    `등록한 기업 맞춤 수업권을 확인해 보세요.`,
    `Check out your B2B Partner credits.`,
    's_pricing.input_company_code_verified',
    ``,
    ``,
    ``,
    ``,
  ],
  verify_code: [`인증`, `Verify`, 's_pricing.verify_code', ``, ``, ``, ``],
  sameday_benefit: [`당일수업권 혜택`, `Last-minute Credits`, 's_pricing.sameday_benefit', ``, ``, ``, ``],
  promo_23_1st: {
    earlybirdbanner: [
      `지금 보고 있는 얼리버드 혜택이 곧 사라져요!`,
      `The Early Bird Offer will disappear soon!`,
      's_pricing.promo_23_1st.earlybirdbanner',
      ``,
      ``,
      ``,
      ``,
    ],
    newcomerbanner: [
      `지금 적용된 신규회원 혜택,  nn:nn:nn 뒤에 사라져요!`,
      `Your Newcomer Discount will disappear in nn:nn:nn!`,
      's_pricing.promo_23_1st.newcomerbanner',
      ``,
      ``,
      ``,
      ``,
    ],
    datechip_early: [`얼리버드 혜택`, `Early Bird`, 's_pricing.promo_23_1st.datechip_early', ``, ``, ``, ``],
    datechip_regular: [
      `신년 프로모션`,
      `New Year Promotion`,
      's_pricing.promo_23_1st.datechip_regular',
      ``,
      ``,
      ``,
      ``,
    ],
    date_early: [
      `22.12.23 (금) - 23.02.02 (목)`,
      `Dec 23, 2022 (Fri) - Jan 5, 2023 (Thu)`,
      's_pricing.promo_23_1st.date_early',
      ``,
      ``,
      ``,
      ``,
    ],
    date_regular: [
      `23.01.06 (금) - 23.02.02 (목)`,
      `Jan 6, 2023 (Fri) - Feb 2, 2023 (Thu)`,
      's_pricing.promo_23_1st.date_regular',
      ``,
      ``,
      ``,
      ``,
    ],
    herotitle: [
      `가장 저렴하게 시작하는 23년 영어 정복`,
      `Study English at the Lowest Prices`,
      's_pricing.promo_23_1st.herotitle',
      ``,
      ``,
      ``,
      ``,
    ],
    herosubtitle: [
      `더 이상 영어 공부를 미룰 수 없는 당신을 위해 링글이 준비했어요.`,
      `For you to get a jump-start on studying English`,
      's_pricing.promo_23_1st.herosubtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    herocard1_subtext: [
      `프로모션 한정 할인`,
      `New Year Promotion Only`,
      's_pricing.promo_23_1st.herocard1_subtext',
      ``,
      ``,
      ``,
      ``,
    ],
    herocard1_text: [`최대 38% 할인`, `Up to 38% off`, 's_pricing.promo_23_1st.herocard1_text', ``, ``, ``, ``],
    n_herocard2_subtext: [
      `3시간 한정 신규회원 혜택`,
      `For Newcomers Only`,
      's_pricing.promo_23_1st.n_herocard2_subtext',
      ``,
      ``,
      ``,
      ``,
    ],
    n_herocard2_text: [`수업권 추가 증정`, `Bonus Credits`, 's_pricing.promo_23_1st.n_herocard2_text', ``, ``, ``, ``],
    herocard2_subtext: [
      `보스턴&뉴욕 챌린지 혜택`,
      `Boston & NYC Challenge`,
      's_pricing.promo_23_1st.herocard2_subtext',
      ``,
      ``,
      ``,
      ``,
    ],
    herocard2_text: [`수업권 추가 증정`, `Bonus Credits`, 's_pricing.promo_23_1st.herocard2_text', ``, ``, ``, ``],
    herocard3_subtext: [
      `프로모션 구매자 한정`,
      `For purchasers only`,
      's_pricing.promo_23_1st.herocard3_subtext',
      ``,
      ``,
      ``,
      ``,
    ],
    herocard3_text: [`스터디 클럽`, `Join Study Club`, 's_pricing.promo_23_1st.herocard3_text', ``, ``, ``, ``],
    n_herorow1: [
      <>
        첫 2회 수업
        <br />
        불만족 시 100% 환불
      </>,
      <>
        100% money-back guarantee
        <br />
        if unsatisfied after 2 lessons
      </>,
      's_pricing.promo_23_1st.n_herorow1',
      ``,
      ``,
      ``,
      ``,
    ],
    herorow1: [
      <>
        수업권 구매 시<br />
        수강기간 연장 쿠폰 증정
      </>,
      <>
        Special coupon
        <br />
        for extending lesson days
      </>,
      's_pricing.promo_23_1st.herorow1',
      ``,
      ``,
      ``,
      ``,
    ],
    herorow2: [
      <>
        첫 수업 시작 전<br />
        수강 기간 미차감
      </>,
      <>
        Expiration begins
        <br />
        after the first lesson
      </>,
      's_pricing.promo_23_1st.herorow2',
      ``,
      ``,
      ``,
      ``,
    ],
    herorow3: [
      <>
        수업 하루 전까지
        <br />
        스케줄 변경 가능
      </>,
      <>
        Reschedule anytime
        <br />
        up to 24 hours before the lesson
      </>,
      's_pricing.promo_23_1st.herorow3',
      ``,
      ``,
      ``,
      ``,
    ],
    pricecard_n_chip: [
      `시간 한정 신규회원 혜택`,
      `Bonus Credits for Newcomers`,
      's_pricing.promo_23_1st.pricecard_n_chip',
      ``,
      ``,
      ``,
      ``,
    ],
    pricecard_chip: [`당일수업권 혜택`, `Last-minute Credits`, 's_pricing.promo_23_1st.pricecard_chip', ``, ``, ``, ``],
    cta_early: [`얼리버드 프로모션 마감`, `Early Bird Promotion`, 's_pricing.promo_23_1st.cta_early', ``, ``, ``, ``],
    cta_regular: [`정규 프로모션 마감`, `New Year Promotion`, 's_pricing.promo_23_1st.cta_regular', ``, ``, ``, ``],
    mo_herocard1_text: [`최대 38% 할인`, `Up to 38% off`, 's_pricing.promo_23_1st.mo_herocard1_text', ``, ``, ``, ``],
    mo_n_herocard2_subtext: [
      `신규회원 혜택`,
      `For Newcomers Only`,
      's_pricing.promo_23_1st.mo_n_herocard2_subtext',
      ``,
      ``,
      ``,
      ``,
    ],
    datechip_regular_mW: [`신년 프로모션`, `Promotion`, 's_pricing.promo_23_1st.datechip_regular_mW', ``, ``, ``, ``],
    herotitle_mW: [
      <>
        가장 저렴하게 시작하는
        <br />
        23년 영어 정복
      </>,
      <>
        Study English
        <br />
        at the Lowest Prices
      </>,
      's_pricing.promo_23_1st.herotitle_mW',
      ``,
      ``,
      ``,
      ``,
    ],
    herosubtitle_mW: [
      <>
        더 이상 영어 공부를 미룰 수 없는 당신을 위해
        <br />
        링글이 준비했어요.
      </>,
      `For you to get a jump-start on studying English`,
      's_pricing.promo_23_1st.herosubtitle_mW',
      ``,
      ``,
      ``,
      ``,
    ],
    main_logout1: [
      `최대 25만원 추가 할인에`,
      `Up to $192 Extra Off`,
      's_pricing.promo_23_1st.main_logout1',
      ``,
      ``,
      ``,
      ``,
    ],
    main_logout2: [
      <>
        신규회원 한정
        <br />
        수업권 증정 혜택까지
      </>,
      <>
        plus bonus credits
        <br />
        for newcomers
      </>,
      's_pricing.promo_23_1st.main_logout2',
      ``,
      ``,
      ``,
      ``,
    ],
    main_countdown1: [
      `신년 프로모션 종료까지`,
      `Promotion Ends in`,
      's_pricing.promo_23_1st.main_countdown1',
      ``,
      ``,
      ``,
      ``,
    ],
    main_countdown2: [`일 남았어요`, `Days left`, 's_pricing.promo_23_1st.main_countdown2', ``, ``, ``, ``],
    main_nocoupon1: [
      `최대 25만원 추가 할인`,
      `Up to $192 Extra Off`,
      's_pricing.promo_23_1st.main_nocoupon1',
      ``,
      ``,
      ``,
      ``,
    ],
    main_nocoupon2: [
      <>
        신년 프로모션 혜택이
        <br />곧 사라져요.
      </>,
      <>
        Our New Year Offer
        <br />
        Disappears Soon!
      </>,
      's_pricing.promo_23_1st.main_nocoupon2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  cta_empty: [`수업권을 선택해 주세요.`, `Please select credit type.`, 's_pricing.cta_empty', ``, ``, ``, ``],
  modal_company_code1: [
    `로그인이 필요합니다.`,
    `You must log in to proceed.`,
    's_pricing.modal_company_code1',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_company_code2: [
    `기업 맞춤 코드를 입력하시려면 로그인을 먼저 진행해주세요.`,
    `Please log in first to enter the B2B code.`,
    's_pricing.modal_company_code2',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_company_code: [`로그인`, `Log in`, 's_pricing.btn_company_code', ``, ``, ``, ``],
  early_discount: [`기간 한정 할인율`, `Promotion Offer`, 's_pricing.early_discount', ``, ``, ``, ``],
  card_newbie: [`신규회원 추천`, `For Newcomers`, 's_pricing.card_newbie', ``, ``, ``, ``],
  newbie_best_price2: [
    <>추가 수업권 혜택 마감</>,
    <>Newcomers Benefit</>,
    's_pricing.newbie_best_price2',
    ``,
    ``,
    ``,
    ``,
  ],
  hour_timer: {
    label: (hour, min, sec) => [
      <>
        {hour}:{min}:{sec} 남음
      </>,
      <>
        {hour}:{min}:{sec} left
      </>,
      's_pricing.hour_timer.label',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  card_promotion_only: [`신년 프로모션 한정`, `Promotion Only`, 's_pricing.card_promotion_only', ``, ``, ``, ``],
  popupcheck: [
    `브라우저 팝업이 차단된 경우 결제가 진행되지 않을 수 있습니다. 팝업 설정을 미리 확인해 주세요.`,
    `You may not be able to proceed with the purchase if pop-ups are blocked. Please make sure that pop-ups are enabled beforehand.`,
    's_pricing.popupcheck',
    ``,
    ``,
    ``,
    ``,
  ],
  pricing_subtitle_1: [
    `하나의 주제에 대해 심도있게`,
    `Topical & In-depth`,
    's_pricing.pricing_subtitle_1',
    ``,
    ``,
    ``,
    ``,
  ],
  pricing_subtitle_2: [`40분 수업`, `40-min lessons`, 's_pricing.pricing_subtitle_2', ``, ``, ``, ``],
  pricing_subtitle_3: [
    `바쁜 일상 속 짧지만 확실하게`,
    `Short & Compact`,
    's_pricing.pricing_subtitle_3',
    ``,
    ``,
    ``,
    ``,
  ],
  pricing_subtitle_4: [`20분 수업`, `20-min lessons`, 's_pricing.pricing_subtitle_4', ``, ``, ``, ``],
  point_message: [
    `보유 포인트를 사용하면 할인된 가격으로 구매할 수 있어요.`,
    `Use your points toward this purchase.`,
    's_pricing.point_message',
    ``,
    ``,
    ``,
    ``,
  ],
  total: [`최종 결제 금액`, `Your total`, 's_pricing.total', ``, ``, ``, ``],
  next_purchase: {
    label: (benefit) => [
      <>재구매 쿠폰 ${benefit} 추가 할인</>,
      <>Extra ${benefit} off</>,
      's_pricing.next_purchase.label',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  welcome: {
    label: (benefit) => [
      <>신규회원 {benefit}원 추가 할인</>,
      <>Extra ${benefit} off</>,
      's_pricing.welcome.label',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  promotion_dday: {
    label: [<>신년 프로모션 마감까지 </>, <>Promotion ends in </>, 's_pricing.promotion_dday.label', ``, ``, ``, ``],
  },
  commissionfee_warning: [
    `실결제 금액은 카드사 적용 환율 및 수수료에 따라 차이가 발생할 수 있습니다.`,
    `Payment may vary based on your card company's rates and fees.`,
    's_pricing.commissionfee_warning',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_menu = {
  not_supported_features_in_english_mode: [
    `언어를 영어로 변경하면, 지원되지 않는 기능이 있을 수 있습니다.`,
    `Some features may not be available in English mode.`,
    's_menu.not_supported_features_in_english_mode',
    ``,
    ``,
    ``,
    ``,
  ],
  portal: {
    conversation: [`1:1 회화`, `1:1 Lessons`, 's_menu.portal.conversation', ``, ``, ``, ``],
    editing_service: [`영문서 교정`, `Editing&proof reading`, 's_menu.portal.editing_service', ``, ``, ``, ``],
    webinar: [`웨비나`, `Webinar`, 's_menu.portal.webinar', ``, ``, ``, ``],
    webinar_and_course: [`웨비나/교재`, `Webinar/Materials`, 's_menu.portal.webinar_and_course', ``, ``, ``, ``],
    purchase_lessons: [`구매`, `Buy now`, 's_menu.portal.purchase_lessons', ``, ``, ``, ``],
    invite: [`친구초대`, `Invite`, 's_menu.portal.invite', ``, ``, ``, ``],
    faq: [`Help Center`, `Help`, 's_menu.portal.faq', ``, ``, ``, ``],
    notice: [`공지사항`, `Updates`, 's_menu.portal.notice', ``, ``, ``, ``],
    mypage: [`내 페이지`, `My Page`, 's_menu.portal.mypage', ``, ``, ``, ``],
  },
  portalv2: {
    home: [`홈`, `Home`, 's_menu.portalv2.home', ``, ``, ``, ``],
    conversation: [`1:1 수업`, `1:1 Lessons`, 's_menu.portalv2.conversation', ``, ``, ``, ``],
    original: [`콘텐츠`, `Content`, 's_menu.portalv2.original', ``, ``, ``, ``],
    history: [`학습 활동`, `Activities`, 's_menu.portalv2.history', ``, ``, ``, ``],
    editing_service: [`영문서 교정`, `Editing&proof reading`, 's_menu.portalv2.editing_service', ``, ``, ``, ``],
  },
  mypage: {
    my_info: [`계정 관리`, `My Account`, 's_menu.mypage.my_info', ``, ``, ``, ``],
    coupon_certificates: [`수업권/증명서류`, `Credits`, 's_menu.mypage.coupon_certificates', ``, ``, ``, ``],
    coupon_box: [`쿠폰함`, `Coupons`, 's_menu.mypage.coupon_box', ``, ``, ``, ``],
    my_point: [`포인트`, `Points`, 's_menu.mypage.my_point', ``, ``, ``, ``],
    faq: [`자주 묻는 질문`, `FAQs`, 's_menu.mypage.faq', ``, ``, ``, ``],
    notice: [`공지사항`, `Updates`, 's_menu.mypage.notice', ``, ``, ``, ``],
    zoom_install: [`Zoom 설치 가이드`, `How to Install Zoom`, 's_menu.mypage.zoom_install', ``, ``, ``, ``],
    sign_out: [`로그아웃`, `Logout`, 's_menu.mypage.sign_out', ``, ``, ``, ``],
    sign_out_message: [
      `로그아웃 하시겠습니까?`,
      `Are you sure you want to log out?`,
      's_menu.mypage.sign_out_message',
      ``,
      ``,
      ``,
      ``,
    ],
    info: [`계정관리`, `My Account`, 's_menu.mypage.info', ``, ``, ``, ``],
    timezone_language: [
      <>타임존 & 언어</>,
      <>Time zone & Language</>,
      's_menu.mypage.timezone_language',
      ``,
      ``,
      ``,
      ``,
    ],
    change: [`변경`, `Update`, 's_menu.mypage.change', ``, ``, ``, ``],
    account: [`계정`, `Account`, 's_menu.mypage.account', ``, ``, ``, ``],
  },
  conversation: {
    trial_lesson: [`체험 신청`, `Trial Lesson`, 's_menu.conversation.trial_lesson', ``, ``, ``, ``],
    schedule_lesson: [`예약`, `Book`, 's_menu.conversation.schedule_lesson', ``, ``, ``, ``],
    upcoming_lessons: (count) => [
      `예습 & 수업 (${count})`,
      `Prep & Lesson (${count})`,
      's_menu.conversation.upcoming_lessons',
      ``,
      ``,
      ``,
      ``,
    ],
    lesson_statistics: [`수업 통계`, `Stats`, 's_menu.conversation.lesson_statistics', ``, ``, ``, ``],
    review_lessons: [`복습`, `Review`, 's_menu.conversation.review_lessons', ``, ``, ``, ``],
    lesson_materials: [`교재`, `Materials`, 's_menu.conversation.lesson_materials', ``, ``, ``, ``],
    tutors: [`튜터`, `Tutors`, 's_menu.conversation.tutors', ``, ``, ``, ``],
    ringle_guide: [`링글 활용법`, `How Ringle works`, 's_menu.conversation.ringle_guide', ``, ``, ``, ``],
    testimonials: [`후기`, `Testimonials`, 's_menu.conversation.testimonials', ``, ``, ``, ``],
    memo: [`메모`, `Memo`, 's_menu.conversation.memo', ``, ``, ``, ``],
    homework: [`숙제`, `Homework`, 's_menu.conversation.homework', ``, ``, ``, ``],
  },
  writing: {
    edit_request: [`교정 요청`, `Requested Edits`, 's_menu.writing.edit_request', ``, ``, ``, ``],
    ongoing_edits: [`진행 중인 교정`, `Edits in Progress`, 's_menu.writing.ongoing_edits', ``, ``, ``, ``],
    completed_edits: [`완료된 교정`, `Completed Edits`, 's_menu.writing.completed_edits', ``, ``, ``, ``],
  },
  original: {
    webinar: [`웨비나`, `Webinars`, 's_menu.original.webinar', ``, ``, ``, ``],
    course: [`전체 교재`, `All`, 's_menu.original.course', ``, ``, ``, ``],
    my: [`내 콘텐츠`, `My Content`, 's_menu.original.my', ``, ``, ``, ``],
    dailybrief: [`데일리 브리프`, `Daily Brief`, 's_menu.original.dailybrief', ``, ``, ``, ``],
  },
  landing: {
    why_ringle: [`What is Ringle`, `What is Ringle`, 's_menu.landing.why_ringle', ``, ``, ``, ``],
    by_customer: [`수강 대상`, `By Customer`, 's_menu.landing.by_customer', ``, ``, ``, ``],
    by_purpose: [`수강 목적`, `By Need`, 's_menu.landing.by_purpose', ``, ``, ``, ``],
    ringle_tip: [`Ringle Tip`, `Testimonials`, 's_menu.landing.ringle_tip', ``, ``, ``, ``],
    beginner: [`영어 초중급자`, `Beginner-Intermediate`, 's_menu.landing.beginner', ``, ``, ``, ``],
    signin: [`로그인`, `Log in`, 's_menu.landing.signin', ``, ``, ``, ``],
    free_trial: [`무료 체험`, `Sign up`, 's_menu.landing.free_trial', ``, ``, ``, ``],
    free_trial_btn: [`무료 체험`, `Free Trial`, 's_menu.landing.free_trial_btn', ``, ``, ``, ``],
    service_intro: [`전체보기`, `Overview`, 's_menu.landing.service_intro', ``, ``, ``, ``],
    portal: [`내 강의실`, `My Lessons`, 's_menu.landing.portal', ``, ``, ``, ``],
    conversation: [`What is Ringle`, `What is Ringle`, 's_menu.landing.conversation', ``, ``, ``, ``],
    tutor: [`튜터`, `Tutors`, 's_menu.landing.tutor', ``, ``, ``, ``],
    lesson_materials: [`교재`, `Materials`, 's_menu.landing.lesson_materials', ``, ``, ``, ``],
    review: [`후기`, `Testimonials`, 's_menu.landing.review', ``, ``, ``, ``],
    lesson_process: [`사용법`, `How Ringle works`, 's_menu.landing.lesson_process', ``, ``, ``, ``],
    lesson_system: [`수업 시스템`, `Features`, 's_menu.landing.lesson_system', ``, ``, ``, ``],
    solution: [`Ringle for`, `Ringle for`, 's_menu.landing.solution', ``, ``, ``, ``],
    pricing: [`가격`, `Pricing`, 's_menu.landing.pricing', ``, ``, ``, ``],
    business: [`비즈니스 영어`, `Business English`, 's_menu.landing.business', ``, ``, ``, ``],
    correction: [`이메일/에세이 교정`, `Resume & Essay Editing`, 's_menu.landing.correction', ``, ``, ``, ``],
    prep_interview: [`인터뷰 준비`, `Interview Prep`, 's_menu.landing.prep_interview', ``, ``, ``, ``],
    pronounce: [<>발음 교정 (미국/영국)</>, <>Pronunciation</>, 's_menu.landing.pronounce', ``, ``, ``, ``],
    office_worker: [`직장인/전문직`, `Global Professionals`, 's_menu.landing.office_worker', ``, ``, ``, ``],
    professional: [`전문직 종사자`, ``, 's_menu.landing.professional', ``, ``, ``, ``],
    study_abroad: [`유학생`, `International Students`, 's_menu.landing.study_abroad', ``, ``, ``, ``],
    junior: [`초중고 학생`, `K-12`, 's_menu.landing.junior', ``, ``, ``, ``],
    prep_exam: [`공인영어시험 대비`, `Exam Prep`, 's_menu.landing.prep_exam', ``, ``, ``, ``],
    live_abroad: [`해외 거주자`, `Overseas Residents`, 's_menu.landing.live_abroad', ``, ``, ``, ``],
    b2b: [`기업제휴`, `Business`, 's_menu.landing.b2b', ``, ``, ``, ``],
    event: [`이벤트`, `Event`, 's_menu.landing.event', ``, ``, ``, ``],
    faq: [`Help Center`, `Help Center`, 's_menu.landing.faq', ``, ``, ``, ``],
    language: [`언어`, `Language`, 's_menu.landing.language', ``, ``, ``, ``],
    free_trial_btn_2: [`무료체험`, `Free Trial`, 's_menu.landing.free_trial_btn_2', ``, ``, ``, ``],
    signup: [`회원가입`, `Sign-up`, 's_menu.landing.signup', ``, ``, ``, ``],
    login: [`로그인`, `Log in`, 's_menu.landing.login', ``, ``, ``, ``],
    bosnychallenge: [`보스턴&뉴욕 챌린지`, `Boston & NYC Challenge`, 's_menu.landing.bosnychallenge', ``, ``, ``, ``],
    free_signup: [`무료 체험`, `Free Trial`, 's_menu.landing.free_signup', ``, ``, ``, ``],
  },
  statistics: {
    all: [`전체`, `All`, 's_menu.statistics.all', ``, ``, ``, ``],
    read: [`읽기`, `Reading`, 's_menu.statistics.read', ``, ``, ``, ``],
    listen: [`듣기`, `Listening`, 's_menu.statistics.listen', ``, ``, ``, ``],
    write: [`쓰기`, `Writing`, 's_menu.statistics.write', ``, ``, ``, ``],
    speak: [`말하기`, `Speaking`, 's_menu.statistics.speak', ``, ``, ``, ``],
  },
  return_prev: [`이전 페이지로 돌아가기`, `Return to previous page`, 's_menu.return_prev', ``, ``, ``, ``],
  sir: [`님`, ``, 's_menu.sir', ``, ``, ``, ``],
  change_language: [`언어 변경`, `Change Language`, 's_menu.change_language', ``, ``, ``, ``],
}
export const s_landing = {
  get_started: [`무료체험 수업 신청하기`, `Sign up`, 's_landing.get_started', ``, ``, ``, ``],
  get_started_free_trial: [
    <>
      지금 링글을 시작해 보세요!
      <br />
      회원가입 시 1회 체험 수업 무료
    </>,
    <>Start now with a free trial</>,
    's_landing.get_started_free_trial',
    ``,
    ``,
    ``,
    ``,
  ],
  signup_check_out: [`무료체험 신청하기`, `Register for free trial`, 's_landing.signup_check_out', ``, ``, ``, ``],
  get_started_signup: [
    <>첫 수업 불만족 시, 100% 환불 가능</>,
    <>100% Money-back guarantee.</>,
    's_landing.get_started_signup',
    ``,
    ``,
    ``,
    ``,
  ],
  get_signup: [`무료체험 신청하기`, `Register for free trial`, 's_landing.get_signup', ``, ``, ``, ``],
  get_started_counsel: [<>무료 상담 신청</>, <>Book 1:1 Advising</>, 's_landing.get_started_counsel', ``, ``, ``, ``],
  gnb: {
    welcome_coupon: [
      <>
        지금 가입하면{' '}
        <Div spanTag fontBold>
          3만원 웰컴쿠폰
        </Div>
        을 드려요
      </>,
      <>
        Join now for{' '}
        <Div spanTag fontBold>
          extra $25
        </Div>{' '}
        off
      </>,
      's_landing.gnb.welcome_coupon',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  welcome_coupon_otherpage_btn: [
    `지금 신규가입 혜택받기`,
    `Get max.$77 off Newcomer Offer`,
    's_landing.welcome_coupon_otherpage_btn',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_system = {
  for_better_study: [
    <>
      오직 당신을 위한
      <br />
      일대일 맞춤 수업 시스템
    </>,
    <>
      The best-in-class
      <br />
      learning platform
    </>,
    's_system.for_better_study',
    ``,
    ``,
    ``,
    ``,
  ],
  reservation_system: [`예약`, `Book`, 's_system.reservation_system', ``, ``, ``, ``],
  reservation_subtitle: [
    <>
      365일 원클릭으로 <br />
      직접 정하는 수업 일정
    </>,
    ``,
    's_system.reservation_subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_i_want: [`원하는 튜터 직접 선택`, `Searchable Tutor Profiles`, 's_system.tutor_i_want', ``, ``, ``, ``],
  tutor_i_want_detail_1: [
    `1000+명의 튜터 상세 프로필 제공`,
    `View detailed profiles of 1000+ tutors`,
    's_system.tutor_i_want_detail_1',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_i_want_detail_2: [
    `전공/경력/커리어/발음 등 맞춤 검색`,
    `Search by tutor's major, experience, career`,
    's_system.tutor_i_want_detail_2',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_i_want_detail_3: [
    `찜한 튜터 수업 오픈 시 알림 제공`,
    `Set notifications for your favorite tutors`,
    's_system.tutor_i_want_detail_3',
    ``,
    ``,
    ``,
    ``,
  ],
  auto_matching: [`자동 매칭 시스템`, `Ringle Auto Matching`, 's_system.auto_matching', ``, ``, ``, ``],
  auto_matching_detail_1: [
    `원하는 일정만 선택하면 최적의 튜터 매칭`,
    `We'll match you to the best tutor available`,
    's_system.auto_matching_detail_1',
    ``,
    ``,
    ``,
    ``,
  ],
  free_scheduling: [`자유로운 일정 변경`, `Flexible Booking`, 's_system.free_scheduling', ``, ``, ``, ``],
  free_scheduling_detail_1: [
    `연중무휴로 하루에도 횟수 제한 없이 예약 가능`,
    `Book as many or as little lessons as you like`,
    's_system.free_scheduling_detail_1',
    ``,
    ``,
    ``,
    ``,
  ],
  free_scheduling_detail_2: [
    `수업 시작 24시간 전까지 변경/취소 가능`,
    `Pick a time that works for you`,
    's_system.free_scheduling_detail_2',
    ``,
    ``,
    ``,
    ``,
  ],
  free_scheduling_detail_3: [
    `수업 시작 기준 24~2시간 이전 취소 시, 당일수업권 제공`,
    `Flexible cancellations`,
    's_system.free_scheduling_detail_3',
    ``,
    ``,
    ``,
    ``,
  ],
  prestudy_system: [`예습`, `Lesson Prep`, 's_system.prestudy_system', ``, ``, ``, ``],
  prestudy_subtitle: [
    <>
      일대일 수업에 최적화된 <br />
      학습 인터페이스
    </>,
    ``,
    's_system.prestudy_subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  course: [<>온라인 교재와 오디오북</>, <>Lesson Materials</>, 's_system.course', ``, ``, ``, ``],
  course_detail_1: [
    <>PC/모바일 앱에서 무제한 열람</>,
    <>Search by topic or difficulty</>,
    's_system.course_detail_1',
    ``,
    ``,
    ``,
    ``,
  ],
  course_detail_2: [
    <>PDF/MP3로 다운로드</>,
    <>Read on Ringle mobile app or PC</>,
    's_system.course_detail_2',
    ``,
    ``,
    ``,
    ``,
  ],
  course_detail_3: [
    <>국/영문 통합본 제공</>,
    <>Download audiobook in MP3</>,
    's_system.course_detail_3',
    ``,
    ``,
    ``,
    ``,
  ],
  pre_qna: [`사전 질문 선택 및 답변 작성`, `Discussion Questions`, 's_system.pre_qna', ``, ``, ``, ``],
  can_choose_question: [
    `수준별 질문 선택으로 난이도 조절`,
    `Choose questions of desired difficulty level`,
    's_system.can_choose_question',
    ``,
    ``,
    ``,
    ``,
  ],
  answer_to_study: [
    `질문별 답변 작성으로 영작문 연습`,
    `Prep responses in advance`,
    's_system.answer_to_study',
    ``,
    ``,
    ``,
    ``,
  ],
  share_answers: [
    `학습자와 튜터의 답변 공유 가능`,
    `Request proofreading on written response`,
    's_system.share_answers',
    ``,
    ``,
    ``,
    ``,
  ],
  can_upload_my_data: [
    `개인 수업 자료 업로드`,
    `Upload Your Own Materials`,
    's_system.can_upload_my_data',
    ``,
    ``,
    ``,
    ``,
  ],
  free_topic_material: [
    `영어 인터뷰, 발표 준비, 이메일 교정 등 맞춤 수업 가능`,
    <>Upload resume, presentation, articles, etc.</>,
    's_system.free_topic_material',
    ``,
    ``,
    ``,
    ``,
  ],
  format_support: [
    `PDF, Docx 등 다양한 포맷 지원`,
    <>Upload in PDF, DOC, or PPT</>,
    's_system.format_support',
    ``,
    ``,
    ``,
    ``,
  ],
  security_system: [
    `개인정보 보호 기능`,
    `Security system protects confidential info`,
    's_system.security_system',
    ``,
    ``,
    ``,
    ``,
  ],
  setting: [`설정`, `Lesson Style`, 's_system.setting', ``, ``, ``, ``],
  setting_subtitle: [
    <>
      원하는 수업을 <br />
      수준에 맞춰 설계
    </>,
    ``,
    's_system.setting_subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_style: [<>수업 방식 설정</>, <>Customizable Lesson Style</>, 's_system.lesson_style', ``, ``, ``, ``],
  debate_talk: [
    <>교정 중심 vs 토론 중심</>,
    <>Discussion Focused vs Paraphrasing Focused</>,
    's_system.debate_talk',
    ``,
    ``,
    ``,
    ``,
  ],
  intensity: [
    <>대화 비중 및 교정 강도 조절</>,
    <>Control discussion engagement</>,
    's_system.intensity',
    ``,
    ``,
    ``,
    ``,
  ],
  focusing_area: [<>집중 교정 영역 선택</>, <>Main Area of Focus</>, 's_system.focusing_area', ``, ``, ``, ``],
  detail_area: [
    <>어휘, 문법, 표현, 발음 등 세분화된 교정 영역</>,
    <>Choose from 4 categories</>,
    's_system.detail_area',
    ``,
    ``,
    ``,
    ``,
  ],
  area_feedback: [
    <>선택 영역 기반 피드백 제공</>,
    <>Feedback provided based on your selection</>,
    's_system.area_feedback',
    ``,
    ``,
    ``,
    ``,
  ],
  detail_request: [<>상세 요청사항 작성</>, <>Other Requests</>, 's_system.detail_request', ``, ``, ``, ``],
  ask_before: [
    <>원하는 수업 방향을 튜터에게 전달</>,
    <>Specify any other requests to your tutor</>,
    's_system.ask_before',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_design: [
    <>학습 목적에 맞는 수업 설계</>,
    <>Further customize your lessons to fit learning objectives</>,
    's_system.lesson_design',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_system: [`수업`, `Lessons`, 's_system.lesson_system', ``, ``, ``, ``],
  lesson_subtitle: [
    <>
      말하면 눈에 보이는 <br />
      실시간 교정 화면
    </>,
    ``,
    's_system.lesson_subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  realtime_correction: [`실시간 교정 제공`, `Real-time corrections`, 's_system.realtime_correction', ``, ``, ``, ``],
  realtime_correction_detail_1: [
    `나의 영어를 실시간으로 시각화`,
    `View corrections, lesson materials, and tutor simultaneously`,
    's_system.realtime_correction_detail_1',
    ``,
    ``,
    ``,
    ``,
  ],
  realtime_correction_detail_2: [`영어 글쓰기 공동작업`, ``, 's_system.realtime_correction_detail_2', ``, ``, ``, ``],
  realtime_correction_detail_3: [
    `수업 후에도 수업 노트 확인 가능`,
    <>You can view correction notes after lessons</>,
    's_system.realtime_correction_detail_3',
    ``,
    ``,
    ``,
    ``,
  ],
  translation_function: [
    `번역기/사전 탑재`,
    `Translator & Dictionary`,
    's_system.translation_function',
    ``,
    ``,
    ``,
    ``,
  ],
  google_translator: [
    `수업 중 번역기 사용 가능`,
    `Translating tools available during lessons`,
    's_system.google_translator',
    ``,
    ``,
    ``,
    ``,
  ],
  dictionary: [`영/한 사전 탑재`, ``, 's_system.dictionary', ``, ``, ``, ``],
  realtime_video: [`끊김없는 화상환경`, `1:1 Video conferencing`, 's_system.realtime_video', ``, ``, ``, ``],
  realtime_video_detail_1: [
    `최신 화상 프로그램(Zoom) 사용`,
    `Lesson conducted via Zoom`,
    's_system.realtime_video_detail_1',
    ``,
    ``,
    ``,
    ``,
  ],
  realtime_video_detail_2: [
    `운영팀 실시간 지원`,
    `Support team available real-time`,
    's_system.realtime_video_detail_2',
    ``,
    ``,
    ``,
    ``,
  ],
  realtime_video_detail_3: [`네트워크 문제 시 해결 및 보상`, ``, 's_system.realtime_video_detail_3', ``, ``, ``, ``],
  review_system: [`복습`, `Lesson Review`, 's_system.review_system', ``, ``, ``, ``],
  review_system_subtitle: [
    <>
      수업이 끝나도
      <br />
      기록에 남는 수업
    </>,
    ``,
    's_system.review_system_subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  review_script: [<>녹음파일 & 대화 스크립트</>, <>Recording & Transcript</>, 's_system.review_script', ``, ``, ``, ``],
  review_script_detail_1: [
    <>수업 전체 녹음파일 지원</>,
    <>Audio-recording of your lesson</>,
    's_system.review_script_detail_1',
    ``,
    ``,
    ``,
    ``,
  ],
  review_script_detail_2: [
    <>튜터와 나눈 대화를 스크립트로 제공</>,
    <>Conversation script of your lesson</>,
    's_system.review_script_detail_2',
    ``,
    ``,
    ``,
    ``,
  ],
  review_script_detail_3: [
    <>수업 노트 다시 보기 가능</>,
    <>Correction notes</>,
    's_system.review_script_detail_3',
    ``,
    ``,
    ``,
    ``,
  ],
  review_feedback: [<>다각도 피드백</>, <>Tutor Feedback</>, 's_system.review_feedback', ``, ``, ``, ``],
  review_feedback_detail_1: [
    <>공인 영어 스피킹 점수 예측</>,
    <>Estimated scores for English proficiency exams</>,
    's_system.review_feedback_detail_1',
    ``,
    ``,
    ``,
    ``,
  ],
  review_feedback_detail_2: [
    <>어휘, 문법, 발음, 유창성 등 영역별 진단</>,
    <>Thorough evaluations on pronunciations, grammar, vocabulary and fluency</>,
    's_system.review_feedback_detail_2',
    ``,
    ``,
    ``,
    ``,
  ],
  review_ai: [<>AI 분석</>, <>AI Analysis</>, 's_system.review_ai', ``, ``, ``, ``],
  review_ai_detail_1: [
    <>Filler Word, 자주 쓰는 단어 등 영어 습관 분석</>,
    <>Review speech pace, vocab range and filler words</>,
    's_system.review_ai_detail_1',
    ``,
    ``,
    ``,
    ``,
  ],
  review_ai_detail_2: [
    <>말하기 속도 및 발화량 분석</>,
    <>Measure your performance against other Ringlers</>,
    's_system.review_ai_detail_2',
    ``,
    ``,
    ``,
    ``,
  ],
  all_this_mobile: [
    <>
      이 모든 것을 <br />
      앱에서 제약없이 <br />
      <br />
      PC - 앱 연동으로 모든 기능을
      <br />
      자유롭게 이동하며 이용할 수 있습니다
    </>,
    <>Access all of Ringle on your phone</>,
    's_system.all_this_mobile',
    ``,
    ``,
    ``,
    ``,
  ],
  app_down: [<>앱 다운로드</>, <>Download App</>, 's_system.app_down', ``, ``, ``, ``],
  ringle_platform: [`Ringle's Approach`, `Ringle's Approach`, 's_system.ringle_platform', ``, ``, ``, ``],
}
export const s_trial = {
  noTimeBanner: {
    text_1: [
      <>
        <div>
          <Span bold>바로 구매하면 추가 혜택을 드립니다.</Span>
        </div>
        <div>
          <Span size={14}>1만 원 추가할인+최대 3회 수업권</Span>
          {isMobile && <br />}
          <Span className="ml-lg-2 text-gray-600" size={isMobile ? 10 : 14}>
            첫 수업 후 환불 요청 시, 100% 환불 제공
          </Span>
        </div>
      </>,
      <>
        Buy now without a Free Trial Lesson
        <br />
        And get up to three free lesson credits
      </>,
      's_trial.noTimeBanner.text_1',
      ``,
      ``,
      ``,
      ``,
    ],
    go: [<>바로 구매하기</>, <>Buy Now</>, 's_trial.noTimeBanner.go', ``, ``, ``, ``],
  },
  purchase_message: [
    <>
      무료 체험 수업은 시간과 교재 선택이 제한적일 수 있습니다.
      <br />
      체험없이 구매 시, 10,000원 추가 할인 및 최대 3회 추가 수업이 제공됩니다. (첫 수업 불만족 시 100% 환불 보장)
    </>,
    <>
      Time slots and materials may be limited for trial lessons.
      <br />
      Buy now without a Free trial for additional promo or 3 free lesson credits.
      <br />
      100% Money-back guarantee.
    </>,
    's_trial.purchase_message',
    ``,
    ``,
    ``,
    ``,
  ],
  select_date: [`날짜 선택`, `Select date`, 's_trial.select_date', ``, ``, ``, ``],
  select_time: [`시간 선택`, `Select time`, 's_trial.select_time', ``, ``, ``, ``],
  request_verification_code: [
    `인증 요청`,
    `Request verification code`,
    's_trial.request_verification_code',
    ``,
    ``,
    ``,
    ``,
  ],
  _1_title: [`체험 수업 시간 선택`, <>Select Date & Time</>, 's_trial._1_title', ``, ``, ``, ``],
  _1_subTitle: [
    `시간은 수업 시작 24시간 전까지 변경하실 수 있습니다.`,
    `You may reschedule as late as 24 hours before the lesson starts.`,
    's_trial._1_subTitle',
    ``,
    ``,
    ``,
    ``,
  ],
  _1_description: [
    `무료 체험 수업은 각 시간대에서 선착순으로 제공되고 있기 때문에 시간 선택이 제한적일 수 있습니다.`,
    ``,
    's_trial._1_description',
    ``,
    ``,
    ``,
    ``,
  ],
  _2_title: [`교재 선택`, `Select Lesson Materials`, 's_trial._2_title', ``, ``, ``, ``],
  _2_subTitle: [
    <>
      수업에서 튜터와 다룰 교재는&nbsp;
      <Span className="font-weight-medium">수업 시작 24시간 전까지</Span>&nbsp; 변경하실 수 있습니다.
    </>,
    `You may change Lesson Materials as late as 24 hours before the lesson starts.`,
    's_trial._2_subTitle',
    ``,
    ``,
    ``,
    ``,
  ],
  _2_description: [
    `무료 체험수업은 일부 교재만 선택할 수 있습니다. 정규수업에서는 인터뷰/프리젠테이션 준비 등을 위한 맞춤 수업이 가능합니다.`,
    ``,
    's_trial._2_description',
    ``,
    ``,
    ``,
    ``,
  ],
  _3_title: [`휴대폰 인증`, `Verify Mobile Number`, 's_trial._3_title', ``, ``, ``, ``],
  _3_subTitle: [
    `무료 체험수업 신청 마지막 단계입니다. 인증 중 문제가 발생하면 [문의]를 클릭하여 연락해주세요.`,
    ``,
    's_trial._3_subTitle',
    ``,
    ``,
    ``,
    ``,
  ],
  there_is_no_available_time: [
    <>
      <div>
        <Span className="font-weight-medium" size={18}>
          해당 날짜 체험수업은 마감되었습니다.
        </Span>
        <br />
      </div>
      <div className="mt-2 display-9">
        [날짜 선택] 탭을 통해 다른 일정을 확인해 보세요.
        <br />
        체험수업은 모든 정규수업이 선 배정된 후 선착순으로 배정됩니다.
        <br />
        현재 모든 수업 수가 동시 증가하여, 체험수업 시간이 충분하지 않음을 양해 부탁드립니다.
      </div>
      <div>
        <Span className="text-gray-600" size={12}>
          수업 관련 문의는 홈페이지 하단의 [1:1 문의]를 이용해 주세요.
        </Span>
      </div>
    </>,
    <>
      <div>
        <Span size={18}>There is no availability on the selected date.</Span>
        <br />
      </div>
      <div className="mt-2 display-9">
        Ringle assigns regular lessons first, then allocates the remaining time slots for free trial lessons.
        <br />
        Please understand that there may not be enough available time slots due to high demands.
      </div>
      <div>
        <Span className="text-gray-600" size={12}>
          If you have any questions, please contact us via chat below.
        </Span>
      </div>
    </>,
    's_trial.there_is_no_available_time',
    ``,
    ``,
    ``,
    ``,
  ],
  not_select_time: [`시간 선택되지 않음`, `Time unselected`, 's_trial.not_select_time', ``, ``, ``, ``],
  selected_time: [`시간 선택 완료`, `Time selected`, 's_trial.selected_time', ``, ``, ``, ``],
  not_verify_phone: [`번호 인증되지 않음`, `Mobile number not verified`, 's_trial.not_verify_phone', ``, ``, ``, ``],
  verify_phone: [`번호 인증 됨`, `Mobile number verified`, 's_trial.verify_phone', ``, ``, ``, ``],
  not_select_course: [`교재 선택되지 않음`, `Materials unselected`, 's_trial.not_select_course', ``, ``, ``, ``],
  selected_course: [`교재 선택 완료`, `Materials selected`, 's_trial.selected_course', ``, ``, ``, ``],
  enter_your_phone_number: [
    `휴대폰 번호를 입력해주세요`,
    `Enter your phone number.`,
    's_trial.enter_your_phone_number',
    ``,
    ``,
    ``,
    ``,
  ],
  verification_is_incorrect_plz_try_again: [
    `인증 번호가 틀렸습니다. 다시 입력해 주세요`,
    `Verification is incorrect. Please try again.`,
    's_trial.verification_is_incorrect_plz_try_again',
    ``,
    ``,
    ``,
    ``,
  ],
  view_material: [`교재 보기`, `View Lesson Materials`, 's_trial.view_material', ``, ``, ``, ``],
  you_have_already_requested: [
    `이미 체험신청이 완료되었습니다.`,
    `You already booked your trial lesson.`,
    's_trial.you_have_already_requested',
    ``,
    ``,
    ``,
    ``,
  ],
  please_select_a_date_and_time: [
    `날짜와 시간을 선택해주세요`,
    `Please select date and time.`,
    's_trial.please_select_a_date_and_time',
    ``,
    ``,
    ``,
    ``,
  ],
  please_select_material: [
    `교재를 선택해주세요`,
    `Please select lesson materials.`,
    's_trial.please_select_material',
    ``,
    ``,
    ``,
    ``,
  ],
  please_complete_phone_number_verification: [
    `휴대폰 인증을 완료해주세요`,
    `Please verify your mobile number.`,
    's_trial.please_complete_phone_number_verification',
    ``,
    ``,
    ``,
    ``,
  ],
  submit: [`체험 수업 신청`, `Submit`, 's_trial.submit', ``, ``, ``, ``],
  incomplete_alert: [
    `수업시간 선택, 교재 선택, 번호 인증 등의 3단계를 모두 완료하셔야만 체험 수업을 신청하실 수 있습니다.`,
    `Please complete all three steps to book your trial lesson: 1) select date & time, 2) select lesson materials, 3) verifying mobile number.`,
    's_trial.incomplete_alert',
    ``,
    ``,
    ``,
    ``,
  ],
  you_can_view_list_of_available_tutors_after_entering_self_intro: [
    `짧게 시간내어 소개를 남기고, 수업에서 만날 튜터의 프로필을 확인해 보세요!`,
    `Complete your profile and check out your tutor's profile!`,
    's_trial.you_can_view_list_of_available_tutors_after_entering_self_intro',
    ``,
    ``,
    ``,
    ``,
  ],
  everybody_conversation: [`일상 대화`, `Casual Conversations`, 's_trial.everybody_conversation', ``, ``, ``, ``],
  freetalking: [`자유주제`, `Free Topic`, 's_trial.freetalking', ``, ``, ``, ``],
  completed_title: [
    <>
      체험 수업 신청이 {isMobile && <br />}
      완료되었습니다.
    </>,
    <>Your free trial lesson {isMobile && <br />}has been successfully scheduled</>,
    's_trial.completed_title',
    ``,
    ``,
    ``,
    ``,
  ],
  completed_description: [
    <>수업 시작 10분 전까지 노트북으로 링글 웹사이트에 로그인 하신 후, 수업을 준비해 주세요.</>,
    <>
      Please log into Ringle's website 10 minutes before the lesson starts. For a better learning experience, please
      enter Ringle lessons on a web browser.
    </>,
    's_trial.completed_description',
    ``,
    ``,
    ``,
    ``,
  ],
  check_before_lesson: [`수업 전 필수 확인 사항`, `Before your lesson`, 's_trial.check_before_lesson', ``, ``, ``, ``],
  need_to_zoom: [
    `화상수업을 위해 Zoom 설치가 필요합니다.`,
    `Ringle lessons are run on Zoom. Please install Zoom.`,
    's_trial.need_to_zoom',
    ``,
    ``,
    ``,
    ``,
  ],
  need_to_chrome: [
    `링글 서비스는 크롬 브라우저에서 최적화되어 되어 있습니다.`,
    `Please use Google Chrome or Microsoft Edge to access Ringle. Some features may not be available in other browsers.`,
    's_trial.need_to_chrome',
    ``,
    ``,
    ``,
    ``,
  ],
  not_support_ie: [
    `익스플로러 브라우저는 지원하지 않습니다.`,
    `Browser not supported.`,
    's_trial.not_support_ie',
    ``,
    ``,
    ``,
    ``,
  ],
  laptop: [
    `1:1 영어 화상 수업은 노트북 사용을 권장드립니다.`,
    `For a better learning experience, please enter Ringle lessons on a web browser.`,
    's_trial.laptop',
    ``,
    ``,
    ``,
    ``,
  ],
  trial_guide: [
    `체험 수업 가이드 확인 하기`,
    `How it works: Ringle Trial Lessons`,
    's_trial.trial_guide',
    ``,
    ``,
    ``,
    ``,
  ],
  go_prep: [`예습하러 가기`, `Lesson Prep`, 's_trial.go_prep', ``, ``, ``, ``],
  download_app: [`링글 모바일앱을 설치해 보세요`, `Download Ringle Mobile App`, 's_trial.download_app', ``, ``, ``, ``],
  stay_connected: [`예습-수업-복습을 언제 어디서나 할 수 있어요.`, ``, 's_trial.stay_connected', ``, ``, ``, ``],
  completed_guide_1: [
    `수업 취소 및 시간 및 교재 변경은 수업 시작 24시간 전까지 예정된 수업 페이지에서 가능합니다.`,
    `You may reschedule or cancel the lesson or change lesson materials as late as 24 hours before the lesson starts.`,
    's_trial.completed_guide_1',
    ``,
    ``,
    ``,
    ``,
  ],
  completed_guide_2: [
    `급한 사정으로 수업 시작 24시간 이내 일정 변경이 필요한 경우, 수업을 취소하고 홈페이지의 [문의]를 통해 연락주세요.`,
    `If you must reschedule less than 24 hours before the lesson, please contact us via chat below.`,
    's_trial.completed_guide_2',
    ``,
    ``,
    ``,
    ``,
  ],
  no_available_lesson: [
    `선착순으로 금일 수업은 마감되었습니다. 다른 일정을 확인해 보세요!`,
    `There is no available lesson on the selected date`,
    's_trial.no_available_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  view_all_material: [`교재 전체 보기`, `View All Materials`, 's_trial.view_all_material', ``, ``, ``, ``],
  freetalking_message: [
    <>
      체험수업에서는 <Span danger>자유주제 수업을 신청할 수 없습니다.</Span>
      <br />
      정규수업 시, 내가 원하는 주제로 토론 및 교정 수업을 진행할 수 있습니다.
    </>,
    <>
      <Span danger>Free talking lessons are unavailable</Span> for the trial.
      <br />
      During the regular lesson, you can take correction or discussion-focused lessons with any topic you’re interested
      in.
    </>,
    's_trial.freetalking_message',
    ``,
    ``,
    ``,
    ``,
  ],
  material_guide: [
    <>
      체험수업 시,&nbsp;
      <span className="font-medium">각 주제마다 가장 인기 있는 교재 10개가 제공됩니다.</span>
      <br />
      정규수업 시, 링글에서 제공하는 1,000개 이상 교재를 자유롭게 무료로 이용 가능합니다.
      <br />
    </>,
    <>
      Only the 10 most popular Ringle materials in each section are available for the trial lesson. During the regular
      lesson, you can select more than 1,000 materials for free.
    </>,
    's_trial.material_guide',
    ``,
    ``,
    ``,
    ``,
  ],
  buy_without_trial: [`무료 체험 없이 바로 구매`, `Buy without trial`, 's_trial.buy_without_trial', ``, ``, ``, ``],
  trial_can_be_limited: [
    `무료체험: 정규수업보다 수업 시간, 교재, 튜터 선택이 자유롭지 않습니다.`,
    `Free trial offers limited options for time slots, materials, and tutors.`,
    's_trial.trial_can_be_limited',
    ``,
    ``,
    ``,
    ``,
  ],
  purchase_message_new: [
    <>무료체험 없이 구매 시, 최대 3회 수업권 + 1만원 추가 할인 혜택을 드려요! (첫 수업 불만족 시, 100% 환불 보장)</>,
    <>
      Skip trial and get up to 3 free credits & $8 off (full refund available for unsatisfied customers after first
      lesson)
    </>,
    's_trial.purchase_message_new',
    ``,
    ``,
    ``,
    ``,
  ],
  promotion_message: [
    `체험수업 일시 부족으로 추가 혜택을 제공합니다.`,
    `Offering extra benefits due to limited free trial`,
    's_trial.promotion_message',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_products = {
  dollarDescription: [
    <>
      <ul>
        <li>현재 환율에 의거하여 달러($)로 결제됩니다.</li>
        <li>
          PayPal을 로그인하지 않아도 사용가능합니다(PayPal Guest Checkout 또는 Pay with Debit or Credit Card로
          결제하시면 됩니다)
        </li>
        <li>영수증은 등록하신 이메일로 자동 발송됩니다. (링글 사이트 내에서 확인이 불가합니다.)</li>
        <li>
          청구주소가 한국이면 이용이 <Span danger>불가능</Span>합니다.
        </li>
        <li>일부 국내법인카드는 Paypal을 지원하지 않습니다. 이 경우, 국내 &gt; 비인증 결제 진행부탁드립니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>
          You can complete your purchase without logging into PayPal. Select PayPal Guest Checkout or Pay with Debit or
          Credit Card.
        </li>
        <li>Your receipt will be emailed to you. You cannot view it on Ringle website.</li>
        <li>
          If your billing address is in South Korea, you <Span danger>cannot</Span> use PayPal.
        </li>
        <li>
          Some South Korean corporate credit cards may not be compatible with PayPal. Please use personal credit cards
          or Non-certificate pay
        </li>
      </ul>
    </>,
    's_products.dollarDescription',
    ``,
    ``,
    ``,
    ``,
  ],
  dollarConfirm: (price) => [
    <>
      <b>
        해당 상품은 <Span primary>${price}</Span>로 결제 됩니다.
        <br />
        진행하시겠습니까?
      </b>
    </>,
    <>
      <b>
        The selected credits will be purchased with&nbsp;
        <Span primary>${price}</Span>. Do you want to proceed?
      </b>
    </>,
    's_products.dollarConfirm',
    ``,
    ``,
    ``,
    ``,
  ],
  no: [`아니오`, `No`, 's_products.no', ``, ``, ``, ``],
  yes: [`네`, `Yes`, 's_products.yes', ``, ``, ``, ``],
  wonDescription: [
    <>
      <ul>
        <li>원화(￦)로 결제됩니다.</li>
        <li>결제 내역에는 링글의 결제 대행사인 ‘JTNET’이 상점명으로 표시됩니다.</li>
        <li>링글 사이트 내에서 영수증을 확인할 수 있습니다.</li>
        <li>소량의 환전 수수료가 추가될 수 있습니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>The purchase will be made in Korean Won (KRW).</li>
        <li>The receipt will be issued under 'JTNET,' Ringle's payment agency.</li>
        <li>You can view your receipt on the Ringle website.</li>
        <li>A small amount of currency exchange fee may be charged.</li>
      </ul>
    </>,
    's_products.wonDescription',
    ``,
    ``,
    ``,
    ``,
  ],
  wonConfirm: (price) => [
    <>
      <b>
        해당 상품은 <Span primary>￦{price}</Span>로 결제 됩니다.
        <br />
        진행하시겠습니까?
      </b>
    </>,
    <>
      <b>
        The selected package will be purchased with&nbsp;
        <Span primary>￦{price}</Span>. Do you want to proceed?
      </b>
    </>,
    's_products.wonConfirm',
    ``,
    ``,
    ``,
    ``,
  ],
  lumpPayment: [`일시불`, `Pay Upfront`, 's_products.lumpPayment', ``, ``, ``, ``],
  month: (month) => [<>{month}개월</>, <>{month} months</>, 's_products.month', ``, ``, ``, ``],
  totalPrice: (price) => [
    <>총 결제 금액: {price}원</>,
    <>Your Total: {price} KRW</>,
    's_products.totalPrice',
    ``,
    ``,
    ``,
    ``,
  ],
  firstCard: [`첫번째 카드 결제 금액`, `Charge on the first card`, 's_products.firstCard', ``, ``, ``, ``],
  secondCard: [`두번째 카드 결제 금액`, `Charge on the second card`, 's_products.secondCard', ``, ``, ``, ``],
  person: [`일시불 / 개인`, `Pay Now`, 's_products.person', ``, ``, ``, ``],
  secondCardPrice: (price) => [
    <>두번째 카드 결제 금액은 {price} 원 입니다.</>,
    <>{price} KRW will be charged to your second card.</>,
    's_products.secondCardPrice',
    ``,
    ``,
    ``,
    ``,
  ],
  firstCardPrice: (price) => [
    <>첫번째 카드 결제 금액은 {price} 원 입니다.</>,
    <>{price} KRW will be charged to your first card.</>,
    's_products.firstCardPrice',
    ``,
    ``,
    ``,
    ``,
  ],
  totalAmount: [`결제 금액`, `Your total`, 's_products.totalAmount', ``, ``, ``, ``],
  realAmount: (n) => [<>{n}원</>, <>${n}</>, 's_products.realAmount', ``, ``, ``, ``],
  cardInstallmentInfo: [`카드 할부 안내`, ``, 's_products.cardInstallmentInfo', ``, ``, ``, ``],
  installment: [`할부`, `Pay in installations`, 's_products.installment', ``, ``, ``, ``],
  inputCard: [`카드번호 입력`, `Enter card number`, 's_products.inputCard', ``, ``, ``, ``],
  personal: [`개인`, `Personal`, 's_products.personal', ``, ``, ``, ``],
  corporation: [`법인`, `Corporate`, 's_products.corporation', ``, ``, ``, ``],
  expiryDate: [`유효기간(mm/YY)`, `Expiration date (mm/YY)`, 's_products.expiryDate', ``, ``, ``, ``],
  passwordTwoDigit: [`비밀번호 앞 2자리`, `First 2 digits of the PIN`, 's_products.passwordTwoDigit', ``, ``, ``, ``],
  birthPersonal: [`생년월일(6자리)`, `Birth Date (6 digits, YYmmdd)`, 's_products.birthPersonal', ``, ``, ``, ``],
  birthCompany: [
    `사업자등록번호(10자리)`,
    `Business registration number (10 digits)`,
    's_products.birthCompany',
    ``,
    ``,
    ``,
    ``,
  ],
  limitPromotionPoint: [
    `해당 수업권은 첫 구매 할인이 적용되어 10,000 포인트만 사용 가능합니다.`,
    `Only up to $8.62 points can be used for purchasing this lesson credit.`,
    's_products.limitPromotionPoint',
    ``,
    ``,
    ``,
    ``,
  ],
  notAllowedPoint: [
    `해당 수업권은 최대 할인이 적용되어 추가 포인트 사용은 불가합니다.`,
    `You cannot use points for purchasing this lesson credit.`,
    's_products.notAllowedPoint',
    ``,
    ``,
    ``,
    ``,
  ],
  notAllowedTimelimitPoint: [
    `해당 수업권은 시간한정 할인이 적용되어 10,000 포인트만 사용 가능합니다.`,
    `Only up to $48.62 points can be used for purchasing this lesson credit.`,
    's_products.notAllowedTimelimitPoint',
    ``,
    ``,
    ``,
    ``,
  ],
  notAllowedGivingBack: [
    `Giving Back 포인트는 18회 이상 수업권 구매 시에만 사용 가능합니다.`,
    <>&apos;Giving Back Promotion&apos; point can be used only when purchasing more than 18 lesson credits.</>,
    's_products.notAllowedGivingBack',
    ``,
    ``,
    ``,
    ``,
  ],
  payment: [`결제하기`, `Purchase`, 's_products.payment', ``, ``, ``, ``],
  cardInfo: [`카드사 무이자 할부 안내`, `0% monthly payments`, 's_products.cardInfo', ``, ``, ``, ``],
  cardInfoContent: [
    <>
      기간 : 2020년 1월 1일 ~ 2020년 1월 31일
      <br />
      금액 : 5만원 이상
      <br />
      <br />
      ▣ 무이자 안내
      <br />
      1. 롯데카드: 2, 3, 4개월 무이자
      <br />
      2. 신한카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      3. 하나카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      4. NH농협카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      5. 비씨카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      6. 삼성카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      7. KB국민카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      8. 현대카드: 2, 3, 4, 5, 6, 7개월 무이자
      <br />
      <br />그 이외의 카드는 결제 시 확인 부탁드립니다.
    </>,
    <>
      기간 : 2020년 1월 1일 ~ 2020년 1월 31일
      <br />
      금액 : 5만원 이상
      <br />
      <br />
      ▣ 무이자 안내
      <br />
      1. 롯데카드: 2, 3, 4개월 무이자
      <br />
      2. 신한카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      3. 하나카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      4. NH농협카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      5. 비씨카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      6. 삼성카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      7. KB국민카드: 2, 3, 4, 5, 6개월 무이자
      <br />
      8. 현대카드: 2, 3, 4, 5, 6, 7개월 무이자
      <br />
      <br />그 이외의 카드는 결제 시 확인 부탁드립니다.
    </>,
    's_products.cardInfoContent',
    ``,
    ``,
    ``,
    ``,
  ],
  firstTimePurchase: [`첫구매가`, `First-time Purchase`, 's_products.firstTimePurchase', ``, ``, ``, ``],
  purchase_must_be: [
    `('결제사 정책에 의해 1,000원 이하 결제는 불가합니다')`,
    `(Purchases must be greater than $1.)`,
    's_products.purchase_must_be',
    ``,
    ``,
    ``,
    ``,
  ],
  corporate_donot_allow_installments: [
    `법인카드 사용시, 할부 결제가 불가능합니다.`,
    `Corporate cards do not allow installments.`,
    's_products.corporate_donot_allow_installments',
    ``,
    ``,
    ``,
    ``,
  ],
  how_to_use: {
    title: [`수업권 사용 안내`, `How to Use Credits`, 's_products.how_to_use.title', ``, ``, ``, ``],
    list1: [
      `수업권은 수강기간 내 자유롭게 이용할 수 있습니다. (일 별 수업 예약 횟수에 제한이 없습니다.)`,
      `You can book lessons during business hours, on any date before the credits expire. (You can freely book the lesson at any date and time.)`,
      's_products.how_to_use.list1',
      ``,
      ``,
      ``,
      ``,
    ],
    list2: [
      `수업 시간은 연중무휴로 한국 시간 기준 오전 5시 ~ 오후 2시, 저녁 7시 ~ 새벽 3시까지 입니다.`,
      `Lessons are available 5AM - 2PM / 7PM - 3AM (KST) every day.`,
      's_products.how_to_use.list2',
      ``,
      ``,
      ``,
      ``,
    ],
    list3: [
      `구매 후 1년까지는 첫 수업 완료 시점을 기준으로 수강기간이 차감됩니다.`,
      `Expiration dates start counting down from the date you complete the first lesson up to a year after the purchase date.`,
      's_products.how_to_use.list3',
      ``,
      ``,
      ``,
      ``,
    ],
    list4: [
      `구매 후 1년이 되는 시점부터는 사용 이력이 없어도 수강기간이 차감됩니다.`,
      `Unused credits' expiration dates will start counting down a year after the purchase date.`,
      's_products.how_to_use.list4',
      ``,
      ``,
      ``,
      ``,
    ],
    list5: [
      `포인트 사용 혹은 소액 결제를 통해 수강기간을 연장할 수 있습니다.`,
      `You can extend credit expiration dates with your points or by paying a small fee.`,
      's_products.how_to_use.list5',
      ``,
      ``,
      ``,
      ``,
    ],
    list6: [
      `실결제가 18만원 이상 수업권을 구매하신 후 링글 팀으로 연락주시면, 보유하신 수업권의 남은 기간에 최초 수강 기간을 더해 연장해드립니다. (예: 90일 수업권 구매 후, 수강기간 10일 남은 시점에 추가 수업권 구매 시, 해당 수업권에 90일 수강기간을 추가 연장 조치함)`,
      `If you contact our Help Center after purchasing a package worth $155.17 or more, we will extend your old credits by the initial validity period (e.g. 90-day credits expiring in 10 days will be extended by 90 days after the purchase of 180,000KRW worth of new credits).`,
      's_products.how_to_use.list6',
      ``,
      ``,
      ``,
      ``,
    ],
    list7: [
      `1회 수업권은 구매 후 즉시 수강 기간이 차감됩니다.`,
      `The trial lesson credit starts expiring immediately after purchase.`,
      's_products.how_to_use.list7',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  refund_policy: {
    title: [`수업권 환불 정책`, `Refund Policy`, 's_products.refund_policy.title', ``, ``, ``, ``],
    list1: [
      `구매 후 1년 이내 사용하지 않은 수업권은 100% 환불 가능합니다.`,
      `Credits that have not been used within a year after purchase are eligible for a full refund.`,
      's_products.refund_policy.list1',
      ``,
      ``,
      ``,
      ``,
    ],
    list2: [
      `첫 수업 불만족 시, 전액 환불 가능합니다. (첫 수업에 체험 수업 포함)`,
      `We offer a 100% money-back guarantee for your first lesson (including trial lessons).`,
      's_products.refund_policy.list2',
      ``,
      ``,
      ``,
      ``,
    ],
    list3: [
      `수강기간이 절반 이하 남거나, 수업 횟수가 절반 이하 남은 수업권은 환불이 불가능합니다.`,
      `No refund is offered for lesson credits starting halfway through the expiration dates or for packages that are half-used or more.`,
      's_products.refund_policy.list3',
      ``,
      ``,
      ``,
      ``,
    ],
    list4: [
      `수강 이력이 있는 수업권은 이용약관(13조, 15조)에 의거하여 처리합니다.`,
      `For refunds on used credits, please refer to articles 13 and 15 of your terms of service.`,
      's_products.refund_policy.list4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const s_lesson_style = {
  record: {
    title: [`수업 녹음`, `Lesson Audio-Recording`, 's_lesson_style.record.title', ``, ``, ``, ``],
    note: [
      `*수업 녹음 MP3파일은 수업 종료 후 파일 제작이 완료되면 "복습" 메뉴에서 확인하실 수 있습니다.`,
      `Your audio file (.MP3) will be available under "Review" after your lesson.`,
      's_lesson_style.record.note',
      ``,
      ``,
      ``,
      ``,
    ],
    noteTrial: [
      `수업 후, 복습을 위한 녹음파일과 Beta 서비스로 운영하는 스크립트 자료 제공를 위해 수업 내용은 녹음됩니다.`,
      `Trial Lessons are recorded to provide AI Analysis and audio playback & transcript.`,
      's_lesson_style.record.noteTrial',
      ``,
      ``,
      ``,
      ``,
    ],
    yes: [`수업 녹음하기`, `Record`, 's_lesson_style.record.yes', ``, ``, ``, ``],
    no: [`수업 녹음하지 않기`, `Do Not Record`, 's_lesson_style.record.no', ``, ``, ``, ``],
  },
  intro: {
    title: [`자기 소개`, `Tutor & Student Introductions`, 's_lesson_style.intro.title', ``, ``, ``, ``],
    placeholder: [
      `소개 없이 바로 수업할 경우 미리 튜터가 알 수 있도록 자신을 소개하는 글을 적어주세요.`,
      `If you'd like to start the lesson without introductions, please write a brief intro on yourself here.
E.g. My name is Kildong Hong and I am a business developer at Ringle, a startup providing 1:1 online English education services.`,
      's_lesson_style.intro.placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    skip: [`소개 없이 바로 시작`, `Start lesson without introductions`, 's_lesson_style.intro.skip', ``, ``, ``, ``],
    do: [
      `서로 소개 3분 후 시작`,
      `Spend first 3 minutes exchanging introductions`,
      's_lesson_style.intro.do',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  lesson_mode: {
    title: [`수업 모드`, `Lesson Mode`, 's_lesson_style.lesson_mode.title', ``, ``, ``, ``],
    note: [
      <>
        체험수업은 각 질문에 대한 대화가 끝난 후 교정받는 방식으로 진행합니다. 정규수업에서는 수업 모드를 교정 중심과
        토론 중심 중에 선택하고 세부 수업 방식을 선택할 수 있습니다.
      </>,
      <>
        All trial lessons are discussion-focused.
        <br />
        Paid lessons can either be discussion-focused or correction focused.
      </>,
      's_lesson_style.lesson_mode.note',
      ``,
      ``,
      ``,
      ``,
    ],
    note_short: [
      `15분 대화 - 5분 피드백`,
      `15m conversation - 5m feedback`,
      's_lesson_style.lesson_mode.note_short',
      ``,
      ``,
      ``,
      ``,
    ],
    correction: [`교정 중심`, `Paraphrasing-Focused`, 's_lesson_style.lesson_mode.correction', ``, ``, ``, ``],
    discussion: [`토론 중심`, `Discussion-Focused`, 's_lesson_style.lesson_mode.discussion', ``, ``, ``, ``],
  },
  mode_detail: {
    correction: {
      instant: [
        `상시교정(틀린 영어 표현을 할 때마다 교정 받음)`,
        `Rephrase my English as soon as possible`,
        's_lesson_style.mode_detail.correction.instant',
        ``,
        ``,
        ``,
        ``,
      ],
      instant_short: [
        `상시교정`,
        `Instant Paraphrasing`,
        's_lesson_style.mode_detail.correction.instant_short',
        ``,
        ``,
        ``,
        ``,
      ],
      intermittent: [
        `대화 교정모드(각 질문에 대한 대화가 끝난 후 교정 받음)`,
        `Rephrase my English after each of my answers`,
        's_lesson_style.mode_detail.correction.intermittent',
        ``,
        ``,
        ``,
        ``,
      ],
      intermittent_short: [
        `대화 교정모드`,
        `Intermittent Paraphrasing`,
        's_lesson_style.mode_detail.correction.intermittent_short',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    discussion: {
      mode55: [
        `5:5 토론(튜터와 내가 절반씩 이야기를 주고 받음)`,
        `I want my tutor to speak as much as I do (5:5)`,
        's_lesson_style.mode_detail.discussion.mode55',
        ``,
        ``,
        ``,
        ``,
      ],
      mode55_short: [
        `5:5 토론`,
        `5:5 Discussion`,
        's_lesson_style.mode_detail.discussion.mode55_short',
        ``,
        ``,
        ``,
        ``,
      ],
      mode82: [
        `8:2 토론(튜터는 듣고 내가 주로 이야기함)`,
        `I want to speak more than than my tutor (8:2)`,
        's_lesson_style.mode_detail.discussion.mode82',
        ``,
        ``,
        ``,
        ``,
      ],
      mode82_short: [
        `8:2 토론`,
        `8:2 Discussion`,
        's_lesson_style.mode_detail.discussion.mode82_short',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  intensive_focus: {
    title: [
      `집중 교정 영역 (다중 선택 가능)`,
      `Focus Areas (Multi-selectable)`,
      's_lesson_style.intensive_focus.title',
      ``,
      ``,
      ``,
      ``,
    ],
    detail: [`집중 교정`, `Focus Areas`, 's_lesson_style.intensive_focus.detail', ``, ``, ``, ``],
  },
  additional_request: {
    title: [
      `상세 요청 사항 (다중 선택 가능)`,
      `Additional Requests (Multi-selectable)`,
      's_lesson_style.additional_request.title',
      ``,
      ``,
      ``,
      ``,
    ],
    detail: [`상세 요청`, `Additional requests`, 's_lesson_style.additional_request.detail', ``, ``, ``, ``],
    placeholder: [
      `튜터에게 구체적으로 원하는 수업 방식을 영어로 남겨주세요!`,
      `Feel free to enter any other requests.`,
      's_lesson_style.additional_request.placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    other_request: [`추가 요청`, `Specific request`, 's_lesson_style.additional_request.other_request', ``, ``, ``, ``],
  },
}
export const s_portal_webinar = {
  lesson_enter_modal_title: [
    `웨비나 입장 안내`,
    `Enter Live Webinar`,
    's_portal_webinar.lesson_enter_modal_title',
    ``,
    ``,
    ``,
    ``,
  ],
  speed: [`재생 속도`, `Playback Speed`, 's_portal_webinar.speed', ``, ``, ``, ``],
  normal: [`(보통)`, `(normal)`, 's_portal_webinar.normal', ``, ``, ``, ``],
  lesson_enter_modal_content: [
    <>
      <Span size={12}>웨비나 시작 15분 전부터 입장하실 수 있습니다.</Span>
    </>,
    <>
      <Span size={12}>You can enter live webinars up to 15 minutes before the scheduled start time.</Span>
    </>,
    's_portal_webinar.lesson_enter_modal_content',
    ``,
    ``,
    ``,
    ``,
  ],
  viewAll: [`전체보기`, `View All`, 's_portal_webinar.viewAll', ``, ``, ``, ``],
  clips: [`클립 목록`, `Clips`, 's_portal_webinar.clips', ``, ``, ``, ``],
  currentApply: (count) => [
    <>현재 신청 {count}명</>,
    <>{count} people RSVP-ed</>,
    's_portal_webinar.currentApply',
    ``,
    ``,
    ``,
    ``,
  ],
  totalAttendee: (total) => [
    <>전체 정원 {total}명</>,
    <>Up to {total} attendees</>,
    's_portal_webinar.totalAttendee',
    ``,
    ``,
    ``,
    ``,
  ],
  doc: {
    search: {
      placeholder: [
        `튜터명과 주제등을 검색하세요`,
        `Enter keyword(s) to search.`,
        's_portal_webinar.doc.search.placeholder',
        ``,
        ``,
        ``,
        ``,
      ],
      label: [`검색하기`, `Search`, 's_portal_webinar.doc.search.label', ``, ``, ``, ``],
    },
  },
  register: [`자세히 보기`, `View detail`, 's_portal_webinar.register', ``, ``, ``, ``],
  lucky_draw_enroll: [`이벤트 참여`, `Enter Event`, 's_portal_webinar.lucky_draw_enroll', ``, ``, ``, ``],
  related_material: [`관련 교재`, `Related Materials`, 's_portal_webinar.related_material', ``, ``, ``, ``],
  related_article: [`관련 링크`, `Related Links`, 's_portal_webinar.related_article', ``, ``, ``, ``],
  related_video: [`관련 기사`, `Related Videos`, 's_portal_webinar.related_video', ``, ``, ``, ``],
  lesson_cancel_modal_title: [
    `링글 라이브 신청 취소`,
    `Cancel Ringle LIVE RSVP`,
    's_portal_webinar.lesson_cancel_modal_title',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_cancel_modal_content: [
    `신청한 링글 라이브를 취소하시겠습니까?`,
    `Do you want to cancel Ringle LIVE RSVP?`,
    's_portal_webinar.lesson_cancel_modal_content',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_cancel_modal_subject: [`주제`, `Topic`, 's_portal_webinar.lesson_cancel_modal_subject', ``, ``, ``, ``],
  lesson_cancel_modal_time: [`시간`, `Time`, 's_portal_webinar.lesson_cancel_modal_time', ``, ``, ``, ``],
}
export const s_webinar = {
  like: [`찜하기`, `Save`, 's_webinar.like', ``, ``, ``, ``],
  apply_description: {
    li_1: [
      `구글 계정을 사용하시면 '내 구글 캘린더'에 자동으로 일정이 추가되며 웨비나 당일 1시간 전 앱 푸시 알림을 받아볼 수 있습니다.`,
      `If you RSVP using a gmail account, the webinar will be automatically added to your Google Calendar. Ringle App will also send you a reminder 1 hour before the session is scheduled to start.`,
      's_webinar.apply_description.li_1',
      ``,
      ``,
      ``,
      ``,
    ],
    li_2: [
      `링글 LIVE 수업 녹화 영상은 세션 종료 후 최대 7일 이내로 ‘[링글 홈] > [콘텐츠] > [웨비나]’에 업데이트됩니다.`,
      `Live webinars recordings are uploaded on Ringle's website and app under '[Contents] > [Webinar]' within 7 days from live streaming.`,
      's_webinar.apply_description.li_2',
      ``,
      ``,
      ``,
      ``,
    ],
    li_3: [
      `수업 녹화 영상 링크와 자료는 수업 후 2~3일 내로 링글 LIVE를 신청한 링글 계정 이메일로 공유드립니다.`,
      `The materials used during the webinar and the link to the webinar recording will be shared to the RSVPed email addresses within 2-3 days from live streaming.`,
      's_webinar.apply_description.li_3',
      ``,
      ``,
      ``,
      ``,
    ],
    li_4: [
      `링글 LIVE와 관련하여 문의사항이 있으시면, [홈페이지 1:1 문의창 > 링글 콘텐츠 > 웨비나] 를 통해 문의사항을 남겨주세요! (* 1:1 문의 : 홈페이지 화면 우측 하단 보라색 말풍선)`,
      `If you have any questions about Ringle LIVE, please leave a message via the 1:1 chat support at the bottom right of the Ringle homepage (click 'Ringle contents' > 'Webinars').`,
      's_webinar.apply_description.li_4',
      ``,
      ``,
      ``,
      ``,
    ],
    li_5: [
      `수업 녹화 영상과 자료는 연사의 요청에 따라 공개되지 않을 수 있습니다.`,
      `Some live webinar recordings and/or materials may not be released at the request of the speaker.`,
      's_webinar.apply_description.li_5',
      ``,
      ``,
      ``,
      ``,
    ],
    sign_up_now: [`링글 LIVE 안내`, `Ringle LIVE Info`, 's_webinar.apply_description.sign_up_now', ``, ``, ``, ``],
    sign_up_now_paid: [
      `링글 LIVE 안내`,
      `Ringle LIVE Info`,
      's_webinar.apply_description.sign_up_now_paid',
      ``,
      ``,
      ``,
      ``,
    ],
    li_1_paid: [
      `링글 유료 라이브의  베타 기간동안,  라이브 신청 및 결제는 전문 행사 플랫폼 이벤터스에서 진행됩니다.`,
      `During the paywall testing period, participants will be directed to EventUs website and complete their registration payments there.`,
      's_webinar.apply_description.li_1_paid',
      ``,
      ``,
      ``,
      ``,
    ],
    li_2_paid: [
      `신청 마감 안내 라이브 신청은 세션 시작 3시간 전 마감되며, 신청 마감 시간 이후 취소 및 환불은 불가합니다.`,
      `PLEASE NOTE: Registration ends three hours before all upcoming live events start. The same applies for the deadline to receive a full refund for your registration.`,
      's_webinar.apply_description.li_2_paid',
      ``,
      ``,
      ``,
      ``,
    ],
    li_3_paid: [
      `취소/환불 안내 취소 및 환불은 결제하신 이벤터스 페이지에서 진행하시면 되며, 관련 문의는 이벤터스 ‘호스트에게 문의하기’ 를 통해 문의하실 수 있습니다.`,
      `Find 'Contact the Host' on the EventUs registration page for further assistance in regard to cancellations and refunds.`,
      's_webinar.apply_description.li_3_paid',
      ``,
      ``,
      ``,
      ``,
    ],
    li_4_paid: [
      `참여 가능한 줌 링크는 세션 시작 1시간 전 이벤터스를 통해 남겨주신 문자 및 이메일을 통해 발송드립니다.`,
      `A meeting link will be sent to you via text or email an hour before the scheduled time of the event.`,
      's_webinar.apply_description.li_4_paid',
      ``,
      ``,
      ``,
      ``,
    ],
    li_5_paid: [
      `해당 라이브의 다시보기 링크는 구매하신 분께만 별도로 전송됩니다. 다만, 수업자료와 다시 보기 영상의 경우 수업의 성격에 따라 무료 공유가 제한될 수 있습니다.`,
      `The replay link for the event will only be accessible to the participants. Depending on the type of event, free sharing may be limited for the lesson materials and replay videos.`,
      's_webinar.apply_description.li_5_paid',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  apply_float: {
    download: [`자료 받기`, `Download Materials`, 's_webinar.apply_float.download', ``, ``, ``, ``],
    register: [`신청하기`, `RSVP`, 's_webinar.apply_float.register', ``, ``, ``, ``],
    completed: [`신청 완료`, `RSVP-ed`, 's_webinar.apply_float.completed', ``, ``, ``, ``],
    cancel: [`신청 취소`, `Cancel RSVP`, 's_webinar.apply_float.cancel', ``, ``, ``, ``],
    enter: [`입장하기`, `Enter`, 's_webinar.apply_float.enter', ``, ``, ``, ``],
    enterLong: [`입장하기`, `Enter Live Webinar`, 's_webinar.apply_float.enterLong', ``, ``, ``, ``],
    register_paid: (amount) => [
      <>신청하러 가기 ({amount}원)</>,
      <>register ({amount}KRW) </>,
      's_webinar.apply_float.register_paid',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  a_tutor_will_be_added_soon: [
    `튜터가 곧 추가될 예정입니다.`,
    `A tutor will be added soon.`,
    's_webinar.a_tutor_will_be_added_soon',
    ``,
    ``,
    ``,
    ``,
  ],
  tab_summary: [`개요`, `Overview`, 's_webinar.tab_summary', ``, ``, ``, ``],
  tab_tutor: (name) => [`${name}의 다른 웨비나`, `More webinars by ${name}`, 's_webinar.tab_tutor', ``, ``, ``, ``],
  introduce_of_out_tutor: [`Tutor`, `Tutor`, 's_webinar.introduce_of_out_tutor', ``, ``, ``, ``],
  search_result: [`웨비나 검색 결과`, `Search Results`, 's_webinar.search_result', ``, ``, ``, ``],
  no_result: [`😣  키워드에 맞는 검색 결과가 없습니다.`, `😣  No Result`, 's_webinar.no_result', ``, ``, ``, ``],
  search_webinar: [`웨비나 검색`, `Search`, 's_webinar.search_webinar', ``, ``, ``, ``],
  apply: [`웨비나 신청`, `RSVP`, 's_webinar.apply', ``, ``, ``, ``],
  need_login: [
    <>
      선택하신 웨비나를 보시려면 로그인이 필요합니다.
      <br />
      링글 회원에게만 제공되는 300+개의 무료 웨비나를 확인해보세요.
    </>,
    <>
      Please log in to watch the webinar you selected.
      <br />
      Check 300+ webinars hosted by Ringe's best tutors.
    </>,
    's_webinar.need_login',
    ``,
    ``,
    ``,
    ``,
  ],
  login_desc: [
    `로그인하고 무료로 링글의 콘텐츠를 경험해 보세요.`,
    `Sign in to access all of Ringle's content for free.`,
    's_webinar.login_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  login: [`로그인`, `Log in`, 's_webinar.login', ``, ``, ``, ``],
}
export const s_schedule_enter = {
  go: [`예약하기`, `Go`, 's_schedule_enter.go', ``, ``, ``, ``],
  purchase: [`수업권 구매하러 가기`, `Buy now`, 's_schedule_enter.purchase', ``, ``, ``, ``],
  challenge_book_title: (date) => [
    <>도전 기간: {date}</>,
    <>Challenge Period: {date}</>,
    's_schedule_enter.challenge_book_title',
    ``,
    ``,
    ``,
    ``,
  ],
  challenge_book_duration: (duration) => [
    <>도전 기간: {duration}일 (첫 수업 완료 후 시작)</>,
    <>Challenge Period: {duration} days (Countdown will start once you complete your first lesson)</>,
    's_schedule_enter.challenge_book_duration',
    ``,
    ``,
    ``,
    ``,
  ],
  challenge_book_content: [
    `해당 수업권 구매 시 추가 수업권이 제공됩니다.`,
    `If you buy the package, you will get extra two credits.`,
    's_schedule_enter.challenge_book_content',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_book_title: [
    <>[수업권 챌린지] 장기권 혜택으로 당일수업권 제공</>,
    <>Last-minute Credits</>,
    's_schedule_enter.lesson_book_title',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_book_content: [
    `매 정규수업권 4회 소진 시마다, 당일수업권 1회를 제공해 드립니다.`,
    `For every four completed lessons, you’ll get one Last-minute Credit that can be used for [Last-minute Lessons].`,
    's_schedule_enter.lesson_book_content',
    ``,
    ``,
    ``,
    ``,
  ],
  used_challenge: (used) => [`${used}회 달성`, `Achieved ${used}`, 's_schedule_enter.used_challenge', ``, ``, ``, ``],
  used: (used) => [`${used}회 사용`, `${used}`, 's_schedule_enter.used', ``, ``, ``, ``],
  received: (received) => [`${received}회 받음`, `Received ${received}`, 's_schedule_enter.received', ``, ``, ``, ``],
  available: [`남은 수업권`, `Remaining Lesson Credit(s)`, 's_schedule_enter.available', ``, ``, ``, ``],
  select: [`수업 선택`, `Select Lesson`, 's_schedule_enter.select', ``, ``, ``, ``],
  expired: [`수강 기간`, `Expires`, 's_schedule_enter.expired', ``, ``, ``, ``],
  expiredIn: (days) => [<>{days}일 남음</>, <>in {days} days</>, 's_schedule_enter.expiredIn', ``, ``, ``, ``],
  select_time_first: {
    heading: [`시간 먼저 선택`, `Sort by time`, 's_schedule_enter.select_time_first.heading', ``, ``, ``, ``],
    i_will_choose_a_time_slot_first: [
      <>원하는 수업 시간을 먼저 선택할 수 있습니다.</>,
      `See available tutors for selected time`,
      's_schedule_enter.select_time_first.i_will_choose_a_time_slot_first',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  select_tutor_first: {
    heading: [`튜터 먼저 선택`, `Sort by tutor`, 's_schedule_enter.select_tutor_first.heading', ``, ``, ``, ``],
    i_will_choose_a_tutor_first: [
      <>
        원하는 튜터를
        <br />
        먼저 선택할 수 있습니다.
      </>,
      `See available slots of selected tutors`,
      's_schedule_enter.select_tutor_first.i_will_choose_a_tutor_first',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  my_coupons: [`내 수업권`, `My Credits`, 's_schedule_enter.my_coupons', ``, ``, ``, ``],
  no_coupons: [
    `수업을 예약할 수 있는 수업권이 없습니다.`,
    `You have no lesson credit`,
    's_schedule_enter.no_coupons',
    ``,
    ``,
    ``,
    ``,
  ],
  min: [`분`, `min`, 's_schedule_enter.min', ``, ``, ``, ``],
  free: [`무료`, `Free`, 's_schedule_enter.free', ``, ``, ``, ``],
  regular_credits: [`정규수업권`, `Regular`, 's_schedule_enter.regular_credits', ``, ``, ``, ``],
  limited_window_credits: [
    `시간한정수업권`,
    `Limited window credits`,
    's_schedule_enter.limited_window_credits',
    ``,
    ``,
    ``,
    ``,
  ],
  makeup_credits: [`당일수업권`, `Last-minute`, 's_schedule_enter.makeup_credits', ``, ``, ``, ``],
  regular_tooltip: [
    `정규수업권 1회로 정규 시간 내 1:1 수업을 1회 자유롭게 이용가능하며, [당일수업] 예약에도 사용할 수 있습니다.`,
    `Regular Credits can be used to book any regular lessons. You can also book a lesson available in 24 hours with Regular Credits.`,
    's_schedule_enter.regular_tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  limited_tooltip: [
    `시간한정수업권은 정해진 시간대에 사용 가능한 수업권입니다. (새벽반: 오전 5시 ~ 오전 8시(KST), 심야반: 자정 00시 ~ 새벽 3시(KST))`,
    `Limited window credits can only be used for certain time slots.
Morning: Lessons starting between 5am & 7am (KST)
Midday: Lessons starting between 11am & 1pm (KST)
Late night: Lessons starting between 12am & 2am (KST)`,
    's_schedule_enter.limited_tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  makeup_tooltip: [
    `당일수업권이란 예약 시점 기준 24시간 이내에 시작하는 수업만 예약할 수 있는 수업권으로, [1:1 수업] > [예약] > [예약 가능한 당일수업]에서 사용하실 수 있습니다.`,
    `[Last-minute Credits] are lesson credits that can only be used to reserve [Lessons starting within 24 hours] from the time of reservation, and can be reserved at [1:1 Lessons] > [Book] > [Last-minute Lessons].`,
    's_schedule_enter.makeup_tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  selected_material: [
    `교재 > 내교재: 찜한 교재에 추가되었습니다.`,
    `Added to Favorites under Materials > My Materials`,
    's_schedule_enter.selected_material',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_unassigned_lessons = {
  n_left: (n) => [<>{n}개</>, <>{n} left</>, 's_unassigned_lessons.n_left', ``, ``, ``, ``],
  credit_choice: [`수업권 선택하기`, `Select Lesson Credit`, 's_unassigned_lessons.credit_choice', ``, ``, ``, ``],
  credits: {
    regular_credit: [`정규수업권`, `Regular Credit`, 's_unassigned_lessons.credits.regular_credit', ``, ``, ``, ``],
    makeup_credit: [`당일수업권`, `Last-minute Credit`, 's_unassigned_lessons.credits.makeup_credit', ``, ``, ``, ``],
    urgent_credit: [
      `긴급편성수업권`,
      `FastBook Lesson Credit`,
      's_unassigned_lessons.credits.urgent_credit',
      ``,
      ``,
      ``,
      ``,
    ],
    no_credits_available: [
      `사용할 수 있는 수업권이 없습니다.`,
      `No credits available`,
      's_unassigned_lessons.credits.no_credits_available',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  expires_in_n_days: (n) => [
    <>수강기간: {n}일</>,
    <>Expires in: {n} days</>,
    's_unassigned_lessons.expires_in_n_days',
    ``,
    ``,
    ``,
    ``,
  ],
  info: {
    title: [`예약 가능한 당일수업`, `Last-minute lessons available`, 's_unassigned_lessons.info.title', ``, ``, ``, ``],
    what_is_makeup_lesson: [
      `당일수업이란?`,
      `What are lessons available in 24 hours?`,
      's_unassigned_lessons.info.what_is_makeup_lesson',
      ``,
      ``,
      ``,
      ``,
    ],
    makeup_lesson_desc: [
      <>
        - 예약 시점 기준 24시간 이내에 시작하는 수업입니다. (타 수강생 취소 시 발생)
        <br />- 정규수업권으로 예약하는 경우 포인트가 추가 적립됩니다. (40분: ￦3,000P / 20분: ￦1,000P){' '}
      </>,
      `These are lessons that opened up due to last-minute cancellations by other students.`,
      's_unassigned_lessons.info.makeup_lesson_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    label_info: [`2. 라벨 설명`, `2. Label Description`, 's_unassigned_lessons.info.label_info', ``, ``, ``, ``],
    makeup_lesson_info: [
      `당일수업권으로 예약 가능한 24시간 이내 수업을 의미합니다.`,
      `Lessons available in 24 hours that can be booked with Last-minute Credits.`,
      's_unassigned_lessons.info.makeup_lesson_info',
      ``,
      ``,
      ``,
      ``,
    ],
    urgent_lesson_info: [
      `긴급편성수업권으로 2시간 이내 시작하는 수업을 예약할 수 있습니다.`,
      `Lesson starting in two hours or less can be booked using Fastbook Credit(s).`,
      's_unassigned_lessons.info.urgent_lesson_info',
      ``,
      ``,
      ``,
      ``,
    ],
    n_minute_lesson40: [
      `40분 수업입니다.`,
      `This is a 40-minute lesson.`,
      's_unassigned_lessons.info.n_minute_lesson40',
      ``,
      ``,
      ``,
      ``,
    ],
    n_minute_lesson20: [
      `20분 수업입니다.`,
      `This is a 20-minute lesson.`,
      's_unassigned_lessons.info.n_minute_lesson20',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  inform: {
    topic_of_this_lesson_will_be: (topic) => [
      <>
        <b>{topic}</b> 교재로 진행됩니다.
      </>,
      <>
        This lesson will be on <strong>{topic}</strong>.
      </>,
      's_unassigned_lessons.inform.topic_of_this_lesson_will_be',
      ``,
      ``,
      ``,
      ``,
    ],
    available_lessons: [
      `1. 당일수업이란?`,
      `1. What is a Last-minute Lesson?`,
      's_unassigned_lessons.inform.available_lessons',
      ``,
      ``,
      ``,
      ``,
    ],
    available_lessons_count: (n) => [
      <>예약 가능한 당일수업 ({n})</>,
      <>Last-minute lessons available for booking ({n}) </>,
      's_unassigned_lessons.inform.available_lessons_count',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  makeup_lesson: [`당일수업`, `Last-minute`, 's_unassigned_lessons.makeup_lesson', ``, ``, ``, ``],
  lessons_in_24: [`당일수업 예약`, `Book Last-minute Lesson`, 's_unassigned_lessons.lessons_in_24', ``, ``, ``, ``],
  noti: {
    title_notifications: [
      `알림 설정`,
      `Notification Settings`,
      's_unassigned_lessons.noti.title_notifications',
      ``,
      ``,
      ``,
      ``,
    ],
    get_noti_all: [
      `모든 튜터의 [당일수업] 업데이트 알림 받기`,
      `Notify me every time a lesson becomes available in 24 hours`,
      's_unassigned_lessons.noti.get_noti_all',
      ``,
      ``,
      ``,
      ``,
    ],
    get_noti_of_my_tutors_only: [
      `찜한 튜터의 [당일수업] 업데이트 알림 받기`,
      `Notify me only when my favorite tutor has a lesson available in 24 hours`,
      's_unassigned_lessons.noti.get_noti_of_my_tutors_only',
      ``,
      ``,
      ``,
      ``,
    ],
    set_off: [
      `[당일수업] 업데이트 알림 받지 않기`,
      `Turn notifications off`,
      's_unassigned_lessons.noti.set_off',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  lesson_in_24_hours: [
    `예약 가능한 당일수업`,
    `Last-minute Lessons`,
    's_unassigned_lessons.lesson_in_24_hours',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_in_24_description: [
    <>
      24시간 이내에 시작하는 수업을 예약할 수 있습니다.
      <br />
      정규/당일/긴급편성수업권으로 사용 가능합니다.
    </>,
    `You can book with Regular, Last-minute, or FastBook Lesson Credits.`,
    's_unassigned_lessons.lesson_in_24_description',
    ``,
    ``,
    ``,
    ``,
  ],
  register: [`신청하기`, `Book`, 's_unassigned_lessons.register', ``, ``, ``, ``],
  urgent_lesson: [
    `긴급편성수업권 사용 가능`,
    `Can be booked using Fastbook Credit(s).`,
    's_unassigned_lessons.urgent_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  credit_to_use: [`사용 수업권`, `Selected Credit`, 's_unassigned_lessons.credit_to_use', ``, ``, ``, ``],
  tutor: [`튜터`, `Tutor:`, 's_unassigned_lessons.tutor', ``, ``, ``, ``],
  confirm: {
    title_register_for_lessons: [
      `수업 신청`,
      `Book Lesson`,
      's_unassigned_lessons.confirm.title_register_for_lessons',
      ``,
      ``,
      ``,
      ``,
    ],
    registration_has_been_successful: [
      `수업이 신청되었습니다.`,
      `Your booking has been submitted`,
      's_unassigned_lessons.confirm.registration_has_been_successful',
      ``,
      ``,
      ``,
      ``,
    ],
    has_failed: [
      `수업 신청에 실패하였습니다.`,
      `Your booking has failed.`,
      's_unassigned_lessons.confirm.has_failed',
      ``,
      ``,
      ``,
      ``,
    ],
    no_available_credit: [
      `사용 가능한 수업권이 없습니다.`,
      `We're sorry - your booking didn't go through. Please try again.`,
      's_unassigned_lessons.confirm.no_available_credit',
      ``,
      ``,
      ``,
      ``,
    ],
    ok: [`확인`, `Ok`, 's_unassigned_lessons.confirm.ok', ``, ``, ``, ``],
  },
  warning: {
    check_if_you_agree: [
      `유의사항`,
      `Terms and Conditions`,
      's_unassigned_lessons.warning.check_if_you_agree',
      ``,
      ``,
      ``,
      ``,
    ],
    not_restore_if_you_cancel: [
      `당일수업은 수업 취소 및 노쇼 시 수업권이 차감됩니다.`,
      `The credit used for booking the last-minute lesson will not be returned even if you cancel or miss the lesson.`,
      's_unassigned_lessons.warning.not_restore_if_you_cancel',
      ``,
      ``,
      ``,
      ``,
    ],
    cannot_change_tutor_or_time: [
      `당일수업은 튜터 및 시간 변경이 불가합니다.`,
      `You cannot change the tutor or time slot for last-minute lessons.`,
      's_unassigned_lessons.warning.cannot_change_tutor_or_time',
      ``,
      ``,
      ``,
      ``,
    ],
    auto_matching_after_tutor_canceled: [
      `당일수업의 튜터는 상황에 따라 변경될 수 있습니다.`,
      `The tutor for the last-minute lesson is subject to change.`,
      's_unassigned_lessons.warning.auto_matching_after_tutor_canceled',
      ``,
      ``,
      ``,
      ``,
    ],
    use_a_regular_credit: (credit) => [
      <>해당 수업은 정규수업권으로 신청됩니다. (+{credit} 포인트가 추가됩니다.)</>,
      <>This lesson will use a regular lesson credit. +${financial(credit / DOLLARS_TO_KRW)}P will be added.</>,
      's_unassigned_lessons.warning.use_a_regular_credit',
      ``,
      ``,
      ``,
      ``,
    ],
    use_a_regular_credit_mulcam: [
      <>해당 수업은 정규수업권으로 신청됩니다.</>,
      <>This lesson will use a regular lesson credit.</>,
      's_unassigned_lessons.warning.use_a_regular_credit_mulcam',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  has_not_unassigned_lessons: [
    `현재 예약 가능한 당일수업이 없습니다.`,
    `Sorry, there are currently no lessons available within 24 hours.`,
    's_unassigned_lessons.has_not_unassigned_lessons',
    ``,
    ``,
    ``,
    ``,
  ],
  remaining_time: (n) => [
    <>{n}분 후 수업 시작</>,
    <>Lesson begins in {n} minutes</>,
    's_unassigned_lessons.remaining_time',
    ``,
    ``,
    ``,
    ``,
  ],
  step_info_1: [`STEP 1. 수업 선택`, `STEP 1. Select Lesson`, 's_unassigned_lessons.step_info_1', ``, ``, ``, ``],
  step_info_2: [
    `STEP 2. 교재 선택`,
    `STEP 2. Select Lesson Material`,
    's_unassigned_lessons.step_info_2',
    ``,
    ``,
    ``,
    ``,
  ],
  step_info_3: [
    `STEP 3. 수업권 선택  & 예약 확인`,
    `STEP 3. Select Lesson Credit & Confirm Booking`,
    's_unassigned_lessons.step_info_3',
    ``,
    ``,
    ``,
    ``,
  ],
  regular_credit_choice_40_min: [
    `정규수업권으로 수업 예약 시 ￦3,000P 추가 적립`,
    `Additional $2.31P rewarded when booked using a regular credit.`,
    's_unassigned_lessons.regular_credit_choice_40_min',
    ``,
    ``,
    ``,
    ``,
  ],
  regular_credit_choice_20_min: [
    `정규수업권으로 수업 예약 시 ￦1,000P 추가 적립`,
    `Additional $0.77P rewarded when booked using a regular credit.`,
    's_unassigned_lessons.regular_credit_choice_20_min',
    ``,
    ``,
    ``,
    ``,
  ],
  regular_credit_choice_point_info_40_min: [
    <>
      <span className="text-primary">￦3,000P</span>가 추가 적립됩니다.
    </>,
    <>
      Additional <span className="text-primary">$2.31P</span> rewarded.
    </>,
    's_unassigned_lessons.regular_credit_choice_point_info_40_min',
    ``,
    ``,
    ``,
    ``,
  ],
  regular_credit_choice_point_info_20_min: [
    <>
      <span className="text-primary">￦1,000P</span>가 추가 적립됩니다.
    </>,
    <>
      Additional <span className="text-primary">$0.77P</span> rewarded.
    </>,
    's_unassigned_lessons.regular_credit_choice_point_info_20_min',
    ``,
    ``,
    ``,
    ``,
  ],
  exit: [`나가기`, `Leave`, 's_unassigned_lessons.exit', ``, ``, ``, ``],
  credit_select: [`수업권 선택`, `Select Lesson Credit`, 's_unassigned_lessons.credit_select', ``, ``, ``, ``],
  alarm_setting: [
    `예약 가능시 알림 받기`,
    `Notify me about updates`,
    's_unassigned_lessons.alarm_setting',
    ``,
    ``,
    ``,
    ``,
  ],
  ask_unassigned_alarm_turned_on: [
    `당일수업이 예약 가능할 때 앱 푸시를 받으시겠습니까?`,
    `Would you like to receive an app push notification when a last-minute lesson becomes available?`,
    's_unassigned_lessons.ask_unassigned_alarm_turned_on',
    ``,
    ``,
    ``,
    ``,
  ],
  already_unassigned_alarm_turned_on: [
    `이미 당일수업 업데이트 알림이 설정되어 있습니다.`,
    `You are already receiving notifications on last-minute lessons.`,
    's_unassigned_lessons.already_unassigned_alarm_turned_on',
    ``,
    ``,
    ``,
    ``,
  ],
  alrarm_turned_on_succ_toast: [
    `당일 수업 알림 신청이 완료되었습니다.`,
    `Notifications on last-minute lessons have been enabled.`,
    's_unassigned_lessons.alrarm_turned_on_succ_toast',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_schedule = {
  exit: [`나가기`, `Leave`, 's_schedule.exit', ``, ``, ``, ``],
  change_timezone: [`타임존 변경`, `Change time zone`, 's_schedule.change_timezone', ``, ``, ``, ``],
  valid_n_days: (n) => [
    <>수강 기간: {n}일 남음</>,
    <>Expires in: {n} days</>,
    's_schedule.valid_n_days',
    ``,
    ``,
    ``,
    ``,
  ],
  valid_from_to_days: (from, to) => [
    `수강기간 ${from} ~ ${to}일`,
    `Valid from ${from} to ${to}`,
    's_schedule.valid_from_to_days',
    ``,
    ``,
    ``,
    ``,
  ],
  unused_credits: [`미사용 수업권`, `Unused Lesson Credits`, 's_schedule.unused_credits', ``, ``, ``, ``],
  please_click_button_to_select_material: [
    `다음을 눌러 교재를 선택하세요`,
    ``,
    's_schedule.please_click_button_to_select_material',
    ``,
    ``,
    ``,
    ``,
  ],
  please_select_more_than_one_lesson: [
    `1개 이상 수업을 신청하세요`,
    `Please select more than one lesson.`,
    's_schedule.please_select_more_than_one_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  select_credit: [`수업권 선택`, `Select Credits`, 's_schedule.select_credit', ``, ``, ``, ``],
  please_select_the_time_and_tutors: [
    `시간과 튜터를 선택해 주세요.`,
    `Please select time and tutors.`,
    's_schedule.please_select_the_time_and_tutors',
    ``,
    ``,
    ``,
    ``,
  ],
  automatic_matching_for_tutor: [
    `링글 자동 매칭`,
    `Automatically match me to a tutor`,
    's_schedule.automatic_matching_for_tutor',
    ``,
    ``,
    ``,
    ``,
  ],
  ringle_automatic_matching: [
    `링글 자동 매칭`,
    `Auto matching`,
    's_schedule.ringle_automatic_matching',
    ``,
    ``,
    ``,
    ``,
  ],
  ringle_will_match_appropriate_tutor: [
    `예약 가능한 튜터 중, 평점이 높고 성실한 튜터와 자동으로 매칭해 드려요.`,
    `We'll find the best tutor available for you.`,
    's_schedule.ringle_will_match_appropriate_tutor',
    ``,
    ``,
    ``,
    ``,
  ],
  recommend: [`추천`, `Featured`, 's_schedule.recommend', ``, ``, ``, ``],
  please_select_material: [
    `교재를 선택해 주세요`,
    `Please select lesson materials.`,
    's_schedule.please_select_material',
    ``,
    ``,
    ``,
    ``,
  ],
  click_button_to_go_to_the_next_step: [
    `버튼을 누르면 다음 단계로 넘어갑니다.`,
    ``,
    's_schedule.click_button_to_go_to_the_next_step',
    ``,
    ``,
    ``,
    ``,
  ],
  previous: [`이전`, `Back`, 's_schedule.previous', ``, ``, ``, ``],
  next: [`다음`, `Next`, 's_schedule.next', ``, ``, ``, ``],
  do_you_want_to_cancel_lesson: [
    `이 수업을 취소하시겠습니까?`,
    `Do you want to cancel this lesson?`,
    's_schedule.do_you_want_to_cancel_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  the_material_is_not_selected: [
    `교재를 선택해주세요`,
    `Please select the lesson material you wish to use.`,
    's_schedule.the_material_is_not_selected',
    ``,
    ``,
    ``,
    ``,
  ],
  go_to_the_previous_step_cuz_no_lessons: [
    `신청한 수업이 없어서 이전 단계로 이동합니다`,
    `Since there is no booked lesson, you're moving to the previous step.`,
    's_schedule.go_to_the_previous_step_cuz_no_lessons',
    ``,
    ``,
    ``,
    ``,
  ],
  counter_of_selected_materials: (materialCount, completedCount) => [
    `교재 선택 : ${materialCount} / ${completedCount} 개`,
    `Selected materials: ${materialCount} / ${completedCount}`,
    's_schedule.counter_of_selected_materials',
    ``,
    ``,
    ``,
    ``,
  ],
  an_error_occurred_during_the_process: [
    `신청 과정에서 오류가 발생했습니다.`,
    `An error occurred during the process.`,
    's_schedule.an_error_occurred_during_the_process',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_register: [`신청하기`, `Book`, 's_schedule.btn_register', ``, ``, ``, ``],
  complete: [`신청 완료`, `Book Lesson`, 's_schedule.complete', ``, ``, ``, ``],
  after_cancel_auto_matching_unavailable: [
    `현재 가능한 튜터가 없어서, 취소 후 자동 매칭 기능은 제공되지 않습니다.`,
    `We couldn't match you to a tutor.`,
    's_schedule.after_cancel_auto_matching_unavailable',
    ``,
    ``,
    ``,
    ``,
  ],
  the_past_time: [
    `지난 시간 입니다.`,
    `Sorry, you cannot book a past lesson.`,
    's_schedule.the_past_time',
    ``,
    ``,
    ``,
    ``,
  ],
  you_cannot_register_within_26_hours: [
    `현재 시간 기준 26시간 안에는 신청 할 수 없습니다`,
    `Book lessons starting in 26h or later.`,
    's_schedule.you_cannot_register_within_26_hours',
    ``,
    ``,
    ``,
    ``,
  ],
  you_cannet_register_with_the_selected_credits: [
    `선택된 수업권으로는 신청하실 수 없습니다.`,
    `You cannot use selected lesson credit.`,
    's_schedule.you_cannet_register_with_the_selected_credits',
    ``,
    ``,
    ``,
    ``,
  ],
  you_are_out_of_credits_please_choose_another_credit: [
    `남은 수업권이 없습니다. 다른 수업권을 선택해 주세요.`,
    `You are out of lesson credits.`,
    's_schedule.you_are_out_of_credits_please_choose_another_credit',
    ``,
    ``,
    ``,
    ``,
  ],
  credit: [`수업권`, `Credit`, 's_schedule.credit', ``, ``, ``, ``],
  change_registration_mode: [`신청 방식 변경`, `Change sorting`, 's_schedule.change_registration_mode', ``, ``, ``, ``],
  select_time_first: [`시간 먼저 선택`, `Sort by time`, 's_schedule.select_time_first', ``, ``, ``, ``],
  select_tutor_first: [`튜터 먼저 선택`, `Sort by tutor`, 's_schedule.select_tutor_first', ``, ``, ``, ``],
  are_you_sure_you_want_to_delete_this_lesson: [
    `이 수업을 삭제하시겠습니까?`,
    `Are you sure you want to cancel this lesson?`,
    's_schedule.are_you_sure_you_want_to_delete_this_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_reserved: [`수업 예정`, `Booked`, 's_schedule.lesson_reserved', ``, ``, ``, ``],
  selected: [`선택 완료`, `Selected`, 's_schedule.selected', ``, ``, ``, ``],
  select_tutor: [`튜터 선택`, `Select a tutor`, 's_schedule.select_tutor', ``, ``, ``, ``],
  selectable: [`선택 가능`, `Available`, 's_schedule.selectable', ``, ``, ``, ``],
  unavailable: [`신청 불가`, `Unavailable`, 's_schedule.unavailable', ``, ``, ``, ``],
  reserve_closed: [`예약 마감`, <>Booking Closed</>, 's_schedule.reserve_closed', ``, ``, ``, ``],
  not_service_hours: [
    `서비스 운영 시간이 아닙니다`,
    `Ringle is not open yet.`,
    's_schedule.not_service_hours',
    ``,
    ``,
    ``,
    ``,
  ],
  view_profiles: [`프로필 보기`, `View profiles`, 's_schedule.view_profiles', ``, ``, ``, ``],
  please_register_for_lessons_by_select_the_desired_time_on_calender: [
    <>
      캘린더에서 원하시는 시간을 눌러
      <br />
      수업을 신청해 주세요.
    </>,
    `Please select time on calendar.`,
    's_schedule.please_register_for_lessons_by_select_the_desired_time_on_calender',
    ``,
    ``,
    ``,
    ``,
  ],
  not_able_to_fetch_tutors_info: [
    `튜터 정보를 가져오지 못했습니다.`,
    `We were not able to fetch this tutor's information.`,
    's_schedule.not_able_to_fetch_tutors_info',
    ``,
    ``,
    ``,
    ``,
  ],
  gender: [`성별`, `Gender`, 's_schedule.gender', ``, ``, ``, ``],
  accent: [`억양`, `Accent`, 's_schedule.accent', ``, ``, ``, ``],
  major: [`전공`, `Major`, 's_schedule.major', ``, ``, ``, ``],
  available_tutor: [`예약 가능`, `Available`, 's_schedule.available_tutor', ``, ``, ``, ``],
  recommended_tutor: [`추천 튜터`, `Featured`, 's_schedule.recommended_tutor', ``, ``, ``, ``],
  saved_tutor: [`찜한 튜터`, `Favorites`, 's_schedule.saved_tutor', ``, ``, ``, ``],
  tutors: [`튜터 수`, `Tutors`, 's_schedule.tutors', ``, ``, ``, ``],
  my_tutors: [`찜한 튜터`, `Favorites`, 's_schedule.my_tutors', ``, ``, ``, ``],
  tutors_available_in_this_time: [
    `선택한 시간에 수업 가능한 튜터들입니다.`,
    `Tutors available at selected time`,
    's_schedule.tutors_available_in_this_time',
    ``,
    ``,
    ``,
    ``,
  ],
  tutors_unavailable_in_this_time: [
    `선택한 시간에 수업 예약이 마감된 튜터들입니다.`,
    `Tutors unavailable at selected time`,
    's_schedule.tutors_unavailable_in_this_time',
    ``,
    ``,
    ``,
    ``,
  ],
  tutors_available_you_gave_a_good_rating: [
    <>
      추천 튜터는 최대 5명까지만 노출됩니다.
      <br />더 많은 튜터를 [예약 가능] 탭에서 확인해보세요!
    </>,
    <>
      Shows up to five featured tutors.
      <br />
      Please check out the [Available] tab to view more tutors!
    </>,
    's_schedule.tutors_available_you_gave_a_good_rating',
    ``,
    ``,
    ``,
    ``,
  ],
  tutors_youve_liked_that_are_available: [
    `선택한 시간에 수업 가능한 찜한 튜터들입니다.`,
    `Favorite tutors available at selected time`,
    's_schedule.tutors_youve_liked_that_are_available',
    ``,
    ``,
    ``,
    ``,
  ],
  add_more_tutor_my_tutor: [
    `찜한 튜터 리스트 입니다. 더 다양한 튜터를 찾아보세요!`,
    `Browse more Ringle tutors!`,
    's_schedule.add_more_tutor_my_tutor',
    ``,
    ``,
    ``,
    ``,
  ],
  add_more_tutor_available: [
    `수업 가능한 튜터 중, 내게 꼭 맞는 튜터를 찾아보세요!`,
    `Browse more available tutors to find your perfect fit!`,
    's_schedule.add_more_tutor_available',
    ``,
    ``,
    ``,
    ``,
  ],
  find_a_tutor: [`튜터 찾기`, `Search tutors`, 's_schedule.find_a_tutor', ``, ``, ``, ``],
  the_tutors_you_gave_a_good_raitings: [
    `링글에서 추천하는 신규 튜터들입니다.`,
    `Featured tutors`,
    's_schedule.the_tutors_you_gave_a_good_raitings',
    ``,
    ``,
    ``,
    ``,
  ],
  there_is_no_credit_available_you_can_register_for_lesson_after_purchase: [
    `신청 가능한 수업권이 없습니다. 수업권 구매 후 수업 신청하실 수 있습니다.`,
    `You have no lesson credits. Please book lessons after adding more credits.`,
    's_schedule.there_is_no_credit_available_you_can_register_for_lesson_after_purchase',
    ``,
    ``,
    ``,
    ``,
  ],
  the_selected_lesson_starts_within_48_hours: [
    `선택하신 수업은 48시간 이내에 시작하는 수업입니다. 튜터와의 시차 및 응답 속도에 따라 매칭되지 않을 가능성이 높습니다.`,
    `This lesson starts in less than 48 hours. Tutor may not confirm in time.`,
    's_schedule.the_selected_lesson_starts_within_48_hours',
    ``,
    ``,
    ``,
    ``,
  ],
  AM_hour: (h) => [`오전 ${h}시`, `AM ${h}`, 's_schedule.AM_hour', ``, ``, ``, ``],
  PM_hour: (h) => [`오후 ${h}시`, `PM ${h}`, 's_schedule.PM_hour', ``, ``, ``, ``],
  thirty_min: [`30분`, `:30`, 's_schedule.thirty_min', ``, ``, ``, ``],
  o_clock: [`시`, `:00`, 's_schedule.o_clock', ``, ``, ``, ``],
  select_available_tutor: [
    `튜터 직접 선택`,
    `Tutors available at selected time`,
    's_schedule.select_available_tutor',
    ``,
    ``,
    ``,
    ``,
  ],
  modal: {
    no_credit: [`수업권 미보유`, `No Credit`, 's_schedule.modal.no_credit', ``, ``, ``, ``],
    after_purchasing: [
      `수업권 구매 후 이용 가능합니다.`,
      `Available after purchasing lesson credits.`,
      's_schedule.modal.after_purchasing',
      ``,
      ``,
      ``,
      ``,
    ],
    purchase: [`구매하러 가기`, `Purchase`, 's_schedule.modal.purchase', ``, ``, ``, ``],
    title: [`추천 튜터 수업 안내`, `Lessons with featured tutors`, 's_schedule.modal.title', ``, ``, ``, ``],
  },
  get_matched_with_other_tutors: [
    `다른 튜터 매칭 받기`,
    `Match me with another tutor`,
    's_schedule.get_matched_with_other_tutors',
    ``,
    ``,
    ``,
    ``,
  ],
  check_other_times_for_the_selected_tutor: [
    `선택한 튜터의 다른 시간 확인하기`,
    `Show availability of this tutor`,
    's_schedule.check_other_times_for_the_selected_tutor',
    ``,
    ``,
    ``,
    ``,
  ],
  recovering_a_used_class_ticket_after_canceling: [
    `수업 취소 후 사용한 수업권 복구받기`,
    `Restore my lesson credit`,
    's_schedule.recovering_a_used_class_ticket_after_canceling',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_matching_options: [
    `튜터 매칭 옵션`,
    `Tutor matching options`,
    's_schedule.tutor_matching_options',
    ``,
    ``,
    ``,
    ``,
  ],
  check_class_reservation_history: [
    `수업 예약 내역 확인`,
    `Lesson Booking Details`,
    's_schedule.check_class_reservation_history',
    ``,
    ``,
    ``,
    ``,
  ],
  choose_how_to_handle_the_class_in_the_tutor_matching: [
    <>
      <Div textPrimary>수업 정보</Div>와{' '}
      <Div textPrimary mx4>
        튜터 매칭 옵션
      </Div>
      확인 후 예약을 완료해 주세요.
    </>,
    <>
      Please review{' '}
      <Div textPrimary mx4>
        the lesson information
      </Div>
      and{' '}
      <Div textPrimary ml4>
        tutor matching option
      </Div>
      .{' '}
    </>,
    's_schedule.choose_how_to_handle_the_class_in_the_tutor_matching',
    ``,
    ``,
    ``,
    ``,
  ],
  classes_for_which_reservations: [
    `예약 신청한 수업`,
    `Selected Lesson`,
    's_schedule.classes_for_which_reservations',
    ``,
    ``,
    ``,
    ``,
  ],
  class_reservation: [`수업 예약`, `Book lesson`, 's_schedule.class_reservation', ``, ``, ``, ``],
  book_another_class: [`다른 수업 예약하기`, `Book Another Lesson`, 's_schedule.book_another_class', ``, ``, ``, ``],
  set_up_a_class: [`수업 방식 설정하기`, `Select Lesson Style`, 's_schedule.set_up_a_class', ``, ``, ``, ``],
  class_reservation_results: [
    `수업 예약 결과`,
    `Lesson Booking Details`,
    's_schedule.class_reservation_results',
    ``,
    ``,
    ``,
    ``,
  ],
  recommended_to_prepare_a_lesson_before_class: [
    `학습 효과 및 맞춤 수업을 위해 수업 전에 예습 및 수업 방식 설정을 추천합니다.`,
    `Set 'Lesson Styles' before the lesson for the best learning experience and customized lesson.`,
    's_schedule.recommended_to_prepare_a_lesson_before_class',
    ``,
    ``,
    ``,
    ``,
  ],
  classes_that_have_been_booked: [
    `예약 완료한 수업`,
    `Lesson(s) Successfully Booked`,
    's_schedule.classes_that_have_been_booked',
    ``,
    ``,
    ``,
    ``,
  ],
  classes_that_have_not_been_booked: [
    `예약 실패한 수업`,
    `Booking Failure`,
    's_schedule.classes_that_have_not_been_booked',
    ``,
    ``,
    ``,
    ``,
  ],
  step3_heck_class_reservation_history: [
    `STEP 3. 수업 예약 내역 확인`,
    `STEP3. Check My Booked Lessons`,
    's_schedule.step3_heck_class_reservation_history',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_reservation_cancel: [
    `수업 예약 취소`,
    `Cancel reservation`,
    's_schedule.lesson_reservation_cancel',
    ``,
    ``,
    ``,
    ``,
  ],
  want_to_cancel_the_reservation: [
    <>
      현재까지 예약 신청중인 내역이 삭제됩니다.
      <br />
      진행중인 수업 예약을 취소하시겠습니까?
    </>,
    `Want to continue booking the lesson?`,
    's_schedule.want_to_cancel_the_reservation',
    ``,
    ``,
    ``,
    ``,
  ],
  keep_booking: [`예약 계속하기`, `Continue scheduling`, 's_schedule.keep_booking', ``, ``, ``, ``],
  choose_tutor_first: [
    `튜터를 먼저 선택하세요`,
    `Select the tutor first`,
    's_schedule.choose_tutor_first',
    ``,
    ``,
    ``,
    ``,
  ],
  available_times: [
    `캘린더에 신청 가능한 시간이 표시됩니다.`,
    `Available time slots will be displayed on the calendar`,
    's_schedule.available_times',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_and_time_selection: [
    `STEP 1. 튜터 및 시간 선택`,
    `STEP 1. Select tutor & time`,
    's_schedule.tutor_and_time_selection',
    ``,
    ``,
    ``,
    ``,
  ],
  left_count: [`회 남음`, `left`, 's_schedule.left_count', ``, ``, ``, ``],
  there_is_no_saved_tutor: [
    `찜한 튜터가 없습니다.`,
    `You haven't added any favorite tutors`,
    's_schedule.there_is_no_saved_tutor',
    ``,
    ``,
    ``,
    ``,
  ],
  choose_material: [
    `STEP 2. 교재 선택`,
    `STEP 2. Select lesson materials`,
    's_schedule.choose_material',
    ``,
    ``,
    ``,
    ``,
  ],
  class_reservations_are_not_available: [
    `수업 예약 가능한 수업권이 없습니다.`,
    `You have no lesson credit.`,
    's_schedule.class_reservations_are_not_available',
    ``,
    ``,
    ``,
    ``,
  ],
  go_to_home: [`홈으로 가기`, `Home`, 's_schedule.go_to_home', ``, ``, ``, ``],
  check_slots: [`스케쥴 보기`, `View slots`, 's_schedule.check_slots', ``, ``, ``, ``],
  additional_available_tutors: [
    `예약 가능한 튜터가 추가될 예정입니다.`,
    `More tutors will be available soon.`,
    's_schedule.additional_available_tutors',
    ``,
    ``,
    ``,
    ``,
  ],
  appreciate_if_you_could_wait_for: [
    <>
      오늘 기준 2주뒤 수업은 <Span fontBold>순차적으로 오픈될 예정</Span>입니다. 조금만 기다려 주시면 감사하겠습니다.
    </>,
    <>
      We would appreciate if you could wait for <Span fontBold>more lesson slots to be opened soon.</Span>
    </>,
    's_schedule.appreciate_if_you_could_wait_for',
    ``,
    ``,
    ``,
    ``,
  ],
  scheduling_progress: [`스케쥴 오픈 중`, `Scheduling in progress`, 's_schedule.scheduling_progress', ``, ``, ``, ``],
  temporaily_closed: [`임시 마감`, `Temporaily Closed`, 's_schedule.temporaily_closed', ``, ``, ``, ``],
  tutors_generally_open_their_lesson: [
    <>
      링글 튜터들의 경우, 주로 <Span fontBold>향후 2주까지 스케쥴을 오픈</Span>하고 있습니다.
    </>,
    <>
      Ringle <Span fontBold>tutors generally open their lesson</Span> avaliability no more than{' '}
      <Span bold>two weeks in advance.</Span>
    </>,
    's_schedule.tutors_generally_open_their_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_unavailable: [`수업 마감 안내`, `Tutor unavailable`, 's_schedule.tutor_unavailable', ``, ``, ``, ``],
  schedule_closed: (tutor_name) => [
    <>{tutor_name}튜터의 이번주 수업이 마감되었습니다.</>,
    <>{tutor_name}'s schedule is now closed for:</>,
    's_schedule.schedule_closed',
    ``,
    ``,
    ``,
    ``,
  ],
  closed_time: [`마감된 이번주 수업기간:`, ``, 's_schedule.closed_time', ``, ``, ``, ``],
  select_different_date: [
    `다른  기간의 스케쥴을 신청해주세요.`,
    `Please select a different date & time.`,
    's_schedule.select_different_date',
    ``,
    ``,
    ``,
    ``,
  ],
  slot_unavailable: [`수업 마감`, `Unavailable`, 's_schedule.slot_unavailable', ``, ``, ``, ``],
  select_tutor_matching_option: [
    `튜터와 매칭이 안 될 경우 어떻게 진행해 드릴까요?`,
    `What should we do if the tutor cancels?`,
    's_schedule.select_tutor_matching_option',
    ``,
    ``,
    ``,
    ``,
  ],
  check_tutor_matching_option: [
    `튜터 매칭 옵션을 선택하고 수업 예약을 완료해주세요.`,
    `Please select the tutor matching option to complete your lesson booking.`,
    's_schedule.check_tutor_matching_option',
    ``,
    ``,
    ``,
    ``,
  ],
  final_step_for_booking: [
    `수업 예약 마지막 단계입니다.`,
    `This is the final step for booking the lesson.`,
    's_schedule.final_step_for_booking',
    ``,
    ``,
    ``,
    ``,
  ],
  select_credit_b2b: {
    b2b_title: (name, count) => [
      <>
        {name} {count}회 수업권
      </>,
      <>
        {name} {count}Lessons
      </>,
      's_schedule.select_credit_b2b.b2b_title',
      ``,
      ``,
      ``,
      ``,
    ],
    b2b_vaild_n_day: (day, n) => [
      <>수강기간: {day}일 남음</>,
      <>Expires in: {n}days</>,
      's_schedule.select_credit_b2b.b2b_vaild_n_day',
      ``,
      ``,
      ``,
      ``,
    ],
    unused_b2b_credits_1: [
      `남은 정규 수업권`,
      `Available Credits`,
      's_schedule.select_credit_b2b.unused_b2b_credits_1',
      ``,
      ``,
      ``,
      ``,
    ],
    count: (count) => [<>{count}</>, <>{count}</>, 's_schedule.select_credit_b2b.count', ``, ``, ``, ``],
    attandance: (percent) => [
      <>출석률: {percent}%</>,
      <>Attendance Rate: {percent}%</>,
      's_schedule.select_credit_b2b.attandance',
      ``,
      ``,
      ``,
      ``,
    ],
    unused_b2b_credits_2: (count) => [
      <>남은 정규 수업권 {count}회</>,
      <>Available Credits {count}</>,
      's_schedule.select_credit_b2b.unused_b2b_credits_2',
      ``,
      ``,
      ``,
      ``,
    ],
    unused_b2b_makeup_credits: (count) => [
      <>남은 당일 수업권 {count}회</>,
      <>Last-minute Credits {count}</>,
      's_schedule.select_credit_b2b.unused_b2b_makeup_credits',
      ``,
      ``,
      ``,
      ``,
    ],
    attandance_notice: [
      `*출석률 반영을 위해 예약 가능한 당일수업에서 남은 당일 수업권을 사용해주세요.`,
      `*Please book lessons available within 24 hours using your last-minute credits to increase your attendance rate.`,
      's_schedule.select_credit_b2b.attandance_notice',
      ``,
      ``,
      ``,
      ``,
    ],
    attandance_notice_2: [
      `*출석률 반영을 위해 남은 당일 수업권을 사용해주세요.`,
      `*Please use your last-minute credits to increase your attendance rate.`,
      's_schedule.select_credit_b2b.attandance_notice_2',
      ``,
      ``,
      ``,
      ``,
    ],
    book_makeup_lesson: [
      `당일수업 예약하기`,
      `Make-up Lessons`,
      's_schedule.select_credit_b2b.book_makeup_lesson',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  refund: [`불만족 시 수업권 복구`, `Get Credit Back`, 's_schedule.refund', ``, ``, ``, ``],
  refund_notice: [
    `자동 매칭 튜터와의 수업 만족도가 낮을 경우, 수업권을 복구해드립니다. (튜터 평가 3점 이하 및 사유 제출, 월 2회 한정)`,
    `If you are not satified with the auto-match tutor, we'll return your lesson credit. (Rate as 3 stars or lower and must submit reason for low rating, limited to twice per month)`,
    's_schedule.refund_notice',
    ``,
    ``,
    ``,
    ``,
  ],
  recommended_notice: [
    <>
      링글이 추천하는 튜터와 수업해보세요!
      <br />
      #높은평점 #불만족시수업권복구
    </>,
    <>
      Learn from Ringle-recommended tutors!
      <br />
      #HighlyRated #GetCreditBack
    </>,
    's_schedule.recommended_notice',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_detail: [
    <>
      <span style={{ color: '#513CCD' }}>자세히 보기</span>
    </>,
    <>
      <span style={{ color: '#513CCD' }}>See details</span>
    </>,
    's_schedule.btn_detail',
    ``,
    ``,
    ``,
    ``,
  ],
  automatic_matching_notice: [
    <>
      <span style={{ color: '#513CCD' }}>튜터 매칭 옵션</span>에서 [다른 튜터 매칭 받기]를 선택하시면,
      <br />
      튜터와 매칭이 안될 경우 평점이 높은 튜터와 다시 매칭해 드려요.
    </>,
    <>
      If you select [Match me with another tutor] in{' '}
      <span style={{ color: '#513CCD' }}>the Tutor Matching Options</span>, we'll find another highly-rated tutor for
      you when a tutor matching falls through.
    </>,
    's_schedule.automatic_matching_notice',
    ``,
    ``,
    ``,
    ``,
  ],
  automatic_matching_desc: [
    <>
      <span style={{ color: '#FF3051' }}>*</span>매칭된 튜터와의 수업 만족도가 낮을 경우, 수업권을 복구해 드립니다.
    </>,
    <>
      <span style={{ color: '#FF3051' }}>*</span>We'll give your credit back if you are not satisfied with the assigned
      tutor.
    </>,
    's_schedule.automatic_matching_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  choose_automatic_matching: [
    <>
      <span style={{ color: '#513CCD' }}>수업 정보</span> 확인 후 예약을 완료해 주세요.
    </>,
    <>
      Please check <span style={{ color: '#513CCD' }}>the lesson details</span> and complete the booking.
    </>,
    's_schedule.choose_automatic_matching',
    ``,
    ``,
    ``,
    ``,
  ],
  tutors_unavailable_recommended: [
    <>
      해당 시간에 수업 가능한 추천 튜터가 없습니다.
      <br />
      [예약 가능] 탭을 확인해주세요.
    </>,
    <>
      There are no featured tutors available for the chosen time slot.
      <br />
      Please check out the [Available] tab.
    </>,
    's_schedule.tutors_unavailable_recommended',
    ``,
    ``,
    ``,
    ``,
  ],
  get_matched_with_other_tutors_label: [
    <div className="flex items-center">
      다른 튜터 매칭 받기{' '}
      <span className="bg-danger text-danger text-[14px] bg-opacity-10 rounded-[5px] px-[8px] ml-[8px]">추천</span>
    </div>,
    <div className="flex items-center">
      Match me with another tutor{' '}
      <span className="bg-danger text-danger text-[14px] bg-opacity-10 rounded-[5px] px-[8px] ml-[8px]">Featured</span>
    </div>,
    's_schedule.get_matched_with_other_tutors_label',
    ``,
    ``,
    ``,
    ``,
  ],
  ringle_tips: [`Ringle’s Tip`, `Ringle’s Tip`, 's_schedule.ringle_tips', ``, ``, ``, ``],
  tips_message_1: [
    `튜터의 수업이 빠르게 마감될 수 있습니다. 미리 예약해보세요.`,
    `Tutors' slots may fill up quickly!`,
    's_schedule.tips_message_1',
    ``,
    ``,
    ``,
    ``,
  ],
  tips_message_2: [
    `수업을 미리 예약하면 나와 더 잘 맞는 튜터를 만날 수 있어요.`,
    `Book lessons in advance to learn from tutors that suit you best.`,
    's_schedule.tips_message_2',
    ``,
    ``,
    ``,
    ``,
  ],
  tips_message_3: [
    `주 1회 이상 꾸준히 공부해야 영어 실력이 늘어요.`,
    `Study English at least once a week to enhance your English proficiency.`,
    's_schedule.tips_message_3',
    ``,
    ``,
    ``,
    ``,
  ],
  tips_message_4: [
    `3~4주 뒤 수업도 미리 예약해 보세요.`,
    `Go ahead and book your lessons for a month in advance.`,
    's_schedule.tips_message_4',
    ``,
    ``,
    ``,
    ``,
  ],
  tips_message_5: [
    `지금 한 달 수업을 미리 예약해 보세요.`,
    `Go ahead and book your lessons for a month in advance.`,
    's_schedule.tips_message_5',
    ``,
    ``,
    ``,
    ``,
  ],
  selected_credit_confirm: [
    `수업권 선택 & 예약 확인`,
    `Select Credit & Confirm`,
    's_schedule.selected_credit_confirm',
    `选择课程券 & 确认预约`,
    `選擇課程券 & 確認預約`,
    `レッスンチケットの選択 & 予約内容確認`,
    `Chọn Tiết học & Xác nhận`,
  ],
  select_tutor_time: [
    `튜터 및 시간 선택`,
    `Select Tutor and Tim`,
    's_schedule.select_tutor_time',
    `选择任课教师及时间`,
    `選擇任課教師及時間`,
    `チューターおよび時間帯選択`,
    `Chọn gia sư và thời gian`,
  ],
  add_tutor: [
    `튜터 추가`,
    `Add tutor`,
    's_schedule.add_tutor',
    `添加任课教师`,
    `新增任課教師`,
    `チューター追加選択`,
    `Thêm gia sư`,
  ],
}
export const s_history = {
  read: [`읽기`, `Reading`, 's_history.read', ``, ``, ``, ``],
  listen: [`듣기`, `Listening`, 's_history.listen', ``, ``, ``, ``],
  watch: [`보기`, `Watching`, 's_history.watch', ``, ``, ``, ``],
  only_ringle_logs_from_mar_1_2021_can_be_checked_: [
    `2021년 3월 30일부터의 학습 활동만 확인 가능합니다.`,
    `Ringle logs are available from Mar 30, 2021.`,
    's_history.only_ringle_logs_from_mar_1_2021_can_be_checked_',
    ``,
    ``,
    ``,
    ``,
  ],
  haven_t_done_ringle_yet: [
    <>아직 링글 콘텐츠를 경험하지 않으셨네요!</>,
    <>Hi, you haven't explored Ringle content yet!</>,
    's_history.haven_t_done_ringle_yet',
    ``,
    ``,
    ``,
    ``,
  ],
  haven_t_done_ringle_yet_2: [
    <>
      지금 <b>콘텐츠</b> 메뉴에서 다양한 <b>Live 동영상, VOD, 교재</b>를 확인하고 <b>수업</b>을 시작해 보세요.
    </>,
    <>
      Go to <b>Contents</b> to explore our archive of&nbsp;
      <b>webinars and materials</b> and start your study log.
    </>,
    's_history.haven_t_done_ringle_yet_2',
    ``,
    ``,
    ``,
    ``,
  ],
  haven_t_done_ringle_yet_3: [
    <>여러분이 살펴본 모든 콘텐츠와 수업 학습 데이터까지 모두 기록해서 알려드려요!</>,
    <></>,
    's_history.haven_t_done_ringle_yet_3',
    ``,
    ``,
    ``,
    ``,
  ],
  haven_t_done_ringle_yet_this_week_all: [
    <>
      이번 주는 아직 링글에서 <b>영어 공부를 시작하지 않으셨네요!</b>
    </>,
    <>You haven't studied English with Ringle this week!</>,
    's_history.haven_t_done_ringle_yet_this_week_all',
    ``,
    ``,
    ``,
    ``,
  ],
  only_steady_ringle_ing_can_improve_your_english_skills_all: [
    `지금, 영역별 학습을 시작하고 학습 활동을 쌓아보세요.`,
    `Start learning and add an entry to study log.`,
    's_history.only_steady_ringle_ing_can_improve_your_english_skills_all',
    ``,
    ``,
    ``,
    ``,
  ],
  haven_t_done_ringle_yet_this_week_read: [
    <>
      이번 주는 아직 링글에서 <b>읽기</b> 영역 학습을 시작하지 않으셨네요!
    </>,
    <>
      You haven't <b>read</b> any lesson materials this week!
    </>,
    's_history.haven_t_done_ringle_yet_this_week_read',
    ``,
    ``,
    ``,
    ``,
  ],
  only_steady_ringle_ing_can_improve_your_english_skills_read: [
    `교재 인사이트, 핵심 표현, 관련 기사 등을 읽고 읽기 영역 학습을 시작해 보세요.`,
    <>Read lesson materials to gain insights and learn new expressions.</>,
    's_history.only_steady_ringle_ing_can_improve_your_english_skills_read',
    ``,
    ``,
    ``,
    ``,
  ],
  haven_t_done_ringle_yet_this_week_listen: [
    <>
      이번 주는 아직 링글에서 <b>듣기</b> 영역 학습을 시작하지 않으셨네요!
    </>,
    <>
      You haven't practiced your <b>listening skills</b> this week!
    </>,
    's_history.haven_t_done_ringle_yet_this_week_listen',
    ``,
    ``,
    ``,
    ``,
  ],
  only_steady_ringle_ing_can_improve_your_english_skills_listen: [
    <>
      <b>교재 MP3, 웨비나, 수업 녹음파일</b> 등을 듣고 듣기 영역 학습을 시작해 보세요.
    </>,
    <>Listen to audio books, Webinars, and lesson recordings.</>,
    's_history.only_steady_ringle_ing_can_improve_your_english_skills_listen',
    ``,
    ``,
    ``,
    ``,
  ],
  haven_t_done_ringle_yet_this_week_write: [
    <>
      이번 주는 아직 링글에서 <b>쓰기</b> 영역 학습을 시작하지 않으셨네요!
    </>,
    <>
      You haven't practiced your <b>writing</b> this week!
    </>,
    's_history.haven_t_done_ringle_yet_this_week_write',
    ``,
    ``,
    ``,
    ``,
  ],
  only_steady_ringle_ing_can_improve_your_english_skills_write: [
    <>교재 토론질문에 대한 의견을 작성하고 쓰기 영역의 학습을 시작해 보세요.</>,
    <>Write your response to discussion questions!</>,
    's_history.only_steady_ringle_ing_can_improve_your_english_skills_write',
    ``,
    ``,
    ``,
    ``,
  ],
  haven_t_done_ringle_yet_this_week_speak: [
    <>
      이번 주는 아직 링글에서 <b>말하기</b> 영역 학습을 시작하지 않으셨네요!
    </>,
    <>
      You haven't practiced your <b>speaking</b> this week!
    </>,
    's_history.haven_t_done_ringle_yet_this_week_speak',
    ``,
    ``,
    ``,
    ``,
  ],
  only_steady_ringle_ing_can_improve_your_english_skills_speak: [
    <>
      <b>1:1 화상영어 수업</b>에서 영어 말하기를 연습하고 말하기 영역 학습을 시작해 보세요.
    </>,
    <>Book a lesson to practice speaking with a tutor.</>,
    's_history.only_steady_ringle_ing_can_improve_your_english_skills_speak',
    ``,
    ``,
    ``,
    ``,
  ],
  ringle_ing_in_mmm_days: (m, n) => [
    <Span className="pl-2" key={m}>
      {m.getMonth() + 1}월 링글 학습
      <Span primary className="pl-2">
        {n}일
      </Span>
    </Span>,
    <>
      <Span className="display-9 pl-2">Ringling in {format(m, 'MMM')}:</Span>
      <Span primary className="display-9 pl-2">
        {n} days
      </Span>
    </>,
    's_history.ringle_ing_in_mmm_days',
    ``,
    ``,
    ``,
    ``,
  ],
  this_week: [`이번 주로 가기`, `This week`, 's_history.this_week', ``, ``, ``, ``],
  packet: [`교재`, `Materials`, 's_history.packet', ``, ``, ``, ``],
  lesson: [`수업시간`, `Lesson`, 's_history.lesson', ``, ``, ``, ``],
  webinar: [`웨비나`, `Webinars`, 's_history.webinar', ``, ``, ``, ``],
  ok: [`확인`, `OK`, 's_history.ok', ``, ``, ``, ``],
  tutor: [`튜터`, `Tutor`, 's_history.tutor', ``, ``, ``, ``],
  minutes: [`분`, `-min`, 's_history.minutes', ``, ``, ``, ``],
  this_week_all_checked: [
    `이 주의 모든 학습 활동을 확인하셨습니다.`,
    `You've checked this week's log.`,
    's_history.this_week_all_checked',
    ``,
    ``,
    ``,
    ``,
  ],
  answer: [`답변`, `Response`, 's_history.answer', ``, ``, ``, ``],
  matching: [`매칭 중`, `We're finding a tutor for you`, 's_history.matching', ``, ``, ``, ``],
  answer_edit: [
    <>
      답변
      <br />
      (첨삭신청)
    </>,
    <>
      Response
      <br />
      (Request edits)
    </>,
    's_history.answer_edit',
    ``,
    ``,
    ``,
    ``,
  ],
  help_modal_title: [`학습 활동`, `Log`, 's_history.help_modal_title', ``, ``, ``, ``],
  help_modal_sub1: [
    `학습 활동 페이지에서는 링글에서 학습한 데이터를 읽기/듣기/쓰기/말하기 영역별로 기록하고 분석한 데이터를 제공합니다.`,
    `Log keeps record of your reading, listening, writing, and speaking practices.`,
    's_history.help_modal_sub1',
    ``,
    ``,
    ``,
    ``,
  ],
  help_modal_sub2: [`학습 활동 기록 항목`, ``, 's_history.help_modal_sub2', ``, ``, ``, ``],
  help_modal_sub3: [
    <>교재 인사이트, 핵심 표현, 관련 기사, 다른 링글러의 답변, 수업 노트, 튜터 피드백</>,
    <>Lesson materials, key expressions, linked articles, Ringlers' responses, class notes, and tutor's feedback</>,
    's_history.help_modal_sub3',
    ``,
    ``,
    ``,
    ``,
  ],
  help_modal_sub4: [
    <>교재 MP3, 핵심표현 듣기, 관련 영상, 웨비나 Live/VOD</>,
    <>Lesson materials audio book, linked videos, webinars</>,
    's_history.help_modal_sub4',
    ``,
    ``,
    ``,
    ``,
  ],
  help_modal_sub5: [
    <>교재 토론 질문작성</>,
    <>Submit responses to discussion questions</>,
    's_history.help_modal_sub5',
    ``,
    ``,
    ``,
    ``,
  ],
  help_modal_sub6: [<>수업 진행</>, <>Lessons</>, 's_history.help_modal_sub6', ``, ``, ``, ``],
  help_modal_reading: [<b>읽기:</b>, <b>Reading:</b>, 's_history.help_modal_reading', ``, ``, ``, ``],
  help_modal_listening: [<b>듣기:</b>, <b>Listening:</b>, 's_history.help_modal_listening', ``, ``, ``, ``],
  help_modal_writing: [<b>쓰기:</b>, <b>Writing:</b>, 's_history.help_modal_writing', ``, ``, ``, ``],
  help_modal_speaking: [<b>말하기:</b>, <b>Speaking:</b>, 's_history.help_modal_speaking', ``, ``, ``, ``],
  read_the_packet_again: [
    `교재 다시 읽기`,
    `Read the materials again`,
    's_history.read_the_packet_again',
    ``,
    ``,
    ``,
    ``,
  ],
  listen_to_the_mp3_again_: [
    `교재 다시 듣기`,
    `Listen to audiobook again`,
    's_history.listen_to_the_mp3_again_',
    ``,
    ``,
    ``,
    ``,
  ],
  continue_to_listent_to_the_mp3: [
    `교재 이어서 듣기`,
    `Continue listening`,
    's_history.continue_to_listent_to_the_mp3',
    ``,
    ``,
    ``,
    ``,
  ],
  clip: [`영상`, `Clip`, 's_history.clip', ``, ``, ``, ``],
  continue_to_watch_the_video: [
    `영상 이어서 보기`,
    `Continue watch clip`,
    's_history.continue_to_watch_the_video',
    ``,
    ``,
    ``,
    ``,
  ],
  watch_the_video_again: [`영상 다시 보기`, `Watch the clip again`, 's_history.watch_the_video_again', ``, ``, ``, ``],
  article: [`기사`, `News Article`, 's_history.article', ``, ``, ``, ``],
  reac_the_article_again: [
    `기사 다시 읽기`,
    `Read news articles again`,
    's_history.reac_the_article_again',
    ``,
    ``,
    ``,
    ``,
  ],
  expressions: [`핵심표현`, `Expressions`, 's_history.expressions', ``, ``, ``, ``],
  study_the_expressions_again: [
    `핵심표현 다시 학습하기`,
    `Study the expressions again`,
    's_history.study_the_expressions_again',
    ``,
    ``,
    ``,
    ``,
  ],
  expression_example: [`핵심표현 및 예문`, `Expression & Example`, 's_history.expression_example', ``, ``, ``, ``],
  listen_to_the_example_again: [
    `핵심표현 예문 다시 듣기`,
    `Listen to the example again`,
    's_history.listen_to_the_example_again',
    ``,
    ``,
    ``,
    ``,
  ],
  question: [`토론질문`, `Questions`, 's_history.question', ``, ``, ``, ``],
  prep_the_lesson: [`수업 예습하기`, `Lesson Prep`, 's_history.prep_the_lesson', ``, ``, ``, ``],
  listen_to_lesson_recording_again: [
    `수업 녹음 다시 듣기`,
    `Listen to lesson recording again`,
    's_history.listen_to_lesson_recording_again',
    ``,
    ``,
    ``,
    ``,
  ],
  review_the_lesson: [`수업 복습하기`, `Review Lesson`, 's_history.review_the_lesson', ``, ``, ``, ``],
  read_the_feedback_again: [
    `튜터 피드백 다시 확인하기`,
    `Read tutor feedback again`,
    's_history.read_the_feedback_again',
    ``,
    ``,
    ``,
    ``,
  ],
  continue_to_watch_the_clip: [
    `클립 이어서 보기`,
    `Continue watching the clip`,
    's_history.continue_to_watch_the_clip',
    ``,
    ``,
    ``,
    ``,
  ],
  watch_the_clip_again: [`클립 다시 보기`, `Watch the clip again`, 's_history.watch_the_clip_again', ``, ``, ``, ``],
  watch_the_webinar_again: [
    `웨비나 다시 보기`,
    `Watch the webinar again`,
    's_history.watch_the_webinar_again',
    ``,
    ``,
    ``,
    ``,
  ],
  canceled_lesson: [`취소된 수업`, `Cancelled Lesson`, 's_history.canceled_lesson', ``, ``, ``, ``],
  year_month: (n) => [
    <DateComponent format="yyyy년 MM월" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="MMM yyyy" locale="en-US">
      {n}
    </DateComponent>,
    's_history.year_month',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_curation = {
  source: [`출처`, `Source`, 's_curation.source', ``, ``, ``, ``],
  go_to_prev: [`홈으로 돌아가기`, `Home`, 's_curation.go_to_prev', ``, ``, ``, ``],
  example: [`예문`, `Example`, 's_curation.example', ``, ``, ``, ``],
  topic: [`주제`, `Topic`, 's_curation.topic', ``, ``, ``, ``],
  today_s_expression: [`오늘의 expression`, `Today's expression`, 's_curation.today_s_expression', ``, ``, ``, ``],
  want_to_read_the_packet_related_to_this_brief_: [
    `이 Daily Brief가 마음에 드셨다면?`,
    `View Lesson Materials related to this brief`,
    's_curation.want_to_read_the_packet_related_to_this_brief_',
    ``,
    ``,
    ``,
    ``,
  ],
  want_to_watch_the_webinar_related_to_this_brief_: [
    `이 Brief 관련 웨비나를 보고 싶다면?`,
    `View Webinars related to this brief`,
    's_curation.want_to_watch_the_webinar_related_to_this_brief_',
    ``,
    ``,
    ``,
    ``,
  ],
  go_to_read_the_packet: [
    `교재 읽으러 가기`,
    `Go to Lesson Materials`,
    's_curation.go_to_read_the_packet',
    ``,
    ``,
    ``,
    ``,
  ],
  go_to_watch_the_webinar: [`웨비나 보러 가기`, `Go to Webinars`, 's_curation.go_to_watch_the_webinar', ``, ``, ``, ``],
  this_week_s_briefs: [`이번 주의 Briefs`, `This Week's Briefs`, 's_curation.this_week_s_briefs', ``, ``, ``, ``],
  reading_now: [`지금 읽고 있음`, `Reading now`, 's_curation.reading_now', ``, ``, ``, ``],
  modal_body: (day, hour, min) => [
    <>
      이 Brief는 {day <= 0 ? '' : day + '일'} {hour <= 0 ? '' : hour + '시간'}&nbsp;
      {min <= 0 ? '' : min + '분'} 후에 확인할 수 있어요!
    </>,
    <>
      This Daily Brief will be availalbe in {day <= 0 ? '' : day + ' days'}&nbsp;
      {hour <= 0 ? '' : hour + ' hours'} {min <= 0 ? '' : min + ' mins'}.
    </>,
    's_curation.modal_body',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_lesson_edit = {
  do_you_want_to_exit: [
    `수업 시간 변경 페이지를 나가겠습니까?`,
    `Do you want to exit rescheduling?`,
    's_lesson_edit.do_you_want_to_exit',
    ``,
    ``,
    ``,
    ``,
  ],
  change_time_of_lesson: [`수업 시간 변경`, `Reschedule`, 's_lesson_edit.change_time_of_lesson', ``, ``, ``, ``],
  before_change: [`변경 전`, `Before change`, 's_lesson_edit.before_change', ``, ``, ``, ``],
  after_change: [`변경 후`, `After change`, 's_lesson_edit.after_change', ``, ``, ``, ``],
  title_cancel_lesson: [`수업 취소`, `Cancel Lesson`, 's_lesson_edit.title_cancel_lesson', ``, ``, ``, ``],
  cancel_lessons: [`수업 취소`, `Cancel Lesson`, 's_lesson_edit.cancel_lessons', ``, ``, ``, ``],
  select_the_reason_for_cancellation: [
    `수업 취소 이유를 선택해 주세요.`,
    `Select reason for cancellation.`,
    's_lesson_edit.select_the_reason_for_cancellation',
    ``,
    ``,
    ``,
    ``,
  ],
  change_lessons: [`수업 변경`, `Change Lessons`, 's_lesson_edit.change_lessons', ``, ``, ``, ``],
  select_the_category_you_want_to_change: [
    `변경을 원하시는 메뉴를 클릭해주세요.`,
    `Select category you want to change.`,
    's_lesson_edit.select_the_category_you_want_to_change',
    ``,
    ``,
    ``,
    ``,
  ],
  request_cancellation: [`수업 취소 요청`, `Cancel Lesson`, 's_lesson_edit.request_cancellation', ``, ``, ``, ``],
  change_lesson_materials: [
    `교재 변경`,
    `Change lesson materials`,
    's_lesson_edit.change_lesson_materials',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_change_lesson_material: [
    `교재 변경하기`,
    `Change lesson materials`,
    's_lesson_edit.btn_change_lesson_material',
    ``,
    ``,
    ``,
    ``,
  ],
  title_change_time: [`시간 변경`, `Reschedule`, 's_lesson_edit.title_change_time', ``, ``, ``, ``],
  time_has_been_changed: [
    `수업 시간이 변경되었습니다.`,
    `The lesson has been rescheduled.`,
    's_lesson_edit.time_has_been_changed',
    ``,
    ``,
    ``,
    ``,
  ],
  select_time_to_change: [
    `변경할 시간을 선택해 주세요`,
    `Select time.`,
    's_lesson_edit.select_time_to_change',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_changing_time: [`시간 변경하기`, `Reschedule`, 's_lesson_edit.btn_changing_time', ``, ``, ``, ``],
  not_prepared_for_the_lesson: [
    `수업을 들을 준비가 부족합니다.`,
    `I am not prepared for this lesson.`,
    's_lesson_edit.not_prepared_for_the_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  urgent_personal_matter: [
    `급한 개인 사정이 생겨서 수업을 취소합니다.`,
    `I have a conflict.`,
    's_lesson_edit.urgent_personal_matter',
    ``,
    ``,
    ``,
    ``,
  ],
  other_reasons: [`기타 사유`, `Others`, 's_lesson_edit.other_reasons', ``, ``, ``, ``],
  let_us_know_the_reason_for_cancelling: [
    `기타 취소 사유를 입력해주세요.`,
    `Type in your reason for cancellation.`,
    's_lesson_edit.let_us_know_the_reason_for_cancelling',
    ``,
    ``,
    ``,
    ``,
  ],
  automatic_matching_for_tutors: [
    `링글 자동 매칭`,
    `Automatically match me to a tutor`,
    's_lesson_edit.automatic_matching_for_tutors',
    ``,
    ``,
    ``,
    ``,
  ],
  select_the_materials_to_change: [
    `변경할 교재를 선택해 주세요`,
    `Select lesson materials.`,
    's_lesson_edit.select_the_materials_to_change',
    ``,
    ``,
    ``,
    ``,
  ],
  click_to_change_material: [
    `버튼을 누르면 교재가 변경 됩니다`,
    ``,
    's_lesson_edit.click_to_change_material',
    ``,
    ``,
    ``,
    ``,
  ],
  material_has_been_changed: [
    `교재가 변경되었습니다.`,
    `Your lesson materials have been changed.`,
    's_lesson_edit.material_has_been_changed',
    ``,
    ``,
    ``,
    ``,
  ],
  do_you_want_to_cancel_changing_materials: [
    `교재 변경을 취소하시겠습니까?`,
    `Do you want to cancel changing lesson materials?`,
    's_lesson_edit.do_you_want_to_cancel_changing_materials',
    ``,
    ``,
    ``,
    ``,
  ],
  select_material: [`교재 선택`, `Select`, 's_lesson_edit.select_material', ``, ``, ``, ``],
  cancel_material: [`선택 취소`, `Cancel`, 's_lesson_edit.cancel_material', ``, ``, ``, ``],
  info_change_material_more_than_1h: [
    `수업 예약 후 교재를 변경할 수 있습니다. 부담 없이 교재를 선택해주세요.`,
    `You can change the lesson material after you complete booking the lesson, so please go ahead and choose what interests you most.`,
    's_lesson_edit.info_change_material_more_than_1h',
    ``,
    ``,
    ``,
    ``,
  ],
  info_change_material_less_than_1h: [
    `수업 시작 1시간 이내인 경우 '자유주제' 또는 '튜터가 이전에 수업한 교재'만 선택 가능합니다.`,
    `If the lesson begins in less than one hour, you can either select 'Free Topic' or choose from 'Tutor's Previously Taught Materials.`,
    's_lesson_edit.info_change_material_less_than_1h',
    ``,
    ``,
    ``,
    ``,
  ],
  change_material_modal_title: [
    `교재 변경 확인`,
    `Change Lesson Material`,
    's_lesson_edit.change_material_modal_title',
    ``,
    ``,
    ``,
    ``,
  ],
  change_material_modal_content: [
    <>
      새로 선택한 교재로 변경하시겠습니까?
      <br />
      교재 변경 시 진행한 예습 목록은 초기화됩니다.
    </>,
    <>
      Would you like to change to the selected lesson material?
      <br />
      Changing the lesson material will also reset your lesson prep list.
    </>,
    's_lesson_edit.change_material_modal_content',
    ``,
    ``,
    ``,
    ``,
  ],
  change_material_modal_content_80_correction_info: [
    `80단어 첨삭 요청을 한 경우, 기존 요청 건은 취소가 되며 새로 첨삭을 신청해야 합니다.`,
    `Any previous request for 80-word response editing will be cancelled. If you wish to request 80-word response editing, please submit your response for the new lesson material.`,
    's_lesson_edit.change_material_modal_content_80_correction_info',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_cancel: [`취소`, `Cancel`, 's_lesson_edit.modal_cancel', ``, ``, ``, ``],
  modal_proceed: [`교재 변경`, `Confirm`, 's_lesson_edit.modal_proceed', ``, ``, ``, ``],
}
export const s_preparation = {
  exit: [`나가기`, `Exit`, 's_preparation.exit', ``, ``, ``, ``],
  prep: [`예습`, `Prep`, 's_preparation.prep', ``, ``, ``, ``],
  save: [`저장`, `Save`, 's_preparation.save', ``, ``, ``, ``],
  free_editing: [`80단어 답변 첨삭`, `80-word Response Editing`, 's_preparation.free_editing', ``, ``, ``, ``],
  correction_placeholder: [
    `수업시간 하루 전까지, 교정 받고 싶은 문장을 넣어주세요. 단 Confidential한 내용은 넣지 마세요!`,
    `Submit your response in 80 words or less at least 24 hours before the lesson starts. Please be sure not to share anything confidential!`,
    's_preparation.correction_placeholder',
    ``,
    ``,
    ``,
    ``,
  ],
  click_if_you_want_this_question: [
    `수업에서 다룰 질문을 선택해 주세요!`,
    `Select question to discuss in lesson`,
    's_preparation.click_if_you_want_this_question',
    ``,
    ``,
    ``,
    ``,
  ],
  show_only_to_me: [`튜터와 나만보기`, `Only visible to me & tutor`, 's_preparation.show_only_to_me', ``, ``, ``, ``],
  my_answer: [`내 답변`, `My Response`, 's_preparation.my_answer', ``, ``, ``, ``],
  my_answer_placeholder: [
    `질문에 대한 생각을 작성해 주세요. 작성하신 내용은 수업 직전까지 언제든 수정하실 수 있습니다.`,
    `Please write your response. You can revise it any time before the lesson.`,
    's_preparation.my_answer_placeholder',
    ``,
    ``,
    ``,
    ``,
  ],
  upload_your_file_to_share: [
    <>
      [Free Topic 수업 유의사항]
      <br />
      1) <Span className="font-bold">수업 시작 24시간 전</Span>까지 문서 업로드를 권장 드립니다.
      <br />
      2) <Span className="font-bold">문서 교정은 수업 시간에 실시간으로 진행</Span>됩니다.
      <br />
      3) 개인 정보가 노출될 수 있는 <Span className="font-bold">민감한 내용은 사전 수정 요청</Span>드립니다.
      <br />
      4) 수업 중 영문 교정을 위해 개인 자료를 업로드 시,{' '}
      <Span className="font-bold">20분 수업은 500 단어 (A4 1장), 40분 수업은 1000 단어 (A4 2장 분량)</Span> 분량을 권장
      드립니다.
      <br />
      5) Writing-focused 위주의 수업은 튜터 피드백 리포트가 제공되지 않습니다.{' '}
    </>,
    <>
      Please upload any materials you'd like to discuss in this class.
      <br />
      <br />
      1) Please upload your document at least 24 hours before the lesson.
      <br />
      2) Writing corrections will be conducted live during the lesson.
      <br />
      3) Please be sure to delete or redact any personal or confidential information.
      <br />
      4)When you upload your own material to be edited during your Free Topic lesson, please limit the word count to 500
      (one A4 page) for a 20-minute lesson, and 1,000 (two A4 pages) for a 40-minute lesson.
      <br />
      5) A separate tutor's feedback report is not provided after Writing Focused Lessons.
    </>,
    's_preparation.upload_your_file_to_share',
    ``,
    ``,
    ``,
    ``,
  ],
  correct_policy1: [
    `교정 요청 원문 및 교정된 문장은 익명으로 공개되어, 링글 수강생들의 학습용도로 사용될 예정입니다.
이에, 교정 요청 전 수강생의 신분을 노출시킬 수 있는 단어, 사내 기밀 내용이 담긴 문장이 있는지 살펴주시면 감사하겠습니다`,
    `Submitted document and tutor's edits may be used as an educational reference for other Ringlers. We will not reveal your name.
Please make sure that your document does not contain any personal or confidential information.`,
    's_preparation.correct_policy1',
    ``,
    ``,
    ``,
    ``,
  ],
  answer_history: [`지난 수업에 작성한 답변`, `My Previous Response`, 's_preparation.answer_history', ``, ``, ``, ``],
  correct_policy2: [
    <Fragment>
      <li>
        예습 답안교정 베타 서비스는 수업 당 80단어 (80 words) 까지 제공되며, 교정된 답은 수업 종료 후 피드백 레포트를
        통해 확인 가능합니다.
      </li>
      <li>
        교정 요청하신 원문 및 튜터가 제공한 교정본은 향후 익명으로 공개될 예정이며, Ringle 의 수강생분들의 영어 학습
        콘텐츠로 제공될 예정입니다.
      </li>
      <li>
        각 질문 별 답을 작성하시는 고객 분들께, 다른 수강생이 해당 질문에 대한 예습 시 남긴 교정 요청 원문 및 튜터가
        제공한 교정본을 익명으로 open 하여, 다른 수강생 분들께서 영어 공부 content 로 활용하실 수 있게 제공될
        예정입니다.
      </li>
      <li>
        이에, 수강생 분의 신분이 노출될 수 있는 내용, 기밀 유지가 필요한 내용에 대해서는 교정 요청을 하지 말아주시길
        부탁드립니다.
      </li>
      <li>
        Ringle 은 수강생 분들의 영어 학습 과정이, 다른 수강생 분들께도 영어 학습을 위한 content 로 제공될 수 있는 환경을
        조성하고자 합니다. 단, 모든 content 는 익명으로 제공하여 수강생 분들의 privacy 보호에 가장 큰 중점을 둘
        예정입니다.
      </li>
      <li>
        관련하여 의견 및 피드백 있으시면 언제든 contact@ringleplus.com 을 통해 연락주십시오. 저희가 바로 회신드리고
        소통하고, 의견을 적극 반영하겠습니다. 감사합니다.
      </li>
    </Fragment>,
    <Fragment>
      <li>
        The Response Editing Service (beta) provides free editing of 80 words per lesson. You can view the edits on the
        Feedback Report after the lesson.
      </li>
      <li>
        The submitted document and its edited version will be used for educational purposes without revealing the
        writer's name.
      </li>
      <li>
        Your answers and your tutor's corrections will be shown to other Ringlers that are answering the same questions
        to help with their learning process. All of the disclosed answers will be anonymous.
      </li>
      <li>
        Therefore, please do not submit a document that contains personal or confidential information for the editing
        service.
      </li>
      <li>
        Ringle aims to establish a learning atmosphere where your learning experience can help other Ringlers. However,
        we will protect your privacy by posting everything anonymously.
      </li>
      <li>
        If you have any suggestions or feedback regarding this, please do not hesitate to contact us at
        contact@ringleplus.com. We will respond as soon as possible, and do our best to reflect your opinions on our
        services. Thank you.
      </li>
    </Fragment>,
    's_preparation.correct_policy2',
    ``,
    ``,
    ``,
    ``,
  ],
  terms: [`이용약관`, `Terms of Use`, 's_preparation.terms', ``, ``, ``, ``],
  intro: [`인트로`, `Intro`, 's_preparation.intro', ``, ``, ``, ``],
  insight: [`인사이트`, `Insight`, 's_preparation.insight', ``, ``, ``, ``],
  clip: [`영상`, `Video`, 's_preparation.clip', ``, ``, ``, ``],
  article: [`기사`, `News`, 's_preparation.article', ``, ``, ``, ``],
  clipComplete: [
    `영상 1개 보기를 완료하셨습니다!`,
    `You watched one video.`,
    's_preparation.clipComplete',
    ``,
    ``,
    ``,
    ``,
  ],
  toastInsight: [
    `인사이트 읽기를 완료하셨습니다!`,
    `You completed reading Insight!`,
    's_preparation.toastInsight',
    ``,
    ``,
    ``,
    ``,
  ],
  listenMp3: [
    `MP3 듣기를 완료하셨습니다!`,
    `You completed listening to audiobook!`,
    's_preparation.listenMp3',
    ``,
    ``,
    ``,
    ``,
  ],
  readInsight: [`인사이트 읽기 완료`, `Done reading Insight`, 's_preparation.readInsight', ``, ``, ``, ``],
  keyQuestion: [`수업 질문`, `Questions`, 's_preparation.keyQuestion', ``, ``, ``, ``],
  keyQeustionSelected: (length) => [
    `수업 질문 ${length}개 선택하기를 완료하셨습니다!`,
    `You selected ${length} discussion questions!`,
    's_preparation.keyQeustionSelected',
    ``,
    ``,
    ``,
    ``,
  ],
  googleDocs: [`수업 노트`, `Lesson Notes`, 's_preparation.googleDocs', ``, ``, ``, ``],
  saved: [`저장됨`, `Saved`, 's_preparation.saved', ``, ``, ``, ``],
  you_can_only_select_up_to_three_questions: [
    `20분 수업의 경우 최대 3개의 질문만 선택 가능합니다.`,
    `You can only choose up to 3 questions for 20-minute lessons.`,
    's_preparation.you_can_only_select_up_to_three_questions',
    ``,
    ``,
    ``,
    ``,
  ],
  you_can_view_previous_answers: [
    `질문에 남기신 답변은 저장되어 다음 예습 시 열람하실 수 있습니다.`,
    `You'll be able to view your responses at Lesson Prep.`,
    's_preparation.you_can_view_previous_answers',
    ``,
    ``,
    ``,
    ``,
  ],
  your_upload_has_failed: [
    `서류 업로드가 정상적으로 되지 않았습니다. 다시 업로드해주세요`,
    `Your upload has failed. Please try again.`,
    's_preparation.your_upload_has_failed',
    ``,
    ``,
    ``,
    ``,
  ],
  the_size_of_file_is_less_than_10mb: [
    `크기가 10MB를 넘지 않는 파일만 업로드가 가능합니다.`,
    `You cannot upload files larger than 10MB.`,
    's_preparation.the_size_of_file_is_less_than_10mb',
    ``,
    ``,
    ``,
    ``,
  ],
  unsupported_file_format: [
    `지원되지 않는 파일 형식입니다. 확인후 다시 업로드해주세요.`,
    `This file type is not supported. Please check that your file is PPT, PPTX, DOC, DOCX, or PDF and re-upload.`,
    's_preparation.unsupported_file_format',
    ``,
    ``,
    ``,
    ``,
  ],
  upload_your_file_first: [
    `파일을 먼저 업로드해주세요!`,
    `Please first upload your file.`,
    's_preparation.upload_your_file_first',
    ``,
    ``,
    ``,
    ``,
  ],
  document_submission: [`자료 업로드`, `Upload`, 's_preparation.document_submission', ``, ``, ``, ``],
  delete_documents: [`자료 삭제`, `Delete document`, 's_preparation.delete_documents', ``, ``, ``, ``],
  reuploading: [`다른 자료 업로드`, `Re-upload`, 's_preparation.reuploading', ``, ``, ``, ``],
  download_template: [`템플릿 다운로드`, `Download Templates`, 's_preparation.download_template', ``, ``, ``, ``],
  displayed_after_file_upload: [
    `업로드한 자료가 없습니다.`,
    ``,
    's_preparation.displayed_after_file_upload',
    ``,
    ``,
    ``,
    ``,
  ],
  google_docs_is_being_generated: [
    `Google Docs 생성 중입니다.`,
    `Generating Google Docs...`,
    's_preparation.google_docs_is_being_generated',
    ``,
    ``,
    ``,
    ``,
  ],
  if_no_screen: [
    <>
      업로드한 자료가 보이지 않으면 새로고침해주세요. <br />
      직접 교정받고 싶은 내용은 수업 노트에 붙여보세요.
    </>,
    `If the material doesn't show up, please try refreshing the page.`,
    's_preparation.if_no_screen',
    ``,
    ``,
    ``,
    ``,
  ],
  other_people_ans: [`다른 링글러들의 답변`, `Ringlers’ Response`, 's_preparation.other_people_ans', ``, ``, ``, ``],
  share_answer: [
    `선택 해제시 다른 수강생에게 내 답변이 공유됩니다.`,
    `If you deselect, other Ringlers can view your response.`,
    's_preparation.share_answer',
    ``,
    ``,
    ``,
    ``,
  ],
  select_question: [
    `수업 질문으로 선택하기`,
    `Select question to discuss in lesson`,
    's_preparation.select_question',
    ``,
    ``,
    ``,
    ``,
  ],
  upload_material: [
    <>
      수업에서 다루고 싶은 자료를 업로드 해보세요.
      <br />
      직접 교정받고 싶은 내용은 오른쪽 수업 노트에 붙여보세요.
    </>,
    <>
      Upload your lesson materials.
      <br />
      If you have a written sample that need proofreading, please try pasting it on Lesson Notes.
    </>,
    's_preparation.upload_material',
    ``,
    ``,
    ``,
    ``,
  ],
  no_saved_answer: [`저장된 답변이 없습니다.`, `No answer saved.`, 's_preparation.no_saved_answer', ``, ``, ``, ``],
  no_shared_answer: [
    `다른 링글러들의 답변이 아직 없습니다. 첫번째 답변 작성자가 되어 보세요.`,
    `Be the first one to share a response to this question!`,
    's_preparation.no_shared_answer',
    ``,
    ``,
    ``,
    ``,
  ],
  open_note_new_tab: [
    `수업 노트 새 탭으로 열기 (1hr)`,
    `Open in a new tab (1hr)`,
    's_preparation.open_note_new_tab',
    ``,
    ``,
    ``,
    ``,
  ],
  upload_material_notice: [
    `10mb 미만 PPT, PPTX, DOC, DOCX, PDF 파일 형식만 지원하며, 1개 파일만 업로드 할 수 있습니다.`,
    `You can only upload one file with PPT, PPTX, DOC, DOCX, PDF format less than 10 MB.`,
    's_preparation.upload_material_notice',
    ``,
    ``,
    ``,
    ``,
  ],
  upload_youtube_link: [
    `수업에서 다루고 싶은 유튜브 영상의 링크를 공유해주세요.`,
    `Upload the YouTube link you want to discuss with the tutor.`,
    's_preparation.upload_youtube_link',
    ``,
    ``,
    ``,
    ``,
  ],
  notice_youtube_link: {
    one: [
      `분 미만의 유튜브 영상 1개의 링크만 공유할 수 있습니다.`,
      `You can only upload one YouTube video link less than ( ) minute.`,
      's_preparation.notice_youtube_link.one',
      ``,
      ``,
      ``,
      ``,
    ],
    two: [
      `수업 내용과 관련하여 튜터에게 남기고 싶은 말을 수업 노트에 남겨주세요.`,
      `Leave a message to the tutor on Lesson Notes.`,
      's_preparation.notice_youtube_link.two',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  upload_article_link: [
    `수업에서 다루고 싶은 아티클의 링크를 공유해주세요.`,
    `Upload the article link you want to discuss with the tutor.`,
    's_preparation.upload_article_link',
    ``,
    ``,
    ``,
    ``,
  ],
  notice_article_link: {
    one: [
      `단어 이내의 아티클 1개의 링크만 공유할 수 있습니다.`,
      `You can only upload one article link less than () words.`,
      's_preparation.notice_article_link.one',
      ``,
      ``,
      ``,
      ``,
    ],
    two: [
      `수업 내용과 관련하여 튜터에게 남기고 싶은 말을 수업 노트에 남겨주세요.`,
      `Leave a message to the tutor on Lesson Notes.`,
      's_preparation.notice_article_link.two',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  upload_your_link_to_share: {
    article: [
      <>
        [Free Topic 수업 유의사항]
        <br />
        1) <Span className="font-bold">수업 시작 24시간 전</Span>까지 아티클 링크 공유를 권장 드립니다.
        <br />
        2) 수업에서 <Span className="font-bold">다루기에 적절한 내용</Span>을 공유해 주세요. 부적절하거나 민감한 내용일
        경우 수업에서 다루지 못할 수 있습니다.
      </>,
      <>
        [Notice on Free Topic Lesson]
        <br />
        1) Upload the article link <Span className="font-bold"> at least 24 hours before the lesson begins.</Span>
        <br />
        2) Share <Span className="font-bold"> materials relevant to the lesson.</Span> Inappropriate or sensitive
        materials may not be discussed in the lesson.
      </>,
      's_preparation.upload_your_link_to_share.article',
      ``,
      ``,
      ``,
      ``,
    ],
    youtube: [
      <>
        [Free Topic 수업 유의사항]
        <br />
        1) <Span className="font-bold">수업 시작 24시간 전</Span>까지 유튜브 링크 공유를 권장 드립니다.
        <br />
        2) 수업에서 <Span className="font-bold">다루기에 적절한 내용</Span>을 공유해 주세요. 부적절하거나 민감한 내용일
        경우 수업에서 다루지 못할 수 있습니다.
      </>,
      <>
        [Notice on Free Topic Lesson]
        <br />
        1) Upload the Youtube link <Span className="font-bold"> at least 24 hours before the lesson begins.</Span>
        <br />
        2) Share <Span className="font-bold"> materials relevant to the lesson.</Span> Inappropriate or sensitive
        materials may not be discussed in the lesson.
      </>,
      's_preparation.upload_your_link_to_share.youtube',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn_delete_link: [`삭제`, ``, 's_preparation.btn_delete_link', ``, ``, ``, ``],
  btn_upload_link: [`링크 공유`, ``, 's_preparation.btn_upload_link', ``, ``, ``, ``],
  youtube_link_error: {
    title: [`유튜브 링크 형식 오류`, `YouTube link error`, 's_preparation.youtube_link_error.title', ``, ``, ``, ``],
    body: [
      `입력하신 링크는 올바른 유튜브 링크 형식이 아닙니다. 링크를 확인해주세요.`,
      `The uploaded YouTube link is in wrong form. Please check it again.`,
      's_preparation.youtube_link_error.body',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  please_connect_directly: [
    `공유한 링크의 내용이 여기서 바로  보이지 않으면,  새 창에서 직접 접속해서 아티클을 보면서 튜터와 수업해주세요.`,
    `If the uploaded link doesn't show up, please open a new window to share it with the tutor.`,
    's_preparation.please_connect_directly',
    ``,
    ``,
    ``,
    ``,
  ],
  upload_your_link: [
    `링크를 입력하거나 붙여 주세요.`,
    `Enter or paste the webpage link on Lesson Notes.`,
    's_preparation.upload_your_link',
    ``,
    ``,
    ``,
    ``,
  ],
  ringle_docs_is_being_generated: [
    `Ringle Docs 생성 중입니다.`,
    `Generating Ringle Docs...`,
    's_preparation.ringle_docs_is_being_generated',
    ``,
    ``,
    ``,
    ``,
  ],
  no_link: [`공유된 링크가 없습니다.`, `No link has been shared.`, 's_preparation.no_link', ``, ``, ``, ``],
  due_to_technical_issues: [
    `해당 수업은 테크 이슈로 구글닥으로 진행되며, 수업권이 보상 지급 됩니다.`,
    `Due to technical issues, this lesson will be conducted with Google Docs. As an apology, we have sent you an excusal credit.`,
    's_preparation.due_to_technical_issues',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_my_original = {
  saved_webinars: [`찜한 웨비나`, `Saved Webinars`, 's_my_original.saved_webinars', ``, ``, ``, ``],
  saved_packets: [`찜한 교재`, `My Materials`, 's_my_original.saved_packets', ``, ``, ``, ``],
  save_the_webinar_that_you_want_to_watch_again_: [
    <>
      다시 보고 싶은
      <br />
      웨비나를 찜해보세요.
    </>,
    <>Add your favorite webinars!</>,
    's_my_original.save_the_webinar_that_you_want_to_watch_again_',
    ``,
    ``,
    ``,
    ``,
  ],
  save_the_packet_that_you_want_to_read_again_: [
    <>
      다시 보고 싶은
      <br />
      교재를 찜해보세요.
    </>,
    <>Add your favorite materials!</>,
    's_my_original.save_the_packet_that_you_want_to_read_again_',
    ``,
    ``,
    ``,
    ``,
  ],
  no_packets_saved_yet: [
    `찜한 교재가 없습니다.`,
    `You haven't saved any materials.`,
    's_my_original.no_packets_saved_yet',
    ``,
    ``,
    ``,
    ``,
  ],
  no_packets: [`선택 가능한 교재가 없습니다.`, `No materials available`, 's_my_original.no_packets', ``, ``, ``, ``],
  save_daily_briefs: [
    <>
      다시 보고 싶은
      <br /> 데일리 브리프를 찜해보세요.
    </>,
    `Add your favorite daily briefs!`,
    's_my_original.save_daily_briefs',
    ``,
    ``,
    ``,
    ``,
  ],
  dailybrief: [`찜한 데일리 브리프`, `Favorite Daily Briefs`, 's_my_original.dailybrief', ``, ``, ``, ``],
  favorite_webinar: [`찜한 웨비나`, `Favorite Webinars`, 's_my_original.favorite_webinar', ``, ``, ``, ``],
}
export const s_invite = {
  recommender_count: [
    `어제 추천한 링글러의 수`,
    `Ringlers who have been recommended`,
    's_invite.recommender_count',
    ``,
    ``,
    ``,
    ``,
  ],
  recommendee_count: [
    `어제 추천으로 가입한 링글러의 수`,
    `Ringlers who signed up with recommendation`,
    's_invite.recommendee_count',
    ``,
    ``,
    ``,
    ``,
  ],
  people: [`명`, `People`, 's_invite.people', ``, ``, ``, ``],
  doc: {
    saved_clipboard: [
      <>
        초대 링크가 복사되었습니다.
        <br />
        친구에게 바로 링글을 선물하세요!
      </>,
      `The link has been copied. Invite your friend to Ringle now!`,
      's_invite.doc.saved_clipboard',
      ``,
      ``,
      ``,
      ``,
    ],
    thank_you: [
      `링글을 추천해주셔서 감사합니다.`,
      `Thank you for recommending Ringle.`,
      's_invite.doc.thank_you',
      ``,
      ``,
      ``,
      ``,
    ],
    fail_to_post: [`포스트가 올라가지 않았습니다.`, `Failed to post.`, 's_invite.doc.fail_to_post', ``, ``, ``, ``],
  },
  body_header: {
    title: [`친구 초대 이벤트`, `Referral Event`, 's_invite.body_header.title', ``, ``, ``, ``],
    subtitle: [
      `무제한 포인트 적립 & 최대 20회 수업권 혜택`,
      `Unlimited points & max 20 credits`,
      's_invite.body_header.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    description: [
      `아직도 링글러가 아닌 친구가 있다면 지금 바로 추천하세요!!`,
      `Recommend Ringle to your friend!!`,
      's_invite.body_header.description',
      ``,
      ``,
      ``,
      ``,
    ],
    subheader: [
      <>
        친구를 초대하고
        <br />
        최대 36만 원+α 선물 받으세요!
      </>,
      <>
        Invite your friends
        <br />
        and earn up to $310.34 & more!
      </>,
      's_invite.body_header.subheader',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  section1: {
    headline: [
      <>
        더 수준 높은 영어가 필요한
        <br />
        모든 분들을 위해
      </>,
      <>
        For everyone
        <br />
        who needs a more sophisticated English
      </>,
      's_invite.section1.headline',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  section2: {
    display: [
      <>
        친구에게
        <br />
        링글을 알리기만 해도!
      </>,
      <>
        Just
        <br />
        Tell your friend!
      </>,
      's_invite.section2.display',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [`스타벅스 (선착순 100명)`, `Starbucks (First 100 people)`, 's_invite.section2.title', ``, ``, ``, ``],
    body: [
      `*해쉬태그 #링글을 남겨주셔야 당첨을 확인할 수 있어요!`,
      `*Hashtag #Ringle to see if you've won!`,
      's_invite.section2.body',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  section3: {
    display: [
      <>
        친구가 링글러 되면
        <br />
        나에게 돌아오는 혜택!
      </>,
      <>
        Invite your friend to Ringle
        <br />
        and get rewards!
      </>,
      's_invite.section3.display',
      ``,
      ``,
      ``,
      ``,
    ],
    blue: [
      `체험만해도`,
      `That includes when your friend just takes a trial lesson`,
      's_invite.section3.blue',
      ``,
      ``,
      ``,
      ``,
    ],
    pink: [`링글러가 되면`, `If you friend becomes a Ringler`, 's_invite.section3.pink', ``, ``, ``, ``],
    description_box: [
      `5만포인트(최대3명) / 3만포인트(7명) / 이후 1만포인트 🍋`,
      `$43.10 Points (First 3 people)/ $25.86 Points (Next 7 people)/ $8.62 Points (Everyone else) 🍋`,
      's_invite.section3.description_box',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  section4: {
    display: [
      <>
        링글러가 된 친구에게
        <br />
        선물하는 혜택!
      </>,
      <>
        Send gifts to your friend
        <br />
        who just became a Ringler!
      </>,
      's_invite.section4.display',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  section5: {
    display: [
      <>
        친구가 링글을 오래할수록
        <br />더 커지는 혜택
      </>,
      <>
        Receive more gifts
        <br />
        if your friend stays with Ringle longer!
      </>,
      's_invite.section5.display',
      ``,
      ``,
      ``,
      ``,
    ],
    count: (n) => [<>{n}회</>, <>{n} times</>, 's_invite.section5.count', ``, ``, ``, ``],
    package: (n) => [
      <>{n}회 패키지 이상 결제시</>,
      <>If your friend purchases packages of more than {n} lessons</>,
      's_invite.section5.package',
      ``,
      ``,
      ``,
      ``,
    ],
    additional_coupon: [
      `수업권 추가 증정`,
      `You will get additional credits`,
      's_invite.section5.additional_coupon',
      ``,
      ``,
      ``,
      ``,
    ],
    max_coupon: [
      `최대 20회 수업권 증정`,
      `Get max 20 additional credits`,
      's_invite.section5.max_coupon',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  section6: {
    display: [
      <>
        추천 포인트는
        <br />
        이렇게 쓸 수 있어요!
      </>,
      <>
        You can get gifts
        <br />
        with your referral points&nbsp;
      </>,
      's_invite.section6.display',
      ``,
      ``,
      ``,
      ``,
    ],
    img_alt_1: [`링글 수업권 결제`, `Purchase Ringle lessons`, 's_invite.section6.img_alt_1', ``, ``, ``, ``],
    img_alt_2: [
      `스타벅스 카드로 교환`,
      `Exchange with Starbucks Gift Card`,
      's_invite.section6.img_alt_2',
      ``,
      ``,
      ``,
      ``,
    ],
    img_alt_3: [
      `신세계 상품권 교환`,
      `Exchange with Shinsegae Gift Card`,
      's_invite.section6.img_alt_3',
      ``,
      ``,
      ``,
      ``,
    ],
    img_alt_4: [`링글 수업권 교환`, `Exchange with Ringle lessons`, 's_invite.section6.img_alt_4', ``, ``, ``, ``],
  },
  section7: {
    title: [
      <>
        지금 친구에게
        <br />
        링글 수업권을 보내주세요!
      </>,
      <>
        Send your friend
        <br />a Ringle lesson right now!
      </>,
      's_invite.section7.title',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle: [
      `10초면 끝 ! 지금 바로 보내주세요 !`,
      `It only takes 10 seconds! Send right now!`,
      's_invite.section7.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  banner: {
    normal_title: (name) => [
      <>
        고객님만을 위한 <span className="text-[#F1E3FB]">특별한 혜택</span>
      </>,
      <>{name} has invited you to Ringle Teens</>,
      's_invite.banner.normal_title',
      ``,
      ``,
      ``,
      ``,
    ],
    sub_title: [
      <>지금 바로 무료 체험하고 5만원 추가 할인을 받아보세요!</>,
      <>Get a free trial and an extra $50 off!</>,
      's_invite.banner.sub_title',
      ``,
      ``,
      ``,
      ``,
    ],
    my_code: [`나의코드`, `My Code`, 's_invite.banner.my_code', ``, ``, ``, ``],
    share_link: [`초대링크 공유`, `Share invitation link`, 's_invite.banner.share_link', ``, ``, ``, ``],
    button: [<>무료 체험하러 가기</>, <>Get a free lesson</>, 's_invite.banner.button', ``, ``, ``, ``],
    button2: [
      <>100,000P 받고 링글 시작하기</>,
      <>Sign up & Earn $76 Points</>,
      's_invite.banner.button2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      <>
        오직 <span className="text-[#ffffff]">이 링크에서만 가능한 혜택입니다.</span>
      </>,
      <>
        This benefit is available <span className="text-[#ffffff]">only through current page.</span>
      </>,
      's_invite.banner.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  kakao: {
    title: [
      `친구 초대 - 링글로 영어 공부하는 친구가 초대했어요!`,
      `Personalized Ringle lessons--Just for me!`,
      's_invite.kakao.title',
      ``,
      ``,
      ``,
      ``,
    ],
    description: [
      `지금 초대장으로 가입하시면 바로 드려요 🙂`,
      `Experience 40 minutes of intellectual conversation`,
      's_invite.kakao.description',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  footer: {
    title: [
      <>10초만에 링글로 친구를 초대해 보세요!</>,
      <>Invite your friend to Ringle in 10 seconds!</>,
      's_invite.footer.title',
      ``,
      ``,
      ``,
      ``,
    ],
    copy: [`친구에게 초대장 보내기`, `Send Invitation`, 's_invite.footer.copy', ``, ``, ``, ``],
  },
  sign_up_after_benefit: [
    `회원가입하고 혜택받기`,
    `Sign up and get benefits`,
    's_invite.sign_up_after_benefit',
    ``,
    ``,
    ``,
    ``,
  ],
  item_list_1: [
    `링글에 대한 솔직한 수업 후기를 남겨주세요!`,
    `Tell us how you felt about the lesson!`,
    's_invite.item_list_1',
    ``,
    ``,
    ``,
    ``,
  ],
  item_list_2: [`인스타그램에서 #링글 #Ringle 태그`, `#Ringle on Instagram`, 's_invite.item_list_2', ``, ``, ``, ``],
  item_list_3: [
    `추천코드링크 공유와 함께 추천할 친구 @ 소환`,
    `@Tag a friend with an invitation code`,
    's_invite.item_list_3',
    ``,
    ``,
    ``,
    ``,
  ],
  join_ringle_and_start_to_excel: [
    `영어가 경쟁력이 되는 일대일 화상영어, 링글. 지금 시작하세요!`,
    `Join Ringle and start to excel in English!`,
    's_invite.join_ringle_and_start_to_excel',
    ``,
    ``,
    ``,
    ``,
  ],
  portal: {
    subtitle: [
      `‘똑똑한 영어’를 선물하는 방법`,
      `Share the smartest way to improve English fluency`,
      's_invite.portal.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    title1: [`링글을 친구에게`, `Invite Your Friends`, 's_invite.portal.title1', ``, ``, ``, ``],
    title2: [`소개해 주세요!`, `to Ringle`, 's_invite.portal.title2', ``, ``, ``, ``],
    benefit1: {
      title1: [
        `초대장을 받은 친구는`,
        `When your friend signs up with your invite code`,
        's_invite.portal.benefit1.title1',
        ``,
        ``,
        ``,
        ``,
      ],
      title2: (amount) => [
        <>회원가입하면 {amount}를 받아요</>,
        <>they’ll receive {amount} in points</>,
        's_invite.portal.benefit1.title2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: (amount) => [
        <>({amount}만원 이상 수업권 결제 시, 즉시 할인 가능)</>,
        <>(Can be applied towards purchases over {amount})</>,
        's_invite.portal.benefit1.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      caption: [
        `* 회원 가입 기준: ‘본인 인증’ 완료`,
        `Must complete identity verification to sign up`,
        's_invite.portal.benefit1.caption',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    benefit2: {
      title1: [
        `친구가 첫 수업을 완료하면`,
        `When your friend completes the first paid lesson`,
        's_invite.portal.benefit2.title1',
        ``,
        ``,
        ``,
        ``,
      ],
      title2: (amount) => [
        <>나도 {amount}를 받아요</>,
        <>you'll earn {amount} in points</>,
        's_invite.portal.benefit2.title2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `(SSG, 스타벅스 상품권, 링글 수업권 교환 가능)`,
        `(Can be exchanged to Ringle credits)`,
        's_invite.portal.benefit2.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      caption: (amount) => [
        <>* 첫 수업 기준: ‘{amount}만원 이상’ 유료 수업 완료</>,
        <>First purchased lesson must be from a package over {amount}</>,
        's_invite.portal.benefit2.caption',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    guide: {
      title: [`꼭 읽어주세요!`, `Please read the terms!`, 's_invite.portal.guide.title', ``, ``, ``, ``],
      list_1: [
        `신규 회원 가입한 대상에게만 포인트가 제공됩니다.`,
        `Points will be provided only for new Ringle students.`,
        's_invite.portal.guide.list_1',
        ``,
        ``,
        ``,
        ``,
      ],
      list_2: (amount, normal_price) => [
        <>
          포인트는 초대받은 친구에게는 가입 즉시 지급, 나에게는 친구가 실결제가 {amount}만원 이상 수업권 구매 후, 첫
          수업을 완료하는 시점에 지급됩니다.
        </>,
        <>
          Your friend will earn {amount} points right after they sign up. You will earn {amount} points when your friend
          completes the first lesson of the credits priced over {normal_price}.
        </>,
        's_invite.portal.guide.list_2',
        ``,
        ``,
        ``,
        ``,
      ],
      list_3: (amount, benefit) => [
        <>
          친구초대 혜택은 매 월 1명까지 {amount}, 이후 {benefit}이 제공됩니다
        </>,
        <>
          The {amount} referral points will be provided once a month. Then, earn {benefit} for each additional friend.
        </>,
        's_invite.portal.guide.list_3',
        ``,
        ``,
        ``,
        ``,
      ],
      list_4: [
        `제공받은 친구초대 포인트는 링글 수업권으로 교환할 수 있습니다.`,
        `Referral points can be exchanged to Ringle credits.`,
        's_invite.portal.guide.list_4',
        ``,
        ``,
        ``,
        ``,
      ],
      list_5: [
        `본인 계정 중복 초대 등 비정상적인 참여로 판단될 시 포인트가 지급되지 않거나 회수될 수 있습니다.`,
        `Inviting duplicate accounts may result in no points or points cancellation.`,
        's_invite.portal.guide.list_5',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    floating: {
      title: (N) => [
        <>{N}월 친구초대 최대 혜택</>,
        <>{N} Invite & Earn Offer</>,
        's_invite.portal.floating.title',
        ``,
        ``,
        ``,
        ``,
      ],
      btn1: [`초대장 보내기`, `Invite Friends`, 's_invite.portal.floating.btn1', ``, ``, ``, ``],
      btn2: [`링크 복사`, `Copy Link`, 's_invite.portal.floating.btn2', ``, ``, ``, ``],
    },
    event: {
      subtitle: [`링글 친구 초대 이벤트`, `Ringle Referral Event`, 's_invite.portal.event.subtitle', ``, ``, ``, ``],
      title: [
        <>
          지금, <span className="font-bold underline underline-offset-[10px]">링글 추천하기</span>
          <br />
          가장 좋은 타이밍!
        </>,
        <>
          Now is the best time
          <br />
          to <span className="font-bold underline underline-offset-[10px]">invite your friends!</span>
        </>,
        's_invite.portal.event.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        <>
          초대하는 사람, 초대 받은 사람
          <br />
          모두에게 좋은 링글 친구 초대 이벤트
          <br />
          <span className="underline text-h4 font-bold underline-offset-[6px]">오직 3주간의 특별한 기회</span>를 놓치지
          마세요!
        </>,
        <>
          Don't miss out on this
          <span className="underline text-h4 font-bold underline-offset-[6px]"> special chance</span>
          <br />
          to get amazing rewards
          <br />
          for both you and your friends!
        </>,
        's_invite.portal.event.desc',
        ``,
        ``,
        ``,
        ``,
      ],
      date: [
        `22.08.23 (화) - 09.08 (목)`,
        `August 23 (Tue) - September 8 (Thu), 2022`,
        's_invite.portal.event.date',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        <>
          초대하는 사람, 초대 받은 사람
          <br />
          모두에게 좋은 링글 친구 초대 이벤트
        </>,
        <>
          Get amazing rewards
          <br />
          for both you and your friends!
        </>,
        's_invite.portal.event.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      section_subtitle_1: [
        `더 많이 알릴수록`,
        `The More You Reach Out`,
        's_invite.portal.event.section_subtitle_1',
        ``,
        ``,
        ``,
        ``,
      ],
      section_title_1: [
        `쌓이는 나의 혜택`,
        `The More Rewards
You Get!`,
        's_invite.portal.event.section_title_1',
        ``,
        ``,
        ``,
        ``,
      ],
      section_list_1_subtitle: [
        <>
          내 초대 코드로 가입한 친구가 <span className="text-primary">첫 수업을 완료</span> 하면
        </>,
        <>
          When your friend <span className="text-primary">completes the first paid lesson*</span>
        </>,
        's_invite.portal.event.section_list_1_subtitle',
        ``,
        ``,
        ``,
        ``,
      ],
      section_list_1_title: [
        `5만 링글 포인트를 드려요.`,
        `You get $38p referral points`,
        's_invite.portal.event.section_list_1_title',
        ``,
        ``,
        ``,
        ``,
      ],
      section_list_1_desc: [
        `(SSG, 스타벅스 상품권, 링글 수업권 교환 가능)`,
        `(Exchange for SSG/Starbucks or Ringle credits)`,
        's_invite.portal.event.section_list_1_desc',
        ``,
        ``,
        ``,
        ``,
      ],
      section_desc_1: [
        `* 첫 수업 완료 기준 : 30만원 이상 수업권 구매 후 수업 완료`,
        `* First lesson completed from a package worth $231+`,
        's_invite.portal.event.section_desc_1',
        ``,
        ``,
        ``,
        ``,
      ],
      section_subtitle_2: [
        `내 친구에게도`,
        `Give Your Friends`,
        's_invite.portal.event.section_subtitle_2',
        ``,
        ``,
        ``,
        ``,
      ],
      section_title_2: [
        `가장 필요한 혜택`,
        `What They Need
the Most`,
        's_invite.portal.event.section_title_2',
        ``,
        ``,
        ``,
        ``,
      ],
      section_list_2_subtitle: [
        `초대 코드로 회원 가입하는 즉시`,
        `When your friends sign up`,
        's_invite.portal.event.section_list_2_subtitle',
        ``,
        ``,
        ``,
        ``,
      ],
      section_list_2_title: [
        `5만 링글 포인트를 받아요.`,
        `They get $38p referral points`,
        's_invite.portal.event.section_list_2_title',
        ``,
        ``,
        ``,
        ``,
      ],
      section_desc_2: [
        `* 30만원 이상 수업권 구매 시 사용 가능`,
        `* Can be used toward packages worth $231+`,
        's_invite.portal.event.section_desc_2',
        ``,
        ``,
        ``,
        ``,
      ],
      terms_title: [`유의 사항`, `Terms and Conditions`, 's_invite.portal.event.terms_title', ``, ``, ``, ``],
      terms_subtitle: [
        `[포인트 지급 및 사용 안내]`,
        `[Issuance and use of points]`,
        's_invite.portal.event.terms_subtitle',
        ``,
        ``,
        ``,
        ``,
      ],
      terms_1: [
        `초대받은 친구에게는 가입 즉시 포인트가 지급됩니다.`,
        `Your friend will earn referral points right after they sign up.`,
        's_invite.portal.event.terms_1',
        ``,
        ``,
        ``,
        ``,
      ],
      terms_2: [
        `나에게는 친구가 실결제가 30만원 이상 수업권 구매 후, 첫 수업을 완료하는 시점에 지급됩니다.`,
        `You will earn referral points when your friend completes the first lesson after purchasing packages worth $230 or more excluding discounts or points.`,
        's_invite.portal.event.terms_2',
        ``,
        ``,
        ``,
        ``,
      ],
      terms_3: [
        `제공받은 친구 초대 포인트는 SSG, 스타벅스 상품권, 링글 수업권으로 교환할 수 있습니다.`,
        `Referral points can be exchanged for SSG or Starbucks gift cards or Ringle credits.`,
        's_invite.portal.event.terms_3',
        ``,
        ``,
        ``,
        ``,
      ],
      terms_4: [
        `친구 초대 혜택은 매 월 1명까지 ₩50,000, 이후 ₩10,000이 제공됩니다.`,
        `You can earn $38p referral points once a month when a friend signs up with your referral code and $8p referral points for each additional friend who signs up. `,
        's_invite.portal.event.terms_4',
        ``,
        ``,
        ``,
        ``,
      ],
      terms_5: [
        `본인 계정 중복 초대 등 비정상적인 참여로 판단될 시 포인트가 지급되지 않거나 회수될 수 있습니다.`,
        `Invalid participation, such as inviting duplicate accounts, may result in no points or points cancellation.`,
        's_invite.portal.event.terms_5',
        ``,
        ``,
        ``,
        ``,
      ],
      terms_6: [
        `지급된 포인트의 유효기간은 30일입니다.`,
        `Points issued to your account for this event are valid for 30 days after the issue date.`,
        's_invite.portal.event.terms_6',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    dashboard: {
      friend: [
        `내 초대 코드로 가입한 친구`,
        `Friends who joined Ringle`,
        's_invite.portal.dashboard.friend',
        ``,
        ``,
        ``,
        ``,
      ],
      point: [`누적된 친구 초대 포인트`, `Referral points earned`, 's_invite.portal.dashboard.point', ``, ``, ``, ``],
      friend_count: (count) => [<>{count}명</>, <>{count}</>, 's_invite.portal.dashboard.friend_count', ``, ``, ``, ``],
      point_count: (point) => [<>{point}</>, <>{point}</>, 's_invite.portal.dashboard.point_count', ``, ``, ``, ``],
      friend_promo: [
        `이벤트 기간 중 초대로 가입한 친구`,
        `Friends who joined Ringle`,
        's_invite.portal.dashboard.friend_promo',
        ``,
        ``,
        ``,
        ``,
      ],
      point_promo: [
        `이벤트 기간 중 받은 친구 초대 포인트`,
        `Referral points earned`,
        's_invite.portal.dashboard.point_promo',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    referral: {
      code: (referralCode) => [
        <>내 초대 코드 : {referralCode}</>,
        <>My referral code : {referralCode}</>,
        's_invite.portal.referral.code',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    my_benefit: [`나의 혜택 보기`, `Rewards for You`, 's_invite.portal.my_benefit', ``, ``, ``, ``],
    friend_benefit: [`친구 혜택 보기`, `Rewards for Friends`, 's_invite.portal.friend_benefit', ``, ``, ``, ``],
    btn: {
      kakao_short: [`카카오톡 공유하기`, `Share via KakaoTalk`, 's_invite.portal.btn.kakao_short', ``, ``, ``, ``],
      kakao_long: [
        `카카오톡으로 초대장 공유하기`,
        `Share invite via KakaoTalk`,
        's_invite.portal.btn.kakao_long',
        ``,
        ``,
        ``,
        ``,
      ],
      link_short: [`초대장 공유하기`, `Copy link`, 's_invite.portal.btn.link_short', ``, ``, ``, ``],
      link_long: [`친구 초대장 링크 복사하기`, `Copy invite link`, 's_invite.portal.btn.link_long', ``, ``, ``, ``],
      kakao_long_promo: [
        `카카오톡으로 초대장 공유하기`,
        `Send invitation via KakaoTalk`,
        's_invite.portal.btn.kakao_long_promo',
        ``,
        ``,
        ``,
        ``,
      ],
      link_long_promo: [
        `초대장 링크 복사하기`,
        `Copy invite link`,
        's_invite.portal.btn.link_long_promo',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    share: {
      desc: [
        <>
          공유하기/복사하기를 클릭하면
          <br />내 초대 코드가 공유돼요!
        </>,
        <>
          Simply click the buttons below
          <br />
          to share your referral code!
        </>,
        's_invite.portal.share.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  bottom: {
    desc: [`첫 수업 0원 ∙ 무료 AI 레벨 진단`, ``, 's_invite.bottom.desc', ``, ``, ``, ``],
    under_button: [`최대 혜택 상담하기`, ``, 's_invite.bottom.under_button', ``, ``, ``, ``],
    button: [`무료 체험하기`, ``, 's_invite.bottom.button', ``, ``, ``, ``],
  },
}
export const s_lesson = {
  example: [`예문`, `Example`, 's_lesson.example', ``, ``, ``, ``],
  does_not_provide_expressions: [
    `해당 교재는 핵심 표현이 제공되지 않습니다.`,
    `This lesson material does not have expressions.`,
    's_lesson.does_not_provide_expressions',
    ``,
    ``,
    ``,
    ``,
  ],
  google_translator: [`구글 번역기`, `Google Translate`, 's_lesson.google_translator', ``, ``, ``, ``],
  translate: [`번역하기`, `Translate`, 's_lesson.translate', ``, ``, ``, ``],
}
export const s_evaluation = {
  thank_you: [
    `평가가 완료되었습니다. 감사합니다.`,
    `Your evaluation has been completed. Thank you.`,
    's_evaluation.thank_you',
    ``,
    ``,
    ``,
    ``,
  ],
  help: [`지원 요청`, `Help`, 's_evaluation.help', ``, ``, ``, ``],
}
export const s_review = {
  lesson_review: [`수업 후기`, `Lesson Reviews`, 's_review.lesson_review', ``, ``, ``, ``],
  customer_review: [`고객 후기`, `Testimonials`, 's_review.customer_review', ``, ``, ``, ``],
  so_far_n_reviews_written: (n) => [
    <>
      현재까지
      <span className="ml-2 text-primary font-weight-bold">{n}</span>
      개의 수업 후기가 작성되었습니다.
    </>,
    <>
      <span className="ml-2 text-primary font-weight-bold">{n}</span>
      reviews
    </>,
    's_review.so_far_n_reviews_written',
    ``,
    ``,
    ``,
    ``,
  ],
  view_all: [`전체보기`, `View all`, 's_review.view_all', ``, ``, ``, ``],
  company_using_english: [`영어 쓰는 회사`, `Global companies`, 's_review.company_using_english', ``, ``, ``, ``],
  presentation_report_paper: [
    `발표/보고/논문`,
    `Presentation/Report/Paper`,
    's_review.presentation_report_paper',
    ``,
    ``,
    ``,
    ``,
  ],
  interview_essay: [`인터뷰/에세이`, `Interview/Essay`, 's_review.interview_essay', ``, ``, ``, ``],
  study_abroad_immigrant: [
    `유학/해외거주`,
    `Study Abroad/Immigration`,
    's_review.study_abroad_immigrant',
    ``,
    ``,
    ``,
    ``,
  ],
  tutors_who_fit_me: [`핏이 맞는 튜터`, `Tutors who fit me`, 's_review.tutors_who_fit_me', ``, ``, ``, ``],
  free_schedule: [`자유로운 스케쥴`, `Flexible schedule`, 's_review.free_schedule', ``, ``, ``, ``],
  overcome_fear: [`두려움 극복`, `Overcome fear`, 's_review.overcome_fear', ``, ``, ``, ``],
  most_frequency: [`내가 사용한 단어`, `View your words`, 's_review.most_frequency', ``, ``, ``, ``],
  filler_word_desc: [
    `필러워드(문장 사이를 채워 주는 영어 추임새, ex)Hmm~, I mean~)`,
    `Filler words (words used to mark a pause or hesitation, ex. Hmm, like)`,
    's_review.filler_word_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  see_recommended_synonyms_list: [
    `추천 동의어 리스트`,
    `View suggested synonyms`,
    's_review.see_recommended_synonyms_list',
    ``,
    ``,
    ``,
    ``,
  ],
  recorded_script: [
    <>
      수업 녹음 및 스크립트는 Zoom 기능을 활용하고 있습니다.
      <br />
      유의사항
      <br /> 동시에 말하는 경우 스크립트가 정확하지 않을 수 있습니다.
      <br /> 원어민 관점에서 영어 발음이 분석되어 일부 스크립트가 정확하지 않을 수 있습니다.
    </>,
    <>
      Ringle provides lesson recordings and script through Zoom's features.
      <br />
      Notice
      <br /> When the student and the tutor speak at the same time, the script may be inaccurate.
      <br />- Some parts of the script may not be accurate, as the conversation is processed based on native speakers
      pronunciation.
    </>,
    's_review.recorded_script',
    ``,
    ``,
    ``,
    ``,
  ],
  suggestions: [
    <>
      Filler Word는 like, um과 같이 의미 없이 문장에서 사용되는 단어들을 말합니다.
      <br />
      주로 사용한 단어의 동의어를 확인하고, 다음 수업에서 활용해보세요.
    </>,
    <>
      In this part, you can view the analysis of your speech. You can check which vocabularies are most frequently used
      by native speakers, see synonyms, and spot filler words you've used. Filler words refer to words spoken in
      conversation without meaning, in order to signal a pause or hesitation.
      <br />
      Also, check out synonyms of your most frequently used words, and use them in your next lesson.
      <br />
      The percentage of filler word shows the probability of you using filler words more than once, for every sentence
      you speak. Appropriate rate for this category is about 30%.
    </>,
    's_review.suggestions',
    ``,
    ``,
    ``,
    ``,
  ],
  feedback: [
    `튜터가 남긴 나의 영어 실력에 대한 평가 점수, 피드백 및 교정결과를 확인할 수 있습니다. 평가 점수를 바탕으로 IELTS, TOEIC SPEAKING, TOEFL 예상 점수도 확인할 수 있습니다.`,
    `You can view your tutor's evaluation, feedback, and corrections. You can also get expected scores for standardized English exams, such as IELTS, TOEIC SPEAKING, and TOEFL.`,
    's_review.feedback',
    ``,
    ``,
    ``,
    ``,
  ],
  summary_graph: [
    `수업 시간 동안 사용한 단어의 종류, 분당 말하기 속도를 확인할 수 있습니다. 튜터와 비교하여, 원어민 대비 나의 실력이 어느 정도인지 체크해보세요.`,
    `You can view types of vocabularies used in your lessons and your speech pace. Compare these information with native speakers' and find out how you compare.`,
    's_review.summary_graph',
    ``,
    ``,
    ``,
    ``,
  ],
  script_you_can_setting_record: [
    <>
      해당 수업은 [수업 녹음] 미설정으로 녹음 파일과 스크립트가 제공되지 않습니다.
      <br />
      <br />
      수업 전 [수업 설정 방식] 메뉴에서 [수업 녹음]을 설정하시면 다음 수업부터 수업 녹음파일과 스크립트를 확인할 수
      있습니다.
    </>,
    <>
      You did not record this lesson. Audio recording and transcripts are only available for lessons that allowed audio
      recording. You can change this setting under Lesson Style for each lesson.
    </>,
    's_review.script_you_can_setting_record',
    ``,
    ``,
    ``,
    ``,
  ],
  noshow: [
    `노쇼 수업으로 수업 분석이 제공되지 않습니다.`,
    `No-show lessons do not have audio recording and scripts.`,
    's_review.noshow',
    ``,
    ``,
    ``,
    ``,
  ],
  analyzing_patterns: [
    `스피킹 패턴(구문/표현) 분석 중`,
    `Analyzing speaking patterns`,
    's_review.analyzing_patterns',
    ``,
    ``,
    ``,
    ``,
  ],
  wpm: [
    `1분당 말하는 단어수를 의미하며 학습자의 말하기 속도를 측정합니다.`,
    `Speech pace is estimated using average words uttered per minute.`,
    's_review.wpm',
    ``,
    ``,
    ``,
    ``,
  ],
  vocabulary_range: [
    `수업 중 학습자가 사용한 단어를 기반으로 현재 학습자가 구사할 수 있는 영어 구사력을 측정합니다.`,
    `Measures fluency based on words spoken during lesson.`,
    's_review.vocabulary_range',
    ``,
    ``,
    ``,
    ``,
  ],
  script_being_prepared: [
    <>
      수업 녹음파일과 스크립트를 생성중입니다.
      <br />
      수업 종료 후, 최대 24시간 정도 소요될 수 있습니다. 조금만 기다려 주세요!
    </>,
    <>
      Generating lesson audio recordings and transcripts.
      <br />
      This process may take up to 24 hours after lesson. Thank you for your patience!
    </>,
    's_review.script_being_prepared',
    ``,
    ``,
    ``,
    ``,
  ],
  script_being_delayed: [
    <>
      수업 녹음파일과 스크립트 생성이 지연되고 있습니다.
      <br />
      아래 문의 버튼을 통해 링글팀에 연락 주시면, 빠르게 확인 후 조치하겠습니다.
      <br />
      복습 자료 제공이 늦어진 점에 사과드립니다.
    </>,
    <>
      We're experiencing a delay in generating your lesson audio recording and transcripts.
      <br />
      We apologize for the inconvenience.
    </>,
    's_review.script_being_delayed',
    ``,
    ``,
    ``,
    ``,
  ],
  inquiry: [`링글팀에게 알리기`, `Report to Ringle team`, 's_review.inquiry', ``, ``, ``, ``],
  successfully_submitted: [
    `링글팀에 복습 자료를 아직 제공받지 못한 것을 알렸습니다. 빠르게 확인 후 연락드리겠습니다.`,
    `The report has been sent to the Ringle team. We'll review and get back to you.`,
    's_review.successfully_submitted',
    ``,
    ``,
    ``,
    ``,
  ],
  only_available_allowed_recording: [
    `수업 녹음파일 미설정으로 AI 분석 기능은 제공되지 않습니다.`,
    `The AI analysis service is not available due to "Do not record" state.`,
    's_review.only_available_allowed_recording',
    ``,
    ``,
    ``,
    ``,
  ],
  analyzing: [
    `말하기 속도 & 사용 단어 수 분석 중`,
    `Analyzing Speech Pace & Vocabulary Range`,
    's_review.analyzing',
    ``,
    ``,
    ``,
    ``,
  ],
  feedback_waiver: [
    <>
      해당 수업의 피드백은 아래의 사유 중 하나로 제공되지 않습니다.
      <br />
      문의사항이 있으시면 홈페이지 내 [문의] 채널로 알려주세요!
      <br />
      <br />
      - 수강생의 요청
      <br />
      - 작문 위주로 진행된 수업
      <br />
      - 인터뷰 형식으로 진행된 수업
      <br />
    </>,
    <>
      Feedback from this class is not available due to one of the following reasons:
      <br />
      If you have any questions, please contact us via chat below
      <br />
      <br />
      - Student confirmation - no feedback required
      <br />
      - Writing-oriented class
      <br />
      - Interview-oriented class
      <br />
    </>,
    's_review.feedback_waiver',
    ``,
    ``,
    ``,
    ``,
  ],
  contact: [`링글팀에게 알리기`, `Report to Ringle team`, 's_review.contact', ``, ``, ``, ``],
  alertMessage: [
    `링글팀에 튜터 피드백 리포트를 아직 받지 못한 것을 알렸습니다. 빠르게 확인 후 연락드리겠습니다.`,
    `The report has been sent to the Ringle team. We'll review and get back to you.`,
    's_review.alertMessage',
    ``,
    ``,
    ``,
    ``,
  ],
  worst: [`최악이었어요!`, `Worst`, 's_review.worst', ``, ``, ``, ``],
  bad: [`그냥 별로였어요`, `Bad`, 's_review.bad', ``, ``, ``, ``],
  dissatisfied: [`전반적으로 아쉬웠어요`, `Dissatisfied`, 's_review.dissatisfied', ``, ``, ``, ``],
  fine: [`좋았는데, 조금 아쉬웠어요`, `Fine`, 's_review.fine', ``, ``, ``, ``],
  great: [`좋았어요!`, `Great`, 's_review.great', ``, ``, ``, ``],
  what_did_you_have_problem: [
    `어떤 점이 불만이셨나요?`,
    `What did you have problems with?`,
    's_review.what_did_you_have_problem',
    ``,
    ``,
    ``,
    ``,
  ],
  what_small_improvements: [
    `어떤 점만 개선하면 좋을까요?`,
    `What improvements would you suggest?`,
    's_review.what_small_improvements',
    ``,
    ``,
    ``,
    ``,
  ],
  what_did_you_like_the_most: [
    `특히 어떤 점이 좋으셨나요?`,
    `What did you like the most?`,
    's_review.what_did_you_like_the_most',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder_1: [
    `수업 이용에 불편을 드려 죄송합니다. 튜터와 진행한 수업에서 겪으셨던 불편 사항을 남겨주세요.`,
    `We are sorry to hear that. Please note what you found to be unsatisfactory.`,
    's_review.placeholder_1',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder_2: [
    `기대하셨던 것과는 달리 진행된 수업에서 튜터의 부족했던 부분을 남겨주세요.`,
    `Has your tutor missed your expectations at all? How so?`,
    's_review.placeholder_2',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder_3: [
    `만족도 높은 수업을 제공하지 못해 아쉽습니다. 튜터가 개선할 수 있는 부분을 남겨주세요.`,
    `We are sorry to hear that. Please note where the tutor could improve`,
    's_review.placeholder_3',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder_4: [
    `튜터에 대한 자세한 수업 평가는 튜터의 역량 개선 및 수업 퀄리티 증진에 큰 도움이 됩니다.
(네트워크/교재 평가는 아래 선택사항에 남겨주세요. 수업과 무관한 내용, 욕설 등이 포함된 악의적인 평가는 사전 안내 없이 삭제될 수 있습니다.)`,
    `Your detailed feedback on tutor helps improving our tutor’s ability and class quality.
(Leave feedback on network or lesson materials through options below. Ill-intended feedback irrelevant to lessons or containing swearwords may be deleted without prior notice.)`,
    's_review.placeholder_4',
    ``,
    ``,
    ``,
    ``,
  ],
  submit: [`제출하기`, `Submit`, 's_review.submit', ``, ``, ``, ``],
  shareRingleTeam: [
    `링글팀과 튜터에게만 공유하기`,
    `Visible only to Ringle Team and Tutor.`,
    's_review.shareRingleTeam',
    ``,
    ``,
    ``,
    ``,
  ],
  ringleTeamComment: [
    `링글팀에게 남길 의견이 있으신가요?`,
    `Do you have any feedback for the Ringle Team?`,
    's_review.ringleTeamComment',
    ``,
    ``,
    ``,
    ``,
  ],
  submit_late: [`나중에 하기`, `Skip for now`, 's_review.submit_late', ``, ``, ``, ``],
  required_field: [
    `필수로 입력해야하는 항목을 입력해주세요`,
    `Please enter the required fields`,
    's_review.required_field',
    ``,
    ``,
    ``,
    ``,
  ],
  preparation: [`수업 준비도`, `Preparation`, 's_review.preparation', ``, ``, ``, ``],
  tech_environment: [`수업 환경`, `Teaching Environment`, 's_review.tech_environment', ``, ``, ``, ``],
  tech_quality: [`비디오/마이크 상태`, `Audio/Video Quality`, 's_review.tech_quality', ``, ``, ``, ``],
  punctuality: [`지각/조기종료`, `Punctuality`, 's_review.punctuality', ``, ``, ``, ``],
  correction_quality: [`교정 능력`, `Correction Quality`, 's_review.correction_quality', ``, ``, ``, ``],
  tailoring: [`수업 설정 미반영`, `Tailoring to Lesson Requests`, 's_review.tailoring', ``, ``, ``, ``],
  lesson_direction: [`수업 진행 능력`, `Lesson Direction`, 's_review.lesson_direction', ``, ``, ``, ``],
  attitude: [`수업 태도`, `Attitude and Tone`, 's_review.attitude', ``, ``, ``, ``],
  punctualityRight: [`수업시간 엄수`, `Punctuality`, 's_review.punctualityRight', ``, ``, ``, ``],
  tailoringRight: [`수업 설정 숙지`, `Tailoring to Lesson Requests`, 's_review.tailoringRight', ``, ``, ``, ``],
  writeThisPlace: [
    `소중한 의견을 작성해주세요. 더 나은 서비스를 위해 노력하겠습니다.`,
    `Please leave a comment.`,
    's_review.writeThisPlace',
    ``,
    ``,
    ``,
    ``,
  ],
  material: [`교재`, `Materials`, 's_review.material', ``, ``, ``, ``],
  network: [`네트워크`, `Network`, 's_review.network', ``, ``, ``, ``],
  overall: [`총평`, `Overall`, 's_review.overall', ``, ``, ``, ``],
  doNotWantToMeetAgain: [
    `이 튜터와 다시 만나지 않기`,
    `I do not want to meet this tutor again`,
    's_review.doNotWantToMeetAgain',
    ``,
    ``,
    ``,
    ``,
  ],
  howWasYourLessonWith: (tutor) => [
    <>{tutor} 튜터와의 수업은 어떠셨나요?</>,
    <>How was your lesson with {tutor}?</>,
    's_review.howWasYourLessonWith',
    ``,
    ``,
    ``,
    ``,
  ],
  leaveTutorComment: [
    `튜터에게 수업에 대한 평가를 남겨주세요.`,
    `Please leave a comment about this tutor.`,
    's_review.leaveTutorComment',
    ``,
    ``,
    ``,
    ``,
  ],
  lessonOverall: [
    `전반적인 수업 만족도는 어떠셨나요?`,
    `What did you think about the lesson overall?`,
    's_review.lessonOverall',
    ``,
    ``,
    ``,
    ``,
  ],
  optional: [`선택사항`, `Optional`, 's_review.optional', ``, ``, ``, ``],
  placeholder_5: [
    <>수업과 관련 없거나 부적절한 표현이 포함된 평가는 숨김 처리 될 수 있습니다.</>,
    <>Feedbacks containing irrelevant facts or offensive language may be hidden.</>,
    's_review.placeholder_5',
    ``,
    ``,
    ``,
    ``,
  ],
  entire: [`전체 수업`, `All`, 's_review.entire', ``, ``, ``, ``],
  no_show_lesson: [`노쇼한 수업`, `No-show`, 's_review.no_show_lesson', ``, ``, ``, ``],
  all_viewed: [`모두 조회했습니다.`, `Review complete`, 's_review.all_viewed', ``, ``, ``, ``],
  all: [`전체`, `All`, 's_review.all', ``, ``, ``, ``],
  no_reviewed: [
    `복습을 완료한 수업이 없습니다.`,
    `You haven't reviewed any lessons.`,
    's_review.no_reviewed',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_eval_submit: [`수업 평가가 제출되었습니다.`, `Submitted.`, 's_review.lesson_eval_submit', ``, ``, ``, ``],
  placeholder_refund: [
    `만족스러운 수업을 제공해 드리지 못해 죄송합니다. 튜터가 개선할 수 있는 부분을 구체적으로 남겨주시면, 담당팀에서 확인 후 수업권 복구에 대해 안내해 드리겠습니다.`,
    `We are sorry to hear that you were unsatisfied with the lesson. If you tell us what areas the tutor can work on in detail, the Ringle team will review your comments and contact you about reissuing the credit.`,
    's_review.placeholder_refund',
    ``,
    ``,
    ``,
    ``,
  ],
  year_month: (n) => [
    <DateComponent format="yyyy년 MM월" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="MMM yyyy" locale="en-US">
      {n}
    </DateComponent>,
    's_review.year_month',
    ``,
    ``,
    ``,
    ``,
  ],
  current_month: [`이번 달로 가기`, `Show Current Month`, 's_review.current_month', ``, ``, ``, ``],
  full_date: (year, month, date) => [
    <>
      {year}.{month}.{date}
    </>,
    <>
      {month}.{date}.{year}
    </>,
    's_review.full_date',
    ``,
    ``,
    ``,
    ``,
  ],
  no_list_desc: [
    `이 달의 수업기록이 없습니다.`,
    `You did not take any lessons during this period.`,
    's_review.no_list_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  view_recent_lessons: [`최근 수업 기록 보기`, `View Recent Lessons`, 's_review.view_recent_lessons', ``, ``, ``, ``],
  full_month: (month) => [`${month}월`, `${getMonthName(month - 1)[1]}`, 's_review.full_month', ``, ``, ``, ``],
  keyword_title_1: [`수업 진행능력`, `Teaching Ability`, 's_review.keyword_title_1', ``, ``, ``, ``],
  keyword_title_2: [`수업 참여도/준비도`, `Engagement/Preperation`, 's_review.keyword_title_2', ``, ``, ``, ``],
  keyword_title_3: [`수업 태도`, `Attitude`, 's_review.keyword_title_3', ``, ``, ``, ``],
  keyword_title_4: [`수업 진행능력`, `Teaching Ability`, 's_review.keyword_title_4', ``, ``, ``, ``],
  keyword_title_5: [`튜터의 태도/환경`, `Attitude/Environment`, 's_review.keyword_title_5', ``, ``, ``, ``],
  keyword_1_1: [`교정의 양이 많아요`, `Provides sufficient paraphrasing`, 's_review.keyword_1_1', ``, ``, ``, ``],
  keyword_1_2: [`새로운 문장 형식을 알려줘요`, `Teaches sentence structures`, 's_review.keyword_1_2', ``, ``, ``, ``],
  keyword_1_3: [`새로운 단어를 많이 알려줘요`, `Teaches many new expressions`, 's_review.keyword_1_3', ``, ``, ``, ``],
  keyword_1_4: [`추가 질문을 많이 해줬어요`, `Asks many questions`, 's_review.keyword_1_4', ``, ``, ``, ``],
  keyword_1_5: [`대화를 많이 할 수 있어요`, `Actively engages conversation`, 's_review.keyword_1_5', ``, ``, ``, ``],
  keyword_1_6: [`교정에 대한 이유를 잘 설명해요`, `Provides good explanations`, 's_review.keyword_1_6', ``, ``, ``, ``],
  keyword_1_7: [`반복되는 실수를 잘 짚어줘요`, `Corrects repetitive mistakes`, 's_review.keyword_1_7', ``, ``, ``, ``],
  keyword_2_1: [`수업을 잘 리드해요`, `Leads lessons well`, 's_review.keyword_2_1', ``, ``, ``, ``],
  keyword_2_2: [
    `학생의 요청사항을 잘 들어줘요`,
    `Responds willingly to requests`,
    's_review.keyword_2_2',
    ``,
    ``,
    ``,
    ``,
  ],
  keyword_2_3: [`수업 시간을 충분히 활용해요`, `Makes full use of time`, 's_review.keyword_2_3', ``, ``, ``, ``],
  keyword_2_4: [`수업 준비를 잘 해줘요`, `Comes to lessons prepared`, 's_review.keyword_2_4', ``, ``, ``, ``],
  keyword_2_5: [`수업 노트를 잘 정리해요`, `Provides good written feedback`, 's_review.keyword_2_5', ``, ``, ``, ``],
  keyword_2_6: [`교재를 정확히 이해하고 있어요`, ``, 's_review.keyword_2_6', ``, ``, ``, ``],
  keyword_3_1: [`수업 분위기가 활기차요`, `Energetic and lively`, 's_review.keyword_3_1', ``, ``, ``, ``],
  keyword_3_2: [`차분하게 수업을 진행해요`, `Calm and composed`, 's_review.keyword_3_2', ``, ``, ``, ``],
  keyword_3_3: [`답변에 대한 리액션이 좋아요`, `Good reactions`, 's_review.keyword_3_3', ``, ``, ``, ``],
  keyword_3_4: [`수준에 맞게 수업을 진행해줘요`, `Teaches at my level`, 's_review.keyword_3_4', ``, ``, ``, ``],
  keyword_3_5: [`냉철한 피드백을 해줘요`, `Gives objective feedback`, 's_review.keyword_3_5', ``, ``, ``, ``],
  keyword_4_1: [`교정이 부족했어요`, `Not enough paraphrasing`, 's_review.keyword_4_1', ``, ``, ``, ``],
  keyword_4_2: [`단순 문법 교정만 진행됐어요`, `Only corrects simple grammar`, 's_review.keyword_4_2', ``, ``, ``, ``],
  keyword_4_3: [`설명이 부족했어요`, `Not enough explanations`, 's_review.keyword_4_3', ``, ``, ``, ``],
  keyword_4_4: [`교정 외에 대화가 부족했어요`, `Not enough conversation`, 's_review.keyword_4_4', ``, ``, ``, ``],
  keyword_4_5: [`학생과 소통이 부족했어요`, `Not communicative`, 's_review.keyword_4_5', ``, ``, ``, ``],
  keyword_4_6: [`수업을 리드하지 못했어요`, `Not good at leading lessons`, 's_review.keyword_4_6', ``, ``, ``, ``],
  keyword_4_7: [`수업 준비가 아쉬웠어요`, `Did not come prepared`, 's_review.keyword_4_7', ``, ``, ``, ``],
  keyword_5_1: [`수업 시간을 잘 지키지 않았어요`, `Came late/ended early`, 's_review.keyword_5_1', ``, ``, ``, ``],
  keyword_5_2: [`수업에 집중하지 못했어요`, `Lacked focus`, 's_review.keyword_5_2', ``, ``, ``, ``],
  keyword_5_3: [`수업 진행 환경이 어수선해요`, `Distracting environment`, 's_review.keyword_5_3', ``, ``, ``, ``],
  keyword_5_4: [
    `불친절해서 대화가 어려웠어요`,
    `Unfriendly and difficult to talk to`,
    's_review.keyword_5_4',
    ``,
    ``,
    ``,
    ``,
  ],
  title: [`수업 평가`, ``, 's_review.title', ``, ``, ``, ``],
  subtitle_1: [`이번 수업은 어떠셨나요?`, ``, 's_review.subtitle_1', ``, ``, ``, ``],
  subtitle_2: [
    `이번 수업에서 {tutor name}의 아쉬웠던 점을 알려주세요. (1개 이상)`,
    ``,
    's_review.subtitle_2',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle_3: [`{tutor name}튜터와 다음에도 수업을 하고 싶으신가요?`, ``, 's_review.subtitle_3', ``, ``, ``, ``],
  subtitle_4: [`자세한 수업 후기를 알려주세요. (선택)`, ``, 's_review.subtitle_4', ``, ``, ``, ``],
  subtitle_5: [`교재가 수업에 도움이 되었나요? (선택)`, ``, 's_review.subtitle_5', ``, ``, ``, ``],
  subtitle_6: [`수업 중 네트워크 환경은 어땠나요? (선택)`, ``, 's_review.subtitle_6', ``, ``, ``, ``],
  rating_0: [`수업 만족도를 평가해주세요.`, ``, 's_review.rating_0', ``, ``, ``, ``],
  rating_1: [`최악이었어요.`, ``, 's_review.rating_1', ``, ``, ``, ``],
  rating_2: [`너무 별로였어요.`, ``, 's_review.rating_2', ``, ``, ``, ``],
  rating_3: [`아쉬웠어요.`, ``, 's_review.rating_3', ``, ``, ``, ``],
  rating_4: [`좋았어요.`, ``, 's_review.rating_4', ``, ``, ``, ``],
  rating_5: [`최고에요!`, ``, 's_review.rating_5', ``, ``, ``, ``],
  report: [`수업이 진행되지 않았다면 링글팀에게 알려주세요.`, ``, 's_review.report', ``, ``, ``, ``],
  btn_1: [`나중에 하기`, ``, 's_review.btn_1', ``, ``, ``, ``],
  btn_2: [`다음`, ``, 's_review.btn_2', ``, ``, ``, ``],
  btn_3: [`선택 완료`, ``, 's_review.btn_3', ``, ``, ``, ``],
  btn_4: [`평가 완료`, ``, 's_review.btn_4', ``, ``, ``, ``],
  btn_5: [`제출하기`, ``, 's_review.btn_5', ``, ``, ``, ``],
  btn_6: [`확인`, ``, 's_review.btn_6', ``, ``, ``, ``],
  btn_7: [`평가 완료하기`, ``, 's_review.btn_7', ``, ``, ``, ``],
  btn_8: [`추가 작성하기`, ``, 's_review.btn_8', ``, ``, ``, ``],
  btn_9: [`확인`, ``, 's_review.btn_9', ``, ``, ``, ``],
  prefer_0: [`튜터 선호도는 링글팀에게만 공개됩니다.`, ``, 's_review.prefer_0', ``, ``, ``, ``],
  prefer_1: [`아니요. 다시 만나고 싶지 않아요.`, ``, 's_review.prefer_1', ``, ``, ``, ``],
  prefer_2: [`괜찮았어요. 기회가 되면 또 하고 싶어요.`, ``, 's_review.prefer_2', ``, ``, ``, ``],
  prefer_3: [`최고의 튜터! 꼭 다시 만나고 싶어요.`, ``, 's_review.prefer_3', ``, ``, ``, ``],
  placeholder_textreview: [
    `자세한 수업 후기는 튜터의 역량 개선 및 수업의 만족도 증진에 큰 도움이 됩니다. 
부적절한 단어/표현이 포함된 경우,  별도의 통지없이 삭제될 수 있습니다.`,
    ``,
    's_review.placeholder_textreview',
    ``,
    ``,
    ``,
    ``,
  ],
  word_count: [`/최소 20자`, ``, 's_review.word_count', ``, ``, ``, ``],
  option_share_1: [`전체공개`, ``, 's_review.option_share_1', ``, ``, ``, ``],
  option_share_2: [`링글팀에게만 공유하기`, ``, 's_review.option_share_2', ``, ``, ``, ``],
  option_share_3: [`링글팀과 튜터에게만 공유하기`, ``, 's_review.option_share_3', ``, ``, ``, ``],
  placeholder_optional: [
    `어떤점이 아쉬웠는지 의견을 보내주세요. (선택)`,
    ``,
    's_review.placeholder_optional',
    ``,
    ``,
    ``,
    ``,
  ],
  desc_error: [`자세한 수업 후기는 20자 이상으로 입력해주세요.`, ``, 's_review.desc_error', ``, ``, ``, ``],
  toast: [`리뷰를 남겨주셔서 감사합니다.`, ``, 's_review.toast', ``, ``, ``, ``],
  title_pointback: [`새로 온 튜터 후기 작성`, ``, 's_review.title_pointback', ``, ``, ``, ``],
  desc_pointback: [
    `수업 후기를 100자 이상 입력해야 {00,000}포인트를 돌려 받을 수 있어요.
지금 수업 평가를 완료하시겠어요?`,
    ``,
    's_review.desc_pointback',
    ``,
    ``,
    ``,
    ``,
  ],
  label_pointback: [`새로 온 튜터 포인트백 혜택`, ``, 's_review.label_pointback', ``, ``, ``, ``],
  info_pointback: [
    `100자 이상의 수업 후기를 남기면 {00,000}P를 돌려드려요!`,
    ``,
    's_review.info_pointback',
    ``,
    ``,
    ``,
    ``,
  ],
  toast_pointback: [`새로 온 튜터 후기 작성 포인트가 적립되었습니다.`, ``, 's_review.toast_pointback', ``, ``, ``, ``],
  title_report: [`수업에 어떤 문제가 있었나요?`, ``, 's_review.title_report', ``, ``, ``, ``],
  report_option_1: [`줌이 제대로 실행되지 않았어요.`, ``, 's_review.report_option_1', ``, ``, ``, ``],
  report_option_2: [`비디오/오디오가 연결되지 않았어요.`, ``, 's_review.report_option_2', ``, ``, ``, ``],
  report_option_3: [`튜터가 입장하지 않았어요.`, ``, 's_review.report_option_3', ``, ``, ``, ``],
  report_option_4: [`정규 수업 시간을 충분히 채우지 못했어요.`, ``, 's_review.report_option_4', ``, ``, ``, ``],
  report_option_5: [`수업이 자꾸 끊겼어요.`, ``, 's_review.report_option_5', ``, ``, ``, ``],
  subtitle_report: [`문제 상황을 설명해 주세요.`, ``, 's_review.subtitle_report', ``, ``, ``, ``],
  placeholder_report: [
    `불편을 겪으신 사항을 자세히 알려주세요. 
링글팀이 1 영업일 이내 확인 후 연락 드리겠습니다.`,
    ``,
    's_review.placeholder_report',
    ``,
    ``,
    ``,
    ``,
  ],
  desc_report: [
    `수업에 불편을 드려 죄송합니다. 제보해주신 내용은 1영업일 내로 처리 결과를 알려드리겠습니다.`,
    ``,
    's_review.desc_report',
    ``,
    ``,
    ``,
    ``,
  ],
  title_quit: [`수업 나가기`, ``, 's_review.title_quit', ``, ``, ``, ``],
  desc_quit: [`수업을 중단하시겠습니까?`, ``, 's_review.desc_quit', ``, ``, ``, ``],
  btn_quit_1: [`취소`, ``, 's_review.btn_quit_1', ``, ``, ``, ``],
  btn_quit_2: [`나가기`, ``, 's_review.btn_quit_2', ``, ``, ``, ``],
  review_being_delayed: [
    <>
      수업 녹음파일과 스크립트 생성이 지연되고 있습니다.
      <br />
      아래 문의 버튼을 통해 링글팀에 연락 주시면, 빠르게 확인 후 조치하겠습니다.
      <br />
      복습 자료 제공이 늦어진 점에 사과드립니다.
    </>,
    <>
      We're experiencing a delay in generating your lesson audio recording and transcripts.
      <br />
      We apologize for the inconvenience.
    </>,
    's_review.review_being_delayed',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_materials = {
  title: [`교재`, `Lesson Materials`, 's_materials.title', ``, ``, ``, ``],
  search_placeholder: [
    `원하시는 교재의 키워드를 입력해주세요. ex) Economy`,
    `Search for lesson materials ex) Economy`,
    's_materials.search_placeholder',
    ``,
    ``,
    ``,
    ``,
  ],
  material_download: [`PDF`, `PDF`, 's_materials.material_download', ``, ``, ``, ``],
  view_material: [`교재 보기`, `View`, 's_materials.view_material', ``, ``, ``, ``],
  select_material_you_want_to_change: [
    `변경할 수업 교재를 선택해 주세요.`,
    `Select the lesson material you want to change.`,
    's_materials.select_material_you_want_to_change',
    ``,
    ``,
    ``,
    ``,
  ],
  feedback_not_provided: [
    `* 튜터의 Feedback Report 미제공`,
    `* This option does not provide tutor's feedback report`,
    's_materials.feedback_not_provided',
    ``,
    ``,
    ``,
    ``,
  ],
  no_result: [`키워드에 맞는 검색 결과가 없습니다.`, `No Result`, 's_materials.no_result', ``, ``, ``, ``],
  search_result: [`교재 검색 결과`, `Search Results`, 's_materials.search_result', ``, ``, ``, ``],
  insight: [`인사이트`, `Insight`, 's_materials.insight', ``, ``, ``, ``],
  clip_article: [`영상 / 기사`, `Video Clip / Article`, 's_materials.clip_article', ``, ``, ``, ``],
  key_questions: [`수업 질문`, `Questions`, 's_materials.key_questions', ``, ``, ``, ``],
  expressions: [`핵심 표현`, `Expressions`, 's_materials.expressions', ``, ``, ``, ``],
  does_not_include_video_article: [
    `해당 교재는 영상 및 기사가 제공되지 않습니다.`,
    `This lesson material does not include a video or an article.`,
    's_materials.does_not_include_video_article',
    ``,
    ``,
    ``,
    ``,
  ],
  new: [`새 교재`, `New material`, 's_materials.new', ``, ``, ``, ``],
  like: [`찜하기`, `Save`, 's_materials.like', ``, ``, ``, ``],
  categoryDescription_1: [
    <>
      <ul className="pl-4">
        <li>20분 수업용으로 좋습니다.</li>
        <li>영어 초중급 - 토플 iBT 19-24점, OPIC IL 이상, 토익스피킹 5-6점 - 인 분들께 추천해 드립니다.</li>
        <li>예습은 30분 이내면 충분합니다.</li>
        <li>교정 위주 수업 보다는 대화 중심 수업에 적합합니다.</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Best for 20-minute lessons</li>
      <li>Best for beginner-intermediate level speakers (TOEFL iBT 19-24, OPIC IL and up, TOEIC Speaking 5-6)</li>
      <li>Lesson Prep takes less than 30 minutes</li>
      <li>Best for discussion-focused lessons</li>
    </ul>,
    's_materials.categoryDescription_1',
    ``,
    ``,
    ``,
    ``,
  ],
  categoryDescription_2: [
    <>
      <ul className="pl-4">
        <li>40분 수업용으로 좋습니다.</li>
        <li>영어 중급 이상 - 토플 iBT +25점, OPIC IH 이상, 토익스피킹 +7점 - 분들께 추천해 드립니다.</li>
        <li>30분 이상 예습이 필요합니다.</li>
        <li>비즈니스 토론, 심층 교정 모두 가능합니다.</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Best for 40-minute lessons</li>
      <li>Best for advanced speakers (TOFLE iBT 25+, OPIC IH and up, TOEIC Speaking 7+)</li>
      <li>Lesson Prep takes 30 minutes or more</li>
      <li>Good for both discussion and correction-focused lessons</li>
    </ul>,
    's_materials.categoryDescription_2',
    ``,
    ``,
    ``,
    ``,
  ],
  categoryDescription_3: [
    <>
      <ul className="pl-4">
        <li>뉴욕타임즈에서 학생들을 위해 특별히 만든 교재입니다.</li>
        <li>초등 4학년 ~ 중등 3학년에게 추천해드립니다.</li>
        <li>20분, 40분 수업 모두 사용하기에 적합합니다.</li>
        <li>시사/과학 관련 토론수업을 원하는 성인 분들께도 추천합니다.</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>Designed for teens & junior users</li>
      <li>Best for 4th-9th graders</li>
      <li>Good for adult discussions on current events</li>
      <li>Good for both 20 and 40-minute lessons</li>
    </ul>,
    's_materials.categoryDescription_3',
    ``,
    ``,
    ``,
    ``,
  ],
  categoryDescription_4: [
    <>
      <ul className="pl-4">
        <li>내가 올린 자료 - pdf, ppt, docs 형식 문서 가능 - 를 튜터와 함께 보며 수업할 수 있습니다.</li>
        <li>이메일 교정, 프레젠테이션 발표연습, 인터뷰 연습 등을 할 수 있습니다.</li>
        <li>문서 교정 위주의 수업은 40분이 적합합니다.</li>
        <li>자유주제 수업 관련 도움이 필요하시면 1:1 문의로 연락하실 수 있습니다.</li>
      </ul>
    </>,
    <ul className="pl-4">
      <li>You can upload your own lesson materials for your lesson (PDF, ppt, docs formats).</li>
      <li>You can get corrections on your writing piece, practice presentations, or prepare for an interview.</li>
      <li>If you want to get edits for your document, we recommend you to take a 40-minute lesson.</li>
      <li>If you have any questions, please contact us!</li>
    </ul>,
    's_materials.categoryDescription_4',
    ``,
    ``,
    ``,
    ``,
  ],
  speaking_focused_guide_title: [
    <>
      모의 인터뷰, 프레젠테이션 연습, 자유주제 말하기 연습 등 원하는 주제로 수업할 수 있습니다.
      <br />
      준비한 자료를 수업에서 튜터와 함께 보고 목적에 맞는 수업을 진행해 보세요.
    </>,
    <>
      You can take a lesson on any topic through the Free Speaking lesson by uploading the relevant documents, such as
      mock interviews, practicing for presentations, etc. <br /> Students can upload their own resume, presentation,
      etc. before the lesson and then tutors will go through the material with students during the lesson and cover the
      topics for the lesson style you have selected.
    </>,
    's_materials.speaking_focused_guide_title',
    ``,
    ``,
    ``,
    ``,
  ],
  speaking_focused_guide_text1: [
    <>[Speaking Focused 수업 유의사항]</>,
    <>[Please note for Speaking Focused Lesson]</>,
    's_materials.speaking_focused_guide_text1',
    ``,
    ``,
    ``,
    ``,
  ],
  speaking_focused_guide_text2: [
    <>
      1) 수업 예약 후, 예정된 수업 페이지에서 자료를 업로드 할 수 있습니다.
      <br />
      2) 수업 시작 24시간 전까지 문서 업로드를 권장 드립니다. <br />
      3) 문서 교정은 수업 시간에 실시간으로 진행됩니다.
      <br />
      4) 수업 중 영문 교정을 위해 개인 자료를 업로드 시, 20분 수업은 500 단어 (A4 1장), 40분 수업은 1000 단어 (A4 2장
      분량) 분량을 권장 드립니다.
    </>,
    <>
      1) After scheduling the lesson, you can upload the material on the upcoming lesson page.
      <br />
      2) It is recommended to upload your document at least 24 hours before the lesson starts.
      <br />
      3) Writing corrections will be conducted live during the lesson.
      <br />
      4) When you upload your own material to be edited during your Free Topic lesson, please limit the word count to
      500 (one A4 page) for a 20-minute lesson, and 1,000 (two A4 pages) for a 40-minute lesson.
    </>,
    's_materials.speaking_focused_guide_text2',
    ``,
    ``,
    ``,
    ``,
  ],
  writing_focused_guide_title: [
    <>
      직접 작성한 에세이, 이력서, 이메일 등 영문서를 업로드하고 수업에서 교정 받을 수 있습니다.
      <br />
      업로드한 자료를 수업에서 튜터와 함께 보고 상황과 문맥에 맞는 문장으로 교정해 보세요.
    </>,
    <>
      Students can upload their own essay, resume, e-mail, etc. before the lesson and then tutors will go through the
      material with students during the lesson and provide appropriate corrections.
    </>,
    's_materials.writing_focused_guide_title',
    ``,
    ``,
    ``,
    ``,
  ],
  writing_focused_guide_text_1: [
    <>[Writing Focused 수업 유의사항]</>,
    <>[Please note for Writing Focused Lesson] </>,
    's_materials.writing_focused_guide_text_1',
    ``,
    ``,
    ``,
    ``,
  ],
  writing_focused_guide_text_2: [
    <>
      1) Writing 위주의 수업으로&nbsp; 튜터 Feedback Report는 제공되지 않습니다.
      <br />
      2) 수업 예약 후, 예정된 수업 페이지에서 자료를 업로드 할 수 있습니다.
      <br />
      3) 수업 시작 24시간 전까지 문서 업로드를 권장 드립니다.
      <br />
      4) 문서 교정은 수업 시간에 실시간으로 진행됩니다.
      <br />
      5) 수업 중 영문 교정을 위해 개인 자료를 업로드 시, 20분 수업은 500 단어 (A4 1장), 40분 수업은 1000 단어 (A4 2장
      분량) 분량을 권장 드립니다.
    </>,
    <>
      1) Feedback reports are not available for Writing Focused lesson. upcoming lesson page.
      <br />
      2) After scheduling the lesson, you can upload the material on the lesson starts.
      <br />
      3) It is recommended to upload your document at least 24 hours before the
      <br />
      4) Writing corrections will be conducted live during the lesson.
      <br />
      5) When you upload your own material to be edited during your Free Topic lesson, please limit the word count to
      500 (one A4 page) for a 20-minute lesson, and 1,000 (two A4 pages) for a 40-minute lesson.
    </>,
    's_materials.writing_focused_guide_text_2',
    ``,
    ``,
    ``,
    ``,
  ],
  search_material: [`교재 검색`, `Search`, 's_materials.search_material', ``, ``, ``, ``],
  search_results: [`교재 검색 결과`, `Search Results`, 's_materials.search_results', ``, ``, ``, ``],
  login_desc: [
    `로그인하고 무료로 링글의 콘텐츠를 경험해 보세요.`,
    `Sign in to access all of Ringle's content for free.`,
    's_materials.login_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  login: [`로그인`, `Log in`, 's_materials.login', ``, ``, ``, ``],
  more_from_ringle: [`링글 관련 콘텐츠`, `More from Ringle`, 's_materials.more_from_ringle', ``, ``, ``, ``],
  download_material: [`다운로드하기`, `Download Materials`, 's_materials.download_material', ``, ``, ``, ``],
  notice_desc: [
    <>* 라이팅 중심 수업은 튜터 피드백 리포트가 제공되지 않습니다.</>,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    's_materials.notice_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  banner_title: [
    <>Writing-focused 수업은 튜터 Feedback Report가 제출되지 않습니다.</>,
    `A separate tutor's feedback report is not provided after Writing Focused Lessons.`,
    's_materials.banner_title',
    ``,
    ``,
    ``,
    ``,
  ],
  copyright_desc1: [`찜하기`, `Save`, 's_materials.copyright_desc1', ``, ``, ``, ``],
  action_title: [
    `지금 보신 교재가 마음에 드셨다면?`,
    `Like this material?`,
    's_materials.action_title',
    ``,
    ``,
    ``,
    ``,
  ],
  action_subtitle: [
    `이 교재로 수업해 보세요.`,
    `Use this material for your next lesson.`,
    's_materials.action_subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  btn1: [`수업 예약하기`, `Book lessons`, 's_materials.btn1', ``, ``, ``, ``],
}
export const s_upcoming_lessons = {
  to_do_list: [`예습 목록`, `Lesson Prep List`, 's_upcoming_lessons.to_do_list', ``, ``, ``, ``],
  not_confirmed: [`확정 대기중`, `Not Confirmed`, 's_upcoming_lessons.not_confirmed', ``, ``, ``, ``],
  confirmed: [`확정 완료`, `Confirmed`, 's_upcoming_lessons.confirmed', ``, ``, ``, ``],
  compatible_tutor: [
    `고객님께 적합한 튜터를 매칭 중입니다.`,
    `We are finding the best tutor for you.`,
    's_upcoming_lessons.compatible_tutor',
    ``,
    ``,
    ``,
    ``,
  ],
  option_not_change: [
    `튜터가 확정되어 옵션 변경이 불가능합니다.`,
    `Option cannot be changed since the lesson has a tutor assigned with confirmation.`,
    's_upcoming_lessons.option_not_change',
    ``,
    ``,
    ``,
    ``,
  ],
  option_not_change_auto: [
    `자동매칭은 옵션 변경이 불가능합니다.`,
    `Option cannot be changed with auto tutor matching`,
    's_upcoming_lessons.option_not_change_auto',
    ``,
    ``,
    ``,
    ``,
  ],
  matching_after: {
    auto: [
      `자동으로 매칭해주세요`,
      `Automatically match me with another tutor.`,
      's_upcoming_lessons.matching_after.auto',
      ``,
      ``,
      ``,
      ``,
    ],
    credit: [
      `수업을 취소하고 수업권을 복구해주세요`,
      `Cancel and restore my lesson credit.`,
      's_upcoming_lessons.matching_after.credit',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  options: {
    option: {
      title: [
        `튜터 매칭 옵션 변경`,
        `In case of tutor cancellation`,
        's_upcoming_lessons.options.option.title',
        ``,
        ``,
        ``,
        ``,
      ],
      description: [
        `선택한 튜터와 수업이 이뤄지지 않을 때 처리 방법을 설정합니다.`,
        `Please select how you'd like to resolve cancellation by your tutor.`,
        's_upcoming_lessons.options.option.description',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    time: {
      title: [`수업시간 변경`, `Reschedule Lesson`, 's_upcoming_lessons.options.time.title', ``, ``, ``, ``],
      description: [
        `수업이 확정되기 전까지만 가능합니다.`,
        `Lesson time can only be changed before the lesson is confirmed by tutor`,
        's_upcoming_lessons.options.time.description',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    course: {
      title: [`교재 변경`, `Change Materials`, 's_upcoming_lessons.options.course.title', ``, ``, ``, ``],
      description: [
        `수업 교재를 변경할 수 있습니다.`,
        `You can change the lesson material.`,
        's_upcoming_lessons.options.course.description',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    setting: {
      title: [`수업 방식 설정`, `Set Lesson Style`, 's_upcoming_lessons.options.setting.title', ``, ``, ``, ``],
      check_title: [`수업 방식`, `Lesson Style`, 's_upcoming_lessons.options.setting.check_title', ``, ``, ``, ``],
      description: [
        `수업녹음, 수업모드 등에 대해 설정할 수 있습니다.`,
        `Set Lesson Style for audio recording and customized lesson.`,
        's_upcoming_lessons.options.setting.description',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    cancel: {
      title: [`수업 취소`, `Cancel Lesson`, 's_upcoming_lessons.options.cancel.title', ``, ``, ``, ``],
      description: [
        `수업 시작 24시간 이내 취소하면 수업권이 복구되지 않습니다.`,
        `Cancellations made less than 24 hours before the lesson will be non-refundable.`,
        's_upcoming_lessons.options.cancel.description',
        ``,
        ``,
        ``,
        ``,
      ],
      description2: [
        `튜터가 배정되지 않아 취소시 수업권이 자동으로 복구됩니다.`,
        `Since a tutor hasn't been assigned, if you cancel now we'll restore your leson credit.`,
        's_upcoming_lessons.options.cancel.description2',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  banner_zoom: {
    test_for_video_call: [
      `Zoom 설치 가이드`,
      `Test Zoom`,
      's_upcoming_lessons.banner_zoom.test_for_video_call',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  no_schedule_ahead: [
    `현재 예정되어 있는 수업이 없어요.`,
    `You have no upcoming lessons.`,
    's_upcoming_lessons.no_schedule_ahead',
    ``,
    ``,
    ``,
    ``,
  ],
  go_to_registration_page: [
    `수업 예약하러 가기`,
    `Book Lessons`,
    's_upcoming_lessons.go_to_registration_page',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_enter_modal_title: [
    `수업 입장 안내`,
    `Enter Lesson`,
    's_upcoming_lessons.lesson_enter_modal_title',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_style_btn: [`수업 방식 설정`, `Set Lesson Style`, 's_upcoming_lessons.lesson_style_btn', ``, ``, ``, ``],
  change_style_btn: [`수업 방식 변경`, `Set Lesson Style`, 's_upcoming_lessons.change_style_btn', ``, ``, ``, ``],
  enter_btn: [`수업 입장`, `Enter Lesson`, 's_upcoming_lessons.enter_btn', ``, ``, ``, ``],
  zoom_modal: {
    title: [`수업 전 프로그램 설치 확인`, `Wait!`, 's_upcoming_lessons.zoom_modal.title', ``, ``, ``, ``],
    wait: [`잠시만요!`, ``, 's_upcoming_lessons.zoom_modal.wait', ``, ``, ``, ``],
    have_you_installed_zoom: [
      `수업에 꼭 필요한 Zoom 프로그램 설치하셨나요?`,
      `Ringle lessons are run on Zoom. Have you installed Zoom?`,
      's_upcoming_lessons.zoom_modal.have_you_installed_zoom',
      ``,
      ``,
      ``,
      ``,
    ],
    i_have_installed_zoom: [
      `이미 설치했습니다.`,
      `I have installed Zoom`,
      's_upcoming_lessons.zoom_modal.i_have_installed_zoom',
      ``,
      ``,
      ``,
      ``,
    ],
    go_to_installation_page: [
      `설치 페이지 바로가기`,
      `I need to install Zoom`,
      's_upcoming_lessons.zoom_modal.go_to_installation_page',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  after_tutor_cancel: {
    automatic_match_me: [
      `다른 튜터 매칭 받기`,
      `Automatically match me with another tutor.`,
      's_upcoming_lessons.after_tutor_cancel.automatic_match_me',
      ``,
      ``,
      ``,
      ``,
    ],
    plz_let_me_know_available_times: [
      <>선택한 튜터의 다른 시간 확인하기</>,
      <>Show me this tutor's availability. </>,
      's_upcoming_lessons.after_tutor_cancel.plz_let_me_know_available_times',
      ``,
      ``,
      ``,
      ``,
    ],
    cancel_and_restore_credit: [
      `수업 취소 후 사용한 수업권 복구 받기`,
      `Cancel and restore my lesson credit.`,
      's_upcoming_lessons.after_tutor_cancel.cancel_and_restore_credit',
      ``,
      ``,
      ``,
      ``,
    ],
    your_option_change_has_been_completed: [
      `변경한 내용이 저장되었습니다.`,
      `Saved`,
      's_upcoming_lessons.after_tutor_cancel.your_option_change_has_been_completed',
      ``,
      ``,
      ``,
      ``,
    ],
    lesson_canceled: [
      `수업이 취소되었습니다.`,
      `Lesson Canceled`,
      's_upcoming_lessons.after_tutor_cancel.lesson_canceled',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  matching_after_options: {
    view_tutors_schedule: [
      `튜터의 다른 시간 보기`,
      `View tutor's availability`,
      's_upcoming_lessons.matching_after_options.view_tutors_schedule',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  notice: {
    title: [
      `[화상프로그램 (Zoom) 오디오 연결 가이드]`,
      `[Zoom Audio Connection Guide]`,
      's_upcoming_lessons.notice.title',
      ``,
      ``,
      ``,
      ``,
    ],
    content: [
      <>
        수업이 시작되고 오디오 연결이 원활하지 않을 경우,
        <br />꼭 아래 이미지 위치에 있는 <b>오디오 연결</b> 버튼을 클릭해 주세요.
      </>,
      <>
        If you or the student can't hear one another on the Zoom meeting,
        <br />
        Please click on the <b>"Join Audio"</b> button.
      </>,
      's_upcoming_lessons.notice.content',
      ``,
      ``,
      ``,
      ``,
    ],
    footer: [
      <>
        문제가 해결되지 않을 경우,
        <br />
        오른쪽 상단에 위치한 <b>[실시간 Help]</b> 버튼을 클릭하여 링글팀에 도움을 요청하세요.
      </>,
      <>
        If the problem persists, please contact us at <b>[Help button]</b> on the top-right corner.
      </>,
      's_upcoming_lessons.notice.footer',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  welcomeBackModal: {
    main: (name) => [
      <>
        Welcome Back {name}님<br />
        반갑습니다!
      </>,
      <>
        Welcome Back {name},<br />
        Good to see you again!
      </>,
      's_upcoming_lessons.welcomeBackModal.main',
      ``,
      ``,
      ``,
      ``,
    ],
    content: [
      `첫 3회 수업 45% 할인가로 다시 시작해보세요.`,
      <>Start anew with a 45% discount on first 3 Lessons.</>,
      's_upcoming_lessons.welcomeBackModal.content',
      ``,
      ``,
      ``,
      ``,
    ],
    getDiscount: [`할인가로 구매하기`, `Buy now`, 's_upcoming_lessons.welcomeBackModal.getDiscount', ``, ``, ``, ``],
    threeLessons: [
      `3회 수업권`,
      `3-Lesson Credits`,
      's_upcoming_lessons.welcomeBackModal.threeLessons',
      ``,
      ``,
      ``,
      ``,
    ],
    footerLeft: [
      `일주일간 보지 않기`,
      `Remind me in a week`,
      's_upcoming_lessons.welcomeBackModal.footerLeft',
      ``,
      ``,
      ``,
      ``,
    ],
    footerRight: [`닫기`, `Close`, 's_upcoming_lessons.welcomeBackModal.footerRight', ``, ``, ``, ``],
  },
  inviteModal: {
    title: [
      <>
        친구초대 이벤트
        <br /> 2만 포인트가 적립되었습니다.
      </>,
      <>
        Referral Event
        <br /> You earned $17.24
      </>,
      's_upcoming_lessons.inviteModal.title',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle: [
      <>
        20분 수업을 무료로 체험하고,
        <br />첫 3회 특가로 시작해보세요.
      </>,
      <>
        Have a free 20-min Trial Lesson
        <br />
        and start Ringle at a special price
      </>,
      's_upcoming_lessons.inviteModal.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    freeTrial: [`무료 체험 신청하기`, `Start Free Trial`, 's_upcoming_lessons.inviteModal.freeTrial', ``, ``, ``, ``],
  },
  firstCouponModal: {
    main: [
      <>
        첫 3회 패키지
        <br />
        구매 고객께
        <br />
        링글이 드리는 선물
      </>,
      <>A Gift from Ringle:</>,
      's_upcoming_lessons.firstCouponModal.main',
      ``,
      ``,
      ``,
      ``,
    ],
    getDiscount: [`쿠폰함 바로 가기`, `Buy now`, 's_upcoming_lessons.firstCouponModal.getDiscount', ``, ``, ``, ``],
    footerLeft: [
      `일주일간 보지 않기`,
      `Remind me in a week`,
      's_upcoming_lessons.firstCouponModal.footerLeft',
      ``,
      ``,
      ``,
      ``,
    ],
    footerRight: [`닫기`, `Close`, 's_upcoming_lessons.firstCouponModal.footerRight', ``, ``, ``, ``],
  },
  forPromotionModal: {
    main: [
      <>
        2021년에도
        <br />
        멈추지 않는 당신에게
        <br />
        드리는 혜택
      </>,
      <>
        Never Stop Studying:
        <br />
        An exclusive offer
      </>,
      's_upcoming_lessons.forPromotionModal.main',
      ``,
      ``,
      ``,
      ``,
    ],
    getDiscount: [`할인가로 구매하기`, `Buy now`, 's_upcoming_lessons.forPromotionModal.getDiscount', ``, ``, ``, ``],
  },
  firstPurchaseModal: {
    main: (name) => [
      <>
        {name}님, 첫 3회 수업권을 <br />
        구매해주셔서 감사합니다.
      </>,
      <>Thank you for buying your first 3 lessons, #{name}.</>,
      's_upcoming_lessons.firstPurchaseModal.main',
      ``,
      ``,
      ``,
      ``,
    ],
    content: (amount) => [
      <>
        재구매 시 사용 가능한
        <br />
        {amount} 포인트가 지급되었습니다.
      </>,
      <>
        you have been awarded
        <br />
        {amount} points.
      </>,
      's_upcoming_lessons.firstPurchaseModal.content',
      ``,
      ``,
      ``,
      ``,
    ],
    getDiscount: [
      `내 포인트 확인하기`,
      `View My Points`,
      's_upcoming_lessons.firstPurchaseModal.getDiscount',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  givingBackVipModal: {
    dday: [
      <>
        <Span size={14} white>
          프로모션이 <Span warning>오늘</Span> 마감됩니다!
        </Span>
      </>,
      <>
        <Span size={14} white>
          Giving Back Promotion ends <Span warning>today</Span>.
        </Span>
      </>,
      's_upcoming_lessons.givingBackVipModal.dday',
      ``,
      ``,
      ``,
      ``,
    ],
    main: [
      <>
        링글이 100억 투자를 유치하며
        <br />A 라운드를 시작했습니다!
      </>,
      <>
        Ringle has secured
        <br />
        10 billion won in Series A!
      </>,
      's_upcoming_lessons.givingBackVipModal.main',
      ``,
      ``,
      ``,
      ``,
    ],
    content: (name) => [
      <>
        감사의 마음을 담아, {name}님께
        <br />
        10만 포인트를 지급해드렸습니다.
        <br />
        포인트는 재구매 시 사용 가능합니다.
      </>,
      <>
        As a token of our appreciation,
        <br />
        you have been awarded 86.20 Points.
      </>,
      's_upcoming_lessons.givingBackVipModal.content',
      ``,
      ``,
      ``,
      ``,
    ],
    getDiscount: [`지금 구매하기`, `Buy now`, 's_upcoming_lessons.givingBackVipModal.getDiscount', ``, ``, ``, ``],
    footerLeft: [
      `다시 보지 않기`,
      `Don't show again`,
      's_upcoming_lessons.givingBackVipModal.footerLeft',
      ``,
      ``,
      ``,
      ``,
    ],
    footerRight: [`닫기`, `Close`, 's_upcoming_lessons.givingBackVipModal.footerRight', ``, ``, ``, ``],
  },
  upcoming_lesson_enter_modal_content: (lesson, timezone, remainText) => [
    <>
      <Span>
        수업 시간: {lesson.start_time} ({timezone})
        <br />
        <Span textDanger>{remainText}</Span> 남았습니다.
        <br />
        <br />
        수업 입장은 수업 시작 15분 전부터 가능합니다.
      </Span>
    </>,
    <>
      <Span size={12}>
        Lesson time : {lesson.start_time} ({timezone})
        <br />
        The lesson will begin in {remainText}.
        <br />
        <br />
        You can enter the lesson 15 minutes before the lesson begins.
      </Span>
    </>,
    's_upcoming_lessons.upcoming_lesson_enter_modal_content',
    ``,
    ``,
    ``,
    ``,
  ],
  change_cancel: [`변경/ 취소`, `Change/Cancel`, 's_upcoming_lessons.change_cancel', ``, ``, ``, ``],
  addi_request: [`추가 요청 사항`, `Specific Requests`, 's_upcoming_lessons.addi_request', ``, ``, ``, ``],
  plz_choose_impossible_lesson_option: [
    `선택한 튜터와 매칭되지 않을 경우, 수업 처리 방식을 선택해주세요.`,
    `Please select how you'd like to resolve cancellation by your tutor.`,
    's_upcoming_lessons.plz_choose_impossible_lesson_option',
    ``,
    ``,
    ``,
    ``,
  ],
  desc_about_tiral_lesson: [
    `*체험수업은 각 질문에 대한 대화가 끝난 후 교정받는 방식으로 진행합니다. 정규수업에서는 수업모드를 교정 중심과 토론 중심 중에 선택하고 세부 수업 방식을 선택할 수 있습니다.`,
    `*All trial lessons are discussion-focused. Paid lessons can either be discussion-focused or correction focused.`,
    's_upcoming_lessons.desc_about_tiral_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  recording_notice: [
    <>
      수업 시작 시, "Recording In Progress" 안내멘트가 나오고 [● Recording]가 표기됩니다. (수업화면은 녹화되지
      않습니다.)
    </>,
    <>You'll hear "Recording In Progress" and see [● Recording] sign (lesson screen isn't recorded).</>,
    's_upcoming_lessons.recording_notice',
    ``,
    ``,
    ``,
    ``,
  ],
  mp3_warning_danger: [
    <>
      *수업 녹음 설정은 <Span danger> 수업 시작 15분 전까지만 변경 가능</Span>합니다.
    </>,
    <>
      *You can change recording preferences<Span danger> up to 15 minutes before the lesson</Span>.
    </>,
    's_upcoming_lessons.mp3_warning_danger',
    ``,
    ``,
    ``,
    ``,
  ],
  in_upcoming_page_what_you_can_do: [
    <>
      <Div spanTag fontBold textGray700 fontSize18>
        예습 & 수업 페이지
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        에서는 수업 전 준비, 예정된 수업의 변경 및 취소,
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        그리고 수업 방식을 설정할 수 있습니다.
      </Div>
    </>,
    <>
      <Div spanTag textGray700 fontSize18 fontMedium>
        You can set lesson styles, cancel, or reschedule under
      </Div>
      <Div spanTag fontBold textGray700 fontSize18 ml4>
        Lessons
      </Div>
    </>,
    's_upcoming_lessons.in_upcoming_page_what_you_can_do',
    ``,
    ``,
    ``,
    ``,
  ],
  ringle_usage: [`링글 사용법`, `How Ringle works`, 's_upcoming_lessons.ringle_usage', ``, ``, ``, ``],
  zoom_usage: [
    `화상 프로그램 (Zoom) 설치 가이드`,
    `How to Install Zoom`,
    's_upcoming_lessons.zoom_usage',
    ``,
    ``,
    ``,
    ``,
  ],
  wrong_expression: [
    `틀린 영어 표현을 할 때마다 교정 받음`,
    `Correct and paraphrase my errors as soon as I make them`,
    's_upcoming_lessons.wrong_expression',
    ``,
    ``,
    ``,
    ``,
  ],
  after_conversation: [
    `각 질문에 대한 대화가 끝난 후 교정 받음`,
    `Correct and paraphrase my errors after each question`,
    's_upcoming_lessons.after_conversation',
    ``,
    ``,
    ``,
    ``,
  ],
  half_talk: [
    `튜터와 내가 절반씩 이야기를 주고 받음`,
    `I want my tutor to speak as much as I do`,
    's_upcoming_lessons.half_talk',
    ``,
    ``,
    ``,
    ``,
  ],
  i_mainly_talk: [
    `튜터는 듣고 내가 주로 이야기함`,
    `I want to speak more than my tutor`,
    's_upcoming_lessons.i_mainly_talk',
    ``,
    ``,
    ``,
    ``,
  ],
  regular_calibration: [`상시교정`, `Instant Paraphrasing`, 's_upcoming_lessons.regular_calibration', ``, ``, ``, ``],
  conversation_mode: [
    `대화 교정모드`,
    `Intermittent Paraphrasing`,
    's_upcoming_lessons.conversation_mode',
    ``,
    ``,
    ``,
    ``,
  ],
  discussion_55: [`5:5 토론`, `5:5 Discussion`, 's_upcoming_lessons.discussion_55', ``, ``, ``, ``],
  discussion_82: [`8:2 토론`, `8:2 Discussion`, 's_upcoming_lessons.discussion_82', ``, ``, ``, ``],
  tutor_canceled: [`튜터 취소`, `Tutor cancelled`, 's_upcoming_lessons.tutor_canceled', ``, ``, ``, ``],
  tutor_another_time: {
    select: [
      <>튜터의 다른 시간을 선택해 주세요.</>,
      <>Please select a different time for tutor's schedule.</>,
      's_upcoming_lessons.tutor_another_time.select',
      ``,
      ``,
      ``,
      ``,
    ],
    restored: [
      <>수업 취소 시 자동으로 수업권은 복구됩니다.</>,
      <>Cancel the lesson to restore the lesson credit.</>,
      's_upcoming_lessons.tutor_another_time.restored',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  lesson_style: {
    auto_delete_in_24: [
      <Div>
        학생과 튜터 보호를 위해 모든{' '}
        <Div spanTag textBlack fontMedium>
          수업 영상은 녹화되고 영업일 기준 24시간 동안 저장
        </Div>
        합니다. 24시간이 지나면 자동 삭제됩니다.
      </Div>,
      <Div>
        To protect students & parent,{' '}
        <Div spanTag textBlack fontMedium>
          all lessons are recorded.
        </Div>{' '}
        All video recordings will be deleted after 24 hours unless a reporting has been filed on the lesson.
      </Div>,
      's_upcoming_lessons.lesson_style.auto_delete_in_24',
      ``,
      ``,
      ``,
      ``,
    ],
    not_be_deleted_for_lesson: [
      `단, 수업 분석 확인과 수업 다시 듣기를 위해 수업 녹음 파일은 삭제되지 않습니다. (파일 제작은 최대 24시간이 
소요됩니다.)`,
      `Audio recordings won't be deleted for lesson statistics and replay, which may take up to 24 hours after each lesson to process.`,
      's_upcoming_lessons.lesson_style.not_be_deleted_for_lesson',
      ``,
      ``,
      ``,
      ``,
    ],
    class_style: [`수업 스타일`, `Lesson Style`, 's_upcoming_lessons.lesson_style.class_style', ``, ``, ``, ``],
    discussion_type: [`토론형`, `Debate Mode`, 's_upcoming_lessons.lesson_style.discussion_type', ``, ``, ``, ``],
    able_to_communicate: [
      `영어로 토론을 잘하는 것이 목표입니다. 아이의 답변에 챌린지 해주시고, 튜터분의 의견도 적극 표현해 주세요.`,
      `Please ask thought-provoking follow-up questions for in-depth discussions.`,
      's_upcoming_lessons.lesson_style.able_to_communicate',
      ``,
      ``,
      ``,
      ``,
    ],
    speech_guided: [
      `발언 유도형`,
      `Student-Centered Mode`,
      's_upcoming_lessons.lesson_style.speech_guided',
      ``,
      ``,
      ``,
      ``,
    ],
    increase_confidence: [
      `영어에 대한 자신감을 높여주고 싶습니다. 적극적 경청 및 질문을 통해, 아이가 말할 수 있는 기회를 많이 주세요.`,
      `Please ask as many follow-up questions as you can to maximize the student's speaking opportunities.`,
      's_upcoming_lessons.lesson_style.increase_confidence',
      ``,
      ``,
      ``,
      ``,
    ],
    corrective_type: [`교정형`, `Correction Mode`, 's_upcoming_lessons.lesson_style.corrective_type', ``, ``, ``, ``],
    develop_expressive_vocabulary_skills: [
      `표현력/어휘력을 키워주는 것이 목표입니다. 아이의 틀린/어색한 영어 표현을 적극 교정해 주세요.`,
      `Please correct the student's incorrect or awkward grammar and vocabulary.`,
      's_upcoming_lessons.lesson_style.develop_expressive_vocabulary_skills',
      ``,
      ``,
      ``,
      ``,
    ],
    private_to_your_children: [
      `자녀에게는 비공개됩니다.`,
      `Not shown to student.`,
      's_upcoming_lessons.lesson_style.private_to_your_children',
      ``,
      ``,
      ``,
      ``,
    ],
    teaching_style: [
      `티칭 스타일 (3개까지 선택 가능)`,
      `Teaching Style (Select up to 3)`,
      's_upcoming_lessons.lesson_style.teaching_style',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  lesson_confirm: {
    auto_delete_in_24: [
      <Div>
        학생과 튜터 보호를 위해 모든{' '}
        <Div spanTag textBlack fontMedium>
          수업 영상은 녹화되고 영업일 기준 24시간 동안 저장
        </Div>
        합니다. 24시간이 지나면 자동 삭제됩니다.
      </Div>,
      <Div>
        To protect students & parents,{' '}
        <Div spanTag textBlack fontMedium>
          all lessons are recorded.
        </Div>{' '}
        All video recordings will be deleted after 24 hours unless a reporting has been filed on the lesson.
      </Div>,
      's_upcoming_lessons.lesson_confirm.auto_delete_in_24',
      ``,
      ``,
      ``,
      ``,
    ],
    teaching_style: [
      `티칭 스타일`,
      `Teaching Style`,
      's_upcoming_lessons.lesson_confirm.teaching_style',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  lesson_cancellation: [
    `수업 취소 결과`,
    `Lesson Cancellation Results`,
    's_upcoming_lessons.lesson_cancellation',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_confirmed: [
    `튜터의 배정이 확정되었으므로, 시간 변경이 불가능합니다. 
수업 취소 후 다시 신청해주세요.`,
    `As tutor confirmed the lesson, schedule cannot be changed.
Please book again after canceling the lesson.`,
    's_upcoming_lessons.tutor_confirmed',
    ``,
    ``,
    ``,
    ``,
  ],
  caf_beta: [`CAF Beta`, `CAF Beta`, 's_upcoming_lessons.caf_beta', ``, ``, ``, ``],
  caf_beta_info: [
    `CAF 베타 참여중이시네요! CAF 진단을 위해 '수업녹음하기'를 선택해 주세요.`,
    `CAF 베타 참여중이시네요! CAF 진단을 위해 '수업녹음하기'를 선택해 주세요.`,
    's_upcoming_lessons.caf_beta_info',
    ``,
    ``,
    ``,
    ``,
  ],
  caf_beta_record: [
    `CAF 진단을 위해 수업녹음 설정을 변경해주세요.`,
    `CAF 진단을 위해 수업녹음 설정을 변경해주세요.`,
    's_upcoming_lessons.caf_beta_record',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_tutors = {
  schedule: [`예약하기`, `Book lessons`, 's_tutors.schedule', ``, ``, ``, ``],
  find_a_tutor: [`튜터 찾기`, `Find a tutor`, 's_tutors.find_a_tutor', ``, ``, ``, ``],
  placeholder: [
    `원하는 튜터의 키워드를 입력해주세요. ex) Finance`,
    `Find tutors with a keyword (E.g. Finance)`,
    's_tutors.placeholder',
    ``,
    ``,
    ``,
    ``,
  ],
  see_more: [`더보기`, `See more`, 's_tutors.see_more', ``, ``, ``, ``],
  any: [`상관없음`, `Any`, 's_tutors.any', ``, ``, ``, ``],
  gender: {
    label: [`성별`, `Gender`, 's_tutors.gender.label', ``, ``, ``, ``],
    female: [`여자`, `Female`, 's_tutors.gender.female', ``, ``, ``, ``],
    male: [`남자`, `Male`, 's_tutors.gender.male', ``, ``, ``, ``],
  },
  accent: {
    label: [`액센트`, `Accent`, 's_tutors.accent.label', ``, ``, ``, ``],
    accent_american: [`미국식`, `American`, 's_tutors.accent.accent_american', ``, ``, ``, ``],
    accent_british: [`영국식`, `British`, 's_tutors.accent.accent_british', ``, ``, ``, ``],
  },
  major: {
    label: [`전공`, `Major`, 's_tutors.major.label', ``, ``, ``, ``],
    social_sciences: [`사회과학경영`, `Social Sciences/Business`, 's_tutors.major.social_sciences', ``, ``, ``, ``],
    liberal_arts: [`인문계`, `Liberal Arts`, 's_tutors.major.liberal_arts', ``, ``, ``, ``],
    engineering: [`공과계열`, `Engineering`, 's_tutors.major.engineering', ``, ``, ``, ``],
    natural_science: [`자연과학계열`, `Natural Science`, 's_tutors.major.natural_science', ``, ``, ``, ``],
    fine_arts_and_athletics: [
      `예체능`,
      `Fine Arts and Athletics`,
      's_tutors.major.fine_arts_and_athletics',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  search: [`검색`, `Search`, 's_tutors.search', ``, ``, ``, ``],
  recommended_tutor: [`추천 튜터`, `Featured`, 's_tutors.recommended_tutor', ``, ``, ``, ``],
  all_tutor: [`전체 튜터`, `All`, 's_tutors.all_tutor', ``, ``, ``, ``],
  saved_tutor: [`찜한 튜터`, `Favorites`, 's_tutors.saved_tutor', ``, ``, ``, ``],
  search_result: [`튜터 검색 결과`, `Search Results`, 's_tutors.search_result', ``, ``, ``, ``],
  no_result: [`😣  검색 결과가 없습니다`, `😣  No Result`, 's_tutors.no_result', ``, ``, ``, ``],
  accept_rate: [`수락률`, `Acceptance rate`, 's_tutors.accept_rate', ``, ``, ``, ``],
  accept_rate_meaning: [
    <>
      튜터에게 수업 요청 시 튜터가 수업을 수락할 확률 입니다. <br />
      요청하실 튜터의 수락률이 높지 않다면, 신청하는 것을 고려해보세요!
    </>,
    <>
      Indicates probability that a tutor will accept your lesson booking. <br />
      If a tutor has a low acceptance rate, try finding a different tutor!
    </>,
    's_tutors.accept_rate_meaning',
    ``,
    ``,
    ``,
    ``,
  ],
  mark_tutor: [`찜하기`, `Favorite`, 's_tutors.mark_tutor', ``, ``, ``, ``],
  tutor_profile: [`튜터 프로필`, `Tutor Profile`, 's_tutors.tutor_profile', ``, ``, ``, ``],
  student_profile: [`Student Info`, `Student Profile`, 's_tutors.student_profile', ``, ``, ``, ``],
  video_preparing: [
    <>
      튜터가 영상을 준비중입니다.
      <br />
      조금만 기다려주세요!
    </>,
    <>Introduction video will be provided soon!</>,
    's_tutors.video_preparing',
    ``,
    ``,
    ``,
    ``,
  ],
  schedule_available: [`수업 예약 가능`, `Available`, 's_tutors.schedule_available', ``, ``, ``, ``],
  schedule_fully_booked: [`수업 예약 마감`, `Fully booked`, 's_tutors.schedule_fully_booked', ``, ``, ``, ``],
  not_schedule_yet: [`스케쥴 오픈 전`, `Not available`, 's_tutors.not_schedule_yet', ``, ``, ``, ``],
  my_tutor: [`내 튜터`, `My Tutors`, 's_tutors.my_tutor', ``, ``, ``, ``],
  intro: [`자기소개`, `Intro`, 's_tutors.intro', ``, ``, ``, ``],
  review: [`수업 평가`, `Review`, 's_tutors.review', ``, ``, ``, ``],
  annoucement: [`튜터 공지사항`, `Tutor’s Announcement`, 's_tutors.annoucement', ``, ``, ``, ``],
  experience: [`핵심 경험`, `Experience`, 's_tutors.experience', ``, ``, ``, ``],
  interests: [`관심사`, `Interests`, 's_tutors.interests', ``, ``, ``, ``],
  no_junior_desc: [
    <>
      성인 수강생 대상 수업만 진행하는 튜터입니다.
      <br />
      초/중/고 수강생 예약 신청 시, 매칭되지 않을 가능성이 높습니다.
    </>,
    <>
      This tutor prefers lessons with working professionals.
      <br />
      Hence, the lesson may not be matched with juniors.
    </>,
    's_tutors.no_junior_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  save_tutor_like: [
    `같이 수업하고 싶은\n튜터를 찜해보세요.`,
    <>Favorite a tutor.</>,
    's_tutors.save_tutor_like',
    ``,
    ``,
    ``,
    ``,
  ],
  check_exp_tutor: [
    `나와 수업한 튜터를\n여기에서 확인할 수 있습니다.`,
    <>See your previous tutors.</>,
    's_tutors.check_exp_tutor',
    ``,
    ``,
    ``,
    ``,
  ],
  experienced_tutor: [`수업한 튜터`, `Prev. Tutors`, 's_tutors.experienced_tutor', ``, ``, ``, ``],
  apply: [`적용`, `Apply`, 's_tutors.apply', ``, ``, ``, ``],
  search_tutor: [`튜터 검색`, `Search`, 's_tutors.search_tutor', ``, ``, ``, ``],
  k12_lesson: [`초중고생 수업`, `K-12`, 's_tutors.k12_lesson', ``, ``, ``, ``],
  no_review_yet: [`No reviews yet.`, `No reviews yet.`, 's_tutors.no_review_yet', ``, ``, ``, ``],
  accept_rate_requested: [
    `수업 요청 시 튜터가 수락한 비율입니다.`,
    `Indicates rate that a tutor will accept your lesson booking.`,
    's_tutors.accept_rate_requested',
    ``,
    ``,
    ``,
    ``,
  ],
  no_history: [
    `지난 2주간 수업 이력이 없어 수락률이 표기되지 않습니다.`,
    `The acceptance rate is not indicated since the tutor has not conducted lessons for the past two weeks.`,
    's_tutors.no_history',
    ``,
    ``,
    ``,
    ``,
  ],
  adults_only: [`성인만 가능`, `Adults only`, 's_tutors.adults_only', ``, ``, ``, ``],
  k12ok: [`초중고생 가능`, `K-12 OK`, 's_tutors.k12ok', ``, ``, ``, ``],
  review_complete: [`모두 조회했습니다.`, `Review complete`, 's_tutors.review_complete', ``, ``, ``, ``],
  no_reviews_yet: [`아직 등록된 수업 평가가 없습니다.`, `No reviews yet`, 's_tutors.no_reviews_yet', ``, ``, ``, ``],
  new_tutor: [
    `새로 온 튜터`,
    `Rookie Tutors`,
    's_tutors.new_tutor',
    `新教师`,
    `新教師`,
    `新しく来たチューター`,
    `Gia sư mới`,
  ],
  new_tutor_detail_info: {
    title: [
      `수업후기 남기고 수업료 50%를 돌려받으세요.`,
      `Write a review and get 50% value back.`,
      's_tutors.new_tutor_detail_info.title',
      `提交课程评价，领取課程費费用50%积分。`,
      `撰寫課程評價，領取50%課程費用回饋點數。`,
      `レビューを書くとレッスン料の50%を！`,
      `Viết đánh giá và nhận lại 50% học phí.`,
    ],
    desc0: [
      `새로 온 튜터와 수업 후 수업후기를 남기면 수업료의 50%를 포인트로 돌려드려요.`,
      `Get 50% of the lesson fee back in points by leaving a review.`,
      's_tutors.new_tutor_detail_info.desc0',
      `提交与新教師上课的课后评价，可获得課程費费用50%积分回馈。`,
      `撰寫與新教師上課的課後評價，可獲得50%課程費用回饋點數。`,
      `新規チューターのレビューを残すと料金の50％をポイントで受け取れます。`,
      `Nhận 50% hoàn học phí bằng Điểm khi viết nhận xét.`,
    ],
    desc1: [
      `유료 수업권으로 예약한 수업 중 새로 온 튜터와의 수업에 한해 최종 결제 금액 기준 1회 수업료의  50%를 포인트로 드립니다.`,
      `Applicable to paid lessons with Rookie Tutors only / Eligible points will be calculated based on billed payment.`,
      's_tutors.new_tutor_detail_info.desc1',
      `仅限用付费课程券预约新教师的课程，以最终结算金额为基准，1次课程费用的50%将用积分回馈。`,
      `僅限用付費課程券預約新教師的課程，以最終結算金額為基準，1次課程費用的50%將用點數回饋。`,
      `有料レッスンチケットで予約したレッスン中、新しく来たチューターとのレッスンに限り最終決済金額基準の1回レッスン料の50％をポイントで差し上げます。`,
      `50% điểm được cộng 1 lần theo hóa đơn thanh toán gần nhất, chỉ áp dụng cho buổi học trả phí với gia sư mới.`,
    ],
    desc2: [
      `새로 온 튜터와의 수업이 만족스럽지 않으면, 수업권을 복구해 드립니다. (유료 수업권으로 예약 후 튜터 평가 3점 이하 및 사유 제출, 월 2회 한정)`,
      `Credit restored if unsatisfied with the Rookie Tutor. (Rate as 3 stars or less after a paid lesson with detailed reason, twice per month limit)`,
      's_tutors.new_tutor_detail_info.desc2',
      `如果对新教师的授课表现不满意，我们将退还课程券。 （用付费课程券预约后，教师评价不足3分时提出原因，每月限2次）`,
      `如果對新教師的授課表現不滿意，我們將退還課程券。 （用付費課程券預約後，教師評價不足3分時提出原因，每月限2次）`,
      `新規チューターに満足できなかったら、レッスンチケットを復旧いたします。（有料レッスン/評価３点以下/理由提出、月２回限定）`,
      `Phiếu học được hoàn nếu không hài lòng với tiết học cùng gia sư mới (tối đa 2 lần/ tháng cho phiếu học trả phí, đánh giá gia sư 3 sao kèm lý do)`,
    ],
  },
  tutor_profile_title: [
    `새로 온 튜터 50% 포인트백`,
    `Rookie Tutors 50% Value Back`,
    's_tutors.tutor_profile_title',
    `新教师50% 积分回馈`,
    `新教師50% 點數回饋`,
    `新しく来たチューター50% Point Back`,
    `Gia sư mới50% Point Back`,
  ],
  tutor_profile_desc1: (name) => [
    <>{name} 튜터와 수업 후 수업평가를 남기면 수업료의 50%를 포인트로 돌려드려요.</>,
    <>Take a lesson from tutor {name} and leave a review to get 50% value back in points.</>,
    's_tutors.tutor_profile_desc1',
    <>留下{name}教师的课后评价，即可获得课程费用的50％积分回馈。</>,
    <>留下{name}教師的課後評價，即可獲得課程費用的50％點數回饋。</>,
    <>{name}チューターとレッスン後レッスン評価を残したらレッスン料の50％をポイントで差し上げます。</>,
    <>Sau buổi học với gia sư {name}, để lại đánh giá để nhận lại 50% học phí dưới dạng điểm.</>,
  ],
  tutor_profile_desc2: [
    `유료 수업권으로 예약한 수업에 한하여 최종 결제 금액 기준 1회 수업료의  50%를 포인트로 드립니다.`,
    `Book the lesson using a paid credit to receive 50% of the lesson fee (based on billed payment) back in points.`,
    's_tutors.tutor_profile_desc2',
    `仅限于用付费课程券预约的课程，以最终结算金额为基准，返还1次课费的50%积分。`,
    `僅限於用收費課程券預約的課程，以最終結算金額為基準，返還1次課費的50%點數。`,
    `有料レッスンチケットで予約したレッスン中、新しく来たチューターとのレッスンに限り最終決済金額基準の1回レッスン料の50％をポイントで差し上げます。`,
    `50% điểm sẽ được cộng 1 lần dựa trên số tiền thanh toán cuối cùng, chỉ áp dụng cho buổi học được đặt lịch bằng phiếu trả phí.`,
  ],
  new_tutor_desc: [
    `10대 1의 경쟁률을 뚫고 선발된 튜터와 만나보세요.`,
    `Meet our highly-qualified Rookie Tutors.`,
    's_tutors.new_tutor_desc',
    `和新教师上课`,
    `和新教師上課`,
    `10対1の競争率を勝ち抜いた新規チューターはこちら。`,
    `Gặp gỡ nhóm gia sư mới chất lượng cao`,
  ],
  recommended_tutor_desc: [
    `최근 한 달간 높은 수업 평점과 긍정적인 후기를 받은 튜터에요.`,
    `These tutors have received high ratings and positive reviews.`,
    's_tutors.recommended_tutor_desc',
    `最近一个月获得高评分及正面评价的教师`,
    `最近一個月獲得高評分及正面評價的教師`,
    `ここ一ヶ月、高い評価とレビューを維持しているチューターです。`,
    `Gia sư được xếp hạng cao và phản hồi tích cực.`,
  ],
  new_tutor_viewall: [
    `전체보기`,
    `View All`,
    's_tutors.new_tutor_viewall',
    `查看全部`,
    `查看全部`,
    `すべて見る`,
    `Xem tất cả`,
  ],
  search_all: [`전체`, `All`, 's_tutors.search_all', `全部`, `全部`, `すべて`, `Tất cả`],
  keyword_title: [`키워드`, `Keyword`, 's_tutors.keyword_title', `Keyword`, `Keyword`, `Keyword`, ``],
  review_explanation: [
    `이 튜터의 수업을 직접 수강한 고객님들이 선택한 키워드 중 상위 5개에 대한 통계입니다.`,
    `Top 5 keywords students have used to describe the tutor's lesson style`,
    's_tutors.review_explanation',
    `学生给老师的Top 5课程特色形容词`,
    `學生給老師的Top 5課程特色形容詞`,
    `当チューターのレッスンを実際に受講したお客様が選んだキーワードのうち上位5つに対する統計です。`,
    `Top 5 từ khoá học viên dùng để miêu tả phong cách giảng dạy của gia sư`,
  ],
  profile_overview: [`요약`, `Summary`, 's_tutors.profile_overview', `简介`, `簡介`, `要約`, `Tóm tắt`],
  read_more_about_intro: [
    `자기소개 더 읽기`,
    `Read more`,
    's_tutors.read_more_about_intro',
    `自我介绍`,
    `自我介紹`,
    `もっと見る`,
    `Đọc thêm`,
  ],
  read_more_about_review: [
    `수업 평가 전체 보기`,
    `View all`,
    's_tutors.read_more_about_review',
    `课程评价`,
    `課程評價`,
    `全体を見る`,
    `Xem toàn bộ`,
  ],
  video_updated_at: (year, month, day) => [
    <>
      {year}.{month}.{day} 촬영
    </>,
    <>
      Filmed on {month}.{day}.{year}
    </>,
    's_tutors.video_updated_at',
    <>
      拍摄日期 {month}.{day}.{year}
    </>,
    <>
      拍攝日期 {month}.{day}.{year}
    </>,
    <>
      {year}.{month}.{day} 撮影
    </>,
    <>
      Ghi hình ngày {month}.{day}.{year}
    </>,
  ],
  no_my_tutor: [
    <>
      먼저 '<Span bold>+튜터 추가</Span>'에서 관심있는 튜터들을 찜하세요!{'\n'}찜한 튜터들의 수업 스케줄을 확인하시고
      수업을 예약하실 수 있습니다.
    </>,
    <>
      Please '<Span bold>+add tutors</Span>' to your favorites first.{'\n'}Then you can check your favorite tutors'
      availabilities and book lessons here.
    </>,
    's_tutors.no_my_tutor',
    <>
      请先在「<Span bold>+新增任课教师</Span>」收藏您喜欢的任课教师！{'\n'}
      您可确认所收藏的任课教师的上课日程并预约课程。
    </>,
    <>
      請先於「<Span bold>+新增任課教師</Span>」收藏您喜歡的任課教師！{'\n'}您可確認您收藏的任課教師上課日程並預約課程。
    </>,
    <>
      先に '<Span bold>+チューター追加</Span>'から関心があるチューターたちをお気に入り登録してください！{'\n'}
      お気に入りチューターたちのレッスンスケジュールを確認してからレッスンを予約することができます。
    </>,
    <>
      Vui lòng '<Span bold>+thêm gia sư</Span>' vào mục yêu thích trước.{'\n'}Sau đó bạn có thể kiểm tra lịch trống của
      gia sư yêu thích và đặt học ở đây.
    </>,
  ],
  no_available_schedule: [
    `가능한 스케쥴이 없습니다.`,
    `There is no availability`,
    's_tutors.no_available_schedule',
    `无可进行的日程`,
    `無可進行的日程`,
    `空いているスケジュールがありません`,
    `Không có lịch trống`,
  ],
}
export const s_portal_home = {
  reserve_lessons: [`수업예약`, `Book`, 's_portal_home.reserve_lessons', ``, ``, ``, ``],
  time_left: (days, hours, minutes) => [
    <>
      {days <= 0 ? '' : days + '일 '}
      {hours <= 0 ? '' : hours + '시간 '}
      {minutes <= 0 ? '' : minutes + '분 '}남음
    </>,
    <>
      {days <= 0 ? '' : days + 'd '}
      {hours <= 0 ? '' : hours + 'h '}
      {minutes <= 0 ? '' : minutes + 'm '}left
    </>,
    's_portal_home.time_left',
    ``,
    ``,
    ``,
    ``,
  ],
  start_after_min: (time) => [
    `${time}분 후 시작`,
    `Starts in ${time} minutes`,
    's_portal_home.start_after_min',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_start_past_min: (time) => [
    `수업시간 ${time} 분 지남`,
    `Started ${time} min ago`,
    's_portal_home.lesson_start_past_min',
    ``,
    ``,
    ``,
    ``,
  ],
  webinar_start_past_min: (time) => [
    `${time} 분 전부터 진행 중`,
    `Started ${time} min ago`,
    's_portal_home.webinar_start_past_min',
    ``,
    ``,
    ``,
    ``,
  ],
  hello: (name) => [<>안녕하세요 {name}님!</>, <>Hello {name}!</>, 's_portal_home.hello', ``, ``, ``, ``],
  trial_card_title: [`선착순 마감 혜택`, `First-Come First-Served`, 's_portal_home.trial_card_title', ``, ``, ``, ``],
  trial_card: [
    <>
      20분 무료수업
      <br />
      예약하기
    </>,
    <>
      Try 20-min <br />
      lesson now!
    </>,
    's_portal_home.trial_card',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit_1: [
    <>
      최대 3회 수업 제공
      <br />+ 10,000원 추가 할인
    </>,
    <>
      Max 3 extra lessons
      <br />+ ₩10,000 Discount{' '}
    </>,
    's_portal_home.benefit_1',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit_2: [
    <>첫 수업 불만족 시 100% 환불 보장</>,
    <>100% Money-back guarantee</>,
    's_portal_home.benefit_2',
    ``,
    ``,
    ``,
    ``,
  ],
  no_coupons: [
    <>
      체험 없이 구매하고
      <br />
      <span className="text-primary">최대 혜택</span> 받기
    </>,
    <>
      <span className="text-primary">Best offer</span> available without trial lesson
    </>,
    's_portal_home.no_coupons',
    ``,
    ``,
    ``,
    ``,
  ],
  purchase: [`구매하기`, `Buy now`, 's_portal_home.purchase', ``, ``, ``, ``],
  purchaseCredit: [
    <>
      첫 구매{' '}
      <Div spanTag textSecondary>
        할인 혜택
      </Div>
    </>,
    <>
      Benefits on{' '}
      <Div spanTag textSecondary>
        first purchase
      </Div>
    </>,
    's_portal_home.purchaseCredit',
    ``,
    ``,
    ``,
    ``,
  ],
  upcoming: [`예정된 수업`, `Upcoming Lessons`, 's_portal_home.upcoming', ``, ``, ``, ``],
  reviewed_yet2: [`복습 미완료 수업`, `Lessons to review`, 's_portal_home.reviewed_yet2', ``, ``, ``, ``],
  liveWebinar2: [`웨비나 라이브`, `Live Webinar`, 's_portal_home.liveWebinar2', ``, ``, ``, ``],
  tutor: [`튜터`, `Tutors`, 's_portal_home.tutor', ``, ``, ``, ``],
  tutor_like_added: [
    `튜터 > 내튜터: 찜한 튜터에 추가되었습니다.`,
    `Added to Favorites under Tutors > My Tutors`,
    's_portal_home.tutor_like_added',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_like_deleted: [
    `튜터 > 내 튜터: 찜한 튜터에서 삭제되었습니다.`,
    `Removed from Favorite Tutors`,
    's_portal_home.tutor_like_deleted',
    ``,
    ``,
    ``,
    ``,
  ],
  confirm_delete: [
    `콘텐츠 > 내 콘텐츠에서 삭제하시겠습니까?`,
    `Are you sure you want to remove from Content > My Content?`,
    's_portal_home.confirm_delete',
    ``,
    ``,
    ``,
    ``,
  ],
  cancel: [`취소`, `Cancel`, 's_portal_home.cancel', ``, ``, ``, ``],
  remove: [`삭제`, `Remove`, 's_portal_home.remove', ``, ``, ``, ``],
  every_minute_dose_of_insight_english_everyday: [
    `하루 5분으로 쌓는 상식과 영어`,
    `5-minute dose of insight & English`,
    's_portal_home.every_minute_dose_of_insight_english_everyday',
    ``,
    ``,
    ``,
    ``,
  ],
  this_week_s_topic: [`이번 주 주제`, `Weekly Topic`, 's_portal_home.this_week_s_topic', ``, ``, ``, ``],
  today_s_tutor: [`오늘의 튜터`, `Tutor Highlights`, 's_portal_home.today_s_tutor', ``, ``, ``, ``],
  check_out_tutors_recommended_by_ringle_users_: [
    `링글 수강생이 추천하는 튜터를 확인해 보세요.`,
    `Check out tutors recommended by Ringle users.`,
    's_portal_home.check_out_tutors_recommended_by_ringle_users_',
    ``,
    ``,
    ``,
    ``,
  ],
  view_all: [`전체 보기`, `See All`, 's_portal_home.view_all', ``, ``, ``, ``],
  latest_packets: [`새로나온 교재`, `Latest Materials`, 's_portal_home.latest_packets', ``, ``, ``, ``],
  read_new_articles_released_every_week_: [
    `매일 업데이트되는 교재를 읽고, 수업을 바로 예약해 보세요!`,
    `Read new materials released every week.`,
    's_portal_home.read_new_articles_released_every_week_',
    ``,
    ``,
    ``,
    ``,
  ],
  featured_webinars: [`Upcoming 웨비나`, `Upcoming Webinar`, 's_portal_home.featured_webinars', ``, ``, ``, ``],
  watch_video_lectures_taught_by_handpicked_tutors_: [
    `링글의 인기 튜터가 진행하는 LIVE 웨비나를 신청해 보세요.`,
    `Attend live webinars hosted by Ringle's top tutors.`,
    's_portal_home.watch_video_lectures_taught_by_handpicked_tutors_',
    ``,
    ``,
    ``,
    ``,
  ],
  confirm_delete_tutor: [
    `[튜터 > 내 튜터] 찜한 튜터에서 삭제하시겠습니까?`,
    `Are you sure you want to remove from [Tutors > My Tutors] Favorite?`,
    's_portal_home.confirm_delete_tutor',
    ``,
    ``,
    ``,
    ``,
  ],
  unlike: [`찜하기 해제`, `Remove from Favorites`, 's_portal_home.unlike', ``, ``, ``, ``],
  material_like_added: [
    `[콘텐츠 > 내 콘텐츠] 찜한 교재에 추가되었습니다.`,
    `Added to [Content > My Content] Favorite Materials.`,
    's_portal_home.material_like_added',
    ``,
    ``,
    ``,
    ``,
  ],
  material_like_deleted: [
    `[콘텐츠 > 내 콘텐츠] 찜한 교재에서 삭제되었습니다.`,
    `Removed from [Content > My Content] Favorite Materials.`,
    's_portal_home.material_like_deleted',
    ``,
    ``,
    ``,
    ``,
  ],
  brief_like_added: [
    `[콘텐츠 > 내 콘텐츠] 찜한 데일리 브리프에 추가되었습니다.`,
    `Added to [Content > My Content] Favorite Daily Briefs.`,
    's_portal_home.brief_like_added',
    ``,
    ``,
    ``,
    ``,
  ],
  brief_like_deleted: [
    `[콘텐츠 > 내 콘텐츠] 찜한 데일리 브리프에서 삭제되었습니다.`,
    `Removed from [Content > My Content] Favorite Daily Briefs.`,
    's_portal_home.brief_like_deleted',
    ``,
    ``,
    ``,
    ``,
  ],
  webinar_like_added: [
    `[콘텐츠 > 내 콘텐츠] 찜한 웨비나에 추가되었습니다.`,
    `Added to [Content > My Content] Saved Webinars.`,
    's_portal_home.webinar_like_added',
    ``,
    ``,
    ``,
    ``,
  ],
  webinar_like_deleted: [
    `[콘텐츠 > 내 콘텐츠] 찜한 웨비나에서 삭제되었습니다.`,
    `Removed from [Content > My Content] Saved Webinars.`,
    's_portal_home.webinar_like_deleted',
    ``,
    ``,
    ``,
    ``,
  ],
  title_ot: [`오리엔테이션 신청하기`, `Register Orientation`, 's_portal_home.title_ot', ``, ``, ``, ``],
  subtitle_ot: [`링글 수업 200% 활용법`, `Make the best of Ringle`, 's_portal_home.subtitle_ot', ``, ``, ``, ``],
  benefit_3: [<>최대 100,000원 추가 할인</>, <>Up to $77 Discount</>, 's_portal_home.benefit_3', ``, ``, ``, ``],
  purchase_welcome: [
    <>
      신규회원{' '}
      <Div spanTag textSecondary>
        웰컴 쿠폰
      </Div>
    </>,
    <Div textSecondary>Welcome Coupon</Div>,
    's_portal_home.purchase_welcome',
    ``,
    ``,
    ``,
    ``,
  ],
  gnb: {
    welcome_coupon: (day) => [
      <>
        신규회원 한정 3만원 웰컴쿠폰이{' '}
        <Div spanTag fontBold>
          {day}
        </Div>{' '}
        사라집니다
      </>,
      <>
        Last {day} for{' '}
        <Div spanTag fontBold>
          $25 off welcome offers
        </Div>
      </>,
      's_portal_home.gnb.welcome_coupon',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  popup: {
    welcome_coupon: {
      title_1: [`신규회원 한정`, `Welcome Gift`, 's_portal_home.popup.welcome_coupon.title_1', ``, ``, ``, ``],
      title_2: [
        `웰컴쿠폰 발급 안내`,
        `for new members only`,
        's_portal_home.popup.welcome_coupon.title_2',
        ``,
        ``,
        ``,
        ``,
      ],
      footer: [
        <>
          *쿠폰 발급 후 7일 이내 사용 가능합니다.
          <br />
        </>,
        <>
          * Valid for 7 days after issuance.
          <br />* Only redeemable toward regular packages worth $169 or more.
        </>,
        's_portal_home.popup.welcome_coupon.footer',
        ``,
        ``,
        ``,
        ``,
      ],
      btn_1: [`혜택 확인하기`, `See Details`, 's_portal_home.popup.welcome_coupon.btn_1', ``, ``, ``, ``],
      btn_2: [`닫기`, `Close`, 's_portal_home.popup.welcome_coupon.btn_2', ``, ``, ``, ``],
      btn_3: [`일주일간 보지 않기`, `Don't show again`, 's_portal_home.popup.welcome_coupon.btn_3', ``, ``, ``, ``],
    },
  },
  silicon_challenge: {
    title: [`실리콘밸리 챌린지`, `Silicon Valley Challenge`, 's_portal_home.silicon_challenge.title', ``, ``, ``, ``],
    time_left: (day, number) => [
      <>🔥 {day}일 남음</>,
      <>
        🔥 {day} day{number > 1 ? 's' : ''} left
      </>,
      's_portal_home.silicon_challenge.time_left',
      ``,
      ``,
      ``,
      ``,
    ],
    detail: (participants) => [
      <>🎯 {participants}명이 함께하고 있어요.</>,
      <>🎯 {participants} Ringlers participating</>,
      's_portal_home.silicon_challenge.detail',
      ``,
      ``,
      ``,
      ``,
    ],
    silicon_percent: (percent) => [
      <>{percent}%</>,
      <>{percent}%</>,
      's_portal_home.silicon_challenge.silicon_percent',
      ``,
      ``,
      ``,
      ``,
    ],
    silicon_lessons: (number, count) => [
      <>
        <span style={{ color: '#F66B0E' }}>{number}</span>/{count}회 달성
      </>,
      <>
        <span style={{ color: '#F66B0E' }}>{number}</span>/{count} Lessons
      </>,
      's_portal_home.silicon_challenge.silicon_lessons',
      ``,
      ``,
      ``,
      ``,
    ],
    monthly_mission: (month) => [
      <>{month}월 미션</>,
      <>{month} Mission</>,
      's_portal_home.silicon_challenge.monthly_mission',
      ``,
      ``,
      ``,
      ``,
    ],
    monthly_lessons: (number, count) => [
      <>
        <span className="text-black">{number}</span>/{count}회 완료
      </>,
      <>
        {number}/{count} Lessons
      </>,
      's_portal_home.silicon_challenge.monthly_lessons',
      ``,
      ``,
      ``,
      ``,
    ],
    last_week: [`마지막 주`, `Last Week`, 's_portal_home.silicon_challenge.last_week', ``, ``, ``, ``],
    last_week_msg: [
      `챌린지 기간이 얼마 안남았어요!`,
      `There's not much time left!`,
      's_portal_home.silicon_challenge.last_week_msg',
      ``,
      ``,
      ``,
      ``,
    ],
    start_challenge: [
      `챌린지 시작하기!`,
      `Start Challenge!`,
      's_portal_home.silicon_challenge.start_challenge',
      ``,
      ``,
      ``,
      ``,
    ],
    menu_tooltip: [`무료 수업권`, `Free Credit`, 's_portal_home.silicon_challenge.menu_tooltip', ``, ``, ``, ``],
    msg: [
      <>
        수업도 듣고,
        <br />
        <Div spanTag fontBold>
          챌린지 혜택
        </Div>
        도 받으세요.
      </>,
      <>
        <Div spanTag fontBold>
          Earn rewards
        </Div>{' '}
        just by taking Ringle lessons!
      </>,
      's_portal_home.silicon_challenge.msg',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_join_challenge: [
      `1초만에 신청하기`,
      `Start now`,
      's_portal_home.silicon_challenge.btn_join_challenge',
      ``,
      ``,
      ``,
      ``,
    ],
    see_detail: [`자세히 보기`, `See details`, 's_portal_home.silicon_challenge.see_detail', ``, ``, ``, ``],
    forty: [`40분 수업권`, `40min credit`, 's_portal_home.silicon_challenge.forty', ``, ``, ``, ``],
    twenty: [`20분 수업권`, `20min credit`, 's_portal_home.silicon_challenge.twenty', ``, ``, ``, ``],
    point: [`참여 혜택 포인트`, `Points earned`, 's_portal_home.silicon_challenge.point', ``, ``, ``, ``],
    benefit_left: [
      `다음 포인트 획득까지`,
      `Next points`,
      's_portal_home.silicon_challenge.benefit_left',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_ticket: [
      `여행 응모권 획득까지`,
      `Raffle ticket`,
      's_portal_home.silicon_challenge.benefit_ticket',
      ``,
      ``,
      ``,
      ``,
    ],
    left: (number) => [
      <>{number}회 남음</>,
      <>
        {number} lesson{number > 1 ? 's' : ''} left
      </>,
      's_portal_home.silicon_challenge.left',
      ``,
      ``,
      ``,
      ``,
    ],
    date: [
      `06.08(수) - 10.06(목)`,
      `Jun. 08 (Wed) - Oct. 06 (Thu)`,
      's_portal_home.silicon_challenge.date',
      ``,
      ``,
      ``,
      ``,
    ],
    msg1: (point) => [
      <>
        수업만 들어도 챌린지 혜택을 드립니다! 지금 참여하시면{' '}
        <span style={{ color: '#F66B0E' }} className="ml-[4px]">
          {point}P
        </span>
        가 즉시 적립됩니다.
      </>,
      <>
        Join the challenge now and instantly get{' '}
        <span style={{ color: '#F66B0E' }} className="ml-[4px]">
          {point} points
        </span>
        .{' '}
      </>,
      's_portal_home.silicon_challenge.msg1',
      ``,
      ``,
      ``,
      ``,
    ],
    msg2: [
      `수업만 들어도 챌린지 혜택을 드립니다!`,
      `Earn rewards just by taking Ringle lessons!`,
      's_portal_home.silicon_challenge.msg2',
      ``,
      ``,
      ``,
      ``,
    ],
    mission: (number) => [
      <>{number}회 완강 시 무료 수업권 증정</>,
      <>Free credit rewarded for completing {number} lessons</>,
      's_portal_home.silicon_challenge.mission',
      ``,
      ``,
      ``,
      ``,
    ],
    msg3: [
      `수업만 들어도 챌린지 혜택을 드립니다! 수업권을 구매하고 챌린지에 참여하세요.`,
      `Buy credits now and join the challenge.`,
      's_portal_home.silicon_challenge.msg3',
      ``,
      ``,
      ``,
      ``,
    ],
    oct_details: [
      `스탬프 6개 채우고 수업권 받기`,
      `Collect 6 stamps!`,
      's_portal_home.silicon_challenge.oct_details',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  label_today: [`Today`, `Today`, 's_portal_home.label_today', ``, ``, ``, ``],
  coupon: {
    title_welcome: [`신규회원 쿠폰`, `Welcome Coupon`, 's_portal_home.coupon.title_welcome', ``, ``, ``, ``],
    desc_welcome: [
      <>
        <Div spanTag fontBold>
          최대 20만원 할인
        </Div>{' '}
        받는 지금이
        <br />
        링글 시작하기 가장 좋은 타이밍!
      </>,
      <>
        Start Ringle now
        <br />&{' '}
        <Div spanTag fontBold>
          get up to $153 off!
        </Div>
      </>,
      's_portal_home.coupon.desc_welcome',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`수업권 구매하기`, `Buy Credits`, 's_portal_home.coupon.btn', ``, ``, ``, ``],
    title_next: [`재구매 쿠폰`, `Next Purchase`, 's_portal_home.coupon.title_next', ``, ``, ``, ``],
    desc_next: [
      <>
        최대 10만원 추가 할인된
        <br />
        가격으로 수업권을 구매하세요!
      </>,
      <>
        Buy credits at prices
        <br />
        discounted up to $77!
      </>,
      's_portal_home.coupon.desc_next',
      ``,
      ``,
      ``,
      ``,
    ],
    title_secret: [`시크릿 쿠폰 할인`, `Secret Offer`, 's_portal_home.coupon.title_secret', ``, ``, ``, ``],
    desc_secret: [
      <>
        23년 가격 인상 전 마지막
        <br />
        최대 30만원 추가 할인!
      </>,
      <>
        Lowest prices before 2023
        <br />
        Get up to extra $230 off!
      </>,
      's_portal_home.coupon.desc_secret',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  default_stat: {
    title: (name, n) => [
      <>
        {name}님의
        <br />
        링글 학습 {n}일째
      </>,
      <>
        {name}'s
        <br />
        Day {n} with Ringle
      </>,
      's_portal_home.default_stat.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc1: [`지금까지 링글 수업`, `Completed`, 's_portal_home.default_stat.desc1', ``, ``, ``, ``],
    desc2: [`이번 달 링글 수업`, `This month`, 's_portal_home.default_stat.desc2', ``, ``, ``, ``],
    desc3: [`연속 수업 진행`, `In a row`, 's_portal_home.default_stat.desc3', ``, ``, ``, ``],
    total: (n) => [
      <>{n}회</>,
      <>
        {n} {n > 1 ? 'lessons' : 'lesson'}
      </>,
      's_portal_home.default_stat.total',
      ``,
      ``,
      ``,
      ``,
    ],
    this_month: (n) => [
      <>{n}회</>,
      <>
        {n} {n > 1 ? 'lessons' : 'lesson'}
      </>,
      's_portal_home.default_stat.this_month',
      ``,
      ``,
      ``,
      ``,
    ],
    week: (n) => [
      <>{n}주</>,
      <>
        {n} {n > 1 ? 'weeks' : 'week'}
      </>,
      's_portal_home.default_stat.week',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  to_do: {
    msg_list: [
      `링글이 처음이라면 아래 목록을 진행해보세요!`,
      `If you're new to Ringle, check out the list below!`,
      's_portal_home.to_do.msg_list',
      ``,
      ``,
      ``,
      ``,
    ],
    btn1: [`링글 사용법 알아보기`, `How to use Ringle`, 's_portal_home.to_do.btn1', ``, ``, ``, ``],
    btn2: [`OT 신청하기`, `Sign up for Orientation`, 's_portal_home.to_do.btn2', ``, ``, ``, ``],
    btn3: [`튜터 찜하기`, `Add tutors to [My Tutors]`, 's_portal_home.to_do.btn3', ``, ``, ``, ``],
    btn4: [
      `교재 찜하기`,
      <>
        Add materials
        <br />
        to [My Content]
      </>,
      's_portal_home.to_do.btn4',
      ``,
      ``,
      ``,
      ``,
    ],
    placeholder_book: [
      `예정된 수업이 없습니다. 수업을 예약해 보세요!`,
      `You have no scheduled lesson. Why don't you book one?`,
      's_portal_home.to_do.placeholder_book',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_book: [`수업 예약하기`, `Book a Lesson`, 's_portal_home.to_do.btn_book', ``, ``, ``, ``],
    placeholder_purchase: [
      `수업권을 구매하고 수업을 시작해 보세요!`,
      `Buy credits and start taking lessons!`,
      's_portal_home.to_do.placeholder_purchase',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_purchase: [`수업권 구매하기`, `Buy Credits`, 's_portal_home.to_do.btn_purchase', ``, ``, ``, ``],
    msg_prep: [
      `예습을 진행하고 더 효과적인 수업을 경험해 보세요.`,
      `Check and prep for your upcoming lesson.`,
      's_portal_home.to_do.msg_prep',
      ``,
      ``,
      ``,
      ``,
    ],
    title_lesson_crad: [`예정된 수업`, `Upcoming Lesson`, 's_portal_home.to_do.title_lesson_crad', ``, ``, ``, ``],
    btn_prep: [`예습하기`, `Lesson Prep`, 's_portal_home.to_do.btn_prep', ``, ``, ``, ``],
    msg_lesson: [
      `곧 수업이 시작됩니다! 수업에 입장해 주세요.`,
      `The lesson is about to start! Please enter the lesson.`,
      's_portal_home.to_do.msg_lesson',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_lesson: [`입장하기`, `Enter Lesson`, 's_portal_home.to_do.btn_lesson', ``, ``, ``, ``],
    msg_cancel: (time) => [
      <>튜터의 피치 못할 사정으로 {time} 수업이 취소되었습니다. 진심으로 사과드립니다.</>,
      <>We are sorry. The lesson scheduled for {time} has been canceled due to the tutor’s unforeseen circumstance. </>,
      's_portal_home.to_do.msg_cancel',
      ``,
      ``,
      ``,
      ``,
    ],
    msg_automatch: (time) => [
      <>튜터의 피치 못할 사정으로 {time} 수업이 취소되었습니다. 빠르게 다른 튜터를 찾아 매칭하겠습니다.</>,
      <>We are sorry. The lesson scheduled for {time} has been canceled. We will assign a new tutor for you.</>,
      's_portal_home.to_do.msg_automatch',
      ``,
      ``,
      ``,
      ``,
    ],
    msg_start_lesson: [
      `효과적인 수업을 위해 미리 교재를 읽고 수업 질문을 선택해 주세요.`,
      `Please read the lesson material and choose questions in advance.`,
      's_portal_home.to_do.msg_start_lesson',
      ``,
      ``,
      ``,
      ``,
    ],
    msg_prep_freetopic: [
      `효과적인 수업을 위해 수업 자료를 미리 업로드해 주세요.`,
      `Please upload the lesson materials in advance.`,
      's_portal_home.to_do.msg_prep_freetopic',
      ``,
      ``,
      ``,
      ``,
    ],
    tutor_not_matched_yet: [
      `튜터가 배정 중입니다.`,
      `We're finding a tutor for you.`,
      's_portal_home.to_do.tutor_not_matched_yet',
      ``,
      ``,
      ``,
      ``,
    ],
    msg_lesson_started: [
      `수업이 시작되었습니다! 수업에 입장해 주세요.`,
      `The lesson has already begun! Please enter the lesson.`,
      's_portal_home.to_do.msg_lesson_started',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  monthly_challenge: {
    msg: [
      `오늘부터 링글팀과 영어 공부 루틴을 만들어보세요!`,
      `Make a routine of studying English with Ringle!`,
      's_portal_home.monthly_challenge.msg',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_join: [`챌린지 참여하기`, `Join Challenge`, 's_portal_home.monthly_challenge.btn_join', ``, ``, ``, ``],
    title_oct: [`10월 수업 챌린지`, `October Challenge`, 's_portal_home.monthly_challenge.title_oct', ``, ``, ``, ``],
    title_nov: [`11월 수업 챌린지`, `November Challenge`, 's_portal_home.monthly_challenge.title_nov', ``, ``, ``, ``],
    title_dec: [`12월 수업 챌린지`, `December Challenge`, 's_portal_home.monthly_challenge.title_dec', ``, ``, ``, ``],
    mission: [`보너스 스탬프 미션`, `Bonus Mission`, 's_portal_home.monthly_challenge.mission', ``, ``, ``, ``],
    mission_board: [
      `4주 연속 수업 듣기 현황`,
      `Status for taking lessons 4 weeks in a row`,
      's_portal_home.monthly_challenge.mission_board',
      ``,
      ``,
      ``,
      ``,
    ],
    streak: [
      `이번 달 4주 연속 수업 듣기`,
      `Take lessons 4 weeks in a row`,
      's_portal_home.monthly_challenge.streak',
      ``,
      ``,
      ``,
      ``,
    ],
    fast_lesson: (date) => [
      <>{date}까지 첫 수업 듣기</>,
      <>Take first lesson by {date}</>,
      's_portal_home.monthly_challenge.fast_lesson',
      ``,
      ``,
      ``,
      ``,
    ],
    see_detail: [`자세히 보기`, `See details`, 's_portal_home.monthly_challenge.see_detail', ``, ``, ``, ``],
    modal_title_mkt: [
      `챌린지 참여 신청`,
      `Join the challenge`,
      's_portal_home.monthly_challenge.modal_title_mkt',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_desc: [
      `챌린지에 참여하게 되면 챌린지 관련 정보 전달을 위해 [이벤트/혜택] 알림 수신이 켜집니다.`,
      `Clicking on [Join Challenge] will turn on the [Events & offers] notifications to receive information about the challenge.`,
      's_portal_home.monthly_challenge.modal_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_btn1: [`나중에 다시 보기`, `Skip for Now`, 's_portal_home.monthly_challenge.modal_btn1', ``, ``, ``, ``],
    modal_btn2: [`챌린지 참여하기`, `Join Challenge`, 's_portal_home.monthly_challenge.modal_btn2', ``, ``, ``, ``],
    toast: (date) => [
      <>{date} 마케팅 수신에 동의하셨습니다.</>,
      <>You agreed to receive information on Ringle's events & offers on {date}.</>,
      's_portal_home.monthly_challenge.toast',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_title: [`수업 챌린지`, `Monthly Challenge`, 's_portal_home.monthly_challenge.modal_title', ``, ``, ``, ``],
    oct: [`10월 챌린지`, `October`, 's_portal_home.monthly_challenge.oct', ``, ``, ``, ``],
    date_oct: [
      `2022. 10. 1 - 2022. 10. 31`,
      `2022. 10. 1 - 2022. 10. 31`,
      's_portal_home.monthly_challenge.date_oct',
      ``,
      ``,
      ``,
      ``,
    ],
    nov: [`11월 챌린지`, `November`, 's_portal_home.monthly_challenge.nov', ``, ``, ``, ``],
    date_nov: [
      `2022. 11. 1 - 2022. 11. 30`,
      `2022. 11. 1 - 2022. 11. 30`,
      's_portal_home.monthly_challenge.date_nov',
      ``,
      ``,
      ``,
      ``,
    ],
    dec: [`12월 챌린지`, `December`, 's_portal_home.monthly_challenge.dec', ``, ``, ``, ``],
    date_dec: [
      `2022. 12. 1 - 2022. 12. 31`,
      `2022. 12. 1 - 2022. 12. 31`,
      's_portal_home.monthly_challenge.date_dec',
      ``,
      ``,
      ``,
      ``,
    ],
    credit_20min: [`20분 수업권`, `20-min credit`, 's_portal_home.monthly_challenge.credit_20min', ``, ``, ``, ``],
    week: [`주차`, `Week`, 's_portal_home.monthly_challenge.week', ``, ``, ``, ``],
    date: [`기간`, `Period`, 's_portal_home.monthly_challenge.date', ``, ``, ``, ``],
    lesson: [`수업 수`, `Lessons`, 's_portal_home.monthly_challenge.lesson', ``, ``, ``, ``],
    done: [`달성 여부`, `Status`, 's_portal_home.monthly_challenge.done', ``, ``, ``, ``],
    oct_week1: [
      `10. 3(월) - 10. 9(일)`,
      `Oct 3(M) - Oct 9(S)`,
      's_portal_home.monthly_challenge.oct_week1',
      ``,
      ``,
      ``,
      ``,
    ],
    oct_week2: [
      `10. 10(월) - 10. 16(일)`,
      `Oct 10(M) - Oct 16(S)`,
      's_portal_home.monthly_challenge.oct_week2',
      ``,
      ``,
      ``,
      ``,
    ],
    oct_week3: [
      `10.17(월) - 10. 23(일)`,
      `Oct 17(M) - Oct 23(S)`,
      's_portal_home.monthly_challenge.oct_week3',
      ``,
      ``,
      ``,
      ``,
    ],
    oct_week4: [
      `10. 24(월) - 10. 30(일)`,
      `Oct 24(M) - Oct 30(S)`,
      's_portal_home.monthly_challenge.oct_week4',
      ``,
      ``,
      ``,
      ``,
    ],
    nov_week1: [
      `10. 31(월) - 11. 6(일)`,
      `Oct 31(M) - Nov 6(S)`,
      's_portal_home.monthly_challenge.nov_week1',
      ``,
      ``,
      ``,
      ``,
    ],
    nov_week2: [
      `11. 7(월) - 11. 13(일)`,
      `Nov 7(M) - Nov 13(S)`,
      's_portal_home.monthly_challenge.nov_week2',
      ``,
      ``,
      ``,
      ``,
    ],
    nov_week3: [
      `11. 14(월) - 11. 20(일)`,
      `Nov 14(M) - Nov 20(S)`,
      's_portal_home.monthly_challenge.nov_week3',
      ``,
      ``,
      ``,
      ``,
    ],
    nov_week4: [
      `11. 21(월) - 11. 27(일)`,
      `Nov 21(M) - Nov 27(S)`,
      's_portal_home.monthly_challenge.nov_week4',
      ``,
      ``,
      ``,
      ``,
    ],
    dec_week1: [
      `11. 28(월) - 12. 4(일)`,
      `Nov 28(M) - Dec 4(S)`,
      's_portal_home.monthly_challenge.dec_week1',
      ``,
      ``,
      ``,
      ``,
    ],
    dec_week2: [
      `12. 5(월) - 12. 11(일)`,
      `Dec 5(M) - Dec 11(S)`,
      's_portal_home.monthly_challenge.dec_week2',
      ``,
      ``,
      ``,
      ``,
    ],
    dec_week3: [
      `12. 12(월) - 12. 18(일)`,
      `Dec 12(M) - Dec 18(S)`,
      's_portal_home.monthly_challenge.dec_week3',
      ``,
      ``,
      ``,
      ``,
    ],
    dec_week4: [
      `12. 19(월) - 12. 25(일)`,
      `Dec 19(M) - Dec 25(S)`,
      's_portal_home.monthly_challenge.dec_week4',
      ``,
      ``,
      ``,
      ``,
    ],
    note: [
      `챌린지 진행 방식 자세히 보기`,
      `About the Monthly Challenge`,
      's_portal_home.monthly_challenge.note',
      ``,
      ``,
      ``,
      ``,
    ],
    note_body: [
      <>
        <b>STEP 1.</b> 링글 수업 듣고 스탬프 받기
        <br />
        <b>STEP 2.</b> 매달 스탬프 채우고 20분 무료 수업권 받기
        <br />
        + 보너스 스탬프와 서프라이즈 혜택도 놓치지 마세요!
        <br />
        <br />
        <b>혜택 🎁</b>
        <br />
        • 챌린지 달성자 전원에게 링글 계정으로 20분 수업권을 증정합니다. (수강기간: 지급일 기준 14일)
        <br />
        • [12월 한정] 챌린지 달성자 전원에게 1월 라이브 유료 서비스 이용권을 증정합니다.
        <br />
        • 챌린지 달성자 중 10명을 추첨하여 매달 5만원 상당의 서프라이즈 선물을 증정합니다. (1/6(금) 예정)
        <br />
        <br />
        <b>공통 안내</b>
        <br />
        • 모든 일정은 한국 시간 기준입니다.
        <br />
        • 혜택 당첨자는 발표일에 개별 안내해 드립니다.
        <br />
        • 잘못된 개인 정보 기재로 당첨자 발표 연락을 받지 못한 경우, 혜택 수령이 불가할 수 있습니다.
        <br />
        • 본 이벤트는 월별 챌린지로 매달 새로 신청해야 합니다.
        <br />
        • 본 이벤트는 사정에 의해 변경 및 조기 중단될 수 있으며 관련 내용은 사전 고지됩니다.
        <br />
        <br />
        <b>수업 기준</b>
        <br />
        • 모든 수업권으로 매월 마지막 일 23:30PM에 시작한 수업까지 해당 월의 수업으로 인정됩니다.
        <br />
        • 수업 정상 수강, 튜터 노쇼, 수업 정각에 튜터 미배정이 발생한 경우에도 스탬프를 제공합니다.
        <br />• 40분/20분 수업권을 교차 수강할 경우, 40분/20분 수업 챌린지가 개별 적용됩니다.
      </>,
      <>
        <b>STEP 1.</b> Take Ringle lessons and get stamps
        <br />
        <b>STEP 2.</b> Collect stamps and receive a free 20-min credit
        <br />
        + Don't miss out on the bonus missions and a surprise gift!
        <br />
        <br />
        <b>Rewards 🎁</b>
        <br />
        • A 20-min credit will be rewarded to all members that accomplish the monthly challenge. (Valid for 14 days from
        issuance)
        <br />
        • A Premium Ringle LIVE service for January will be rewarded to all members that accomplish December's monthly
        challenge.
        <br />
        • A surprise gift will be given to 10 members picked by a random draw among those who completed the challenge.
        (To be announced on Jan 6)
        <br />
        <br />
        <b>Terms and Conditions</b>
        <br />
        • All schedules are based on Korea Standard Time (KST)
        <br />
        • Winners of the random draw will be notified individually on the date of announcement listed above.
        <br />
        • Your personal information registered on Ringle must be correct and up-to-date to be notified on winning the
        draw and receive rewards.
        <br />
        • The event is a monthly challenge and must rejoin the challenge every month.
        <br />
        • The event is subject to change or cancellation, in which case a prior notice will be given.
        <br />
        <br />
        • Lesson starting on or before 23:30 PM on the last day of the month, booked using any type of credit, will be
        counted toward the Monthly Challenge for the said month.
        <br />
        • A stamp will be given in the following cases: 1) when a lesson is completed, 2) for tutor no-show, 3) or tutor
        unassigned at the beginning of the lesson.
        <br />• When 40-min credits and 20-min credits are used alternately, the stamps for the 40-min and 20-min
        lessons will be added to respective Monthly Challenges.{' '}
      </>,
      's_portal_home.monthly_challenge.note_body',
      ``,
      ``,
      ``,
      ``,
    ],
    acheived: [`성공`, `Accomplished`, 's_portal_home.monthly_challenge.acheived', ``, ``, ``, ``],
    fail: [`미달성`, `Skipped`, 's_portal_home.monthly_challenge.fail', ``, ``, ``, ``],
    in_progress: [`진행중`, `Ongoing`, 's_portal_home.monthly_challenge.in_progress', ``, ``, ``, ``],
    min_40: [`40분`, `40min`, 's_portal_home.monthly_challenge.min_40', ``, ``, ``, ``],
    min_20: [`20분`, `20min`, 's_portal_home.monthly_challenge.min_20', ``, ``, ``, ``],
    crm_msg_20min_1: [
      `🎉 멋진 시작입니다! 첫 수업을 성공적으로 완료했어요!`,
      `🎉 A great start! You successfully completed your first lesson!`,
      's_portal_home.monthly_challenge.crm_msg_20min_1',
      ``,
      ``,
      ``,
      ``,
    ],
    crm_msg_20min_2: [
      `💯 축하합니다! 두 번째 스탬프가 성공적으로 추가되었어요.`,
      `💯 Congratulations! You successfully earned your second stamp.`,
      's_portal_home.monthly_challenge.crm_msg_20min_2',
      ``,
      ``,
      ``,
      ``,
    ],
    crm_msg_20min_3: [
      `🔥 세 번째 스탬프 추가! 한 번만 더 수업 들으면 챌린지 절반을 달성해요.`,
      `🔥 You earned your third stamp! Just one more lesson to get to the halfway mark!`,
      's_portal_home.monthly_challenge.crm_msg_20min_3',
      ``,
      ``,
      ``,
      ``,
    ],
    crm_msg_20min_4: [
      `🚴‍♀️ 벌써 반이나 달려왔어요! 꾸준하게 노력하는 모습이 멋있습니다.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      's_portal_home.monthly_challenge.crm_msg_20min_4',
      ``,
      ``,
      ``,
      ``,
    ],
    crm_msg_20min_5: [
      `💪 노력한 시간만큼 영어 실력으로 돌아올거에요. 화이팅!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
      's_portal_home.monthly_challenge.crm_msg_20min_5',
      ``,
      ``,
      ``,
      ``,
    ],
    crm_msg_20min_6: [
      `🏆 꾸준히 수업해서 벌써 여섯 번째 도장을 받으셨어요!`,
      `🏆 You already received 6 stamps!`,
      's_portal_home.monthly_challenge.crm_msg_20min_6',
      ``,
      ``,
      ``,
      ``,
    ],
    crm_msg_20min_7: [
      `🏃‍♀️ 대단한 성과예요! 수업 하나만 더 듣고 챌린지를 완주하세요.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      's_portal_home.monthly_challenge.crm_msg_20min_7',
      ``,
      ``,
      ``,
      ``,
    ],
    crm_msg_20min_8: [
      `🎁 챌린지 달성을 축하합니다! 링글팀이 선물한 무료 수업권을 확인해 보세요.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      's_portal_home.monthly_challenge.crm_msg_20min_8',
      ``,
      ``,
      ``,
      ``,
    ],
    crm_msg_40min_1: [
      `🎉 멋진 시작입니다! 첫 수업을 성공적으로 완료했어요!`,
      `🎉 A great start! You successfully completed your first lesson!`,
      's_portal_home.monthly_challenge.crm_msg_40min_1',
      ``,
      ``,
      ``,
      ``,
    ],
    crm_msg_40min_2: [
      `🔥 두 번째 스탬프 완료! 한 번만 더 수업 들으면 챌린지 절반을 달성해요.`,
      `🔥 You earned your second stamp! One more lesson to reach the halfway mark!`,
      's_portal_home.monthly_challenge.crm_msg_40min_2',
      ``,
      ``,
      ``,
      ``,
    ],
    crm_msg_40min_3: [
      `🚴‍♀️ 벌써 반이나 달려왔어요! 꾸준하게 노력하는 모습이 멋있습니다.`,
      `🚴‍♀️ You're already halfway done! Your hard work is commendable.`,
      's_portal_home.monthly_challenge.crm_msg_40min_3',
      ``,
      ``,
      ``,
      ``,
    ],
    crm_msg_40min_4: [
      `💪 노력한 시간만큼 영어 실력으로 돌아올거에요. 화이팅!`,
      `💪 Your hard work will pay off in the end. You can do it!`,
      's_portal_home.monthly_challenge.crm_msg_40min_4',
      ``,
      ``,
      ``,
      ``,
    ],
    crm_msg_40min_5: [
      `🏃‍♀️ 대단한 성과예요! 수업 하나만 더 듣고 챌린지를 완주하세요.`,
      `🏃‍♀️ Amazing work! Take one more lesson to complete the challenge.`,
      's_portal_home.monthly_challenge.crm_msg_40min_5',
      ``,
      ``,
      ``,
      ``,
    ],
    crm_msg_40min_6: [
      `🎁 챌린지 달성을 축하합니다! 링글팀이 선물한 무료 수업권을 확인해 보세요.`,
      `🎁 Congratulations on completing the challenge! Check out the free credit reward.`,
      's_portal_home.monthly_challenge.crm_msg_40min_6',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_checkbox: [
      `(선택) 매일 새로운 영어 표현을 배우면서 응원받기💪`,
      `(Option) Get pep-talk notifications in English everyday💪`,
      's_portal_home.monthly_challenge.modal_checkbox',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_desc2: [
      `응원 알림을 추가로 신청 하신 분들에 한하여 성공적인 챌린지 완주를 위한 영어 표현과 응원 메시지를  카카오톡으로 보내드려요. (링글에 가입한 번호의 카카오톡 계정을 통해 한국어로 제공됩니다.)`,
      <>
        If you turn on the notification for pep talks, Ringle will send you useful pep-talk expressions via KakaoTalk.
        <br />
        (This is only sent in Korean to members who have joined Ringle using the phone number linked to KakaoTalk.)
      </>,
      's_portal_home.monthly_challenge.modal_desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    crm_msg_20min_0: [
      `💜 이제 첫 수업을 들어볼까요?`,
      `💜 Book your lesson to start the challenge!`,
      's_portal_home.monthly_challenge.crm_msg_20min_0',
      ``,
      ``,
      ``,
      ``,
    ],
    crm_msg_40min_0: [
      `💜 이제 첫 수업을 들어볼까요?`,
      `💜 Book your lesson to start the challenge!`,
      's_portal_home.monthly_challenge.crm_msg_40min_0',
      ``,
      ``,
      ``,
      ``,
    ],
    week_number: (n) => [`${n}주`, `Week ${n}`, 's_portal_home.monthly_challenge.week_number', ``, ``, ``, ``],
    week2: [`2주`, `Week 2`, 's_portal_home.monthly_challenge.week2', ``, ``, ``, ``],
    week3: [`3주`, `Week 3`, 's_portal_home.monthly_challenge.week3', ``, ``, ``, ``],
    week4: [`4주`, `Week 4`, 's_portal_home.monthly_challenge.week4', ``, ``, ``, ``],
    modal_placeholder_nov: [
      `11월 1일부터 챌린지가 시작될 예정입니다.`,
      `Starts on Nov 1, 2022`,
      's_portal_home.monthly_challenge.modal_placeholder_nov',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_placeholder_dec: [
      `12월 1일부터 챌린지가 시작될 예정입니다.`,
      `Starts on Dec 1, 2022`,
      's_portal_home.monthly_challenge.modal_placeholder_dec',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_placeholder_oct2: [
      `10월 챌린지는 종료되었습니다.`,
      `Ended Oct 31, 2022`,
      's_portal_home.monthly_challenge.modal_placeholder_oct2',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_placeholder_nov2: [
      `11월 챌린지는 종료되었습니다.`,
      `Ended Nov 30, 2022`,
      's_portal_home.monthly_challenge.modal_placeholder_nov2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  point: [`사용 가능한 포인트`, `Available points`, 's_portal_home.point', ``, ``, ``, ``],
  point_alert: [
    `2월 3일 50,000포인트 소멸 예정`,
    `$38 Points valid until Feb 2`,
    's_portal_home.point_alert',
    ``,
    ``,
    ``,
    ``,
  ],
  point_tooltip: [`곧 사라져요!`, `Don’t forget!`, 's_portal_home.point_tooltip', ``, ``, ``, ``],
  point_event: [`5만 포인트 잊으셨나요?`, `Remember $38P gift?`, 's_portal_home.point_event', ``, ``, ``, ``],
  point_desc: [
    <>
      설 선물로 드린
      <br />
      5만 포인트가 아직
      <br />
      잠자고 있어요.
    </>,
    <>
      $38 Points we sent
      <br />
      as a Seollal gift
      <br />
      are waiting
      <br />
      for you.
    </>,
    's_portal_home.point_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  point_btn: [`포인트로 구매하기`, `Buy Credits`, 's_portal_home.point_btn', ``, ``, ``, ``],
}
export const s_lesson_review = {
  three_month_summary: [
    `최근 3개월 수업 정보 요약`,
    `Summary of the Last 3 Months`,
    's_lesson_review.three_month_summary',
    ``,
    ``,
    ``,
    ``,
  ],
  by_section: [`항목별 최신 결과`, `Latest Evaluations`, 's_lesson_review.by_section', ``, ``, ``, ``],
  see_example: [`예시보기`, `View example`, 's_lesson_review.see_example', ``, ``, ``, ``],
  no_lessons_in_the_last_90_days_your_last_lesson_was_days_ago_: (name, day) => [
    <>
      최근 3개월 동안 완료한 수업이 없습니다. <br />
      {name}님의 마지막 수업: {day}일 전
    </>,
    <>
      No lessons in the last 3 months.
      <br />
      Your last lesson was {day} days ago.
    </>,
    's_lesson_review.no_lessons_in_the_last_90_days_your_last_lesson_was_days_ago_',
    ``,
    ``,
    ``,
    ``,
  ],
  are_you_curious_about_how_the_ringlers_do_lessons_: [
    <span>
      월별 수업 통계에서 <br />
      어떤 통계 데이터가 나오는지 궁금하신가요?
    </span>,
    <span>Sample Monthly Summary</span>,
    's_lesson_review.are_you_curious_about_how_the_ringlers_do_lessons_',
    ``,
    ``,
    ``,
    ``,
  ],
  top_5_ringler_lesson_num: (num) => [
    <span>상위 20% 고객의 수업 수는 {num} 회입니다.</span>,
    <span>Top 20%: {num} lessons</span>,
    's_lesson_review.top_5_ringler_lesson_num',
    ``,
    ``,
    ``,
    ``,
  ],
  Summary: [`개요`, `Overview`, 's_lesson_review.Summary', ``, ``, ``, ``],
  lesson: [`1:1 수업`, `1:1 Lesson`, 's_lesson_review.lesson', ``, ``, ``, ``],
  work_in_progress: [`집계중`, `Work in Progress..`, 's_lesson_review.work_in_progress', ``, ``, ``, ``],
  We_are_working_on_it: [
    `계산 중에 있습니다`,
    `This data is not yet available`,
    's_lesson_review.We_are_working_on_it',
    ``,
    ``,
    ``,
    ``,
  ],
  review: [`복습`, `Review`, 's_lesson_review.review', ``, ``, ``, ``],
  reviewed_yet: [`복습을 완료하지 않은 수업`, `Not reviewed`, 's_lesson_review.reviewed_yet', ``, ``, ``, ``],
  reviews_lesson: [`복습을 완료한 수업`, `Reviewed`, 's_lesson_review.reviews_lesson', ``, ``, ``, ``],
  rate_lesson: [`수업 평가하기`, `Rate Lesson`, 's_lesson_review.rate_lesson', ``, ``, ``, ``],
  to_do_list: [`복습 목록`, `To-Do List`, 's_lesson_review.to_do_list', ``, ``, ``, ``],
  preparing: [`준비중`, `Preparing`, 's_lesson_review.preparing', ``, ``, ``, ``],
  options: {
    study: {
      title: [`예습 내역`, `My Lesson Prep`, 's_lesson_review.options.study.title', ``, ``, ``, ``],
    },
    record: {
      title: [`수업 녹음`, `Lesson Recording`, 's_lesson_review.options.record.title', ``, ``, ``, ``],
      desc: [
        `대화를 클릭하면 해당하는 녹음 파트를 바로 재생할 수 있습니다.`,
        `Click on the conversation to play the corresponding part of the recording.`,
        's_lesson_review.options.record.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    analysis: {
      title: [`수업 분석`, `Lesson Analysis`, 's_lesson_review.options.analysis.title', ``, ``, ``, ``],
    },
    feedback: {
      title: [`튜터 피드백`, `Tutor’s Feedback`, 's_lesson_review.options.feedback.title', ``, ``, ``, ``],
    },
    note: {
      title: [`수업 노트`, `Lesson Notes`, 's_lesson_review.options.note.title', ``, ``, ``, ``],
    },
    speakingAnalysis: {
      title: [
        `스피킹 분석 차트`,
        `Speaking Analysis Charts`,
        's_lesson_review.options.speakingAnalysis.title',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    suggestion: {
      title: [
        `스피킹 분석 & 제안`,
        `Patterns & Suggestions`,
        's_lesson_review.options.suggestion.title',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    homework: {
      title: [`숙제`, `Homework`, 's_lesson_review.options.homework.title', ``, ``, ``, ``],
    },
  },
  feedback_notice: [
    `* 노란 선은 영역별 점수 상위 20% 구간의 평균값입니다.`,
    `*The yellow line indicates average scores of top 20% Ringlers.`,
    's_lesson_review.feedback_notice',
    ``,
    ``,
    ``,
    ``,
  ],
  completeMp3: [
    `수업 다시 듣기를 완료하셨습니다.`,
    `You’ve done listening to lesson recording.`,
    's_lesson_review.completeMp3',
    ``,
    ``,
    ``,
    ``,
  ],
  voca_range: [`사용 단어 범위`, `Range of Words`, 's_lesson_review.voca_range', ``, ``, ``, ``],
  wpm: [`말하기 속도`, `Speech Pace`, 's_lesson_review.wpm', ``, ``, ``, ``],
  wpm_unit: [`단어/분 (WPM)`, `words/min(WPM)`, 's_lesson_review.wpm_unit', ``, ``, ``, ``],
  me: [`나`, `Me`, 's_lesson_review.me', ``, ``, ``, ``],
  tutor: [`튜터`, `Tutor`, 's_lesson_review.tutor', ``, ``, ``, ``],
  ringler: [`상위 20%`, `Top 20%`, 's_lesson_review.ringler', ``, ``, ``, ``],
  fillerWord: [`필러워드 사용 빈도`, `Patterns & Suggestions`, 's_lesson_review.fillerWord', ``, ``, ``, ``],
  speaking_pattern: [
    `수업 중 사용한 표현 관련`,
    `Speaking Pattern & Suggestions`,
    's_lesson_review.speaking_pattern',
    ``,
    ``,
    ``,
    ``,
  ],
  completeAnalysis: [
    `수업 분석 확인하기를 완료하셨습니다.`,
    `You’ve done checking the lesson analysis.`,
    's_lesson_review.completeAnalysis',
    ``,
    ``,
    ``,
    ``,
  ],
  completeFeedback: [
    `튜터 피드백 확인을 완료하셨습니다.`,
    `You’ve done checking and evaluating feedback.`,
    's_lesson_review.completeFeedback',
    ``,
    ``,
    ``,
    ``,
  ],
  completeAnalysisButton: [
    `수업 분석 확인하기 완료`,
    `Done checking lesson analysis`,
    's_lesson_review.completeAnalysisButton',
    ``,
    ``,
    ``,
    ``,
  ],
  completeFeedbackButton: [
    `튜터 피드백 확인 및 평가 완료`,
    `Done checking and evaluating feedback`,
    's_lesson_review.completeFeedbackButton',
    ``,
    ``,
    ``,
    ``,
  ],
  not_provide_feedback: [
    <>
      Writing Focused 주제 수업의 피드백은 수업 노트(Ringle Doc)에 포함되어 별도의 피드백 리포트는 제공되지 않습니다.
    </>,
    <>
      Feedback from Writing Focused Lessons is included in the Lesson Note(Ringle Doc), so a separate feedback report is
      not available.
    </>,
    's_lesson_review.not_provide_feedback',
    ``,
    ``,
    ``,
    ``,
  ],
  after_rating_the_lesson: [
    <>
      수업평가 완료 후에
      <br />
      복습하실 수 있습니다.
    </>,
    <>
      You can review a lesson
      <br />
      after rating the lesson.
    </>,
    's_lesson_review.after_rating_the_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  my_lesson_count: [`내 수업 수`, `Your Lessons`, 's_lesson_review.my_lesson_count', ``, ``, ``, ``],
  do_you_want_delete_uploaded_file: [
    `한 번 자료를 삭제 하시면 복원이 불가능합니다. 삭제하시겠습니까?`,
    `This action cannot be undone. Are you sure you want to delete this document?`,
    's_lesson_review.do_you_want_delete_uploaded_file',
    ``,
    ``,
    ``,
    ``,
  ],
  please_evaluate_feedback: [
    `이 피드백 보고서를 평가해주세요`,
    `Please evaluate this feedback report`,
    's_lesson_review.please_evaluate_feedback',
    ``,
    ``,
    ``,
    ``,
  ],
  please_write_reason: [
    `평가에 대한 이유를 적어주세요.\n고객님의 소중한 의견은 튜터가 양질의 피드백 보고서를 제공하는데 도움이 됩니다.`,
    `Please write down the reason for your evaluation.\nYour honest feedback will be of tremendous help for the tutor to grow further.`,
    's_lesson_review.please_write_reason',
    ``,
    ``,
    ``,
    ``,
  ],
  score_criteria: [`점수 기준`, `Score Criteria`, 's_lesson_review.score_criteria', ``, ``, ``, ``],
  see_frequently_used_phrases: [
    `내가 사용한 구문`,
    `View your expressions`,
    's_lesson_review.see_frequently_used_phrases',
    ``,
    ``,
    ``,
    ``,
  ],
  present: [`현재`, `today`, 's_lesson_review.present', ``, ``, ``, ``],
  top_5: [`상위 20%`, `Top 20%`, 's_lesson_review.top_5', ``, ``, ``, ``],
  top_5_ringler: [`상위 20% 링글러`, `Top 20%`, 's_lesson_review.top_5_ringler', ``, ``, ``, ``],
  modal_overall_this_month_40_20: [
    `해당 월에 완료된 40분 및 20분 수업 수를 분석합니다. 일주일 평균 수업 수는 완료된 총 수업 수를 주로 나누어 일주일 평균 몇 회의 수업을 완료하였는지 분석합니다.`,
    <span>Counts all lessons. Weekly average lessons are calculated on a monthly basis.&nbsp;</span>,
    's_lesson_review.modal_overall_this_month_40_20',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_overall_this_month_top5: [
    `상위 20%: 상위 20%의 모집단은 해당 월에 완료한 총 수업수에 한하며, 총 수업수 상위 20% 구간의 평균값입니다.`,
    <span>Top 20% calculated based on monthly completed lessons by all Ringle users.</span>,
    's_lesson_review.modal_overall_this_month_top5',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_feedback_this_month: [
    <span>
      튜터 피드백 평균 점수는 아래 Fluency, Vocabulary, Grammar, Pronunication 네가지 항목의 평균 점수를 나타냅니다.
    </span>,
    `Shows monthly average score across four categories: Fluency, Vocabulary, Grammar, Pronunciation.`,
    's_lesson_review.modal_feedback_this_month',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_wpm_this_month: [
    <span>
      해당 수업에서 내가 말한 속도입니다.
      <br /> 말하기 속도는 수업 시간 동안 내가 말한 단어 수를 기준으로 계산하며, 1분당 말한 단어 수입니다.
    </span>,
    `Total number of words uttered divided by the total number of minutes in lesson.`,
    's_lesson_review.modal_wpm_this_month',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_top5_wpm_selected: [
    `상위 20%의 모집단은 동일 날짜에 완료된 수업에 한하며, 말하기 속도 상위 20% 구간의 평균값입니다.`,
    `Shows top 20% among all lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_top5_wpm_selected',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_feedback_this_month_avg: [
    `튜터 피드백 평균 점수가 모든 사용자들 중 상위 20% 지점의 수치입니다.`,
    `Shows top 20% among all lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_feedback_this_month_avg',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_word_selected: [
    `해당 수업에서 내가 사용한 단어 수이며, 중복값을 제외합니다.`,
    `Number of different words you used in a lesson.`,
    's_lesson_review.modal_word_selected',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_phrase_selected: [
    `해당 수업에서 내가 사용한 구문 수이며, 중복값을 제외합니다.`,
    `Number of different expressions you used in a lesson.`,
    's_lesson_review.modal_phrase_selected',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_top5_word_selected: [
    `상위 20%의 모집단은 동일 날짜에 완료된 20분 수업에 한하며, 사용 단어 수 상위 20% 구간의 평균값입니다.`,
    `Shows top 20% among all 20-minute lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_top5_word_selected',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_top5_phrase_selected: [
    `상위 20%의 모집단은 동일 날짜에 완료된 20분 수업에 한하며, 사용 구문 수 상위 20% 구간의 평균값입니다.`,
    `Shows top 20% among all 20-minute lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_top5_phrase_selected',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_top5_word_selected40: [
    `상위 20%의 모집단은 동일 날짜에 완료된 40분 수업에 한하며, 사용 단어 수 상위 20% 구간의 평균값입니다.`,
    `Shows top 20% among all 40-minute lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_top5_word_selected40',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_top5_phrase_selected40: [
    `상위 20%의 모집단은 동일 날짜에 완료된 40분 수업에 한하며, 사용 구문 수 상위 20% 구간의 평균값입니다.`,
    `Shows top 20% among all 40-minute lessons taken across the Ringle platform on the same day.`,
    's_lesson_review.modal_top5_phrase_selected40',
    ``,
    ``,
    ``,
    ``,
  ],
  times: [`회`, `Lessons`, 's_lesson_review.times', ``, ``, ``, ``],
  following_are_sample_data: [
    `하단의 차트와 통계 내용은 샘플 데이터입니다.`,
    `The charts & stats below are examples.`,
    's_lesson_review.following_are_sample_data',
    ``,
    ``,
    ``,
    ``,
  ],
  average_points: [`평균 점수`, `Avg. Score`, 's_lesson_review.average_points', ``, ``, ``, ``],
  points: [`점`, ``, 's_lesson_review.points', ``, ``, ``, ``],
  year_and_month_statistics: (year, month) => [
    `${year}년 ${month}월`,
    `${getMonthName(month - 1)[1]}, ${year}`,
    's_lesson_review.year_and_month_statistics',
    ``,
    ``,
    ``,
    ``,
  ],
  year: (year) => [`${year}년`, `${year}`, 's_lesson_review.year', ``, ``, ``, ``],
  to_You: (user) => [`${user}`, `You`, 's_lesson_review.to_You', ``, ``, ``, ``],
  You_with_standard: (user) => [
    `${user}님은 총 수업 수 기준`,
    `Regarding total number of lessons, you are in the`,
    's_lesson_review.You_with_standard',
    ``,
    ``,
    ``,
    ``,
  ],
  are: [`님은`, `are`, 's_lesson_review.are', ``, ``, ``, ``],
  apart_of: [`에 속합니다.`, ``, 's_lesson_review.apart_of', ``, ``, ``, ``],
  tutors: [`튜터`, `Tutor`, 's_lesson_review.tutors', ``, ``, ``, ``],
  overview_modal_title: [
    `최근 3개월 수업 정보 요약`,
    `Summary of the Last 3 Months`,
    's_lesson_review.overview_modal_title',
    ``,
    ``,
    ``,
    ``,
  ],
  overview_modal_secTitle1: [
    `최근 3개월간 완료한 수업 데이터의 통계입니다.`,
    ``,
    's_lesson_review.overview_modal_secTitle1',
    ``,
    ``,
    ``,
    ``,
  ],
  overview_modal_paragraph: [
    <span>
      수업 수: 40분 및 20분 수업의 총합
      <br />
      튜터 수: 함께한 튜터 수 (중복 튜터 제외)
      <br />
      교재 수: 학습한 교재 수 (중복 교재 제외)
      <br />
      <br />
      상위 20%: 상위 20%의 모집단은 각 링글러가 최근 3개월간 완료한 총 수업수에 한하며, 총 수업수 상위 20% 구간의
      평균값입니다.
      <br />
    </span>,
    <span>Top 20%: Ringlers who have taken the highest number of lessons in a selected month (40min and 20min)</span>,
    's_lesson_review.overview_modal_paragraph',
    ``,
    ``,
    ``,
    ``,
  ],
  overview_modal_extra: [
    `*정확한 데이터 분석을 위해 48시간 전 완료된 수업까지 반영합니다.`,
    `*Lessons completed within 48 hours may not be shown.`,
    's_lesson_review.overview_modal_extra',
    ``,
    ``,
    ``,
    ``,
  ],
  overview2_modal_title: [
    `항목별 최신 결과`,
    `Latest Evaluations`,
    's_lesson_review.overview2_modal_title',
    ``,
    ``,
    ``,
    ``,
  ],
  overview2_modal_secTitle1: [
    `항목별 가장 최근 수치와 상위 20% 수치를 비교 분석합니다.`,
    `Compare your performance against top 20% Ringlers.`,
    's_lesson_review.overview2_modal_secTitle1',
    ``,
    ``,
    ``,
    ``,
  ],
  overview2_modal_paragraph: [
    <span>
      항목별 수치는 동일 수업의 결과가 아닐 수 있으며, 데이터 분석이 완료된 가장 최근 수업의 결과입니다.
      <br />
      상위 20%의 모집단은 동일 날짜에 완료된 수업에 한합니다.
    </span>,
    <span>
      Each score may be calculated from the most relevant lesson for that statistic.
      <br />
      Top 20% is calculated independently for each category.
      <br />
    </span>,
    's_lesson_review.overview2_modal_paragraph',
    ``,
    ``,
    ``,
    ``,
  ],
  overview2_modal_extra: [
    `*정확한 데이터 분석을 위해 48시간 전 완료된 수업까지 반영합니다.`,
    `*Lessons completed within 48 hours may not be shown.`,
    's_lesson_review.overview2_modal_extra',
    ``,
    ``,
    ``,
    ``,
  ],
  feedback_modal_extra: [
    `*수업 녹음을 설정하지 않아 분석이 불가능한 수업은 해당 페이지에서 조회되지 않습니다.`,
    `*Unrecorded lessons are not included in analysis.`,
    's_lesson_review.feedback_modal_extra',
    ``,
    ``,
    ``,
    ``,
  ],
  overall_statistics: [`월별 수업 통계`, `Monthly Summary`, 's_lesson_review.overall_statistics', ``, ``, ``, ``],
  overall_statistics_graph: [
    `월별 수업 수`,
    `Number of Lessons per Month`,
    's_lesson_review.overall_statistics_graph',
    ``,
    ``,
    ``,
    ``,
  ],
  lessons: [`수업 수`, `Lessons`, 's_lesson_review.lessons', ``, ``, ``, ``],
  _: [`회`, ``, 's_lesson_review._', ``, ``, ``, ``],
  forty_min_used_word_count: [
    `40분 수업 사용 단어 수`,
    `Words used in your 40 minute lessons`,
    's_lesson_review.forty_min_used_word_count',
    ``,
    ``,
    ``,
    ``,
  ],
  twenty_min_used_word_count: [
    `20분 수업 사용 단어 수`,
    `Words used in your 40 minute lessons`,
    's_lesson_review.twenty_min_used_word_count',
    ``,
    ``,
    ``,
    ``,
  ],
  my_used_word_count: [`내 단어 수`, `You`, 's_lesson_review.my_used_word_count', ``, ``, ``, ``],
  used_word_count: [`사용 단어 수`, `Used Words`, 's_lesson_review.used_word_count', ``, ``, ``, ``],
  forty_min_used_phrase_count: [
    `40분 수업 사용 구문 수`,
    `Expressions used in your 40 minute lessons`,
    's_lesson_review.forty_min_used_phrase_count',
    ``,
    ``,
    ``,
    ``,
  ],
  twenty_min_used_phrase_count: [
    `20분 수업 사용 구문 수`,
    `Expressions used in your 20 minute lessons`,
    's_lesson_review.twenty_min_used_phrase_count',
    ``,
    ``,
    ``,
    ``,
  ],
  used_phrase_count: [`사용 구문 수`, `Used Expressions`, 's_lesson_review.used_phrase_count', ``, ``, ``, ``],
  my_used_phrase_count: [`내 구문 수`, `You`, 's_lesson_review.my_used_phrase_count', ``, ``, ``, ``],
  word_list: [`단어 리스트`, `Word List`, 's_lesson_review.word_list', ``, ``, ``, ``],
  phrase_list: [`구문 리스트`, `Expressions List`, 's_lesson_review.phrase_list', ``, ``, ``, ``],
  my_speaking_rate: [`내 속도`, `You`, 's_lesson_review.my_speaking_rate', ``, ``, ``, ``],
  speaking_rate: [`말하기 속도`, `Speech Pace`, 's_lesson_review.speaking_rate', ``, ``, ``, ``],
  convert_month_day_year: (month, day) => [
    `${month}월 ${day}일`,
    `${getMonthName(month - 1)[0]} ${day}`,
    's_lesson_review.convert_month_day_year',
    ``,
    ``,
    ``,
    ``,
  ],
  convert_year: (year) => [`${year}년`, `${year}`, 's_lesson_review.convert_year', ``, ``, ``, ``],
  packets: [`교재 수`, `Materials`, 's_lesson_review.packets', ``, ``, ``, ``],
  points_only_kr: [`내 점수`, `You`, 's_lesson_review.points_only_kr', ``, ``, ``, ``],
  wpm_only_kr: [`내 속도`, `You`, 's_lesson_review.wpm_only_kr', ``, ``, ``, ``],
  words_only_kr: [`내 단어 수`, `You`, 's_lesson_review.words_only_kr', ``, ``, ``, ``],
  phrases_only_kr: [`내 구문 수`, `You`, 's_lesson_review.phrases_only_kr', ``, ``, ``, ``],
  period_kr_time_to_US: (time) => [
    <>{time}</>,
    <>{getMonthName(time.split('.')[1] - 1)[1] + ' ' + time.split('.')[2] + ', ' + '20' + time.split('.')[0]}</>,
    's_lesson_review.period_kr_time_to_US',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_s_feedback_score: [
    `튜터 피드백 점수`,
    `Tutor's Feedback Score`,
    's_lesson_review.tutor_s_feedback_score',
    ``,
    ``,
    ``,
    ``,
  ],
  used_expressions: [`사용 구문 수`, `Used Expressions`, 's_lesson_review.used_expressions', ``, ``, ``, ``],
  top_5_: [`상위 20%`, `Top 20%`, 's_lesson_review.top_5_', ``, ``, ``, ``],
  in_the_top_5_period: [`상위 20%`, `in the Top 20%.`, 's_lesson_review.in_the_top_5_period', ``, ``, ``, ``],
  avg_per_week: [`일주일 평균`, `Avg. Per Week`, 's_lesson_review.avg_per_week', ``, ``, ``, ``],
  you_are_in_the_top_5_: (user) => [
    <div>
      {user}님은 <span className="text-warning">상위 20%</span>에 속합니다.
    </div>,
    <div>
      You are in the <span className="text-warning">top 20%.</span>
    </div>,
    's_lesson_review.you_are_in_the_top_5_',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_prep_review: [`예습 및 복습`, `Lesson Prep & Review`, 's_lesson_review.lesson_prep_review', ``, ``, ``, ``],
  average_prep_review_percentages_per_lesson: [
    `수업별 평균 완료율`,
    `Average % Completed Per Lesson`,
    's_lesson_review.average_prep_review_percentages_per_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  no_data_in_2020: [
    <span>
      예습 및 복습의 데이터는
      <br />
      2021년 1월의 수업부터 확인하실 수 있습니다
    </span>,
    `Lesson Prep. & Review Data is available from January of 2021.`,
    's_lesson_review.no_data_in_2020',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_distribution_based_on_completion: [
    `예습 및 복습 완료 수업 수`,
    `Lessons you Prepped & Reviewed`,
    's_lesson_review.lesson_distribution_based_on_completion',
    ``,
    ``,
    ``,
    ``,
  ],
  prep_review_percentages_are_calculated_by_dividing_the_sum_of_total_prep_review_percentages_by_the_total_number_of_lessons:
    [
      `평균 예습률 및 복습률은 해당 월에 완료된 수업들에 대해 각각 예습률 및 복습률 총합을 완료된 수업 수로 나눈 수치입니다.`,
      `Calculated by dividing lessons that you prepped and reviewed by total lessons you had on a monthly basis.`,
      's_lesson_review.prep_review_percentages_are_calculated_by_dividing_the_sum_of_total_prep_review_percentages_by_the_total_number_of_lessons',
      ``,
      ``,
      ``,
      ``,
    ],
  these_statistics_represent_the_disparities_between_completion_rates_: [
    `해당 월에 완료된 수업들에 대해 예습만 완료, 복습만 완료, 예습과 복습 모두 완료 및 예습과 복습 모두 미완료한 수업 수를 제공합니다.`,
    `Shows how many lessons you have prepped, reviewed, or both on a monthly basis.`,
    's_lesson_review.these_statistics_represent_the_disparities_between_completion_rates_',
    ``,
    ``,
    ``,
    ``,
  ],
  encouraging_more_lessons_on_ringle: [
    <span>
      이 달에는 링글 수업을 안하셨습니다. <br /> 꾸준한 링글 수업만이 영어 실력을 향상시킬 수 있습니다.
    </span>,
    <span>You haven't taken any lessons this month.</span>,
    's_lesson_review.encouraging_more_lessons_on_ringle',
    ``,
    ``,
    ``,
    ``,
  ],
  encouraging_more_lessons_on_ringle_48_hour_exception: [
    <span>
      이 달에는 링글 수업을 안하셨습니다. <br /> 꾸준한 링글 수업만이 영어 실력을 향상시킬 수 있습니다.
      <br /> 48시간 이내 완료된 수업은 조회되지 않을 수 있습니다.
    </span>,
    <span>
      You haven't taken any lessons this month.
      <br />
      Lessons completed within 48 hours may not be shown.
      <br />
    </span>,
    's_lesson_review.encouraging_more_lessons_on_ringle_48_hour_exception',
    ``,
    ``,
    ``,
    ``,
  ],
  showAllTutors: (bool) => [
    `${bool ? '접기' : '전체 보기'}`,
    `${!bool ? 'View all' : 'Close'}`,
    's_lesson_review.showAllTutors',
    ``,
    ``,
    ``,
    ``,
  ],
  showAllPackets: (bool) => [
    `${bool ? '접기' : '전체 보기'}`,
    `${!bool ? 'View all' : 'Close'}`,
    's_lesson_review.showAllPackets',
    ``,
    ``,
    ``,
    ``,
  ],
  showAllWordss: (bool) => [
    `${bool ? '접기' : '전체 보기'}`,
    `${!bool ? 'View all' : 'Close'}`,
    's_lesson_review.showAllWordss',
    ``,
    ``,
    ``,
    ``,
  ],
  showAllPhrasess: (bool) => [
    `${bool ? '접기' : '전체 보기'}`,
    `${!bool ? 'View all' : 'Close'}`,
    's_lesson_review.showAllPhrasess',
    ``,
    ``,
    ``,
    ``,
  ],
  okay: [`확인`, `Ok`, 's_lesson_review.okay', ``, ``, ``, ``],
  review_rate: [`평균 복습률`, `Review`, 's_lesson_review.review_rate', ``, ``, ``, ``],
  prep_: [`평균 예습률`, `Prep`, 's_lesson_review.prep_', ``, ``, ``, ``],
  lessson_distribution_based_on_completion: [
    `예습 및 복습 완료한 수업 수`,
    `Lesson Prepped & Reviewed`,
    's_lesson_review.lessson_distribution_based_on_completion',
    ``,
    ``,
    ``,
    ``,
  ],
  prep_only: [`예습만 완료`, `Prep only`, 's_lesson_review.prep_only', ``, ``, ``, ``],
  review_only: [`복습만 완료`, `Rev. only`, 's_lesson_review.review_only', ``, ``, ``, ``],
  both_completed: [`모두 완료`, `Both`, 's_lesson_review.both_completed', ``, ``, ``, ``],
  both_incomplete: [`모두 미완료`, `Neither`, 's_lesson_review.both_incomplete', ``, ``, ``, ``],
  total: [`총`, `Total`, 's_lesson_review.total', ``, ``, ``, ``],
  gae: [`개`, ``, 's_lesson_review.gae', ``, ``, ``, ``],
  gae_words: [`개`, `words`, 's_lesson_review.gae_words', ``, ``, ``, ``],
  gae_phrases: [`개`, `expressions`, 's_lesson_review.gae_phrases', ``, ``, ``, ``],
  myung: [`명`, ``, 's_lesson_review.myung', ``, ``, ``, ``],
  _3_tutors_you_did_a_lesson_with_the_most: [
    `제일 많이 함께한 튜터 3명`,
    `3 Tutors you had the most lessons with`,
    's_lesson_review._3_tutors_you_did_a_lesson_with_the_most',
    ``,
    ``,
    ``,
    ``,
  ],
  _3_packets_you_did_a_lesson_with_the_most: [
    `제일 많이 학습한 교재 3개`,
    `3 Materials you selected the most`,
    's_lesson_review._3_packets_you_did_a_lesson_with_the_most',
    ``,
    ``,
    ``,
    ``,
  ],
  convert_num_to_month: (num) => [
    `${num}월`,
    `${getMonthName(num - 1)[1]}`,
    's_lesson_review.convert_num_to_month',
    ``,
    ``,
    ``,
    ``,
  ],
  convert_nums_to_month_and_year: (year, month) => [
    `${year}년 ${month}월`,
    `${getMonthName(month - 1)[0]}, ${year}`,
    's_lesson_review.convert_nums_to_month_and_year',
    ``,
    ``,
    ``,
    ``,
  ],
  last_3_months: [`최근 3개월`, `Last 3 months`, 's_lesson_review.last_3_months', ``, ``, ``, ``],
  lessons_within_last_3_months: [
    `최근 3개월 수업 수`,
    `Lessons of the last 3 months`,
    's_lesson_review.lessons_within_last_3_months',
    ``,
    ``,
    ``,
    ``,
  ],
  lessons_within_last_3_months_sample_data: [
    <span>
      최근 3개월 수업 수<span className="text-muted font-size-sm"> 샘플 데이터 입니다.</span>
    </span>,
    <span>
      Lessons of the last 3 months
      <span className="text-muted font-size-sm"> sample chart</span>
    </span>,
    's_lesson_review.lessons_within_last_3_months_sample_data',
    ``,
    ``,
    ``,
    ``,
  ],
  last_6_months: [`최근 6개월`, `Last 6 months`, 's_lesson_review.last_6_months', ``, ``, ``, ``],
  last_1_year: [`최근 1년`, `Last 1 year`, 's_lesson_review.last_1_year', ``, ``, ``, ``],
  avg_score: [`평균 점수`, `Avg. Score`, 's_lesson_review.avg_score', ``, ``, ``, ``],
  feedback_avg_score: [
    `튜터 피드백 평균 점수`,
    `Your Avg. Tutor Feedback Score`,
    's_lesson_review.feedback_avg_score',
    ``,
    ``,
    ``,
    ``,
  ],
  feedback_avg: [`튜터 피드백: 평균`, `Tutor Feedback Score`, 's_lesson_review.feedback_avg', ``, ``, ``, ``],
  tutor_and_packet_for_this_lesson: [
    `이 수업의 튜터와 교재`,
    `Tutor and Materials for This Lesson`,
    's_lesson_review.tutor_and_packet_for_this_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  packet: [`교재`, `Materials`, 's_lesson_review.packet', ``, ``, ``, ``],
  packetSingular: [`교재`, `Materials`, 's_lesson_review.packetSingular', ``, ``, ``, ``],
  detailed_score: [`영역별 점수`, `Score by Area`, 's_lesson_review.detailed_score', ``, ``, ``, ``],
  my: [`내`, `Your`, 's_lesson_review.my', ``, ``, ``, ``],
  last_10_lessons: [`최근 수업 10회`, `Last 10 Lessons`, 's_lesson_review.last_10_lessons', ``, ``, ``, ``],
  last_30_lessons: [`최근 수업 30회`, `Last 30 Lessons`, 's_lesson_review.last_30_lessons', ``, ``, ``, ``],
  last_50_lessons: [`최근 수업 50회`, `Last 50 Lessons`, 's_lesson_review.last_50_lessons', ``, ``, ``, ``],
  last_100_lessons: [`최근 수업 100회`, `Last 100 Lessons`, 's_lesson_review.last_100_lessons', ``, ``, ``, ``],
  my_score: [`내 점수`, `You`, 's_lesson_review.my_score', ``, ``, ``, ``],
  number_of_used_words: [`사용 단어 수`, `Used Words`, 's_lesson_review.number_of_used_words', ``, ``, ``, ``],
  _40_min_lesson: [`40분 수업`, `40min`, 's_lesson_review._40_min_lesson', ``, ``, ``, ``],
  all_lesson: [`전체 수업`, `Total`, 's_lesson_review.all_lesson', ``, ``, ``, ``],
  packets_info1: [
    `해당 월에 학습한 교재 수를 분석하며, 중복 교재는 제외합니다.`,
    `Counts unique materials used in the selected month.`,
    's_lesson_review.packets_info1',
    ``,
    ``,
    ``,
    ``,
  ],
  packets_info2: [
    `*정확한 데이터 분석을 위해 48시간 전 완료된 수업까지 반영합니다.`,
    `*Lessons completed within 48 hours may not be shown.`,
    's_lesson_review.packets_info2',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_info1: [
    `해당 월에 함께한 튜터 수를 분석하며, 중복 튜터는 제외합니다.`,
    `Counts number of different tutors you had lessons with on a monthly basis.`,
    's_lesson_review.tutor_info1',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_info2: [
    `*정확한 데이터 분석을 위해 48시간 전 완료된 수업까지 반영합니다.`,
    `*Lessons completed within 48 hours may not be shown.`,
    's_lesson_review.tutor_info2',
    ``,
    ``,
    ``,
    ``,
  ],
  tutors_number: [`튜터 수`, `Tutors`, 's_lesson_review.tutors_number', ``, ``, ``, ``],
  see_review_list: [`복습 목록 전체보기`, `View all`, 's_lesson_review.see_review_list', ``, ``, ``, ``],
  no_completed_lesson: [
    `현재  완료한 수업이 없습니다.`,
    `You haven't completed any lessons.`,
    's_lesson_review.no_completed_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  recorded_script: [`녹음파일`, `Recording file`, 's_lesson_review.recorded_script', ``, ``, ``, ``],
  in_review_page_what_you_can_do: [
    <>
      <Div spanTag textGray700 fontSize18 fontBold>
        복습페이지
      </Div>
      <Div spanTag textGray700 fontSize18 fontMedium>
        에서는 완료한 수업의 스크립트 및 분석 내용,
      </Div>
      <br />
      <Div spanTag textGray700 fontSize18 fontMedium>
        튜터의 피드백 리포트 등을 확인하거나 수업을 다시 들을 수 있습니다.
      </Div>
    </>,
    <>
      <Div spanTag textGray700 fontSize18 fontMedium>
        You can replay lesson recording, read tutor feedback, or check AI Analysis under
      </Div>
      <Div spanTag textGray700 fontSize18 fontBold ml4>
        Lesson Review
      </Div>
    </>,
    's_lesson_review.in_review_page_what_you_can_do',
    ``,
    ``,
    ``,
    ``,
  ],
  ringle_usage: [`링글 사용법`, `How Ringle works`, 's_lesson_review.ringle_usage', ``, ``, ``, ``],
  zoom_usage: [`화상 프로그램 (Zoom) 설치 가이드`, `How to Install Zoom`, 's_lesson_review.zoom_usage', ``, ``, ``, ``],
  no_noshow_lesson: [
    `노쇼한 수업이 없습니다.`,
    `You showed up to all lessons.`,
    's_lesson_review.no_noshow_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  tooltip: [
    `다운로드할 수업 녹음 파일이 없습니다.`,
    `There are no downloadable audio files.`,
    's_lesson_review.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  teens_complete_feedback: [
    `튜터 피드백 확인하기 완료`,
    `Done checking feedback`,
    's_lesson_review.teens_complete_feedback',
    ``,
    ``,
    ``,
    ``,
  ],
  caf_check: [`CAF 진단 확인하기`, `CAF 진단 확인하기`, 's_lesson_review.caf_check', ``, ``, ``, ``],
  caf_tab: [`CAF 진단`, `CAF 진단`, 's_lesson_review.caf_tab', ``, ``, ``, ``],
  caf_menu: [`CAF 진단`, `CAF 진단`, 's_lesson_review.caf_menu', ``, ``, ``, ``],
  caf_msg: {
    noshow: [
      `노쇼 수업으로 CAF 진단이 제공되지 않습니다.`,
      `노쇼 수업으로 CAF 진단이 제공되지 않습니다.`,
      's_lesson_review.caf_msg.noshow',
      ``,
      ``,
      ``,
      ``,
    ],
    noaudio: [
      `해당 수업은 [수업 녹음] 미설정으로 CAF 진단이 제공되지 않습니다.

수업 전 [수업 설정 방식] 메뉴에서 [수업 녹음]을 설정하시면 다음 수업부터
CAF 진단을 확인할 수 있습니다.`,
      `해당 수업은 [수업 녹음] 미설정으로 CAF 진단이 제공되지 않습니다.

수업 전 [수업 설정 방식] 메뉴에서 [수업 녹음]을 설정하시면 다음 수업부터
CAF 진단을 확인할 수 있습니다.`,
      's_lesson_review.caf_msg.noaudio',
      ``,
      ``,
      ``,
      ``,
    ],
    freetopic: [
      `자유주제 수업에는 CAF 진단이 제공되지 않습니다.`,
      `자유주제 수업에는 CAF 진단이 제공되지 않습니다.`,
      's_lesson_review.caf_msg.freetopic',
      ``,
      ``,
      ``,
      ``,
    ],
    prep: [
      `CAF 진단 준비중입니다.
CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
      `CAF 진단 준비중입니다.
CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
      's_lesson_review.caf_msg.prep',
      ``,
      ``,
      ``,
      ``,
    ],
    gotoapp: [
      `CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
      `CAF 진단 결과는 링글 앱에서만 확인 가능합니다.`,
      's_lesson_review.caf_msg.gotoapp',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const s_classroom = {
  help: [`지원 요청`, `Help`, 's_classroom.help', ``, ``, ``, ``],
  audio_problem: [`오디오 연결 오류`, `Audio issue`, 's_classroom.audio_problem', ``, ``, ``, ``],
  video_problem: [`Zoom 비디오 오류`, `Video issue`, 's_classroom.video_problem', ``, ``, ``, ``],
  write_your_message: [`메시지를 입력해주세요`, `Write your message`, 's_classroom.write_your_message', ``, ``, ``, ``],
  docs_problem: [`Ringle Docs 오류`, `Ringle Docs issue`, 's_classroom.docs_problem', ``, ``, ``, ``],
  zoom_problem: [`Zoom 실행 오류`, `Cannot open Zoom`, 's_classroom.zoom_problem', ``, ``, ``, ``],
  zoom_guide: [`Zoom 가이드`, `Zoom Guide`, 's_classroom.zoom_guide', ``, ``, ``, ``],
  minimize_room_drage_it_here: [
    `Zoom을 최소화하여 이곳에 놓아주세요`,
    `Minimize ‘Zoom’ and drag it here.`,
    's_classroom.minimize_room_drage_it_here',
    ``,
    ``,
    ``,
    ``,
  ],
  click_on_join_audio_button: [
    `소리가 들리지 않을 경우, Zoom화면 왼쪽 하단의 '오디오 연결' 또는 'Join audio' 아이콘을 눌러 [컴퓨터 오디오로 참가] 버튼을 클릭해주세요.`,
    `If you cannot hear the audio, place your mouse cursor on Zoom. Click on the "Join audio" button to start.`,
    's_classroom.click_on_join_audio_button',
    ``,
    ``,
    ``,
    ``,
  ],
  plz_click_if_u_cannot_open_zoom: [
    `Zoom이 실행되지 않을때는 아래의 버튼을 클릭해주세요`,
    `If you cannot open Zoom, please click on the link below.`,
    's_classroom.plz_click_if_u_cannot_open_zoom',
    ``,
    ``,
    ``,
    ``,
  ],
  zoom_force_run: [`Zoom 강제 실행`, `Force zoom to run`, 's_classroom.zoom_force_run', ``, ``, ``, ``],
  lesson_style: [`수업 방식`, `Lesson Style`, 's_classroom.lesson_style', ``, ``, ``, ``],
  my_answer: [`내 답변`, `My Response`, 's_classroom.my_answer', ``, ``, ``, ``],
  leave_classroom: [`수업 나가기`, `Exit`, 's_classroom.leave_classroom', ``, ``, ``, ``],
  leave_classroom_confirm: [
    `수업 화면에서 나가시겠습니까?`,
    `Are you sure you want to exit?`,
    's_classroom.leave_classroom_confirm',
    ``,
    ``,
    ``,
    ``,
  ],
  popup_block_title: [
    `팝업 허용 요청`,
    `Please allow pop-ups on this page.`,
    's_classroom.popup_block_title',
    ``,
    ``,
    ``,
    ``,
  ],
  popup_block_subtitle: [
    `수업 프로그램인 Zoom을 실행하기 위해 팝업을 허용해주세요`,
    `Please allow pop-ups on this page to open Zoom for Ringle lessons.`,
    's_classroom.popup_block_subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_title: [
    `Zoom 화상수업 시작하기`,
    `Connecting to Zoom for Your Lesson`,
    's_classroom.modal_title',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_desc: [
    `곧 자동으로 열리는 Zoom 오디오는 이렇게 연결할 수 있어요.`,
    `When Zoom launches automatically, please follow these steps to enable audio on Zoom.`,
    's_classroom.modal_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_1: [`다시 보지 않기`, `Do not show again`, 's_classroom.btn_1', ``, ``, ``, ``],
  btn_2: [`확인`, `Got it`, 's_classroom.btn_2', ``, ``, ``, ``],
  modal_zoom_title: [`Zoom 오류 해결`, `Zoom Troubleshooting`, 's_classroom.modal_zoom_title', ``, ``, ``, ``],
  modal_zoom_tap_1: [`소리가 들리지 않을 때`, `No sound is coming out`, 's_classroom.modal_zoom_tap_1', ``, ``, ``, ``],
  modal_zoom_tap_2: [`Zoom이 실행되지 않을 때`, `Zoom won't launch`, 's_classroom.modal_zoom_tap_2', ``, ``, ``, ``],
  modal_zoom_desc_1: [
    <>
      Zoom 실행 후 오디오 연결을 아래와 같이 진행해주세요.
      <br />
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test
      </a>{' '}
      에서 카메라와 오디오를 테스트 할 수 있습니다.
    </>,
    <>
      After Zoom is launched, please connect your audio as follows.
      <br />
      You can also test your camera and audio by going to{' '}
      <a href="https://zoom.us/test" target="_blank">
        https://zoom.us/test.
      </a>
    </>,
    's_classroom.modal_zoom_desc_1',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_zoom_desc_2: (helpLink) => [
    <>
      Zoom이 열리지 않는다면 아래의 [Zoom 다시 열기]를 클릭하거나 링글 모바일 앱을 통해 접속해 주세요. 만약 문제가
      해결되지 않는다면, 페이지 상단의 {helpLink}을 통해 문의를 남겨주세요.
    </>,
    <>
      If Zoom doesn't launch automatically, please click [Relaunch Zoom] below or connect from the Ringle app. If the
      problem isn't solved, please leave us a message by clicking on {helpLink} at the top.
    </>,
    's_classroom.modal_zoom_desc_2',
    ``,
    ``,
    ``,
    ``,
  ],
  btn: [`Zoom 다시 열기`, `Relaunch Zoom`, 's_classroom.btn', ``, ``, ``, ``],
  zoom_help: [`Zoom 오류 해결`, `Zoom Troubleshooting`, 's_classroom.zoom_help', ``, ``, ``, ``],
  zoom_tooltip: [
    `Zoom이 열리지 않는다면 이곳을 클릭하세요.`,
    `If Zoom doesn't launch automatically,  
please click here.`,
    's_classroom.zoom_tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_common = {
  ringle: [`링글`, `Ringle`, 's_common.ringle', ``, ``, ``, ``],
  minutes: [`분`, `-min`, 's_common.minutes', ``, ``, ``, ``],
  minutesNoDash: [`분`, `min`, 's_common.minutesNoDash', ``, ``, ``, ``],
  ok: [`확인`, `Ok`, 's_common.ok', ``, ``, ``, ``],
  confirm: [`확인`, `Confirm`, 's_common.confirm', ``, ``, ``, ``],
  cancel: [`취소`, `Cancel`, 's_common.cancel', ``, ``, ``, ``],
  exit: [`나가기`, `Exit`, 's_common.exit', ``, ``, ``, ``],
  send: [`보내기`, `Send`, 's_common.send', ``, ``, ``, ``],
  save: [`저장`, `Save`, 's_common.save', ``, ``, ``, ``],
  dont_show_again: [`다시 보지 않기`, `Don't show again`, 's_common.dont_show_again', ``, ``, ``, ``],
  select: [`선택하기`, `Select`, 's_common.select', ``, ``, ``, ``],
  timezone: [`타임존`, `Time zone`, 's_common.timezone', ``, ``, ``, ``],
  today: [`오늘`, `Today`, 's_common.today', ``, ``, ``, ``],
  _40min: [`40분`, `40min`, 's_common._40min', ``, ``, ``, ``],
  _20min: [`20분`, `20min`, 's_common._20min', ``, ``, ``, ``],
  you_can_search_by_keyboard: [
    `도시를 직접 입력하시거나 리스트에서 선택해주세요.`,
    `Enter a city name or select from the list.`,
    's_common.you_can_search_by_keyboard',
    ``,
    ``,
    ``,
    ``,
  ],
  locale: (userLocale, routerLocale) => [
    <>
      현재 설정된 언어({userLocale})와 접속하려는 페이지의 언어({routerLocale}
      )가 다릅니다. 언어설정을 {routerLocale}(으)로 변경하시겠습니까?
    </>,
    <>
      The page you're about to enter has a different language setting ({routerLocale}) from your current language (
      {userLocale}). Do you want to change your current language setting to {routerLocale}?
    </>,
    's_common.locale',
    ``,
    ``,
    ``,
    ``,
  ],
  save_and_enter: [`저장 & 수업 입장`, `Save & Enter`, 's_common.save_and_enter', ``, ``, ``, ``],
  mon: [`월`, `Mon`, 's_common.mon', ``, ``, ``, ``],
  tue: [`화`, `Tue`, 's_common.tue', ``, ``, ``, ``],
  wed: [`수`, `Wed`, 's_common.wed', ``, ``, ``, ``],
  thu: [`목`, `Thu`, 's_common.thu', ``, ``, ``, ``],
  fri: [`금`, `Fri`, 's_common.fri', ``, ``, ``, ``],
  sat: [`토`, `Sat`, 's_common.sat', ``, ``, ``, ``],
  sun: [`일`, `Sun`, 's_common.sun', ``, ``, ``, ``],
  please_contact_us_via_chat: [
    `페이지가 로드 되지 않으면, 아래 챗으로 문의주세요`,
    `If the page doesn't load, please contact us via chat.`,
    's_common.please_contact_us_via_chat',
    ``,
    ``,
    ``,
    ``,
  ],
  cookiebanner: {
    first: [
      `이 웹사이트를 계속 사용하시면 쿠키 사용에 동의하시는 것으로 간주됩니다.`,
      `By continuing to use this site, you accept our`,
      's_common.cookiebanner.first',
      ``,
      ``,
      ``,
      ``,
    ],
    second: [`쿠키 사용 여부`, `use of cookies.`, 's_common.cookiebanner.second', ``, ``, ``, ``],
    third: [
      `는 설정을 통해 언제든 변경하실 수 있습니다.`,
      `You can change your cookie settings at any time.`,
      's_common.cookiebanner.third',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  new: [`NEW`, ``, 's_common.new', ``, ``, ``, ``],
}
export const s_head = {
  student: {
    title: [
      `No.1 일대일 화상영어 링글`,
      `Ringle: Premium Private English Tutoring`,
      's_head.student.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `스탠퍼드 MBA출신이 만든 1:1 화상 영어 명문대 원어민 튜터의 똑똑한 수업을 경험하세요!`,
      `Have an in-depth discussion with tutors from the top 30 universities in the U.S. and the U.K.`,
      's_head.student.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    keywords: [
      `유학, 영어, 원어민, 일대일영어, 화상영어, 미국대학, 미국유학, 전화영어, 원어민 화상영어, 일대일 영어 회화, 화상영어 사이트, 미국대학교 순위`,
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
      's_head.student.keywords',
      ``,
      ``,
      ``,
      ``,
    ],
    author: [`Ringle Team`, `Ringle Team`, 's_head.student.author', ``, ``, ``, ``],
    noscript: [
      `귀하가 사용하고 계신 브라우저는 HTML5를 지원하고 있지 않아서, 할 수 없이 JScript를 써서 몇몇 요소가 제대로 보이도록 구현했습니다.
그런데 안타깝게도 귀하의 브라우저에선 스크립트 기능이 꺼져있습니다. 이 페이지가 제대로 표시되려면 스크립트 기능을 켜주셔야만 합니다.`,
      `Browser not supported. Please use Google Chrome or Microsoft Edge to access Ringle.`,
      's_head.student.noscript',
      ``,
      ``,
      ``,
      ``,
    ],
    title_error_page: [
      `No.1 일대일 화상영어 링글.`,
      `Ringle: Premium Private English Tutoring.`,
      's_head.student.title_error_page',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  tutor: {
    title: [
      `Ringle: The most flexible, rewarding college job`,
      `Ringle: The most flexible, rewarding college job`,
      's_head.tutor.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `Tutor global professionals at your own schedule with pre-made, carefully curated lesson materials. Earn up to $22 with incentives and bonuses on a tutor-friendly platform.`,
      `Tutor global professionals at your own schedule with pre-made, carefully curated lesson materials. Earn up to $22 with incentives and bonuses on a tutor-friendly platform.`,
      's_head.tutor.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    keywords: [
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
      `study abroad, English, native speakers, 1:1 tutoring, online tutoring, English conversations, English tutoring online, US college rankings`,
      's_head.tutor.keywords',
      ``,
      ``,
      ``,
      ``,
    ],
    author: [`Ringle Team`, `Ringle Team`, 's_head.tutor.author', ``, ``, ``, ``],
    noscript: [
      `Your current browser does not support HTML5, so we had to use JScript to show you some of this page. Unfortunately, the script function is not turned on. You need to turn on the script function on your browser, in order to have a full view of this page.`,
      `Browser not supported. Please use Google Chrome or Microsoft Edge to access Ringle.`,
      's_head.tutor.noscript',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const s_footer = {
  beyond_offline_new_standards: [
    `No.1 일대일 화상영어 링글`,
    `The New Standard in Online English Learning`,
    's_footer.beyond_offline_new_standards',
    ``,
    ``,
    ``,
    ``,
  ],
  companay: [`Company`, `Company`, 's_footer.companay', ``, ``, ``, ``],
  b2b: [`Business`, `Business`, 's_footer.b2b', ``, ``, ``, ``],
  business_inquiry: [`제휴문의`, `B2B Overview`, 's_footer.business_inquiry', ``, ``, ``, ``],
  terms_of_privacy: [`개인정보처리방침`, `Privacy Policy`, 's_footer.terms_of_privacy', ``, ``, ``, ``],
  terms_of_service: [`이용약관`, `Terms of Service`, 's_footer.terms_of_service', ``, ``, ``, ``],
  policy: [`정책`, `Policy`, 's_footer.policy', ``, ``, ``, ``],
  ringle_company: [
    `(주)링글잉글리시에듀케이션서비스`,
    `ⓒ Ringle English Education Service`,
    's_footer.ringle_company',
    ``,
    ``,
    ``,
    ``,
  ],
  ringle_address: [
    `서울 서초구 서초대로78길 22 (홍우제2빌딩) 11층`,
    `22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
    's_footer.ringle_address',
    ``,
    ``,
    ``,
    ``,
  ],
  ringle_phone: [
    <>
      사업자등록번호 876-81-00110 통신판매신고번호 2022-서울서초-3045
      <br />
      대표전화 02-6204-8880
    </>,
    ``,
    's_footer.ringle_phone',
    ``,
    ``,
    ``,
    ``,
  ],
  ringle_ceo: [
    `대표이사 : 이성파, 이승훈. 개인정보책임자 : 이성파 contact@ringleplus.com`,
    `Email : contact@ringleplus.com`,
    's_footer.ringle_ceo',
    ``,
    ``,
    ``,
    ``,
  ],
  pricing: [`가격`, `Pricing`, 's_footer.pricing', ``, ``, ``, ``],
  ringle_team: [`링글 팀`, `Ringle Team`, 's_footer.ringle_team', ``, ``, ``, ``],
  help_center: [`헬프센터`, `Help Center`, 's_footer.help_center', ``, ``, ``, ``],
  career: [`링글 채용`, ``, 's_footer.career', ``, ``, ``, ``],
}
export const s_privacy = {
  heading: [`개인정보처리방침`, `Privacy Policy`, 's_privacy.heading', ``, ``, ``, ``],
  text: [
    `㈜링글잉글리시에듀케이션서비스(이하 "회사” "링글”)은 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 "회원” 권익 보호에 최선을 다하고 있습니다.

"링글"은 "회원”의 개인정보를 보호하고 개인정보와 관련한 "회원"의 고충을 원활하게 처리할 수 있도록 개인정보처리방침을 제정∙시행하며, "링글"이 개인정보처리방침을 개정하는 경우 링글의 홈페이지(ringleplus.com)에 변경 사항을 즉시 게재하거나 "회원” 개별 이메일(ID)로 공지합니다.

제1조(개인정보 수집 항목 및 이용 목적 및 수집방법)
"링글"는 "회원"의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 본 조에서 고지한 범위를 넘어 "회원"의 개인정보를 활용하지 않습니다. 수집한 개인정보 활용의 목적은 아래와 같습니다.

1. 회원 관리: 회원제 서비스 이용에 따른 본인확인, 가입 의사 확인, 개인 식별, 불량 회원의 부정 이용 및 비인가 사용 방지, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 연령확인, 불만처리 등 민원 상담 처리, 고지사항 등 최신 정보 전달
2. 서비스 제공에 관한 계약 이행 및 요금정산: 학습 서비스 및 콘텐츠 일체 제공, 서비스 요금 결제, 상품 배송, 금융서비스 제공, 요금추심 등
3. 마케팅 및 광고에 활용: 신규 서비스 개발 및 고도화, 이벤트 등 광고성 정보 전달, 인구 통계학적 특성에 따른 서비스 제공 및 광고 게재
4. 사고발생시 원인 규명 및 처리를 위한 수집 및 이용

제2조(개인정보 수집 항목)
최초 회원가입 시 회원식별을 위해 아래와 같은 정보를 수집합니다.
- 항목: 성명, 이메일(ID), 비밀번호, 가입인증정보(메일주소, 휴대폰 번호), 만 14세 미만은 법정대리인 정보

원활한 고객상담과 맞춤 서비스의 제공을 위해 아래와 같은 추가적인 정보를 수집할 수 있습니다.
- 항목: 영어 이름, 연락처(메일주소, 휴대폰 번호), 인증정보(메일주소, 휴대폰 번호), 직장 및 직무, 거주지의 시간대, 메일 수신 여부, 수강정보 및 이벤트 소식 이메일 수신 여부,

부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 이용자의 동의를 얻은 후, 다음과 같은 별도의 정보가 추가적으로 수집될 수 있습니다.
- 대학생, 대학원생 할인: 재학증명서 또는 학생증,
- 고객 학습 종료 후 제공되는 피드백과 녹음 및 교정 파일

서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
- 접속 IP 정보, 쿠키, 결제기록, 접속로그, 서비스 이용 기록, 불량 이용 기록

유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
- 신용카드 결제 시: 카드사명, 카드번호, 카드 소유주 생년월일, 유효기간 등
- 계좌 이체 시: 은행 명, 입금자 명, 계좌번호 등

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
1) 회원 가입 및 정보 수정
2) 전화, 메신저, 오프라인 개인 상담
3) 제휴사로부터의 제공
4) 생성정보 수집 툴을 통한 자동 수집

제3조(개인정보의 보유 및 이용기간)
"링글"은 "회원"이 회원자격을 유지하고 있는 동안 수집된 "회원"의 개인 및 수강정보를 보유∙이용할 수 있으며, "회원"이 탈퇴하거나 자격을 상실할 경우에는 "회원"의 별도 요청이 없더라도 수집된 회원정보를 삭제 및 파기합니다. 단, "회원"의 탈퇴 또는 자격상실에도 불구하고 다음의 정보는 아래의 이유로 명시한 기간 동안 보존 합니다.

1. 링글 내부 방침에 의한 정보 보유
- 보존항목: 이름, 이메일 주소(ID), 전화번호, 수업 로그
- 보존 근거: 재가입 제한 및 서비스 중복 신청 확인
- 보존기간: 회원 탈퇴 후 1년,

2. 관련법령에 의한 정보보유 사유
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

계약 또는 청약철회 등에 관한 기록 (소비자 식별정보, 계약∙청약철회 기록 등) - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
대금결제 및 재화 등의 공급에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
소비자의 불만 및 분쟁처리에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 3년
신용정보의 수집∙처리 및 이용 등에 관한 기록 - 신용정보의 이용 및 보호에 관한 법률 - 3년
웹사이트 방문기록 (로그기록, IP 등) - 통신비밀보호법 - 3개월

3. 휴면회원의 정보 보유
"회원"이 마지막 접속일로부터 1년(365일) 이상 로그인을 하지 않은 경우 해당 "회원"의 아이디는 "휴면회원”이 되어 회원 로그인을 비롯한 모든 서비스에 대한 이용이 정지되고, "링글"은 "휴면회원”의 개인정보를 다른 아이디와 별도로 관리합니다. "링글"은 "휴면회원” 처리 예정일 30일 전 해당 사실을 이메일을 통하여 사전 안내하며, "회원"은 "휴면회원” 처리일 이후에 링글 사이트(ringleplus.com)” 및 링글 앱 상에서 직접 본인 확인을 거쳐 휴면상태 해지신청을 하는 즉시 다시 정상적으로 서비스를 이용할 수 있습니다.

제4조(동의의 거부권 등)
"회원"은 개인정보 수집∙이용에 관한 동의를 거부할 권리가 있습니다. 다만, 계약 체결 등을 위해 필요한 최소한의 개인정보 수집∙이용에 관한 동의를 거부하는 경우에는 링글 콘텐츠 및 서비스 이용이 불가(본인 여부 및 계약 체결 의사 불명 이유)하고, 마케팅 활동 및 홍보를 위한 개인정보 수집∙이용 또는 선택적 수집∙이용에 관한 동의를 거부하는 경우에는 이벤트, 혜택에 대한 정보를 제공받지 못하거나 사은품∙판촉물 제공, 할인 혜택 적용 등의 불이익이 발생할 수 있습니다.

제5조(개인정보의 파기절차 및 방법)
링글은 원칙적으로 이용자 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 링글의 개인정보 파기절차 및 방법은 다음과 같습니다.

파기절차
- "회원”이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.

파기기한
개인정보의 보유기간이 경과한 경우에는 그 경과한 날로부터, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 된 경우에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 각 지체 없이(정당한 사유가 없는 한 5일 이내) 해당 개인정보를 파기합니다.

파기방법
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
- 전자파일 형태의 개인정보는 복구 및 재생이 불가능한 기술적 방법을 사용하여 삭제합니다.

제6조(회원 및 법정대리인의 권리와 그 행사방법)
1. "회원"은 언제든지 "회원"의 개인정보를 열람하거나 수정할 수 있으며, "링글"의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청할 수 있습니다.

2. "회원"의 개인정보 조회 및 수정을 위해서는 "나의 정보 수정하기”를, 가입해지(동의철회)를 위해서는 "탈퇴하기(Close Account)"를 클릭하여 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 보호 업무 담당부서에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.

3. 만 14세 이상인 경우에만 회원 가입이 가능하며, 개인정보의 수집∙이용에 법정 대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다.

제7조(개인정보 자동수집 장치의 설치, 운영 및 거부에 관한 사항)
링글은 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이 정보는 반드시 "회원"이 정보를 제공하였을 때만 저장되고, "회원"이 제공하지 않은 정보를 얻을 수 없으며, 컴퓨터에 저장되어 있는 다른 파일들에 접근할 수 없습니다. 쿠키의 사용 목적 및 쿠키 설정 거부 방법은 다음과 같습니다.

쿠키의 사용 목적
링글 회원 및 사이트 방문객들의 이용형태(접속빈도, 방문시간, 서비스 관심도)를 분석해 이용자에게 최적화된 정보를 제공하기 위하여 사용합니다.

쿠키의 설치/운영 및 거부
1. "회원"은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, "회원"은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, "회원”이 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 제공에 어려움이 있을 수 있습니다.

2. 쿠키 설치 허용 여부를 설정하는 방법은 다음과 같습니다.
- Internet Explorer: 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
- Google Chrome: 웹 브라우저 상단의 설정 &gt; 고급 설정 &gt; 개인정보

제8조(개인정보의 기술적/관리적 보호 대책)
회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

1. 비밀번호 관리
"회원” 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 비밀번호의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

2. 해킹 등에 대비한 대책
"링글"은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신・점검을 하며 시스템을 기술적 ・ 물리적으로 감시 및 차단하고 있습니다.
3. 취급 직원의 최소화 및 교육
회사의 개인정보관련 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 관리자 수시 교육을 통하여 링글 개인정보처리방침의 준수를 항상 강조하고 있습니다.

4. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

5. 개인정보처리시스템 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

제9조(개인정보관리책임자 및 담당자의 연락처)
링글의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자께 신고하실 수 있습니다. 링글은 "회원”의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

개인정보 관리책임자
이름: 이성파
이메일: contact@ringleplus.com

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
- 개인정보침해신고센터 (www.118.or.kr / 118)
- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)
- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)
- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)

제10조(고지의 의무)
현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 고지할 것입니다.

-공지일자: 2017년 7월 26일
-시행일자: 2017년 7월 26일`,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Ringle English Education Services Co., Ltd. (hereinafter referred to as "the Company" "RINGLE”) is required to
      comply with the provisions of the relevant laws and regulations on personal information protection, such as the
      Communication Confidentiality Protection Act, the Telecommunications Business Act, the Act on Promotion of
      Information Network Usage and Information Protection. We are committed to protecting the rights and interests of
      our "members" by establishing a personal information processing policy in accordance with relevant laws and
      regulations.
      <br />
      RINGLE enacts and enforces a personal information processing policy to protect the personal information of
      "members" and to smoothly handle complaints related to personal information.
      <br />
      When RINGLE revises a personal information processing policy, RINGLE Immediately posts changes to the
      homepage(ringleplus.com) or notify them by member’s individual email.
      <br />
      <br />
      NOTICE TO CALIFORNIA RESIDENTS: Our use of your information is subject to our "Privacy Notice for California
      Residents”, which is available{' '}
      <a
        className="text-primary"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        HERE
      </a>
      . If you are a California resident, please refer to that document for information on how we collect and use your
      personal information, and your rights under California law.
      <br />
      <br />
      Article 1 Collection Items of Personal Information, Purpose of Use and Collection Method
      <br />
      RINGLE shall not use the personal information of "members" beyond the scope of the notice set out in this article
      except by the consent of the "member" or by the provisions of laws and regulations. The purpose of utilizing
      collected personal information is as follows.
      <br />
      1. Membership Management: Confirmation of the identity of the member by the use of the membership service,
      identification of the individual, illegal use of the bad member and prevention of unauthorized use, restriction of
      the number of joining and subscribing, confirmation of the legal representative, providing up-to-date information
      such as age verification, complaint handling, and civil affairs consultation processing.
      <br />
      2. Implementation of contracts and settlement of fees: Providing all learning services and contents, payment of
      service fees, delivery of goods, provision of financial services, collection of fees, etc.
      <br />
      3. Use in marketing and advertising: development and advancement of new services, delivery of advertisement
      information such as events, provision of services according to demographic characteristics, and advertisement.
      <br />
      4. Collection and use for cause identification and handling in case of an accident
      <br />
      <br />
      Article 2 Personal Information Collection Items
      <br />
      The following information will be collected to identify members when members sign up.
      <br />
      - Item: Name, e-mail(ID), password, subscription authentication information (e-mail address, mobile phone number),
      legal representative information for under 14 years. <br />
      We can collect additional information to provide you with a smooth customer consultation and personalized service.
      <br />
      - Items: English name, contact information (e-mail address, mobile phone number), authentication information
      (e-mail address, mobile phone number), job and role, time zone of residence, whether or not to receive mail,
      course information, and event news.
      <br />
      Additional information and additional services may be collected after obtaining the user's consent only to the
      user of the service during the use of the service or the event application.
      <br />
      - College Student, Graduate Student Discount: Certificate of enrollment or student ID,
      <br />
      - Feedback and recording and corrections on english use provided after the end of lesson
      <br />
      <br />
      The following information may be automatically generated and collected during the process of using the service or
      processing the business.
      <br />
      - Connection IP information, cookies, payment records, access logs, service usage records, bad usage records
      <br />
      The following payment information may be collected during the use of paid services.
      <br />
      - Credit card payment: Name of card, card number, cardholder's date of birth, expiration date, etc.
      <br />
      - When transferring money: Bank name, Depositor’s name, account number, etc.
      <br />
      <br />
      The Company collects personal information in the following ways:
      <br />
      Sign up and edit information
      <br />
      Telephone, messenger, offline personal consultation
      <br />
      Provision from affiliates
      <br />
      Auto collection through information collection tool
      <br />
      CalOPPA DISCLOSURE & TRACKING: We do not currently respond to web browser "Do Not Track” settings that allow you
      to opt-out of information collection.
      <br />
      <br />
      Article 3 Retention and Use Period of Personal Information
      <br />
      RINGLE may retain and use the personal information of "members" collected while "member" maintains membership, and
      shall not be liable for any loss or expulsion of the "member" Delete and destroy collected member information.
      However, despite the withdrawal or loss of qualification of a "Member", the following information will be retained
      for the period specified below for the following reasons:
      <br />
      <br />
      1. Retain information by RINGLE’s internal policy
      <br />
      - Retention items: name, email address (ID), phone number, lesson log
      <br />
      - Preservation grounds: Restriction on re-entry and confirmation of service duplication
      <br />
      - Retention period: 1 year after membership withdrawal
      <br />
      <br />
      2. Reason for holding information by relevant laws and regulations
      <br />
      If it is necessary to preserve in accordance with relevant laws and regulations such as commercial law, consumer
      protection law in e-commerce, etc., the company keeps the member information for a certain period determined by
      related laws and regulations. In this case, the company uses the information it keeps only for the purpose of
      keeping it, and the preservation period is as follows.
      <br />
      Records on contract or withdrawal of subscription (record of consumer identification information, contract,
      withdrawal of subscription, etc.) - Act on Consumer Protection in Electronic Commerce etc. - 5 years
      <br />
      Record of payment and goods supply - Act on consumer protection in electronic commerce - 5 years
      <br />
      Records on complaints and disputes of consumers - Act on consumer protection in electronic commerce - 3 years
      <br />
      Records on the collection, processing and use of credit information - Act on the Use and Protection of Credit
      Information - 3 years
      <br />
      Web site visit history (log, IP, etc.) - Communication secret protection law - 3 months
      <br />
      3. Retention of dormant members' information
      <br />
      If a member does not log in for more than one year (365 days) from the last access date, the ID of the member
      becomes "Inactive Member" and the use of all services including member login is suspended; the "dormant member" is
      managed separately from other IDs. RINGLE will inform the member of the fact by email 30 days before the scheduled
      date of "inactive member", and the member will confirm his / her identity directly on RINGLE site
      (ringleplus.com). The member can use the service normally again as soon as you apply for a termination of
      dormancy.
      <br />
      <br />
      Article 4 Veto of Consent
      <br />
      "Member" has the right to refuse consent to the collection and use of personal information. However, if you refuse
      to consent to the minimum collection and use of personal information necessary for the signing of a contract, you
      shall not use the RINGLE content and services (whether you are a person or not). If you refuse to consent to the
      collection, you may not receive information about the event or benefit, or you may incur disadvantages such as
      giving gifts, promotional items, or discounts.
      <br />
      <br />
      Article 5 Procedures and Methods of Destroying Personal Information
      <br />
      In principle, RINGLE destroys without delay when the purpose of collection and use of user's personal information
      are achieved. The procedure and method of personal information destruction of RINGLE is as follows.
      <br />
      Destruction Process
      <br />
      The information that the "member" inputs for signup etc. is destroyed after a certain period of time according to
      the internal policy and other related laws and regulations (see the period of possession and use) after the
      purpose is achieved. The information shall not be used for any purpose other than those held by law unless
      otherwise provided by law.
      <br />
      Destruction period
      <br />
      In the case where the retention period of personal information has elapsed, it is recognized that the processing
      of personal information is unnecessary when the personal information is unnecessary, such as attaining the
      processing purpose of the personal information, abolishing the corresponding service, ending the business, we will
      destroy the personal information from each day without delay (within 5 days unless there is a legitimate reason)
      <br />
      Destruction Method
      <br />
      - Personal information printed on paper is crushed by a crusher or destroyed by incineration.
      <br />
      - Personal information in the form of electronic files can be deleted using technical methods that can not be
      recovered or reproduced.
      <br />
      <br />
      Article 6 Right of Members and Legal Representatives and How to Act
      <br />
      1. "Member" may read or modify the personal information of "Member" at any time, and may refuse to accept or
      cancel membership (withdrawal) if the user does not consent to the processing of personal information of RINGLE.
      <br />
      2. To view and modify personal information of "Member", please click "Edit My Information" and click "Delete
      Account" to cancel the membership (withdrawal of consent) or withdrawal is possible. If you contact us by phone,
      email, or personal information protection department, we will take action without delay.
      <br />
      3. Only members who are 14 years of age or older are allowed to register. In principle, we do not collect personal
      information of children under the age of 14 who need the consent of a legal representative to collect and use
      personal information.
      <br />
      OUR POLICY TOWARDS CHILDREN: Protecting the privacy of children is especially important to us, and we strictly
      comply with the FTC’s Children’s Online Privacy Protection Rule ("COPPA”). Our Services are not directed to, or
      meant for persons under the age of 13. We do not knowingly collect or solicit personally identifiable information
      from children under 13, or allow them to register accounts. If we become aware that a child under 13 has provided
      us with Personal Information, or any user has provided information of or about any person under the age of 13, we
      will delete such information from our systems. Do not submit any Personal Information to us if you are under the
      age of 13, or any information of, or about, persons under the age of 13.
      <br />
      If you are a parent or guardian and you become aware that your child has provided us with Personal Information
      without your consent, you should contact us immediately at RINGLE customer service via email at
      contact@ringleplus.com, or use the contact functionality we provide through your account or on the Site to request
      we delete your child’s information.
      <br />
      <br />
      Article 7 Installation, Operation and Rejection of Automatic Personal Information Collection
      <br />
      RINGLE uses 'cookies' to store and retrieve your information from time to time to provide personalized and
      customized services. Cookies are small text files that are sent to your browser by the server used to run the
      website and are stored on your computer's hard disk. This information is only stored when "Member" provides
      information, can not obtain information not provided by "Member", and can not access other files stored on the
      computer. The purpose of the cookie and how to decline the cookie setting are as follows.
      <br />
      The purpose of the cookie
      <br />
      It is used to provide information optimized for users by analyzing the usage patterns (frequency of access, visit
      time, and service interest) of members and site visitors.
      <br />
      Installing / operating cookies
      <br />
      1) "Member" has the option to install cookies. Therefore, "Membership" may allow all cookies by setting options in
      the web browser, check each time a cookie is saved, or refuse to save all cookies. However, if "member" declines
      to install cookies, it may be difficult to provide some services that require login.
      <br />
      2) How to set whether to install cookies is as follows.
      <br />
      - Internet Explorer: Tools ; Internet Options ; Privacy
      <br />
      - Google Chrome: Settings ; Advanced Settings ; Privacy
      <br />
      <br />
      Article 8 Technical / Administrative Protection Measures of Personal Information
      <br />
      The Company takes the following technical and administrative measures to ensure the safety of personal information
      in order to prevent the loss, theft, leakage, alteration or damage of personal information in handling users'
      personal information.
      <br />
      1. Password management
      <br />
      The password of "Member" ID is known only to you, and you can check and change your password only by yourself who
      knows the password.
      <br />
      2. Measures against hacking
      <br />
      RINGLE installs a security program to prevent leakage and damage of personal information caused by hacking or
      computer viruses, periodically updates and checks the system, and technically and physically monitors and blocks
      the system.
      <br />
      3. Minimization and training of handling staff
      <br />
      The company's personal information personnel are limited to the person in charge and are given a separate password
      for this purpose. They are regularly updated, and we constantly emphasize compliance with the RINGLE’s personal
      information processing policy through on-the-job training.
      <br />
      4. Establishment and enforcement of internal management plan
      <br />
      We have established and implemented an internal management plan for the safe handling of personal information.
      <br />
      5. Access restriction on personal information processing system
      <br />
      We take necessary measures to control access to personal information through granting, modifying, and deleting
      access rights to the database system that handles personal information. We also control unauthorized access from
      outside by using an intrusion prevention system. However, the company does not take any responsibility for the
      problems caused by leakage of personal information such as ID, password and social security number due to user's
      carelessness or problems on the Internet.
      <br />
      <br />
      Article 9 Notice to California Residents
      <br />
      <br />
      If you are a resident of the State of California, U.S.A., your Personal Information is subject to the California
      Consumer Privacy Act (the "CCPA”).
      <br />
      Our use of your information is fully intended to fully comply with the CCPA. Please consult our RINGLE "Privacy
      Notice for California Residents” (the "CCPA Notice”) for more information on additional rights and protections you
      have under the CCPA regarding your Personal Information.
      <br />
      If you are a resident of California, and there is any conflict between the terms of this Privacy Policy and the
      CCPA Notice, the terms of the CCPA Notice will take precedence at all times.
      <br />
      <br />
      Article 10 Notice Regarding International Transfer of Information
      <br />
      Your information may be transferred to and maintained on computers and systems located outside of your state,
      province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in
      your jurisdiction.
      <br />
      If you are located outside of the Republic of Korea and choose to provide information to us, you agree that your
      Personal Information will be transferred to the Republic of Korea and processed there. Your consent to this
      Privacy Policy followed by your submission of such information represents your agreement to that transfer.
      <br />
      <br />
      Article 11 Links to Other Sites
      <br />
      Our Services may provide links to other websites. If you click on another third-party link, you will be directed
      to that third-party's website. The fact that we link to a third-party website is not an endorsement, authorization
      or representation of our affiliation with that third-party, nor is it an endorsement of their privacy or
      information security policies or practices. We do not exercise control over third-party websites. These other
      websites may place their own cookies or other files on your computer, collect data, or solicit personal
      information from you. Other sites follow different rules regarding the use or disclosure of the personal
      information you submit to them. We encourage you to read the privacy policies or statements of the other websites
      you visit.
      <br />
      <br />
      Article 12 Personal Information Manager and Contact Person
      <br />
      You can report all complaints related to personal information generated by RINGLE service to your personal
      information manager. RINGLE will respond promptly to the "Member's" report.
      <br />
      Personal information manager: Sungpah Lee
      <br />
      Email: contact@ringleplus.com, sungpah@ringleplus.com
      <br />
      <br />
      Article 13 Obligation of Notification
      <br />
      We will notify you at least seven days before the amendment if there is any addition, deletion or amendment of the
      current Privacy Policy.
      <br />
      If you have any questions regarding this Privacy Policy, or our practice and procedures about your information,
      please contact us anytime via email at contact@ringleplus.com, or use the contact links and functionalities we
      provide through your account or on our Sites.
      <br />
    </>,
    's_privacy.text',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_terms = {
  heading: [`이용 약관`, `Terms of Service`, 's_terms.heading', ``, ``, ``, ``],
  text: [
    `
제1조(목적)
본 약관은 "서비스" 이용자가 (주)링글잉글리시에듀케이션서비스(이하 '회사')가 제공하는 온라인 영어교육 플랫폼(www.ringleplus.com) 및 제공되는 일체의 서비스(이하 '서비스’)를 이용함에 있어 회사와 회원의 권리와 의무 및 책임 사항, 이용 절차, 그리고 기타 필요한 사항을 규정함을 목적으로 합니다.

제2조(용어의 정의)
이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
"서비스": 회사가 제공하는 수업과 교정 및 이용가능한 콘텐츠 일체
"회원" : 회사와 "서비스" 이용 계약을 체결하고 회사가 제공하는 "서비스"를 이용하는 자
"이메일(ID)" : 인터넷 메일 계정. 회사는 "회원"의 "이메일”을 "회원” 식별과 "회원"의 "서비스" 이용을 위한 고유의 아이디(ID)로 사용함
"비밀번호" : "회원"의 "이메일(ID)”과 일치된 "회원"임을 확인하고, "회원" 자신의 비밀을 보호하기 위하여 "회원"이 정한 문자와 숫자의 조합
"관리자" : "서비스"의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 사람
"수업권(Credit)” : 링글 1:1 영어회화 또는 인터뷰 수업 1회 단위를 수업권이라 함. 수업권 1개는 수업 1회를 의미함.
"패키지” : 링글이 2개 이상의 수업권을 묶음으로 판매하는 단위를 의미함. 예로, 6회 패키지는 곧 수업 6회(수업권 6개)의 묶음을 의미함.

제3조(약관의 효력과 변경)
회사는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 "서비스" 사이트 초기 화면에 게시합니다.
회사는 이 규정을 변경할 수 있으며, 변경된 규정은 제1항과 같은 방법으로 공지함으로써 효력을 발생합니다.
회사는 약관을 개정하는 경우 개정 사유 및 시행일자를 명시하여 현행약관과 함께 시행일로부터 최소 7일 전에 제1항의 방법으로 공지합니다. 단, "회원"의 권리 또는 의무에 관한 중요한 규정의 변경은 최소 30일전에 공지하고 개정약관을 "회원"이 등록한 "이메일”로 발송하여 통지합니다.
회사의 제3항에 따른 개정약관 공지 또는 통지 후 "회원"이 약관 변경 적용일까지 명시적으로 거부의사를 표시하지 않은 경우 "회원"이 개정약관에 동의한 것으로 봅니다. 단, "회원"은 변경된 약관에 동의하지 않는 경우 "서비스"의 이용을 중단하고 이용계약을 해지할 수 있습니다.
"회원"은 약관의 변경에 대하여 주의의무를 다하여야 하며, 변경된 약관의 부지로 인한 "회원"의 피해는 회사가 책임지지 않습니다.

제4조(약관의 준칙)
이 약관에 명시되지 않은 사항과 이 약관의 해석에 관하여는 전기통신기본법, 전기통신사업법, 온라인 디지털콘텐츠산업 발전법, 전자상거래 등에서의 소비자보호 관련 법률, 정보통신망이용촉진 및 정보보호 등에 관한 법률 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 문화체육관광부장관이 정하는 디지털콘텐츠이용자보호지침, 기타 관계법령 또는 상관례에 따릅니다.

제5조(이용 계약의 성립)
이용 계약은 이용자의 이용 신청에 대한 회사의 이용 승낙과 이용자의 약관 내용에 대한 동의로 성립됩니다.
"회원"에 가입하여 "서비스"를 이용하고자 하는 자는 회사에서 요청하는 개인신상정보를 제공해야 합니다.
회사는 다음 각 호에 해당하는 이용 신청에 대하여는 승낙하지 아니할 수 있습니다.
1) 이름이 실명이 아니거나 다른 사람의 명의를이용한 경우
2) 이용 신청 시 필요 내용을 허위로 기재하여 신청한 경우
3) 사회의 안녕 질서 또는 미풍양속을 저해할 목적으로 신청한 경우
4) 기타 회사가 정한 이용 신청요건이 미비 된 경우

제6조(계약 사항의 변경)
"회원"은 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, "서비스" 관리를 위해 필요한 이메일(ID)는 수정이 불가능합니다.
"회원"은 가입시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 변경사항을 알려야 합니다.
제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.

제7조(개인정보보호 의무)
회사는 이용자의 정보 수집 시 "서비스" 이용을 위한 최소한의 정보를 수집합니다.
회사가 이용자의 개인 식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다. 제공된 개인정보는 당해 이용자의 동의 없이 목적 외로 이용하거나 제3자에게 제공할 수 없으며 이에 대한 모든 책임은 회사가 집니다.
이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집 목적 및 이용 목적, 제3자에 대한 정보 제공 관련 사항(제공 받는 자, 제공 목적 및 제공할 정보의 내용), 수탁업체에 대한 정보제공 관련사항(수탁자, 위탁업무 내용 및 정보제공 항목) 등 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’이라 함)에 규정한 사항을 미리 명시하거나 고지해야 하며 "회원”은 언제든지 이 동의를 철회할 수 있습니다.
회사는 개인정보 보호를 위하여 "관리자"를 최소한의 수로 한정하며 이용자 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
회사 또는 그로부터 개인정보를 제공 받는 제3자는 개인 정보의 수집목적 또는 제공 받는 목적을 달성할 때에는 당해 개인정보를 지체 없이 파괴합니다.

제8조 ("회원"의 "이메일(ID)" 및 "비밀번호" 관리 의무)
"회원"의 "이메일(ID)"와 "비밀번호"에 관한 관리책임은 "회원"에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
회사는 "회원"의 "이메일(ID)"가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인한 우려가 있는 경우, 해당 "이메일(ID)"의 이용을 제한할 수 있습니다.
"회원"은 "이메일(ID)" 및 "비밀번호"가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.
제3항의 경우에 해당 "회원"이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.

제9조(정보 제공 및 "회원"에 대한 통지)
회사는 "회원"이 "서비스" 이용 중 필요하다고 인정되는 다양한 정보를 전자우편, 유선, 서신우편 등의 방법으로 "회원"에게 제공할 수 있습니다.

제10조(회사의 의무)
회사는 이 약관에서 정한 바에 따라 계속적, 안정적으로 "서비스"를 제공할 의무가 있습니다.
회사는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않습니다.
"서비스" 이용과 관련하여 "회원"으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. "회원"이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 "회원"에게 처리과정 및 결과를 전달합니다.
회사는 "회원"의 개인정보(신용 정보 보호) 보호를 위해 보안 시스템을 갖추며, 본인 승낙 없이 제3자에게 누설, 배포하지 않습니다. 단, 전기통신 기본법 등 법률의 규정에 의해 국가 기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적이 있거나 정보 통신 윤리위원회의 요청이 있는 경우 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우에는 그리하지 않습니다.

제11조("회원"의 의무)
"회원"은 "서비스"를 이용할 때 다음 각 호의 행위를 하지 않아야 합니다.
1) 신청 또는 변경 시 허위내용의 등록
2) 타인의 정보도용
3) 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해
4) 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
5) "서비스" 이용시 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 언행
6) 회사의 동의 없이 영리를 목적으로 "서비스"를 사용하는 행위
7) 기타 불법적이거나 부당한 행위
"회원"은 내용별로 회사가 공지사항에 게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다
"회원"은 회사의 "서비스"를 이용하여 얻은 정보를 가공, 판매하는 행위 등 "서비스"에 기재된 자료를 상업적으로 사용하거나 "서비스"를 이용해 영업활동을 할 수 없습니다. 단, 비상업적 목적일 경우에는 회사의 사전 동의를 얻어 사용할 수 있습니다.

제12조("서비스" 제공 및 중단)
"서비스"의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다.
회사는 "서비스"를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.
컴퓨터 등 시스템 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 "관리자"가 임의로 날짜와 시간을 정해 "서비스"의 제공을 일시 중단할 수 있습니다. 이 경우 "회원"에게 사전 공지를 원칙으로 하며, 부득이한 사유가 있을 경우 사후에 통지할 수 있습니다.
회사는 제공하고 있는 "서비스"의 일부 또는 전부를 변경할 수 있습니다. "서비스" 이용 방법과 내용, 이용 시간 등에 변경이 있는 경우에는 변경 사유와 변경 내용, 제공일자 등을 변경 전 7일 이상 "서비스" 초기 화면에 게시하여야 합니다.

제13조(수업 진행, 일정 변경 및 취소)
수업 일정 변경 및 취소는 튜터가 배정되어 있는 수업의 경우, 수업 시작 24시간 이전까지는 (예: 수업 시작 28시간 전) 언제든 가능합니다. 튜터가 배정되어 있는 수업에 대해, 수업 시작 시간 24시간 이내 수업을 취소하는 경우 (예: 수업 시작 16시간 전) 수업권이 차감됩니다. 단, 튜터가 미배정되어 있는 수업의 경우, 언제든 수업 취소/변경이 가능합니다.

또한, 수업권 유효기간이 만료되는 경우에도 수업권이 자동 소멸됩니다. 삭제 또는 소멸된 수업권에 대해서는 환불을 제공하지 않습니다.
고객의 잘못으로 인한 수업권 삭제 관련하여, 이의가 존재하는 경우  "회원"은 회사 측에 통보해야 하며,  해당 "회원"이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.
"회원"의 수업 지각시 지각분을 보충해드리지 않습니다. 수업은 정시 시작, 종료를 원칙으로 합니다. 단, "튜터" 지각시 수업 시작 시점부터 40분 간 수업을 진행해 드립니다.

제 14조(포인트 규정)
링글이 수강생 및 가입자 분들께 지급한 포인트는 링글 서비스 가격을 할인받는 적립금으로 사용될 수 있습니다. 포인트는, 피추천인이 4회 이상 수업 결제 시 추천인에게 제공하는 추천 포인트, 링글 서비스에 하자 발생 시(예: 수업 24시간 이전 튜터 취소 시) 수강생에게 제공하는 보상 포인트, 돈을 주고 구매한 수업을 완강했을 시 제공되는 수업 포인트, 링글이 이벤트 목적으로 가입자 대상으로 제공하는 이벤트 포인트로 구분됩니다.
추천 포인트는, 피추천인이 링글이 정의하는 일정금액 이상 결제 시 추천인에게 제공하는 리워드 성격의 포인트로, 링글 수업 할인 목적 외, 추천 리워드 상품으로 교환 가능합니다.
수업 포인트는, 링글이 정의하는 미션을 달성한 수강생에게 제공하는 포인트로, 수업 포인트 적립 요건은 포인트 페이지에 명시되어 있습니다.
보상 포인트는, 링글 서비스의 하자 발생 시, 수강생에게 보상의 목적으로 제공하는 포인트로, 적립 요건은 포인트 페이지에 명시되어 있습니다.
이벤트 포인트는, 링글이 마케팅 및 프로모션 목적으로, 링글팀이 정한 가입자에게 제공되는 포인트 입니다.
추천 포인트 외 다른 포인트는 링글 수업 할인 목적으로만 이용 가능하며, 추천 리워드 상품으로의 교환은 불가능합니다.
모든 포인트의 유효기간은 생성일로부터 1년이며, 1년 뒤 포인트는 소멸됩니다.

제 15조(환불규정)

회사는 서울시간대 기준 2019년 1월 31일까지의 구매건에 대해서는, "회원"의 환불 요청이 있을시, 다음 조항에 의거해 환불을 처리합니다.
1) 수업료를 지불하고 미수강한 수업에 대해 전액 환급함
2) 이미 진행된 수업에 대해서는 환급하지 않음
3) 무료로 획득한 수업에 대해서는 환급하지 않음
4) 특별 패키지로 구성되어 할인가로 결제한 "회원"의 경우, 이미 수강한 수업은 할인가가 아닌 정상가로 계산하여 제한 후 잔액을 환급함
5) 유효기간이 연장된 수업권은 환불이 불가함
6) 수강증, 출석증이 발급된 구매건은 환불이 불가함

회사는 서울시간대 기준 2019년 2월 1일 구매건부터 아래 원칙에 의거하여 회원에게 환불을 제공합니다.

1) 무료로 제공된 수업 또는 진행 완료된 수업 또는 수업권이 차감된 수업 (예: 수업 시작 24시간 이내에 취소된 수업, 수강생이 노쇼한 수업 등)에 대해서는 환불이 불가능합니다.
2) 회원이 구매한 유료수업에 대한 환불은 아래의 기준에 해당하지 않은 경우 진행 가능합니다. 유료수업 중 환불 불가능한 수업권은 아래와 같습니다.
2-1) 회사는 유효기간이 절반 이하 남은 수업에 대해서는 환불을 제공하지 않습니다. (예: 유효기간 30일 수업의 경우, 유효기간이 15일 되는 시점부터 환불이 불가능합니다). 단, 유효기간 연장은 ‘링글 유효기간 연장 규정’에 의거하여 제공합니다.
2-2) 회사는 회원이 구입한 패키지의 수업이 절반 이하 되는 시점부터 환불이 불가능합니다 (예: 회원이 6회 패키지를 구입한 경우, 수업이 3회 남은 시점부터 환불이 불가능합니다). 단, 유효기간 연장은 ‘유효기간 연장 규정’에 의거하여 제공합니다.
2-3) 회사는 유효기간이 연장된 수업권(수업)은 환불이 불가능합니다.
2-4) 회사는 수강증/출석확인증이 발급된 수업에 대해서는 환불이 불가능합니다

3) 회사는 환불 가능한 수업권에 대해 회사의 환불 계산 공식에 의거하여 환불 가능 금액을 산정 후 환불을 제공합니다.
3-1) 회원이 아직 수업을 개시하지 않은 패키지에 대해서는, 위의 15조 2월 1일부터의 환불규정 2-4)에 해당하지 않는 한 전액 환불을 제공합니다 (예: 6회 패키지 구매 후, 한 개의 수업도 수강하지 않아서 유효기간이 계속 멈춰있는 패키지에 대한 요청 시, 수강증/출석확인증 발급이 안된 경우에는 전액 환불 제공)
3-2) 회원이 수강을 시작한 패키지에 대한 환불을 요청하는 경우 (예: 6회 패키지에 대해 2회 수업을 수강한 후 나머지 4회에 대해 환불 요청), 15조 환불규정의 ‘환불 불가능 수업’에 해당하지 않는 수업에 한해, 실결제 금액에서 이미 진행한 수업에 대한 ‘정가 수업료’를 차감한 금액 만큼 환불해 드립니다 (환불 가능 금액 = 결제 금액 - 정가 수업료 * 기 진행한 수업 수). 이미 진행한 수업에 대한 ‘정가 수업료’는 수업권 관리 페이지에서 확인 가능합니다.
3-3) 환불 금액 지급 방식은, 회원이 신용카드로 결제했을 시 결제 취소를 통해, 계좌 이체로 결제했을 시 송금의 방식으로 제공드립니다.

제16조(수업권 유효기간 차감, 연장 및 소멸한 수업권에 대한 복구 규정)

회사는 아래조항에 의거하여 유효기간 차감 및 연장을 진행합니다.
1) 패키지의 유효기간은 첫 수업을 완료한 시점부터 패키지 내 모든 수업권의 유효기간이 자동으로 차감되기 시작합니다. (예: 20회 패키지 이용 시, 첫 수업 완료 시점부터 나머지 19개 수업권의 유효기간이 자동 차감되기 시작함). 또는, 패키지 구매 후 5년이 되는 시점부터, 유효기간이 자동으로 차감되기 시작합니다.
2) 패키지 유효기간 연장 신청은 유효기간이 처음으로 절반 이하로 떨어지는 시점부터 가능합니다 (예: 6회 30일 패키지의 경우, 유효기간이 15일 이하가 되는 시점부터, 유효기간 연장이 가능함). 패키지의 유효기간 연장을 위한 금액은 ‘유효기간 연장 금액 규정'에서 확인 가능하며, 수업권 관리 페이지에 게재되어 있습니다.
3) 유효기간이 소멸된 수업권은, 소멸이 된 후 3개월 이내만 부활이 가능합니다. 수업권 부활을 위한 금액은 ‘수업권 부활금액 규정'에서 확인 가능하며, 수업권 관리 페이지에 게재되어 있습니다.

제17조(저작권의 귀속 및 이용제한)
회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.
이용자는 회사를 이용함으로써 얻은 정보 중 회사에게 지적재산권이 귀속된 정보를 회사의 사전 승낙없이 복제, 송신, 출판, 배포, 방송하거나 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
회사는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.

서울 시간대 기준 2019년 2월 1일부터 진행되는 수업 및 서비스 결과로 발생한 자료 및 데이터 저작권은 회사 및 회원 양측에 귀속됩니다. 회사는 수업 및 서비스 도중 발생한 결과물에 대해 non-disclosure agreement (NDA)를 이행하여, 데이터/자료가 고객 정보와 함께 그대로 외부에 노출되지 않도록 최선을 의무를 이행합니다. 단, 데이터/자료는 개인식별이 불가능한 형태로 저장되며, 회사는 해당 데이터/자료를 연구 및 제품개발 목적으로 이용할 수 있는 권한을 갖습니다. 회원의 동의가 있을 시, 또는 무료로 제공되는 기능에 대해서는 마케팅 및 홍보 목적으로 결과물을 이용 가능합니다.

제18조(계약 해지 및 "서비스" 이용 제한)
"회원"이 이용 계약을 해지하고자 하는 때에는 "회원" 본인이 온라인을 통해 회사에 해지 신청을 하여야 합니다.
회사는 "회원"이 다음 각 호에 해당하는 행위를 하였을 경우 사전통지 없이 이용 계약을 해지 하거나 또는 기간을 정하여 "서비스" 이용을 중지할 수 있습니다.
1) 타인의 "이메일(ID)" 및 "비밀번호"를 도용한 경우
2) "서비스" 운영을 고의로 방해한 경우
3) 가입한 이름이 실명이 아닌 경우
4) 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우
5) "회원"이 국익 또는 공익 저해 목적으로 "서비스" 이용을 계획 또는 실행하는 경우
6) 회사나 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우
회사는 제2항의 규정에 의하여 이용 제한을 하고자 하는 경우에는 그 사유, 일시 및 기타사항을 정하여 이메일 또는 기타 방법에 의하여 해당 이용자 또는 대리인에게 통지 합니다. 다만, 회사가 긴급하게 이용을 정지할 필요가 있다고 인정하는 경우에는 그러하지 아니 합니다.
제2항의 규정에 의하여 이용 정지의 통지를 받은 이용자 또는 그 대리인은 그 이용 정지의 통지에 대하여 이의가 있을 때에는 이의 신청을 할 수 있습니다.
회사는 제3항의 규정에 의한 이의 신청에 대하여 그 확인을 위한 기간까지 이용 정지를 일시 연기 할 수 있으며, 그 결과를 이용자 또는 그 대리인에게 통지합니다.
회사는 이용정지 기간 중에 그 이용 정지 사유가 해소된 것이 확인된 경우에는 이용 정지 조치를 즉시 해제 합니다.

제19조(손해배상)
회사는 일체의 무료로 제공되는 "서비스"의 이용과 관련하여 "회원"에게 발생한 어떠한 손해에 관하여도 책임을 지지 않습니다.

제20조(면책조항)
회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이 면제됩니다.
회사는 "회원"의 귀책사유로 인한 "서비스" 이용의 장애에 대하여 책임을 지지 않습니다.
회사는 "회원"이 "서비스"에 기재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.

제21조(관할 법원)
"서비스"의 이용으로 발생한 분쟁에 대해 소송이 제기 될 경우 회사의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.`,
    <>
      Last Updated June 07, 2021
      <br />
      <br />
      Welcome to Ringle! Ringle English Education Service Co., Ltd., including our Ringle affiliate companies ("we",
      "us", "our", "the Company” or "RINGLE”), have provided this website and our services to provide users like you
      ("you” or "your”) a platform to find and engage independent tutors, and gain access to related resources, guides,
      and instructive material for learning the English language.
      <br />
      <br />
      This website (the "Site”) and any information, content, applications, products, and services we provide to you are
      referenced here together as the "Services”.
      <br />
      <br />
      You should read the following terms of service (these "Terms”) carefully. These Terms describe how we provide the
      Services. Because these Terms must cover a wide variety of important topics, including how lessons may be
      scheduled and conducted, and your privacy rights about your personal information, your use of the Services are
      subject to additional terms and conditions in our user policies (each, a "Policy”).
      <br />
      All Policies are a part of our Terms. Please see a list of our current Policies at the end of these Terms. If
      there is any conflict between the terms and conditions of any Policy and those written here, the terms and
      conditions of the Policy will govern your use of our Services, but only to the extent of any conflict.
      <br />
      <br />
      IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE, ACCESS, OR REGISTER FOR THE SITE OR USE ANY OF OUR
      SERVICES.
      <br />
      <br />
      If you are a registered user and want to terminate your account, or wish to access, review, or delete your
      personal information, please follow the instructions in these Terms, or you may send your request to RINGLE
      customer service by email at contact@ringleplus.com.
      <br />
      <br />
      Article 1 Purpose
      <br />
      <br />
      The Terms and Conditions will govern your access to, and use of, the Services. The purpose of this document is to
      define the rights and duties of the company and its members, responsibilities, procedures and other requirements.
      By using any of our Services, you agree to these Terms as a binding legal agreement between you and us.
      <br />
      <br />
      Article 2 Definition of Terms
      <br />
      <br />
      Terms used here are defined as follows.
      <br />
      "Member": A person who has entered into a contract with the Company for the use of Service and uses the Services
      provided by the Company
      <br />
      "Email(ID)": Internet mail account. The Company shall use the "E-mail" of Member as a unique "ID” for
      identification of Member and use of the Service of Member
      <br />
      "Password": a combination of letters and numbers set by the Member in order to ensure that the Member is in
      conformity with the Email(ID)
      <br />
      "Administrator": The person selected by the Company for the overall management and smooth operation of the
      Services
      <br />
      "Credit": The Ringle 1:1 English Lesson is called Credit or Coupon. One Credit or Coupon means one lesson.
      <br />
      "Package": The unit in which Ringle sells two or more credits. For example, the 6 credit package means a bundle of
      6 lessons.
      <br />
      <br />
      Article 3 Effect and Change of Terms
      <br />
      <br />
      We may change these Terms from time to time. RINGLE reserves the right, in our sole discretion, to modify,
      discontinue, or terminate any part of the Services, or to modify these Terms, at any time without prior
      notification to you. <br />
      If we do modify the Terms, we will post a notification of any changes or modifications to these Terms on our Site
      or your email address you use to register a RINGLE account. We will also update the "Last Updated Date” at the top
      of these Terms.
      <br />
      If the Member does not expressly indicate his intention to refuse the change until the effective date of the
      change, the Member shall be deemed to have agreed to the revised terms. However, Members may discontinue the use
      of the Services and terminate the agreement if he / she does not agree with the changed Terms.
      <br />
      <br />
      Members shall observe the obligation to observe the change of the Terms and the Company shall not be held
      responsible for the damage caused by the change of the Terms.
      <br />
      <br />
      Article 4 Rule of Terms
      <br />
      <br />
      The matters not specified in these Terms and Conditions and the interpretation of these Terms and Conditions shall
      be governed by the laws of the Telecommunications Basic Act, Telecommunications Business Act, Online Digital
      Content Industry Development Act, Consumer Protection Acts in Electronic Commerce, The Act on Consumer Protection,
      the Regulation on the Regulation of the Terms, the Guidelines for the Protection of Digital Content Users
      established by the Minister of Culture, Sports and Tourism, and other related laws and practices.
      <br />
      <br />
      Article 5 Establishment of Use Contract
      <br />
      <br />
      The use contract is established by the consent of the company's use of the user's application for use and the
      user's terms and conditions. Anyone who subscribes to the Member and wishes to use the Services must provide the
      personal information requested by the Company. The Company may not accept applications for the use of the
      following items.
      <br />
      1) If the name is not a real name or someone else's name is used
      <br />
      2) In case of applying for false information,
      <br />
      3) When applying for the purpose of hindering social order or good morals
      <br />
      4) In case the application requirements set by Ringle are not satisfied
      <br />
      <br />
      Article 6 Privacy and Personal Information
      <br />
      RINGLE treats the privacy of your personal information seriously. Please see our{' '}
      <a
        className="text-primary"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>{' '}
      for information about how we collect and use your personal information.
      <br />
      If you are a California resident, our use of your information is subject to your rights under California law. We
      provide a specific notice for California residents{' '}
      <a
        className="text-primary"
        target="_blank"
        href="https://docs.google.com/document/d/10HMRgK2uWiCSBPSuESJ-8SQpX-CFPb13xCAXFT6cfkk/edit#"
      >
        here
      </a>
      , so please refer to that notice for information on how we collect and use your personal information.
      <br />
      <br />
      Article 7 Social Networking
      <br />
      <br />
      If you are a user of third-party social networking or media services or platforms provided by Facebook® or Google®
      (each, an "SNS”), you may register for your Account using your SNS credentials. Depending on the platform, you may
      also link your RINGLE Account with your SNS accounts.
      <br />
      If you choose to use your SNS account to register, we may collect personal information such as your name, profile
      picture, email address which you provided to the SNS, and any other information you make publicly available about
      yourself through the SNS. We may use any of your SNS account information as part of your Account information, as
      well as to log you into parts of our Site.
      <br />
      Check our Privacy Policy for additional information about how we may use your SNS information. By connecting your
      Account with an SNS, you consent to how we access and use your personal SNS information.
      <br />
      Be advised that any information we collect from an SNS will depend on the privacy settings you set with the SNS.
      Your ability to connect your Account with an SNS will depend on their terms and conditions of service. Your
      relationship with any SNS will be governed by their terms and conditions of service.
      <br />
      <br />
      Article 8 Change of Agreement
      <br />
      <br />
      Members may view and modify their information at any time. However, the E-mail(ID) required for Service management
      can not be modified.
      <br />
      <br />
      Members shall notify the Company of any changes made by the Member at the time of subscription, by online
      modification or by e-mail or other means.
      <br />
      <br />
      The Company shall not be held responsible for the disadvantage caused by not informing the Company of the changes
      in Paragraph 2.
      <br />
      <br />
      Article 9 Obligation to protect personal information
      <br />
      <br />
      The Company collects a minimum amount of information for the use of the Services when collecting information from
      the User.
      <br />
      <br />
      When a company collects personal information that can be used to identify a user, it must obtain the user's
      consent. The personal information provided may not be used for any purpose other than the consent of the user or
      provided to a third party, and the company assumes all responsibility for it.
      <br />
      <br />
      If you need to obtain consent from the user, you must provide the identity of the person in charge of personal
      information management (affiliation, name, phone number and other contact details), purpose of collection and use
      of information, (Hereinafter referred to as the "Information and Communication Network Act") related to
      information provision (such as fiduciary, entrusted business contents, and information provision items). Members
      may withdraw this consent at any time.
      <br />
      <br />
      The Company limits the number of Administrators to a minimum number for the protection of personal information and
      takes all responsibility for any damages to the user due to loss, theft, leakage, alteration of user's personal
      information.
      <br />
      <br />
      A third party who receives personal information from the Company or any other person destroys the personal
      information without delay when it achieves the purpose of collecting or receiving the personal information.
      <br />
      <br />
      Article 10 "Membership" "ID" and "Password" Management Obligations
      <br />
      <br />
      The responsibility for the management of the Email(ID) and Password of Member belongs to Member and should not be
      used by a third party.
      <br />
      <br />
      The Company reserves the right to restrict the use of the Email(ID) if the Email(ID) of the Member is concerned
      about the leakage of personal information, antisocial or mysterious misconduct.
      <br />
      <br />
      Members shall promptly notify the Company and follow Company instructions if the ID and Password are stolen or
      recognized by a third party.
      <br />
      <br />
      The Company shall not be held responsible for any disadvantages caused by failure to follow the Company's guidance
      even if the Member does not notify the Company in the case of Paragraph 3 or notifies the Company.
      <br />
      <br />
      Article 11 Provision of Information and Notice to Members
      <br />
      <br />
      The Company may provide the Member with a variety of information necessary by the Member during use of the
      Services by means of E-mail, wired or correspondence.
      <br />
      <br />
      Article 12 Obligations of the Company
      <br />
      <br />
      The Company has an obligation to provide services continuously and reliably as stipulated in these Terms and
      Conditions.
      <br />
      <br />
      The Company shall not engage in any activities that are prohibited by these laws and these Terms of Use or against
      the Company's moral and ethical conduct.
      <br />
      <br />
      If you find that a comment or complaint from a Member in connection with your use of the Service is justified, you
      must do so. For comments or complaints submitted by Member, we use the bulletin board or send emails to process
      Member and the results.
      <br />
      <br />
      The Company shall have a security system to protect the personal information (credit information protection) of
      Members and shall not disclose or distribute to third parties without their consent. However, in cases where there
      is a request from the national agency pursuant to the provisions of the Act, such as the Basic Act on
      Telecommunications, a request for criminal investigation, a request made by the Information Communication Ethics
      Committee, or a request made pursuant to other relevant laws and regulations, It does not.
      <br />
      <br />
      Article 13 Obligations of Members
      <br />
      <br />
      Members shall not engage in any of the following acts when using the Service.
      <br />
      1) Registration of false contents when applying or changing
      <br />
      2) Stealing of other’s information
      <br />
      3) Infringement of intellectual property rights such as copyrights of the company and other third parties
      <br />
      4) Acts that impair the honor of the Company and other third parties or disrupt business
      <br />
      5) Obscene or violent messages, images, voices when using Services
      <br />
      6) Using Services for commercial purposes without the Company's consent
      <br />
      7) Other illegal or unlawful acts
      <br />
      8) Creating more than one personal account
      <br />
      <br />
      "Membership" shall be published by the company in the notice for each content, or abide by the restrictions of use
      that are separately announced
      <br />
      <br />
      Members may not commercially use the materials described in Services, such as processing and selling information
      obtained through the Company's Services, or conduct business activities using the Services. However, in the case
      of non-commercial purposes, the Member may obtain prior consent from the Company.
      <br />
      <br />
      Article 14 Service provision and suspension
      <br />
      <br />
      The use of the Services shall be conducted 24 hours a day, 7 days a week, unless there is any special business or
      technical obstacle.
      <br />
      <br />
      The company can divide the Services into a certain range and specify the available time for each range separately.
      However, in such cases, the contents shall be announced in advance.
      <br />
      <br />
      In the event that there is a system maintenance inspection, replacement or breakdown of communication such as
      computer, communication disconnection, or there is a reason for operational reasons, Administrator can arbitrarily
      set the date and time and suspend the provision of Services. In this case, the Member will be informed in advance,
      and if there is any unavoidable reason, you can notify the post.
      <br />
      <br />
      The Company may change any or all of the Services provided. If there is any change in the method, content, and
      time of use of the Services, the reason for the change, the details of change, and the date of service should be
      posted on the service announcement screen for more than 7 days prior to the change.
      <br />
      <br />
      Changes and cancellations can be made at any time up to 24 hours before lessons start (e.g. 28 hours before
      classes begin). If a tutor is assigned to a lesson and cancels the lesson within 24 hours of the start of the
      lesson (e.g. 16 hours before lesson starts), the credit will be deducted. However, if the tutor is unassigned, you
      can cancel / change the lesson at any time.
      <br />
      <br />
      Also, credits will automatically expire if their credits expire. We do not offer refunds for credits that have
      been deleted or destroyed.
      <br />
      <br />
      If there is an objection related to the deletion of the credit due to the customer's fault, the Member must notify
      the company, and if the Member does not notify the company or notifies the company, the company is not responsible
      for any disadvantages.
      <br />
      <br />
      We will not compensate for the lateness of the Member lesson. Lessons begin and end at scheduled times. However,
      in case of tutor’s lateness, lessons will be held for 40 minutes from the start of lessons.
      <br />
      <br />
      Article 15 Regulation of Point
      <br />
      <br />
      The points awarded to the students and subscribers by RINGLE can be used as a reserve to discount the price of
      Ringle service. The points are the points recommended by the prospective student for payment of four or more
      number of lessons, the reward points provided to the students when the defects occur in the Ringle service (for
      example, when the tutor cancels before 24 hours), and the event point that Ringle provides to the subscriber for
      event purpose.
      <br />
      <br />
      The recommendation point is the point of the reward given to the recommender at the time of purchase made by
      recommended person with more than a certain amount defined by the RINGLE, and it is possible to exchange it with
      the reward product other than the Ringle lesson discount purpose.
      <br />
      <br />
      Lesson points are points that are provided to students who achieve the mission defined by RINGLE, and the
      requirements for earning lesson points are indicated on the points page.
      <br />
      <br />
      The reward points are points provided to the students for the purpose of reward when the fault of the RINGLE
      service occurs, and the requirements for the reward are indicated on the point page.
      <br />
      <br />
      The event point is the point where RINGLE offers the members who are designated by RINGLE team for marketing and
      promotion purposes.
      <br />
      <br />
      Points other than the recommended points are only available for the Ringle lesson discount, and can not be
      exchanged for the recommended Rewards.
      <br />
      <br />
      All points are valid for one year from the date of creation, and points will expire after one year.
      <br />
      <br />
      Article 16 Refund Policy
      <br />
      <br />
      The Company will issue a refund for the Member refund request by January 31, 2019 based on the Seoul time zone,
      subject to the following provisions.
      <br />
      1) Make a full refund for the paid intensive lessons
      <br />
      2) No refunds will be given for lessons already in progress.
      <br />
      3) No refund will be given for lessons earned free of charge.
      <br />
      4) In the case of a Member who was offered with a special package at a discounted price, the lesson that has
      already been taken is calculated at the normal price, not at the discounted price.
      <br />
      5) No refund for extended credit
      <br />
      6) No refund will be given for the purchase that came with the registration card or the proof receipt.
      <br />
      <br />
      The Company will provide a refund to members based on the following principles starting from February 1, 2019,
      based on the Seoul time zone.
      <br />
      1) There is no refund for lessons offered free of charge or for lessons that have been completed or for which
      credits have been deducted (for example, lessons canceled within 24 hours of the beginning of the lesson or
      lessons the student has missed).
      <br />
      2) Refunds for paid lessons purchased by a member can be made if the following criteria do not apply.
      Non-refundable credits during paid lessons are listed below.
      <br />
      2-1) The company does not provide a refund for less than half of the lessons. (For example, for a 30-day plan, no
      refund will be granted after the moment that 15 days left). However, the extension of the validity period will be
      provided in accordance with the "Extension of the validity period of the RINGLE".
      <br />
      2-2) The company can not refund from less than half of package purchased by the member. (For example, if a member
      buys 6 credits as one package, no refund will be made from the three remaining lessons). However, the extension of
      the validity period is provided in accordance with the "Extension of the validity period".
      <br />
      2-3) The company does not refund credits with an extended validity period.
      <br />
      2-4) The company does not refund the lessons for which the certificate of attendance / attendance is issued
      <br />
      3) The Company will provide a refund after calculating the refundable amount based on the company's refund
      calculation formula for refundable credits.
      <br />
      3-1) If the member has not yet started lessons, we will give you a full refund (unless you are covered by the
      refund policy 2-4) from February 1 st, February 15 , If you do not take one lesson and have a valid period of
      stay, you will be given a full refund if you do not receive your certificate of attendance / attendance)
      <br />
      3-2) If a member requests a refund for the package that started the lesson (for example, request a refund for the
      remaining 4 lessons after taking two lessons from 6 lessons package), Ringle refunds the follow lessons:
      "refundable lesson" (Refundable amount = settlement amount - fixed price per lesson* the number of lessons that
      have been conducted) You can see the "Fixed Price" for lessons you have already completed on the Credit Management
      page.
      <br />
      3-3) Reimbursement Amount Payment method will be provided by the member by credit card, by cancellation of
      payment, by wire transfer when payment is made by bank transfer.
      <br />
      <br />
      Article 17 Restrictions on Credit Derivative Credit Extension, Extension and Termination
      <br />
      <br />
      The Company will proceed with deduction and extension of the validity period in accordance with the following
      provisions.
      <br />
      1) The validity period of the package starts to be automatically deducted from the expiration date of all credits
      in the package after the first lesson is completed. (For example, when using the 20th from a package, the
      remaining 19 credits will be deducted automatically from the completion of the first lesson.) Or, after five years
      from the date of purchase, the expiration date will automatically be deducted.
      <br />
      2) Application for extension of the period of validity of the package is possible from the time when the validity
      period falls below the first half. (For example, in case of 6 times 30 days package, validity period can be
      extended from 15 days or less.) The amount for extending the validity of the package can be found in the 'Expiry
      Amount Policy' and posted on the Credit Management page.
      <br />
      3) The expired credits can only be revived within 3 months of their expiration. The amount for the credit revival
      can be found in the "Credit Renewal Policy" and posted on the credit management page.
      <br />
      <br />
      Article 18 Restrictions on Use and Restriction of Copyright
      <br />
      <br />
      The copyrights and other intellectual property rights of the work created by the company belong to the company.
      <br />
      <br />
      You shall not reproduce, transmit, publish, distribute, broadcast or otherwise use the information to which the
      intellectual property rights belong to the Company without the prior consent of the Company for commercial
      purposes or for any third party.
      <br />
      <br />
      The Company shall notify the user of the use of the copyright belonging to the user in accordance with the
      agreement.
      <br />
      <br />
      Data and data copyrights arising from the lesson and service results from February 1, 2019 in Seoul time zone
      belong to both company and member. The Company implements its non-disclosure agreement (NDA) on the outcome of the
      lesson and service activities to ensure that the data / data are not exposed outside the customer information.
      However, data / materials are stored in a form that is not personally identifiable, and the company has the right
      to use the data / data for research and product development purposes. The products are available for marketing and
      promotional purposes, with the member's consent, or for features provided free of charge.
      <br />
      <br />
      Article 19 Termination of Contract and Restriction on Use of Services
      <br />
      <br />
      When a member wishes to terminate the contract, the member must apply for termination to the company through the
      service.
      <br />
      <br />
      The Company may terminate the use contract or suspend the use of the Services without any prior notice if the
      Member has performed any of the following acts.
      <br />
      1) The member has stolen someone's Email(ID) and Password
      <br />
      2) If the member intentionally interfere with the operation of the Services
      <br />
      3) If member’s name is not a real name
      <br />
      4) In case of deliberate dissemination of contents that are impeded by public order and morals
      <br />
      5) Member plans or implements the use of the Services for national or public interest purposes
      <br />
      6) In case of damaging the reputation of the company or other person or causing disadvantage
      <br />
      <br />
      If the Company intends to restrict the use pursuant to Paragraph (2), the Company shall notify the User or its
      agent by e-mail or other means by setting the reason, date and other matters. However, this shall not apply if the
      Company admits that it is necessary to suspend the use of the Company urgently.
      <br />
      <br />
      A user who has been notified of suspension of use pursuant to Paragraph (2) or his / her agent may file a
      complaint if he / she has objected to the notice of suspension.
      <br />
      <br />
      The Company may delay the suspension of use of the objection under Paragraph 3 until the period for confirmation,
      and notify the user or its agent of the result.
      <br />
      <br />
      The Company shall immediately terminate the use suspension if it is confirmed that the reason for suspension of
      use is resolved during the suspension period.
      <br />
      <br />
      Article 20 SERVICES AVAILABILITY & WARRANTIES
      <br />
      While we do our best to make sure our Site and Services are safe, accurate error-free, complete, and available to
      you, we cannot guarantee that our Site or Services will always be safe, secure, or error-free.
      <br />
      We will use all commercially reasonable efforts to ensure the Site and Services are available to you 24 hours day,
      7 days a week, except in the event of scheduled maintenance or special circumstances, such as service
      interruptions affecting our Site hosts or our service providers, or other causes, such as natural disaster,
      governmental orders, or similar causes which are beyond our reasonable control.
      <br />
      OUR SITE, SERVICES AND ALL CONTENT AVAILABLE THEREIN ARE PROVIDED "AS IS” AND "AS AVAILABLE”, AND YOU USE THEM AT
      YOUR OWN RISK. WE PROVIDE ALL SERVICES WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WITHOUT
      LIMITING THE FOREGOING, AND TO THE FULLEST EXTENT OF THE LAW, WE EXPRESSLY DISCLAIM ANY WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, INCLUDING ANY WARRANTIES ARISING OUT OF
      COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT ANY PART OF THE SERVICES WILL MEET YOUR REQUIREMENTS
      OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY REGARDING THE QUALITY OF ANY
      PART OF THE SERVICES OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY CONTENT.
      <br />
      WE, INCLUDING OUR AFFILIATES, AND EACH OF OUR RESPECTIVE OFFICERS, EMPLOYEES, AND OTHER PERSONNEL CANNOT BE
      RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD-PARTIES, AND YOU AGREE THAT YOU ARE SOLELY
      RESPONSIBLE FOR, AND ASSUME ALL RISKS OF, YOUR INTERACTIONS WITH OTHER USERS OF OUR SERVICES. WE MAKE NO
      REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING OTHER USERS OR THEIR CONDUCT. YOU AGREE TO TAKE REASONABLE
      PRECAUTIONS IN ALL COmmUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SITE.
      <br />
      BY AGREEING TO OUR TERMS, YOU RELEASE US, AND OUR OWNERS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND
      DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD
      PARTIES.
      <br />
      IF YOU ARE A CALIFORNIA RESIDENT, YOU EXPRESSLY WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: "A GENERAL RELEASE
      DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
      EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
      DEBTOR”.
      <br />
      <br />
      Article 21 LIMITED LIABILITY
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, IN NO EVENT WILL WE (INCLUDING OUR AFFILIATES, SUCCESSORS AND ASSIGNS,
      AND EACH OF OUR AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, AGENTS, AND OWNERS) BE LIABLE TO YOU FOR ANY INDIRECT,
      INCIDENTAL, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOST DATA, LOST
      PROFITS, OR FOR SERVICE INTERRUPTIONS, HOWEVER ARISING, AND WHETHER BASED IN CONTRACT, TORT, OR OTHERWISE, WHICH
      ARISE OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES, AND EVEN IF THE REMEDIES UNDER THESE TERMS FAIL OF THEIR ESSENTIAL PURPOSE.
      <br />
      OUR TOTAL AGGREGATE LIABILITY TO YOU ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS SHALL NOT
      EXCEED (A) ANY UNDISPUTED AMOUNTS PAID TO US FOR WHICH A CLAIM ARISES; OR (B) ONE HUNDRED DOLLARS, WHICHEVER IS
      GREATEST.
      <br />
      EXCEPT AS EXPRESSLY SET OUT IN THERE TERMS, WE WILL NOT BE LIABLE TO YOU FOR ANY COMPENSATION, REIMBURSEMENT, OR
      DAMAGES ARISING IN CONNECTION WITH YOUR INABILITY TO USE THE SERVICES OR ANY OTHER PART OF THE SITE OR ANY CONTENT
      AVAILABLE THEREIN AS A RESULT OF SUSPENSION OR TERMINATION OF YOUR ACCOUNT OR ACCESS TO THE SERVICES, OR FOR ANY
      UNANTICIPATED OR UNSCHEDULED DOWNTIME, UNAVAILABILITY OR INOPERABILITY OF THE SERVICES FROM ANY CAUSE BEYOND OUR
      REASONABLE CONTROL.
      <br />
      LIMITATIONS OF LIABILITY OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES MAY NOT APPLY BY LAW
      WHERE YOU RESIDE, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH CASES, OUR LIABILITY WILL
      BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
      <br />
      <br />
      Article 22 Indemnity
      <br />
      The Company shall not be liable for any damages incurred by the Member in connection with the use of any of the
      services provided free of charge.
      <br />
      <br />
      You agree to defend, indemnify, and hold us (including for the purposes of this section, our affiliates,
      subsidiaries, parents, successors and assigns, and each of our respective officers, employees, agents, or owners)
      harmless from any claims, actions, suits, losses, costs, liabilities and expenses, including reasonable attorneys’
      fees, relating to, or arising out of, or in any way connected to, your access or use of the Services or any
      Content available therein, including but not limited to (a) your breach of the Terms, the Rules of Acceptable Use,
      or any document or policy incorporated by reference into these Terms; (b) your use or misuse of, or access to, the
      Services or Content available therein; or (c) any third-party website or services which is linked to our Services.
      Notwithstanding your obligation to indemnify us, we will have sole control of the defense to any claim brought
      against us, in which case you agree to assist us and cooperate in the defense to the claim.
      <br />
      <br />
      Article 23 Disclaimer
      <br />
      <br />
      The Company shall be exempted from liability for the provision of the Services if it can not provide the Services
      due to natural disasters or force majeure. The Company shall not be liable for any disruption in the use of the
      Service due to the cause of the Member. The Company shall not be held responsible for the contents of the
      information, data, facts, credibility, and accuracy of the Member in Service.
      <br />
      <br />
      Article 24 Competent Court
      <br />
      <br />
      If a lawsuit is filed against a dispute arising from the use of the Services, the court of competent jurisdiction
      over the headquarters of the Company shall be the court of competent jurisdiction.
      <br />
      <br />
      Article 25 Severability
      <br />
      Except as otherwise stated in these Terms, if a court of competent jurisdiction finds that any provision of these
      Terms, or compliance by any of the parties with any of its provisions, is unlawful or unenforceable, the provision
      will be modified to the extent required to make it lawful and enforceable and effect the intent of the parties. If
      such modification is not possible, the unlawful or unenforceable provision will be severed from the Terms and the
      remaining provisions will remain in full force and effect to the maximum extent consistent with applicable law.
      <br />
      <br />
      Article 26 Questions, Comments & Support
      <br />
      If you have any questions or comments about these Terms or Services, please email RINGLE support by email at
      contact@ringleplus.com. You can receive our current mailing address and phone number for any questions by emailing
      support.
      <br />
      <br />
      Article 27 SPECIAL NOTICES
      <br />
      For Users Outside the United States: We strive to create a protected community with consistent standards for
      everyone, but we also respect local laws. The following provisions apply to users and non‐users who interact with
      us and the Services outside the United States:
      <br />
      You consent to having your personal data transferred to and processed in the United States. If you are located in
      a country embargoed by the United States, or are on the U.S. Treasury Department's list of Specially Designated
      Nationals you agree that you will not engage in commercial activities through our Services (such as advertising or
      payments) or operate any application or website in connection with our Services. You may not use our Services if
      you are prohibited from receiving products, services, or software originating from the United States.
      <br />
      For California Users: Under California Civil Code Section 1789.3, California users are entitled to the following
      specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the
      California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112,
      Sacramento, CA 95834, or by telephone at +1(916) 445-1254 or +1(800) 952-5210.
      <br />
      <br />
      OUR POLICIES AND GUIDELINES
      <br />
      Our Terms incorporate the following:
      <br />
      our{' '}
      <a className="text-primary" target="_blank" href="https://www.ringleplus.com/en/student/policy/lesson-policy">
        Lessons Policy
      </a>
      , which governs lessons with tutors, as well as lesson cancellations, changes, credits, and refunds;
      <br />
      our{' '}
      <a
        className="text-primary"
        target="_blank"
        href="https://docs.google.com/document/d/110Nji2_29lZ0duHtXpnk5-t8tbulyn4ZunTguvM2ZnQ/edit#heading=h.4hhwa0b1z3vo"
      >
        Privacy Policy
      </a>
      , which governs how we collect and use your personal information;
      <br />
      our{' '}
      <a className="text-primary" target="_blank" href="https://www.ringleplus.com/en/student/policy/ip-policy">
        Copyright & Intellectual Property Policy
      </a>
      , which governs how we deal with Content that is alleged to infringe any third-party’s rights; and
      <br />
      our{' '}
      <a
        className="text-primary"
        target="_blank"
        href="https://www.ringleplus.com/en/student/policy/community-guidelines"
      >
        Community Guidelines Policy
      </a>
      , which governs how we expect and require our users to interact with others.
      <br />
    </>,
    's_terms.text',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_password_reset = {
  reset_password: [`비밀번호 재설정`, `Reset Password`, 's_password_reset.reset_password', ``, ``, ``, ``],
  enter_email_to_reset_your_password: [
    `이메일을 입력하시면, 비밀번호를 재설정 하실 수 있는 링크를 보내드립니다.`,
    `Enter your email address to receive a link to reset your password.`,
    's_password_reset.enter_email_to_reset_your_password',
    ``,
    ``,
    ``,
    ``,
  ],
  email: [`이메일`, `Email`, 's_password_reset.email', ``, ``, ``, ``],
  enter_your_email: [
    `이메일을 입력하세요`,
    `Please enter your email`,
    's_password_reset.enter_your_email',
    ``,
    ``,
    ``,
    ``,
  ],
  submit: [`제출 하기`, `Submit`, 's_password_reset.submit', ``, ``, ``, ``],
  did_you_remember_your_password: [
    <>비밀번호가 생각나셨나요?</>,
    `Did you remember your password?`,
    's_password_reset.did_you_remember_your_password',
    ``,
    ``,
    ``,
    ``,
  ],
  sign_in: [`로그인`, `Log in`, 's_password_reset.sign_in', ``, ``, ``, ``],
}
export const s_complete = {
  title: [
    isMobile ? (
      <>
        진짜 영어를 배울 수 있는
        <br />
        링글 수업을 시작해보세요
      </>
    ) : (
      <>진짜 영어를 배울 수 있는 링글 수업을 시작해보세요.</>
    ),
    isMobile ? (
      <>
        Learn Real English
        <br />
        with Ringle
      </>
    ) : (
      <>Learn Real English with Ringle</>
    ),
    's_complete.title',
    ``,
    ``,
    ``,
    ``,
  ],
  welcome_to_ringle: [
    <>
      {isMobile ? (
        <>
          반갑습니다!
          <br />
          가입해 주셔서 감사합니다.
        </>
      ) : (
        <>
          반갑습니다!
          <br />
          가입해 주셔서 감사합니다.
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          Congratulations!
          <br />
          You've joined Ringle.
        </>
      ) : (
        <>
          Congratulations!
          <br />
          You've joined Ringle.
        </>
      )}
    </>,
    's_complete.welcome_to_ringle',
    ``,
    ``,
    ``,
    ``,
  ],
  trial_lesson: [
    <>
      {isMobile ? (
        <>체험 수업 무료로 신청하기</>
      ) : (
        <>
          체험 수업
          <br />
          무료로 신청하기
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Sign up for a Free Trial</>
      ) : (
        <>
          Sign up for a
          <br />
          Free Trial
        </>
      )}
    </>,
    's_complete.trial_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  apply_for_20_min_lesson: [
    <>
      {isMobile ? (
        <>
          남다른 튜터와 교재를 먼저{' '}
          <Div spanTag textPrimary>
            무료
          </Div>
          로 경험해보세요!
        </>
      ) : (
        <>
          남다른 튜터와 교재를 먼저{' '}
          <Div spanTag textPrimary>
            무료
          </Div>
          로
          <br />
          경험해보세요!
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Sign up now to browse our tutors & materials!</>
      ) : (
        <>
          Sign up now to browse
          <br />
          our tutors & materials!
        </>
      )}
    </>,
    's_complete.apply_for_20_min_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  applyTrial: [<>바로 신청하기</>, <>Sign up now</>, 's_complete.applyTrial', ``, ``, ``, ``],
  dailyBrief: [
    <>
      Daily Brief
      <br />
    </>,
    <>
      Daily Brief
      <br />
    </>,
    's_complete.dailyBrief',
    ``,
    ``,
    ``,
    ``,
  ],
  dailyBriefSub: [
    <>
      링글 회원이라면 누구나 무료로
      <br />볼 수 있는{' '}
      <Div spanTag textPrimary>
        하루 5분
      </Div>{' '}
      상식과 영어
    </>,
    <>5-minute dose of insight & English</>,
    's_complete.dailyBriefSub',
    ``,
    ``,
    ``,
    ``,
  ],
  goDailyBrief: [<>Daily Brief 보러가기</>, <>Learn More</>, 's_complete.goDailyBrief', ``, ``, ``, ``],
  purchase_now: (amount) => [
    <>
      {isMobile ? (
        <>최대 3회 수업권 추가 + {amount}만원 할인</>
      ) : (
        <>
          최대 3회 수업권 +
          <br />
          최대 {amount}만원 할인
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Bonus Lesson + Up to {amount} OFF</>
      ) : (
        <>
          Bonus Lesson +
          <br />
          Up to {amount} OFF
        </>
      )}
    </>,
    's_complete.purchase_now',
    ``,
    ``,
    ``,
    ``,
  ],
  full_refund_guaranteed: [
    <>
      {isMobile ? (
        <>
          첫 구매 고객에게 추가 혜택을 드려요!
          <br />
          <Div textInfo>첫 수업 불만족 시 100% 환불</Div>
        </>
      ) : (
        <>
          첫 구매 고객에게 추가 혜택을 드려요!
          <br />
          <Div textInfo>첫 수업 불만족 시 100% 환불</Div>
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>
          Extra benefits on first purchase!
          <br />
          <Div textInfo>100% money-back guarantee</Div>
        </>
      ) : (
        <>
          Extra benefits on first purchase!
          <br />
          <Div textInfo>100% money-back guarantee</Div>
        </>
      )}
    </>,
    's_complete.full_refund_guaranteed',
    ``,
    ``,
    ``,
    ``,
  ],
  view_course_material: [
    `콘텐츠 교재 읽어보기`,
    `View lesson materials`,
    's_complete.view_course_material',
    ``,
    ``,
    ``,
    ``,
  ],
  purchaseNow: [`지금 구매하기`, `Purchase Now`, 's_complete.purchaseNow', ``, ``, ``, ``],
  if_ringle_is_first_time: [
    <>링글이 처음이라면?</>,
    <>New to Ringle?</>,
    's_complete.if_ringle_is_first_time',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor: [
    <>
      {isMobile ? (
        <>링글 사용법 바로가기</>
      ) : (
        <>
          튜터 프로필
          <br />
          보러가기
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>How it works: Ringle Lessons</>
      ) : (
        <>
          Browse tutors
          <br />
        </>
      )}
    </>,
    's_complete.tutor',
    ``,
    ``,
    ``,
    ``,
  ],
  tutorSub: [
    <>
      {isMobile ? (
        <>언제 어디서나, 원하는 수업을 자유롭게</>
      ) : (
        <>
          1000+명 이상의 검증된 영미권 명문대
          <br />
          <Div spanTag textPrimary>
            원어민 튜터
          </Div>
          를 만나보세요!
        </>
      )}
    </>,
    <>
      {isMobile ? (
        <>Learn around your schedule</>
      ) : (
        <>
          Meet 1000+ Tutors!
          <br />{' '}
        </>
      )}
    </>,
    's_complete.tutorSub',
    ``,
    ``,
    ``,
    ``,
  ],
  goTutor: [
    <>{isMobile ? <>간단한 사용법 보러가기</> : <>맞춤 튜터 찾아보기</>}</>,
    <>{isMobile ? <>How it works: Ringle Lessons</> : <>Browse tutors</>}</>,
    's_complete.goTutor',
    ``,
    ``,
    ``,
    ``,
  ],
  check_out_material: [<>교재 둘러보기</>, <>Check out Materials</>, 's_complete.check_out_material', ``, ``, ``, ``],
  check_out_material_content: [
    <>
      Top MBA 출신과 명문대 튜터들이 직접 집필하는 <span style={{ color: '#7A5DE8' }}>프로페셔널한 교재</span>를
      읽어보세요!
    </>,
    <>Check out curriculum written by top MBA grads and elite tutors! </>,
    's_complete.check_out_material_content',
    ``,
    ``,
    ``,
    ``,
  ],
  check_out_material_content_btn: [
    <>교재 보러가기</>,
    <>View lesson materials</>,
    's_complete.check_out_material_content_btn',
    ``,
    ``,
    ``,
    ``,
  ],
  free_trial_lesson: [
    <>
      20분 무료체험
      <br />
      신청하기
    </>,
    <>
      Free 20-Min <br />
      Lesson
    </>,
    's_complete.free_trial_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  free_trial_lesson_content: [
    <>
      수업권 구매 전 링글을
      <br />
      먼저 경험할 수 있는 기회!
      <br />
      (~5월 31일까지)
    </>,
    <>Want to try before you buy? Register now! (through May 31)</>,
    's_complete.free_trial_lesson_content',
    ``,
    ``,
    ``,
    ``,
  ],
  free_trial_lesson_content_btn: [
    <>무료체험 신청하기</>,
    <>Register for free trial</>,
    's_complete.free_trial_lesson_content_btn',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_signin = {
  kakao_inapp: [
    <Div>
      <Div textGray700>
        카카오톡을 통해 접속한 링글 웹사이트에서는 구글/페이스북 로그인 방식이 제한됩니다. 링글 앱을 설치하시면, 모든
        로그인 방식을 이용할 수 있습니다.
      </Div>
      <br />
      <a className="btn bg-primary text-white mt-4 btn-sm" href={RINGLE_DYNAMIC_LINK} rel="noreferrer" target="_blank">
        링글 앱 설치하기
      </a>
    </Div>,
    <Div>
      <Div textGray700>
        Google/KakaoTalk login is restricted on the Ringle website accessed via KakaoTalk. If you install the Ringle
        app, you can select any login options.
      </Div>
      <br />
      <a className="btn bg-primary text-white mt-4 btn-sm" href={RINGLE_DYNAMIC_LINK} rel="noreferrer" target="_blank">
        Install App
      </a>
    </Div>,
    's_signin.kakao_inapp',
    ``,
    ``,
    ``,
    ``,
  ],
  facebook_inapp: [
    <Div>
      <Div textGray700>
        페이스북을 통해 접속한 링글 웹사이트에서는 구글/카카오톡 로그인 방식이 제한됩니다. 링글 앱을 설치하시면, 모든
        로그인 방식을 이용할 수 있습니다.
      </Div>
      <br />
      <a className="btn bg-primary text-white mt-4 btn-sm" href={RINGLE_DYNAMIC_LINK} rel="noreferrer" target="_blank">
        링글 앱 설치하기
      </a>
    </Div>,
    <Div>
      <Div textGray700>
        Google/KakaoTalk login is restricted on the Ringle website accessed via Facebook. If you install the Ringle app,
        you can select any login options.
      </Div>
      <br />
      <a className="btn bg-primary text-white mt-4 btn-sm" href={RINGLE_DYNAMIC_LINK} rel="noreferrer" target="_blank">
        Install App
      </a>
    </Div>,
    's_signin.facebook_inapp',
    ``,
    ``,
    ``,
    ``,
  ],
  instagram_inapp: [
    <Div>
      <Div textGray700>
        인스타그램을 통해 접속한 링글 웹사이트에서는 구글/카카오톡 로그인 방식이 제한됩니다. 링글 앱을 설치하시면, 모든
        로그인 방식을 이용할 수 있습니다.
      </Div>
      <br />
      <a className="btn bg-primary text-white mt-0 btn-sm" href={RINGLE_DYNAMIC_LINK} rel="noreferrer" target="_blank">
        링글 앱 설치하기
      </a>
    </Div>,
    <Div>
      <Div>
        Google/KakaoTalk login is restricted on the Ringle website accessed via Instagram. If you install the Ringle
        app, you can select any login options.
      </Div>
      <br />
      <a className="btn bg-primary text-white mt-4 btn-sm" href={RINGLE_DYNAMIC_LINK} rel="noreferrer" target="_blank">
        Install App
      </a>
    </Div>,
    's_signin.instagram_inapp',
    ``,
    ``,
    ``,
    ``,
  ],
  sign_in: [`로그인`, `Log in`, 's_signin.sign_in', ``, ``, ``, ``],
  sign_in_with_facebook: [
    `페이스북 계정으로 로그인`,
    `Log in with Facebook`,
    's_signin.sign_in_with_facebook',
    ``,
    ``,
    ``,
    ``,
  ],
  enter_your_email: [
    `이메일 주소(ID)를 입력하세요`,
    `Enter your email address (ID)`,
    's_signin.enter_your_email',
    ``,
    ``,
    ``,
    ``,
  ],
  enter_your_password: [`비밀번호를 입력하세요`, `Enter your password`, 's_signin.enter_your_password', ``, ``, ``, ``],
  forgot_password: [`비밀번호를 잊으셨나요?`, `Forgot password?`, 's_signin.forgot_password', ``, ``, ``, ``],
  no_ringle_account: [
    `아직 링글 계정이 없으신가요?`,
    `Don't have a Ringle account?`,
    's_signin.no_ringle_account',
    ``,
    ``,
    ``,
    ``,
  ],
  sign_up: [`회원가입`, `Sign Up`, 's_signin.sign_up', ``, ``, ``, ``],
  sign_in_others: [<>소셜 계정으로 로그인</>, `Log in with`, 's_signin.sign_in_others', ``, ``, ``, ``],
  sns_inapp: [
    <>
      외부 채널을 통한 진입 시 간편 로그인이 제한됩니다. <br />
      링글앱으로 로그인 해주세요!
    </>,
    `You will not be able to sign in if you have accessed this page from a different app.
Please log in through the Ringle App!`,
    's_signin.sns_inapp',
    ``,
    ``,
    ``,
    ``,
  ],
  install_app: [`링글 앱 설치하기`, `Download App`, 's_signin.install_app', ``, ``, ``, ``],
  log_in_with_facebook: [
    `페이스북 계정으로 로그인`,
    `Log in with Facebook`,
    's_signin.log_in_with_facebook',
    ``,
    ``,
    ``,
    ``,
  ],
  log_in: [`로그인`, `Log in`, 's_signin.log_in', ``, ``, ``, ``],
  title: [`Log in to Ringle`, `Log in to Ringle`, 's_signin.title', ``, ``, ``, ``],
  inapp_alert: [
    `Gmail로 로그인하려면 PC 또는 링글앱을 사용해 주세요.`,
    `For Gmail social login, please use a PC or the Ringle app.`,
    's_signin.inapp_alert',
    ``,
    ``,
    ``,
    ``,
  ],
  enter_valid_email: [
    `유효한 이메일을 입력해 주세요`,
    `Please enter a valid email address`,
    's_signin.enter_valid_email',
    ``,
    ``,
    ``,
    ``,
  ],
  password_length: [
    `8자 이상의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요.`,
    `Enter at least 8 characters, with uppercase, lowercase, numbers, and special characters.`,
    's_signin.password_length',
    ``,
    ``,
    ``,
    ``,
  ],
  password_space: [
    `띄어쓰기 없이 입력해 주세요.`,
    `Please enter without spaces.`,
    's_signin.password_space',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_signup = {
  sign_up_with_sns: [<>소셜 계정으로 회원가입</>, `Sign up with`, 's_signup.sign_up_with_sns', ``, ``, ``, ``],
  sign_up_with_email: [<>이메일로 가입</>, `Sign up with email`, 's_signup.sign_up_with_email', ``, ``, ``, ``],
  select_all: [<>모두 선택</>, `Select all`, 's_signup.select_all', ``, ``, ``, ``],
  kakao_inapp: [`TO_BE_DELETED`, `TO_BE_DELETED`, 's_signup.kakao_inapp', ``, ``, ``, ``],
  facebook_inapp: [`TO_BE_DELETED`, `TO_BE_DELETED`, 's_signup.facebook_inapp', ``, ``, ``, ``],
  instagram_inapp: [`TO_BE_DELETED`, `TO_BE_DELETED`, 's_signup.instagram_inapp', ``, ``, ``, ``],
  sign_up: [`회원가입`, `Sign Up`, 's_signup.sign_up', ``, ``, ``, ``],
  email: [`이메일`, `Email`, 's_signup.email', ``, ``, ``, ``],
  enter_email: [`이메일을 입력하세요`, `Enter your email`, 's_signup.enter_email', ``, ``, ``, ``],
  password: [`비밀번호`, `Password`, 's_signup.password', ``, ``, ``, ``],
  enter_password: [`비밀번호를 입력해주세요`, `Enter your password`, 's_signup.enter_password', ``, ``, ``, ``],
  password_length: [
    `8자 이상의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요.`,
    `Enter at least 8 characters, with uppercase, lowercase, numbers, and special characters.`,
    's_signup.password_length',
    `Enter at least 8 characters, with uppercase, lowercase, numbers, and special characters.`,
    `Enter at least 8 characters, with uppercase, lowercase, numbers, and special characters.`,
    `Enter at least 8 characters, with uppercase, lowercase, numbers, and special characters.`,
    `Mật khẩu cần ít nhất 6 ký tự.`,
  ],
  confirm_password: [`비밀번호 확인`, `Confirm Password`, 's_signup.confirm_password', ``, ``, ``, ``],
  re_enter_your_password: [
    `비밀번호를 한번 더 입력해주세요`,
    `Re-enter your password`,
    's_signup.re_enter_your_password',
    ``,
    ``,
    ``,
    ``,
  ],
  enter_your_name: [`이름을 입력해주세요`, `Enter your name`, 's_signup.enter_your_name', ``, ``, ``, ``],
  enter_additional_info: [
    `기업맞춤 / 친구 코드 입력 [선택]`,
    `Enter referral code`,
    's_signup.enter_additional_info',
    ``,
    ``,
    ``,
    ``,
  ],
  phone_number: [`전화 번호`, `Phone number`, 's_signup.phone_number', ``, ``, ``, ``],
  sign_in: [`로그인`, `Log In`, 's_signup.sign_in', ``, ``, ``, ``],
  already_have_account: [
    `이미 계정이 있으신가요?`,
    `Already have a Ringle account?`,
    's_signup.already_have_account',
    ``,
    ``,
    ``,
    ``,
  ],
  i_want_to_receive_promotions: [
    `이벤트 및 혜택 소식을 수신하고 싶습니다`,
    `I want to receive marketing communications on events and offers.`,
    's_signup.i_want_to_receive_promotions',
    ``,
    ``,
    ``,
    ``,
  ],
  optional: [`선택`, `Optional`, 's_signup.optional', ``, ``, ``, ``],
  required: [`필수`, `Required`, 's_signup.required', ``, ``, ``, ``],
  i_older_14: [`만 14세 이상입니다`, `I am 14 years old or older.`, 's_signup.i_older_14', ``, ``, ``, ``],
  i_agree_to_privacy: [
    `개인정보 취급 방침에 동의합니다`,
    `I agree to the privacy policy`,
    's_signup.i_agree_to_privacy',
    ``,
    ``,
    ``,
    ``,
  ],
  i_agree_to_terms: [
    `이용약관에 동의합니다`,
    `I agree to the terms of service`,
    's_signup.i_agree_to_terms',
    ``,
    ``,
    ``,
    ``,
  ],
  company_code: [`기업 맞춤 코드(선택)`, `B2B Partner code (optional)`, 's_signup.company_code', ``, ``, ``, ``],
  verify: [`인증`, `Verify`, 's_signup.verify', ``, ``, ``, ``],
  refferal_code: [`친구 코드(선택)`, `Referral Code (optional)`, 's_signup.refferal_code', ``, ``, ``, ``],
  verified: [`인증 완료`, `Verified`, 's_signup.verified', ``, ``, ``, ``],
  sign_up_with_facebook: [
    `페이스북으로 가입하기`,
    `Sign up with Facebook`,
    's_signup.sign_up_with_facebook',
    ``,
    ``,
    ``,
    ``,
  ],
  privacy_consent: [
    `개인정보 취급 방침에 동의해주세요`,
    `To complete the registration, you must agree to Ringle's privacy policy.`,
    's_signup.privacy_consent',
    ``,
    ``,
    ``,
    ``,
  ],
  privacy_terms_consent: [
    `개인정보 취급 방침과 이용 약관에 모두 동의해주세요`,
    `To complete the registration, you must agree to Ringle's privacy policy and terms of service.`,
    's_signup.privacy_terms_consent',
    ``,
    ``,
    ``,
    ``,
  ],
  please_enter_email: [`이메일을 입력해주세요`, `Please enter e-mail.`, 's_signup.please_enter_email', ``, ``, ``, ``],
  password_check: [
    `비밀번호 일치 여부를 확인해주세요`,
    `Please confirm your password`,
    's_signup.password_check',
    ``,
    ``,
    ``,
    ``,
  ],
  please_enter_name: [`이름을 입력해주세요`, `Please enter your name`, 's_signup.please_enter_name', ``, ``, ``, ``],
  please_enter_name_more: [
    `이름을 2자 이상 입력해주세요`,
    `Please enter at least 2 characters in the name`,
    's_signup.please_enter_name_more',
    ``,
    ``,
    ``,
    ``,
  ],
  additional_info: [`필수정보 입력`, `Required Information`, 's_signup.additional_info', ``, ``, ``, ``],
  already_signed_up: [
    `이미 가입된 계정입니다.`,
    `This account already exists.`,
    's_signup.already_signed_up',
    ``,
    ``,
    ``,
    ``,
  ],
  basic_info: [`기본 정보`, `Basic Info`, 's_signup.basic_info', ``, ``, ``, ``],
  veri_number: [`인증번호`, `Verification code`, 's_signup.veri_number', ``, ``, ``, ``],
  verified_success: [`인증됨`, `Verified`, 's_signup.verified_success', ``, ``, ``, ``],
  plz_veri_phone: [
    `휴대폰을 인증해주세요`,
    `Please verify your phone number`,
    's_signup.plz_veri_phone',
    ``,
    ``,
    ``,
    ``,
  ],
  select_all_free_lesson: [
    `전체 동의 시 20분 무료체험 수업 제공`,
    `Select all for a free 20-min lesson`,
    's_signup.select_all_free_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  trial_modal: {
    title: [
      `20분 무료체험 혜택은 전체 마케팅 수신에 동의하신 분들께만 드리고 있어요.`,
      `A free 20-min lesson is available 
if you opt in to marketing communications.`,
      's_signup.trial_modal.title',
      ``,
      ``,
      ``,
      ``,
    ],
    content: [
      `전체 마케팅 수신에 동의하고 혜택을 받아보세요!`,
      `Opt in and win the offer!`,
      's_signup.trial_modal.content',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`전체 동의하고 혜택받기`, `Opt in`, 's_signup.trial_modal.btn', ``, ``, ``, ``],
  },
  trial_modal_after_agree: {
    title: [
      <>
        전체 마케팅 수신동의로 변경되었습니다. <br />
        지금 바로 20분 수업을 체험해보세요.
      </>,
      `Thank you for opting in! We have sent you a free 20-min lesson credit.`,
      's_signup.trial_modal_after_agree.title',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`무료체험 신청하기`, `Register for free trial`, 's_signup.trial_modal_after_agree.btn', ``, ``, ``, ``],
  },
  i_want_to_receive_promotions_list: {
    item1: [
      `이메일 수신 동의`,
      `Receive email list`,
      's_signup.i_want_to_receive_promotions_list.item1',
      ``,
      ``,
      ``,
      ``,
    ],
    item2: [
      `SMS·알림톡 수신 동의`,
      `Receive text/Kakao messaging`,
      's_signup.i_want_to_receive_promotions_list.item2',
      ``,
      ``,
      ``,
      ``,
    ],
    item3: [
      `앱 푸시 수신 동의`,
      `Receive app push notifications`,
      's_signup.i_want_to_receive_promotions_list.item3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  sns_inapp: [
    <>
      외부 채널에서 접속한 경우 간편 회원가입이 제한됩니다. <br />
      링글 앱을 설치하고 링글의 다양한 콘텐츠를 경험하세요!{' '}
    </>,
    `You will not be able to sign up if you have accessed this page from a different app.
Download the Ringle App to see more contents!`,
    's_signup.sns_inapp',
    ``,
    ``,
    ``,
    ``,
  ],
  install_app: [`링글 앱 설치하기`, `Download App`, 's_signup.install_app', ``, ``, ``, ``],
  log_in: [`로그인`, `로그인`, 's_signup.log_in', ``, ``, ``, ``],
  enter_valid_email: [
    `유효한 이메일을 입력해 주세요`,
    `Please enter a valid email address`,
    's_signup.enter_valid_email',
    ``,
    ``,
    ``,
    ``,
  ],
  inapp_warning: (inapp) => [
    `${inapp}을 통해 접속한 링글 웹사이트에서는 구글과 페이스북 로그인 방식이 제한됩니다.`,
    ``,
    's_signup.inapp_warning',
    ``,
    ``,
    ``,
    ``,
  ],
  inapp_recommend_app: [
    `링글 틴즈 앱을 설치하시면, 모든 로그인 방식을 이용할 수 있습니다.`,
    ``,
    's_signup.inapp_recommend_app',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_point = {
  your_points: [`보유 포인트`, `Your points`, 's_point.your_points', ``, ``, ``, ``],
  policy_note: [`링글 포인트 유의사항`, `Terms and conditions for Points`, 's_point.policy_note', ``, ``, ``, ``],
  point_history: [<>전체 포인트</>, <>Total Points</>, 's_point.point_history', ``, ``, ``, ``],
  invite: [`초대하기`, `Invite`, 's_point.invite', ``, ``, ``, ``],
  my_friend_point: [`친구 초대 포인트`, `Referral Points`, 's_point.my_friend_point', ``, ``, ``, ``],
  shipped_at_the_beginning_of_month: [
    `상품 전달은 매월 말일에 문자 메세지로 일괄 전달됩니다.`,
    `The gift card will be shipped at the end of every month`,
    's_point.shipped_at_the_beginning_of_month',
    ``,
    ``,
    ``,
    ``,
  ],
  use_point: [`포인트 사용하기`, `Use my points`, 's_point.use_point', ``, ``, ``, ``],
  total_point_earned: [`누적 포인트 내역`, `Total points earned`, 's_point.total_point_earned', ``, ``, ``, ``],
  total_use: [`총 사용`, `Total use`, 's_point.total_use', ``, ``, ``, ``],
  total_points_used: [`사용 포인트 내역`, `Total points used`, 's_point.total_points_used', ``, ``, ``, ``],
  total_points: [`총 누적`, `Total points`, 's_point.total_points', ``, ``, ``, ``],
  invite_friend_for_secret_offer: [
    `시크릿 친구초대`,
    `Secret Offer Referrals`,
    's_point.invite_friend_for_secret_offer',
    ``,
    ``,
    ``,
    ``,
  ],
  invite_friend: [`일반 친구초대`, `Regular Referrals`, 's_point.invite_friend', ``, ``, ``, ``],
  all_invite_history: [`전체 현황`, `Total Status`, 's_point.all_invite_history', ``, ``, ``, ``],
  exclude_event: [
    `이벤트 기간 제외 상시`,
    `Applicable any time other than event period`,
    's_point.exclude_event',
    ``,
    ``,
    ``,
    ``,
  ],
  invite_history: [<>친구 초대 포인트</>, <>Referral Points</>, 's_point.invite_history', ``, ``, ``, ``],
  received_point: (n) => [<>₩ {n} 받음</>, <>Received {n}</>, 's_point.received_point', ``, ``, ``, ``],
  ringle_coupon: [`1:1 회화 수업권(40분) 1회`, `1 Lesson Credit (40min)`, 's_point.ringle_coupon', ``, ``, ``, ``],
  no_use_point_history: [
    `사용 포인트 내역이 없습니다.`,
    `No point usage history.`,
    's_point.no_use_point_history',
    ``,
    ``,
    ``,
    ``,
  ],
  shinsegae_coupon: [`신세계 상품권`, `Shinsegae Gift Card`, 's_point.shinsegae_coupon', ``, ``, ``, ``],
  starbucks_coupon: [`스타벅스 상품권`, `Starbucks Gift Card`, 's_point.starbucks_coupon', ``, ``, ``, ``],
  has_been_chagned: [
    `선물로 변경이 완료 되었습니다.`,
    `You have successfully exchanged your points for the gift.`,
    's_point.has_been_chagned',
    ``,
    ``,
    ``,
    ``,
  ],
  one_o_one_coversation_credit: [
    `추천 포인트 1:1 회화 수업권`,
    `Referral Point Credit`,
    's_point.one_o_one_coversation_credit',
    ``,
    ``,
    ``,
    ``,
  ],
  you_can_exchange_for_shinsegae_starbucks_coupon: [
    `신세계/스타벅스 쿠폰으로 교환 가능합니다.`,
    `You can exchange for Shinsegae or Starbucks Gift Card`,
    's_point.you_can_exchange_for_shinsegae_starbucks_coupon',
    ``,
    ``,
    ``,
    ``,
  ],
  coupons_are_shiped_to_mobile: [
    `상품권 및 쿠폰은, 매달 말일에 모바일로 일괄 배송해드립니다.`,
    `Gifts exchanged using Referral Points will be send at the end of every month.`,
    's_point.coupons_are_shiped_to_mobile',
    ``,
    ``,
    ``,
    ``,
  ],
  exchange_for_gifts: [`선물로 변경하기`, `Exchange my points for gifts`, 's_point.exchange_for_gifts', ``, ``, ``, ``],
  send_again: [`다시 보내기`, `Send again`, 's_point.send_again', ``, ``, ``, ``],
  given_date: [`적립일`, `Date added`, 's_point.given_date', ``, ``, ``, ``],
  expired_date: [`만료일`, `Expiration date`, 's_point.expired_date', ``, ``, ``, ``],
  valid_until: [`유효기간`, `Validity period`, 's_point.valid_until', ``, ``, ``, ``],
  list_1: [
    `링글 포인트는 종류에 따라 사용처 및 사용조건, 유효기간 등이 다릅니다.`,
    `By type of Ringle Points, where to use, conditions of use, and validation date may vary.`,
    's_point.list_1',
    ``,
    ``,
    ``,
    ``,
  ],
  list_2: [
    `수업 포인트는 유료 수업 완료 7일 후 실결제 금액의 3%가 자동 적립되고, 수업권 구매 및 수강기간 연장 등에 사용할 수 있습니다.`,
    `Lesson points are automatically awarded at 3% of the actual payment amount in 7 days after the completion of the paid lesson. And it can be used to purchase lesson credits and extend the validation period.`,
    's_point.list_2',
    ``,
    ``,
    ``,
    ``,
  ],
  list_3: [
    `친구 추천 포인트는 수업권 구매 및 수강기간 연장 이외에 상품권으로 교환할 수도 있습니다. 상품권은 입력된 휴대폰 번호로 매월 말 일괄 발송됩니다.`,
    `In addition to purchasing lesson credits and extending the course period, Referral points can be exchanged with gift certificates on the PC web. Gift certificates are sent at the end of each month to the mobile phone number registered.`,
    's_point.list_3',
    ``,
    ``,
    ``,
    ``,
  ],
  list_4: [
    `수업권 구매를 위해 특별 지급된 경우, 최소 결제 금액이 있을 수 있습니다.`,
    `In the case of points awarded only for purchasing lesson credits, there may be a minimum payment amount.`,
    's_point.list_4',
    ``,
    ``,
    ``,
    ``,
  ],
  list_5: [
    `친구 추천 혜택으로 적립된 포인트는 상품권 등 선물로 교환할 수 있습니다.`,
    `Only Points earned through referrals can be exchanged for gift cards.`,
    's_point.list_5',
    ``,
    ``,
    ``,
    ``,
  ],
  list_6: [
    `친구 추천 포인트로 변경한 선물은 매 월말 일괄 배송됩니다. (선물은 계정에 입력된 휴대폰 번호로 발송됩니다.)`,
    `Gifts exchanged using Referral Points will be sent at the end of the month.`,
    's_point.list_6',
    ``,
    ``,
    ``,
    ``,
  ],
  expiration_points_within_30_days: [
    <>30일 이내 만료 예정 포인트</>,
    <>The points will expire within 30 days</>,
    's_point.expiration_points_within_30_days',
    ``,
    ``,
    ``,
    ``,
  ],
  policy_faq: [
    <>링글 포인트 적립과 사용법이 궁금하신가요?</>,
    <>How to earn and use Ringle points</>,
    's_point.policy_faq',
    ``,
    ``,
    ``,
    ``,
  ],
  korean_name_number_required: [
    <>
      신세계/ 스타벅스 상품권의 경우, 수령자 본인의{' '}
      <span className="text-danger">한국이름과 한국번호가 아닌 경우 수령에 제한</span>이 있을 수 있음을 안내드립니다.
      개인정보는 마이메뉴{'>'} 내 계정관리{'>'} 기본정보에서 확인/변경할 수 있습니다.
    </>,
    <>
      If the recipient is not registered as a Korean name and phone number, there might be restrictions in terms of
      receiving Shinsegae/Starbucks gift cards. Personal information can be viewed and varied in My Page{'>'} My Account
      {'>'} Basic info.
    </>,
    's_point.korean_name_number_required',
    ``,
    ``,
    ``,
    ``,
  ],
  how_to_use: [
    <>
      [링글 포인트 사용법]
      <br />
      1. 링글 수업권 구매시 포인트만큼 할인
      <br />
      2. 수업권 수강기간을 연장시 사용
      <br />
      <br />
      *포인트의 유효기간은 적립일로부터 365일입니다.
      <br />
      *추천한 친구가 결제 시, 제공되는 추천 포인트만 선물로 교환 가능합니다.
      <br />
      <br />
      [수강기간/포인트 확인법]
      <br />
      PC 웹: 오른쪽 상단 [아이콘] → [수업권/증명서류] OR [포인트]
      <br />
      모바일 앱: [나의 설정] → [나의 계정] → [포인트 관리]
    </>,
    <>
      You can use your Ringle Points towards your next purchase or use them to pay for extending your credits'
      expiration dates.
      <br />
      Points are valid for 365 days from the time of issuance (except for the first-purchase points,
      <br /> which expire 48 hours following the time of issuance).
      <br />
      <br />
      You can check under "Points" to check your points and their expiration dates.
    </>,
    's_point.how_to_use',
    ``,
    ``,
    ``,
    ``,
  ],
  usage: [
    `Q. 포인트는 어떻게 사용하나요? 보유한 포인트의 유효기간은 어디서 확인하나요?`,
    `Q. How can I use Ringle points? How long are they good for?`,
    's_point.usage',
    ``,
    ``,
    ``,
    ``,
  ],
  point_alert: [
    `설 선물로 드린 포인트가 곧 사라져요! (2023.02.03만료)`,
    `Points received as Seollal gift (expires Feb 3, 2023)`,
    's_point.point_alert',
    ``,
    ``,
    ``,
    ``,
  ],
  btn: [`사용하기`, `Use my points`, 's_point.btn', ``, ``, ``, ``],
}
export const s_change_password = {
  new_password: [`새 비밀번호`, `New password`, 's_change_password.new_password', ``, ``, ``, ``],
  confirm_password: [`비밀번호 확인`, `Confirm password`, 's_change_password.confirm_password', ``, ``, ``, ``],
  save: [`저장`, `save`, 's_change_password.save', ``, ``, ``, ``],
  password_placeholder: [
    `6글자 이상 입력해주세요.`,
    `Enter at least 6 characters`,
    's_change_password.password_placeholder',
    ``,
    ``,
    ``,
    ``,
  ],
  confirm_password_placeholder: [
    `새 비밀번호를 다시 입력해주세요.`,
    `Re-enter your new password`,
    's_change_password.confirm_password_placeholder',
    ``,
    ``,
    ``,
    ``,
  ],
  your_option_change_has_been_completed: [
    `변경한 내용이 저장되었습니다.`,
    `Saved`,
    's_change_password.your_option_change_has_been_completed',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_mypage_nav = {
  credit: [`수업권/증명서류`, `Credits`, 's_mypage_nav.credit', ``, ``, ``, ``],
  point: [`포인트`, `Points`, 's_mypage_nav.point', ``, ``, ``, ``],
  coupon: [`내 쿠폰함`, `Coupons`, 's_mypage_nav.coupon', ``, ``, ``, ``],
  basic_profile: [`기본 정보`, `Basic Info`, 's_mypage_nav.basic_profile', ``, ``, ``, ``],
  set_profile: [`프로필`, `Profile`, 's_mypage_nav.set_profile', ``, ``, ``, ``],
  notification: [`알림 수신 설정`, `Notification Settings`, 's_mypage_nav.notification', ``, ``, ``, ``],
  password: [`비밀번호`, `Password`, 's_mypage_nav.password', ``, ``, ``, ``],
  timezone: [`타임존`, `Time zone`, 's_mypage_nav.timezone', ``, ``, ``, ``],
  news_special_offers: [`혜택 정보 알림`, `Ringle Newsletter`, 's_mypage_nav.news_special_offers', ``, ``, ``, ``],
  receive_news_special_offers: [
    `마케팅 정보 알림 수신동의 안내`,
    ``,
    's_mypage_nav.receive_news_special_offers',
    ``,
    ``,
    ``,
    ``,
  ],
  sender_ringle: [
    `전송자: 링글잉글리시에듀케이션 서비스`,
    `Sender: Ringle Education Service`,
    's_mypage_nav.sender_ringle',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_credit = {
  your_credits: [``, `Your credits`, 's_credit.your_credits', ``, ``, ``, ``],
  credit_note: [`수업권 유의사항`, `Terms`, 's_credit.credit_note', ``, ``, ``, ``],
  points_history: [`포인트 내역`, `Points History`, 's_credit.points_history', ``, ``, ``, ``],
  invited_history: [`초대 현황`, `Referral History`, 's_credit.invited_history', ``, ``, ``, ``],
  enter_gift_code: [`선물 코드 입력`, `Enter gift code`, 's_credit.enter_gift_code', ``, ``, ``, ``],
  please_enter_the_gift_code_here: [
    `여기에 선물 코드를 입력하세요`,
    `Please enter gift code here`,
    's_credit.please_enter_the_gift_code_here',
    ``,
    ``,
    ``,
    ``,
  ],
  input: [`입력`, `Enter`, 's_credit.input', ``, ``, ``, ``],
  exchanging_credit: [`수업권 변경하기`, `Exchange Credits`, 's_credit.exchanging_credit', ``, ``, ``, ``],
  nonrefundable: [`환불 불가`, `Nonrefundable`, 's_credit.nonrefundable', ``, ``, ``, ``],
  total_credits: [`총 수업권 수`, `Total Credits`, 's_credit.total_credits', ``, ``, ``, ``],
  unused_credits: [`미사용 수업권`, `Credits remaining`, 's_credit.unused_credits', ``, ``, ``, ``],
  extended_valid_days: [`수강기간 연장`, `Extend expiration dates`, 's_credit.extended_valid_days', ``, ``, ``, ``],
  see_details: [`자세히 보기`, `See details`, 's_credit.see_details', ``, ``, ``, ``],
  lesson_book: [`수업 관리북`, `Lesson book`, 's_credit.lesson_book', ``, ``, ``, ``],
  lesson_count: (count) => [
    <>{count}회</>,
    <>
      {count} {count > 1 ? 'Credits' : 'Credit'}
    </>,
    's_credit.lesson_count',
    ``,
    ``,
    ``,
    ``,
  ],
  valid_n_days: (exp_days) => [
    <>수강 기간 : {exp_days}일</>,
    <>Expires in: {exp_days} Days </>,
    's_credit.valid_n_days',
    ``,
    ``,
    ``,
    ``,
  ],
  alert_deadline: (deadline) => [
    <>(~{deadline}까지)</>,
    <> (on {deadline})</>,
    's_credit.alert_deadline',
    ``,
    ``,
    ``,
    ``,
  ],
  not_started_credit: [
    `(첫 수업 완료 전까지 수강기간이 차감되지 않습니다)`,
    `(from your first completed lesson)`,
    's_credit.not_started_credit',
    ``,
    ``,
    ``,
    ``,
  ],
  using_credit: [`사용 수업권 :`, `Used credit:`, 's_credit.using_credit', ``, ``, ``, ``],
  credit_has_expired: [
    `수업권 기간이 만료되었습니다`,
    `Credit(s) expired`,
    's_credit.credit_has_expired',
    ``,
    ``,
    ``,
    ``,
  ],
  lessons_cancelled_within_n_hours: (h) => [
    <>수업 {h}시간 이내에 취소한 수업입니다</>,
    <>This lesson has been cancelled within {h} hours before it starts</>,
    's_credit.lessons_cancelled_within_n_hours',
    ``,
    ``,
    ``,
    ``,
  ],
  free_credit: [`당일수업권`, `Last-minute Credit`, 's_credit.free_credit', ``, ``, ``, ``],
  no_show_lesson: [
    `노쇼한 수업입니다`,
    `You did not show up for this lesson`,
    's_credit.no_show_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  credit_details: [`상세 수업권 현황`, `Purchase history`, 's_credit.credit_details', ``, ``, ``, ``],
  course_work_includes: [`수강일 포함`, `Include lesson days`, 's_credit.course_work_includes', ``, ``, ``, ``],
  without_taking_one: [`수강일 미포함`, `Do not include lesson days`, 's_credit.without_taking_one', ``, ``, ``, ``],
  display_full_price: [`전체 금액 표기`, `Display full price`, 's_credit.display_full_price', ``, ``, ``, ``],
  display_dividing_price: [
    `분할 금액 표기`,
    `Display installations`,
    's_credit.display_dividing_price',
    ``,
    ``,
    ``,
    ``,
  ],
  hangul_certificate: [`한글 증명서`, `Certificate in Korean`, 's_credit.hangul_certificate', ``, ``, ``, ``],
  english_certificate: [`영문 증명서`, `Certificate in English`, 's_credit.english_certificate', ``, ``, ``, ``],
  lesson_certificate: [`수강증`, `Lesson certificate`, 's_credit.lesson_certificate', ``, ``, ``, ``],
  there_is_no_receipt_please_contact_through_channeltalk: [
    `PayPal 결제 내역은 사용하시는 PayPal 계정을 통해 확인할 수 있습니다`,
    `PayPal payment receipts can be checked through the PayPal account that you use.`,
    's_credit.there_is_no_receipt_please_contact_through_channeltalk',
    ``,
    ``,
    ``,
    ``,
  ],
  attendance_certificate: [`출석증`, `Attendance certificate`, 's_credit.attendance_certificate', ``, ``, ``, ``],
  attendance_required_option: [
    `출석증 출력 시, 수강일이 포함됩니다.`,
    `The duration of the class will be included when the attendance certification is printed.`,
    's_credit.attendance_required_option',
    ``,
    ``,
    ``,
    ``,
  ],
  receipt: [`영수증`, `Receipt`, 's_credit.receipt', ``, ``, ``, ``],
  select_form_of_certificate: [
    `증명서류 종류 선택`,
    `Select a type of certificate`,
    's_credit.select_form_of_certificate',
    ``,
    ``,
    ``,
    ``,
  ],
  info_select_form_of_certificate: [
    `증명서류 종류를 선택해주세요`,
    `Select a type of certificate`,
    's_credit.info_select_form_of_certificate',
    ``,
    ``,
    ``,
    ``,
  ],
  specified_lesson_period: [
    `수강 기간 지정`,
    `Select a lesson period`,
    's_credit.specified_lesson_period',
    ``,
    ``,
    ``,
    ``,
  ],
  select_form: [`양식 선택`, `Select a form`, 's_credit.select_form', ``, ``, ``, ``],
  preview_output: [`출력 내용 미리 보기`, `Print preview`, 's_credit.preview_output', ``, ``, ``, ``],
  issue_certificate: [`증명서 발급`, `Issue certificate`, 's_credit.issue_certificate', ``, ``, ``, ``],
  issue_receipt: [`영수증 발급`, `Issue receipt`, 's_credit.issue_receipt', ``, ``, ``, ``],
  issue_date: [`발급 시점`, `Issue date`, 's_credit.issue_date', ``, ``, ``, ``],
  receipt_of_first_purchase: [
    `첫번째 결제 영수증`,
    `First payment receipt`,
    's_credit.receipt_of_first_purchase',
    ``,
    ``,
    ``,
    ``,
  ],
  receipt_of_second_purchase: [
    `두번째 결제 영수증`,
    `Second payment receipt`,
    's_credit.receipt_of_second_purchase',
    ``,
    ``,
    ``,
    ``,
  ],
  received: [`지급 완료`, `Received`, 's_credit.received', ``, ``, ``, ``],
  lesson_recommend_duration: [
    `링글의 추천: 5일에 1회 수업`,
    `Ringle's recommendation: 1 lesson per 5 days`,
    's_credit.lesson_recommend_duration',
    ``,
    ``,
    ``,
    ``,
  ],
  receipt_guide: [
    <>
      카카오페이 및 페이팔 결제 시, 링글 홈페이지 내 영수증 발급이 불가합니다.
      <br />
      해당 결제 사이트에서 직접 영수증 발급 가능한 점 안내드립니다
    </>,
    <>
      Receipt on PayPal is not provided on the Ringle webpage.
      <br />
      Please visit the official website for the receipt issued.
    </>,
    's_credit.receipt_guide',
    ``,
    ``,
    ``,
    ``,
  ],
  list_1: [
    `정규수업권 1회로 링글 1:1 수업 1회를 수강할 수 있으며, 정규 시간 내 자유롭게 이용가능합니다.`,
    `You can take one lesson per Credit.`,
    's_credit.list_1',
    ``,
    ``,
    ``,
    ``,
  ],
  list_2: [
    `정규수업권은 [당일수업] 예약에도 사용할 수 있습니다.`,
    `You can book a lesson available in 24 hours with a Regular Credit.`,
    's_credit.list_2',
    ``,
    ``,
    ``,
    ``,
  ],
  list_3: [
    `정규수업권으로 수업 시작 기준 24시간 이전에 취소 시, 해당 수업권은 자동 복구됩니다.`,
    `Cancellation made on a lesson booked with Regular Credit 2-24 hours before the lesson will replace the used Regular Credit with a Last-minute Credit.`,
    's_credit.list_3',
    ``,
    ``,
    ``,
    ``,
  ],
  list_4: [
    `정규수업권으로 수업 시작 기준 24~2시간 이전 취소 시, 해당 수업권 차감 후 당일수업권이 제공됩니다.`,
    `Cancellations made 2-24 hours before the lesson will replace the credit used with a Last-minute Credit.`,
    's_credit.list_4',
    ``,
    ``,
    ``,
    ``,
  ],
  list_5: [
    `정규수업권으로 수업 시작 전 2시간 이내 취소 시, 해당 수업권은 차감되며 당일수업권은 제공되지 않습니다.`,
    `Cancellations made less than 2 hours before the lesson will not restore the lesson credit.`,
    's_credit.list_5',
    ``,
    ``,
    ``,
    ``,
  ],
  list_6: [
    `수강기간의 기준은 수업을 예약하는 시점이 아닌 수강 완료하는 시점입니다.`,
    `You must book and complete lessons before your credits expire.`,
    's_credit.list_6',
    ``,
    ``,
    ``,
    ``,
  ],
  list_7: [
    `수업권 관련 문의사항이 있을 경우 [1:1 문의]로 연락해 주십시오.`,
    `If you have any questions regarding, please feel free to contact us via chat below.`,
    's_credit.list_7',
    ``,
    ``,
    ``,
    ``,
  ],
  remaining: [`남음`, `Remaining`, 's_credit.remaining', ``, ``, ``, ``],
  transfer_credit: [`틴즈로 수업권 이관하기`, `Transfer Credits to Teens`, 's_credit.transfer_credit', ``, ``, ``, ``],
  teens_credits: [
    `링글 틴즈로 이관한 수업권 입니다.`,
    `This credit has been transferred to Ringle Teens.`,
    's_credit.teens_credits',
    ``,
    ``,
    ``,
    ``,
  ],
  title_1: [`내 수업권`, `My Credits`, 's_credit.title_1', ``, ``, ``, ``],
  regular: [`정규수업권`, `Regular`, 's_credit.regular', ``, ``, ``, ``],
  makeup: [`당일수업권`, `Last-minute`, 's_credit.makeup', ``, ``, ``, ``],
  limited: [`시간한정수업권`, `Limited Window`, 's_credit.limited', ``, ``, ``, ``],
  urgent: [`긴급편성수업권`, `FastBook`, 's_credit.urgent', ``, ``, ``, ``],
  min_20: [`20분`, `20 minutes`, 's_credit.min_20', ``, ``, ``, ``],
  min_40: [`40분`, `40 minutes`, 's_credit.min_40', ``, ``, ``, ``],
  note_title: [`수업권 유의사항`, `Notice`, 's_credit.note_title', ``, ``, ``, ``],
  note_1: [
    `수강기간은 첫 수업을 완료하는 시점부터 차감됩니다. (수업권 구매 후 1년 내 원하는 시점에 수업 시작 가능)`,
    `Expiration date will begin to diminish immediately after you complete your first lesson. (You can take your first lesson anytime within one year from the day you purchased the credits.)`,
    's_credit.note_1',
    ``,
    ``,
    ``,
    ``,
  ],
  note_2: [
    `단, 수업권 구매 후 1년이 되는 시점까지 수업을 시작하지 않은 경우에는 수강기간이 자동 차감됩니다.`,
    `However, if you do not take your first lesson within one year from purchasing the credits, lesson days will begin to diminish automatically after one year.`,
    's_credit.note_2',
    ``,
    ``,
    ``,
    ``,
  ],
  note_3: [
    `수강기간의 기준은 수업을 예약하는 시점이 아닌 수강 완료하는 시점입니다.`,
    `You must complete lessons within the lesson days, not just book the lessons.`,
    's_credit.note_3',
    ``,
    ``,
    ``,
    ``,
  ],
  free: [`무료`, `Free`, 's_credit.free', ``, ``, ``, ``],
  no_refund: [`환불 불가`, `Non-Refundable`, 's_credit.no_refund', ``, ``, ``, ``],
  status_1: [`수강 기간`, `Expiration date`, 's_credit.status_1', ``, ``, ``, ``],
  status_2: [`남은 횟수`, `Credits`, 's_credit.status_2', ``, ``, ``, ``],
  status_3: [`발급 시점`, `Issue date`, 's_credit.status_3', ``, ``, ``, ``],
  date_not_started: (remainingDays) => [
    `${remainingDays}일 (미시작)`,
    `${remainingDays} days (frozen)`,
    's_credit.date_not_started',
    ``,
    ``,
    ``,
    ``,
  ],
  remain: (remainingCredits) => [
    `${remainingCredits}회`,
    `${remainingCredits} credits`,
    's_credit.remain',
    ``,
    ``,
    ``,
    ``,
  ],
  date: (remainingDays) => [`${remainingDays}일 남음`, `${remainingDays} days left`, 's_credit.date', ``, ``, ``, ``],
  date_remain_days: (count) => [`${count}일`, `${count}day(s)`, 's_credit.date_remain_days', ``, ``, ``, ``],
  date_remain_hours: (count) => [`${count}시간`, `${count}hour(s)`, 's_credit.date_remain_hours', ``, ``, ``, ``],
  date_remain_msg: (remainDate) => [
    `${remainDate} 남음`,
    `${remainDate} left`,
    's_credit.date_remain_msg',
    ``,
    ``,
    ``,
    ``,
  ],
  date_issue: (n) => [
    <DateComponent format="yyyy.MM.dd HH:mm:ss" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="MM.dd.yyyy HH:mm:ss" locale="en">
      {n}
    </DateComponent>,
    's_credit.date_issue',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_1: [`수업 관리북`, `Lesson Book`, 's_credit.btn_1', ``, ``, ``, ``],
  btn_2: [`수강기간 연장`, `Extend dates`, 's_credit.btn_2', ``, ``, ``, ``],
  btn_3: [`증명서/영수증 발급`, `Certificate/Receipt`, 's_credit.btn_3', ``, ``, ``, ``],
  see_detail: [`자세히 보기`, `See details`, 's_credit.see_detail', ``, ``, ``, ``],
  msg_refund: [`환불 완료`, `Refunded`, 's_credit.msg_refund', ``, ``, ``, ``],
  filter: [`상세조회`, `Search`, 's_credit.filter', ``, ``, ``, ``],
  option1_default: [`사용 가능 여부`, `Status`, 's_credit.option1_default', ``, ``, ``, ``],
  option1_1: [`전체보기`, `All`, 's_credit.option1_1', ``, ``, ``, ``],
  option1_2: [`사용 가능`, `Unused`, 's_credit.option1_2', ``, ``, ``, ``],
  option1_3: [`사용 완료/만료/환불`, `Used/Expired/Refunded`, 's_credit.option1_3', ``, ``, ``, ``],
  option2_default: [`수업권 종류`, `Credit type`, 's_credit.option2_default', ``, ``, ``, ``],
  option2_1: [`전체보기`, `All`, 's_credit.option2_1', ``, ``, ``, ``],
  option2_2: [`정규수업권`, `Regular`, 's_credit.option2_2', ``, ``, ``, ``],
  option2_3: [`당일수업권`, `Last-minute`, 's_credit.option2_3', ``, ``, ``, ``],
  option2_4: [`시간한정수업권`, `Limited Window`, 's_credit.option2_4', ``, ``, ``, ``],
  option2_5: [`긴급편성수업권`, `FastBook`, 's_credit.option2_5', ``, ``, ``, ``],
  option3_default: [`수업 길이`, `Lesson Duration`, 's_credit.option3_default', ``, ``, ``, ``],
  option3_1: [`전체 보기`, `All`, 's_credit.option3_1', ``, ``, ``, ``],
  option3_2: [`20분`, `20 minutes`, 's_credit.option3_2', ``, ``, ``, ``],
  option3_3: [`40분`, `40 minutes`, 's_credit.option3_3', ``, ``, ``, ``],
  apply: [`적용`, `Apply`, 's_credit.apply', ``, ``, ``, ``],
  clear: [`초기화`, `Reset`, 's_credit.clear', ``, ``, ``, ``],
  placeholder: [
    `찾으시는 수업권이 없습니다.`,
    `You have no credits that fit this criteria.`,
    's_credit.placeholder',
    ``,
    ``,
    ``,
    ``,
  ],
  buy: [`구매하러 가기`, `Purchase now`, 's_credit.buy', ``, ``, ``, ``],
  title_2: [`수업권 상세 내역`, `Credit Details`, 's_credit.title_2', ``, ``, ``, ``],
  title_3: [`수업권 현황`, `Credit Summary`, 's_credit.title_3', ``, ``, ``, ``],
  title_4: [`수강 내역`, `Lesson History`, 's_credit.title_4', ``, ``, ``, ``],
  available: [`사용 가능`, `Unused`, 's_credit.available', ``, ``, ``, ``],
  number_available: (remainingCredits) => [
    `${remainingCredits}`,
    `${remainingCredits}`,
    's_credit.number_available',
    ``,
    ``,
    ``,
    ``,
  ],
  expired: [`만료/환불`, `Expired/Refunded`, 's_credit.expired', ``, ``, ``, ``],
  number_expired: (expiredCredits) => [
    `${expiredCredits}`,
    `${expiredCredits}`,
    's_credit.number_expired',
    ``,
    ``,
    ``,
    ``,
  ],
  number_history: (completedCount) => [
    `${completedCount}`,
    `${completedCount}`,
    's_credit.number_history',
    ``,
    ``,
    ``,
    ``,
  ],
  table_header_1: [`수업권 종류`, `Credit type`, 's_credit.table_header_1', ``, ``, ``, ``],
  table_header_2: [`수업권 수`, `Credits`, 's_credit.table_header_2', ``, ``, ``, ``],
  table_header_3: [`수강 기간`, `Expiration dates`, 's_credit.table_header_3', ``, ``, ``, ``],
  table_header_4: [`No.`, `No.`, 's_credit.table_header_4', ``, ``, ``, ``],
  table_header_5: [`수업 내용`, `Lesson topic`, 's_credit.table_header_5', ``, ``, ``, ``],
  table_header_6: [`수업 시간`, `Lesson date`, 's_credit.table_header_6', ``, ``, ``, ``],
  msg_date: (date) => [`${date}일`, `${date}day(s)`, 's_credit.msg_date', ``, ``, ``, ``],
  msg_expire: (n) => [
    <DateComponent format="yyyy.MM.dd HH:mm까지" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="MM.dd.yyyy HH:mm" locale="en">
      {n}
    </DateComponent>,
    's_credit.msg_expire',
    ``,
    ``,
    ``,
    ``,
  ],
  msg_expired: [`기간 만료`, `Expired`, 's_credit.msg_expired', ``, ``, ``, ``],
  msg_date_expired: (n) => [
    <DateComponent format="yyyy.MM.dd HH:mm" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="MM.dd.yyyy HH:mm" locale="en">
      {n}
    </DateComponent>,
    's_credit.msg_date_expired',
    ``,
    ``,
    ``,
    ``,
  ],
  list_latest: [`최신순`, `Newest`, 's_credit.list_latest', ``, ``, ``, ``],
  list_late: [`오래된순`, `Oldest`, 's_credit.list_late', ``, ``, ``, ``],
  lesson_date: (n) => [
    <DateComponent format="yyyy.MM.dd HH:mm" locale="ko">
      {n}
    </DateComponent>,
    <DateComponent format="MM.dd.yyyy HH:mm" locale="en">
      {n}
    </DateComponent>,
    's_credit.lesson_date',
    ``,
    ``,
    ``,
    ``,
  ],
  view_more: [`더보기`, `See more`, 's_credit.view_more', ``, ``, ``, ``],
  tooltip_regular: [
    `정규수업권은 정규 시간 내 1:1 수업을 자유롭게 예약 가능한
수업권이며, 당일 수업 예약에도 사용할 수 있습니다.`,
    `Regular credits can be used to book 1:1 lessons for any time within regular hours, including last-minute lessons that begin within 24 hours from booking.`,
    's_credit.tooltip_regular',
    ``,
    ``,
    ``,
    ``,
  ],
  tooltip_makeup: [
    `당일수업권은 예약 시점 기준 24시간 이내에 시작하는 수업만 예약할 수 있는 수업권으로, [1:1 수업] > [예약] > [예약 가능한 당일수업]에서 사용하실 수 있습니다.`,
    `Last-minute credits can only be used to book lessons that begin within 24 hours from booking. You can use Last-minute credits by going to [1:1 Lessons] > [Book Lessons] > [Last-minute Lessons].`,
    's_credit.tooltip_makeup',
    ``,
    ``,
    ``,
    ``,
  ],
  tooltip_limited: [
    `시간한정수업권은 정해진 시간대의 수업만 예약할 수 있는 수업권입니다.
(새벽반: 오전 5시 ~ 오전 8시(KST), 심야반: 자정 00시 ~ 새벽 3시(KST))`,
    `Limited Window credits can only be used to book lessons for certain time periods. (Early bird: 5:00-8:00 am (KST), Night owl: Midnight-03:00 am (KST))`,
    's_credit.tooltip_limited',
    ``,
    ``,
    ``,
    ``,
  ],
  tooltip_urgent: [
    `긴급편성수업권은 예약 시점 기준 2시간 이내 시작하는 수업만
예약할 수 있는 수업권으로, [1:1수업]>[예약]>[예약 가능한
당일수업] 에서 사용하실 수 있습니다.`,
    `FastBook credits can only be used to book lessons that begin within 2 hours from booking. You can use FastBook credits by going to [1:1 Lessons] > [Book Lessons] > [Last-minute Lessons].`,
    's_credit.tooltip_urgent',
    ``,
    ``,
    ``,
    ``,
  ],
  tooltip_not_started: [
    <>
      첫 수업 수강 전까지는 수강기간이 차감되지 않습니다.
      <br />
      (단, 구매 1년 뒤 자동 차감 시작)
    </>,
    `Expiration dates are frozen until you complete your first lesson. (However, expiration dates will begin to diminish automatically after one year from purchase date.)`,
    's_credit.tooltip_not_started',
    ``,
    ``,
    ``,
    ``,
  ],
  tooltip_btn_2: [
    <>
      1. 무료수업권은 연장이 불가합니다.
      <br />
      2. 수강기간 만료시 연장이 불가합니다.
      <br />
      3. 수강기간 연장은 수강기간이 절반 이하 남은 시점부터 가능합니다.
    </>,
    `1. Expiration dates for free credits cannot be extended.
2. Expiration dates cannot be extended after the credit has expired.
3. Expiration dates can be extended when there are less than half of the lesson days left.`,
    's_credit.tooltip_btn_2',
    ``,
    ``,
    ``,
    ``,
  ],
  lessons_cancelled_within_2_hours: (h) => [
    <>수업 {h}시간 이내 취소로 수업권이 차감되었습니다.</>,
    <>You cancelled this lesson within {h} hours before it was scheduled to start.</>,
    's_credit.lessons_cancelled_within_2_hours',
    ``,
    ``,
    ``,
    ``,
  ],
  no_show: [
    `수업 노쇼로 수업권이 차감되었습니다.`,
    `A credit was deducted for the no-show lesson.`,
    's_credit.no_show',
    ``,
    ``,
    ``,
    ``,
  ],
  lessons_cancelled_within_24_hours: (h) => [
    <>수업 {h}시간 이내 취소로 당일수업권이 제공되었습니다.</>,
    <>
      A last-minute credit has been issued for the lesson that was cancelled within {h} hours before it was scheduled to
      start.
    </>,
    's_credit.lessons_cancelled_within_24_hours',
    ``,
    ``,
    ``,
    ``,
  ],
  hours: [`시간`, `Time`, 's_credit.hours', ``, ``, ``, ``],
  last_minute_lessons_cancelled_within_24_hours: (h) => [
    <>수업 {h}시간 이내 취소로 수업권이 차감되었습니다.</>,
    <>You cancelled this lesson within {h} hours before it was scheduled to start.</>,
    's_credit.last_minute_lessons_cancelled_within_24_hours',
    ``,
    ``,
    ``,
    ``,
  ],
  note: [
    `새로워진 [수업권/증명서류] 페이지를 확인해보세요.`,
    `Check out our renewed [Credits] page.`,
    's_credit.note',
    ``,
    ``,
    ``,
    ``,
  ],
  note_btn: [`안내문 보기`, `Learn more`, 's_credit.note_btn', ``, ``, ``, ``],
  changed: [`(변경됨)`, `(Exchanged)`, 's_credit.changed', ``, ``, ``, ``],
  date_expired: [`-`, `-`, 's_credit.date_expired', ``, ``, ``, ``],
  done: [`사용 완료`, `Used`, 's_credit.done', ``, ``, ``, ``],
}
export const s_mypage_noti = {
  n_hours_ago: (n) => [`${n} 시간 전`, `${n} hour before lesson`, 's_mypage_noti.n_hours_ago', ``, ``, ``, ``],
  email: [`이메일`, `Email`, 's_mypage_noti.email', ``, ``, ``, ``],
  appo_push_noti: [`앱 푸시`, `App Push`, 's_mypage_noti.appo_push_noti', ``, ``, ``, ``],
  sms: [`SMS`, `SMS (Text message)`, 's_mypage_noti.sms', ``, ``, ``, ``],
  select_noti_times: [
    `알림 시간 (2개까지 설정 가능)`,
    `Notification Time (Up to 2)`,
    's_mypage_noti.select_noti_times',
    ``,
    ``,
    ``,
    ``,
  ],
  saved: [`저장됨`, `Saved`, 's_mypage_noti.saved', ``, ``, ``, ``],
  ringle_benefit: [`링글 소식 & 혜택`, `Ringle Newsletter`, 's_mypage_noti.ringle_benefit', ``, ``, ``, ``],
  ringle_benefit_desc: [
    `링글 뉴스레터, 할인 혜택, 이벤트, 콘텐츠 업데이트 소식 등을 받으실 수 있습니다.`,
    `You will receive Ringle's exclusive promotions and latest news.`,
    's_mypage_noti.ringle_benefit_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_reminder: [`1:1 수업 리마인더`, `Lesson Reminder`, 's_mypage_noti.lesson_reminder', ``, ``, ``, ``],
  lesson_reminder_desc: [
    `1:1 수업의 학습 효과를 높일 수 있도록 다양한 리마인더를 받을 수 있습니다.`,
    `You will receive various reminders to help you stay on top of your lessons.`,
    's_mypage_noti.lesson_reminder_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  google_calendar: [`구글 캘린더 초대장`, `Google Calendar Invites`, 's_mypage_noti.google_calendar', ``, ``, ``, ``],
  calendar_schedule: [
    `신청한 1:1 수업의 일정`,
    `1:1 Lesson schedule`,
    's_mypage_noti.calendar_schedule',
    ``,
    ``,
    ``,
    ``,
  ],
  calendar_desc: [
    `알림 수신 방법으로 선택한 이메일 계정으로 신청한 수업의 일정이 구글 캘린더 초대장으로 발송됩니다.`,
    `Lessons will automatically be added to your Google Calendar.`,
    's_mypage_noti.calendar_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_schedule: [`1:1 수업 스케줄 업데이트`, `Lesson Availability`, 's_mypage_noti.lesson_schedule', ``, ``, ``, ``],
  lesson_schedule_desc: [
    `찜한 튜터의 수업 스케줄이 업데이트되었을 때, 24시간 이내 시작하는 수업이 이용 가능할 때 알림을 받으실 수 있습니다.`,
    `You will be notified when your favorite tutors update their availabilities or a slot opens up.`,
    's_mypage_noti.lesson_schedule_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  notification_my_tutor: [
    `찜한 튜터의 수업 스케줄 업데이트`,
    `Updates to my Favorite Tutors' availability`,
    's_mypage_noti.notification_my_tutor',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_in_24: [
    `24시간 이내 가능한 수업`,
    `Available Lessons in 24 Hours`,
    's_mypage_noti.lesson_in_24',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_in_24_my_tutor: [
    `찜한 튜터의 수업만 알림 받기`,
    `Only notify me when my favorite tutors have a new availability in 24 hours`,
    's_mypage_noti.lesson_in_24_my_tutor',
    ``,
    ``,
    ``,
    ``,
  ],
  receive_email: [`이메일`, `Email`, 's_mypage_noti.receive_email', ``, ``, ``, ``],
  sms_info: [
    `휴대폰 번호`,
    <>
      Mobile
      <br />
      (Text Message)
    </>,
    's_mypage_noti.sms_info',
    ``,
    ``,
    ``,
    ``,
  ],
  verified: [
    `인증 및 휴대폰 번호 변경이 완료 되었습니다.`,
    `Verification complete.`,
    's_mypage_noti.verified',
    ``,
    ``,
    ``,
    ``,
  ],
  phone: [`휴대폰 번호`, `Phone number`, 's_mypage_noti.phone', ``, ``, ``, ``],
  new_db_update: [`신규 Daily Brief 업데이트`, `New Daily Brief update`, 's_mypage_noti.new_db_update', ``, ``, ``, ``],
  live_webinar_invitation: [
    `예약한 LIVE 웨비나의 일정`,
    `RSVP-ed LIVE Webinars`,
    's_mypage_noti.live_webinar_invitation',
    ``,
    ``,
    ``,
    ``,
  ],
  live_webinar_app_push: [`리마인더 수신 방법`, `Delivery`, 's_mypage_noti.live_webinar_app_push', ``, ``, ``, ``],
  before_hour_and_two_min: [
    `LIVE 당일 1시간 전, 그리고 당일 시작 2분 후 제공됩니다.`,
    `Will be sent 1 hour before and 2 minutes after the LIVE Webinar stars.`,
    's_mypage_noti.before_hour_and_two_min',
    ``,
    ``,
    ``,
    ``,
  ],
  notification_detail: [`알림 세부 설정`, `Notification Settings`, 's_mypage_noti.notification_detail', ``, ``, ``, ``],
  change: [`변경`, `Update`, 's_mypage_noti.change', ``, ``, ``, ``],
  daily_brief: {
    title: [`Daily Brief 업데이트`, `Daily Brief`, 's_mypage_noti.daily_brief.title', ``, ``, ``, ``],
    desc: [
      `월~금 매일 업데이트되는 Daily Brief 알림을 받으실 수 있습니다.`,
      `You can receive Daily Briefs every weekday.`,
      's_mypage_noti.daily_brief.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  live_webinar: {
    title: [`Live Webinar 리마인더`, `Live Webinar`, 's_mypage_noti.live_webinar.title', ``, ``, ``, ``],
    desc: [
      `매 주말 진행되는 링글 웹세미나 리마인더를 받으실 수 있습니다.`,
      `You can receive weekly notifications about Live Webinars.`,
      's_mypage_noti.live_webinar.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  change_email: [`이메일 변경`, `Change email`, 's_mypage_noti.change_email', ``, ``, ``, ``],
  not_selected: [`선택하지 않음`, `Not Selected`, 's_mypage_noti.not_selected', ``, ``, ``, ``],
  save: [`저장`, `Save`, 's_mypage_noti.save', ``, ``, ``, ``],
  cancel: [`취소`, `Cancel`, 's_mypage_noti.cancel', ``, ``, ``, ``],
  enroll_phone_number: [
    `휴대폰 번호 등록`,
    `Register your mobile number`,
    's_mypage_noti.enroll_phone_number',
    ``,
    ``,
    ``,
    ``,
  ],
  phone_number_verification: [
    `전화번호 인증`,
    `Phone number verification`,
    's_mypage_noti.phone_number_verification',
    ``,
    ``,
    ``,
    ``,
  ],
  veri_number: [`인증번호`, `Verification code`, 's_mypage_noti.veri_number', ``, ``, ``, ``],
  verification_code_re_request: [
    `인증번호 재요청`,
    `Request verification code`,
    's_mypage_noti.verification_code_re_request',
    ``,
    ``,
    ``,
    ``,
  ],
  verification_code_request: [
    `인증번호 요청`,
    `Request verification code`,
    's_mypage_noti.verification_code_request',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_reminder_settings: [
    `수업 리마인더 설정`,
    `Lesson Reminder Settings`,
    's_mypage_noti.lesson_reminder_settings',
    ``,
    ``,
    ``,
    ``,
  ],
  notification_channel: [`알림 채널`, `Notification Channel`, 's_mypage_noti.notification_channel', ``, ``, ``, ``],
  db_update_time: [`업데이트 시간 설정`, `App Push Schedule`, 's_mypage_noti.db_update_time', ``, ``, ``, ``],
  verify: [`입력 완료`, `Verify`, 's_mypage_noti.verify', ``, ``, ``, ``],
  sms_kakao: [`SMS·알림톡`, `Text/Kakao`, 's_mypage_noti.sms_kakao', ``, ``, ``, ``],
  app_push: [`앱 푸시`, `App Push`, 's_mypage_noti.app_push', ``, ``, ``, ``],
  googlecalendar: [`이메일`, `Email`, 's_mypage_noti.googlecalendar', ``, ``, ``, ``],
  googlecalendar_guide: [
    `구글 가입 계정에 한하여 예약한 수업 일정을 구글 캘린더 초대장으로 받을 수 있습니다.`,
    `Get calendar invites for scheduled lessons (only for users signed up with Google)`,
    's_mypage_noti.googlecalendar_guide',
    ``,
    ``,
    ``,
    ``,
  ],
  creditinfo: {
    title: [`수업권/포인트/쿠폰 정보`, ``, 's_mypage_noti.creditinfo.title', ``, ``, ``, ``],
    credit_info: [`수업권·포인트`, `Credits & points`, 's_mypage_noti.creditinfo.credit_info', ``, ``, ``, ``],
    credit_chanel: [`수업권`, `Credits`, 's_mypage_noti.creditinfo.credit_chanel', ``, ``, ``, ``],
    credit_guide: [``, ``, 's_mypage_noti.creditinfo.credit_guide', ``, ``, ``, ``],
    point_info: [
      `수업권의 수강기간 만료 알림을 받을 수 있습니다.`,
      `Get alerts for expiring credits`,
      's_mypage_noti.creditinfo.point_info',
      ``,
      ``,
      ``,
      ``,
    ],
    point_chanel: [`포인트`, `Points`, 's_mypage_noti.creditinfo.point_chanel', ``, ``, ``, ``],
  },
  lesson: {
    title: [
      `포인트 적립과 만료 알림을 받을 수 있습니다.`,
      `Get alerts for earned or expiring points`,
      's_mypage_noti.lesson.title',
      ``,
      ``,
      ``,
      ``,
    ],
    modal1: {
      title: [`알림 필수 항목`, `Notification required`, 's_mypage_noti.lesson.modal1.title', ``, ``, ``, ``],
      guide: [
        `하나 이상의 알림 채널을 꼭 설정해 주셔야 합니다.`,
        `Choose at least one notification channel.`,
        's_mypage_noti.lesson.modal1.guide',
        ``,
        ``,
        ``,
        ``,
      ],
      ok: [`확인`, `Confirm`, 's_mypage_noti.lesson.modal1.ok', ``, ``, ``, ``],
    },
  },
  content: {
    modal1: {
      title: [
        `Content Weekly 구독 취소 사유`,
        `Unsubscribe from Content Weekly`,
        's_mypage_noti.content.modal1.title',
        ``,
        ``,
        ``,
        ``,
      ],
      guide: [
        `그동안 Content Weekly를 구독해 주셔서 감사합니다.
구독을 취소하는 이유를 알려주세요. (복수 선택 가능)`,
        `Before you go, would you be willing to tell us why you want to unsubscribe? (Choose one or more)`,
        's_mypage_noti.content.modal1.guide',
        ``,
        ``,
        ``,
        ``,
      ],
      category1: [
        `영어 공부에 관심이 없어졌습니다.`,
        `I'm not interested in learning English anymore.`,
        's_mypage_noti.content.modal1.category1',
        ``,
        ``,
        ``,
        ``,
      ],
      category2: [
        `링글 콘텐츠가 영어 공부나 인사이트 개발에 도움이 되지 않습니다.`,
        `Ringle content is not very effective for learning English or building insights.`,
        's_mypage_noti.content.modal1.category2',
        ``,
        ``,
        ``,
        ``,
      ],
      category3: [
        `시간이나 비용을 들이고 싶지 않습니다.`,
        `I don't want to invest any time or money into Ringle content.`,
        's_mypage_noti.content.modal1.category3',
        ``,
        ``,
        ``,
        ``,
      ],
      category4: [
        `다른 좋은 콘텐츠를 찾았습니다.`,
        `I have found better content somewhere else.`,
        's_mypage_noti.content.modal1.category4',
        ``,
        ``,
        ``,
        ``,
      ],
      category5: [`기타`, `Others`, 's_mypage_noti.content.modal1.category5', ``, ``, ``, ``],
      placeholder: [
        `무엇인지 알려주세요!`,
        `Please specify.`,
        's_mypage_noti.content.modal1.placeholder',
        ``,
        ``,
        ``,
        ``,
      ],
      submit: [`제출하기`, `Submit`, 's_mypage_noti.content.modal1.submit', ``, ``, ``, ``],
      toast: [
        `Content Weekly 구독 완료`,
        `You have successfully subscribed to Content Weekly`,
        's_mypage_noti.content.modal1.toast',
        ``,
        ``,
        ``,
        ``,
      ],
      toast2: [
        `Content Weekly 구독 취소 사유 제출 완료`,
        `You have successfully unsubscribed from Content Weekly`,
        's_mypage_noti.content.modal1.toast2',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
}
export const s_edit_profile = {
  timezone: [`타임존`, `Time zone`, 's_edit_profile.timezone', ``, ``, ``, ``],
  change: [`변경`, `Change`, 's_edit_profile.change', ``, ``, ``, ``],
  korea_name: [`한국 이름`, `Name`, 's_edit_profile.korea_name', ``, ``, ``, ``],
  company_name: [`회사 이름`, `Company`, 's_edit_profile.company_name', ``, ``, ``, ``],
  role: [`직책(역할)`, `Role`, 's_edit_profile.role', ``, ``, ``, ``],
  company_code: [`기업 맞춤 코드`, `B2B Partner code`, 's_edit_profile.company_code', ``, ``, ``, ``],
  company_code_only_once_it_can_be_entered: [
    `기업 맞춤 코드는 1회에 한하여, 입력이 가능합니다. 기업 맞춤 코드 변경을 원하신다면 하단의 1:1 문의 해주시면 됩니다.`,
    `You can only enter one B2B Partner code. If you need to change your code, please contact us via chat below.`,
    's_edit_profile.company_code_only_once_it_can_be_entered',
    ``,
    ``,
    ``,
    ``,
  ],
  inquiries_about_the_company_codes_are_let_us_know_by_channeltalk: [
    `기업 맞춤 코드에 대한 문의는 1) 재직중인 회사 2) 링글 아이디와 함께 홈페이지 하단의 1:1 문의로 남겨주시면 됩니다.`,
    `For B2B Partner code related inquiries, please contact us via chat below and leave us your current company name and Ringle ID.`,
    's_edit_profile.inquiries_about_the_company_codes_are_let_us_know_by_channeltalk',
    ``,
    ``,
    ``,
    ``,
  ],
  channel_talk_contact: [`1:1 문의`, `Contact us via Chat`, 's_edit_profile.channel_talk_contact', ``, ``, ``, ``],
  save: [`저장`, `Save`, 's_edit_profile.save', ``, ``, ``, ``],
  set_time_zone: [`타임존 설정`, `Set time zone`, 's_edit_profile.set_time_zone', ``, ``, ``, ``],
  it_has_been_saved: [`저장되었습니다.`, `Saved`, 's_edit_profile.it_has_been_saved', ``, ``, ``, ``],
  complete_your_profile_and_earn_10_000p_your_profile_will_help_your_tutor_to_tailor_your_lesson_: [
    <>
      프로필을 완성하고, <span style={{ color: '#d3c8fc' }}>10,000P</span>를 받으세요! 튜터가 고객님을 위한 맞춤 수업을
      하는데 도움이 됩니다.
    </>,
    <>
      Complete your profile and earn&nbsp;
      <span style={{ color: '#d3c8fc' }}>$8.62P</span>!
    </>,
    's_edit_profile.complete_your_profile_and_earn_10_000p_your_profile_will_help_your_tutor_to_tailor_your_lesson_',
    ``,
    ``,
    ``,
    ``,
  ],
  haven_t_completed_your_profile_yet_please_complete_your_profile_for_more_tailored_class_: [
    <>
      효과적인 맞춤 수업 위해
      <Span primary> 프로필 완성</Span>하고
      <Span style={{ color: '#2a97ff' }}> 10,000 포인트</Span> 적립 받으세요.
    </>,
    <>
      <Span primary>Complete your profile</Span> for a better Ringle class experience &
      <Span style={{ color: '#2a97ff' }}> receive $8.62 points</Span>!
    </>,
    's_edit_profile.haven_t_completed_your_profile_yet_please_complete_your_profile_for_more_tailored_class_',
    ``,
    ``,
    ``,
    ``,
  ],
  english_name: [`영어 이름`, `English Name`, 's_edit_profile.english_name', ``, ``, ``, ``],
  occupation: [`직업`, `Occupation`, 's_edit_profile.occupation', ``, ``, ``, ``],
  purpose_of_usaing_ringle: [
    `링글 사용 목적`,
    `Purpose of Using Ringle`,
    's_edit_profile.purpose_of_usaing_ringle',
    ``,
    ``,
    ``,
    ``,
  ],
  self_introduction_in_english: [
    `영어 자기 소개`,
    `About Me`,
    's_edit_profile.self_introduction_in_english',
    ``,
    ``,
    ``,
    ``,
  ],
  profile_movie: [`프로필 영상`, `Profile Video`, 's_edit_profile.profile_movie', ``, ``, ``, ``],
  required: [`필수`, `Required`, 's_edit_profile.required', ``, ``, ``, ``],
  optional: [`선택`, `Optional`, 's_edit_profile.optional', ``, ``, ``, ``],
  enter_your_basic_info_in_english_: [
    `영어로 고객님에 대한 기본 소개를 입력해주세요.`,
    `Please tell us about yourself in English.`,
    's_edit_profile.enter_your_basic_info_in_english_',
    ``,
    ``,
    ``,
    ``,
  ],
  change_video: [`변경하기`, `Change video`, 's_edit_profile.change_video', ``, ``, ``, ``],
  shoot_video: [`동영상 촬영`, `Record video`, 's_edit_profile.shoot_video', ``, ``, ``, ``],
  profile_photo: [`프로필 사진`, `Profile Photo`, 's_edit_profile.profile_photo', ``, ``, ``, ``],
  occupation_company: [`직업`, `Occupation`, 's_edit_profile.occupation_company', ``, ``, ``, ``],
  complete_my_profile_for_a_lesson: [
    <>
      수업 전 튜터가 내게 꼭 맞는 수업을 준비할 수 있도록&nbsp;
      <Span primary>내 프로필</Span>을 완성해 보세요!
    </>,
    <>
      Please complete <Span primary>your profile</Span> for a better class experience!
    </>,
    's_edit_profile.complete_my_profile_for_a_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  more_effective_lessons: [
    <>
      여러분을 잘 알고 있는 튜터와 <Span primary>보다 더 효과적인 수업</Span>을 진행할 수 있어요.
    </>,
    ``,
    's_edit_profile.more_effective_lessons',
    ``,
    ``,
    ``,
    ``,
  ],
  event_profile_points: [
    <>
      <Span style={{ color: '#2a97ff' }}>[Event]</Span> 성공적으로 내 프로필을 완성하면
      <Span style={{ color: '#2a97ff' }}>10,000포인트</Span>가 적립됩니다.
    </>,
    <>
      <Span warning>[Event]</Span> Complete your profile and earn $8.62P.
    </>,
    's_edit_profile.event_profile_points',
    ``,
    ``,
    ``,
    ``,
  ],
  event_only_tutor: [
    <>작성한 프로필은 수업 진행을 확정한 튜터만 확인할 수 있고, 언제든 수정할 수 있습니다.</>,
    <>Only tutors can see your profile and it can be modified at any time.&nbsp;</>,
    's_edit_profile.event_only_tutor',
    ``,
    ``,
    ``,
    ``,
  ],
  required_menu: [`필수 항목`, `Required`, 's_edit_profile.required_menu', ``, ``, ``, ``],
  optional_menu: [`선택 항목`, `Optional`, 's_edit_profile.optional_menu', ``, ``, ``, ``],
  btn_rerecord: [`다시 촬영`, `Record again`, 's_edit_profile.btn_rerecord', ``, ``, ``, ``],
  info: [`안내`, `Info`, 's_edit_profile.info', ``, ``, ``, ``],
  rerecord: [
    `다시 촬영하시면 기존에 촬영하신 동영상은 삭제됩니다. 다시 촬영하시겠습니까?`,
    `If you record again, previously recorded video will be deleted. Would you like to record again?`,
    's_edit_profile.rerecord',
    ``,
    ``,
    ``,
    ``,
  ],
  start_record: [
    `* 버튼을 누르는 순간 촬영이 시작됩니다.`,
    `Recording will start immediately after you push the button.`,
    's_edit_profile.start_record',
    ``,
    ``,
    ``,
    ``,
  ],
  please_push_your_btn: [
    <>
      <Span primary>녹화 시작하기 </Span>를 눌러
      <br />
      촬영을 시작해주세요
    </>,
    <>
      When you're ready,
      <br />
      Please click Start Recording button below.
    </>,
    's_edit_profile.please_push_your_btn',
    ``,
    ``,
    ``,
    ``,
  ],
  study_goal: [`영어 공부 목표`, `Learning objectives`, 's_edit_profile.study_goal', ``, ``, ``, ``],
  more_than_50: [`(50 단어 이상 입력)`, `(enter min. 50 words)`, 's_edit_profile.more_than_50', ``, ``, ``, ``],
  name: [`이름`, `Name`, 's_edit_profile.name', ``, ``, ``, ``],
  word_count: [`입력된 단어 수`, `Word count:`, 's_edit_profile.word_count', ``, ``, ``, ``],
  submitted: [`제출되었습니다.`, `Submitted`, 's_edit_profile.submitted', ``, ``, ``, ``],
  check_point: [`포인트 확인`, `View Points`, 's_edit_profile.check_point', ``, ``, ``, ``],
  close: [`닫기`, `Close`, 's_edit_profile.close', ``, ``, ``, ``],
  complete: [`작성 완료`, `Complete`, 's_edit_profile.complete', ``, ``, ``, ``],
  upload_picture: [`사진 업로드`, `Upload picture`, 's_edit_profile.upload_picture', ``, ``, ``, ``],
  video_popup_title: [
    `프로필 영상 촬영`,
    `Self-introduction Video`,
    's_edit_profile.video_popup_title',
    ``,
    ``,
    ``,
    ``,
  ],
  browser_media_permission: [
    <>
      카메라와 마이크 접근이 혀용되지 않아 동영상 촬영이 안됩니다.
      <br />
      브라우저 설정에서 카메라와 마이크 권한을 허용해주세요.
    </>,
    <>
      Access to camera and microphone was blocked so you can't record.
      <br />
      Please update your browser settings to allow access.
    </>,
    's_edit_profile.browser_media_permission',
    ``,
    ``,
    ``,
    ``,
  ],
  preview: [`프로필 미리보기`, `Preview`, 's_edit_profile.preview', ``, ``, ``, ``],
  go: [`바로가기`, `Go`, 's_edit_profile.go', ``, ``, ``, ``],
  start_recording: [`녹화 시작하기`, `Start Recording`, 's_edit_profile.start_recording', ``, ``, ``, ``],
  submit: [`제출하기`, `Submit`, 's_edit_profile.submit', ``, ``, ``, ``],
  congratulations: [
    <>
      <Span textPrimary>축하합니다!</Span>
      <br />
      <Span textPrimary>1만 포인트</Span>가 지급되었습니다.
    </>,
    <>
      <Span primary>Congrats!</Span>
      <br />
      You got <Span primary>$8.62P.</Span>
    </>,
    's_edit_profile.congratulations',
    ``,
    ``,
    ``,
    ``,
  ],
  about_point: [
    <>
      발급된 포인트는 마이 페이지 - 포인트에서 확인 가능합니다.
      <br />* 최초 3회 수업권 구매시 사용 불가합니다.
    </>,
    <>
      Check your points under My Page - Points
      <br />
      *Can't apply points to first 3 lessons.
    </>,
    's_edit_profile.about_point',
    ``,
    ``,
    ``,
    ``,
  ],
  use_towards_your_next_purchase: [
    `수업권 구매 시 사용가능합니다.`,
    `Use it towards your next purchase!`,
    's_edit_profile.use_towards_your_next_purchase',
    ``,
    ``,
    ``,
    ``,
  ],
  stop_recording: [`녹화 종료하기`, `Stop Recording`, 's_edit_profile.stop_recording', ``, ``, ``, ``],
  record_again: [`다시 촬영하기`, `Record Again`, 's_edit_profile.record_again', ``, ``, ``, ``],
  delete_video: [`동영상 삭제`, `Delete video`, 's_edit_profile.delete_video', ``, ``, ``, ``],
  click_button_to_save: {
    default: [
      `변경 사항이 있습니다. 저장하기 버튼을 눌러 저장하세요.`,
      ``,
      's_edit_profile.click_button_to_save.default',
      ``,
      ``,
      ``,
      ``,
    ],
    with_welcome_coupon: [
      `언어 변환 시, 제공된 웰컴쿠폰 사용이 제한됩니다.`,
      `The Welcome Coupon will be unavailable if you change your language setting.`,
      's_edit_profile.click_button_to_save.with_welcome_coupon',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const s_zoom = {
  title: [`화상 프로그램 (Zoom) 설치`, `How to Install Zoom`, 's_zoom.title', ``, ``, ``, ``],
  subtitle: [
    `링글 수업을 이용하기 위해서는, 줌(Zoom)설치가 필요합니다`,
    `You need to install Zoom in order to use Ringle`,
    's_zoom.subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  step3: [
    <>
      3. 다운로드 완료 후, ZOOM 회원가입/ 로그인/ 미팅 참여 하실 필요 없습니다.
      <br />
      수업 접속시, 링글 홈페이지 - 예정된 수업 - 수업입장 버튼을 통해 입장 가능합니다.
      <br />
      <br />
      *수업 시작 전 별도 줌 링크가 제공되지 않습니다.
    </>,
    <>
      3.After downloading, you do not need to make a new Zoom account. You just have to have the program installed on
      your computer.
    </>,
    's_zoom.step3',
    ``,
    ``,
    ``,
    ``,
  ],
  step4: [`4. Zoom Test 하기 (약 2분 소요)`, `4. Test Zoom (takes about 2 minutes)`, 's_zoom.step4', ``, ``, ``, ``],
  zoom_test_btn: [`Zoom Test 하기`, `Test Zoom`, 's_zoom.zoom_test_btn', ``, ``, ``, ``],
  steps_are_following: [
    `단계는 아래와 같습니다.`,
    `For testing, follow these steps:`,
    's_zoom.steps_are_following',
    ``,
    ``,
    ``,
    ``,
  ],
  step4_1_click_join_button: [
    `1. Zoom Test 입장 후, 참가 버튼을 클릭합니다.`,
    <>1) After entering the Zoom Test, click the "Join".</>,
    's_zoom.step4_1_click_join_button',
    ``,
    ``,
    ``,
    ``,
  ],
  step4_2: [`2. Zoom. us 열기를 클릭합니다.`, <>2) Click "Open Zoom.us".</>, 's_zoom.step4_2', ``, ``, ``, ``],
  setp4_4_node: [
    <>만약, 줌 이용이 어려우시다면 언제든지 하단 1:1 문의/카카오톡을 통해 링글팀에게 알려주세요.</>,
    `If you have any problem using Zoom, please contact us. Thank you.`,
    's_zoom.setp4_4_node',
    ``,
    ``,
    ``,
    ``,
  ],
  go_to_lesson_page_btn: [`예습 & 수업 페이지 가기`, `Go to Lesson`, 's_zoom.go_to_lesson_page_btn', ``, ``, ``, ``],
  new_guide_step_3: [
    <>
      3. 오디오 연결 방식을 선택 후<br />
      카메라, 스피커 및 마이크 테스트를 진행합니다.
    </>,
    <>
      3. Select audio option and <br />
      test camera, speaker and mic.
    </>,
    's_zoom.new_guide_step_3',
    ``,
    ``,
    ``,
    ``,
  ],
  new_guide_step_4: [
    <>
      4. 만약 카메라가 보이지 않으면,
      <br /> 하단 버튼을 클릭 후 카메라 설정을 변경해보세요.
    </>,
    <>
      4. If you're having video issues,
      <br /> please check your camera settings.
    </>,
    's_zoom.new_guide_step_4',
    ``,
    ``,
    ``,
    ``,
  ],
  new_guide_step_5: [
    <>
      5. 만약 벨소리가 들리지 않으면,
      <br /> 하단 버튼을 클릭 후 스피커 설정을 변경해보세요.
    </>,
    <>
      5. If you don't hear the ringtone,
      <br /> please check your speaker settings.
    </>,
    's_zoom.new_guide_step_5',
    ``,
    ``,
    ``,
    ``,
  ],
  new_guide_step_6: [
    <>
      6. 만약 마이크가 작동하지 않으면,
      <br /> 하단 버튼을 클릭 후 마이크 설정을 변경해보세요.
    </>,
    <>
      6. If your mic doesn't work,
      <br /> please check your input.
    </>,
    's_zoom.new_guide_step_6',
    ``,
    ``,
    ``,
    ``,
  ],
  new_guide_step_7: [
    <>
      7. 다음과 동일한 화면이 나오면,
      <br /> 카메라,스피커와 마이크가 정상적으로 작동중입니다.
    </>,
    <>
      7. If you see below screen,
      <br /> you're all good to go.
    </>,
    's_zoom.new_guide_step_7',
    ``,
    ``,
    ``,
    ``,
  ],
  new_guide_step_8: [
    <>
      8. 수업 입장 시, "오디오 연결” 버튼을
      <br /> 클릭하여 오디오가 잘 연결되었는지 확인해보세요.
    </>,
    <>
      8. When you enter your lesson, <br />
      please "Join With Computer Audio".
    </>,
    's_zoom.new_guide_step_8',
    ``,
    ``,
    ``,
    ``,
  ],
  new_guide_step_9: [
    `9. 수업 전 스피커와 비디오가 켜져 있는지 확인해보세요.`,
    `9. Please double check that your audio & video is on.`,
    's_zoom.new_guide_step_9',
    ``,
    ``,
    ``,
    ``,
  ],
  step_1_zoom_download: [
    `Step 1. Zoom 다운로드`,
    `Step 1. Download Zoom`,
    's_zoom.step_1_zoom_download',
    ``,
    ``,
    ``,
    ``,
  ],
  plz_download_zoom: [
    `링글의 수업은 Zoom 으로 진행됩니다. 아래의 Zoom 파일을 다운로드 해주세요.`,
    `All Ringle lessons run on zoom.`,
    's_zoom.plz_download_zoom',
    ``,
    ``,
    ``,
    ``,
  ],
  zoom_download: [`Zoom 다운로드`, `Download Zoom`, 's_zoom.zoom_download', ``, ``, ``, ``],
  step_2_install: [`Step 2. 설치`, `Step 2. Install Zoom`, 's_zoom.step_2_install', ``, ``, ``, ``],
  run_file_install_in_computer: [
    `다운 받은 파일을 실행 후, 내 컴퓨터에 설치해주세요.`,
    `After download, please install.`,
    's_zoom.run_file_install_in_computer',
    ``,
    ``,
    ``,
    ``,
  ],
  step3_class_access: [`Step 3. 수업접속`, `Step 3. Enter Lesson`, 's_zoom.step3_class_access', ``, ``, ``, ``],
  after_download_do_not_need_participation: [
    `다운로드 완료 후, ZOOM 회원가입/ 로그인/ 미팅 참여 하실 필요 없습니다.`,
    `You don't need a Zoom account to enter Ringle lessons.`,
    's_zoom.after_download_do_not_need_participation',
    ``,
    ``,
    ``,
    ``,
  ],
  class_can_enter_with_button: [
    `수업 접속시, 링글 홈페이지 - 예정된 수업 - 수업입장 버튼을 통해 입장 가능합니다.`,
    `Please sign in to Ringle and go to Upcoming Lessons > Enter Lesson to enter your lesson.`,
    's_zoom.class_can_enter_with_button',
    ``,
    ``,
    ``,
    ``,
  ],
  before_start_class_no_zoom_link: [
    `*수업 시작 전 별도 줌 링크가 제공되지 않습니다.`,
    `*We do not provide zoom link prior to lessons.`,
    's_zoom.before_start_class_no_zoom_link',
    ``,
    ``,
    ``,
    ``,
  ],
  step4_zoom_test: [`Step 4. Zoom Test`, `Step 4. Test Zoom`, 's_zoom.step4_zoom_test', ``, ``, ``, ``],
  after_install_push_below_link: [
    `설치 후, 아래 링크를 눌러서 테스트를 해주세요. (약 2분 소요)`,
    `Please go to link below to test your audio & video on Zoom (takes 2 min).`,
    's_zoom.after_install_push_below_link',
    ``,
    ``,
    ``,
    ``,
  ],
  before_class_check_audio_video: [
    `수업 전에 오디오와 비디오가 올바로 동작하시는지 확인하실 수 있습니다.`,
    ``,
    's_zoom.before_class_check_audio_video',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_auth = {
  modify: [`수정`, `Update`, 's_auth.modify', ``, ``, ``, ``],
  phone_verification: [`휴대폰 인증`, `Phone Verification`, 's_auth.phone_verification', ``, ``, ``, ``],
  verification_code_request: [
    `인증번호 요청`,
    `Request verification code`,
    's_auth.verification_code_request',
    ``,
    ``,
    ``,
    ``,
  ],
  verification_code_re_request: [
    `인증번호 재요청`,
    `Request verification code`,
    's_auth.verification_code_re_request',
    ``,
    ``,
    ``,
    ``,
  ],
  submit_code: [`입력 완료`, `Verify`, 's_auth.submit_code', ``, ``, ``, ``],
  kakao: [`카카오로 5초만에 가입`, `Sign up with Kakao`, 's_auth.kakao', ``, ``, ``, ``],
  with_email: [`이메일로 가입`, `Sign up with email`, 's_auth.with_email', ``, ``, ``, ``],
  naver: [`네이버로 가입`, `Sign up with Naver`, 's_auth.naver', ``, ``, ``, ``],
  choose: [`회원가입 방법을 선택해주세요`, `Sign up with`, 's_auth.choose', ``, ``, ``, ``],
  send_verification: [`인증번호 전송`, `Send Verification Code`, 's_auth.send_verification', ``, ``, ``, ``],
  ok: [`확인`, `OK`, 's_auth.ok', ``, ``, ``, ``],
  phone_check: [
    `올바른 휴대폰 번호를 입력해주세요.`,
    `Invalid phone number. Please try again.`,
    's_auth.phone_check',
    ``,
    ``,
    ``,
    ``,
  ],
  google: [`구글로 가입`, `Sign up with Google`, 's_auth.google', ``, ``, ``, ``],
  facebook: [`페이스북으로 가입`, `Sign up with Facebook`, 's_auth.facebook', ``, ``, ``, ``],
  native_languaged: [`모국어`, `Native language`, 's_auth.native_languaged', ``, ``, ``, ``],
  native_language_placeholder: [
    `모국어를 선택해주세요`,
    `Select your native language`,
    's_auth.native_language_placeholder',
    ``,
    ``,
    ``,
    ``,
  ],
  this_number_already_exists: [
    `이미 해당 번호로 가입된 계정이 있습니다. 링글팀에 문의해주세요.`,
    `An account with this number already exists. Please contact us with any questions.`,
    's_auth.this_number_already_exists',
    ``,
    ``,
    ``,
    ``,
  ],
  title: [`Welcome to Ringle!`, `Welcome to Ringle!`, 's_auth.title', ``, ``, ``, ``],
  apply_tutor: [`Apply to become a tutor`, `Apply to become a tutor`, 's_auth.apply_tutor', ``, ``, ``, ``],
}
export const s_purchase = {
  header: [`결제하기`, `Purchase`, 's_purchase.header', ``, ``, ``, ``],
  name_ko: [`한글 이름`, `Name`, 's_purchase.name_ko', ``, ``, ``, ``],
  lesson_length: [`수업길이`, `Duration`, 's_purchase.lesson_length', ``, ``, ``, ``],
  time: [`시간대`, `Times`, 's_purchase.time', ``, ``, ``, ``],
  point: [`포인트`, `Points`, 's_purchase.point', ``, ``, ``, ``],
  price: [`결제금액`, `Subtotal`, 's_purchase.price', ``, ``, ``, ``],
  discount_price: [`총 할인 금액`, `Total discount`, 's_purchase.discount_price', ``, ``, ``, ``],
  won: (price) => [`${price}원`, `$${price}`, 's_purchase.won', ``, ``, ``, ``],
  total_price: [`최종 결제 금액`, `Your total`, 's_purchase.total_price', ``, ``, ``, ``],
  directPayment: [`카드 직접 입력`, `Enter card information`, 's_purchase.directPayment', ``, ``, ``, ``],
  virtual_account: [`무통장 입금`, `Wire Transfer`, 's_purchase.virtual_account', ``, ``, ``, ``],
  paypal: [`Paypal 사용`, `via PayPal`, 's_purchase.paypal', ``, ``, ``, ``],
  use_point: [`포인트 사용`, `Points applied`, 's_purchase.use_point', ``, ``, ``, ``],
  verify: [`본인 인증`, `Verify`, 's_purchase.verify', ``, ``, ``, ``],
  verify_explanation: [
    `타인 명의 도용, 중복 가입 방지를 위해 본인을 인증합니다.`,
    `Please verify to prevent fraud.`,
    's_purchase.verify_explanation',
    ``,
    ``,
    ``,
    ``,
  ],
  usual_price: [`정가 가격`, `Regular price`, 's_purchase.usual_price', ``, ``, ``, ``],
  discount_sum: [`총 할인금액`, `Total discount`, 's_purchase.discount_sum', ``, ``, ``, ``],
  account_transfer: [`실시간 계좌 이체`, `Wire Transfer`, 's_purchase.account_transfer', ``, ``, ``, ``],
  naver_pay: [`네이버페이(카드결제)`, `NaverPay`, 's_purchase.naver_pay', ``, ``, ``, ``],
  virtual_account_make: [`가상 계좌 발급`, `Virtual Account`, 's_purchase.virtual_account_make', ``, ``, ``, ``],
  pay_pal: [`PayPal`, `PayPal`, 's_purchase.pay_pal', ``, ``, ``, ``],
  warning: [`주의사항`, `Terms`, 's_purchase.warning', ``, ``, ``, ``],
  second_card: [`분할 결제 (두번째 카드)`, `Second card`, 's_purchase.second_card', ``, ``, ``, ``],
  first_card: [`분할 결제 (첫번째 카드)`, `First card`, 's_purchase.first_card', ``, ``, ``, ``],
  two_digit: [`2자리`, `2 Digit`, 's_purchase.two_digit', ``, ``, ``, ``],
  only_domestic: [`국내 카드만 가능`, `Only available for Korean cards.`, 's_purchase.only_domestic', ``, ``, ``, ``],
  purchase_agreement: [
    <>
      결제 내역을 확인하였으며{' '}
      <LinkLocale href={urls.teens.policy.all} target="_blank">
        <span className="underline text-primary">전자상거래법 및 링글 정책</span>
      </LinkLocale>
      에 의거하여 환불이 진행되는 것에 동의합니다.
    </>,
    <>
      I confirm my purchase and agree to{' '}
      <LinkLocale href={urls.teens.policy.all} target="_blank">
        <span className="underline text-primary">Ringle's terms and conditions</span>
      </LinkLocale>
      .
    </>,
    's_purchase.purchase_agreement',
    ``,
    ``,
    ``,
    ``,
  ],
  switch_to_won: [`원화 결제하기`, `Pay in KRW`, 's_purchase.switch_to_won', ``, ``, ``, ``],
  switch_to_other: [`달러 결제하기`, `Pay in USD`, 's_purchase.switch_to_other', ``, ``, ``, ``],
  process: {
    title: [`결제가 진행중입니다.`, `Processing your payment...`, 's_purchase.process.title', ``, ``, ``, ``],
    subtitle1: [
      `최대 1분이 소요될 수 있습니다. 잠시만 기다려주세요.`,
      `This may take up to a minute to complete.`,
      's_purchase.process.subtitle1',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle2: [
      `브라우저를 닫거나 새로고침 하시면 결제 오류가 발생할 수 있습니다.`,
      `Please stay on the page while the payment is being processed.`,
      's_purchase.process.subtitle2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  fail: {
    title: [
      <>
        결제를 완료할 수 없습니다.
        <br />
        다시 시도해주세요.
      </>,
      <>
        Your payment didn't go through.
        <br />
        Please try again.
      </>,
      's_purchase.fail.title',
      ``,
      ``,
      ``,
      ``,
    ],
    error: [`결제 오류`, `Transaction failed`, 's_purchase.fail.error', ``, ``, ``, ``],
    history: [`결제 시도 내역`, `Payment attempts`, 's_purchase.fail.history', ``, ``, ``, ``],
    amount: [`결제 요청 금액`, `Amount due`, 's_purchase.fail.amount', ``, ``, ``, ``],
    help1: [`결제 오류가 반복되신다면`, `Having trouble?`, 's_purchase.fail.help1', ``, ``, ``, ``],
    help2: [`여기를 눌러 문의 해주세요.`, `Let us help you.`, 's_purchase.fail.help2', ``, ``, ``, ``],
    btn: [`다시 결제하기`, `Try again`, 's_purchase.fail.btn', ``, ``, ``, ``],
    popup_title: [
      `팝업이 차단되어 결제할 수 없습니다. 팝업 허용 후 다시 시도해 주세요.`,
      `Payment could not be completed because pop-ups are blocked by the browser. Please try again after enabling pop-ups.`,
      's_purchase.fail.popup_title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  notice_1credit: [
    `1회 수업권은 구매 후 즉시 수강 기간이 차감됩니다.`,
    `The trial lesson credit starts expiring immediately after purchase.`,
    's_purchase.notice_1credit',
    ``,
    ``,
    ``,
    ``,
  ],
  ticket_name: [`상품명`, `Ticket`, 's_purchase.ticket_name', ``, ``, ``, ``],
  ticket_date: [`참여날짜`, `Date`, 's_purchase.ticket_date', ``, ``, ``, ``],
  discount_secret: [`시크릿 쿠폰 할인`, `Secret Offer`, 's_purchase.discount_secret', ``, ``, ``, ``],
  total_price_card: {
    price: [`최종 결제 금액`, `Total`, 's_purchase.total_price_card.price', ``, ``, ``, ``],
    promo: [`프로모션 특가`, `Promotional Deal`, 's_purchase.total_price_card.promo', ``, ``, ``, ``],
  },
  commissionfee_warning: [
    `링글 틴즈는 미국 법인에서 운영되고 있어 카드사에 따라 1 - 2% 의 해외 결제 수수료가 부과될 수 있습니다.`,
    `Ringle Teens is operated by a U.S. corporation and may be subject to international payment fees of 1 - 2% depending on your card provider.`,
    's_purchase.commissionfee_warning',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_purchase_completed = {
  done: [`결제완료`, `Complete`, 's_purchase_completed.done', ``, ``, ``, ``],
  done_desc: (name) => [
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}님
        <br />
        <Div spanTag textPrimary>
          결제 완료
        </Div>
        되었습니다.
      </Div>
      <Div fontBold textD5>
        이제 링글 수업을 시작해보세요!
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        Hi {name}
        <br />
        <Div spanTag textPrimary>
          Congrats!
        </Div>
      </Div>
      <Div mt8 fontBold textD5>
        We received your payment.
      </Div>
    </>,
    's_purchase_completed.done_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  purchase_info: [`결제 내역`, `Purchase history`, 's_purchase_completed.purchase_info', ``, ``, ``, ``],
  point_use: [`포인트 사용 내역`, `Points history`, 's_purchase_completed.point_use', ``, ``, ``, ``],
  total: [`최종 결제 금액`, `Your total`, 's_purchase_completed.total', ``, ``, ``, ``],
  order: [`주문상품`, `Your cart`, 's_purchase_completed.order', ``, ``, ``, ``],
  lesson_credit: [`수업권명`, `Package`, 's_purchase_completed.lesson_credit', ``, ``, ``, ``],
  lesson_length: [`수업길이`, `Duration`, 's_purchase_completed.lesson_length', ``, ``, ``, ``],
  minutes: (minute) => [`${minute}분`, `${minute} min`, 's_purchase_completed.minutes', ``, ``, ``, ``],
  exp_dates: [`시간대`, `Times`, 's_purchase_completed.exp_dates', ``, ``, ``, ``],
  time_desc: [`수업 시작 기준입니다.`, `Lesson starting times`, 's_purchase_completed.time_desc', ``, ``, ``, ``],
  duration: [`수강기간`, `Expiration dates`, 's_purchase_completed.duration', ``, ``, ``, ``],
  duration_desc: [
    `첫 수업시작 전까지 수강기간은 미차감됩니다.`,
    `Calculated from the date you complete your first lesson using the purchased package.`,
    's_purchase_completed.duration_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  challenge: [`도전기간`, `Challenge period`, 's_purchase_completed.challenge', ``, ``, ``, ``],
  challenge_desc: (day, count) => [
    `첫수업 후 ${day}일내 정규수업권 소진 시, +${count}회가 지급됩니다.`,
    `Use all your credits in ${day} days and get +${count} bonus lessons.`,
    's_purchase_completed.challenge_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  receipt_certificate: [
    `영수증/출석증은 PC에서 오른쪽 상단 프로필 > 수업권/증명서류 탭에서 PDF로 다운로드 가능합니다.`,
    `You can download your lesson certificates & proof of attendance under Credits.`,
    's_purchase_completed.receipt_certificate',
    ``,
    ``,
    ``,
    ``,
  ],
  new_to_ringle: [
    `링글 수업 전 꼭 확인해 주세요!`,
    `New to Ringle?`,
    's_purchase_completed.new_to_ringle',
    ``,
    ``,
    ``,
    ``,
  ],
  go_to_home: [`홈으로 가기`, `Home`, 's_purchase_completed.go_to_home', ``, ``, ``, ``],
  vbank_name: [`입금 가상 계좌번호`, `Virtual account number`, 's_purchase_completed.vbank_name', ``, ``, ``, ``],
  vbank_date: [`입금 기한`, `Payment due by`, 's_purchase_completed.vbank_date', ``, ``, ``, ``],
  vbank_title: (name) => [
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}님
        <br />
        아래 계좌로 입금해 주세요.
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        {' '}
        {name}
        <br /> please make payment to the account below
      </Div>{' '}
    </>,
    's_purchase_completed.vbank_title',
    ``,
    ``,
    ``,
    ``,
  ],
  existing_customer: [
    `링글 수업, 이렇게도 활용할 수 있어요!`,
    ``,
    's_purchase_completed.existing_customer',
    ``,
    ``,
    ``,
    ``,
  ],
  go_to_booking: [`수업 예약하기`, `Book a lesson`, 's_purchase_completed.go_to_booking', ``, ``, ``, ``],
  go_to_OT: [`1:1 학습 상담 알아보기`, `Register Orientation`, 's_purchase_completed.go_to_OT', ``, ``, ``, ``],
  go_to_booking_app: [
    `앱으로 수업 예약하기`,
    `Book a lesson`,
    's_purchase_completed.go_to_booking_app',
    ``,
    ``,
    ``,
    ``,
  ],
  go_to_study_week: [
    `영어학습 세미나 신청하기`,
    `Sign up: 
How to Study English Seminar`,
    's_purchase_completed.go_to_study_week',
    ``,
    ``,
    ``,
    ``,
  ],
  email_desc: [
    `컨퍼런스 신청이 완료되었습니다. 자세한 내용은
가입 시 입력하신 이메일로 보내드립니다.`,
    ``,
    's_purchase_completed.email_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  btn1: [`컨퍼런스 페이지로 돌아가기`, `Conference Page`, 's_purchase_completed.btn1', ``, ``, ``, ``],
  btn2: [`링글 알아보기`, `Ringle Home`, 's_purchase_completed.btn2', ``, ``, ``, ``],
  done_desc_conference: (name) => [
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}님
        <br />
        <Div spanTag textPrimary>
          결제 완료
        </Div>
        되었습니다.
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        Hi {name}
        <br />
        <Div spanTag textPrimary>
          Congrats!
        </Div>
      </Div>
      <Div mt8 fontBold textD5>
        We received your payment.
      </Div>
    </>,
    's_purchase_completed.done_desc_conference',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_mypage = {
  ringle_email: [`링글 가입 계정 이메일`, `Email (Ringle account)`, 's_mypage.ringle_email', ``, ``, ``, ``],
  email2: [`추가 이메일`, `Secondary email`, 's_mypage.email2', ``, ``, ``, ``],
  choose_email: [
    `알람 수신용 이메일을 입력하세요.`,
    `Please enter email for notifications.`,
    's_mypage.choose_email',
    ``,
    ``,
    ``,
    ``,
  ],
  can_only_change_email2: [
    `링글 가입 계정 이메일은 변경되지 않으며, 알림 수신 이메일만 변경됩니다.`,
    `We'll send any notifications to this email. Your sign in (account) email won't change.`,
    's_mypage.can_only_change_email2',
    ``,
    ``,
    ``,
    ``,
  ],
  timezone_desc: [
    `현재 위치와 타임존이 다른 경우, 도시 이름을 입력하거나 리스트에서 선택해주세요.`,
    `Enter a city name or select from the list to change the time zone.`,
    's_mypage.timezone_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  save: [`저장`, `save`, 's_mypage.save', ``, ``, ``, ``],
  leave: {
    title: [`링글 탈퇴하기`, `Delete my account`, 's_mypage.leave.title', ``, ``, ``, ``],
    description: [
      `유의사항에 모두 체크하고, 탈퇴를 누르면  계정 내용이 모두 삭제 됩니다.`,
      `Account information will be deleted when you confirm the terms and delete the account.`,
      's_mypage.leave.description',
      ``,
      ``,
      ``,
      ``,
    ],
    important_title: [`유의사항`, `Terms`, 's_mypage.leave.important_title', ``, ``, ``, ``],
    important_title_1: [
      `수업권, 포인트 및 쿠폰 모두 삭제`,
      `Deleted accounts cannot be restored.`,
      's_mypage.leave.important_title_1',
      ``,
      ``,
      ``,
      ``,
    ],
    important_title_2: [
      `탈퇴 후 수업권 환불 불가`,
      `Remained credits and points will all be deleted.`,
      's_mypage.leave.important_title_2',
      ``,
      ``,
      ``,
      ``,
    ],
    important_title_3: [
      `피드백 리포트, 수업 내역 등의 학습 기록의 재열람 불가`,
      `All lesson history will be deleted.`,
      's_mypage.leave.important_title_3',
      ``,
      ``,
      ``,
      ``,
    ],
    important_title_4: [
      `재가입 시 다른 이메일 계정 필요`,
      `You will have to make a new account with a different email address if you want to sign up again.`,
      's_mypage.leave.important_title_4',
      ``,
      ``,
      ``,
      ``,
    ],
    submit: [`탈퇴`, `Leave`, 's_mypage.leave.submit', ``, ``, ``, ``],
    cancel: [`취소`, `Cancel`, 's_mypage.leave.cancel', ``, ``, ``, ``],
  },
  does_not_match: [
    `새 비밀번호와 일치하지 않습니다.`,
    `Password does not match.`,
    's_mypage.does_not_match',
    ``,
    ``,
    ``,
    ``,
  ],
  at_least_six_char: [
    `비밀번호를 6글자 이상 입력해주세요.`,
    `Your password must be at least 6 characters.`,
    's_mypage.at_least_six_char',
    ``,
    ``,
    ``,
    ``,
  ],
  your_option_change_has_been_completed: [
    `변경한 내용이 저장되었습니다.`,
    `Saved`,
    's_mypage.your_option_change_has_been_completed',
    ``,
    ``,
    ``,
    ``,
  ],
  please_select_language: [
    `주요 사용 언어를 선택해주세요.`,
    `Please select language.`,
    's_mypage.please_select_language',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_moportal = {
  group_a: {
    welcome_to_ringle: (name) => [
      <>
        안녕하세요,
        <br />
        {name}님!{' '}
      </>,
      <>Hi {name}!</>,
      's_moportal.group_a.welcome_to_ringle',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      <>
        진짜 영어를 배울 수 있는
        <br />
        링글 수업을 시작해보세요
      </>,
      <>
        Learn Real English
        <br />
        with Ringle
      </>,
      's_moportal.group_a.title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  group_b: {
    welcome_to_ringle: [
      <>
        체험 수업은
        <br />
        만족스러우셨나요?
      </>,
      <>How was your trial lesson?</>,
      's_moportal.group_b.welcome_to_ringle',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      <>
        이제 원하는 튜터와 교재를 선택하고
        <br />
        정규수업을 시작해보세요!
      </>,
      <>
        At regular lessons you can
        <br />
        choose your tutor & materials
      </>,
      's_moportal.group_b.title',
      ``,
      ``,
      ``,
      ``,
    ],
    purchase: [<>수업권 보러가기</>, <>See pricing</>, 's_moportal.group_b.purchase', ``, ``, ``, ``],
    purchase_sub: [
      <>
        나에게 맞는 수업권을 찾아보기!
        <br />
        심도 깊은 40분, 효율적인 20분
      </>,
      <>
        Find the right package for you
        <br />{' '}
      </>,
      's_moportal.group_b.purchase_sub',
      ``,
      ``,
      ``,
      ``,
    ],
    go_purchase: [<>가격 보러가기</>, <>See pricing</>, 's_moportal.group_b.go_purchase', ``, ``, ``, ``],
    review: [
      <>AI & 튜터가 분석해주는 복습</>,
      <>Feedback from your tutor & AI</>,
      's_moportal.group_b.review',
      ``,
      ``,
      ``,
      ``,
    ],
    review_sub: [
      <>
        수업이 끝나도 사라지지 않는 영어,
        <br />
        링글을 끝까지 경험해보세요!
      </>,
      <>Review your lesson</>,
      's_moportal.group_b.review_sub',
      ``,
      ``,
      ``,
      ``,
    ],
    go_review: [<>앱에서 확인하기</>, <>View on Ringle App</>, 's_moportal.group_b.go_review', ``, ``, ``, ``],
    how_to_ringle: [
      <>링글 사용법 바로가기</>,
      <>How Ringle works</>,
      's_moportal.group_b.how_to_ringle',
      ``,
      ``,
      ``,
      ``,
    ],
    how_to_ringle_sub: [
      <>언제 어디서나, 원하는 수업을 자유롭게</>,
      <>Learn whenever and wherever</>,
      's_moportal.group_b.how_to_ringle_sub',
      ``,
      ``,
      ``,
      ``,
    ],
    go_how_to_ringle: [
      <>간단한 사용법 보러가기</>,
      <>How Ringle works</>,
      's_moportal.group_b.go_how_to_ringle',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  group_c: {
    welcome_to_ringle: (name) => [
      <>
        안녕하세요,
        <br />
        {name}님!{' '}
      </>,
      <>Hi {name}!</>,
      's_moportal.group_c.welcome_to_ringle',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      <>
        링글 앱을 사용하면
        <br />
        손쉽게 수업을 예습하고 복습할 수 있어요!
      </>,
      <>Prep for your lessons via Ringe App!</>,
      's_moportal.group_c.title',
      ``,
      ``,
      ``,
      ``,
    ],
    schedule: [<>수업 예약하기</>, <>Book Lessons</>, 's_moportal.group_c.schedule', ``, ``, ``, ``],
    schedule_sub: [
      <>
        원하는 튜터와 교재를 선택하고
        <br />
        정규수업을 시작해보세요!
      </>,
      <>
        At regular lessons you can
        <br />
        choose your tutor & materials
      </>,
      's_moportal.group_c.schedule_sub',
      ``,
      ``,
      ``,
      ``,
    ],
    go_schedule: [<>앱에서 확인하기</>, <>View on Ringle App</>, 's_moportal.group_c.go_schedule', ``, ``, ``, ``],
    tutor: [<>튜터 프로필 보러가기</>, <>View Tutors</>, 's_moportal.group_c.tutor', ``, ``, ``, ``],
    tutor_sub: [
      <>
        1000+명 이상 검증된 <span style={{ color: '#7A5DE8' }}>영미권 명문대 원어민 튜터</span>를 만나보세요!
      </>,
      <>Choose from 1000+ Tutors!</>,
      's_moportal.group_c.tutor_sub',
      ``,
      ``,
      ``,
      ``,
    ],
    go_tutor: [<>앱에서 확인하기</>, <>View on Ringle App</>, 's_moportal.group_c.go_tutor', ``, ``, ``, ``],
    materials: [<>링글 교재 보러가기</>, <>View Materials</>, 's_moportal.group_c.materials', ``, ``, ``, ``],
    materials_sub: [
      <>일상 대화부터 시사, 실전 비즈니스 영어까지</>,
      <>Master your English from casual to advanced.</>,
      's_moportal.group_c.materials_sub',
      ``,
      ``,
      ``,
      ``,
    ],
    go_materials: [<>앱에서 확인하기</>, <>View on Ringle App</>, 's_moportal.group_c.go_materials', ``, ``, ``, ``],
  },
}
export const t_dashboard = {
  credit: [`수업권 관리`, `Plans & Credits`, 't_dashboard.credit', ``, ``, ``, ``],
  child_account_settings: [`자녀 계정 관리`, `Child Account`, 't_dashboard.child_account_settings', ``, ``, ``, ``],
  my_account_settings: [`내 계정관리`, `My Account`, 't_dashboard.my_account_settings', ``, ``, ``, ``],
  dashboard: [`대시보드`, `Dashboard`, 't_dashboard.dashboard', ``, ``, ``, ``],
  parent: [`부모`, `Parent`, 't_dashboard.parent', ``, ``, ``, ``],
  child_password: [`자녀 비밀번호 변경`, `Password`, 't_dashboard.child_password', ``, ``, ``, ``],
  child_timezone: [`자녀 타임존 변경`, `Timezone`, 't_dashboard.child_timezone', ``, ``, ``, ``],
  child_profile_title: [`자녀 프로필 설정`, `Profile`, 't_dashboard.child_profile_title', ``, ``, ``, ``],
  child_notification: [
    `자녀 알림 수신 설정`,
    `Notification Settings`,
    't_dashboard.child_notification',
    ``,
    ``,
    ``,
    ``,
  ],
  child_basic_info: [`자녀 기본 정보`, `Child Profile`, 't_dashboard.child_basic_info', ``, ``, ``, ``],
  go_to_study_place: [`학습공간 가기`, `Enter Learning Space`, 't_dashboard.go_to_study_place', ``, ``, ``, ``],
  validate_year: [
    `태어난 년도를 정확하게 입력해주세요.`,
    `Please enter the year you were born in.`,
    't_dashboard.validate_year',
    ``,
    ``,
    ``,
    ``,
  ],
  validate_month: [
    `태어난 월을 정확하게 입력해주세요.`,
    `Please enter the month you were born in.`,
    't_dashboard.validate_month',
    ``,
    ``,
    ``,
    ``,
  ],
  validate_day: [
    `태어난 일을 정확하게 입력해주세요.`,
    `Please enter the day you were born on.`,
    't_dashboard.validate_day',
    ``,
    ``,
    ``,
    ``,
  ],
  menu: {
    home: [`홈`, `Home`, 't_dashboard.menu.home', ``, ``, ``, ``],
    plans_credits: {
      default: [`수업권`, `Credits`, 't_dashboard.menu.plans_credits.default', ``, ``, ``, ``],
      myplans: [`내 요금제`, `My Plans`, 't_dashboard.menu.plans_credits.myplans', ``, ``, ``, ``],
      credits_history: [
        `크레딧 & 기록`,
        `Credits & History`,
        't_dashboard.menu.plans_credits.credits_history',
        ``,
        ``,
        ``,
        ``,
      ],
      points: [`포인트`, `Points`, 't_dashboard.menu.plans_credits.points', ``, ``, ``, ``],
    },
    child_account_settings: [
      `자녀 계정 관리`,
      `Child Account`,
      't_dashboard.menu.child_account_settings',
      ``,
      ``,
      ``,
      ``,
    ],
    my_account_settings: [`내 계정관리`, `My Account`, 't_dashboard.menu.my_account_settings', ``, ``, ``, ``],
    banner: {
      title: [
        <>
          교재를 선택하는데 도움이
          <br />
          필요하신가요?
        </>,
        `Can we assist you in choosing the right materials?`,
        't_dashboard.menu.banner.title',
        ``,
        ``,
        ``,
        ``,
      ],
      button: [`가이드 보기`, `View guide`, 't_dashboard.menu.banner.button', ``, ``, ``, ``],
    },
  },
  navi: {
    dashboard: [`대시보드`, `Dashboard`, 't_dashboard.navi.dashboard', ``, ``, ``, ``],
    buynow: [`구매`, `Buy now`, 't_dashboard.navi.buynow', ``, ``, ``, ``],
    profilemenu: {
      title1: [`학부모 대시보드`, `Parent Dashboard`, 't_dashboard.navi.profilemenu.title1', ``, ``, ``, ``],
      title2: [`자녀 계정`, `Child Account`, 't_dashboard.navi.profilemenu.title2', ``, ``, ``, ``],
      plans: [`요금제 & 지불방식`, `Plans & Payment`, 't_dashboard.navi.profilemenu.plans', ``, ``, ``, ``],
      noti: [`알림 수신 설정`, `Notification Settings`, 't_dashboard.navi.profilemenu.noti', ``, ``, ``, ``],
      faq: [`자주 묻는 질문`, `FAQs`, 't_dashboard.navi.profilemenu.faq', ``, ``, ``, ``],
      installzoom: [
        `화상 프로그램 (Zoom) 설치 가이드`,
        `How to Install Zoom`,
        't_dashboard.navi.profilemenu.installzoom',
        ``,
        ``,
        ``,
        ``,
      ],
      logout: [`로그아웃`, `Log out`, 't_dashboard.navi.profilemenu.logout', ``, ``, ``, ``],
    },
  },
  credits_payment: {
    title: [`수업권 & 포인트`, `Credits & Points`, 't_dashboard.credits_payment.title', ``, ``, ``, ``],
    credits1: {
      default: [`정규수업권`, `Regular`, 't_dashboard.credits_payment.credits1.default', ``, ``, ``, ``],
      inform: [
        `당일 수업을 포함하여 모든 수업 예약이 
가능한 수업권입니다.`,
        <>
          You can book any lessons available
          <br />
          including last-minute lessons.
        </>,
        't_dashboard.credits_payment.credits1.inform',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    credits2: {
      default: [`보충수업권`, `Make-up`, 't_dashboard.credits_payment.credits2.default', ``, ``, ``, ``],
      inform: [
        `24시간 이내 시작하는 당일 수업만
예약할 수 있는 수업권입니다.`,
        <>
          You can only book last-minute lessons
          <br />
          starting within the next 24 hours
        </>,
        't_dashboard.credits_payment.credits2.inform',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    button1: [`자세히 보기`, `View detail`, 't_dashboard.credits_payment.button1', ``, ``, ``, ``],
    button2: [`결제방식`, `Manage payment`, 't_dashboard.credits_payment.button2', ``, ``, ``, ``],
    nextpayment: [`다음 결제일은`, `Next payment on`, 't_dashboard.credits_payment.nextpayment', ``, ``, ``, ``],
    card: [`비자카드 끝자리`, `Visa card Ending`, 't_dashboard.credits_payment.card', ``, ``, ``, ``],
    startpayment: {
      default: [
        `월간 요금제 시작`,
        `Start a Monthly Plan`,
        't_dashboard.credits_payment.startpayment.default',
        ``,
        ``,
        ``,
        ``,
      ],
      inform: [
        `요금제 선택하고 링글 틴즈 수업 듣기`,
        `Choose a plan to begin Ringle Lessons`,
        't_dashboard.credits_payment.startpayment.inform',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  book_lesson: {
    title: [`수업 예약`, `Book Lessons`, 't_dashboard.book_lesson.title', ``, ``, ``, ``],
    sortbytime: {
      title: [`시간 먼저 선택`, `Sort by time`, 't_dashboard.book_lesson.sortbytime.title', ``, ``, ``, ``],
      infom: [
        `원하는 수업 시간을 먼저 선택할 수 있습니다.`,
        `See available tutors for selected time`,
        't_dashboard.book_lesson.sortbytime.infom',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    sortbytutor: {
      title: [`튜터 먼저 선택`, `Sort by tutor`, 't_dashboard.book_lesson.sortbytutor.title', ``, ``, ``, ``],
      infom: [
        `원하는 튜터를 먼저 선택할 수 있습니다.`,
        `See available slots of selected tutors`,
        't_dashboard.book_lesson.sortbytutor.infom',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    sameday: {
      title: [`예약 가능한 당일수업`, `Last-min Lesson`, 't_dashboard.book_lesson.sameday.title', ``, ``, ``, ``],
      infom: [
        `24시간 이내에 시작하는 수업을 예약할 수 있습니다.`,
        `Book with Regular, Last-minute lesson credits`,
        't_dashboard.book_lesson.sameday.infom',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    banner: {
      title: [
        `교재를 선택하는데 도움이 필요하신가요?`,
        `Need help choosing materials?`,
        't_dashboard.book_lesson.banner.title',
        ``,
        ``,
        ``,
        ``,
      ],
      button: [`가이드 보기`, `View guide`, 't_dashboard.book_lesson.banner.button', ``, ``, ``, ``],
    },
  },
  upcoming_lesson: {
    title: [`예정된 수업`, `Upcoming Lesson`, 't_dashboard.upcoming_lesson.title', ``, ``, ``, ``],
    viewall: [`전체보기`, `View all`, 't_dashboard.upcoming_lesson.viewall', ``, ``, ``, ``],
    no_lesson: [
      `현재 예정되어 있는 수업이 없습니다.`,
      `You have no upcoming lessons.`,
      't_dashboard.upcoming_lesson.no_lesson',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  past_lesson: {
    title: [`지난 수업`, `Past Lesson`, 't_dashboard.past_lesson.title', ``, ``, ``, ``],
    viewall: [`전체보기`, `View all`, 't_dashboard.past_lesson.viewall', ``, ``, ``, ``],
    no_lesson: [
      `지난 수업 기록이 없습니다`,
      `You have no past lesson history.`,
      't_dashboard.past_lesson.no_lesson',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  upcoming_lesson_page: {
    title: [`예정된 수업`, `Upcoming Lessons`, 't_dashboard.upcoming_lesson_page.title', ``, ``, ``, ``],
    button1: [`더보기`, `See more`, 't_dashboard.upcoming_lesson_page.button1', ``, ``, ``, ``],
  },
  past_lesson_page: {
    title: [`지난 수업`, `Past Lessons`, 't_dashboard.past_lesson_page.title', ``, ``, ``, ``],
    button1: [`더보기`, `See more`, 't_dashboard.past_lesson_page.button1', ``, ``, ``, ``],
    dropdown: {
      all: [`전체`, `All`, 't_dashboard.past_lesson_page.dropdown.all', ``, ``, ``, ``],
      list_1: [
        `복습을 완료하지 않은 수업`,
        `Lessons you haven’t reviewed yet`,
        't_dashboard.past_lesson_page.dropdown.list_1',
        ``,
        ``,
        ``,
        ``,
      ],
      list_2: [
        `복습을 완료한 수업`,
        `Lessons you have reviewed`,
        't_dashboard.past_lesson_page.dropdown.list_2',
        ``,
        ``,
        ``,
        ``,
      ],
      list_3: [`노쇼`, `No-show`, 't_dashboard.past_lesson_page.dropdown.list_3', ``, ``, ``, ``],
    },
  },
  plans_credits: {
    title: [`요금제 & 크레딧`, `Plans & Credits`, 't_dashboard.plans_credits.title', ``, ``, ``, ``],
    my_plans: [`내 요금제`, `My Plans`, 't_dashboard.plans_credits.my_plans', ``, ``, ``, ``],
    credits_history: [
      `크레딧 & 기록`,
      `Credits & History`,
      't_dashboard.plans_credits.credits_history',
      ``,
      ``,
      ``,
      ``,
    ],
    points: [`포인트`, `Points`, 't_dashboard.plans_credits.points', ``, ``, ``, ``],
  },
  materials_banner: {
    title: [
      <>
        적합한 교재를 선택하는데 도움이
        <br />
        필요하신가요?
      </>,
      `Can we assist you in choosing the right materials?`,
      't_dashboard.materials_banner.title',
      ``,
      ``,
      ``,
      ``,
    ],
    button: [`가이드 보기`, `View guide`, 't_dashboard.materials_banner.button', ``, ``, ``, ``],
  },
  add_child: {
    step1: {
      exit: [`나가기`, `Exit`, 't_dashboard.add_child.step1.exit', ``, ``, ``, ``],
      page_title: [`자녀 추가`, `Add Child`, 't_dashboard.add_child.step1.page_title', ``, ``, ``, ``],
      page_subtitle: [
        `STEP 1. 자녀 기본 정보`,
        `STEP 1. Child Basic Info`,
        't_dashboard.add_child.step1.page_subtitle',
        ``,
        ``,
        ``,
        ``,
      ],
      title: [
        `자녀의 기본 정보를 입력해 주세요.`,
        `Enter your child's basic info.`,
        't_dashboard.add_child.step1.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc1: [`자녀 영어 이름`, `Child’s Name`, 't_dashboard.add_child.step1.desc1', ``, ``, ``, ``],
      desc2: [`자녀 생년월일`, `Child’s Birthday`, 't_dashboard.add_child.step1.desc2', ``, ``, ``, ``],
      desc3: [`타임존`, `Time Zone`, 't_dashboard.add_child.step1.desc3', ``, ``, ``, ``],
      placeholder1: [`이름`, `First Name`, 't_dashboard.add_child.step1.placeholder1', ``, ``, ``, ``],
      placeholder2: [`성`, `Last Name`, 't_dashboard.add_child.step1.placeholder2', ``, ``, ``, ``],
      placeholder3: [
        `자녀계정 이메일 (ID)`,
        `Email (ID) for child’s account`,
        't_dashboard.add_child.step1.placeholder3',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder4: [
        `자녀 계정 비밀번호 (6자 이상)`,
        `Password for child’s account (At least 6 characters)`,
        't_dashboard.add_child.step1.placeholder4',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder5: [
        `비밀번호를 한번 더 입력해 주세요.`,
        `Re-enter password`,
        't_dashboard.add_child.step1.placeholder5',
        ``,
        ``,
        ``,
        ``,
      ],
      mm: [`월`, `MM`, 't_dashboard.add_child.step1.mm', ``, ``, ``, ``],
      dd: [`일`, `dd`, 't_dashboard.add_child.step1.dd', ``, ``, ``, ``],
      yyyy: [`연도`, `yyyy`, 't_dashboard.add_child.step1.yyyy', ``, ``, ``, ``],
      title2: [
        `자녀가 직접 로그인을 할 수 있도록 자녀 계정을 만드시겠어요?`,
        `Do you wish your child to have their own login credentials?`,
        't_dashboard.add_child.step1.title2',
        ``,
        ``,
        ``,
        ``,
      ],
      subtitle2: [
        <>
          자녀 계정이 없더라도
          <br />
          부모 계정을 통해 링글 틴즈를 사용할 수 있습니다.
        </>,
        <>
          Your child(s) can access Ringle Teens using a parent’s login
          <br />
          credentials without their own login credentials.
        </>,
        't_dashboard.add_child.step1.subtitle2',
        ``,
        ``,
        ``,
        ``,
      ],
      radio1: [`예`, `Yes`, 't_dashboard.add_child.step1.radio1', ``, ``, ``, ``],
      radio2: [`아니오`, `No`, 't_dashboard.add_child.step1.radio2', ``, ``, ``, ``],
      msg1: [
        `올바른 이메일을 입력해 주세요.`,
        `Please enter email correctly`,
        't_dashboard.add_child.step1.msg1',
        ``,
        ``,
        ``,
        ``,
      ],
      msg2: [
        `8자 이상의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요.`,
        `Enter at least 8 characters, with uppercase, lowercase, numbers, and special characters.`,
        't_dashboard.add_child.step1.msg2',
        ``,
        ``,
        ``,
        ``,
      ],
      msg3: [
        `비밀번호를 확인해 주세요.`,
        `Please confirm your password`,
        't_dashboard.add_child.step1.msg3',
        ``,
        ``,
        ``,
        ``,
      ],
      tooltip: [
        `부모 대시보드에서 다른 자녀의 정보를 <br>추가하실 수 있습니다. 단, 무료 수업권은 부모 계정에 한 개만 지급된다는 점을 유의해주세요.`,
        <>
          You can add another child’s account later in the dashboard.
          <br />
          But be sure that only one free lesson is available per parent.
        </>,
        't_dashboard.add_child.step1.tooltip',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    step2: {
      toast: [
        `자녀 기본 정보가 입력되었습니다.`,
        `The child's basic information was successfully entered!`,
        't_dashboard.add_child.step2.toast',
        ``,
        ``,
        ``,
        ``,
      ],
      title: (name) => [
        `${name}에 대해서 더 자세히 알려주세요!`,
        `Tell us more about ${name}`,
        't_dashboard.add_child.step2.title',
        ``,
        ``,
        ``,
        ``,
      ],
      subtitle1: (name) => [
        <>
          튜터들이 작성해주신 답변을 통해 <br /> {name}을(를) 잘 이해하고 효과적인 수업을 진행할 수 있습니다.
        </>,
        <>
          These questions will help our tutors better understand {name}
          <br />
          so they can conduct more efficient lessons.
        </>,
        't_dashboard.add_child.step2.subtitle1',
        ``,
        ``,
        ``,
        ``,
      ],
      subtitle2: [
        `입력한 답변은 대시보드에서 언제든지 수정하실 수 있습니다.`,
        `You can edit your answers later in the dashboard.`,
        't_dashboard.add_child.step2.subtitle2',
        ``,
        ``,
        ``,
        ``,
      ],
      question1: (name) => [
        `${name}(이)가 영어를 자유롭게 구사하나요?`,
        `Is English ${name}’s primary language?`,
        't_dashboard.add_child.step2.question1',
        ``,
        ``,
        ``,
        ``,
      ],
      question2: (name) => [
        <>
          {name}(이)가 링글 틴즈에서
          <br />
          어떤 능력이 성장하길 원하시나요? (복수 선택 가능)
        </>,
        <>
          What do you hope for {name} to accomplish using
          <br /> Ringle Teens? (multiple choice)
        </>,
        't_dashboard.add_child.step2.question2',
        ``,
        ``,
        ``,
        ``,
      ],
      question3: (name) => [
        <>
          {name}에게 딱 맞는 수업 교재를 추천할 수 있도록
          <br />
          교재 주제를 선택해주세요 (세개 이상)
        </>,
        <>
          Would you select topics for us to recommend
          <br />
          {name}’s lesson materials? (3 and more)
        </>,
        't_dashboard.add_child.step2.question3',
        ``,
        ``,
        ``,
        ``,
      ],
      radio1: [`예`, `Yes`, 't_dashboard.add_child.step2.radio1', ``, ``, ``, ``],
      radio2: [`아니오`, `No`, 't_dashboard.add_child.step2.radio2', ``, ``, ``, ``],
      checkbox1: [`비판적 사고 능력`, `Critical Thinking`, 't_dashboard.add_child.step2.checkbox1', ``, ``, ``, ``],
      checkbox2: [`창의적 사교 능력`, `Creative Thinking`, 't_dashboard.add_child.step2.checkbox2', ``, ``, ``, ``],
      checkbox3: [`소통 능력`, `Communication`, 't_dashboard.add_child.step2.checkbox3', ``, ``, ``, ``],
      checkbox4: [`집중력`, `Concentration`, 't_dashboard.add_child.step2.checkbox4', ``, ``, ``, ``],
      btn1: [`자녀 추가 완료`, `Complete`, 't_dashboard.add_child.step2.btn1', ``, ``, ``, ``],
    },
    modal_title: [`자녀 추가 나가기`, `Leave Add Child`, 't_dashboard.add_child.modal_title', ``, ``, ``, ``],
    modal_desc: [
      `아직 자녀 추가하기 진행 중입니다. 자녀 추가를 취소하고 나가시겠습니까? 현재까지 입력한 정보는 저장되지 않습니다.`,
      `You haven’t completed adding your child yet. All information entered will be deleted if you exit now. Are you sure you want to exit?`,
      't_dashboard.add_child.modal_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_btn1: [`자녀 추가 진행`, `Keep Adding Child`, 't_dashboard.add_child.modal_btn1', ``, ``, ``, ``],
    modal_btn2: [`취소하고 나가기`, `Cancel & Exit`, 't_dashboard.add_child.modal_btn2', ``, ``, ``, ``],
    toast_complete: [
      `자녀 추가가 완료되었습니다.`,
      `Adding child was successfully completed`,
      't_dashboard.add_child.toast_complete',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  book_lwt: {
    title: [`Let’s Write Together`, `Let's Write Together`, 't_dashboard.book_lwt.title', ``, ``, ``, ``],
    btn1: [`더 알아보기`, `Learn More`, 't_dashboard.book_lwt.btn1', ``, ``, ``, ``],
    btn2: [`수업 신청하기`, `Schedule Lesson`, 't_dashboard.book_lwt.btn2', ``, ``, ``, ``],
    modal_title: [
      `Let’s Write Together란 무엇일까요?`,
      `Let's Write Together`,
      't_dashboard.book_lwt.modal_title',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_body: [
      `링글 틴즈의 심화 Writing 프로그램으로 한 튜터와 40분 수업을 3번 진행하며 한 편의 글을 함께 완성하는 수업입니다.`,
      `Let’s Write Together is a writing program for students to write an essay with a tutor during the course of three 40-minute lessons.`,
      't_dashboard.book_lwt.modal_body',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_btn1: [`닫기`, `Close`, 't_dashboard.book_lwt.modal_btn1', ``, ``, ``, ``],
    modal_btn2: [`더 알아보기`, `Learn More`, 't_dashboard.book_lwt.modal_btn2', ``, ``, ``, ``],
  },
  lesson_lwt: {
    modal_title: [
      `수업 변경/취소`,
      `Reschedule or Cancel Lessons`,
      't_dashboard.lesson_lwt.modal_title',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_body: [
      <>
        Let's Write Together 수업은 현재 소수의 튜터들에 의해서만 진행되기 때문에 실시간으로 수업의 일정을 변경하거나
        취소가 어렵습니다. 수업 일정 변경 또는 취소가 링글팀에 연락해주세요. (응답 가능 시간: 한국 시간 월-금
        10:00-18:00시)
        <br />
        <br />
        전화번호: +82 10-4599-3431
        <br />
        카카오톡 ID: http://pf.kakao.com/_SKVxexj
      </>,
      <>
        Because Let’s Write Together is a new program with a smaller selection of tutors, real time lesson rescheduling
        and cancellations are currently restricted. If you need to reschedule or cancel your lesson, please contact us
        by phone or KakaoTalk. (Business hours: Mon.-Fri. 10:00-18:00 KST)
        <br />
        <br />
        Phone: +82 10-4599-3431
        <br />
        KakaoTalk ID: http://pf.kakao.com/_SKVxexj
      </>,
      't_dashboard.lesson_lwt.modal_body',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_btn1: [`확인`, `Ok`, 't_dashboard.lesson_lwt.modal_btn1', ``, ``, ``, ``],
  },
  child_s: [`의`, `’s`, 't_dashboard.child_s', ``, ``, ``, ``],
  credits_points: {
    title: [`수업권·포인트`, `Credits & Points`, 't_dashboard.credits_points.title', ``, ``, ``, ``],
    placeholder: [
      `수업권을 구매하고 수업을 시작하세요!`,
      `Buy credits to begin Ringle Lessons!`,
      't_dashboard.credits_points.placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`수업권 구매하기`, `Go to Purchase`, 't_dashboard.credits_points.btn', ``, ``, ``, ``],
    point: [`보유 포인트`, `Your points`, 't_dashboard.credits_points.point', ``, ``, ``, ``],
    point_expire: [
      `7일 이내 만료 예정 포인트`,
      `Points expires in 7 days`,
      't_dashboard.credits_points.point_expire',
      ``,
      ``,
      ``,
      ``,
    ],
    view_detail: [`자세히 보기`, `View detail`, 't_dashboard.credits_points.view_detail', ``, ``, ``, ``],
  },
  child_basic_information: [
    `자녀 기본 정보`,
    `Child’s Basic Information`,
    't_dashboard.child_basic_information',
    ``,
    ``,
    ``,
    ``,
  ],
  child_additional_information: [
    `자녀 추가 정보`,
    `Child’s Additional Information`,
    't_dashboard.child_additional_information',
    ``,
    ``,
    ``,
    ``,
  ],
  section_child: {
    btn_text: [`자녀 추가`, `Add Child`, 't_dashboard.section_child.btn_text', ``, ``, ``, ``],
    tooltip: [
      <>
        자녀의 학습 포털에서 링글 튜터와
        <br />
        교재를 확인해보세요.
      </>,
      <>
        Go to your child's learning portal
        <br />
        and check out our tutors and materials.
      </>,
      't_dashboard.section_child.tooltip',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_dashboard: [`학습 포털로 이동`, `Go to Portal`, 't_dashboard.section_child.btn_dashboard', ``, ``, ``, ``],
    title_book: (child_name) => [
      <>{child_name}의 수업 예약하기</>,
      <>Book a lesson for {child_name}!</>,
      't_dashboard.section_child.title_book',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  banner_trial: {
    timer: [`{amount}일{amount} 남음`, ``, 't_dashboard.banner_trial.timer', ``, ``, ``, ``],
    title: [
      `지금 무료 체험 수업을 예약하세요!`,
      `Book a free trial class right now!`,
      't_dashboard.banner_trial.title',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`체험 수업 예약하기`, `Book a free trial class`, 't_dashboard.banner_trial.btn', ``, ``, ``, ``],
  },
  banner_call: {
    title: [
      <>
        상담을 통해
        <br />
        무료 체험 수업을 예약하세요.
      </>,
      <>
        Contact us to schedule a<br />
        free trial class
      </>,
      't_dashboard.banner_call.title',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`상담 신청하기`, `Contact Ringle Teens team`, 't_dashboard.banner_call.btn', ``, ``, ``, ``],
  },
  remain: {
    days: (day) => [
      `${day}일`,
      `${day} days`,
      't_dashboard.remain.days',
      `${day}日`,
      `${day}天`,
      `${day}日`,
      `${day} ngày`,
    ],
  },
  banner_points: {
    timer: (amount) => [
      `${amount}일 ${amount} 남음`,
      `${amount}day(s) ${amount}left`,
      't_dashboard.banner_points.timer',
      ``,
      ``,
      ``,
      ``,
    ],
    title: (amount) => [
      `${amount} 포인트가 곧 사라집니다.`,
      `Points received as Special Gift expires soon.`,
      't_dashboard.banner_points.title',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`포인트 사용하기`, `Use Points`, 't_dashboard.banner_points.btn', ``, ``, ``, ``],
  },
  banner: {
    txt: (child_name) => [
      <>{child_name}님과 딱 맞는 튜터와 교재를 확인해보세요.</>,
      <>Find the best-suited tutors and materials for {child_name}.</>,
      't_dashboard.banner.txt',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const t_my_original = {
  taught_material: [`수업한 교재`, `Learned Materials`, 't_my_original.taught_material', ``, ``, ``, ``],
  favorite_material: [`찜한 교재`, `Favorite Materials`, 't_my_original.favorite_material', ``, ``, ``, ``],
  my_materials: [`내 교재`, `My Materials`, 't_my_original.my_materials', ``, ``, ``, ``],
  check_exp_course: [
    <>
      내가 수업한 교재를 <br /> 여기서 확인할 수 있습니다.
    </>,
    `See your previous materials.`,
    't_my_original.check_exp_course',
    ``,
    ``,
    ``,
    ``,
  ],
  favorite_material_caption: [
    `다시 보고 싶은 교재를 찜해보세요.`,
    `Add your favorite materials!`,
    't_my_original.favorite_material_caption',
    ``,
    ``,
    ``,
    ``,
  ],
  taught_material_caption: [
    <>
      수업한 교재를
      <br />
      여기에서 확인할 수 있어요.
    </>,
    `See your previous materials.`,
    't_my_original.taught_material_caption',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_menu = {
  parent_dashboard: [`부모 대시보드`, `Parent Dashboard`, 't_menu.parent_dashboard', ``, ``, ``, ``],
  child_space: [`자녀 학습 포털`, `Student Learning Portal`, 't_menu.child_space', ``, ``, ``, ``],
  landing: {
    book_a_call: [`상담 신청`, `Book a call`, 't_menu.landing.book_a_call', ``, ``, ``, ``],
    sign_up: [`회원가입`, `Sign Up`, 't_menu.landing.sign_up', ``, ``, ``, ``],
  },
  change_language: [`언어 변경`, `Change Language`, 't_menu.change_language', ``, ``, ``, ``],
  learning_portal: [`학습 포털`, `Learning Portal`, 't_menu.learning_portal', ``, ``, ``, ``],
  modal_timezone_title: [`타임존 변경`, `Change Timezone`, 't_menu.modal_timezone_title', ``, ``, ``, ``],
  modal_timezone_desc: [
    `현재 위치와 타임존이 다른 경우, 도시 이름을 입력하거나 리스트에서 선택해주세요.`,
    `Enter a city name or select from the list to change the time zone.`,
    't_menu.modal_timezone_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_timezone_btn: [`저장`, `Save`, 't_menu.modal_timezone_btn', ``, ``, ``, ``],
  btn_event: [`봄학기 프로그램`, `Spring Program`, 't_menu.btn_event', ``, ``, ``, ``],
}
export const t_home = {
  todo_book_lesson: [
    `수업을 예약하고 영어 실력을 키워보아요! 💪`,
    `Book a Lesson and develop your 4Cs!💪`,
    't_home.todo_book_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  todo_all_done: [
    <>
      최고!
      <br />
      해야할 일을 모두 끝냈어요! 🥳{' '}
    </>,
    <>
      Woohoo!
      <br />
      You're done for today! 🥳{' '}
    </>,
    't_home.todo_all_done',
    ``,
    ``,
    ``,
    ``,
  ],
  recommended_tutor: [`추천 튜터`, `Recommended Tutor`, 't_home.recommended_tutor', ``, ``, ``, ``],
  check_out_tutors_recommended_by_ringle_users_: [
    `링글 틴즈 수강생이 추천하는 튜터를 확인해 보세요.`,
    `Check out tutors recommended by Ringle Teens users.`,
    't_home.check_out_tutors_recommended_by_ringle_users_',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_todo = {
  all_todo_list: [`앞으로의 모든 학습 리스트입니다`, `Here are your to-do's.`, 't_todo.all_todo_list', ``, ``, ``, ``],
  prep_due: [`예습 Due`, `Lesson prep due`, 't_todo.prep_due', ``, ``, ``, ``],
  lesson_start_time: [`수업 시작 시간`, `Lesson schedule`, 't_todo.lesson_start_time', ``, ``, ``, ``],
  homework_due: [`숙제 Due`, `Homework due`, 't_todo.homework_due', ``, ``, ``, ``],
  after_1_hour: [`수업이 끝나고 1시간 후`, `An hour after Lesson`, 't_todo.after_1_hour', ``, ``, ``, ``],
  review_due: [`복습 Due`, `Review due`, 't_todo.review_due', ``, ``, ``, ``],
  after_7_days: [`수업이 끝나고 7일 후`, `7 days after Lesson`, 't_todo.after_7_days', ``, ``, ``, ``],
  complete: (n) => [<>완료 ({n}개)</>, <>{n} completed</>, 't_todo.complete', ``, ``, ``, ``],
  incomplete: (n) => [<>미완료 ({n}개)</>, <>{n} not completed</>, 't_todo.incomplete', ``, ``, ``, ``],
}
export const t_homework = {
  submit_homework_and_check_corrections: [
    <>
      <b>숙제 페이지</b>에서는 숙제의 확인 및 제출, <br />
      그리고 제출한 숙제에 대한 튜터의 교정안을 확인할 수 있습니다.
    </>,
    `Submit homework and check the tutor's corrections at the homework tab`,
    't_homework.submit_homework_and_check_corrections',
    ``,
    ``,
    ``,
    ``,
  ],
  past_due: [
    <>
      제출 기한이
      <br />
      지났습니다
    </>,
    `Submission deadline passed`,
    't_homework.past_due',
    ``,
    ``,
    ``,
    ``,
  ],
  wait_tutors_paraphrasing: [
    <>
      튜터의 교정안을
      <br />
      기다려주세요
    </>,
    `Waiting for tutor's correction`,
    't_homework.wait_tutors_paraphrasing',
    ``,
    ``,
    ``,
    ``,
  ],
  read_tutors_paraphrasing: [
    <>
      튜터의 교정안을
      <br />
      읽어보세요
    </>,
    `Check tutor's correction`,
    't_homework.read_tutors_paraphrasing',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_paraphrasing: [`튜터의 교정안`, `Tutor's correction`, 't_homework.tutor_paraphrasing', ``, ``, ``, ``],
  my_answer: [`나의 답변`, `My Answer`, 't_homework.my_answer', ``, ``, ``, ``],
  check_tutor_paraphrasing: [
    `튜터 교정안 확인하기 완료`,
    <>
      Done checking
      <br />
      Tutor's correction
    </>,
    't_homework.check_tutor_paraphrasing',
    ``,
    ``,
    ``,
    ``,
  ],
  not_submitted: [`미제출`, `Not submitted`, 't_homework.not_submitted', ``, ``, ``, ``],
  submit_before: [`제출 전`, `Before submission`, 't_homework.submit_before', ``, ``, ``, ``],
  submit_completed: [`제출 완료`, `Submission complete`, 't_homework.submit_completed', ``, ``, ``, ``],
  tutor_paraphrasing2: [`튜터 교정안`, `Tutor's correction`, 't_homework.tutor_paraphrasing2', ``, ``, ``, ``],
  paraphrasing_time: [`교정 일시`, `Correction Date`, 't_homework.paraphrasing_time', ``, ``, ``, ``],
  my_answer_placeholder: (min_word_count, max_word_count) => [
    <>
      답변을 최소 {min_word_count}단어, 최대 {max_word_count}단어 이내로 작성해주세요.
    </>,
    <>
      Please respond in {min_word_count} to {max_word_count} words
    </>,
    't_homework.my_answer_placeholder',
    ``,
    ``,
    ``,
    ``,
  ],
  submit_due: [`제출 기한`, `Submission Deadline`, 't_homework.submit_due', ``, ``, ``, ``],
  remaintime_until_due: (remainTime) => [
    <Div>
      제출까지
      <br />
      <Div spanTag textPrimary fontBold>
        {remainTime}분
      </Div>{' '}
      남았습니다
    </Div>,
    `${remainTime} left until submission deadline`,
    't_homework.remaintime_until_due',
    ``,
    ``,
    ``,
    ``,
  ],
  hw_submittted: [`숙제를 제출했습니다`, `Submission complete`, 't_homework.hw_submittted', ``, ``, ``, ``],
  temp_saved: [`임시 저장 되었습니다.`, `Draft saved`, 't_homework.temp_saved', ``, ``, ``, ``],
  submit_hw: [`숙제 제출하기`, `Submit homework`, 't_homework.submit_hw', ``, ``, ``, ``],
  sure_final_submit: [
    <>
      숙제 제출 후에는 수정이 불가능합니다.
      <br />
      제출하시겠습니까?
    </>,
    `Homework cannot be edited after submission. Would you like to submit?`,
    't_homework.sure_final_submit',
    ``,
    ``,
    ``,
    ``,
  ],
  see_prep_btn: [`예습 보기`, `See Lesson Prep`, 't_homework.see_prep_btn', ``, ``, ``, ``],
  checked_tutor_para: [
    `튜터의 교정을 확인하였습니다.`,
    `Done checking Tutor's correction`,
    't_homework.checked_tutor_para',
    ``,
    ``,
    ``,
    ``,
  ],
  want_to_temp_save: [
    `지금까지 작성한 답변을 임시 저장하시겠습니까? 저장하지 않으면 작성한 답변이 사라집니다.`,
    `Do you want to save a draft of your response? Otherwise, your answer will not be restored.`,
    't_homework.want_to_temp_save',
    ``,
    ``,
    ``,
    ``,
  ],
  hw_question: [`숙제 질문`, `Homework question`, 't_homework.hw_question', ``, ``, ``, ``],
  temp_save: [`임시 저장`, `Save draft`, 't_homework.temp_save', ``, ``, ``, ``],
  submit: [`제출하기`, `Submit`, 't_homework.submit', ``, ``, ``, ``],
  cancel: [`취소`, `Cancel`, 't_homework.cancel', ``, ``, ``, ``],
  submission_date: [`제출 일시`, `Submission date`, 't_homework.submission_date', ``, ``, ``, ``],
  remaintime_until_due_in_15min: (remainTime) => [
    <Div>
      제출까지
      <br />
      <Div spanTag textDanger fontBold>
        {remainTime}분
      </Div>{' '}
      남았습니다
    </Div>,
    `${remainTime} left before submission`,
    't_homework.remaintime_until_due_in_15min',
    ``,
    ``,
    ``,
    ``,
  ],
  corrected: [`교정완료`, `Corrected`, 't_homework.corrected', ``, ``, ``, ``],
  not_corrected_yet: [`교정중`, `Correcting`, 't_homework.not_corrected_yet', ``, ``, ``, ``],
  minutes_left: (minutes) => [
    `${minutes}분 남음`,
    `${minutes} minutes left`,
    't_homework.minutes_left',
    ``,
    ``,
    ``,
    ``,
  ],
  out_of_n_words: (n) => [`/${n} 단어`, `/${n} word`, 't_homework.out_of_n_words', ``, ``, ``, ``],
  leave_homework: [`숙제 나가기`, `Exit homework`, 't_homework.leave_homework', ``, ``, ``, ``],
  just_leave: [`그냥 나가기`, `Just exit`, 't_homework.just_leave', ``, ``, ``, ``],
  save_and_leave: [`저장하고 나가기`, `Save and exit`, 't_homework.save_and_leave', ``, ``, ``, ``],
  child_answer: [`의 답변`, `s Answer`, 't_homework.child_answer', ``, ``, ``, ``],
  translate: [`한국어로 번역`, ``, 't_homework.translate', ``, ``, ``, ``],
  translate_complete: [
    `Google로 번역되어 튜터의 실제 의도와 다를 수 있음`,
    ``,
    't_homework.translate_complete',
    ``,
    ``,
    ``,
    ``,
  ],
  tutor_comment: [`튜터의 코멘트`, `Tutor's Comment`, 't_homework.tutor_comment', ``, ``, ``, ``],
  msg_save: [
    `답변을 임시로 저장했어요. 숙제 제출 시간이 지나면 자동으로 숙제가 제출됩니다.`,
    ``,
    't_homework.msg_save',
    ``,
    ``,
    ``,
    ``,
  ],
  toast_submit: [`숙제를 제출했어요!`, ``, 't_homework.toast_submit', ``, ``, ``, ``],
  toast_save: [`숙제를 임시 저장했어요.`, ``, 't_homework.toast_save', ``, ``, ``, ``],
  modal_timeover: {
    title: [`숙제 제출 시간이 지났어요.`, ``, 't_homework.modal_timeover.title', ``, ``, ``, ``],
    desc: [
      `숙제 제출 시간이 지나 임시 저장된 숙제가 제출되었어요.`,
      ``,
      't_homework.modal_timeover.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_no_savefile: [
      `숙제 제출 시간이 지났어요. 다음에는 꼭 시간 내 숙제를 제출해 주세요.`,
      ``,
      't_homework.modal_timeover.desc_no_savefile',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const t_preparation = {
  parent_question_no: [
    `부모님은 수업 질문을 선택하실 수 없습니다.`,
    `Parent cannot select questions to discuss in a lesson.`,
    't_preparation.parent_question_no',
    ``,
    ``,
    ``,
    ``,
  ],
  parent_function_no: [
    `부모님께 제공되지 않는 기능입니다.`,
    `This feature is not available to parent`,
    't_preparation.parent_function_no',
    ``,
    ``,
    ``,
    ``,
  ],
  guideline: [`가이드라인`, `Guideline`, 't_preparation.guideline', ``, ``, ``, ``],
}
export const t_edit_profile = {
  intro_place1: [
    `영어로 자신에 대한 소개를 적어주세요.`,
    `Please introduce yourself.`,
    't_edit_profile.intro_place1',
    ``,
    ``,
    ``,
    ``,
  ],
  intro_place2: [
    `영어로 내가 잘하거나 좋아하는 것을 적어주세요.`,
    `Please write what you are good at or like.`,
    't_edit_profile.intro_place2',
    ``,
    ``,
    ``,
    ``,
  ],
  intro_place3: [
    `영어로 나의 꿈에 대해 적어주세요.`,
    `Please write about my dream.`,
    't_edit_profile.intro_place3',
    ``,
    ``,
    ``,
    ``,
  ],
  intro_place4: [
    `영어로 나의 학교 생활에 대해 적어주세요.`,
    `Please write about my school life.`,
    't_edit_profile.intro_place4',
    ``,
    ``,
    ``,
    ``,
  ],
  intro_place5: [
    `영어로 나의 요즘 고민 대해 적어주세요.`,
    `Please write about my current worries.`,
    't_edit_profile.intro_place5',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_portal_home = {
  confirm_delete_tutor: [
    `[튜터 > 내 튜터] 찜한 튜터에서 삭제하시겠습니까?`,
    `Are you sure you want to remove from [Tutors > My Tutors]?`,
    't_portal_home.confirm_delete_tutor',
    ``,
    ``,
    ``,
    ``,
  ],
  like_added: [
    <>[교재 {'>'} 내 교재] 찜한 교재에 추가되었습니다.</>,
    <>Added from Materials {'>'} My Content.</>,
    't_portal_home.like_added',
    ``,
    ``,
    ``,
    ``,
  ],
  like_deleted: [
    <>교재 찜하기가 해제되었습니다.</>,
    <>Removed from Favorite Materials</>,
    't_portal_home.like_deleted',
    ``,
    ``,
    ``,
    ``,
  ],
  confirm_delete: [
    `[교재 > 내 교재] 찜한 교재에서 삭제하시겠습니까?`,
    `Do you want to remove the material from your [Favorite Materials]?`,
    't_portal_home.confirm_delete',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_enter_browser: {
    popup_title: [
      `Browser Issue Detected`,
      `Browser Issue Detected`,
      't_portal_home.lesson_enter_browser.popup_title',
      ``,
      ``,
      ``,
      ``,
    ],
    popup_subtitle: (browserName, browserVer) => [
      <>
        The browser you are using is not supported on Ringle.
        <br /> Current browser: {browserName} / v. {browserVer}
        <br />
        <br />
        If you experience in-lesson tech issues while using this browser, we cannot assist you with any requests related
        to pay adjustments.
        <br />
        <br />
        Would you still like to enter the lesson?
      </>,
      <>
        The browser you are using is not supported on Ringle.
        <br /> Current browser: {browserName} / v. {browserVer}
        <br />
        <br />
        If you experience in-lesson tech issues while using this browser, we cannot assist you with any requests related
        to pay adjustments.
        <br />
        <br />
        Would you still like to enter the lesson?
      </>,
      't_portal_home.lesson_enter_browser.popup_subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    left_btn: [`Enter Lesson`, `Enter Lesson`, 't_portal_home.lesson_enter_browser.left_btn', ``, ``, ``, ``],
    right_btn: [`Download Chrome`, `Download Chrome`, 't_portal_home.lesson_enter_browser.right_btn', ``, ``, ``, ``],
  },
}
export const t_materials = {
  no_result: [`😣  조건에 맞는 교재가 없습니다.`, `😣  No Result`, 't_materials.no_result', ``, ``, ``, ``],
}
export const t_tutors = {
  no_result: [`😣  조건에 맞는 튜터가 없습니다.`, `😣  No Result`, 't_tutors.no_result', ``, ``, ``, ``],
}
export const t_classroom = {
  leave_class: [`수업 나가기`, `Leave Lesson`, 't_classroom.leave_class', ``, ``, ``, ``],
  cancel: [`취소`, `Cancel`, 't_classroom.cancel', ``, ``, ``, ``],
  quit_the_current_class: [
    `현재 진행 중인 수업을 중단하고 나가시겠습니까?`,
    `Are you sure you want to leave this lesson?`,
    't_classroom.quit_the_current_class',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_stop: [`수업 중단`, `Leave Lesson`, 't_classroom.lesson_stop', ``, ``, ``, ``],
  zoom_help: [`Zoom 오류 해결`, `Zoom Troubleshooting`, 't_classroom.zoom_help', ``, ``, ``, ``],
  zoom_tooltip: [
    `Zoom이 열리지 않는다면 이곳을 클릭하세요.`,
    `If Zoom doesn't launch automatically,  
please click here.`,
    't_classroom.zoom_tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_zoom_title: [`Zoom 오류 해결`, `Zoom Troubleshooting`, 't_classroom.modal_zoom_title', ``, ``, ``, ``],
  modal_zoom_tap_2: [`Zoom이 실행되지 않을 때`, `Zoom won’t launch`, 't_classroom.modal_zoom_tap_2', ``, ``, ``, ``],
  modal_zoom_desc_2: [
    `Zoom이 열리지 않는다면 아래의 [Zoom 다시 열기]를 클릭하거나 링글 모바일 앱을 통해 접속해 주세요. 만약 문제가 해결되지 않는다면, 페이지 상단의 지원 요청을 통해 문의를 남겨주세요.`,
    `If Zoom doesn't launch automatically, please click [Relaunch Zoom] below or connect from the Ringle app. If the problem isn't solved, please leave us a message by clicking on Help at the top.`,
    't_classroom.modal_zoom_desc_2',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_zoom_tap_1: [`소리가 들리지 않을 때`, `No sound is coming out`, 't_classroom.modal_zoom_tap_1', ``, ``, ``, ``],
  modal_zoom_desc_1: [
    `Zoom 실행 후 오디오 연결을 아래와 같이 진행해주세요.
https://zoom.us/test 에서 카메라와 오디오를 테스트 할 수 있습니다.`,
    `After Zoom is launched, please connect your audio as follows. You can also test your camera and audio by going to https://zoom.us/test.`,
    't_classroom.modal_zoom_desc_1',
    ``,
    ``,
    ``,
    ``,
  ],
  btn: [`Zoom 다시 열기`, `Relaunch Zoom`, 't_classroom.btn', ``, ``, ``, ``],
}
export const s_lessons_schedule = {
  rules: {
    check_rules: [
      `수업 에티켓을 확인해 주세요!`,
      `Check Our Rules of Conduct`,
      's_lessons_schedule.rules.check_rules',
      ``,
      ``,
      ``,
      ``,
    ],
    set_up: [
      `알맞은 수업 환경 준비하기`,
      `Set up an Environment for Learning`,
      's_lessons_schedule.rules.set_up',
      ``,
      ``,
      ``,
      ``,
    ],
    do_not_drive: [
      `운전 중 / 이동 중 수업은 삼가 주세요.`,
      `Don’t Zoom and drive.`,
      's_lessons_schedule.rules.do_not_drive',
      ``,
      ``,
      ``,
      ``,
    ],
    no_drinking: [
      `음주 후 수업은 자제해 주세요.`,
      `No drinking during lessons.`,
      's_lessons_schedule.rules.no_drinking',
      ``,
      ``,
      ``,
      ``,
    ],
    punctual: [`약속된 시간 지키기`, `Be Punctual`, 's_lessons_schedule.rules.punctual', ``, ``, ``, ``],
    keep_schdules: [
      `정해진 수업 시간을 지켜주세요.`,
      `Keep track of your lesson schedules.`,
      's_lessons_schedule.rules.keep_schdules',
      ``,
      ``,
      ``,
      ``,
    ],
    no_external_request: [
      `수업 종료 이후 요청은 자제해 주세요.`,
      `Tutors aren’t responsible for\nany external lesson requests.`,
      's_lessons_schedule.rules.no_external_request',
      ``,
      ``,
      ``,
      ``,
    ],
    with_respect: [
      `튜터 존중하기`,
      `Treat Your Tutor with Respect`,
      's_lessons_schedule.rules.with_respect',
      ``,
      ``,
      ``,
      ``,
    ],
    no_photos: [
      `수업 중 사적인 질문, 사진 촬영 등은 삼가 주세요.`,
      `Refrain from asking\nsensitive questions or taking photographs.`,
      's_lessons_schedule.rules.no_photos',
      ``,
      ``,
      ``,
      ``,
    ],
    no_discriminatory_remarks: [
      `의도하지 않은 혐오 발언을 주의해 주세요.`,
      `Do not make discriminatory remarks.`,
      's_lessons_schedule.rules.no_discriminatory_remarks',
      ``,
      ``,
      ``,
      ``,
    ],
    focus_on_learning: [
      `영어 학습 목적에 충실하기`,
      `Focus on Learning English`,
      's_lessons_schedule.rules.focus_on_learning',
      ``,
      ``,
      ``,
      ``,
    ],
    avoid_unrelated: [
      `튜터에게 수업과 관련 없는 요청은 삼가 주세요.`,
      `Avoid making requests unrelated to the lesson.`,
      's_lessons_schedule.rules.avoid_unrelated',
      ``,
      ``,
      ``,
      ``,
    ],
    turn_on_video: [
      `학습 효과를 위해 카메라는 꼭 켜주세요.`,
      `Turn on video for effective communication.`,
      's_lessons_schedule.rules.turn_on_video',
      ``,
      ``,
      ``,
      ``,
    ],
    follow_guidelines: [
      `수업 에티켓을 지키지 않으면, 링글 수업이 제한될 수 있습니다.`,
      `If the rules of conduct are not followed, future lessons may be limited.`,
      's_lessons_schedule.rules.follow_guidelines',
      ``,
      ``,
      ``,
      ``,
    ],
    check: [`잠깐! 수업 에티켓`, `Check Our Rules of Conduct!`, 's_lessons_schedule.rules.check', ``, ``, ``, ``],
    done_checking: [`확인 완료`, `Done checking`, 's_lessons_schedule.rules.done_checking', ``, ``, ``, ``],
    next: [`다음`, `Next`, 's_lessons_schedule.rules.next', ``, ``, ``, ``],
  },
  failure_reason: [`실패사유:`, `Reason:`, 's_lessons_schedule.failure_reason', ``, ``, ``, ``],
  dont_zoom_travelling: [
    `이동하면서 줌에 접속하지 마세요`,
    `Don’t zoom when traveling`,
    's_lessons_schedule.dont_zoom_travelling',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_subscribe = {
  message: {
    success: [
      `구독을 축하드립니다!`,
      `Congratulations, you have successfully subscribed!`,
      's_subscribe.message.success',
      ``,
      ``,
      ``,
      ``,
    ],
    already_subscribed: [
      `이미 구독하고 계신 이메일 입니다. 스팸함을 확인해주세요.`,
      `You have already subscribed with this email. Please check your spam.`,
      's_subscribe.message.already_subscribed',
      ``,
      ``,
      ``,
      ``,
    ],
    failure: [
      `구독중 문제가 발생하였습니다. 고객센터에 문의해주세요.`,
      `There was a problem while subscribing to the newsletter. Please contact the administrator.`,
      's_subscribe.message.failure',
      ``,
      ``,
      ``,
      ``,
    ],
    invalidName: [`이름을 적어주세요.`, `Please input your name.`, 's_subscribe.message.invalidName', ``, ``, ``, ``],
    invalidEmail: [
      `잘못된 이메일 형식입니다.`,
      `Not a valid email address`,
      's_subscribe.message.invalidEmail',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  body_header: {
    name_input: [`이름`, `Name`, 's_subscribe.body_header.name_input', ``, ``, ``, ``],
    email_input: [`이메일`, `Email`, 's_subscribe.body_header.email_input', ``, ``, ``, ``],
    header_button: [
      `Content Weekly 무료 구독하기`,
      `Subscribe to Content Weekly`,
      's_subscribe.body_header.header_button',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      `매주 목요일, 금주의 가장 인기 있는 링글 콘텐츠를 받아보세요.`,
      `Subscribe to get Ringle's hottest contents every Thursday.`,
      's_subscribe.body_header.title',
      ``,
      ``,
      ``,
      ``,
    ],
    button: [
      `Content Weekly 무료 구독하기`,
      `Subscribe to Content Weekly`,
      's_subscribe.body_header.button',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  success_header: {
    title: [
      `Content Weekly 구독 완료되었습니다!`,
      `You have successfully subscribed to the newsletter!`,
      's_subscribe.success_header.title',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle: [
      `매주 목요일, 금주의 가장 인기 있는 링글 콘텐츠를 보내드릴게요.`,
      `Every Thursday, you will get Ringle's hottest contents.`,
      's_subscribe.success_header.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    button: [`링글 콘텐츠 둘러보기`, `Check out Ringle Contents`, 's_subscribe.success_header.button', ``, ``, ``, ``],
    email_change_button: [
      `이메일 주소 변경하기`,
      `Change email address`,
      's_subscribe.success_header.email_change_button',
      ``,
      ``,
      ``,
      ``,
    ],
    email_subscribed: [
      `발송되는 이메일 주소:`,
      `Subscribed email address:`,
      's_subscribe.success_header.email_subscribed',
      ``,
      ``,
      ``,
      ``,
    ],
    content1: [
      `발송되고 있는 이메일 주소:`,
      `Subscribed email:`,
      's_subscribe.success_header.content1',
      ``,
      ``,
      ``,
      ``,
    ],
    content2: [
      `메일을 받지 못한 경우 스팸함이나 프로모션 카테고리를 확인해주세요.`,
      `Check out this week's Content Weekly`,
      's_subscribe.success_header.content2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  body: {
    example: [
      `이번주 Content Weekly 보기`,
      `Check out this week's Content Weekly`,
      's_subscribe.body.example',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  subs_btn: [`Content Weekly 구독하기`, `Subscribe to Content Weekly`, 's_subscribe.subs_btn', ``, ``, ``, ``],
  failure_modal: {
    title: [
      `이미 구독중인 이메일입니다.`,
      `Your email is already on the subscription list.`,
      's_subscribe.failure_modal.title',
      ``,
      ``,
      ``,
      ``,
    ],
    close: [`확인`, `Close`, 's_subscribe.failure_modal.close', ``, ``, ``, ``],
  },
}
export const s_unsubscribe = {
  message: {
    success: [
      `답변을 제출하셨습니다. 감사합니다.`,
      `Your response has been submitted. Thank you.`,
      's_unsubscribe.message.success',
      ``,
      ``,
      ``,
      ``,
    ],
    submit_failure: [
      `답변 제출중 문제가 발생하셨습니다.`,
      `There was a problem while submitting your answers.`,
      's_unsubscribe.message.submit_failure',
      ``,
      ``,
      ``,
      ``,
    ],
    failure: [
      `구독 취소중 문제가 발생하였습니다. 고객센터에 문의해주세요.`,
      `There was a problem while unsubscribing to the newsletter. Please contact the administrator.`,
      's_unsubscribe.message.failure',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  body_header: {
    title: [
      `그동안 콘텐츠 뉴스레터를 구독해 주셔서 정말 감사합니다.`,
      `Thank you for your time as our subscriber.`,
      's_unsubscribe.body_header.title',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle: [
      `구독을 취소한 이유를 알려주세요. (복수 선택 가능)`,
      `Please tell us why you decided to unsubscribe.`,
      's_unsubscribe.body_header.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  reasons: {
    not_interested: [
      `영어 공부에 관심이 없어졌습니다.`,
      `I lost interest in studying English.`,
      's_unsubscribe.reasons.not_interested',
      ``,
      ``,
      ``,
      ``,
    ],
    contents_not_good: [
      `링글 콘텐츠가 영어 공부나 인사이트 개발에 도움이 되지 않습니다.`,
      `Ringle Contents did not help me in learning English or getting insights on topics.`,
      's_unsubscribe.reasons.contents_not_good',
      ``,
      ``,
      ``,
      ``,
    ],
    no_time: [
      `시간이나 비용을 들이고 싶지 않습니다.`,
      `I do not have time for the newsletters.`,
      's_unsubscribe.reasons.no_time',
      ``,
      ``,
      ``,
      ``,
    ],
    other_contents: [
      `다른 좋은 콘텐츠를 찾았습니다.`,
      `Found other helpful contents.`,
      's_unsubscribe.reasons.other_contents',
      ``,
      ``,
      ``,
      ``,
    ],
    other: [`기타`, `Other`, 's_unsubscribe.reasons.other', ``, ``, ``, ``],
    specify: [
      `좀 더 자세히 알려주세요!`,
      `Please tell us what it is!`,
      's_unsubscribe.reasons.specify',
      ``,
      ``,
      ``,
      ``,
    ],
    tell_us: [`무엇인지 알려주세요!`, `Tell us what it is!`, 's_unsubscribe.reasons.tell_us', ``, ``, ``, ``],
    no_email: [
      `링크에 문제가 있습니다. 이메일에 있는 링크를 사용해 주세요.`,
      `This is a corrupted link. Please use the link in the email.`,
      's_unsubscribe.reasons.no_email',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  button: [`제출하기`, `Submit`, 's_unsubscribe.button', ``, ``, ``, ``],
  disabled_button: [`제출 완료`, `Submitted`, 's_unsubscribe.disabled_button', ``, ``, ``, ``],
  resubscribe: [`다시 구독하기`, `Subscribe again`, 's_unsubscribe.resubscribe', ``, ``, ``, ``],
}
export const t_gateway = {
  dashboard: [`학부모 대시보드`, `Go to Parent Dashboard`, 't_gateway.dashboard', ``, ``, ``, ``],
  enter_parent: (name) => [
    `부모로 입장`,
    <>
      {name}
      <br /> (Parent View)
    </>,
    't_gateway.enter_parent',
    ``,
    ``,
    ``,
    ``,
  ],
  choose_where_to_go: [
    `이동할 공간을 선택해주세요.`,
    `Who's using Ringle Teens?`,
    't_gateway.choose_where_to_go',
    ``,
    ``,
    ``,
    ``,
  ],
  study_place_of: (name) => [<>{name}의 학습공간</>, <>{name}</>, 't_gateway.study_place_of', ``, ``, ``, ``],
  passcode: [`패스코드`, `Passcode`, 't_gateway.passcode', ``, ``, ``, ``],
  enter_as_1: (name) => [<>{name}으로 입장</>, <>{name}</>, 't_gateway.enter_as_1', ``, ``, ``, ``],
  enter_as_2: (name) => [<>{name}로 입장</>, <>{name}</>, 't_gateway.enter_as_2', ``, ``, ``, ``],
  enter_passcode: [
    `학부모 계정으로 이동하려면 패스코드를 입력해주세요.`,
    `Please enter your passcode to go to your parent account.`,
    't_gateway.enter_passcode',
    ``,
    ``,
    ``,
    ``,
  ],
  passcode_title: [`패스코드`, `Passcode`, 't_gateway.passcode_title', ``, ``, ``, ``],
  passcode_check: [
    `올바른 패스코드를 입력해주세요.`,
    `Please enter the correct passcode.`,
    't_gateway.passcode_check',
    ``,
    ``,
    ``,
    ``,
  ],
  no_child: [`학부모 대시보드는 자녀를 먼저 추가해야 접근할 수 있습니다.`, ``, 't_gateway.no_child', ``, ``, ``, ``],
}
export const s_content = {
  mobile_web: {
    access_on_app: [
      `앱을 다운 받고, 링글의 컨텐츠와 수업을 경험해 보세요.`,
      `Access all of Ringle's content for free
on Ringle App`,
      's_content.mobile_web.access_on_app',
      ``,
      ``,
      ``,
      ``,
    ],
    view_in_app: [`앱으로 보기`, `Open app`, 's_content.mobile_web.view_in_app', ``, ``, ``, ``],
    no_thanks: [
      `괜찮습니다, 모바일 웹으로 볼게요.`,
      `No thanks, I want to stay on this page.`,
      's_content.mobile_web.no_thanks',
      ``,
      ``,
      ``,
      ``,
    ],
    continue_on_app: [`앱에서 이어보기`, `Continue on app`, 's_content.mobile_web.continue_on_app', ``, ``, ``, ``],
    finished_preview: [
      `1분 미리듣기가 완료되었습니다.`,
      `You finished the 1-minute preview.`,
      's_content.mobile_web.finished_preview',
      ``,
      ``,
      ``,
      ``,
    ],
    RSVP_on_app: [`앱에서 웨비나 신청하기`, `RSVP on App`, 's_content.mobile_web.RSVP_on_app', ``, ``, ``, ``],
    sign_in_to_view_webinar_clips: [
      `웨비나의 다른 클립은 로그인 후에 시청 가능합니다.`,
      `Please log in to view other webinar clips.`,
      's_content.mobile_web.sign_in_to_view_webinar_clips',
      ``,
      ``,
      ``,
      ``,
    ],
    please_note: [`안내`, `Please note`, 's_content.mobile_web.please_note', ``, ``, ``, ``],
    can_access_after_sign_in: [
      `로그인 후 링글 앱과 PC 웹에서만 제공되는 기능입니다.`,
      `This feature can be accessed after signing in on Ringle website or App.`,
      's_content.mobile_web.can_access_after_sign_in',
      ``,
      ``,
      ``,
      ``,
    ],
    cancel: [`취소`, `Cancel`, 's_content.mobile_web.cancel', ``, ``, ``, ``],
    open_app: [`앱으로 이동`, `Open app`, 's_content.mobile_web.open_app', ``, ``, ``, ``],
  },
  web: {
    sign_in_to_access: [
      `로그인하고 무료로 링글의 콘텐츠를 경험해 보세요.`,
      `Log in to access all of Ringle's content for free`,
      's_content.web.sign_in_to_access',
      ``,
      ``,
      ``,
      ``,
    ],
    sign_in_to_continue: [
      `로그인하고 이어보기`,
      `Log in to continue`,
      's_content.web.sign_in_to_continue',
      ``,
      ``,
      ``,
      ``,
    ],
    sign_in_to_RSVP: [`로그인 후 신청하기`, `Log in to RSVP`, 's_content.web.sign_in_to_RSVP', ``, ``, ``, ``],
    sign_in_to_download_MP3: [
      <>
        MP3파일 다운로드 기능은 로그인 후에 제공되는 기능입니다. <br />
        로그인하고 계속 진행하시겠습니까?
      </>,
      `Please sign in to download the MP3.`,
      's_content.web.sign_in_to_download_MP3',
      ``,
      ``,
      ``,
      ``,
    ],
    sign_in_to_download_PDF: [
      <>
        PDF파일 다운로드 기능은 로그인 후에 제공되는 기능입니다. <br />
        로그인하고 계속 진행하시겠습니까?
      </>,
      `Please sign in to download the PDF.`,
      's_content.web.sign_in_to_download_PDF',
      ``,
      ``,
      ``,
      ``,
    ],
    save: [`찜하기`, `Save`, 's_content.web.save', ``, ``, ``, ``],
    sign_in_to_save: [
      <>
        찜하기 기능은 로그인 후에 제공되는 기능입니다. <br />
        로그인하고 계속 진행하시겠습니까?
      </>,
      `Please sign in to save.`,
      's_content.web.sign_in_to_save',
      ``,
      ``,
      ``,
      ``,
    ],
    download_materials: [`자료 받기`, `Download materials`, 's_content.web.download_materials', ``, ``, ``, ``],
    sign_in_to_download_materials: [
      `자료 받기 기능은 로그인 후에 제공되는 기능입니다. 로그인하고 계속 진행하시겠습니까?`,
      `Please sign in to download webinar materials.`,
      's_content.web.sign_in_to_download_materials',
      ``,
      ``,
      ``,
      ``,
    ],
    sign_in: [`로그인`, `Log in`, 's_content.web.sign_in', ``, ``, ``, ``],
    sign_up: [`회원가입 하기`, `Sign up`, 's_content.web.sign_up', ``, ``, ``, ``],
  },
  do_you_like: [
    `오늘의 Daily Brief가 마음에 드셨다면?`,
    `Liked this Daily Brief?`,
    's_content.do_you_like',
    ``,
    ``,
    ``,
    ``,
  ],
  related_material_read: [
    `이 Daily Brief를 공유하거나 관련 교재를 읽어보세요.`,
    `Share it with your friends or view related materials.`,
    's_content.related_material_read',
    ``,
    ``,
    ``,
    ``,
  ],
  sharing: [`공유하기`, `Share`, 's_content.sharing', ``, ``, ``, ``],
  related_material: [`관련 교재 읽기`, `View Insights`, 's_content.related_material', ``, ``, ``, ``],
  do_you_like_daily_brief: [
    `오늘의 Daily Brief가 마음에 드셨다면?`,
    `Liked this Daily Brief?`,
    's_content.do_you_like_daily_brief',
    ``,
    ``,
    ``,
    ``,
  ],
  kakakotalk: [`카카오톡`, `Kakao Talk`, 's_content.kakakotalk', ``, ``, ``, ``],
  facebook: [`페이스북`, `Facebook`, 's_content.facebook', ``, ``, ``, ``],
  url_copy: [`URL복사`, `URL Copy`, 's_content.url_copy', ``, ``, ``, ``],
  link_copied: [
    <>
      링크가 복사되었습니다. <br /> 원하시는 곳에 복사하여 붙여넣어주세요.
    </>,
    `Link is copied to your clipboard.`,
    's_content.link_copied',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_login = {
  placeholder1: [
    `이메일 주소를 입력해주세요 (ID)`,
    `Enter your email address (ID)`,
    't_login.placeholder1',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder2: [`비밀번호를 입력해주세요`, `Enter your password`, 't_login.placeholder2', ``, ``, ``, ``],
  alert_msg1: [
    `올바른 이메일을 적어주세요`,
    `Please enter a valid Email address`,
    't_login.alert_msg1',
    ``,
    ``,
    ``,
    ``,
  ],
  alert_msg2: [`존재하지 않는 비밀번호입니다`, `Did not match stored password`, 't_login.alert_msg2', ``, ``, ``, ``],
  btn1: [`로그인하기`, `Log in`, 't_login.btn1', ``, ``, ``, ``],
  btn2: [`가입하고 체험 수업 신청하기`, `Sign up & Try a Free Lesson`, 't_login.btn2', ``, ``, ``, ``],
  forgot_pw: [`비밀번호를 잊으셨나요?`, `Forgot password?`, 't_login.forgot_pw', ``, ``, ``, ``],
  sign_up_message: [
    `링글 틴즈 학부모 계정이 없으신가요?`,
    `Don't have a Ringle Teens parent account?`,
    't_login.sign_up_message',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_landing_home = {
  title1: {
    default: [
      <>
        당신이 꿈꾸던
        <br />
        영어실력과 커리어를
        <br />
        현실로
      </>,
      <>
        Improve your English,
        <br />
        Unlock your potential
        <br />
      </>,
      's_landing_home.title1.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      <>
        아이비리그 출신 원어민 튜터와 함께하는
        <br />
        1:1 화상영어 수업
      </>,
      <>
        1:1 online English lessons
        <br />
        with top university students & graduates
      </>,
      's_landing_home.title1.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn1: [`링글 시작하기`, `Get Started`, 's_landing_home.btn1', ``, ``, ``, ``],
  title2: {
    mobile: [
      <>
        이미 많은 고객분들이
        <br />
        링글과 함께 꿈을 현실로
        <br />
        만들고 있습니다.
      </>,
      <>
        Ringle has allowed
        <br />
        countless dreams
        <br />
        to come true
      </>,
      's_landing_home.title2.mobile',
      ``,
      ``,
      ``,
      ``,
    ],
    desktop: [
      <>이미 많은 고객분들이 링글과 함께 꿈을 현실로 만들고 있습니다.</>,
      <>Ringle has allowed countless dreams to come true</>,
      's_landing_home.title2.desktop',
      ``,
      ``,
      ``,
      ``,
    ],
    caption: [`*링글 제휴 기업`, `*Companies partnering with Ringle`, 's_landing_home.title2.caption', ``, ``, ``, ``],
    pc: [
      `이미 많은 고객 분들이 링글과 함께 꿈을 현실로 만들고 있습니다`,
      `Ringle has allowed countless dreams to come true`,
      's_landing_home.title2.pc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title3: {
    default: [
      <>
        영어로 생각을 나눌 수 있는
        <br />
        명문대 원어민 튜터
      </>,
      <>
        In-depth discussions
        <br />
        with exceptional tutors
      </>,
      's_landing_home.title3.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `영어가 모국어인 사람은 많지만 정돈된 영어를 쓰는 사람은 따로 있죠. 내 생각을 영어로 효과적으로 표현하게 도와주는 1,000명 이상의 아이비리그 출신 튜터들과 지적인 대화를 나누세요.`,
      `Speaking eloquently is more an art than science. Learn from 1000+ intellectual tutors from top universities to express your thoughts effectively.`,
      's_landing_home.title3.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    more_btn: [`튜터 더 알아보기`, `Meet our tutors`, 's_landing_home.title3.more_btn', ``, ``, ``, ``],
  },
  review1: {
    default: [
      `글로벌 기업에서 일할 때의 영어는 누군가를 설득해야 하는 경우가 많아서 한 단계 높은 비즈니스 영어가 필요하죠. 링글 튜터들의 피드백을 받으면서 영어로 일하는 자신감을 가지게 됐습니다.`,
      `In the workplace, you need strong communication skills to be persuasive. Since joining Ringle, I’ve been a better communicator and negotiator at work.`,
      's_landing_home.review1.default',
      ``,
      ``,
      ``,
      ``,
    ],
    writer: [
      `서주영 / 파트너 엔지니어, Google HQ`,
      `Juyung Seo / Partner Engineer, Google HQ`,
      's_landing_home.review1.writer',
      ``,
      ``,
      ``,
      ``,
    ],
    tag: {
      default: [`해외 유학`, `Studying Abroad`, 's_landing_home.review1.tag.default', ``, ``, ``, ``],
    },
    title: {
      default: [
        <>
          우지혜님의
          <br />
          미국 석사에서 커리어 점프까지
        </>,
        <>
          Jihye's journey
          <br />
          to getting an MBA and making a career change
        </>,
        's_landing_home.review1.title.default',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    desc1: {
      default: [
        `프로덕트 디자이너, ZipRecruiter`,
        `Product Designer, Zip Recruiter`,
        's_landing_home.review1.desc1.default',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    desc2: {
      default: [
        `Pratt Institute 석사`,
        `Pratt Institute, Information Design Master's degree`,
        's_landing_home.review1.desc2.default',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  title4: {
    default: [
      <>
        눈에 보이는 교정,
        <br />
        기억에 남는 맞춤 피드백
      </>,
      <>
        Real-time corrections and
        <br />
        Personalized feedback
      </>,
      's_landing_home.title4.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `링글의 수업은 말하고 사라지는 영어가 아닌 교정되는 내 영어를 눈으로 보고, 기록으로 남깁니다. 수업 후에는 영어 사용에 대한 AI 분석과 튜터의 피드백 리포트를 통해 성장하는 실력까지 확인하세요.`,
      `What you learn stays with you on Ringle. Real-time corrections are visualized and saved in your class notes. You can also monitor your growth through AI analysis and tutor reports.`,
      's_landing_home.title4.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    more_btn: [
      `수업 시스템 더 알아보기`,
      `Browse our learning system`,
      's_landing_home.title4.more_btn',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  review2: {
    default: [
      `최근에 영어 발표 연습을 하면서 교정 받은 표현은 절대 잊지 않고 계속 쓸 것 같아요. 다른 플랫폼에서는 수업이 끝나고 내용이 사라지는 느낌이 들었다면 링글은 내가 말하는 것을 눈으로 보면서 고쳐주고 저장까지 되니까 확실히 머릿속에 남는 느낌이 들어요.`,
      `Unlike other platforms, Ringle's system makes it easier to retain and implement what I learned. I can see what I said exactly and how it was corrected by the tutor. It's intuitive!`,
      's_landing_home.review2.default',
      ``,
      ``,
      ``,
      ``,
    ],
    writer: [
      `김도엽 / 북미총괄 컨트롤러, 삼성물산`,
      `Doyeop Kim / General Manager, Samsung C&T`,
      's_landing_home.review2.writer',
      ``,
      ``,
      ``,
      ``,
    ],
    tag: {
      default: [`직장인 MBA`, `MBA for Professionals`, 's_landing_home.review2.tag.default', ``, ``, ``, ``],
    },
    title: {
      default: [
        <>
          이상윤님의
          <br />
          성공적인 MBA 에세이 준비
        </>,
        <>
          How Sangyun
          <br />
          wrote a successful MBA application essay
        </>,
        's_landing_home.review2.title.default',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    desc1: {
      default: [
        `공동 창업자, NextChapter`,
        `Co-founder, NextChapter`,
        's_landing_home.review2.desc1.default',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    desc2: {
      default: [
        `HBS MBA Class of 2020`,
        `HBS MBA Class of 2020`,
        's_landing_home.review2.desc2.default',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  title5: {
    default: [
      <>
        지금 가장 주목받는 주제의
        <br />
        교재로 익히는 실전 영어
      </>,
      <>
        Lesson Materials
        <br />
        on Trending Topics
      </>,
      's_landing_home.title5.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `시사∙경제, 비즈니스, 자기 계발까지. 아이비리그 출신 원어민과 MBA 졸업생 등으로 구성된 콘텐츠 팀이 직접 만드는 1,000개 이상의 교재와 영상을 활용하여 내가 관심 있는 주제의 영어 표현을 익히세요.`,
      `Gain access to 1,000+ articles and videos on politics, economics, business, and self-improvement, produced by Ivy-League and MBA graduates.`,
      's_landing_home.title5.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    more_btn: [`교재 더 알아보기`, `See more Materials`, 's_landing_home.title5.more_btn', ``, ``, ``, ``],
  },
  review3: {
    default: [
      `영어로 대화를 잘 하려면 말하고 싶은 주제에 대해 영어로 익숙해지는 게 더 중요하더라고요. 한국어도 할 말이 없으면 대화가 잘 안되잖아요. 링글 수업 후에는 외국 팀원들과 날씨 이야기 말고도 다양한 주제로 대화하기가 훨씬 수월해졌어요.`,
      `Having a good conversation depends on your familiarity with the topic in any language, and English is no exception. Since I started using Ringle, I've found it easier to talk to my English-speaking colleagues.`,
      's_landing_home.review3.default',
      ``,
      ``,
      ``,
      ``,
    ],
    writer: [
      `정수지 / 프로그램 리드, SAP`,
      `Suji Jeong / Program Lead, SAP`,
      's_landing_home.review3.writer',
      ``,
      ``,
      ``,
      ``,
    ],
    tag: {
      default: [`글로벌 취업`, `Working Abroad`, 's_landing_home.review3.tag.default', ``, ``, ``, ``],
    },
    title: {
      default: [
        <>
          최예원님의
          <br />
          글로벌 커리어로의 전환
        </>,
        <>
          Yewon's story
          <br />
          of restarting her career abroad
        </>,
        's_landing_home.review3.title.default',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    desc1: {
      default: [
        `클리니컬 디렉터, Merck`,
        `Clinical Director, Merck`,
        's_landing_home.review3.desc1.default',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    desc2: {
      default: [
        `서울대 의대, 임상병리 박사`,
        `MD, PhD in Clinical Pharmacology`,
        's_landing_home.review3.desc2.default',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  title6: {
    default: [
      <>
        더 이상 막연한 꿈이 아닌
        <br />
        현실로
      </>,
      <>
        No longer a dream,
        <br />
        but a reality
      </>,
      's_landing_home.title6.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      <>
        해외 유학, 글로벌 취업 준비, 영어 실력 향상 등.
        <br />늘 마음속에만 담아둔 목표, 이제는 링글과 함께 현실로 만드세요.
      </>,
      <>
        Studying or working abroad
        <br />
        The dreams you've always aspired to realize them with Ringle
      </>,
      's_landing_home.title6.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    more_btn: [`고객 스토리 더 알아보기`, `Check Out Testimonials`, 's_landing_home.title6.more_btn', ``, ``, ``, ``],
  },
  title7: {
    default: {
      mobile: [
        <>
          가입만으로도
          <br />
          주어지는 성장의 기회
        </>,
        <>
          Sign up
          <br />
          to start your journey
        </>,
        's_landing_home.title7.default.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
      pc: [
        <>
          가입만으로도 주어지는
          <br />
          성장의 기회
        </>,
        <>
          Sign up
          <br />
          to start your journey
        </>,
        's_landing_home.title7.default.pc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    desc: {
      default: [
        <>
          망설이고, 미루기만 했던 영어공부.
          <br />
          링글 앱 다운 받고 무료 콘텐츠로 시작하세요.
        </>,
        <>
          Stop procrastinating
          <br />
          Download the Ringle app to view free content
        </>,
        's_landing_home.title7.desc.default',
        ``,
        ``,
        ``,
        ``,
      ],
      pc: [
        <>
          망설이고, 미루기만 했던 영어공부.
          <br />
          회원 가입하고 무료 콘텐츠로 시작하세요.
        </>,
        <>
          Stop procrastinating
          <br />
          Sign up Ringle to view free content
        </>,
        's_landing_home.title7.desc.pc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    brief: {
      mobile: [
        <>
          원하는 시간에 알려주는 매일의 공부습관.
          <br />
          <b>새로나온 교재</b>
        </>,
        <>
          Daily study habits <br />
          with regular reminders.
          <br />
          <b>Latest Materials</b>
        </>,
        's_landing_home.title7.brief.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
      pc: [
        <>
          원하는 시간에 알려주는 매일의 공부습관.
          <br />
          <b>새로나온 교재</b>
        </>,
        <>
          Daily study habits with regular reminders.
          <br />
          <b>Latest Materials</b>
        </>,
        's_landing_home.title7.brief.pc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    webinars: {
      mobile: [
        <>
          인기 튜터와 <br />
          다른 수강생들과 함께 하는 수업.
          <br />
          <b>링글 LIVE</b>
        </>,
        <>
          Join our most esteemed tutors
          <br />
          and community of Ringlers on
          <br />
          <b>Ringle LIVE</b>
        </>,
        's_landing_home.title7.webinars.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
      pc: [
        <>
          인기 튜터와 다른 수강생들과 함께 하는 수업.
          <br />
          <b>링글 LIVE</b>
        </>,
        <>
          Join our most esteemed tutors and community of Ringlers on
          <br />
          <b>Ringle LIVE</b>
        </>,
        's_landing_home.title7.webinars.pc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    events: {
      mobile: [
        <>
          영어 학습 습관을 도와주는
          <br />
          <b>
            각종 이벤트와
            <br />
            수업 관련 알림까지
          </b>
        </>,
        <>
          Successfully form a new habit through
          <br />
          <b>
            our event updates and
            <br />
            class notifications
          </b>
        </>,
        's_landing_home.title7.events.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
      pc: [
        <>
          영어 학습 습관을 도와주는
          <br />
          <b>각종 챌린지와 이벤트까지</b>
        </>,
        <>
          Successfully form a new habit
          <br />
          <b>through events and challenges</b>
        </>,
        's_landing_home.title7.events.pc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  btn2: [`링글 앱 다운받기`, `Download the Ringle App`, 's_landing_home.btn2', ``, ``, ``, ``],
  title8: {
    mobile: [
      <>
        누구나 영어의 장벽을 넘어,
        <br />
        더 큰 기회에 도전하는 세상을
        <br />
        만들 때까지 링글이 함께 합니다.
      </>,
      <>
        A world without the English language barrier
        <br />
        A world where you can dream bigger
        <br />A world Ringle creates
      </>,
      's_landing_home.title8.mobile',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle1: [
      `지금까지 진행된 수업`,
      <>
        Total number
        <br />
        of lessons
      </>,
      's_landing_home.title8.subtitle1',
      ``,
      ``,
      ``,
      ``,
    ],
    count1: [`건`, ``, 's_landing_home.title8.count1', ``, ``, ``, ``],
    subtitle2: [
      `수업 및 튜터 평균 만족도`,
      <>
        Average rating
        <br />
        of lessons and tutors
      </>,
      's_landing_home.title8.subtitle2',
      ``,
      ``,
      ``,
      ``,
    ],
    count2: [`점`, ``, 's_landing_home.title8.count2', ``, ``, ``, ``],
    count2_1: [`/5점`, `/5`, 's_landing_home.title8.count2_1', ``, ``, ``, ``],
    subtitle3: [
      `최근 1개월간 수업한 튜터`,
      <>
        Active tutors
        <br />
        in the past month
      </>,
      's_landing_home.title8.subtitle3',
      ``,
      ``,
      ``,
      ``,
    ],
    count3: [`명`, ``, 's_landing_home.title8.count3', ``, ``, ``, ``],
    subtitle4: [
      `지금까지 등록된 교재`,
      <>
        Total number
        <br />
        of materials
      </>,
      's_landing_home.title8.subtitle4',
      ``,
      ``,
      ``,
      ``,
    ],
    count4: [`개`, ``, 's_landing_home.title8.count4', ``, ``, ``, ``],
    caption: [`*2022.11.07 내부 데이터 기준`, `*As of Nov 07, 2022`, 's_landing_home.title8.caption', ``, ``, ``, ``],
    pc: [
      <>
        누구나 영어의 장벽을 넘어, 더 큰 기회에 도전하는
        <br />
        세상을 만들 때까지 링글이 함께 합니다.
      </>,
      <>
        A world without the English language barrier
        <br />
        A world where you can dream bigger
        <br />A world Ringle creates
      </>,
      's_landing_home.title8.pc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn3: [`링글 수업권 확인하기`, `Check Ringle Credits`, 's_landing_home.btn3', ``, ``, ``, ``],
  btn4: [`링글 시작하기`, `Get Started`, 's_landing_home.btn4', ``, ``, ``, ``],
  review4: {
    tag: {
      default: [`영어 실력 향상`, `Improve Your English`, 's_landing_home.review4.tag.default', ``, ``, ``, ``],
    },
    title: {
      default: [
        <>
          김정은님의
          <br />
          해외 이직을 자극하는 영어 공부
        </>,
        <>
          Studying with Ringle
          <br />
          inspired Jeongeun to move abroad
        </>,
        's_landing_home.review4.title.default',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    desc1: {
      default: [
        `주임 연구원, 에릭슨 - LG`,
        `Research Engineer, Ericsson-LG`,
        's_landing_home.review4.desc1.default',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
}
export const s_app_download = {
  label: [
    <>
      링글 앱에서 <b>수업을 준비</b>하고
      <br />
      <b>다양한 콘텐츠</b>를 확인해보세요.
    </>,
    <>
      Experience all of Ringle’s content and lessons on <b>Ringle App.</b>
    </>,
    's_app_download.label',
    ``,
    ``,
    ``,
    ``,
  ],
  button: [`앱에서 링글 시작하기`, `Continue in App`, 's_app_download.button', ``, ``, ``, ``],
  footer: [`오늘은 모바일 웹을 더 볼래요.`, `Continue in Browser.`, 's_app_download.footer', ``, ``, ``, ``],
  title: [
    <>
      링글 앱에서 <b>매일 새로운 영어 콘텐츠</b>를<br />
      <b>무료로</b> 받아보실 수 있어요!
    </>,
    ``,
    's_app_download.title',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_1: [`앱에서 더보기`, ``, 's_app_download.btn_1', ``, ``, ``, ``],
  btn_2: [`이 콘텐츠로 수업하는 법이 궁금하신가요?`, ``, 's_app_download.btn_2', ``, ``, ``, ``],
}
export const s_guide_modal = {
  title: [
    `링글 수업이 어떻게 진행되는지 궁금하다면?`,
    `Curious how Ringle lessons are conducted?`,
    's_guide_modal.title',
    ``,
    ``,
    ``,
    ``,
  ],
  btn: [`수업 미리보기`, `Lesson Preview`, 's_guide_modal.btn', ``, ``, ``, ``],
}
export const s_guide_player = {
  title: [`링글 수업 가이드`, `Ringle Lesson Guide`, 's_guide_player.title', ``, ``, ``, ``],
  subtitle1: [`Step1. 수업 예약`, `Step 1. Book Lesson`, 's_guide_player.subtitle1', ``, ``, ``, ``],
  subtitle2: [`Step2. 예습 & 수업`, `Step 2. Prep & Lesson`, 's_guide_player.subtitle2', ``, ``, ``, ``],
  subtitle3: [`Step3. 복습`, `Step 3. Review`, 's_guide_player.subtitle3', ``, ``, ``, ``],
  desc: [
    `가이드 영상은 Help Center에서도 시청이 가능합니다.`,
    `You can also watch the guide video at the Help Center.`,
    's_guide_player.desc',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_guide_dialog = {
  desc: [
    <>
      가이드 영상은 도움이 되셨나요? 이제 링글 수업을 시작해보세요!
      <br />
      해당 영상은 Help Center 에서 다시 시청 할 수 있습니다.
    </>,
    <>Was the guide video helpful? Start a Ringle lesson now! You can watch this video again at the Help Center.</>,
    's_guide_dialog.desc',
    ``,
    ``,
    ``,
    ``,
  ],
  btn: [`확인`, `Next`, 's_guide_dialog.btn', ``, ``, ``, ``],
}
export const t_pricing = {
  title: [`수업권 구매`, `Purchase`, 't_pricing.title', ``, ``, ``, ``],
  dropdown_jpy: [`JPY`, `JPY`, 't_pricing.dropdown_jpy', ``, ``, ``, ``],
  dropdown_twd: [`TWD`, `TWD`, 't_pricing.dropdown_twd', ``, ``, ``, ``],
  dropdown_usd: [`USD`, `USD`, 't_pricing.dropdown_usd', ``, ``, ``, ``],
  dropdown_krw: [`KRW`, `KRW`, 't_pricing.dropdown_krw', ``, ``, ``, ``],
  dropdown_vnd: [`VND`, `VND`, 't_pricing.dropdown_vnd', ``, ``, ``, ``],
  dropdown_cny: [`CNY`, `CNY`, 't_pricing.dropdown_cny', ``, ``, ``, ``],
  duration: [`기간`, `Duration`, 't_pricing.duration', ``, ``, ``, ``],
  monthly: [`월간 요금제`, `Monthly Plans`, 't_pricing.monthly', ``, ``, ``, ``],
  subtitle: [
    `이 상품은 매달 정기적으로 결제가 진행되는 상품입니다.`,
    `Billed automatically on the same date/time every month`,
    't_pricing.subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  buy: [`구매하기`, `Purchase`, 't_pricing.buy', ``, ``, ``, ``],
  detail: [`자세히 보기`, `View Detail`, 't_pricing.detail', ``, ``, ``, ``],
  first: [`첫 구매 혜택`, `First Purchase`, 't_pricing.first', ``, ``, ``, ``],
  credit_info: [`수업권 선택 방법`, `Credits Detail`, 't_pricing.credit_info', ``, ``, ``, ``],
  length: [`수업길이`, `Lesson duration`, 't_pricing.length', ``, ``, ``, ``],
  length_info: [
    `수업 진행 방식과 사용하는 콘텐츠는 동일`,
    `Lesson format and available materials are the same`,
    't_pricing.length_info',
    ``,
    ``,
    ``,
    ``,
  ],
  about_40: [
    <>
      하나의 주제에 대해 심도 있게!
      <br />
      최신 트렌드부터 북챗까지
      <br />
      지적 대화를 레벨업하는 40분 수업
    </>,
    `A 40-minute lesson is great for those who want an in-depth discussion with ample time to interact with the tutor.`,
    't_pricing.about_40',
    ``,
    ``,
    ``,
    ``,
  ],
  about_20: [
    <>
      짧지만 확실하게!
      <br />
      부담스러운 예습 시간은 줄이고
      <br />
      일상 대화 능력을 향상시키는
      <br />
      20분 수업
    </>,
    `A 20-minute lesson is great for those who want a short and concentrated lesson amidst busy schedules.`,
    't_pricing.about_20',
    ``,
    ``,
    ``,
    ``,
  ],
  time: [`시간대`, `Times`, 't_pricing.time', ``, ``, ``, ``],
  time_info: [`수업 시작 기준`, `Lesson starting time`, 't_pricing.time_info', ``, ``, ``, ``],
  all_time: [`정규`, `Regular`, 't_pricing.all_time', ``, ``, ``, ``],
  time_east: [`오후 12시 ~ 오후 11시 (EST)`, `12PM - 11PM (EST)`, 't_pricing.time_east', ``, ``, ``, ``],
  time_west: [`오전 9시 ~ 오후 8시 (PST)`, `9AM - 8PM (PST)`, 't_pricing.time_west', ``, ``, ``, ``],
  guarantee: [`링글이 보장합니다`, `We guarantee Ringle is worth your time`, 't_pricing.guarantee', ``, ``, ``, ``],
  refund_1: [
    <>
      첫 1회 수업 불만족 시 100% 환불
      <br />첫 수업 시작 전 수강 기간 미차감
      <br />
      수업 하루 전까지 스케줄 변경 가능
    </>,
    <>
      100% Money-back guarantee after the first lesson
      <br />
      Expiration begins after the first lesson
      <br />
      Reschedule lesson anytime up to 24 hours
    </>,
    't_pricing.refund_1',
    ``,
    ``,
    ``,
    ``,
  ],
  refund_2: [
    <>
      수업권 구매 시 수강기간 연장 쿠폰 증정
      <br />첫 수업 시작 전 수강 기간 미차감
      <br />
      수업 하루 전까지 스케줄 변경 가능
    </>,
    <>
      Special coupon for extending lesson days
      <br />
      Expiration begins after the first lesson
      <br />
      Reschedule lesson anytime up to 24 hours
    </>,
    't_pricing.refund_2',
    ``,
    ``,
    ``,
    ``,
  ],
  makeup_lesson: [
    `수업 2시간 전까지 변경 시, 보충 수업권 제공`,
    `*Cancel up to 24 hours before the lesson`,
    't_pricing.makeup_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit: [`수강 혜택`, `Benefits`, 't_pricing.benefit', ``, ``, ``, ``],
  benefit_list_1: {
    default: [
      `매달 업데이트되는 최신 콘텐츠`,
      `New learning content updated monthly`,
      't_pricing.benefit_list_1.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc1: [`150+종의 링글`, `150+ Ringle Teens`, 't_pricing.benefit_list_1.desc1', ``, ``, ``, ``],
    desc2: [`매달 업데이트되는`, `Monthly Updates`, 't_pricing.benefit_list_1.desc2', ``, ``, ``, ``],
    desc3: [`매 수업 주어지는`, `After-lesson Homework`, 't_pricing.benefit_list_1.desc3', ``, ``, ``, ``],
    title1: [`오리지널 콘텐츠`, `Original Content`, 't_pricing.benefit_list_1.title1', ``, ``, ``, ``],
    title2: [`틴즈 전용 교재`, `Lesson Materials for Teens`, 't_pricing.benefit_list_1.title2', ``, ``, ``, ``],
    title3: [
      `작문 숙제 및 피드백`,
      <>
        Writing Assignment
        <br />
        and Feedback
      </>,
      't_pricing.benefit_list_1.title3',
      ``,
      ``,
      ``,
      ``,
    ],
    caption1: [
      `PDF 파일 / MP3 오디오북 다운로드 지원`,
      `PDF/MP3 Audiobook provided`,
      't_pricing.benefit_list_1.caption1',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  benefit_list_2: {
    default: [
      `AI가 기록하는 복습 시스템`,
      `AI-supported review system`,
      't_pricing.benefit_list_2.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc1: [`매 수업 종료 후 제공`, `Detailed Tutor Feedback`, 't_pricing.benefit_list_2.desc1', ``, ``, ``, ``],
    desc2: [`AI 기반 분석 시스템`, `AI Review system's`, 't_pricing.benefit_list_2.desc2', ``, ``, ``, ``],
    desc3: [`별도 제공`, `Additional Feature`, 't_pricing.benefit_list_2.desc3', ``, ``, ``, ``],
    title1: [
      `학생 및 부모 피드백`,
      `Feedback for Learners and Parents`,
      't_pricing.benefit_list_2.title1',
      ``,
      ``,
      ``,
      ``,
    ],
    title2: [`말하기 패턴 분석 자료`, `Speech pattern analysis`, 't_pricing.benefit_list_2.title2', ``, ``, ``, ``],
    title3: [
      `개별 첨삭 수업노트`,
      <>
        Lesson Notes for
        <br />
        Personalized Learning
      </>,
      't_pricing.benefit_list_2.title3',
      ``,
      ``,
      ``,
      ``,
    ],
    caption1: [
      `모든 수업 녹음파일 무제한 듣기`,
      `Exclusive Lesson Materials`,
      't_pricing.benefit_list_2.caption1',
      ``,
      ``,
      ``,
      ``,
    ],
    caption2: [
      `말하기, 듣기, 읽기, 쓰기 영역별 학습 데이터`,
      `Detailed feedback on vocabulary, fluency, grammar, and pronunciation`,
      't_pricing.benefit_list_2.caption2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  notice: [`유의사항`, `Terms`, 't_pricing.notice', ``, ``, ``, ``],
  recurring_info: {
    default: [`정기 결제 유의사항`, `Recurring payment policy`, 't_pricing.recurring_info.default', ``, ``, ``, ``],
    desc1: [
      `정기 결제 신청 시, 매월 같은 날에 자동으로 결제됩니다.`,
      `You are automatically charged for your monthly plan on the same date every month.`,
      't_pricing.recurring_info.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `정기 결제일은 최초로 수업권을 결제하는 일자로 자동 설정됩니다.`,
      `Your billing date corresponds to the day you purchased your monthly plan.`,
      't_pricing.recurring_info.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2_add: [
      `다만 특정 월에 결제일이 존재하지 않는 경우, 해당 월에 한하여 말일에 결제됩니다. (예를 들어, 1월 31일 가입자의 정기 결제일은 2월 28일, 3월 31일, 4월 30일 등)`,
      `If the day of your billing date does not occur in every month, you will be billed on the last day of that month instead. (For example, if you purchased your plan on Jan 31, your next payment will be on Feb 28, and subsequently on Mar 31, Apr 30, etc.)`,
      't_pricing.recurring_info.desc2_add',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      `원화 및 달러를 제외한 통화의 경우, 달러 기준 결제 당시 환율(결제사 기준)로 계산하여 청구 됩니다. 사용하시는 은행이나 카드사에 따라 환율 및 해외 결제 수수료가 달라 실제 원화로 청구된 금액에는 차이가 발생할 수 있습니다.`,
      `For any currencies other than KRW or USD, you will be charged based on the USD exchange rate at the point of transaction. Your total in KRW may show a slight increase/decrease due to the different exchange rates or transaction fees used by different banks or credit card issuers.`,
      't_pricing.recurring_info.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc4: [
      `다음 달 정기 결제 취소는 현지 시간 기준 결제일 "8AM” 까지 가능합니다.`,
      `If you cancel your plan before 8am on the billing date, you will not be charged after your current billing cycle.`,
      't_pricing.recurring_info.desc4',
      ``,
      ``,
      ``,
      ``,
    ],
    desc5: [
      `정기 결제가 결제수단 등의 문제로 늦어지면, 최종 결제가 이루어지는 시점 기준으로 정기 결제일이 재설정 됩니다.`,
      `If the payment is made after the billing date, your billing date will be changed to the date on which the payment went through.`,
      't_pricing.recurring_info.desc5',
      ``,
      ``,
      ``,
      ``,
    ],
    desc6: [
      `정기 결제 실패 시 7일 후 자동 결제를 1회 시도합니다. 최종적으로 실패되면 이용 중단 상태로 최종 변경됩니다. 내 플랜 > 결제 정보 변경 에서 유효한 결제 수단으로 정기 결제 정보를 갱신하면 보류 중인 정기 결제가 재개됩니다.`,
      `After any failed transaction, you will be charged again seven days later. If the second attempt also fails, we will pause your monthly plan. Update your payment information on [My Plans] > [Edit Payment] with a viable payment option to resume your plan.`,
      't_pricing.recurring_info.desc6',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  refund_info: {
    default: [`환불정책`, `Refund policy`, 't_pricing.refund_info.default', ``, ``, ``, ``],
    desc1: [
      `환불은 고객센터 [1:1 문의]를 통해서만 가능합니다.`,
      `Refunds are only available via [Chat] with our Help Center.`,
      't_pricing.refund_info.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `전액환불: 사용한 수업권 없을 경우, 결제 시점으로 1개월 이내 전액 환불 가능합니다.`,
      `Full refund: An unused plan is eligible for a full refund within one month following the date of payment.`,
      't_pricing.refund_info.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      `부분환불: 1) 결제 시점으로부터 15일 이내 2) 지급된 수업권을 절반 이하로 사용한 경우, [실결제금액]에서 10% 위약금을 제하고 [기이용수업권] 비중만큼 차감하여 지급합니다. (두 조건이 모두 충족되어야 합니다)`,
      `Partial refund: A used plan is eligible for a refund amount equal to the [total amount paid] minus 10% fee minus [the value of used credits] if 1) 15 days or less have lapsed since the date of payment, and 2) half or less of the credits have been used.`,
      't_pricing.refund_info.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3_add: [
      `(예: 수강기간 15일이 남고 수업권이 절반 이상 남은 경우, 환불 가능합니다)`,
      `(Example: If you have half or more of the credits remaining and 15 days or more left until expiration, you're eligible for a refund.)`,
      't_pricing.refund_info.desc3_add',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3_add2: [
      `사용한 수업권이 있을 경우, 결제 이후 16일이 되는 시점부터 환불이 불가합니다.`,
      `If you have used a credit or more from your plan, refund becomes unavailable from the 16th day following the date of payment.`,
      't_pricing.refund_info.desc3_add2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc4: [
      `환불시 은행 및 카드 결제 수단의 수수료는 차감되고 환불될 수 있습니다.`,
      `No refunds are available for any transaction fees charged by your bank or credit card issuer.`,
      't_pricing.refund_info.desc4',
      ``,
      ``,
      ``,
      ``,
    ],
    desc5: [
      `정기 결제시 사용한 결제 수단과 다른 방식으로 환불할 경우 발생된 수수료는 반환되지 않습니다.`,
      `No refunds are available for any transaction fees charged for the refund made to a card/bank account different from the original payment method.`,
      't_pricing.refund_info.desc5',
      ``,
      ``,
      ``,
      ``,
    ],
    desc6: [
      `수강기간이 연장된 수업권은 환불이 불가합니다.`,
      `No refunds are available for extended credits.`,
      't_pricing.refund_info.desc6',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  credit_subtitle: [
    `이 상품은 매달 정기적으로 결제가 진행되는 상품입니다.`,
    `Billed automatically on the same date/time every month`,
    't_pricing.credit_subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  payment_info: {
    desc1: [
      `매월 동일 일자에 자동 결제 되도록 설정됩니다.`,
      `You will be automatically charged on the same date every month.`,
      't_pricing.payment_info.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `첫 결제일이 31일 이라면, 정기 결제는 매달 말일로 설정됩니다.`,
      `If you purchased the plan on the 31st, you will be charged on the last day of shorter months.`,
      't_pricing.payment_info.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  lesson_times: [`수업 시간`, `Lesson Times`, 't_pricing.lesson_times', ``, ``, ``, ``],
  per_month: (product_name) => [
    `${product_name} / 월`,
    `${product_name} / month`,
    't_pricing.per_month',
    ``,
    ``,
    ``,
    ``,
  ],
  total_today: (regular_price) => [
    `오늘의 금액 합계 ("${regular_price}/월. 요금제")`,
    `Your total today (for a "${regular_price}/mo. plan")`,
    't_pricing.total_today',
    ``,
    ``,
    ``,
    ``,
  ],
  only_applied_today: [
    `첫 구매 할인은 오늘 지불한 총 결제액에만 적용되며, 향후에는 자동으로 패키지 구매 할인만 적용된 금액이 청구됩니다.`,
    `The first purchase discount will only be applied for your total payment today, and you will be automatically charged the package discounted price onwards.`,
    't_pricing.only_applied_today',
    ``,
    ``,
    ``,
    ``,
  ],
  tab: {
    precaution: [`유의사항`, `Terms`, 't_pricing.tab.precaution', ``, ``, ``, ``],
  },
  including_trial: [
    `첫 구매 혜택도 첫 수업에 포함됨`,
    `*Includes trial lesson.`,
    't_pricing.including_trial ',
    ``,
    ``,
    ``,
    ``,
  ],
  not_opened_1: [
    `첫 수업 시작 전까지`,
    `Lesson days are frozen until first lesson`,
    't_pricing.not_opened_1',
    ``,
    ``,
    ``,
    ``,
  ],
  not_opened_2: [`수강기간 미차감`, ``, 't_pricing.not_opened_2', ``, ``, ``, ``],
  additional_extend: [
    `구매 1년 후 수강기간 자동 차감`,
    `Buy now and start later`,
    't_pricing.additional_extend',
    ``,
    ``,
    ``,
    ``,
  ],
  guarantee3: [`링글이 보장합니다 3`, `Ringle guarantees 3`, 't_pricing.guarantee3', ``, ``, ``, ``],
  qna: {
    title: [`자주 묻는 질문`, `Frequently Asked Questions`, 't_pricing.qna.title', ``, ``, ``, ``],
    list1: {
      main: [
        `어떤 수업권을 선택하면 될까요?`,
        `What type of credit is recommended?`,
        't_pricing.qna.list1.main',
        ``,
        ``,
        ``,
        ``,
      ],
      desc1: [
        `20분과 40분 수업권은 수업 길이에만 차이가 있을 뿐, 진행 방식, 교재, 튜터 선택지는 모두 동일합니다.`,
        `The 20-minute and 40-minute lessons differ only in length of the lessons. The teaching method, textbooks, and tutor options are all identical.`,
        't_pricing.qna.list1.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `20분 수업은 비교적 가벼운 주제로 꾸준한 영어 말하기 습관을 형성하는데 추천하는 수업입니다.`,
        `The 20-minute lesson is recommended for those who are seeking to form a consistent habit of speaking in English with relatively lighter topics.`,
        't_pricing.qna.list1.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `40분 수업은 특정 주제에 대해 심도 있게 대화하거나, 인터뷰/Writing 첨삭에 추천하는 수업입니다.`,
        `The 40-minute class is recommended for those who want to have in-depth conversations on specific topics or for interviews/writing corrections.`,
        't_pricing.qna.list1.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    list2: {
      main: [
        `수업을 예약할 수 있는 시간/횟수가 정해져 있나요?`,
        `Is there a set time/number of times to book classes?`,
        't_pricing.qna.list2.main',
        ``,
        ``,
        ``,
        ``,
      ],
      desc1: [
        `1:1 수업권은 횟수제로 제공되며, 구매하신 수업권 횟수만큼 수강기간 내 자유롭게 수강할 수 있습니다. 일 별 또는 주 별로 수강할 수 있는 수업 횟수에 제한이 없습니다.`,
        `1:1 lessons are offered on a frequency basis, and you can take as many classes as you like during the course period. There is no limit to the number of classes you can take per day or per week.`,
        't_pricing.qna.list2.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `정규 수업은 예약 시점 기준 24시간 이후에 시작하는 수업으로, 튜터와 교재를 자유롭게 선택 가능합니다.`,
        `Regular lessons start 24 hours from the time of reservation, and you can choose tutors and textbooks freely.`,
        't_pricing.qna.list2.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `당일 수업은 예약시점 기준 24시간 이내에 시작하는 수업으로, 수업 1시간 전까지 교재 선정이 가능합니다.`,
        `Last-minute lessons start within 24 hours from the time of reservation, and textbooks can be selected 1 hour before class.`,
        't_pricing.qna.list2.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
      desc4: [
        `[정규 수업권]은 정규 시간 내 자유롭게 이용가능하며, 당일 수업 예약에도 사용할 수 있습니다.`,
        `[Regular lesson credits] can be freely used during regular hours, and can also be used for Last-minute lesson reservations.`,
        't_pricing.qna.list2.desc4',
        ``,
        ``,
        ``,
        ``,
      ],
      desc5: [
        `링글 틴즈 수업은 주중/주말 연중무휴로 아래 시간에 진행됩니다.`,
        `Ringle Teens classes are held on weekdays/weekends throughout the year during the times listed below.`,
        't_pricing.qna.list2.desc5',
        ``,
        ``,
        ``,
        ``,
      ],
      desc6: [
        `오전 05:00 ~ 오후 14:00 (한국 시간 기준)`,
        `5AM to 2PM (Korean Standard Time)`,
        't_pricing.qna.list2.desc6',
        ``,
        ``,
        ``,
        ``,
      ],
      desc7: [
        `저녁 19:00 ~ 오전 05:00 (한국 시간 기준)`,
        `7PM to 5AM (Korean Standard Time)`,
        't_pricing.qna.list2.desc7',
        ``,
        ``,
        ``,
        ``,
      ],
      desc8: [
        `오후 7PM ~ 익일 오후 2:00 PM (한국 시간 기준KST)`,
        `7 PM ~ 2:00 PM the next day (KST)`,
        't_pricing.qna.list2.desc8',
        ``,
        ``,
        ``,
        ``,
      ],
      desc9: [
        `[당일수업권]이란 예약 시점 기준 24시간 이내에 시작하는 [당일 수업]만 예약할 수 있는 수업권으로, [1:1 수업] → [예약] → [당일 수업]에서 사용하실 수 있습니다.`,
        `[Last-minute lesson credit] is a class ticket that can only be reserved for [Last-minute lessons] that start within 24 hours from the time of reservation. [1:1 class] → [Reservation] → You can use it in [Last-minute lessons].`,
        't_pricing.qna.list2.desc9',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    list3: {
      main: [
        `수업권의 수강기간이 궁금해요.`,
        `How long is the course period for lesson credits?`,
        't_pricing.qna.list3.main',
        ``,
        ``,
        ``,
        ``,
      ],
      desc1: [
        `수강기간은 첫 수업을 완료하는 시점부터 차감됩니다.`,
        `The course period is deducted from the point of completion of the first class.`,
        't_pricing.qna.list3.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `단, 수업권 구매 후 1년이 되는 시점까지 수업을 시작하지 않는 경우에는 수강기간이 자동 차감됩니다. 따라서, 수업권 구매 후 1년 내 원하시는 시점에 수업 시작이 가능합니다.`,
        `However, if you do not use your lesson credits up to one year upon purchase, the course period will be automatically deducted. Therefore, you can start your lessons at any time you want within one year of purchasing lesson credits.`,
        't_pricing.qna.list3.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `수강기간의 기준은 수업을 예약하는 시점이 아닌 수강 완료하는 시점입니다.`,
        `The criterion of the course period is the time of completion of the course, not the time of booking the course.`,
        't_pricing.qna.list3.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    list4: {
      main: [`수업 취소 정책이 궁금해요.`, `How do I cancel lessons?`, 't_pricing.qna.list4.main', ``, ``, ``, ``],
      desc1: [
        `수업 시작 기준 24시간 이전에 취소 시, 해당 수업권은 자동 복구됩니다.`,
        `Cancellations made more than 24 hours before the lesson will be refundable and restore the lesson credit.`,
        't_pricing.qna.list4.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `수업 시작 기준 24~2시간 이전 취소 시, 해당 수업권 차감 후 [당일수업권]이 지급됩니다.`,
        `Cancellations made 2-24 hours before the lesson will replace the credit used with a Last-minute credit.`,
        't_pricing.qna.list4.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `수업 시작 전 2시간 이내 취소 시, 해당 수업권은 차감되며 [당일수업권]은 지급되지 않습니다.`,
        `Cancellations made less than 2 hours before the lesson won't be refundable`,
        't_pricing.qna.list4.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    list5: {
      main: [`수업권 환불 정책이 궁금해요.`, `How do I get a refund?`, 't_pricing.qna.list5.main', ``, ``, ``, ``],
      desc1: [
        `첫 수업 불만족 시 100% 환불을 보장합니다.`,
        `We offer a 100% money-back guarantee if you're not satisfied with your first lesson.`,
        't_pricing.qna.list5.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `중도 환불 신청 시 잔여 수업권은 위약금 없이 100% 환불 가능합니다.`,
        `Any remaining tuition is 100% refundable without penalty.`,
        't_pricing.qna.list5.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `만료된 수업권 환불이 불가하나, 만료 전 기간 연장 요청 시 추가 기간 연장을 지원합니다.`,
        `We do not offer refunds for expired enrollments, but we do offer additional period extensions if requested prior to expiration.`,
        't_pricing.qna.list5.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
      desc4: [``, ``, 't_pricing.qna.list5.desc4', ``, ``, ``, ``],
      desc5: [``, ``, 't_pricing.qna.list5.desc5', ``, ``, ``, ``],
    },
  },
  min_40: [`40분`, `40min`, 't_pricing.min_40', ``, ``, ``, ``],
  min_20: [`20분`, `20min`, 't_pricing.min_20', ``, ``, ``, ``],
  pay_in_pieces: [`2개 카드 분할 결제 가능`, ``, 't_pricing.pay_in_pieces', ``, ``, ``, ``],
  interest_free: [`최대 7개월 무이자 할부`, ``, 't_pricing.interest_free', ``, ``, ``, ``],
  notice_usd: [
    ``,
    `The price shown in your local currency reflects today's exchange rate.`,
    't_pricing.notice_usd',
    ``,
    ``,
    ``,
    ``,
  ],
  notice_exchange: [
    ``,
    `The final transaction price may differ due to the currency exchange rate
and processing fee applied by your credit card company.`,
    't_pricing.notice_exchange',
    ``,
    ``,
    ``,
    ``,
  ],
  per_credit: [`/크레딧`, `per credit`, 't_pricing.per_credit', ``, ``, ``, ``],
  caption: [
    <>
      5AM ~ 14PM
      <br />
      19PM ~ 5AM (KST)
    </>,
    <>
      5AM ~ 14PM
      <br />
      19PM ~ 5AM (KST)
    </>,
    't_pricing.caption',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle_40min: [`하나의 주제에 대해 심도있게`, `Topical & In-depth`, 't_pricing.subtitle_40min', ``, ``, ``, ``],
  title_40min: [`40분 수업`, `40-min lessons`, 't_pricing.title_40min', ``, ``, ``, ``],
  subtitle_20min: [`부담없이 영어 습관을 만드는`, `Short & Compact`, 't_pricing.subtitle_20min', ``, ``, ``, ``],
  title_20min: [`20분 수업`, `20-min lessons`, 't_pricing.title_20min', ``, ``, ``, ``],
  card: {
    date: [`일`, `days`, 't_pricing.card.date', ``, ``, ``, ``],
    credit: [`/회`, `/credit`, 't_pricing.card.credit', ``, ``, ``, ``],
    total: [`최종 결제 금액`, `total`, 't_pricing.card.total', ``, ``, ``, ``],
    see_detail: [`자세히 보기`, `see detail`, 't_pricing.card.see_detail', ``, ``, ``, ``],
    btn: [`구매하기`, `Purchase`, 't_pricing.card.btn', ``, ``, ``, ``],
    label_newcomer: [`신규회원 추천`, `For Newcomers`, 't_pricing.card.label_newcomer', ``, ``, ``, ``],
    label_promo: [`선착순 100명 한정`, `For the first 100`, 't_pricing.card.label_promo', ``, ``, ``, ``],
    label_popular: [`인기 수업권`, `Popular`, 't_pricing.card.label_popular', ``, ``, ``, ``],
    min_40: [`40분`, `40min`, 't_pricing.card.min_40', ``, ``, ``, ``],
    min_20: [`20분`, `20min`, 't_pricing.card.min_20', ``, ``, ``, ``],
    label_benefit: [`최대 혜택`, `Max Benefit`, 't_pricing.card.label_benefit', ``, ``, ``, ``],
    label_summer: (n) => [
      `${n}회 추가 증정 (20분)`,
      `${n} extra credits (20min)`,
      't_pricing.card.label_summer',
      ``,
      ``,
      ``,
      ``,
    ],
    extra_discount_won: (extra_discount) => [
      `기존 대비 ${extra_discount}원 할인`,
      `Extra ₩${extra_discount}K off`,
      't_pricing.card.extra_discount_won',
      ``,
      ``,
      ``,
      ``,
    ],
    extra_discount_usd: (extra_discount) => [
      `기존 대비 $${extra_discount} 할인`,
      `Extra $${extra_discount} off`,
      't_pricing.card.extra_discount_usd',
      ``,
      ``,
      ``,
      ``,
    ],
    credits: (amount) => [`${amount}회 수업권`, `${amount} Credits`, 't_pricing.card.credits', ``, ``, ``, ``],
    discount: (amount) => [`${amount}% ↓`, `${amount}% ↓`, 't_pricing.card.discount', ``, ``, ``, ``],
    price_per_lesson_won: (amount) => [
      `${amount}원`,
      `₩${amount}`,
      't_pricing.card.price_per_lesson_won',
      ``,
      ``,
      ``,
      ``,
    ],
    price_per_lesson_usd: (amount) => [
      `$${amount}`,
      `$${amount}`,
      't_pricing.card.price_per_lesson_usd',
      ``,
      ``,
      ``,
      ``,
    ],
    count: [`/회`, `/Credit`, 't_pricing.card.count', ``, ``, ``, ``],
    original_price_total_won: (amount) => [
      `${amount}원`,
      `₩${amount}`,
      't_pricing.card.original_price_total_won',
      ``,
      ``,
      ``,
      ``,
    ],
    original_price_total_usd: (amount) => [
      `$${amount}`,
      `$${amount}`,
      't_pricing.card.original_price_total_usd',
      ``,
      ``,
      ``,
      ``,
    ],
    final_price_total_won: (amount) => [
      `${amount}원`,
      `₩${amount}`,
      't_pricing.card.final_price_total_won',
      ``,
      ``,
      ``,
      ``,
    ],
    final_price_total_usd: (amount) => [
      `$${amount}`,
      `$${amount}`,
      't_pricing.card.final_price_total_usd',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit1: (amount) => [
      `1:1 수업 (${amount}분)`,
      `1:1 lesson (${amount}-mins)`,
      't_pricing.card.benefit1',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit1_count: (amount) => [`${amount}회`, `${amount} credits`, 't_pricing.card.benefit1_count', ``, ``, ``, ``],
    benefit1_1: [`튜터 피드백 리포트`, `Tutor’s feedback report`, 't_pricing.card.benefit1_1', ``, ``, ``, ``],
    benefit1_2: [`라이팅 숙제 첨삭`, `Essay editing`, 't_pricing.card.benefit1_2', ``, ``, ``, ``],
    benefit2: [`AI 레벨 진단`, `AI level assessment`, 't_pricing.card.benefit2', ``, ``, ``, ``],
    benefit2_count: (amount) => [`${amount}회`, `${amount} credits`, 't_pricing.card.benefit2_count', ``, ``, ``, ``],
    benefit3: [`수강기간`, `Validity period`, 't_pricing.card.benefit3', ``, ``, ``, ``],
    benefit3_count: (amount) => [`${amount}일`, `${amount} days`, 't_pricing.card.benefit3_count', ``, ``, ``, ``],
    benefit3_count_extra: (amount) => [
      <>
        {amount}
        <span className="text-[#4495FF]">+{amount}일</span>
      </>,
      <>
        {amount}
        <span className="text-[#4495FF]">+{amount} days</span>
      </>,
      't_pricing.card.benefit3_count_extra',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit4: [`원서읽기 특강`, `Read Together: Book`, 't_pricing.card.benefit4', ``, ``, ``, ``],
    benefit4_count: (amount) => [`${amount}권`, `${amount} books`, 't_pricing.card.benefit4_count', ``, ``, ``, ``],
    benefit4_count_extra: (normal, bonus) => [
      <>
        {normal}
        <span className="text-[#4495FF]">+{bonus}권</span>
      </>,
      <>
        {normal}
        <span className="text-[#4495FF]">+{bonus} books</span>
      </>,
      't_pricing.card.benefit4_count_extra',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit4_desc: (month) => [
      `*${month} 원서읽기를 참여할 수 있습니다.`,
      `*Get access to ${month} Read Together lecture.`,
      't_pricing.card.benefit4_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_purchase: [`구매`, `Purchase`, 't_pricing.card.btn_purchase', ``, ``, ``, ``],
    time_desc: [
      `* 수업 시간: 한국 시간 기준 5AM-14PM, 19PM - 5AM (14PM-19PM에만 수업을 진행하지 않습니다.)`,
      `*Regular lesson hours: 5AM - 14PM, 19PM - 5AM (KST) / No lessons are held between 14PM - 19PM.`,
      't_pricing.card.time_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    extra_discount: (extra_discount) => [
      `기존 대비 ${extra_discount} 할인`,
      `Extra ${extra_discount} off`,
      't_pricing.card.extra_discount',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_label: [`겨울캠프 혜택`, `Winter Camp`, 't_pricing.card.benefit_label', ``, ``, ``, ``],
    benefit_label_desc: [
      `*25.1~25.2에 진행됩니다.`,
      `*Jan-Feb 2025 lectures.`,
      't_pricing.card.benefit_label_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_secret1: [`미국 교과과정 특강`, `USA course`, 't_pricing.card.benefit_secret1', ``, ``, ``, ``],
    benefit_secret1_desc: (amount) => [
      `${amount}회`,
      `${amount} credits`,
      't_pricing.card.benefit_secret1_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_secret2: [`모의유엔 특강`, `Model UN`, 't_pricing.card.benefit_secret2', ``, ``, ``, ``],
    benefit_secret2_desc: (amount) => [
      `${amount}회`,
      `${amount} credits`,
      't_pricing.card.benefit_secret2_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_secret3: [`영자신문읽기 특강`, `Read Together: News`, 't_pricing.card.benefit_secret3', ``, ``, ``, ``],
    benefit_secret3_desc: (amount) => [
      `${amount}회`,
      `${amount} credits`,
      't_pricing.card.benefit_secret3_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    promo_discount_won: (amount) => [
      `가을학기 ${amount}원 할인`,
      `Promo ₩${amount} off`,
      't_pricing.card.promo_discount_won',
      ``,
      ``,
      ``,
      ``,
    ],
    promo_discount_usd: (amount) => [
      `가을학기 $${amount} 할인`,
      `Promo $${amount} off`,
      't_pricing.card.promo_discount_usd',
      ``,
      ``,
      ``,
      ``,
    ],
    point_discount: [`포인트 추가 할인`, `Extra points`, 't_pricing.card.point_discount', ``, ``, ``, ``],
    desc_price_per_lesson_won: (amount) => [
      `회당 ${amount}원`,
      `₩${amount} per credit`,
      't_pricing.card.desc_price_per_lesson_won',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_price_per_lesson_usd: (amount) => [
      `회당 $${amount}`,
      `$${amount} per credit`,
      't_pricing.card.desc_price_per_lesson_usd',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_label2: [`별도 혜택`, `Extra benefit`, 't_pricing.card.benefit_label2', ``, ``, ``, ``],
    benefit_label3: [`첫 구매 혜택`, `First purchase`, 't_pricing.card.benefit_label3', ``, ``, ``, ``],
  },
  normal_price: [`정규 수업권`, `Regular`, 't_pricing.normal_price', ``, ``, ``, ``],
  see_detail: {
    discount_promo: [`가을학기 할인`, `Fall Discount`, 't_pricing.see_detail.discount_promo', ``, ``, ``, ``],
    discount_summer: [`여름캠프 정규 할인`, `Summer Discount`, 't_pricing.see_detail.discount_summer', ``, ``, ``, ``],
    discount_intensive: [
      `집중바 할인`,
      `Intensive Program Discount`,
      't_pricing.see_detail.discount_intensive',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  hero: {
    label_old1: [`마감임박`, `Ending soon`, 't_pricing.hero.label_old1', ``, ``, ``, ``],
    label_old2: [`| 얼리버드 추가 할인`, `| Early Bird Discount`, 't_pricing.hero.label_old2', ``, ``, ``, ``],
    title1: [`아이비리그 튜터와의`, `Online Summer Camp`, 't_pricing.hero.title1', ``, ``, ``, ``],
    title2: [`틴즈 온라인 여름 캠프`, `with Ivy League Tutors`, 't_pricing.hero.title2', ``, ``, ``, ``],
    subtitle: [
      `얼리버드 등록 6.8 (목) - 6.20 (화)   ㅣ   캠프 기간 7.21 (금) - 8.20 (일) KST`,
      `Early Bird Registration Jun 8 - 20 ㅣ Summer Camp Jul 21 - Aug 20 KST`,
      't_pricing.hero.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit1: [
      `첫 1회 수업 \n 불만족 시 100% 환불`,
      `100% money-back guarantee \n for your first lesson`,
      't_pricing.hero.benefit1',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit2: [
      `수업 하루 전까지 \n 스케줄 변경 가능`,
      `Reschedule up to \n 1 day before class`,
      't_pricing.hero.benefit2',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit3: [
      `얼리버드 한정 \n 최대 42% 할인`,
      `Early bird discount \n up to 42%`,
      't_pricing.hero.benefit3',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit4: [
      `여름 캠프 한정 \n 최대 38% 할인`,
      `Early bird discount \n up to 38%`,
      't_pricing.hero.benefit4',
      ``,
      ``,
      ``,
      ``,
    ],
    countdown: [`얼리버드 종료까지`, `Early bird ends in`, 't_pricing.hero.countdown', ``, ``, ``, ``],
    countdown_day: [`일 후 종료`, `Day(s) left`, 't_pricing.hero.countdown_day', ``, ``, ``, ``],
    banner_title: [
      <>
        <span className="font-bold">기존 고객 한정</span> <span>시크릿 혜택 마감까지!</span>
      </>,
      <>
        <span>Only for existing customers:</span> <span className="font-bold">Secret offer ends</span>
      </>,
      't_pricing.hero.banner_title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  camp_price: {
    title: [`봄학기 수업권`, `Spring Program`, 't_pricing.camp_price.title', ``, ``, ``, ``],
    label: [`한정판매`, `Limited`, 't_pricing.camp_price.label', ``, ``, ``, ``],
  },
  summercamp_price: {
    title: [`여름캠프 수업권`, `Summer camp`, 't_pricing.summercamp_price.title', ``, ``, ``, ``],
    label: [`한정`, `limit`, 't_pricing.summercamp_price.label', ``, ``, ``, ``],
  },
  intensive_price: {
    title: [`집중반 수업권`, `Intensive Class`, 't_pricing.intensive_price.title', ``, ``, ``, ``],
  },
  banner: {
    title: [<>특별 포인트 만료까지</>, <>Special Gift Points expires in</>, 't_pricing.banner.title', ``, ``, ``, ``],
  },
  label: {
    promo_amount_won: (amount) => [
      `가을학기 추가 ${amount} 할인`,
      `Promo ₩${amount}K off`,
      't_pricing.label.promo_amount_won',
      `比原價便宜 ₩${amount}K`,
      `比原价便宜 ₩${amount}K`,
      `Promo ₩${amount}K off`,
      `Promo ₩${amount}K off`,
    ],
    promo_amount_usd: (amount) => [
      `가을학기 추가 ${amount} 할인`,
      `Promo $${amount} off`,
      't_pricing.label.promo_amount_usd',
      `比原價便宜 $${amount}`,
      `比原价便宜 $${amount}`,
      `Promo $${amount} off`,
      `Promo $${amount} off`,
    ],
    promo_2_amount_won: (amount) => [
      `집중반 추가 ${amount} 할인`,
      `Promo ₩${amount}K off`,
      't_pricing.label.promo_2_amount_won',
      ``,
      ``,
      ``,
      ``,
    ],
    promo_2_amount_usd: (amount) => [
      `집중반 추가 ${amount} 할인`,
      `Promo $${amount} off`,
      't_pricing.label.promo_2_amount_usd',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  select_promotion: {
    title: [
      `자녀에게 맞는 수업권 찾기`,
      `Choose the lesson credit that fits your child`,
      't_pricing.select_promotion.title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  select_promotion_1: {
    label: [`기존 고객 한정`, `Only for Existing Customers`, 't_pricing.select_promotion_1.label', ``, ``, ``, ``],
    title: [`추석 선물 패키지`, `Chuseok Gift Package`, 't_pricing.select_promotion_1.title', ``, ``, ``, ``],
    desc: [`선물하기 전용 수업권`, `Gift-only credits`, 't_pricing.select_promotion_1.desc', ``, ``, ``, ``],
  },
  select_promotion_2: {
    title: [`가을학기 수업권`, `Autumn Promotion`, 't_pricing.select_promotion_2.title', ``, ``, ``, ``],
    desc: [`기본 수업권`, `Regular Credits`, 't_pricing.select_promotion_2.desc', ``, ``, ``, ``],
  },
  select_time: {
    title: [`수업 시간 선택`, `Select lesson duration`, 't_pricing.select_time.title', ``, ``, ``, ``],
    mins_20: [`20분 수업`, `20-min lesson`, 't_pricing.select_time.mins_20', ``, ``, ``, ``],
    mins_40: [`40분 수업`, `40-min lesson`, 't_pricing.select_time.mins_40', ``, ``, ``, ``],
    mins_20_desc: [
      `짧게 자주 수업해 영어로 일상 대화 능력을 향상시키고 싶은 학생에게 추천합니다.`,
      `Recommended for those who wants to improve their daily English conversation skills.`,
      't_pricing.select_time.mins_20_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    mins_40_desc: [
      `최신 시사 이슈부터 원서 읽고 토론까지 하나의 주제에 대해 심도 있는 수업을 희망하는 학생에게 추천합니다.`,
      `Recommended for those who wish to engage in in-depth discussions on a topic, from the latest social issues to debates based on books.`,
      't_pricing.select_time.mins_40_desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  credit_name: {
    monthly: (month) => [
      `${month}개월 수업권`,
      `${month}month package`,
      't_pricing.credit_name.monthly',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  credit_pricing: {
    monthly: [`개월`, `monthly`, 't_pricing.credit_pricing.monthly', ``, ``, ``, ``],
  },
  gift_name: (number) => [`선물하기 ${number}회`, ``, 't_pricing.gift_name', ``, ``, ``, ``],
  select_promotion_3: {
    title: [`추석 선물 패키지`, `Chuseok Gift Package`, 't_pricing.select_promotion_3.title', ``, ``, ``, ``],
    desc: [`선물하기 전용 수업권`, `Gift-only credits`, 't_pricing.select_promotion_3.desc', ``, ``, ``, ``],
    label: [`추석 이벤트`, `Chuseok Event`, 't_pricing.select_promotion_3.label', ``, ``, ``, ``],
  },
  event_banner: {
    label: [`EVENT`, `EVENT`, 't_pricing.event_banner.label', ``, ``, ``, ``],
    point_event_title: [
      `신규 고객 포인트 혜택`,
      `Extra points only for new comers`,
      't_pricing.event_banner.point_event_title',
      ``,
      ``,
      ``,
      ``,
    ],
    point_event_title_after_login: (name) => [
      `${name}님의 보유 포인트`,
      `My points`,
      't_pricing.event_banner.point_event_title_after_login',
      ``,
      ``,
      ``,
      ``,
    ],
    point_event_totalpoints: (amount) => [
      `${amount}P`,
      `${amount}P`,
      't_pricing.event_banner.point_event_totalpoints',
      ``,
      ``,
      ``,
      ``,
    ],
    point_exp_timer: (time) => [
      <>포인트 사용기한 {time}</>,
      <>Points expire in {time}</>,
      't_pricing.event_banner.point_exp_timer',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `*포인트가 적용된 가격입니다.`,
      `*Points are applied in the price.`,
      't_pricing.event_banner.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_signup: [
      `가입하고 포인트 혜택받기`,
      `Sign up to apply points`,
      't_pricing.event_banner.btn_signup',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const t_purchase = {
  dates: [`날짜들`, `Dates`, 't_purchase.dates', ``, ``, ``, ``],
  payment_date: [
    `결제 예정일`,
    <>
      Payment
      <br />
      date
    </>,
    't_purchase.payment_date',
    ``,
    ``,
    ``,
    ``,
  ],
  point_option: [
    `정기 결제가 진행될 때 항상 포인트를 사용합니다.`,
    `Always redeem my points toward my monthly payments.`,
    't_purchase.point_option',
    ``,
    ``,
    ``,
    ``,
  ],
  point_option_info: [
    `정기 결제가 진행될 때 자동으로 보유 포인트를 전부 사용하여 결제를 진행합니다.`,
    `Check the box to automatically use all your points for your monthly payments.`,
    't_purchase.point_option_info',
    ``,
    ``,
    ``,
    ``,
  ],
  title_card: [`카드로 결제하기`, `Pay with card`, 't_purchase.title_card', ``, ``, ``, ``],
  header: [`결제하기`, `Purchase`, 't_purchase.header', ``, ``, ``, ``],
  point: [`포인트`, `Points`, 't_purchase.point', ``, ``, ``, ``],
  my_point: [`보유포인트`, `My points`, 't_purchase.my_point', ``, ``, ``, ``],
  use_all: [`전부 사용`, `Use all`, 't_purchase.use_all', ``, ``, ``, ``],
  price: [`결제금액`, `Subtotal`, 't_purchase.price', ``, ``, ``, ``],
  usual_price: [`정가가격`, `Regular price`, 't_purchase.usual_price', ``, ``, ``, ``],
  discount_sum: [`총 할인금액`, `Total discount`, 't_purchase.discount_sum', ``, ``, ``, ``],
  use_point: [`포인트 사용`, `Points applied`, 't_purchase.use_point', ``, ``, ``, ``],
  total_price: [`최종 결제 금액`, `Your total`, 't_purchase.total_price', ``, ``, ``, ``],
  switch_to_other: [`해외 결제 수단으로 바꾸기`, ``, 't_purchase.switch_to_other', ``, ``, ``, ``],
  switch_to_kr: [``, `Switch to Korean payment method`, 't_purchase.switch_to_kr', ``, ``, ``, ``],
  purchase_method: [`결제 방식 선택`, ``, 't_purchase.purchase_method', ``, ``, ``, ``],
  purchase_method_card: [``, `Pay with card`, 't_purchase.purchase_method_card', ``, ``, ``, ``],
  domestic_payment: [`국내 카드 결제`, ``, 't_purchase.domestic_payment', ``, ``, ``, ``],
  pay_normal: [`카드 일반 결제`, ``, 't_purchase.pay_normal', ``, ``, ``, ``],
  pay_card: [`카드 직접 입력`, ``, 't_purchase.pay_card', ``, ``, ``, ``],
  pay_kakao: [`카카오페이`, ``, 't_purchase.pay_kakao', ``, ``, ``, ``],
  pay_samsung: [`삼성페이`, ``, 't_purchase.pay_samsung', ``, ``, ``, ``],
  pay_payco: [`PAYCO`, ``, 't_purchase.pay_payco', ``, ``, ``, ``],
  pay_naver: [`네이버페이(카드결제)`, ``, 't_purchase.pay_naver', ``, ``, ``, ``],
  pay_account: [`실시간 계좌 이체`, ``, 't_purchase.pay_account', ``, ``, ``, ``],
  pay_viryual: [`가상 계좌 발급`, ``, 't_purchase.pay_viryual', ``, ``, ``, ``],
  pay_two_card: [`2개 카드 결제`, ``, 't_purchase.pay_two_card', ``, ``, ``, ``],
  installment_payment: [`분할 결제`, ``, 't_purchase.installment_payment', ``, ``, ``, ``],
  note_1: [
    `구매 후 1년까지는 첫 수업 완료 시점을 기준으로 수강기간이 차감됩니다.`,
    `Expiration dates start counting down from the date you complete the first lesson up to a year after the purchase date.`,
    't_purchase.note_1',
    ``,
    ``,
    ``,
    ``,
  ],
  note_2: [
    `구매 후 1년이 되는 시점부터는 사용 이력이 없어도 수강기간이 차감됩니다.`,
    `Unused credits' expiration dates will start counting down a year after the purchase date.`,
    't_purchase.note_2',
    ``,
    ``,
    ``,
    ``,
  ],
  note_3: [`국내 카드만 가능`, ``, 't_purchase.note_3', ``, ``, ``, ``],
  note_4: [
    `최종 결제는 미국 달러로 결제됩니다.`,
    `Payments will be processed in USD.`,
    't_purchase.note_4',
    ``,
    ``,
    ``,
    ``,
  ],
  purcahse_agreement: [
    `결제 내역을 확인하였으며 전자상거래법 및 링글 정책에 의거하여 환불이 진행되는 것에 동의합니다.`,
    `I confirm my purchase and agree to Rignle’s terms and`,
    't_purchase.purcahse_agreement',
    ``,
    ``,
    ``,
    ``,
  ],
  purchase: [`결제하기`, `conditions.`, 't_purchase.purchase', ``, ``, ``, ``],
  title_point: [`포인트`, `Points`, 't_purchase.title_point', ``, ``, ``, ``],
  caption_1: [`보유 포인트`, `Your points`, 't_purchase.caption_1', ``, ``, ``, ``],
  caption_2: [
    `보유 포인트를 사용하면 할인된 가격으로 구매할 수 있어요`,
    `Use your points toward this purchase.`,
    't_purchase.caption_2',
    ``,
    ``,
    ``,
    ``,
  ],
  btn: [`모두 사용`, `Use all`, 't_purchase.btn', ``, ``, ``, ``],
  desc: [
    `할부 결제는 국내 카드 (Korean Card) 으로만 가능합니다.`,
    `Installment payments are only available with Korean card(국내 카드)`,
    't_purchase.desc',
    ``,
    ``,
    ``,
    ``,
  ],
  complete: {
    title: [`결제가 완료됐어요.`, `Payment completed`, 't_purchase.complete.title', ``, ``, ``, ``],
    detail_caption_1: [`수강기간`, `Expires in`, 't_purchase.complete.detail_caption_1', ``, ``, ``, ``],
    detail_caption_2: [`정가`, `Regular price`, 't_purchase.complete.detail_caption_2', ``, ``, ``, ``],
    detail_caption_3: [`총 할인 금액`, `Total discount`, 't_purchase.complete.detail_caption_3', ``, ``, ``, ``],
    detail_caption_4: [`프로모션 할인`, `Promo discount`, 't_purchase.complete.detail_caption_4', ``, ``, ``, ``],
    detail_caption_5: [`포인트 사용`, `Points applied`, 't_purchase.complete.detail_caption_5', ``, ``, ``, ``],
    detail_caption_6: [`최종 결제 금액`, `Your total`, 't_purchase.complete.detail_caption_6', ``, ``, ``, ``],
    bonus_title: [`추가 혜택 지급 완료`, `Bonus`, 't_purchase.complete.bonus_title', ``, ``, ``, ``],
    bonus_caption: [
      `특강 이용권으로 약속드린 특강 혜택을 모두 참여할 수 있어요.`,
      `With your Webinar Pass, you can participate in all the promised webinar benefits.`,
      't_purchase.complete.bonus_caption',
      ``,
      ``,
      ``,
      ``,
    ],
    expiration_title: [`수강 기간 안내`, `Credit information`, 't_purchase.complete.expiration_title', ``, ``, ``, ``],
    expiration_caption_1: [
      `첫 수업 완료 시점을 기준으로 수강기간이 차감됩니다.`,
      `The validity period will only begin to expire after your first lesson.`,
      't_purchase.complete.expiration_caption_1',
      ``,
      ``,
      ``,
      ``,
    ],
    expiration_caption_2: [
      `단, 구매 후 1년 되는 시점부터는 수업 이력이 없어도 수강기간이 차감됩니다.`,
      `However, unused credits will start expiring one year after purchase.`,
      't_purchase.complete.expiration_caption_2',
      ``,
      ``,
      ``,
      ``,
    ],
    receipt_desc: [
      `영수증/출석증은 PC에서 오른쪽 상단 프로필 > 수업권/증명서류 탭에서 PDF로 다운로드 가능합니다.`,
      `You can download your lesson certificates & proof of attendance under Credits.`,
      't_purchase.complete.receipt_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    receipt_btn: [`바로가기`, `Go`, 't_purchase.complete.receipt_btn', ``, ``, ``, ``],
    btn_course: [
      `추천 코스로 수업 예약하기`,
      `Try out a curated course`,
      't_purchase.complete.btn_course',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const t_purchase_completed = {
  exp_date_info: [
    `정기결제 수업권은 다음 정기 결제일 전날 23:59까지 수강할 수 있음`,
    `Monthly credits are valid until 23:59 pm on the day before the next billing date.`,
    't_purchase_completed.exp_date_info',
    ``,
    ``,
    ``,
    ``,
  ],
  validity: [`수강기간`, `Monthly validity`, 't_purchase_completed.validity', ``, ``, ``, ``],
  points_option: [`포인트 옵션`, `Points`, 't_purchase_completed.points_option', ``, ``, ``, ``],
  dates: [`결제 시작일`, `Purchase date`, 't_purchase_completed.dates', ``, ``, ``, ``],
  payments_date: [`결제 예정일`, `Billing date`, 't_purchase_completed.payments_date', ``, ``, ``, ``],
  point_selected_info: [
    `내 플랜 > 결제 수단 변경에서 옵션을 변경할 수 있습니다.`,
    `You can change your option on [My Plans] > [Edit Payment]`,
    't_purchase_completed.point_selected_info',
    ``,
    ``,
    ``,
    ``,
  ],
  credit_info: [
    `수업권은 결제 시작 시점부터 사용 가능합니다.`,
    `You can start using your plan from the date of purchase.`,
    't_purchase_completed.credit_info',
    ``,
    ``,
    ``,
    ``,
  ],
  cancel_recurring_info: [
    `이 플랜을 취소하시려면 대시보드 > 내 플랜에서 수정이 가능합니다.`,
    `To end this plan, change your option on [My Plan] > [Edit Payment]`,
    't_purchase_completed.cancel_recurring_info',
    ``,
    ``,
    ``,
    ``,
  ],
  print_receipt_info: [
    `영수증/출석증은 결제 이메일에서 PDF로 다운로드 가능합니다.`,
    `Receipts and proof of attendance can be downloaded from your invoice email.`,
    't_purchase_completed.print_receipt_info',
    ``,
    ``,
    ``,
    ``,
  ],
  btn1: [`OT 자료 열람하기`, `Begin Orientation`, 't_purchase_completed.btn1', ``, ``, ``, ``],
  btn2: [`수업 예약하기`, `Book a Lesson`, 't_purchase_completed.btn2', ``, ``, ``, ``],
  every_month: (product_name) => [
    `${product_name} 매 월`,
    `${product_name} Every Month`,
    't_purchase_completed.every_month',
    ``,
    ``,
    ``,
    ``,
  ],
  subscription_info: [`정기 결제 정보`, `Payment Summary`, 't_purchase_completed.subscription_info', ``, ``, ``, ``],
  use_point: [
    `정기 결제 시 포인트를 사용합니다.`,
    `Use points for monthly payments.`,
    't_purchase_completed.use_point',
    ``,
    ``,
    ``,
    ``,
  ],
  dont_use_point: [
    `포인트를 사용하지 않습니다.`,
    `Do not use points for monthly payments.`,
    't_purchase_completed.dont_use_point',
    ``,
    ``,
    ``,
    ``,
  ],
  from: (created_at) => [`${created_at} 부터`, `From ${created_at}`, 't_purchase_completed.from', ``, ``, ``, ``],
  faq1: {
    title: [
      `수업 예약은 어떻게 하나요?`,
      `How can I book a lesson?`,
      't_purchase_completed.faq1.title',
      ``,
      ``,
      ``,
      ``,
    ],
    answer: [
      `365일 & 하루에도 여러 번 원하시는 일정에 맞춰 수업 예약 가능합니다.<br/><br/>[1:1 수업] &gt; [예약] 탭에서, 원하시는 시간/튜터/ 교재를 직접 선택하여 예약 가능합니다.`,
      `You can book and take lessons as many times as you want all year round.<br/><br/>Click on [1:1 lessons] → [Book] and select the time/tutor/material to book your Ringle Teens lesson.`,
      't_purchase_completed.faq1.answer',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq2: {
    title: [
      `예습은 어떻게 하나요?`,
      `How can I prepare for a lesson?`,
      't_purchase_completed.faq2.title',
      ``,
      ``,
      ``,
      ``,
    ],
    answer: [
      `교재를 미리 읽어보고, 수업에서 다룰 질문을 선택한 후 답변을 준비해보세요.<br/><br/>수업 예약 후 [1:1 수업] &gt; [예정된 수업]에서 교재 및 MP3, 핵심표현 등 다양한 자료를 활용하여 예습해보세요.`,
      `Read the insight of the chosen lesson material and select questions to discuss in your lesson.<br/><br/>After booking a lesson, click on [1:1 lessons] → [Prep&Lesson] to access the insight, MP3, discussion questions, and links to relevant videos & news articles to prepare for your upcoming lesson.`,
      't_purchase_completed.faq2.answer',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq3: {
    title: [
      `수업 입장은 어떻게 하나요?`,
      `How can I enter a lesson?`,
      't_purchase_completed.faq3.title',
      ``,
      ``,
      ``,
      ``,
    ],
    answer: [
      `화상수업을 위해 줌 설치/ 카메라가 설치된 PC에서 수업을 입장해주세요.<br/><br/>1:1 수업 &gt; 예정된 수업 &gt; 오른쪽 하단 [수업 입장] 버튼을 통해, 수업 입장 가능합니다.<br/><br/><Div textGray600>*교재/실시간 교정화면은 모바일 환경에서 제한적으로 보일 수 있습니다.</Div>`,
      `For your 1:1 video lesson, please enter the lesson on a camera-equipped laptop/PC with Zoom installed. <br/><br/>Click on 1:1 Lessons → [Prep&Lesson] → [Enter Lesson] to enter a lesson.<br/><br/><Div textGray600>*Viewing lesson material and real-time lesson notes can be limited on mobile devices.</Div>`,
      't_purchase_completed.faq3.answer',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq4: {
    title: [
      `교재/수업 변경 및 취소는 어떻게 하나요?`,
      `How can I change or cancel a lesson or lesson material?`,
      't_purchase_completed.faq4.title',
      ``,
      ``,
      ``,
      ``,
    ],
    answer: [
      `교재/ 체험 수업 시간 변경 및 취소는 수업 시작 24시간 이전까지 가능합니다.<br/><br/>[정규 수업 취소 정책]
• 수업 시작 24시간 이전 취소: 해당 수업권 자동 복구
• 수업 시작 24시간 이내~ 2시간 이전 취소: 해당 수업권 차감 후, '보충수업권'* 지급
• 수업 시작 2시간 이내 취소: 해당 수업권 차감 및 보충 수업권 미제공<br/><br/><Div textGray600>*보충 수업은 수업 시작 24시간 이내 기존 수강생이 취소하는 수업을 말합니다. 제공된 보충 수업권은 수강 기간이 60일이며, 24시간 이내 가능한 수업 화면에서 예약 가능합니다. </Div>`,
      `You can only change the lesson materials and lesson time 24 hours prior to the start of a lesson.<br/><br/>[Regular Lesson Credit]
• For cancellations made more than 24 hours before the lesson, your regular lesson credit will be restored.
• For cancellations made between 2 and 24 hours before the lesson, your lesson credit will be expired, and a Last-minute Lesson Credit with a validity period of 60 days will be provided.
• For cancellations made less than 2 hours before the lesson, your credit will be expired.<br/><br/><Div textGray600>Last-minute Lesson Credits are used to book lessons that become available in 24 hours due to untaken slots or last-minute cancellations by other students. You can only use the Last-minute Lesson Credits to book lessons on "Last-minute Lesson” </Div>`,
      't_purchase_completed.faq4.answer',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  ask_help_center: [
    `고객센터로 문의하세요`,
    `Please try a different payment method`,
    't_purchase_completed.ask_help_center',
    ``,
    ``,
    ``,
    ``,
  ],
  order: [`주문상품`, `Your cart`, 't_purchase_completed.order', ``, ``, ``, ``],
  lesson_credit: [`수업권명`, `Package`, 't_purchase_completed.lesson_credit', ``, ``, ``, ``],
  lesson_length: [`수업길이`, `Duration`, 't_purchase_completed.lesson_length', ``, ``, ``, ``],
  exp_dates: [`시간대`, `Times`, 't_purchase_completed.exp_dates', ``, ``, ``, ``],
  duration: [
    `수강기간`,
    `Expiration
dates`,
    't_purchase_completed.duration',
    ``,
    ``,
    ``,
    ``,
  ],
  done_desc: (name) => [
    <>
      <Div textD5 sm="text-d3" fontBold>
        {name}님
        <br />
        <Div spanTag textPrimary>
          결제 완료
        </Div>
        되었습니다.
      </Div>
      <Div fontBold textD5>
        이제 링글 수업을 시작해보세요!
      </Div>
    </>,
    <>
      <Div textD5 sm="text-d3" fontBold>
        Hi {name}
        <br />
        <Div spanTag textPrimary>
          Congrats!
        </Div>
      </Div>
      <Div mt8 fontBold textD5>
        We received your payment.
      </Div>
    </>,
    't_purchase_completed.done_desc',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_myplans = {
  title: [`내 요금제`, `My Plans`, 't_myplans.title', ``, ``, ``, ``],
  error_info: [
    `결제 정보를 업데이트해주세요`,
    `Please update your payment details`,
    't_myplans.error_info',
    ``,
    ``,
    ``,
    ``,
  ],
  point_option: [
    `다음 결제 때 포인트 사용하기`,
    `Use points for the next payment`,
    't_myplans.point_option',
    ``,
    ``,
    ``,
    ``,
  ],
  change_option: [
    `다음 정기 결제에 포인트를 사용합니다.`,
    `Use points for the next payment`,
    't_myplans.change_option',
    ``,
    ``,
    ``,
    ``,
  ],
  change_option2: [
    `다음 정기 결제에 포인트를 사용하지 않습니다.`,
    `Do not use points for the next payment`,
    't_myplans.change_option2',
    ``,
    ``,
    ``,
    ``,
  ],
  notice_empty: [
    `죄송합니다, 검색에 해당하는 수업권이 존재하지 않습니다.`,
    `Sorry, no lesson credits found for your search.`,
    't_myplans.notice_empty',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_start: [`구독 시작하기`, `Start a Plan`, 't_myplans.btn_start', ``, ``, ``, ``],
  btn1: [`결제방식 수정하기`, `Edit Payment`, 't_myplans.btn1', ``, ``, ``, ``],
  btn2: [`구독 취소하기`, `End Plan`, 't_myplans.btn2', ``, ``, ``, ``],
  next_payment: [`다음 결제일`, `Next payment on`, 't_myplans.next_payment', ``, ``, ``, ``],
}
export const t_credits = {
  title1: [`내 수업권`, `Remaining Credits`, 't_credits.title1', ``, ``, ``, ``],
  title2: [`수업권 현황`, `Credit History`, 't_credits.title2', ``, ``, ``, ``],
  credits1: [`정규수업권`, `Regular`, 't_credits.credits1', ``, ``, ``, ``],
  credits2: [`당일수업권`, `Last-minute`, 't_credits.credits2', ``, ``, ``, ``],
  text_btn: [`수업권 구매하기`, `Buy More Credits`, 't_credits.text_btn', ``, ``, ``, ``],
  notice: [`수업권 유의사항`, `Terms`, 't_credits.notice', ``, ``, ``, ``],
  desc: [
    <>
      예약 가능 구간 외의 날짜에 수업을 예약하실 수<br />
      없습니다
    </>,
    `You cannot book lessons outside the monthly validity period.`,
    't_credits.desc',
    ``,
    ``,
    ``,
    ``,
  ],
  option1: [`사용가능`, `Available`, 't_credits.option1', ``, ``, ``, ``],
  option2: [`사용만료`, `Expired`, 't_credits.option2', ``, ``, ``, ``],
  option3: [`20분`, `20min`, 't_credits.option3', ``, ``, ``, ``],
  option4: [`40분`, `40min`, 't_credits.option4', ``, ``, ``, ``],
  option5: [`정규수업권`, `Regular`, 't_credits.option5', ``, ``, ``, ``],
  option6: [`당일수업권`, `Last-minute`, 't_credits.option6', ``, ``, ``, ``],
  option_info: [
    `버튼을 누르면 필터를 선택/해지할 수 있습니다.`,
    `Apply filters above.`,
    't_credits.option_info',
    ``,
    ``,
    ``,
    ``,
  ],
  exp_date: [`수강 기간`, `Duration`, 't_credits.exp_date', ``, ``, ``, ``],
  unused: [`남은 횟수`, `Remaining Credits`, 't_credits.unused', ``, ``, ``, ``],
  issue_date: [`발급 시점`, `Issue Date`, 't_credits.issue_date', ``, ``, ``, ``],
  see_detail: [`상세 내역 보기`, `See details`, 't_credits.see_detail', ``, ``, ``, ``],
  book: [`수업 관리북`, `Lesson book`, 't_credits.book', ``, ``, ``, ``],
  btn1: [`더보기`, `See more`, 't_credits.btn1', ``, ``, ``, ``],
  tooltip1: [
    <>
      수업 시작 24시간 이전/이내 수업
      <br />
      모두 예약 가능한 수업권입니다.
    </>,
    <>
      You can book any lessons available
      <br />
      (starting before or after 24 hours from now).
    </>,
    't_credits.tooltip1',
    ``,
    ``,
    ``,
    ``,
  ],
  tooltip2: [
    <>
      수업 시작 24시간 이내 수업만 예약 가능한
      <br />
      수업권입니다. [예약 &gt; 24시간 이내 수업]
    </>,
    `You can only book lessons starting within the next 24 hours.`,
    't_credits.tooltip2',
    ``,
    ``,
    ``,
    ``,
  ],
  notice_empty: [
    `죄송합니다, 보유하고 계신 수업권이 없습니다`,
    `Sorry, no lesson credits found for your search`,
    't_credits.notice_empty',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_start: [`수업권 구매하기`, `Go to Purchase`, 't_credits.btn_start', ``, ``, ``, ``],
  desc1: [
    `수강기간은 첫 수업을 완료하는 시점부터 차감됩니다. (수업권 구매 후 1년 내 원하는 시점에 수업 시작 가능)`,
    `Expiration date countdown will begin after the first lesson is completed.`,
    't_credits.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  desc2: [
    `단, 수업권 구매 후 1년이 되는 시점까지 수업을 시작하지 않는 경우에는 수강기간이 자동 차감됩니다.`,
    `However, if you do not take a lesson within 1 year after your purchase, the credits will automatically expire. Therefore, lessons may begin at anytime within 1 year of your purchase.`,
    't_credits.desc2',
    ``,
    ``,
    ``,
    ``,
  ],
  desc3: [
    `수강기간의 기준은 수업을 예약하는 시점이 아닌 수강 완료하는 시점입니다.`,
    `Expiration dates are calculated as of the time your first lesson is completed, not reserved.`,
    't_credits.desc3',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_credits_detail = {
  title: {
    default: [`수업권 상세 내역`, `Credits Detail`, 't_credits_detail.title.default', ``, ``, ``, ``],
  },
  title1: {
    default: [`남은 수업권`, `Unused Credits`, 't_credits_detail.title1.default', ``, ``, ``, ``],
    list1: [`남은 수업 수`, `Remaining Lessons`, 't_credits_detail.title1.list1', ``, ``, ``, ``],
    list2: [`수업권`, `Credits`, 't_credits_detail.title1.list2', ``, ``, ``, ``],
    list3: [`남은 수업 기간`, `Remaining Duration`, 't_credits_detail.title1.list3', ``, ``, ``, ``],
  },
  title2: {
    default: [`사용 수업권`, `Credits Used`, 't_credits_detail.title2.default', ``, ``, ``, ``],
    list1: [`수업 횟수`, `Lessons Complete`, 't_credits_detail.title2.list1', ``, ``, ``, ``],
    list2: [`수업 내용`, `Lesson Topic`, 't_credits_detail.title2.list2', ``, ``, ``, ``],
    list3: [`수업 시간`, `Lesson Time`, 't_credits_detail.title2.list3', ``, ``, ``, ``],
  },
  dropdown1: [`최근 수업 순`, `Sort by Newest`, 't_credits_detail.dropdown1', ``, ``, ``, ``],
  dropdown2: [`오래된 수업 순`, `Sort by Oldest`, 't_credits_detail.dropdown2', ``, ``, ``, ``],
  btn1: [`더보기`, `See more`, 't_credits_detail.btn1', ``, ``, ``, ``],
}
export const t_upcoming_lesson = {
  goals_for_this_lesson_optional: {
    default: [
      `이 수업을 듣는 목적 (선택)`,
      `Goals for This Lesson (optional)`,
      't_upcoming_lesson.goals_for_this_lesson_optional.default',
      ``,
      ``,
      ``,
      ``,
    ],
    info: [
      `이 수업을 통해 배우고 싶은 것이나 이루고 싶은 것은 무엇인가요?`,
      `Write about what you want to learn or achieve during this lesson.`,
      't_upcoming_lesson.goals_for_this_lesson_optional.info',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const t_landing_home = {
  btn1: [`로그인`, `Sign-in`, 't_landing_home.btn1', ``, ``, ``, ``],
  btn2: [`체험 수업 신청하기`, `Try a Free Lesson`, 't_landing_home.btn2', ``, ``, ``, ``],
  title1: {
    default: [
      `Ringle Teens와 함께 사고력을 키워보세요`,
      `Grow Intellectually with Ringle Teens`,
      't_landing_home.title1.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [`4가지 능력을 키워보세요`, `Expand your learner's 4Cs`, 't_landing_home.title1.desc', ``, ``, ``, ``],
    info1: [`소통 능력`, `Communication`, 't_landing_home.title1.info1', ``, ``, ``, ``],
    info2: [`비판적 사고 능력`, `Critical Thinking`, 't_landing_home.title1.info2', ``, ``, ``, ``],
    info3: [`창의적 사고 능력`, `Creative Thinking`, 't_landing_home.title1.info3', ``, ``, ``, ``],
    info4: [`집중력`, `Concentration Skills`, 't_landing_home.title1.info4', ``, ``, ``, ``],
  },
  btn3: [`체험 수업 신청하기`, `Try a Free Lesson`, 't_landing_home.btn3', ``, ``, ``, ``],
  title2: {
    default: [
      `상세한 1:1 온라인 토론 세션`,
      `In-depth 1:1 online Discussion Sessions`,
      't_landing_home.title2.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `링글 틴즈는 하버드와 예일대와 같은 명문대학교 출신의 튜터들과의 1:1 수업을 통해 4C 능력을 기를 수 있는 기회를 제공합니다`,
      `Ringle Teens helps students develop their 4Cs by offering 1:1 lessons with tutors from prestigious universities, such as Harvard and Yale.`,
      't_landing_home.title2.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn4: [`체험 수업 신청하기`, `Try a Free Lesson`, 't_landing_home.btn4', ``, ``, ``, ``],
  review1: {
    default: [
      <>
        링글 틴즈의 튜터들과 만남은 제 기대를 뛰어넘었어요!
        <br />이 수업을 통해 저는 다양한 정보에 대해 비판적사고를 하며 현명한 판단을 할 수 있게 되었어요.
      </>,
      <>
        Ringle Teens lessons exceeded my expectations!
        <br />
        My tutor helped me make intelligent judgments about various information. I have now become a critical thinker!
      </>,
      't_landing_home.review1.default',
      ``,
      ``,
      ``,
      ``,
    ],
    writer: [
      `미국 펜실베이니아에 살고 있는 링글 틴즈 학생`,
      `A Ringle Teens Student in Pennsylvania, US`,
      't_landing_home.review1.writer',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  subtitle2: {
    default: [
      `교육 및 학습에 대한 링글 틴즈만의 접근 방식`,
      `OUR UNIQUE APPROACH TO TEACHING AND LEARNING`,
      't_landing_home.subtitle2.default',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title3: {
    default: [
      <>
        링글 틴즈는 4C로<span className="text-secondary">사고력을 확장합니다</span>
      </>,
      <>
        We Help Develop the <span className="text-secondary">4Cs</span>
      </>,
      't_landing_home.title3.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `링글 틴즈의 수업들은 검증 된 교육학적 방식인  '하브루타 교육법,' '하크네스 방법,' '소크라테스 대화법' 을 기반으로 진행됩니다.`,
      `Ringle Teens has adapted proven pedagogical methods and learning styles such as "the Havruta Method,” "the Harkness Method,” and "the Socratic Method.” Ringle Teens lessons reflect such learning styles.`,
      't_landing_home.title3.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  card1: {
    default: [`비판적 사고 능력`, `Critical Thinking`, 't_landing_home.card1.default', ``, ``, ``, ``],
    desc: [
      `다양한 기사와 관련 주제에 대한 논의를 바탕으로 논리적인 사고방식을 길러보세요`,
      `Make logical connections between ideas by reading articles and having relevant discussions`,
      't_landing_home.card1.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  card2: {
    default: [`창의적 사고 능력`, `Creative Thinking`, 't_landing_home.card2.default', ``, ``, ``, ``],
    desc: [
      `다양한 배경 출신의 튜터들과 수업하며 창의적인 사고 방식을 연습해보세요.`,
      `Practice innovative thinking by interacting with tutors of diverse backgrounds`,
      't_landing_home.card2.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  card3: {
    default: [`집중력`, `Concentration`, 't_landing_home.card3.default', ``, ``, ``, ``],
    desc: [
      `1:1 수업을 통해 집중력을 길러보세요.`,
      `Develop your concentration skills 
by participating in engaging 1:1 lessons`,
      't_landing_home.card3.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  card4: {
    default: [`소통 능력`, `Communication`, 't_landing_home.card4.default', ``, ``, ``, ``],
    desc: [
      `튜터들의 상세한 피드백을 바탕으로 말하기와 쓰기 실력을 향상시켜 보세요.`,
      `Improve your oral and written skills through detailed feedback from our tutors`,
      't_landing_home.card4.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  subtitle3: {
    default: [
      `링글 틴즈 수업은 이렇게 진행됩니다!`,
      `HOW RINGLE TEENS WORKS`,
      't_landing_home.subtitle3.default',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title4: {
    default: [
      `예습,수업,복습으로 이어지는 사고력 다지기`,
      `Maximize Your Learner's Experience with 3 Steps`,
      't_landing_home.title4.default',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  tab1: {
    default: [`Step 1`, `Step 1`, 't_landing_home.tab1.default', ``, ``, ``, ``],
    title: [`수업 준비`, `Lesson Preparation`, 't_landing_home.tab1.title', ``, ``, ``, ``],
    desc1: [<>"인사이트" 읽기</>, <>Read the "Insight"</>, 't_landing_home.tab1.desc1', ``, ``, ``, ``],
    desc2: [`오디오 파일 듣기`, `Listen to an audio file`, 't_landing_home.tab1.desc2', ``, ``, ``, ``],
    desc3: [`비디오 클립 보기`, `Watch a video clip`, 't_landing_home.tab1.desc3', ``, ``, ``, ``],
    desc4: [
      `질문을 세가지 이상 선택하세요`,
      `Select 3+ questions to answer`,
      't_landing_home.tab1.desc4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  tab2: {
    default: [`Step 2`, `Step 2`, 't_landing_home.tab2.default', ``, ``, ``, ``],
    title: [`수업 & 숙제`, `Lesson & Homework`, 't_landing_home.tab2.title', ``, ``, ``, ``],
    desc1: [
      `튜터와의 활발한 대화에 참여하세요`,
      `Engage in an active conversation with a tutor`,
      't_landing_home.tab2.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `튜터로부터 실시간 피드백을 받아보세요`,
      `Receive live feedback from the tutor`,
      't_landing_home.tab2.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      `쓰기 숙제를 하며 수업을 마무리 하세요`,
      `Conclude your lesson with a written assignment`,
      't_landing_home.tab2.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  tab3: {
    default: [`Step 3`, `Step 3`, 't_landing_home.tab3.default', ``, ``, ``, ``],
    title: [`수업 복습하기`, `Lesson Review`, 't_landing_home.tab3.title', ``, ``, ``, ``],
    desc1: [
      `수업 녹음본을 듣고 복습하세요`,
      `Listen to the lesson recording and review your session`,
      't_landing_home.tab3.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `수업 분석, 피드백, 그리고 교정을 확인하세요`,
      `Check lesson analysis, feedback, and corrections`,
      't_landing_home.tab3.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      `글쓰기 숙제에 대한 튜터의 피드백을 검토하세요`,
      `Review your tutor's feedback on your written assignment`,
      't_landing_home.tab3.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title5: {
    default: [
      `링글 틴즈의 모든 수업들은 다음과 같은 혜택을 제공합니다`,
      `All Ringle Teens Lesson Include`,
      't_landing_home.title5.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc1: [
      <>
        학습자의 필요에 맞춘
        <br />
        다양한 종류의 수업 방식
      </>,
      <>
        Wide range of lesson
        <br />
        styles for your learner’s needs
      </>,
      't_landing_home.title5.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `수업 중 도움이 필요할 경우를 대비해 상시 대기 중인 모니터링 팀`,
      `On-demand monitoring team ready to support`,
      't_landing_home.title5.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      <>
        학부모 대시보드와
        <br />
        수업 진행 현황을 알리는 알림 시스템
      </>,
      <>
        Parent dashboard and
        <br />
        alarm system to check lesson progress
      </>,
      't_landing_home.title5.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc4: [
      <>
        학부모님들에게만 보이는
        <br />
        튜터의 피드백
      </>,
      <>
        Tutor feedback exclusively
        <br />
        visible to parents
      </>,
      't_landing_home.title5.desc4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn5: [`상담 신청`, `Ask More to Our Advisor`, 't_landing_home.btn5', ``, ``, ``, ``],
  subtitle4: {
    default: [
      `링글 틴즈의 튜터들을 소개합니다`,
      `KEY FEATURES: OUR TUTORS`,
      't_landing_home.subtitle4.default',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title6: {
    default: [
      `명문대 출신의 유능한 튜터들`,
      `Quality Tutors From Prestigious Universities`,
      't_landing_home.title6.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc1: [
      `튜터 선발 과정에서 뛰어난 성적을 거둔 명문대 원어민 튜터들`,
      `Experienced native English speaking tutors who aced our tutor selection process`,
      't_landing_home.title6.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `STEM 에서 ART 까지 다양한 전공의 튜터들`,
      `Knowledgeable scholars in areas ranging from STEM to the Arts`,
      't_landing_home.title6.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      `학업과 인생에 대한 멘토링까지 이끌어내는 튜터들`,
      `Mentors who can motivate students to have a positive attitude in life and academia`,
      't_landing_home.title6.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn6: [`가입하고 튜터들 보러가기`, `Sign up & Meet Our Tutors`, 't_landing_home.btn6', ``, ``, ``, ``],
  btn7: [`가입하고 더 다양한 교재들 보기`, `Sign up & See More Materials`, 't_landing_home.btn7', ``, ``, ``, ``],
  subtitle5: {
    default: [
      `십대 중심의 인사이트`,
      `KEY FEATURES: TEEN-FOCUSED INSIGHTS`,
      't_landing_home.subtitle5.default',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title8: {
    default: [
      `십대만을 위한 다양한 주제의 교재들`,
      `Articles on Various Topics for Teens`,
      't_landing_home.title8.default',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title9: {
    default_mobile: [
      `다양한 교재들을 둘러보세요!`,
      `Check our various materials!`,
      't_landing_home.title9.default_mobile',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title10: {
    default: [
      `링글 틴즈에 대해 더 알고 싶으신가요?`,
      `Still Curious About Ringle Teens?`,
      't_landing_home.title10.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `링글 틴즈 만의 학습자 맞춤수업을 통해 영어실력 향상을 경험하세요!`,
      `Let us help customize your learner's academic journey!`,
      't_landing_home.title10.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn8: [`오늘 상담을 예약해보세요!`, `Book a Call Today!`, 't_landing_home.btn8', ``, ``, ``, ``],
  title11: {
    default: [
      `학생들과 학부모들의 사용후기`,
      `5-Star Reviews from Students & Parents`,
      't_landing_home.title11.default',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  review2: {
    default1: [
      <>링글 틴즈는 단순히 무언가를 배우기만 하는 교육 플랫폼이 아니에요. </>,
      <>Ringle Teens is not just an educational platform where you simply learn things. </>,
      't_landing_home.review2.default1',
      ``,
      ``,
      ``,
      ``,
    ],
    default2: [
      `시야를 넓혀주고 전에는 상상하지도 못한 인생의 목표를 세우게 해주는 플랫폼입니다.`,
      `It helps you broaden your horizons and set life goals that you’ve never dreamed of achieving before.`,
      't_landing_home.review2.default2',
      ``,
      ``,
      ``,
      ``,
    ],
    writer1: [`링글 틴즈 학생`, `Teens Student`, 't_landing_home.review2.writer1', ``, ``, ``, ``],
    writer2: [`워싱턴, 미국`, `from Washington, US`, 't_landing_home.review2.writer2', ``, ``, ``, ``],
  },
  review3: {
    default1: [
      <>링글 틴즈의 수업들이 너무 즐거워서 제 친구들에게 추천했어요! 제 튜터의 피드백 덕분에 </>,
      <>
        I truly enjoyed my Ringle Teens lessons that I recommended this service to all of my friends! Thanks to my
        tutor’s helpful feedback,{' '}
      </>,
      't_landing_home.review3.default1',
      ``,
      ``,
      ``,
      ``,
    ],
    default2: [
      `저는 소통과 작문 능력을 기를 수 있었어요!`,
      `I have stronger communication and writing skills!`,
      't_landing_home.review3.default2',
      ``,
      ``,
      ``,
      ``,
    ],
    writer1: [`링글 틴즈 학생`, `Teens Student`, 't_landing_home.review3.writer1', ``, ``, ``, ``],
    writer2: [`펜실베이니아, 미국`, `from Pennsylvania, US`, 't_landing_home.review3.writer2', ``, ``, ``, ``],
  },
  review4: {
    default1: [
      <>소통 능력, 비판적 사고 능력, 창의적 사고 능력, 집중력은 모두 인생에서 필수적인 요소들입니다. </>,
      <>Concentration, communication, creative thinking, and critical thinking skills are essential in life. </>,
      't_landing_home.review4.default1',
      ``,
      ``,
      ``,
      ``,
    ],
    default2: [
      `제 아이는 이 모든 능력을 링글 틴즈에서 기를 수 있었어요!`,
      `Ringle Teens helped my child develop all of those on one platform!`,
      't_landing_home.review4.default2',
      ``,
      ``,
      ``,
      ``,
    ],
    writer1: [`링글 틴즈 학부모님`, `Teens Parent`, 't_landing_home.review4.writer1', ``, ``, ``, ``],
    writer2: [`베를린, 독일`, `from Berlin, Germany`, 't_landing_home.review4.writer2', ``, ``, ``, ``],
  },
  review5: {
    default1: [
      <>링글 틴즈의 튜터들은 제 아들이 4Cs를 향상시킬 수 있도록 집중적으로 지도해주었습니다. </>,
      <>Ringle Teens tutors paid extra attention to make sure my son improves his 4Cs. </>,
      't_landing_home.review5.default1',
      ``,
      ``,
      ``,
      ``,
    ],
    default2: [
      `덕분에 제 아들은 이제 디베이트를 매우 잘하게 되었어요!`,
      `My son now excels in discussion sessions and debate classes at his school!`,
      't_landing_home.review5.default2',
      ``,
      ``,
      ``,
      ``,
    ],
    writer1: [`링글 틴즈 학부모님`, `Teens Parent`, 't_landing_home.review5.writer1', ``, ``, ``, ``],
    writer2: [`시드니, 호주`, `from Sydney, Australia`, 't_landing_home.review5.writer2', ``, ``, ``, ``],
  },
  title12: {
    default: [`자주 묻는 질문`, `Frequently Asked Questions`, 't_landing_home.title12.default', ``, ``, ``, ``],
    faq1: {
      default: [
        `제 아이가 링글 틴즈를 통해 어떻게 4C 능력을 향상시킬 수 있나요?`,
        `How can Ringle Teens help my child hone his or her 4Cs?`,
        't_landing_home.title12.faq1.default',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `링글 틴즈는 튜터들이 4Cs(소통 능력, 비판적 사고 능력, 창의적 사고 능력, 집중력)을 기반으로 수업을 진행하고 피드백을 주도록 트레이닝하고 있습니다.`,
        `Ringle Teens trains its tutors to lead lessons and give helpful feedback constructed upon the 4Cs: Communication, Critical Thinking, Creative Thinking, and Concentration Skills.`,
        't_landing_home.title12.faq1.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    faq2: {
      default: [
        `링글 틴즈는 어떤 나이대의 학생들을 위한 것인가요?`,
        `What ages is Ringle Teens aimed towards?`,
        't_landing_home.title12.faq2.default',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `9살부터 15살의 학생들을 대상으로 하고 있지만, 더 어리거나 나이가 많아도 누구든지 링글 틴즈의 수업을 유익하게 들으실 수 있습니다!`,
        `It is geared toward students from 9 to 15 years of age. However, younger and older students can still enjoy Ringle Teens lessons!`,
        't_landing_home.title12.faq2.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    faq3: {
      default: [
        `수업의 가격은 어떠한가요?`,
        `How much do lessons cost?`,
        't_landing_home.title12.faq3.default',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `월간 요금제는 ____부터 시작하지만, 편의에 맞게 요금제를 사용자 맞춤화하여 이용하실 수도 있습니다! 한 달에 더 많은 수업을 신청하여 학습자의의 학습 여정을 한단계 더 증진시켜보세요. 모든 링글 틴즈 이용자는 저희의 튜터들, 십대들을 위한 학습 자료들, 유저분들만을 위한 이벤트들의 혜택을 누리실 수 있습니다.`,
        `Our monthly plan starts at $149 — yet, you may customize your plan!
Add more lessons per month to further your learner’s journey.  For every Ringle Teens user, access to our caring tutors, teens-oriented learning materials, and user-only events will be available.`,
        't_landing_home.title12.faq3.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    faq4: {
      default: [
        `월간 요금제 취소는 어떻게 하나요?`,
        `How do I cancel my monthly plan?`,
        't_landing_home.title12.faq4.default',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        <>
          월간 요금제를 취소하기 위해서는 다음의 절차를 따라주세요.
          <br />
          1. 부모 계정으로 로그인
          <br />
          2. 좌측 메뉴에 있는 "내 약정" 클릭
          <br />
          3. "약정 관리"를 클릭하여 계정 삭제
        </>,
        <>
          If you want to cancel your monthly plan, follow the procedures below.
          <br />
          1. Login to Parent's account
          <br />
          2. Click on "My Plan" from the left side menu
          <br />
          3. Click on "Manage Plan" to cancel the plan
        </>,
        't_landing_home.title12.faq4.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    faq5: {
      default: [
        `링글 틴즈가 제 아이에게 맞을지 판단하기 위해 링글팀에 연락해도 되나요?`,
        `Can I contact the Ringle Team to see if Ringle Teens is right for my child?`,
        't_landing_home.title12.faq5.default',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `물론입니다! 1:1 상담을 신청하여 링글 틴즈 담당자와 상담을 해보세요. 또한, 질문이 있으시다면 언제든지 teens@ringleplus.com로 이메일 주시길 바랍니다.`,
        `Absolutely! Speak with a Ringle Teens advisor by signing up on our Calendly page for a 1:1 consultation. Also, feel free to email us with your inquiries at teens@ringleplus.com.`,
        't_landing_home.title12.faq5.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    faq6: {
      default: [
        `수업을 위해 어떤 셋업이 준비되어야 하나요?`,
        `What kind of setup will my learner need at home?`,
        't_landing_home.title12.faq6.default',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        <>
          1. Windows 혹은 Mac OS가 실행되고 마이크, 스피커, 웹캠까지 탑재된 컴퓨터를 준비해주세요
          <br />
          2. Chrome browser 와 Zoom Client 를 설치해주세요
          <br />
          3. 인터넷 연결
        </>,
        <>
          1. A computer that runs on Windows or Mac OS and has a microphone, speaker, and a webcam
          <br />
          2. Install the Chrome browser and Zoom Client
          <br />
          3. An Internet Connection
        </>,
        't_landing_home.title12.faq6.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    faq7: {
      default: [
        `링글 틴즈에 일주일간 들이는 시간은 대략 몇시간인가요?`,
        `What is a Ringle Teens user's expected time commitment for a week?`,
        't_landing_home.title12.faq7.default',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        <>
          학습자의 스케줄에 따라 얼마나 많은 수업을 들을지 결정하실 수 있습니다. 각 수업은 다음과 같은 세가지 단계들이
          포함되며, 모든 단계를 완료하는데에는 총 2~3시간 정도의 시간이 소요됩니다:
          <br />
          1. <b>수업 준비:</b> 기사를 읽고, 오디오 듣기, 영상 시청하기, 답변할 세가지 질문 선택하기
          <br />
          2. <b>수업과 숙제:</b> 20분/40분 수업을 듣고 작문 숙제까지 완료하기
          <br />
          3. <b>수업 복습:</b> 수업 녹화본, 수업 분석, 튜터의 피드백을 모두 복습하기
          <br />
        </>,
        <>
          You can decide how many lessons to take according to your learner’s schedule. Each lesson consists of the
          following elements, and these three elements take about 2 to 3 hours:
          <br />
          1.<b>Lesson Preparation:</b> Read and listen to an article, watch a clip, and select 3 questions to answer
          <br />
          2.<b>Lesson & Homework:</b> Take either 20-min or 40-min lesson and finish it with a written assignment
          <br />
          3.<b>Lesson Review:</b> Review your lesson recording, lesson analysis, and tutor’s feedback
        </>,
        't_landing_home.title12.faq7.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  title13: {
    default1: [`체험 수업 신청하기`, `Try a Free Lesson`, 't_landing_home.title13.default1', ``, ``, ``, ``],
    default2: [
      `링글 틴즈를 경험해보세요!`,
      `and Experience Ringle Teens!`,
      't_landing_home.title13.default2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn9: [`지금 무료수업 체험해보기`, `Try a Free Lesson Now`, 't_landing_home.btn9', ``, ``, ``, ``],
  subtitle1: {
    default: [`링글 틴즈란?`, `WHAT IS RINGLE TEENS`, 't_landing_home.subtitle1.default', ``, ``, ``, ``],
  },
  title7: {
    default_mobile: [`앱 다운로드`, `Download App`, 't_landing_home.title7.default_mobile', ``, ``, ``, ``],
  },
  text_btn2_mobile: [`앱 다운로드`, `Download App`, 't_landing_home.text_btn2_mobile', ``, ``, ``, ``],
  text_btn1_mobile: [`앱 다운로드`, `Download App`, 't_landing_home.text_btn1_mobile', ``, ``, ``, ``],
  text_btn3_mobile: [`앱 다운로드`, `Download App`, 't_landing_home.text_btn3_mobile', ``, ``, ``, ``],
  go_to_portal: [`포털로 가기`, `Go to Portal`, 't_landing_home.go_to_portal', ``, ``, ``, ``],
  welcome: [`환영합니다!`, `Welcome!`, 't_landing_home.welcome', ``, ``, ``, ``],
  promo_title: [`런칭 기념 선착순 100명에게만 제공 중!`, ``, 't_landing_home.promo_title', ``, ``, ``, ``],
  promo_btn: [`한정 판매 수업권 확인하기`, ``, 't_landing_home.promo_btn', ``, ``, ``, ``],
  benefits_title: [`오직 런칭 프로모션에서만 만날 수 있는 혜택`, ``, 't_landing_home.benefits_title', ``, ``, ``, ``],
  benefit_1: [`혜택 1.`, ``, 't_landing_home.benefit_1', ``, ``, ``, ``],
  benefit_1_subtitle: [`연중 최저가로 시작하세요!`, ``, 't_landing_home.benefit_1_subtitle', ``, ``, ``, ``],
  benefit_1_title: [`수업권 최대 39% 할인`, ``, 't_landing_home.benefit_1_title', ``, ``, ``, ``],
  benefit_2: [`혜택 2.`, ``, 't_landing_home.benefit_2', ``, ``, ``, ``],
  benefit_2_subtitle: [`지금 가입만 해도`, ``, 't_landing_home.benefit_2_subtitle', ``, ``, ``, ``],
  benefit_2_title: [`5만 포인트 추가 증정`, ``, 't_landing_home.benefit_2_title', ``, ``, ``, ``],
  benefit_3: [`혜택 3.`, ``, 't_landing_home.benefit_3', ``, ``, ``, ``],
  benefit_3_subtitle: [`자신 있는 만큼`, ``, 't_landing_home.benefit_3_subtitle', ``, ``, ``, ``],
  benefit_3_title: [`무료 체험 수업 제공`, ``, 't_landing_home.benefit_3_title', ``, ``, ``, ``],
  pricing_subtitle: [`선착순 100명 한정 판매`, ``, 't_landing_home.pricing_subtitle', ``, ``, ``, ``],
  pricing_title: [
    `런칭 기념 단 한 번, 연중 최저가로 링글 틴즈를 만나보세요.`,
    ``,
    't_landing_home.pricing_title',
    ``,
    ``,
    ``,
    ``,
  ],
  pricing_40min: [`40분`, `40min`, 't_landing_home.pricing_40min', ``, ``, ``, ``],
  pricing_20min: [`20분`, `20min`, 't_landing_home.pricing_20min', ``, ``, ``, ``],
  pricing_credit: [`회`, `credit`, 't_landing_home.pricing_credit', ``, ``, ``, ``],
  pricing_total: [`프로모션 할인가격`, ``, 't_landing_home.pricing_total', ``, ``, ``, ``],
  pricing_regular: [`정가`, ``, 't_landing_home.pricing_regular', ``, ``, ``, ``],
  pricing_duration: [`1회 수업 시간`, ``, 't_landing_home.pricing_duration', ``, ``, ``, ``],
  pricing_lesson: [`수업 횟수`, ``, 't_landing_home.pricing_lesson', ``, ``, ``, ``],
  pricing_expires: [`수강 기간`, ``, 't_landing_home.pricing_expires', ``, ``, ``, ``],
  pricing_min: [`분`, ``, 't_landing_home.pricing_min', ``, ``, ``, ``],
  pricing_date: [`일`, ``, 't_landing_home.pricing_date', ``, ``, ``, ``],
  pricing_caption_1: [
    `*수업권은 수강기간 내 자유롭게 이용할 수 있습니다.`,
    ``,
    't_landing_home.pricing_caption_1',
    ``,
    ``,
    ``,
    ``,
  ],
  pricing_caption_2: [
    `*첫 구매 고객 대상 할인이 적용된 가격입니다.`,
    ``,
    't_landing_home.pricing_caption_2',
    ``,
    ``,
    ``,
    ``,
  ],
  timer: {
    d_day_txt1: [
      `가장 알찬 여름방학을\n가장 합리적으로 준비할 수 있는 시간`,
      `Time to prepare for the\nmost fulfilling summer vacation`,
      't_landing_home.timer.d_day_txt1',
      ``,
      ``,
      ``,
      ``,
    ],
    d_day_txt2: [
      `미 명문대 수준의 여름 캠프를\n합리적으로 구매할 수 있는 기회`,
      `A chance to experience high-quality\nsummer camp most affordably`,
      't_landing_home.timer.d_day_txt2',
      ``,
      ``,
      ``,
      ``,
    ],
    d_day_early: [
      `얼리버드 등록 마감까지`,
      `Early Bird Discount ends in`,
      't_landing_home.timer.d_day_early',
      ``,
      ``,
      ``,
      ``,
    ],
    d_day_regular: [`캠프 등록 마감까지`, `Registration ends in`, 't_landing_home.timer.d_day_regular', ``, ``, ``, ``],
    hrs: [`시간`, `Hour`, 't_landing_home.timer.hrs', ``, ``, ``, ``],
    mins: [`분`, `Min`, 't_landing_home.timer.mins', ``, ``, ``, ``],
    secs: [`초`, `Sec`, 't_landing_home.timer.secs', ``, ``, ``, ``],
  },
  btn_pricing: [`가격`, `Pricing`, 't_landing_home.btn_pricing', ``, ``, ``, ``],
  pricing: {
    subtitle: [
      `수업권을 구매하면 모든 프로그램을 한번에!`,
      `Access all programs with our lesson credits!`,
      't_landing_home.pricing.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [`9월을 위한 특별한 가격`, `Special Price for September`, 't_landing_home.pricing.title', ``, ``, ``, ``],
    tab: [`9월 한정 할인`, `Sept-Only`, 't_landing_home.pricing.tab', ``, ``, ``, ``],
  },
}
export const t_sign_up = {
  title: [
    <>링글 틴즈 회원 가입을 어떻게 진행할까요?</>,
    <>How would you like to create your Ringle account?</>,
    't_sign_up.title',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle: [`소셜 계정으로 가입`, `Sign up with`, 't_sign_up.subtitle', ``, ``, ``, ``],
  or: [`혹은`, `or`, 't_sign_up.or', ``, ``, ``, ``],
  btn1: [`이메일로 가입하기`, `Sign up with email`, 't_sign_up.btn1', ``, ``, ``, ``],
  dssc: [`이미 링글 계정이 있으신가요?`, `Do you already have a Ringle account?`, 't_sign_up.dssc', ``, ``, ``, ``],
  btn2: [`링글 계정으로 가입하기`, `Sign up with Ringle Account`, 't_sign_up.btn2', ``, ``, ``, ``],
  teens_sign_up: [`링글 틴즈 가입`, `Teens Sign Up`, 't_sign_up.teens_sign_up', ``, ``, ``, ``],
  ok: [`확인`, `OK`, 't_sign_up.ok', ``, ``, ``, ``],
  title_moweb: [`Sign Up`, `Sign Up`, 't_sign_up.title_moweb', ``, ``, ``, ``],
  btn_ringle_moweb: [
    `링글 플러스 계정으로 가입`,
    `Sign up with Ringle Plus`,
    't_sign_up.btn_ringle_moweb',
    ``,
    ``,
    ``,
    ``,
  ],
  text_moweb: [
    `이미 링글 틴즈 회원이신가요?`,
    `Already have a Ringle Teens account?`,
    't_sign_up.text_moweb',
    ``,
    ``,
    ``,
    ``,
  ],
  text_btn_moweb: [`링글 틴즈 앱 다운로드`, `Download Ringle Teens App`, 't_sign_up.text_btn_moweb', ``, ``, ``, ``],
  complete: {
    msg: [`회원가입 완료!`, ``, 't_sign_up.complete.msg', ``, ``, ``, ``],
    title: (name) => [
      <>
        {name}님,
        <br />
        링글 틴즈 가입을 환영해요!
      </>,
      ``,
      't_sign_up.complete.title',
      ``,
      ``,
      ``,
      ``,
    ],
    credit: [`20분 수업권 1개`, ``, 't_sign_up.complete.credit', ``, ``, ``, ``],
    subtitle: [
      `무료 수업권을 선물로 드렸어요.
지금 링글 틴즈 앱에서 수업을 시작하세요!`,
      ``,
      't_sign_up.complete.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    title_download: [
      `링글 틴즈 앱에서 모두 확인할 수 있어요.`,
      ``,
      't_sign_up.complete.title_download',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_1: [`내 아이의 성장을 돕는 튜터의 프로필`, ``, 't_sign_up.complete.desc_1', ``, ``, ``, ``],
    desc_2: [`흥미를 자극하는 다양한 교재`, ``, 't_sign_up.complete.desc_2', ``, ``, ``, ``],
    desc_3: [`수업권 가격과 프로그램 구성`, ``, 't_sign_up.complete.desc_3', ``, ``, ``, ``],
    btn: [`앱 다운로드하기`, ``, 't_sign_up.complete.btn', ``, ``, ``, ``],
  },
  section_input_parents: {
    title: [
      <>
        회원가입을 위해
        <br />
        부모님의 정보를 입력하세요.
      </>,
      <>
        Enter parent's information
        <br />
        to sign up
      </>,
      't_sign_up.section_input_parents.title',
      ``,
      ``,
      ``,
      ``,
    ],
    caption: [`기본 정보`, `Basic Info`, 't_sign_up.section_input_parents.caption', ``, ``, ``, ``],
    placeholder_1: [`이메일`, `Email address (ID)`, 't_sign_up.section_input_parents.placeholder_1', ``, ``, ``, ``],
    btn: [`이메일 중복 확인`, `Check for email duplicates.`, 't_sign_up.section_input_parents.btn', ``, ``, ``, ``],
    placeholder_2: [`비밀번호`, `Password`, 't_sign_up.section_input_parents.placeholder_2', ``, ``, ``, ``],
    placeholder_3: [`부모님 이름`, `Parent's name`, 't_sign_up.section_input_parents.placeholder_3', ``, ``, ``, ``],
    option_1: [`오전 10시 - 오후 1시`, `10AM ~ 1PM (KST)`, 't_sign_up.section_input_parents.option_1', ``, ``, ``, ``],
    option_2: [`오후 2시 - 오후 7시`, `2PM ~ 7PM (KST)`, 't_sign_up.section_input_parents.option_2', ``, ``, ``, ``],
    error_1: [
      `올바른 이메일 형식을 입력하세요.`,
      `Please enter a valid email address`,
      't_sign_up.section_input_parents.error_1',
      ``,
      ``,
      ``,
      ``,
    ],
    error_2: [
      `이미 가입된 이메일 주소입니다.`,
      `The email address has already been signed up.`,
      't_sign_up.section_input_parents.error_2',
      ``,
      ``,
      ``,
      ``,
    ],
    error_3: [
      `8자 이상의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요.`,
      `Enter at least 8 characters, with uppercase, lowercase, numbers, and special characters.`,
      't_sign_up.section_input_parents.error_3',
      ``,
      ``,
      ``,
      ``,
    ],
    caption_2: [`(선택) 친구 초대 코드`, `Referral code`, 't_sign_up.section_input_parents.caption_2', ``, ``, ``, ``],
    placeholder_4: [
      `(선택) 친구 초대 코드`,
      `(Optional) Referral code`,
      't_sign_up.section_input_parents.placeholder_4',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_2: [`추천 코드 확인`, `Verify Referral Code`, 't_sign_up.section_input_parents.btn_2', ``, ``, ``, ``],
    confirmation_1: [
      `추천 코드가 확인되었습니다.`,
      `Referral code verified.`,
      't_sign_up.section_input_parents.confirmation_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title_1: [`부모 정보`, `Parent's information`, 't_sign_up.section_input_parents.title_1', ``, ``, ``, ``],
    email: [`이메일`, `Email`, 't_sign_up.section_input_parents.email', ``, ``, ``, ``],
    password: [`비밀번호`, `Password`, 't_sign_up.section_input_parents.password', ``, ``, ``, ``],
    name: [`이름`, `Name`, 't_sign_up.section_input_parents.name', ``, ``, ``, ``],
    number: [
      `휴대폰 번호(‘-’ 없이 번호만 입력)`,
      `Phone number (without '-')`,
      't_sign_up.section_input_parents.number',
      ``,
      ``,
      ``,
      ``,
    ],
    number_btn_1: [`인증번호 요청`, `Send Code`, 't_sign_up.section_input_parents.number_btn_1', ``, ``, ``, ``],
    number_btn_2: [`인증`, `Verify`, 't_sign_up.section_input_parents.number_btn_2', ``, ``, ``, ``],
    number_btn_3: [`재요청`, `Resend Code`, 't_sign_up.section_input_parents.number_btn_3', ``, ``, ``, ``],
    passwaord_caption: [
      `비밀번호를 설정해 두면 간편 로그인이 어려운 경우에도 이메일과 비밀번호를 입력해 로그인할 수 있어요.`,
      `Setting a password will allow you to log in using your email address and password when you are unable log in with another account.`,
      't_sign_up.section_input_parents.passwaord_caption',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  section_input_child: {
    title: [
      <>
        체험 수업을 위한
        <br />
        자녀의 정보를 입력하세요.
      </>,
      <>
        Enter your child's information
        <br />
        for the trial class
      </>,
      't_sign_up.section_input_child.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `자녀가 수업에서 사용할 이름과 생년월일을 알려주세요. 자녀의 나이에 맞는 교재를 추천해 드려요.`,
      `Tell us your child's name and date of birth to use in class. We'll recommend age-appropriate materials for your child.`,
      't_sign_up.section_input_child.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    caption: [`자녀 정보`, `Child Information`, 't_sign_up.section_input_child.caption', ``, ``, ``, ``],
    placeholder: [
      `자녀의 영문 이름`,
      `Your child's english name.`,
      't_sign_up.section_input_child.placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    error_1: [
      `영어 이름을 입력해주세요.`,
      `Write an english name.`,
      't_sign_up.section_input_child.error_1',
      ``,
      ``,
      ``,
      ``,
    ],
    error_2: [
      `생년월일을 정확히 입력하세요.`,
      `Please enter your date of birth correctly.`,
      't_sign_up.section_input_child.error_2',
      ``,
      ``,
      ``,
      ``,
    ],
    placeholder_month: [`월`, `Month`, 't_sign_up.section_input_child.placeholder_month', ``, ``, ``, ``],
    placeholder_date: [`일`, `Day`, 't_sign_up.section_input_child.placeholder_date', ``, ``, ``, ``],
    placeholder_year: [`년`, `Year`, 't_sign_up.section_input_child.placeholder_year', ``, ``, ``, ``],
    title_1: [`자녀 정보`, `Child's information`, 't_sign_up.section_input_child.title_1', ``, ``, ``, ``],
    placeholder_1: [`영어 이름`, `English Name`, 't_sign_up.section_input_child.placeholder_1', ``, ``, ``, ``],
    placeholder_2: [`출생 연도`, `Year of birth`, 't_sign_up.section_input_child.placeholder_2', ``, ``, ``, ``],
  },
  section_input_number: {
    title: [
      `상담을 진행할 연락처를 알려주세요.`,
      `Provide your phone number for consultation.`,
      't_sign_up.section_input_number.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `학습 담당자와 상담을 통해 1:1 맞춤 수업을 진행합니다.
꼭 연락 가능한 학부모 전화번호를 남겨 주세요.`,
      `Our manager will suggest custom classes fit for your child through consultation.
Make sure to leave a phone number where you can be reached.`,
      't_sign_up.section_input_number.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    caption_1: [`휴대폰 번호`, `Phone number`, 't_sign_up.section_input_number.caption_1', ``, ``, ``, ``],
    placeholder: [
      `휴대폰 번호(‘-’ 없이 번호만 입력)`,
      `Phone number (without '-')`,
      't_sign_up.section_input_number.placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    caption_2: [
      `상담 시간 (월~금)`,
      `Consultation hours (Mon~Fri)`,
      't_sign_up.section_input_number.caption_2',
      ``,
      ``,
      ``,
      ``,
    ],
    error_1: [
      `번호만 입력해주세요.`,
      `Please enter \bonly the number.`,
      't_sign_up.section_input_number.error_1',
      ``,
      ``,
      ``,
      ``,
    ],
    caption: [`인증번호가 안 온다면?`, `Didn't get a code?`, 't_sign_up.section_input_number.caption', ``, ``, ``, ``],
    popup_body: [
      <>
        통신사에 따라 문자메시지(SMS) 수신에 지연이 있을 수 있습니다.  일정 시간 동안 기다려도 문자메시지(SMS)가 오지
        않으면 재요청 버튼을 눌러 주세요.
        <br />
        <br />
        <b>어려움이 있으시다면 링글 틴즈팀에 문의해 주세요.</b>
      </>,
      <>
        If you do not see an SMS after a few minutes, please press the "Resend Code" button.
        <br />
        <br />
        <b>If you need any help, please contact us!</b>
      </>,
      't_sign_up.section_input_number.popup_body',
      ``,
      ``,
      ``,
      ``,
    ],
    popup_btn_1: [`확인`, `Ok`, 't_sign_up.section_input_number.popup_btn_1', ``, ``, ``, ``],
    popup_btn_2: [`틴즈팀에 문의하기`, `Contact us`, 't_sign_up.section_input_number.popup_btn_2', ``, ``, ``, ``],
    done: [`인증되었습니다`, `Verified`, 't_sign_up.section_input_number.done', ``, ``, ``, ``],
    error_2: [
      `이미 인증된 연락처 입니다.`,
      `This phone number is already registered.`,
      't_sign_up.section_input_number.error_2',
      ``,
      ``,
      ``,
      ``,
    ],
    caption_desc: [
      <>
        링글 플러스에 이미 가입되어 있어요.
        <br />
        계정을 연동해서 가입해 주세요.
      </>,
      `There already exists a Ringle Plus account that is registered to this phone number. Please sign up with the Ringle Plus account.`,
      't_sign_up.section_input_number.caption_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    caption_btn: [`계정 연동하기`, `Connect Account`, 't_sign_up.section_input_number.caption_btn', ``, ``, ``, ``],
  },
  section_confirm: {
    select_all: [`전체 선택`, `Select all`, 't_sign_up.section_confirm.select_all', ``, ``, ``, ``],
    check_1: [
      `[필수] 18세 이상입니다.`,
      `[Required] I am 18 years old or older.`,
      't_sign_up.section_confirm.check_1',
      ``,
      ``,
      ``,
      ``,
    ],
    check_2: [
      `[필수] 링글 틴즈 개인정보 취급 방침에 동의합니다.`,
      `[Required] I agree to Ringle Teens Privacy Policy.`,
      't_sign_up.section_confirm.check_2',
      ``,
      ``,
      ``,
      ``,
    ],
    check_3: [
      `[필수] 링글 틴즈 이용약관에 동의합니다.`,
      `[Required] I agree to Ringle Teens Terms of Service.`,
      't_sign_up.section_confirm.check_3',
      ``,
      ``,
      ``,
      ``,
    ],
    check_4: [
      `[선택] 이벤트 및 혜택 소식을 수신하고 싶습니다.`,
      `[Optional] I want to receive marking communications on events and offers.`,
      't_sign_up.section_confirm.check_4',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`회원가입 완료`, `Signup completed`, 't_sign_up.section_confirm.btn', ``, ``, ``, ``],
  },
  pop_up: {
    title: [
      `무료 체험 수업권을 받았어요!`,
      `We’ve given you a free trial lesson credit.`,
      't_sign_up.pop_up.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `지금 수업을 예약하고 아이비리그 튜터를 만나보세요. 조금 더 알아보고 싶다면 상담을 먼저 받아보세요.`,
      `Book a lesson with an Ivy League tutor today. If you want to learn more, start after looking around.`,
      't_sign_up.pop_up.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_cta: [`지금 바로 예약하기`, `Book a Trial Lesson Now`, 't_sign_up.pop_up.btn_cta', ``, ``, ``, ``],
    btn_dismiss: [`상담 후 예약하기`, `Skip for now`, 't_sign_up.pop_up.btn_dismiss', ``, ``, ``, ``],
  },
  toast: {
    toast: [`입력 항목을 다시 확인해주세요.`, `Please check your entries.`, 't_sign_up.toast.toast', ``, ``, ``, ``],
  },
  head: [`회원가입`, `Sign Up`, 't_sign_up.head', ``, ``, ``, ``],
  section_input_code: {
    placeholder_1: [
      `[선택] 친구초대코드`,
      `Referral Code (optional)`,
      't_sign_up.section_input_code.placeholder_1',
      ``,
      ``,
      ``,
      ``,
    ],
    error_1: [
      `유효하지 않은 추천 코드 입니다.`,
      `Invalid referral code.`,
      't_sign_up.section_input_code.error_1',
      ``,
      ``,
      ``,
      ``,
    ],
    chuseok_placeholder: [
      `[선택] 추석 선물 코드`,
      `[Optional] Chuseok Gift Code`,
      't_sign_up.section_input_code.chuseok_placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    error_2: [
      `유효하지 않은 선물 코드 입니다.`,
      `Invalid gift code`,
      't_sign_up.section_input_code.error_2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const t_ringle_login = {
  title: [
    `링글 계정 인증을 위해 로그인 해주세요.`,
    `Verify your account using your Ringle login credentials.`,
    't_ringle_login.title',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle: [
    `링글 틴즈에서도 링글과 동일한 계정을 사용할 수 있습니다.`,
    `You will use the same login credentials for Ringle & Ringle Teens.`,
    't_ringle_login.subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder1: [`이메일 주소를 입력하세요`, `Enter your email adress`, 't_ringle_login.placeholder1', ``, ``, ``, ``],
  placeholder2: [`비밀번호를 입력하세요`, `Enter your password`, 't_ringle_login.placeholder2', ``, ``, ``, ``],
  msg1: [`올바른 이메일을 적어주세요`, `Please enter a vaild Email address`, 't_ringle_login.msg1', ``, ``, ``, ``],
  msg2: [`존재하지 않는 비밀번호입니다`, `Did not match stored password`, 't_ringle_login.msg2', ``, ``, ``, ``],
  btn: [`로그인`, `Login`, 't_ringle_login.btn', ``, ``, ``, ``],
  forgot: [`비밀번호를 잊으셨나요?`, `Forgot password?`, 't_ringle_login.forgot', ``, ``, ``, ``],
  tooltip: [
    `링글 혹은 링글 틴즈 계정의<br>비밀번호를 변경하시면<br>두 계정 모두에 반영됩니다.`,
    `Changing the password for either<br>Ringle or Ringle Teens will automatically<br>change both passwords.`,
    't_ringle_login.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_step1_email = {
  step1: [`부모 계정`, `Parent Account`, 't_step1_email.step1', ``, ``, ``, ``],
  step2: [`자녀 기본 정보`, `Child Basic Info`, 't_step1_email.step2', ``, ``, ``, ``],
  step3: [`자녀 추가 정보`, `Child Additional Info`, 't_step1_email.step3', ``, ``, ``, ``],
  title: [
    `회원 가입을 위해 부모님의 정보를 입력해주세요.`,
    `Create your parental account`,
    't_step1_email.title',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [`기본 정보`, `Basic Info`, 't_step1_email.desc1', ``, ``, ``, ``],
  desc2: [`휴대폰 인증하기`, `Phone number verification`, 't_step1_email.desc2', ``, ``, ``, ``],
  placeholder1: [`ID (이메일)`, `ID (Email)`, 't_step1_email.placeholder1', ``, ``, ``, ``],
  placeholder2: [`비밀번호`, `Password`, 't_step1_email.placeholder2', ``, ``, ``, ``],
  placeholder3: [
    `비밀번호를 다시 입력해주세요`,
    `Re-enter your password`,
    't_step1_email.placeholder3',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder4: [`이름을 적어주세요.`, `Enter your name`, 't_step1_email.placeholder4', ``, ``, ``, ``],
  placeholder5: [
    `휴대폰 번호 (숫자만 입력)`,
    `Mobile number (Number only)`,
    't_step1_email.placeholder5',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder6: [`인증번호`, `Verification code`, 't_step1_email.placeholder6', ``, ``, ``, ``],
  checkbox_all: [`모두 선택`, `Select all`, 't_step1_email.checkbox_all', ``, ``, ``, ``],
  checkbox1: [
    `[필수] 18세 이상입니다.`,
    `[Required] I am 18 years old or older.`,
    't_step1_email.checkbox1',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox2: (privacy_policy) => [
    <>[필수] {privacy_policy}에 동의합니다.</>,
    <>[Required] I agree to the {privacy_policy}.</>,
    't_step1_email.checkbox2',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox3: (terms_of_service) => [
    <>[필수] {terms_of_service}에 동의합니다.</>,
    <>[Required] I agree to the {terms_of_service}.</>,
    't_step1_email.checkbox3',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox4: [
    `[선택] 이벤트 및 혜택 소식을 수신하고 싶습니다.`,
    `[Optional] I want to receive Ringle Teens' exclusive promotions and latest news.`,
    't_step1_email.checkbox4',
    ``,
    ``,
    ``,
    ``,
  ],
  text_btn1: [`링글 틴즈 개인정보 취급 방침`, `Teens’ privacy policy`, 't_step1_email.text_btn1', ``, ``, ``, ``],
  text_btn2: [`링글 틴즈 이용약관`, `Teens’ terms of service`, 't_step1_email.text_btn2', ``, ``, ``, ``],
  btn1: [`인증번호 요청`, `Request Verification Code`, 't_step1_email.btn1', ``, ``, ``, ``],
  btn2: [`부모 계정 생성 & 다음`, `Create & Next`, 't_step1_email.btn2', ``, ``, ``, ``],
  btn3: [`인증하기`, `Verify`, 't_step1_email.btn3', ``, ``, ``, ``],
  msg1: [`올바른 이메일을 입력해 주세요`, `Please enter email correctly`, 't_step1_email.msg1', ``, ``, ``, ``],
  msg2: [
    `8자 이상의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요.`,
    `Enter at least 8 characters, with uppercase, lowercase, numbers, and special characters.`,
    't_step1_email.msg2',
    ``,
    ``,
    ``,
    ``,
  ],
  msg3: [`비밀번호를 확인해 주세요.`, `Please confirm your password`, 't_step1_email.msg3', ``, ``, ``, ``],
  msg4: [
    `이름은 2자 이상을 입력해 주세요.`,
    `Please enter at least 2 characters in the name`,
    't_step1_email.msg4',
    ``,
    ``,
    ``,
    ``,
  ],
  update: [`업데이트`, `Update`, 't_step1_email.update', ``, ``, ``, ``],
  btn_check: [`사용 가능 여부 확인`, `Check Availability`, 't_step1_email.btn_check', ``, ``, ``, ``],
  msg_success: [`사용 가능한 이메일입니다.`, `This email is available.`, 't_step1_email.msg_success', ``, ``, ``, ``],
  modal_title: [
    `이미 링글 계정이 있으신가요?`,
    `It seems you already have a Ringle account.`,
    't_step1_email.modal_title',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_subtitle1: [
    `링글과 동일한 계정을 사용하고 싶으시다면`,
    `Please verify your account using your Ringle login credentials`,
    't_step1_email.modal_subtitle1',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_subtitle2: [
    `링글 계정으로 로그인하고 계정 인증을 해주세요.`,
    `if you want to use the same account for Ringle Teens.`,
    't_step1_email.modal_subtitle2',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_placeholder1: [
    `이메일 주소를 입력해 주세요.`,
    `Enter your email adress`,
    't_step1_email.modal_placeholder1',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_placeholder2: [
    `비밀번호를 입력해 주세요.`,
    `Enter your password`,
    't_step1_email.modal_placeholder2',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_btn: [`로그인`, `Login`, 't_step1_email.modal_btn', ``, ``, ``, ``],
  modal_forgot: [`비밀번호를 잊으셨나요?`, `Forgot password?`, 't_step1_email.modal_forgot', ``, ``, ``, ``],
  modal_msg1: [
    `올바른 이메일을 입력해 주세요.`,
    `Please enter a vaild Email address`,
    't_step1_email.modal_msg1',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_msg2: [
    `비밀번호를 잘못 입력하셨습니다.`,
    `Did not match stored password`,
    't_step1_email.modal_msg2',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_tooltip: [
    <>
      링글 혹은 링글 틴즈 계정의
      <br />
      비밀번호를 변경하시면
      <br />두 계정 모두에 반영됩니다.
    </>,
    <>
      Changing the password for either
      <br />
      Ringle or Ringle Teens will automatically
      <br />
      change both passwords.
    </>,
    't_step1_email.modal_tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  msg_failure: [
    `해당 이메일로 가입한 계정이 이미 존재합니다.`,
    `The account registered under this email already exists.`,
    't_step1_email.msg_failure',
    ``,
    ``,
    ``,
    ``,
  ],
  check_child_email: [
    `자녀의 이메일 계정을 확인해주세요.`,
    `Please check child's email address.`,
    't_step1_email.check_child_email',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_step2 = {
  toast: [
    `링글 틴즈 부모 계정이 생성되었습니다!`,
    `Your account was successfully created!`,
    't_step2.toast',
    ``,
    ``,
    ``,
    ``,
  ],
  title: [`자녀의 기본 정보를 입력해 주세요.`, `Enter your child’s basic info`, 't_step2.title', ``, ``, ``, ``],
  subtitle: [
    `부모계정 하나 당 한개의 무료 수업권이 지급됩니다`,
    `One free lesson limit per parent`,
    't_step2.subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [`자녀 영어 이름`, `Child’s Name`, 't_step2.desc1', ``, ``, ``, ``],
  desc2: [`자녀 생년월일`, `Child’s Birthday`, 't_step2.desc2', ``, ``, ``, ``],
  desc3: [`타임존`, `Time Zone`, 't_step2.desc3', ``, ``, ``, ``],
  placeholder1: [`이름`, `First Name`, 't_step2.placeholder1', ``, ``, ``, ``],
  placeholder2: [`성`, `Last Name`, 't_step2.placeholder2', ``, ``, ``, ``],
  placeholder3: [`자녀 계정 이메일 (ID)`, `Email (ID) for child’s account`, 't_step2.placeholder3', ``, ``, ``, ``],
  placeholder4: [
    `자녀 계정 비밀번호 (최소 6자)`,
    `Password for child’s account (At least 6 characters)`,
    't_step2.placeholder4',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder5: [`비밀번호를 다시 입력해 주세요.`, `Re-enter password`, 't_step2.placeholder5', ``, ``, ``, ``],
  mm: [`월`, `mm`, 't_step2.mm', ``, ``, ``, ``],
  dd: [`일`, `dd`, 't_step2.dd', ``, ``, ``, ``],
  yyyy: [`연도`, `yyyy`, 't_step2.yyyy', ``, ``, ``, ``],
  title2: [
    `자녀가 직접 로그인을 할 수 있도록 자녀 계정을 만드시겠어요?`,
    `Do you wish your child to have their own login credentials?`,
    't_step2.title2',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle2: [
    <>
      자녀 계정이 없더라도
      <br />
      부모 계정을 통해 링글 틴즈를 사용할 수 있습니다.
    </>,
    <>
      Your child(s) can access Ringle Teens using a parent’s login
      <br />
      credentials without their own login credentials.
    </>,
    't_step2.subtitle2',
    ``,
    ``,
    ``,
    ``,
  ],
  radio1: [`예`, `Yes`, 't_step2.radio1', ``, ``, ``, ``],
  radio2: [`아니오`, `No`, 't_step2.radio2', ``, ``, ``, ``],
  msg1: [`올바른 이메일을 입력해 주세요.`, `Please enter email correctly`, 't_step2.msg1', ``, ``, ``, ``],
  msg2: [
    `8자 이상의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요.`,
    `Enter at least 8 characters, with uppercase, lowercase, numbers, and special characters.`,
    't_step2.msg2',
    ``,
    ``,
    ``,
    ``,
  ],
  msg3: [`비밀번호를 확인해 주세요.`, `Please confirm your password`, 't_step2.msg3', ``, ``, ``, ``],
  tooltip: [
    `부모 대시보드에서 다른 자녀의 정보를 <br>추가하실 수 있습니다. 단, 무료 수업권은 부모 계정에 한 개만 지급된다는 점을 유의해주세요.`,
    `You can add another child’s account later in the dashboard. But be sure that only one free lesson is available per parent.`,
    't_step2.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  btn2: [`다음`, `Create & Next`, 't_step2.btn2', ``, ``, ``, ``],
  title3: [`자녀의 기본 정보를 입력해 주세요.`, `Enter your child's basic info.`, 't_step2.title3', ``, ``, ``, ``],
}
export const t_step3 = {
  toast: [`거의 다 마무리 되어 갑니다!`, `Great! You are almost done.`, 't_step3.toast', ``, ``, ``, ``],
  title: (name) => [
    `${name}에 대해서 더 자세히 알려주세요!`,
    `Tell us more about ${name}`,
    't_step3.title',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle1: (name) => [
    <>
      튜터들이 작성해주신 답변을 통해 <br /> {name}을(를) 잘 이해하고 효과적인 수업을 진행할 수 있습니다.
    </>,
    <>
      These questions will help our tutors better understand {name}
      <br />
      so they can conduct more efficient lessons.
    </>,
    't_step3.subtitle1',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle2: [
    `입력한 답변은 대시보드에서 언제든지 수정하실 수 있습니다.`,
    `You can edit your answers later in the dashboard.`,
    't_step3.subtitle2',
    ``,
    ``,
    ``,
    ``,
  ],
  question1: (name) => [
    `${name}(이)가 영어를 자유롭게 구사하나요?`,
    `Is English ${name}’s primary language?`,
    't_step3.question1',
    ``,
    ``,
    ``,
    ``,
  ],
  question2: (name) => [
    <>
      {name}(이)가 링글 틴즈에서
      <br />
      어떤 능력이 성장하길 원하시나요? (복수 선택 가능)
    </>,
    <>
      What do you hope for {name} to accomplish using
      <br /> Ringle Teens? (multiple choice)
    </>,
    't_step3.question2',
    ``,
    ``,
    ``,
    ``,
  ],
  question3: (name) => [
    <>
      {name}에게 딱 맞는 수업 교재를 추천할 수 있도록
      <br />
      교재 주제를 선택해주세요 (세개 이상)
    </>,
    <>
      Would you select topics for us to recommend
      <br />
      {name}’s lesson materials? (3 and more)
    </>,
    't_step3.question3',
    ``,
    ``,
    ``,
    ``,
  ],
  radio1: [`예`, `Yes`, 't_step3.radio1', ``, ``, ``, ``],
  radio2: [`아니오`, `No`, 't_step3.radio2', ``, ``, ``, ``],
  checkbox1: [`비판적 사고 능력`, `Critical Thinking`, 't_step3.checkbox1', ``, ``, ``, ``],
  checkbox2: [`창의적 사고 능력`, `Creative Thinking`, 't_step3.checkbox2', ``, ``, ``, ``],
  checkbox3: [`소통 능력`, `Communication`, 't_step3.checkbox3', ``, ``, ``, ``],
  checkbox4: [`집중력`, `Concentration`, 't_step3.checkbox4', ``, ``, ``, ``],
  btn1: [`다음`, `Next`, 't_step3.btn1', ``, ``, ``, ``],
  modal_title: [`회원가입 나가기`, `Leave Sign Up`, 't_step3.modal_title', ``, ``, ``, ``],
  modal_desc: [
    `아직 회원 가입 과정 진행 중입니다. 정말 로그아웃 하시겠습니까?`,
    `You haven’t completed Teens sign up yet. Are you sure you want to log out ?`,
    't_step3.modal_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_btn1: [`계속 진행`, `Stay & Complete`, 't_step3.modal_btn1', ``, ``, ``, ``],
  modal_btn2: [`로그아웃`, `Log Out`, 't_step3.modal_btn2', ``, ``, ``, ``],
  primary_language: [
    `여러 주제에 대해 유창하고 자연스럽게<br>원어민과 대화할 수 있습니다.`,
    `Primary language is the language the student best understands and uses most frequently to communicate with others.`,
    't_step3.primary_language',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_dashboard_childaccount = {
  basic_info: {
    title: [`기본 정보`, `Basic Info`, 't_dashboard_childaccount.basic_info.title', ``, ``, ``, ``],
    email_id: [`이메일 ID`, `Email ID`, 't_dashboard_childaccount.basic_info.email_id', ``, ``, ``, ``],
    set_login: [
      `로그인 정보를 입력해주세요`,
      `Set Login Credentials`,
      't_dashboard_childaccount.basic_info.set_login',
      ``,
      ``,
      ``,
      ``,
    ],
    first_name: [`이름`, `First Name`, 't_dashboard_childaccount.basic_info.first_name', ``, ``, ``, ``],
    last_name: [`성`, `Last Name`, 't_dashboard_childaccount.basic_info.last_name', ``, ``, ``, ``],
    birthday: [`생년월일`, `Birthday`, 't_dashboard_childaccount.basic_info.birthday', ``, ``, ``, ``],
    primary_language: (name) => [
      `${name}(이)가 영어를 자유롭게 구사하나요?`,
      `Is English ${name}’s primary language?`,
      't_dashboard_childaccount.basic_info.primary_language',
      ``,
      ``,
      ``,
      ``,
    ],
    yes: [`예`, `Yes`, 't_dashboard_childaccount.basic_info.yes', ``, ``, ``, ``],
    no: [`아니오`, `No`, 't_dashboard_childaccount.basic_info.no', ``, ``, ``, ``],
    button_save: [`저장`, `Save`, 't_dashboard_childaccount.basic_info.button_save', ``, ``, ``, ``],
    dialog_1: {
      title: [
        `자녀 로그인 계정`,
        `Child’s Login Credentials`,
        't_dashboard_childaccount.basic_info.dialog_1.title',
        ``,
        ``,
        ``,
        ``,
      ],
      detail: [
        `자녀 로그인 계정이 없더라도 부모 계정을 통해 링글 틴즈를 사용할 수 있습니다.`,
        `Your child(ren) can access Ringle Teens using a parent’s login credentials without their own login credentials.`,
        't_dashboard_childaccount.basic_info.dialog_1.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder1: [
        `자녀 계정 이메일 ID`,
        `ID (Email) for child’s account`,
        't_dashboard_childaccount.basic_info.dialog_1.placeholder1',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder2: [
        `자녀 계정 비밀번호 (6자 이상)`,
        `Password for child’s account (At least 6 characters)`,
        't_dashboard_childaccount.basic_info.dialog_1.placeholder2',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder3: [
        `비밀번호를 한번 더 입력해 주세요.`,
        `Re-enter password`,
        't_dashboard_childaccount.basic_info.dialog_1.placeholder3',
        ``,
        ``,
        ``,
        ``,
      ],
      button1: [`취소`, `Cancel`, 't_dashboard_childaccount.basic_info.dialog_1.button1', ``, ``, ``, ``],
      button2: [`생성`, `Create`, 't_dashboard_childaccount.basic_info.dialog_1.button2', ``, ``, ``, ``],
      error1: [
        `올바른 이메일을 입력해 주세요.`,
        `Please enter email correctly`,
        't_dashboard_childaccount.basic_info.dialog_1.error1',
        ``,
        ``,
        ``,
        ``,
      ],
      error2: [
        `8자 이상의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요.`,
        `Enter at least 8 characters, with uppercase, lowercase, numbers, and special characters.`,
        't_dashboard_childaccount.basic_info.dialog_1.error2',
        ``,
        ``,
        ``,
        ``,
      ],
      error3: [
        `비밀번호를 확인해 주세요`,
        `Please confirm your password`,
        't_dashboard_childaccount.basic_info.dialog_1.error3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  edit_profile: {
    title: [`의 프로필`, `’s Profile`, 't_dashboard_childaccount.edit_profile.title', ``, ``, ``, ``],
    detail: [
      `학생의 프로필은 튜터들만 볼 수 있고, 언제든지 수정할 수 있습니다.`,
      `Only tutors can see your profile and it can be modified at any time.`,
      't_dashboard_childaccount.edit_profile.detail',
      ``,
      ``,
      ``,
      ``,
    ],
    required: [`필수`, `Required`, 't_dashboard_childaccount.edit_profile.required', ``, ``, ``, ``],
    optional: [`선택`, `Optional`, 't_dashboard_childaccount.edit_profile.optional', ``, ``, ``, ``],
    preview: [`미리보기`, `Preview`, 't_dashboard_childaccount.edit_profile.preview', ``, ``, ``, ``],
    skills_to_develop: {
      title: [
        `집중 성장 능력 (4Cs)`,
        `Skills to Develop (4Cs)`,
        't_dashboard_childaccount.edit_profile.skills_to_develop.title',
        ``,
        ``,
        ``,
        ``,
      ],
      detail: (name) => [
        `${name}(이)가 링글 틴즈 수업을 통해 어떤 능력을 키우길 원하시나요?`,
        `Select skills you want ${name}to develop by taking Ringle Teens lessons.`,
        't_dashboard_childaccount.edit_profile.skills_to_develop.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      list1: [
        `비판적 사고 능력`,
        `Critical Thinking`,
        't_dashboard_childaccount.edit_profile.skills_to_develop.list1',
        ``,
        ``,
        ``,
        ``,
      ],
      list2: [
        `창의적 사고 능력`,
        `Creative Thinking`,
        't_dashboard_childaccount.edit_profile.skills_to_develop.list2',
        ``,
        ``,
        ``,
        ``,
      ],
      list3: [
        `소통능력`,
        `Communication`,
        't_dashboard_childaccount.edit_profile.skills_to_develop.list3',
        ``,
        ``,
        ``,
        ``,
      ],
      list4: [
        `집중력`,
        `Concentration`,
        't_dashboard_childaccount.edit_profile.skills_to_develop.list4',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    lesson_goals: {
      title: [
        `링글 틴즈 사용 목적`,
        `Lesson Goals from Ringle Teens`,
        't_dashboard_childaccount.edit_profile.lesson_goals.title',
        ``,
        ``,
        ``,
        ``,
      ],
      detail: [
        `링글 틴즈에서 이루고자 하는 목표를 선택해주세요`,
        `Select your goals from Ringle Teens lessons.`,
        't_dashboard_childaccount.edit_profile.lesson_goals.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      list1: [
        `언어 및 어휘 능력을 기르기 위해`,
        `To enhance language and vocabulary skills`,
        't_dashboard_childaccount.edit_profile.lesson_goals.list1',
        ``,
        ``,
        ``,
        ``,
      ],
      list2: [
        `다양한 주제에 대해 배우기 위해`,
        `To gain knowledge on diverse themes`,
        't_dashboard_childaccount.edit_profile.lesson_goals.list2',
        ``,
        ``,
        ``,
        ``,
      ],
      list3: [
        `대학 생활 및 입시 준비에 대해 더 자세히 이해하고 목표를 세우기 위해`,
        `To better understand and set goals for college life/college prep`,
        't_dashboard_childaccount.edit_profile.lesson_goals.list3',
        ``,
        ``,
        ``,
        ``,
      ],
      list4: [
        `커리어에 대해 고민하고 대학 이후의 삶에 대해 배우기 위해`,
        `To explore career paths and understand life after college`,
        't_dashboard_childaccount.edit_profile.lesson_goals.list4',
        ``,
        ``,
        ``,
        ``,
      ],
      list5: [
        `나의 성장을 위한 시간`,
        `To learn for the sake of learning`,
        't_dashboard_childaccount.edit_profile.lesson_goals.list5',
        ``,
        ``,
        ``,
        ``,
      ],
      list6: [
        `튜터/멘토들로부터 영감을 받고 배우기 위해서`,
        `To be inspired by and learn from tutors/mentors`,
        't_dashboard_childaccount.edit_profile.lesson_goals.list6',
        ``,
        ``,
        ``,
        ``,
      ],
      list7: [`기타`, `Others`, 't_dashboard_childaccount.edit_profile.lesson_goals.list7', ``, ``, ``, ``],
      list7_placeholder: [
        `다른 코멘트 남기기`,
        `Enter other comments`,
        't_dashboard_childaccount.edit_profile.lesson_goals.list7_placeholder',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    materials_topics: {
      title: [
        `관심 교재 주제`,
        `Materials Topics`,
        't_dashboard_childaccount.edit_profile.materials_topics.title',
        ``,
        ``,
        ``,
        ``,
      ],
      detail: [
        <>
          관심 있는 주제를 3개 이상 선택해주세요. 선택을 반영하여 맞춤
          <br />
          수업 교재를 추천해드립니다.
        </>,
        `Select 3 topics (or more) of your choice. This would help us recommend intriguing lesson materials to you.`,
        't_dashboard_childaccount.edit_profile.materials_topics.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      button1: [`수정하기`, `Edit`, 't_dashboard_childaccount.edit_profile.materials_topics.button1', ``, ``, ``, ``],
      detail_styled: [
        <>
          <Div spanTag textPrimary>
            관심 있는 주제를 3개 이상 선택해주세요.
          </Div>{' '}
          선택을 반영하여 맞춤 수업 교재를 추천해드립니다.
        </>,
        <>
          <Div spanTag textPrimary>
            Select 3 topics (or more) of your choice.
          </Div>{' '}
          This would help us recommend intriguing lesson materials to you.
        </>,
        't_dashboard_childaccount.edit_profile.materials_topics.detail_styled',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    interests: {
      title: [`관심사`, `Interests`, 't_dashboard_childaccount.edit_profile.interests.title', ``, ``, ``, ``],
      detail: [
        <>
          관심 있는 주제들을 선택하여 튜터와 더 의미있는 대화를
          <br />
          해보세요.
        </>,
        `Your selection helps further a smooth conversation between you and your tutor.`,
        't_dashboard_childaccount.edit_profile.interests.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      button1: [`수정하기`, `Edit`, 't_dashboard_childaccount.edit_profile.interests.button1', ``, ``, ``, ``],
      message1: [
        `관심사를 선택해주세요`,
        `Select your interests`,
        't_dashboard_childaccount.edit_profile.interests.message1',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    about_me: {
      title: [`나에 관하여`, `About Me`, 't_dashboard_childaccount.edit_profile.about_me.title', ``, ``, ``, ``],
      detail: [
        `학생의 자기소개를 통해 튜터들은 더 효율적으로 수업을 준비할 수 있습니다.`,
        `Your introduction helps your tutors lead the lesson efficiently.`,
        't_dashboard_childaccount.edit_profile.about_me.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      category1: [
        `나에 관하여 (50자 이상)`,
        `About Me (at least 50 words)`,
        't_dashboard_childaccount.edit_profile.about_me.category1',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder1: [
        `자기소개, 거주지, 다니는 학교 등의 정보를 입력해주세요.`,
        `Please enter who you are, where you live, what school you go to, etc.`,
        't_dashboard_childaccount.edit_profile.about_me.placeholder1',
        ``,
        ``,
        ``,
        ``,
      ],
      category2: [
        `좋아하는 것이나 잘하는 것은 무엇인가요?`,
        `Things I’m Good at or Things I Like`,
        't_dashboard_childaccount.edit_profile.about_me.category2',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder2: [
        `좋아하는 것이나 잘하는 것은 무엇인가요?`,
        `Please write about what you are good at or like.`,
        't_dashboard_childaccount.edit_profile.about_me.placeholder2',
        ``,
        ``,
        ``,
        ``,
      ],
      category3: [`나의 꿈`, `My Dreams`, 't_dashboard_childaccount.edit_profile.about_me.category3', ``, ``, ``, ``],
      placeholder3: [
        `꿈이 무엇인가요?`,
        `Please write about your dream.`,
        't_dashboard_childaccount.edit_profile.about_me.placeholder3',
        ``,
        ``,
        ``,
        ``,
      ],
      category4: [
        `학교 생활`,
        `My School Life`,
        't_dashboard_childaccount.edit_profile.about_me.category4',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder4: [
        `학교 생활에 대해 알려주세요.`,
        `Please write about your school life.`,
        't_dashboard_childaccount.edit_profile.about_me.placeholder4',
        ``,
        ``,
        ``,
        ``,
      ],
      category5: [
        `요즘 가지고 있는 고민들`,
        `My Current Worries`,
        't_dashboard_childaccount.edit_profile.about_me.category5',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder5: [
        `현재 고민거리들은 무엇인가요?`,
        `Please write about your current worries.`,
        't_dashboard_childaccount.edit_profile.about_me.placeholder5',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    profile_photo: {
      title: [
        `프로필 사진`,
        `Profile Photo`,
        't_dashboard_childaccount.edit_profile.profile_photo.title',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    profile_video: {
      title: [
        `프로필 비디오`,
        `Profile Video`,
        't_dashboard_childaccount.edit_profile.profile_video.title',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    button_save: [`저장`, `Save`, 't_dashboard_childaccount.edit_profile.button_save', ``, ``, ``, ``],
    photo: {
      button1: [`사진 업로드`, `Upload picture`, 't_dashboard_childaccount.edit_profile.photo.button1', ``, ``, ``, ``],
      button2: [`사진 변경하기`, `Change photo`, 't_dashboard_childaccount.edit_profile.photo.button2', ``, ``, ``, ``],
    },
    video: {
      button1: [`녹음본 변경`, `Record video`, 't_dashboard_childaccount.edit_profile.video.button1', ``, ``, ``, ``],
      button2: [`비디오 변경`, `Change video`, 't_dashboard_childaccount.edit_profile.video.button2', ``, ``, ``, ``],
    },
    to_tutor_title: [
      `튜터에게 전달하고 싶은 메세지`,
      `Message to the Tutor`,
      't_dashboard_childaccount.edit_profile.to_tutor_title',
      ``,
      ``,
      ``,
      ``,
    ],
    to_tutor_desc: [
      `튜터에게 전달하고 싶은 학생의 특징과 영어 공부 목적을 알려주세요.`,
      ``,
      't_dashboard_childaccount.edit_profile.to_tutor_desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const t_account_setting = {
  basic_info_menu: [`기본 정보`, `Basic Info`, 't_account_setting.basic_info_menu', ``, ``, ``, ``],
  edit_profile_menu: [`프로필`, `Profile`, 't_account_setting.edit_profile_menu', ``, ``, ``, ``],
  change_password_menu: [`비밀번호`, `Password`, 't_account_setting.change_password_menu', ``, ``, ``, ``],
  basic_info: {
    title: [`기본 정보`, `Basic Info`, 't_account_setting.basic_info.title', ``, ``, ``, ``],
    email_id: [`이메일 ID`, `Email ID`, 't_account_setting.basic_info.email_id', ``, ``, ``, ``],
    first_name: [`이름`, `First Name`, 't_account_setting.basic_info.first_name', ``, ``, ``, ``],
    last_name: [`성`, `Last Name`, 't_account_setting.basic_info.last_name', ``, ``, ``, ``],
    birthday: [`생년월일`, `Birthday`, 't_account_setting.basic_info.birthday', ``, ``, ``, ``],
    primary_language: [
      `영어를 자유롭게 구사하나요?`,
      `Is English my primary language?`,
      't_account_setting.basic_info.primary_language',
      ``,
      ``,
      ``,
      ``,
    ],
    yes: [`예`, `Yes`, 't_account_setting.basic_info.yes', ``, ``, ``, ``],
    no: [`아니오`, `No`, 't_account_setting.basic_info.no', ``, ``, ``, ``],
    button_save: [`저장`, `Save`, 't_account_setting.basic_info.button_save', ``, ``, ``, ``],
  },
  edit_profile: {
    title: [`프로필 편집`, `Edit Profile`, 't_account_setting.edit_profile.title', ``, ``, ``, ``],
    detail: [
      `학생의 프로필은 튜터들만 볼 수 있고, 언제든지 수정할 수 있습니다.`,
      `Only tutors can see your profile and it can be modified at any time.`,
      't_account_setting.edit_profile.detail',
      ``,
      ``,
      ``,
      ``,
    ],
    required: [`필수`, `Required`, 't_account_setting.edit_profile.required', ``, ``, ``, ``],
    optional: [`선택`, `Optional`, 't_account_setting.edit_profile.optional', ``, ``, ``, ``],
    preview: [`미리보기`, `Preview`, 't_account_setting.edit_profile.preview', ``, ``, ``, ``],
    skills_to_develop: {
      title: [
        `기르고자 하는 능력 (4Cs)`,
        `Skills to Develop (4Cs)`,
        't_account_setting.edit_profile.skills_to_develop.title',
        ``,
        ``,
        ``,
        ``,
      ],
      detail: [
        `링글 틴즈 수업을 통해 향상시키고 싶은 능력을 선택하세요.`,
        `Select skills you want to develop by taking Ringle Teens lessons.`,
        't_account_setting.edit_profile.skills_to_develop.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      list1: [
        `비판적 사고 능력`,
        `Critical Thinking`,
        't_account_setting.edit_profile.skills_to_develop.list1',
        ``,
        ``,
        ``,
        ``,
      ],
      list2: [
        `창의적 사고 능력`,
        `Creative Thinking`,
        't_account_setting.edit_profile.skills_to_develop.list2',
        ``,
        ``,
        ``,
        ``,
      ],
      list3: [`소통 능력`, `Communication`, 't_account_setting.edit_profile.skills_to_develop.list3', ``, ``, ``, ``],
      list4: [`집중력`, `Concentration`, 't_account_setting.edit_profile.skills_to_develop.list4', ``, ``, ``, ``],
    },
    lesson_goals: {
      title: [
        `링글 틴즈 수업을 통해 이루고자 하는 것은?`,
        `Lesson Goals from Ringle Teens`,
        't_account_setting.edit_profile.lesson_goals.title',
        ``,
        ``,
        ``,
        ``,
      ],
      detail: [
        `링글 틴즈에서 이루고자 하는 목표를 선택해주세요`,
        `Select your goals from Ringle Teens lessons.`,
        't_account_setting.edit_profile.lesson_goals.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      list1: [
        `언어와 어휘 실력을 증진시키기 위해`,
        `To enhance language and vocabulary skills`,
        't_account_setting.edit_profile.lesson_goals.list1',
        ``,
        ``,
        ``,
        ``,
      ],
      list2: [
        `다양한 주제에 대해 배우기 위해`,
        `To gain knowledge on diverse themes`,
        't_account_setting.edit_profile.lesson_goals.list2',
        ``,
        ``,
        ``,
        ``,
      ],
      list3: [
        `대학 생활 및 입시 준비에 대해 더 자세히 이해하고 목표를 세우기 위해`,
        `To better understand and set goals for college life/college prep`,
        't_account_setting.edit_profile.lesson_goals.list3',
        ``,
        ``,
        ``,
        ``,
      ],
      list4: [
        `커리어에 대해 고민하고 대학 이후의 삶에 대해 배우기 위해`,
        `To explore career paths and understand life after college`,
        't_account_setting.edit_profile.lesson_goals.list4',
        ``,
        ``,
        ``,
        ``,
      ],
      list5: [
        `나의 성장을 위한 시간`,
        `To learn for the sake of learning`,
        't_account_setting.edit_profile.lesson_goals.list5',
        ``,
        ``,
        ``,
        ``,
      ],
      list6: [
        `튜터/멘토들로부터 좋은 영향을 받기 위해`,
        `To be inspired by and learn from tutors/mentors`,
        't_account_setting.edit_profile.lesson_goals.list6',
        ``,
        ``,
        ``,
        ``,
      ],
      list7: [`기타`, `Others`, 't_account_setting.edit_profile.lesson_goals.list7', ``, ``, ``, ``],
      list7_placeholder: [
        `다른 코멘트 남기기`,
        `Enter other comments`,
        't_account_setting.edit_profile.lesson_goals.list7_placeholder',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    materials_topics: {
      title: [
        `어떤 주제의 교재로 수업할까요?`,
        `Materials Topics`,
        't_account_setting.edit_profile.materials_topics.title',
        ``,
        ``,
        ``,
        ``,
      ],
      detail: [
        <>
          관심 있는 주제를 3개 이상 선택해주세요. 선택을 반영하여 맞춤
          <br />
          수업 교재를 추천해드립니다.
        </>,
        `Select  3 topics (or more) of your choice. This would help us recommend intriguing lesson materials to you.`,
        't_account_setting.edit_profile.materials_topics.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      button1: [`수정하기`, `Edit`, 't_account_setting.edit_profile.materials_topics.button1', ``, ``, ``, ``],
    },
    interests: {
      title: [`관심사`, `Interests`, 't_account_setting.edit_profile.interests.title', ``, ``, ``, ``],
      detail: [
        `관심 있는 주제들을 선택하여 튜터와 더 의미있는 대화를 해보세요.`,
        `Your selection helps further a smooth conversation between you and your tutor.`,
        't_account_setting.edit_profile.interests.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      button1: [`수정하기`, `Edit`, 't_account_setting.edit_profile.interests.button1', ``, ``, ``, ``],
      message1: [
        `관심사를 선택해주세요`,
        `Select your interests`,
        't_account_setting.edit_profile.interests.message1',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    about_me: {
      title: [`나에 관하여`, `About Me`, 't_account_setting.edit_profile.about_me.title', ``, ``, ``, ``],
      detail: [
        `학생의 자기소개를 통해 튜터들은 더 효율적으로 수업을 준비할 수 있습니다.`,
        `Your introduction helps your tutors lead the lesson efficiently.`,
        't_account_setting.edit_profile.about_me.detail',
        ``,
        ``,
        ``,
        ``,
      ],
      category1: [
        `나에 관하여 (50자 이상)`,
        `About Me (at least 50 words)`,
        't_account_setting.edit_profile.about_me.category1',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder1: [
        `자기소개, 거주지, 다니는 학교 등의 정보를 입력해주세요.`,
        `Please enter who you are, where you live, what school you go to, etc.`,
        't_account_setting.edit_profile.about_me.placeholder1',
        ``,
        ``,
        ``,
        ``,
      ],
      category2: [
        `좋아하는 것이나 잘하는 것은 무엇인가요?`,
        `Things I’m Good at or Things I Like`,
        't_account_setting.edit_profile.about_me.category2',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder2: [
        `좋아하는 것이나 잘하는 것은 무엇인가요?`,
        `Please write about what you are good at or like.`,
        't_account_setting.edit_profile.about_me.placeholder2',
        ``,
        ``,
        ``,
        ``,
      ],
      category3: [`나의 꿈`, `My Dreams`, 't_account_setting.edit_profile.about_me.category3', ``, ``, ``, ``],
      placeholder3: [
        `꿈이 무엇인가요?`,
        `Please write about your dream.`,
        't_account_setting.edit_profile.about_me.placeholder3',
        ``,
        ``,
        ``,
        ``,
      ],
      category4: [`학교 생활`, `My School Life`, 't_account_setting.edit_profile.about_me.category4', ``, ``, ``, ``],
      placeholder4: [
        `학교 생활에 대해 알려주세요.`,
        `Please write about your school life.`,
        't_account_setting.edit_profile.about_me.placeholder4',
        ``,
        ``,
        ``,
        ``,
      ],
      category5: [
        `요즘 가지고 있는 고민들`,
        `My Current Worries`,
        't_account_setting.edit_profile.about_me.category5',
        ``,
        ``,
        ``,
        ``,
      ],
      placeholder5: [
        `현재 고민거리들은 무엇인가요?`,
        `Please write about your current worries.`,
        't_account_setting.edit_profile.about_me.placeholder5',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    profile_photo: {
      title: [`프로필 사진`, `Profile Photo`, 't_account_setting.edit_profile.profile_photo.title', ``, ``, ``, ``],
    },
    profile_video: {
      title: [`프로필 비디오`, `Profile Video`, 't_account_setting.edit_profile.profile_video.title', ``, ``, ``, ``],
    },
    button_save: [`저장하기`, `Save`, 't_account_setting.edit_profile.button_save', ``, ``, ``, ``],
    photo: {
      button1: [`사진 업로드`, `Upload picture`, 't_account_setting.edit_profile.photo.button1', ``, ``, ``, ``],
      button2: [`사진 변경하기`, `Change photo`, 't_account_setting.edit_profile.photo.button2', ``, ``, ``, ``],
    },
    video: {
      button1: [`녹음본 변경하기`, `Record video`, 't_account_setting.edit_profile.video.button1', ``, ``, ``, ``],
      button2: [`비디오 변경`, `Change video`, 't_account_setting.edit_profile.video.button2', ``, ``, ``, ``],
    },
  },
}
export const t_child_intro = {
  title_default: (name) => [
    <>
      반갑습니다,{' '}
      <Div spanTag textPrimary>
        {name}
      </Div>
      !<br />
      본인에 대해서 더 자세히 알려주실래요?
    </>,
    <>
      Nice to meet you,{' '}
      <Div spanTag textPrimary>
        {name}
      </Div>
      !<br />
      Could you help us to get to know you better?
    </>,
    't_child_intro.title_default',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [
    `이 질문들을 통해 링글 틴즈는 학생에게 더 의미있는 경험을 제공할 수 있습니다.`,
    `Asking these questions will help us provide you with a better experience on Ringle Teens.`,
    't_child_intro.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  desc2: [
    <>
      완성하지 않고 나가면
      <br />
      답변이 저장이 되지 않으며, 처음부터 새로 시작해야합니다.
      <br />
      반드시 모든 것을 한번에 완성해주세요!
    </>,
    <>
      Your progress won’t be saved and you will need to restart the questionnaire
      <br />
      if leave without completing.
      <br />
      Make sure to complete it in one sitting!
    </>,
    't_child_intro.desc2',
    ``,
    ``,
    ``,
    ``,
  ],
  btn1: [`시작해볼까요?`, `Let's Start`, 't_child_intro.btn1', ``, ``, ``, ``],
}
export const t_child_step1 = {
  title1_default: [
    `링글 틴즈에서 이루고 싶은 목표가 무엇인가요?`,
    <>
      What are{' '}
      <Div spanTag textPrimary>
        your goals
      </Div>{' '}
      from Ringle Teens lessons?
    </>,
    't_child_step1.title1_default',
    ``,
    ``,
    ``,
    ``,
  ],
  title2_default: [
    `이루고 싶은 목표를 모두 선택해주세요`,
    `Select as many as you like`,
    't_child_step1.title2_default',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox1: [
    `언어와 어휘 실력을 증진시키기`,
    `To enhance language and vocabulary skills`,
    't_child_step1.checkbox1',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox2: [
    `커리어에 대해 고민하고 대학 이후의 삶에 대해 배우기`,
    `To gain knowledge on diverse themes`,
    't_child_step1.checkbox2',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox3: [
    `대학 생활 및 입시 준비에 대해 더 자세히 이해하고 목표를 세우기`,
    `To better understand and set goals for college life/college prep`,
    't_child_step1.checkbox3',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox4: [`나의 성장`, `To learn for the sake of learning`, 't_child_step1.checkbox4', ``, ``, ``, ``],
  checkbox5: [
    `튜터/멘토들로부터 좋은 영향을 받기`,
    `To be inspired by and learn from tutors/mentors`,
    't_child_step1.checkbox5',
    ``,
    ``,
    ``,
    ``,
  ],
  checkbox6: [`기타`, `Others`, 't_child_step1.checkbox6', ``, ``, ``, ``],
  placeholder: [`다른 코멘트 남기기`, `Enter other comments`, 't_child_step1.placeholder', ``, ``, ``, ``],
  btn1: [`다음`, `Next`, 't_child_step1.btn1', ``, ``, ``, ``],
}
export const t_child_step2 = {
  banner1: [`입력해주셔서 감사합니다!`, `Thank you for your input!`, 't_child_step2.banner1', ``, ``, ``, ``],
  title1_default: [
    `관심 있는 주제를 3가지 이상 골라주세요`,
    <>
      <Div spanTag textPrimary>
        Pick 3 topics
      </Div>{' '}
      (or more) of your choice
    </>,
    't_child_step2.title1_default',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [
    `선택을 반영하여 맞춤 수업 교재를 추천해드립니다.`,
    `This would help us recommend intriguing lesson materials to you.`,
    't_child_step2.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  btn1: [`다음`, `Next`, 't_child_step2.btn1', ``, ``, ``, ``],
  btn2: [`뒤로가기`, `Back`, 't_child_step2.btn2', ``, ``, ``, ``],
  tooltip: [
    `나갔다 돌아와도 입력한 정보들이 저장됩니다`,
    `Your answers for this page will remain upon return`,
    't_child_step2.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_child_step3 = {
  banner1: [`잘하셨습니다!`, `Great job!`, 't_child_step3.banner1', ``, ``, ``, ``],
  title1_default: [
    `무엇에 관심이 있으신가요?`,
    `Let’s find out what your interests are.`,
    't_child_step3.title1_default',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [
    `관심 있는 주제들을 선택하여 튜터와 더 의미있는 대화를 해보세요.`,
    `Your selection helps further a smooth conversation between you and your tutor.`,
    't_child_step3.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  btn1: [`다음`, `Next`, 't_child_step3.btn1', ``, ``, ``, ``],
  btn2: [`뒤로가기`, `Back`, 't_child_step3.btn2', ``, ``, ``, ``],
  tooltip: [
    `나갔다 돌아와도 입력한 정보들이 저장됩니다`,
    `Your answers for this page will remain upon return`,
    't_child_step3.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_child_step4 = {
  banner1: [
    `조금만 더 하면 링글 틴즈와 첫 수업을 시작하실 수 있어요!`,
    `You’re almost there to begin your first lesson with Ringle!`,
    't_child_step4.banner1',
    ``,
    ``,
    ``,
    ``,
  ],
  title1_default: [
    <>
      본인에 대해서{' '}
      <Div textPrimary spanTag>
        더 자세히 알려주세요.
      </Div>
    </>,
    <>
      Let’s learn a bit{' '}
      <Div textPrimary spanTag>
        more about you.
      </Div>
    </>,
    't_child_step4.title1_default',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [
    `학생의 자기소개를 통해 튜터들은 더 효율적으로 수업을 준비할 수 있습니다.`,
    `Your introduction helps your tutors lead the lesson efficiently.`,
    't_child_step4.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder: [
    `자기소개, 거주지, 다니는 학교 등의 정보를 입력해주세요.`,
    `Please enter who you are, where you live, what school you go to, etc.`,
    't_child_step4.placeholder',
    ``,
    ``,
    ``,
    ``,
  ],
  input_desc1: [`50자 이상`, `At least 50 words`, 't_child_step4.input_desc1', ``, ``, ``, ``],
  word_count: [`글자수:`, `Word count:`, 't_child_step4.word_count', ``, ``, ``, ``],
  btn1: [`다음`, `Next`, 't_child_step4.btn1', ``, ``, ``, ``],
  btn2: [`뒤로가기`, `Back`, 't_child_step4.btn2', ``, ``, ``, ``],
  tooltip: [
    `나갔다 돌아와도 입력한 정보들이 저장됩니다`,
    `Your answers for this page will remain upon return`,
    't_child_step4.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_child_step5 = {
  banner1: [`축하합니다! 모두 완료하셨습니다!`, `Yay! You made it!`, 't_child_step5.banner1', ``, ``, ``, ``],
  title1_default: [
    `본인에 대해 더 자세히 알려주세요!`,
    <>
      Is there{' '}
      <Div spanTag textPrimary>
        anything else you want to tell
      </Div>{' '}
      us?
    </>,
    't_child_step5.title1_default',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [
    `계정 설정에서 답변을 수정할 수 있습니다.`,
    `You can come back to these questions again through your profile settings.`,
    't_child_step5.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  title2_default: [
    `좋아하는 것이나 잘하는 것은 무엇인가요?`,
    `Things I’m Good at or Things I Like`,
    't_child_step5.title2_default',
    ``,
    ``,
    ``,
    ``,
  ],
  placeholder1: [
    `좋아하는 것이나 잘하는 것은 무엇인가요?`,
    `Please write about what you are good at or like.`,
    't_child_step5.placeholder1',
    ``,
    ``,
    ``,
    ``,
  ],
  title3_default: [`나의 꿈`, `My Dreams`, 't_child_step5.title3_default', ``, ``, ``, ``],
  placeholder2: [`꿈이 무엇인가요?`, `Please write about your dreams`, 't_child_step5.placeholder2', ``, ``, ``, ``],
  title4_default: [`학교 생활`, `My School Life`, 't_child_step5.title4_default', ``, ``, ``, ``],
  placeholder3: [
    `학교 생활에 대해 알려주세요.`,
    `Please write about your school life.`,
    't_child_step5.placeholder3',
    ``,
    ``,
    ``,
    ``,
  ],
  title5_default: [`요즘 가지고 있는 고민들`, `My Current Worries`, 't_child_step5.title5_default', ``, ``, ``, ``],
  placeholder4: [
    `현재 고민거리들은 무엇인가요?`,
    `Please write about your current worries.`,
    't_child_step5.placeholder4',
    ``,
    ``,
    ``,
    ``,
  ],
  btn1: [`시작하기`, `Get Started`, 't_child_step5.btn1', ``, ``, ``, ``],
  btn2: [`뒤로가기`, `Back`, 't_child_step5.btn2', ``, ``, ``, ``],
  tooltip: [
    `나갔다 돌아와도 입력한 정보들이 저장됩니다`,
    `Your answers for this page will remain upon return`,
    't_child_step5.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_child_logout = {
  modal_title: [`로그아웃`, `Log out`, 't_child_logout.modal_title', ``, ``, ``, ``],
  modal_desc: [
    <>
      아직 링글 틴즈 가입을 완료하지 않으셨습니다.
      <br />
      정말 그만두시겠습니까?
    </>,
    <>
      You haven't completed this process yet.
      <br />
      Are you sure you want to log out?
    </>,
    't_child_logout.modal_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_btn1: [`계속해서 완성하기`, `Stay & Complete`, 't_child_logout.modal_btn1', ``, ``, ``, ``],
  modal_btn2: [`로그아웃`, `Log out`, 't_child_logout.modal_btn2', ``, ``, ``, ``],
}
export const t_welcome_parent = {
  page_title: [`링글 틴즈 소개`, `Introduction to Ringle Teens`, 't_welcome_parent.page_title', ``, ``, ``, ``],
  btn_logout: [`로그아웃`, `Logout`, 't_welcome_parent.btn_logout', ``, ``, ``, ``],
  banner1: [
    `링글 틴즈에 오신 것을 환영합니다!`,
    `Welcome to Ringle Teens!`,
    't_welcome_parent.banner1',
    ``,
    ``,
    ``,
    ``,
  ],
  section1: {
    subtitle: [`링글 틴즈 영어 학습`, `HOW RINGLE TEENS WORKS`, 't_welcome_parent.section1.subtitle', ``, ``, ``, ``],
    title: [
      `링글 틴즈의 맞춤 및 체계적인 수업을 통해 자녀의 성장을 이끌어 주세요.`,
      `Design your child’s lessons whenever and wherever`,
      't_welcome_parent.section1.title',
      ``,
      ``,
      ``,
      ``,
    ],
    tab1: {
      default: [`Step 1`, `Step 1`, 't_welcome_parent.section1.tab1.default', ``, ``, ``, ``],
      title: [`예약 및 예습`, `Book & Lesson Prep`, 't_welcome_parent.section1.tab1.title', ``, ``, ``, ``],
      desc1: [
        `수업 예약하기 (시간/튜터 기준으로 분류)`,
        `Book a lesson (sort by time/ sort by tutor)`,
        't_welcome_parent.section1.tab1.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `맞춤 수업을 위한 수업 방식을 설정하세요`,
        `Set lesson style for customized lesson`,
        't_welcome_parent.section1.tab1.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `자녀의 수업 준비를 도와주세요 (교재 읽기, 오디오 파일 듣기, 답변할 질문 3개 이상 선택하기)`,
        `Assist child to prep for lesson (read the insight, listen to an audio file, select 3+ questions to answer)`,
        't_welcome_parent.section1.tab1.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    tab2: {
      default: [`Step 2`, `Step 2`, 't_welcome_parent.section1.tab2.default', ``, ``, ``, ``],
      title: [`수업 & 숙제`, `Lesson & Homework`, 't_welcome_parent.section1.tab2.title', ``, ``, ``, ``],
      desc1: [
        <>
          자녀가 시간에 맞춰 수업에 입장할 수 있도록
          <br />
          지도해주세요
        </>,
        `Make sure your child enters the lesson on time`,
        't_welcome_parent.section1.tab2.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        <>
          학생은 수업이 끝나고 한시간 이내로 숙제를
          <br />
          제출해야합니다
        </>,
        `Your child will be asked to submit a written assignment within one hour after the lesson is over`,
        't_welcome_parent.section1.tab2.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    tab3: {
      default: [`Step 3`, `Step 3`, 't_welcome_parent.section1.tab3.default', ``, ``, ``, ``],
      title: [`수업 복습`, `Lesson Review`, 't_welcome_parent.section1.tab3.title', ``, ``, ``, ``],
      desc1: [
        <>
          튜터의 수업 피드백을 확인하세요 (부모님을
          <br />
          위한 안내문 포함)
        </>,
        `Review tutor’s lesson feedback report (includes Message to Parent)`,
        't_welcome_parent.section1.tab3.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `글쓰기 숙제에 대한 튜터의 피드백을 확인하세요`,
        `Check tutor’s corrections and feedback on written assignment`,
        't_welcome_parent.section1.tab3.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        <>
          학생이 수업 분석과 피드백을 통해 지난 수업을
          <br />
          복습할 수 있도록 격려해주세요
        </>,
        `Encourage your child to review past lessons by checking lesson analysis and feedbacks`,
        't_welcome_parent.section1.tab3.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  section2: {
    subtitle: [`학부모 대시보드`, `PARENT’S DASHBOARD`, 't_welcome_parent.section2.subtitle', ``, ``, ``, ``],
    title: [
      `대시보드에서 자녀의 수업, 자녀 계정 및 수업권, 포인트 등을 관리하세요.`,
      `Manage your child’s lessons, account, and lesson credits in the dashboard`,
      't_welcome_parent.section2.title',
      ``,
      ``,
      ``,
      ``,
    ],
    point1: {
      title: [`수업 확인 및 관리`, `Check & Manage Lessons`, 't_welcome_parent.section2.point1.title', ``, ``, ``, ``],
      desc: [
        `수업을 예약하고 자녀의 과거 및 향후 수업을 관리할 수 있어요`,
        `Book lessons and manage child’s upcoming & past lessons`,
        't_welcome_parent.section2.point1.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    point2: {
      title: [`수업권 구매 및 관리`, `Lesson Credits`, 't_welcome_parent.section2.point2.title', ``, ``, ``, ``],
      desc: [
        `수업권을 구매하거나 수업권 사용 내역, 적립 포인트 내역 등을 확인할 수 있어요.`,
        `Purchase monthy plans and view lesson credits & history`,
        't_welcome_parent.section2.point2.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    point3: {
      title: [`계정 관리`, `Account Setting`, 't_welcome_parent.section2.point3.title', ``, ``, ``, ``],
      desc: [
        `자녀를 추가하고, 프로필 수정 및 계정 관리를 하실 수 있어요.`,
        `Add child account, edit profiles and account settings`,
        't_welcome_parent.section2.point3.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    btn1: [`대시보드로 가기`, `Go to Dashboard`, 't_welcome_parent.section2.btn1', ``, ``, ``, ``],
  },
  section3: {
    subtitle: [`자녀 학습포털`, `ENTER AS STUDENT`, 't_welcome_parent.section3.subtitle', ``, ``, ``, ``],
    title: [
      `자녀가 스스로 영어 학습을 주도하고 배울 수 있는 포털을 확인해 보세요.`,
      `Explore the portal where your child can take primary responsibility of their own learning`,
      't_welcome_parent.section3.title',
      ``,
      ``,
      ``,
      ``,
    ],
    point1: {
      title: [
        `튜터 및 교재 검색`,
        `Search Tutors & Materials`,
        't_welcome_parent.section3.point1.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `수업하고 싶은 튜터 및 흥미로운 수업 교재를 찾을 수 있어요.`,
        `Students could search for tutors and lesson materials they are interested in`,
        't_welcome_parent.section3.point1.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    point2: {
      title: [
        `수업 예약 및 수업 방식 설정`,
        `Book Lessons & Set Lesson Style`,
        't_welcome_parent.section3.point2.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `수업을 예약하고 원하는 수업 방식을 요청할 수 있어요.`,
        `Student could directly book lessons and request lesson style`,
        't_welcome_parent.section3.point2.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    point3: {
      title: [
        `체계적인 영어 학습`,
        `Systematic English Learning`,
        't_welcome_parent.section3.point3.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        <>
          단계별 학습 - 예습, 수업, 숙제, 복습을 통해
          <br />
          밀도있게 영어 실력을 향상 시킬 수 있어요.
        </>,
        `Space for lesson prep, lesson & homework, and lesson review`,
        't_welcome_parent.section3.point3.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    btn1: [`학습포털로 가기`, `Go to Student Portal`, 't_welcome_parent.section3.btn1', ``, ``, ``, ``],
  },
  section4: {
    subtitle: [
      `수업 길이 및 수업권`,
      `LESSON DURATION & CREDITS`,
      't_welcome_parent.section4.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      `자녀에게 적합한 수업 길이와 횟수를 고려하여 수업권을 구매하세요.`,
      `Find the appropriate credit package for your child.`,
      't_welcome_parent.section4.title',
      ``,
      ``,
      ``,
      ``,
    ],
    point1: {
      title: [`20분 & 40분 수업`, `20-min & 40-min Lessons`, 't_welcome_parent.section4.point1.title', ``, ``, ``, ``],
      desc: [
        `부담없이 일상 대화를 하려면 20분 수업, 심도있는 토론을 하려면 40분 수업을 추천합니다.`,
        `20-min lessons are efficient and less expensive, while 40-min lessons ensure in-depth discussions!`,
        't_welcome_parent.section4.point1.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    point2: {
      title: [
        `4/12/24/48회 수업권`,
        `4/12/24/48 Lesson Packages`,
        't_welcome_parent.section4.point2.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc: [
        `수업권 횟수가 많을수록 수업권의 수강 기간도 길어집니다. 3개월 동안 수강할 수 있는 12회 수업권을 가장 많이 인기가 많습니다.`,
        `4 or 12 lesson credits are great! Have an ambitious child? Try 24 or 48 lesson credits!`,
        't_welcome_parent.section4.point2.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    point3: {
      title: [`패키지 할인 혜택`, `Enjoy our Package Deals`, 't_welcome_parent.section4.point3.title', ``, ``, ``, ``],
      desc: [
        `더 많이 구매할수록 더 많이 할인됩니다. 최대 26% 할인된 가격으로 구매하세요!`,
        `The more you purchase, the bigger the discount! Get up to 26% off on your purchase!`,
        't_welcome_parent.section4.point3.desc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    btn1: [`가격 확인하기`, `Check Pricing`, 't_welcome_parent.section4.btn1', ``, ``, ``, ``],
  },
  btn1: [`체험수업 신청하기`, `Book a Trial Lesson`, 't_welcome_parent.btn1', ``, ``, ``, ``],
  welcome: [`환영합니다`, `Welcome`, 't_welcome_parent.welcome', ``, ``, ``, ``],
}
export const t_welcome_child = {
  page_title: [`환영합니다`, `Welcome`, 't_welcome_child.page_title', ``, ``, ``, ``],
  btn_logout: [`로그아웃`, `Logout`, 't_welcome_child.btn_logout', ``, ``, ``, ``],
  banner1: [`링글 틴즈에 오신 것을 환영합니다`, `Welcome to Ringle Teens`, 't_welcome_child.banner1', ``, ``, ``, ``],
  section1: {
    subtitle: [
      `링글 틴즈 수업은 이렇게 진행됩니다!`,
      `HOW RINGLE TEENS WORKS`,
      't_welcome_child.section1.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      `수업 방식을 언제, 어디서든 직접 설정하세요`,
      `Design your lessons whenever and wherever`,
      't_welcome_child.section1.title',
      ``,
      ``,
      ``,
      ``,
    ],
    tab1: {
      default: [`Step 1`, `Step 1`, 't_welcome_child.section1.tab1.default', ``, ``, ``, ``],
      title: [`수업 준비`, `Lesson Preparation`, 't_welcome_child.section1.tab1.title', ``, ``, ``, ``],
      desc1: [<>"인사이트" 읽기</>, <>Read the "Insight"</>, 't_welcome_child.section1.tab1.desc1', ``, ``, ``, ``],
      desc2: [`오디오 파일 듣기`, `Listen to an audio file`, 't_welcome_child.section1.tab1.desc2', ``, ``, ``, ``],
      desc3: [`비디오 클립 보기`, `Watch a video clip`, 't_welcome_child.section1.tab1.desc3', ``, ``, ``, ``],
      desc4: [
        `질문을 세가지 이상 선택하세요`,
        `Select 3+ questions to answer`,
        't_welcome_child.section1.tab1.desc4',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    tab2: {
      default: [`Step 2`, `Step 2`, 't_welcome_child.section1.tab2.default', ``, ``, ``, ``],
      title: [`수업 & 숙제`, `Lesson & Homework`, 't_welcome_child.section1.tab2.title', ``, ``, ``, ``],
      desc1: [
        `튜터와의 대화에 참여하세요`,
        `Engage in an active conversation with a tutor`,
        't_welcome_child.section1.tab2.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `튜터로부터 실시간 피드백을 받아보세요`,
        `Receive live feedback from the tutor`,
        't_welcome_child.section1.tab2.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `쓰기 숙제를 하며 수업을 마무리 하세요`,
        `Conclude your lesson with a written assignment`,
        't_welcome_child.section1.tab2.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    tab3: {
      default: [`Step 3`, `Step 3`, 't_welcome_child.section1.tab3.default', ``, ``, ``, ``],
      title: [`수업 복습`, `Lesson Review`, 't_welcome_child.section1.tab3.title', ``, ``, ``, ``],
      desc1: [
        `수업 녹음본을 듣고 복습하세요`,
        `Listen to the lesson recording and review your session`,
        't_welcome_child.section1.tab3.desc1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc2: [
        `수업 분석, 피드백 및 교정본을 확인하세요`,
        `Check lesson analysis, feedback, and corrections`,
        't_welcome_child.section1.tab3.desc2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc3: [
        `글쓰기 숙제에 대한 튜터의 피드백을 검토하세요`,
        `Review your tutor's feedback on your written assignment`,
        't_welcome_child.section1.tab3.desc3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  section2: {
    subtitle: [
      `십대 중심의 인사이트`,
      `KEY FEATURES: TEEN-FOCUSED INSIGHTS`,
      't_welcome_child.section2.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      `링글 틴즈의 흥미로운 교재들을 둘러보세요`,
      `Explore our interesting lesson materials.`,
      't_welcome_child.section2.title',
      ``,
      ``,
      ``,
      ``,
    ],
    point1: [
      <>
        학년별로 나뉘어진 교재들이 매주 업데이트
        <br />
        됩니다
      </>,
      `Articles are updated weekly and organized by grade level`,
      't_welcome_child.section2.point1',
      ``,
      ``,
      ``,
      ``,
    ],
    point2: [
      `수업 일상 대화, 교과목, 북챗과 같은 주제들로 진행됩니다`,
      `Lesson topics include casual conversation, school subjects, and book chats`,
      't_welcome_child.section2.point2',
      ``,
      ``,
      ``,
      ``,
    ],
    point3: [
      `학생들은 Student's Choice lessons를 통해 본인만의 교재에 집중할 수 있습니다`,
      `Teens can focus on their own content with Student’s Choice lessons`,
      't_welcome_child.section2.point3',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`교재 살펴보기`, `Explore Materials`, 't_welcome_child.section2.btn', ``, ``, ``, ``],
  },
  section3: {
    subtitle: [
      `링글 틴즈의 튜터들을 소개합니다`,
      `KEY FEATURES: OUR TUTORS`,
      't_welcome_child.section3.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      `링글 틴즈의 뛰어난 튜터들에 대해 더 알아보세요`,
      `Learn more about our celebrated tutors.`,
      't_welcome_child.section3.title',
      ``,
      ``,
      ``,
      ``,
    ],
    point1: [
      `튜터 선발 과정에서 뛰어난 성적을 거둔 명문대 원어민 튜터들`,
      `Experienced native English speaking tutors who aced our tutor selection process`,
      't_welcome_child.section3.point1',
      ``,
      ``,
      ``,
      ``,
    ],
    point2: [
      `STEM 에서 ART 까지 다양한 전공의 튜터들`,
      `Knowledgeable scholars in areas ranging from STEM to the Arts`,
      't_welcome_child.section3.point2',
      ``,
      ``,
      ``,
      ``,
    ],
    point3: [
      <>
        학업과 인생에 대한 멘토링까지 이끌어내는
        <br />
        튜터들
      </>,
      `Mentors who can motivate students to have a positive attitude in life and academia`,
      't_welcome_child.section3.point3',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`튜터들 살펴보기`, `Explore Tutors`, 't_welcome_child.section3.btn', ``, ``, ``, ``],
  },
  btn1: [`수업 예약하기`, `Book a Lesson`, 't_welcome_child.btn1', ``, ``, ``, ``],
  btn2: [`수업 준비하러 가기`, `Go to Lesson Prep`, 't_welcome_child.btn2', ``, ``, ``, ``],
}
export const t_schedule = {
  lesson_prep: {
    btn: [`예습하러 가기`, `Lesson Prep`, 't_schedule.lesson_prep.btn', ``, ``, ``, ``],
  },
  byteensforteens: {
    tooltip_body: [
      `By Teens, For Teens는 Let's Write Together 수업을 통해 학생들이 직접 쓴 교재입니다.`,
      `By Teens, For Teens materials are written by teen students during Let’s Write Together lessons.`,
      't_schedule.byteensforteens.tooltip_body',
      ``,
      ``,
      ``,
      ``,
    ],
    tooltip_btn: [
      `Let's Write Together 알아보기`,
      `Learn More`,
      't_schedule.byteensforteens.tooltip_btn',
      ``,
      ``,
      ``,
      ``,
    ],
    tooltip_btn1: [`닫기`, `Close`, 't_schedule.byteensforteens.tooltip_btn1', ``, ``, ``, ``],
    tooltip_btn2: [`더 알아보기`, `Learn More`, 't_schedule.byteensforteens.tooltip_btn2', ``, ``, ``, ``],
  },
}
export const t_point = {
  list_1: [
    `링글 포인트는 1:1 영어회화, 수강기간 연장, 결제 시점에 사용할 수 있습니다.`,
    `You can use Points to pay for lesson credits.`,
    't_point.list_1',
    ``,
    ``,
    ``,
    ``,
  ],
  list_2: [
    `포인트 유효기간은 적립일로부터 365일 입니다. (프로모션 포인트는 발급될 때마다 상이한 유효기간이 제공됩니다.)`,
    `Points will expire in 365 days from the issue date. Points given during promotion period may vary in expiration dates.`,
    't_point.list_2',
    ``,
    ``,
    ``,
    ``,
  ],
  list_3: [
    `수업 포인트는 유료로 구매한 정규수업 완료 7일 후 자동 적립됩니다. (전체 적립액: 구매한 수업권 실결제 금액의 3%)`,
    `Points will be awarded at 3% of the original purchased price per lesson in 7 days after completing each lesson.`,
    't_point.list_3',
    ``,
    ``,
    ``,
    ``,
  ],
  list_4: [
    `무료 수업권 사용 또는 비정상적으로 종료된 수업이 복구되는 경우 수업 포인트는 적립되지 않습니다.`,
    `Lessons taken with free credits, or lessons terminated in unusual circumstances will not be awarded Points.`,
    't_point.list_4',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_unassigned_lesson = {
  tooltip: [
    `당일수업 예약 시에도 교재를 선택할 수 있습니다.`,
    `You can also select the lesson material for last-minute lessons.`,
    's_unassigned_lesson.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  inform: {
    topic_of_this_lesson_will_be: [
      <>
        당일 수업은 24시간 이내 시작하는 수업으로, 수업까지 남은 시간에 따라 선택할 수 있는 교재가 달라집니다.
        <br />- 수업 시작 기준 1시간 이전 : 모든 교재
        <br />- 수업 시작 기준 1시간 이내: '자유주제' 또는 '튜터가 이전에 수업한 교재'
      </>,
      <>
        For last-minute lessons, you can select lesson materials as follows:
        <br />
        -Up until 1 hour before lesson begins: Any available lesson material
        <br />
        -Within one hour before lesson begins: 'Free Topic' or 'Tutor's Previously Taught Materials'
      </>,
      's_unassigned_lesson.inform.topic_of_this_lesson_will_be',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  reservation_success_info: [
    <>
      당일수업은 튜터 및 시간 변경이 불가합니다.
      <br />
      당일수업은 수업 취소 및 노쇼 시 수업권이 차감됩니다.
    </>,
    <>
      You cannot change the tutor or time slot for last-minute lessons.
      <br />
      The credit used for booking the last-minute lesson will not be returned even if the lesson is cancelled or missed
      (no-show).
    </>,
    's_unassigned_lesson.reservation_success_info',
    ``,
    ``,
    ``,
    ``,
  ],
  reservation_failure_info: [
    `예약을 실패하였습니다. 다시 수업을 예약해주세요.`,
    `Your booking didn't go through. Please try booking a different lesson.`,
    's_unassigned_lesson.reservation_failure_info',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_recommended_tutor = {
  notice: [
    `링글이 추천하는 튜터와 수업해 보세요!`,
    `Learn from Ringle-recommended tutors!`,
    's_recommended_tutor.notice',
    ``,
    ``,
    ``,
    ``,
  ],
  tag1: [`#높은평점`, `#HighlyRated`, 's_recommended_tutor.tag1', ``, ``, ``, ``],
  tag2: [`#링글팀pick`, `#RingleTeamPick`, 's_recommended_tutor.tag2', ``, ``, ``, ``],
  tag3: [`#불만족시수업권복구`, `#GetCreditBack`, 's_recommended_tutor.tag3', ``, ``, ``, ``],
  desc1: [
    `일정 기간 높은 평점과 긍정적인 리뷰를 유지하는 튜터입니다.`,
    `These tutors have maintained high ratings and received positive reviews from students for a given period.`,
    's_recommended_tutor.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  desc2: [
    `링글팀이 직접 수업을 듣고 수업 진행 및 교정 능력이 뛰어난 튜터를 선발했습니다.`,
    `The list also includes tutors recommended by members of the Ringle team who personally took lessons from them.`,
    's_recommended_tutor.desc2',
    ``,
    ``,
    ``,
    ``,
  ],
  desc3: [
    <>
      <span style={{ color: '#513CCD' }}>[1월, 2월 기간 한정]</span> 유료 수업권으로 예약한 추천 튜터 수업 만족도가 낮을
      경우, 수업권을 복구해 드립니다. (튜터 평가 3점 이하 및 사유 제출, 월 2회 한정)
    </>,
    <>
      <span style={{ color: '#513CCD' }}>[Limited Time Offer]</span> Get your credit back if you are not satisfied with
      a featured tutor. (Rate as 3 stars or lower and must submit reason for low rating, limited to twice per month)
    </>,
    's_recommended_tutor.desc3',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_contents_material_ad = {
  title_1: [
    `지금 무료로 회원가입 하고
콘텐츠를 끝까지 읽어보세요!`,
    ``,
    's_contents_material_ad.title_1',
    ``,
    ``,
    ``,
    ``,
  ],
  title_2: [
    `지금 무료로 회원가입 하고
한글 번역본으로 읽어보세요!`,
    ``,
    's_contents_material_ad.title_2',
    ``,
    ``,
    ``,
    ``,
  ],
  title_3: [
    `지금 무료로 회원가입 하고
수업질문을 확인해 보세요.`,
    ``,
    's_contents_material_ad.title_3',
    ``,
    ``,
    ``,
    ``,
  ],
  title_4: [
    `지금 무료로 회원가입 하고
핵심표현을 확인해 보세요.`,
    ``,
    's_contents_material_ad.title_4',
    ``,
    ``,
    ``,
    ``,
  ],
  title_5: [
    `링글 관련 콘텐츠는
앱에서 이용하실 수 있습니다.`,
    ``,
    's_contents_material_ad.title_5',
    ``,
    ``,
    ``,
    ``,
  ],
  title_6: [
    `링글 앱에서 더 많은 교재를
무료로 확인해보세요.`,
    ``,
    's_contents_material_ad.title_6',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_sign_up: [`회원가입 하기`, ``, 's_contents_material_ad.btn_sign_up', ``, ``, ``, ``],
  btn_login: [`로그인`, ``, 's_contents_material_ad.btn_login', ``, ``, ``, ``],
  subtitle: [`이미 회원가입 하셨다면?`, ``, 's_contents_material_ad.subtitle', ``, ``, ``, ``],
  subtitle_2: [`이 콘텐츠로 수업하는 법이 궁금하신가요?`, ``, 's_contents_material_ad.subtitle_2', ``, ``, ``, ``],
  tooltip: [
    `지금 무료로 회원가입 하고, 한글 번역본과 함께 읽어보세요!`,
    ``,
    's_contents_material_ad.tooltip',
    ``,
    ``,
    ``,
    ``,
  ],
  see_detail: [`자세히 보기`, ``, 's_contents_material_ad.see_detail', ``, ``, ``, ``],
  btn_start_app: [`앱에서 링글 시작하기`, ``, 's_contents_material_ad.btn_start_app', ``, ``, ``, ``],
  title_7: [
    `지금 무료로 회원가입하고
링글의 콘텐츠를 이용해 보세요.`,
    ``,
    's_contents_material_ad.title_7',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_survey = {
  title: [`마지막 단계입니다!`, `Just one more step left to finish!`, 't_survey.title', ``, ``, ``, ``],
  subtitle: [
    <>
      링글 틴즈 서비스 개선을 위해
      <br />
      질문에 답변해 주세요!
    </>,
    <>
      Please answer these questions <br />
      to help us improve Ringle Teens!
    </>,
    't_survey.subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  question1_html: [
    <>
      어떻게 링글 틴즈를 알게 되었나요? <br />
      (중복 선택 가능)
    </>,
    <>
      How did you know about Ringle Teens? <br />
      (multiple answers possible)
    </>,
    't_survey.question1_html',
    ``,
    ``,
    ``,
    ``,
  ],
  question1: [
    `어떻게 링글 틴즈를 알게 되었나요? (중복 선택 가능)`,
    `How did you know about Ringle Teens? (multiple answers possible)`,
    't_survey.question1',
    ``,
    ``,
    ``,
    ``,
  ],
  q1_checkbox1: [`Ringle Plus 이메일/팝업`, `Ringle Plus email/Pop-up ads`, 't_survey.q1_checkbox1', ``, ``, ``, ``],
  q1_checkbox2: [
    `직접 문의 후 안내 받음`,
    `1:1 consultation with the Ringle Teens team`,
    't_survey.q1_checkbox2',
    ``,
    ``,
    ``,
    ``,
  ],
  q1_checkbox3: [`지인 추천`, `Recommendation from a friend (Word of Mouth)`, 't_survey.q1_checkbox3', ``, ``, ``, ``],
  q1_checkbox4: [`검색`, `Search engine (e.g. Google, Naver)`, 't_survey.q1_checkbox4', ``, ``, ``, ``],
  q1_checkbox5: [`뉴스기사`, `News articles`, 't_survey.q1_checkbox5', ``, ``, ``, ``],
  q1_checkbox6: [`블로그`, `Blog Post`, 't_survey.q1_checkbox6', ``, ``, ``, ``],
  q1_checkbox7: [`기타`, `Other`, 't_survey.q1_checkbox7', ``, ``, ``, ``],
  placeholder1: [`기타 항목을 입력해 주세요`, `Enter other comments`, 't_survey.placeholder1', ``, ``, ``, ``],
  question2_html: [
    <>
      어디에서 자녀 교육에 대한 정보를 얻으시나요? <br />
      (중복 선택 가능)
    </>,
    <>
      Where do you find information about your <br />
      children's education? (multiple answers possible)
    </>,
    't_survey.question2_html',
    ``,
    ``,
    ``,
    ``,
  ],
  question2: [
    `어디에서 자녀 교육에 대한 정보를 얻으시나요? (중복 선택 가능)`,
    `Where do you find information about your children's education? (multiple answers possible)`,
    't_survey.question2',
    ``,
    ``,
    ``,
    ``,
  ],
  q2_checkbox1: [`학교/지역 커뮤니티`, `School / Local community`, 't_survey.q2_checkbox1', ``, ``, ``, ``],
  q2_checkbox2: [`온라인 커뮤니티`, `Online community`, 't_survey.q2_checkbox2', ``, ``, ``, ``],
  q2_checkbox3: [`SNS/온라인 광고`, `Social media / Online ads`, 't_survey.q2_checkbox3', ``, ``, ``, ``],
  q2_checkbox4: [`친구/지인`, `Friends (Word of Mouth)`, 't_survey.q2_checkbox4', ``, ``, ``, ``],
  q2_checkbox5: [`도서/신문/잡지`, `Books / Publication / Magazine`, 't_survey.q2_checkbox5', ``, ``, ``, ``],
  q2_checkbox6: [`입시설명회`, `College Admissions Consulting`, 't_survey.q2_checkbox6', ``, ``, ``, ``],
  q2_checkbox7: [`유학원/학원`, `Private educational institutions`, 't_survey.q2_checkbox7', ``, ``, ``, ``],
  q2_checkbox8: [`개인과외교사/멘토`, `Personal tutors or mentors`, 't_survey.q2_checkbox8', ``, ``, ``, ``],
  btn1: [`회원 가입 완료`, `Complete`, 't_survey.btn1', ``, ``, ``, ``],
  error: [
    `설문 결과를 저장하는 중 오류가 발생했습니다.`,
    `An error occurred while saving survey results.`,
    't_survey.error',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_onboarding = {
  optional: [`선택입력`, `Optional`, 't_onboarding.optional', ``, ``, ``, ``],
}
export const t_landing_home_kr = {
  hero: {
    title: [
      <>
        아이비리그 출신
        <br />
        원어민 튜터와 함께하는
        <br />
        1:1 화상영어 수업
      </>,
      ``,
      't_landing_home_kr.hero.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      <>
        영어가 장벽이 아닌 <b>디딤돌이 되고</b>
        <br />
        국내를 넘어{' '}
        <b>
          전 세계를 나의 무대로
          <br />
          생각하도록 만들어주세요.
        </b>
      </>,
      ``,
      't_landing_home_kr.hero.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`가입하고 무료체험하기`, ``, 't_landing_home_kr.hero.btn', ``, ``, ``, ``],
    btn_pc: [`지금 무료 체험 시작하기`, ``, 't_landing_home_kr.hero.btn_pc', ``, ``, ``, ``],
    review: {
      pc: [
        <>
          글로벌 무대를 꿈꾸는 아이들은
          <br />
          이미 링글 틴즈를 시작했습니다.
        </>,
        ``,
        't_landing_home_kr.hero.review.pc',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  review: {
    default: [
      <>
        글로벌 무대를
        <br />
        꿈꾸는 아이들은
        <br />
        이미 링글 틴즈를 시작했습니다.
      </>,
      ``,
      't_landing_home_kr.review.default',
      ``,
      ``,
      ``,
      ``,
    ],
    label_parents: [`학부모 후기`, ``, 't_landing_home_kr.review.label_parents', ``, ``, ``, ``],
    label_students: [`학생 후기`, ``, 't_landing_home_kr.review.label_students', ``, ``, ``, ``],
  },
  review1: {
    desc: [
      `영어 유치원 졸업 후, 아이가 영어에 노출되는 시간이 줄어서 걱정했어요. 수준 높은 원어민과 집중해서 1:1 수업을 하니, 스피킹과 발음이 유지되고 단어가 느는 것이 보여서  매우 만족스럽습니다. 이에 힘입어 국제 중학교 진학을 생각하고 있습니다.`,
      ``,
      't_landing_home_kr.review1.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`초등3학년 학부모, 김OO 님`, ``, 't_landing_home_kr.review1.reviewer', ``, ``, ``, ``],
  },
  review2: {
    desc: [
      `학원이랑 다르게 선생님이랑 1:1 로 하는 수업이라 좋아요. 제가 하고 싶은 이야기를 하니까 영어가 더 재미있어졌어요. 얼마 전에 영어 경시 대회에서 대상을 수상하게 된 건 링글 틴즈 튜터 덕분이에요.`,
      ``,
      't_landing_home_kr.review2.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`초등4학년 학생, 이OO 군`, ``, 't_landing_home_kr.review2.reviewer', ``, ``, ``, ``],
  },
  review3: {
    desc: [
      `1년 정도 미국 생활을 준비하고 있는데, 막상 아이가 미국에서 적응을 잘 할 수 있을지 걱정이 많았어요. 그런데 튜터 선생님 만나서 말도 훨씬 자연스럽게 하고, 자기 생각도 잘 표현하는 거 보면서 훨씬 마음을 놓을 수 있게 되어 제겐 너무 고마운 플랫폼입니다.`,
      ``,
      't_landing_home_kr.review3.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`초등6학년 학부모, 이OO 님`, ``, 't_landing_home_kr.review3.reviewer', ``, ``, ``, ``],
  },
  review4: {
    desc: [
      `어릴 때 미국에서 살았는데, 한국에 와서 영어를 자꾸 까먹는 것 같았거든요. 그런데 튜터 선생님이랑 얘기하다 보니 영어도 잘 나오고, 새로운 단어도 배우게 되어서 좋아요. 특히 제가 읽고 싶었지만 이해하기 어려웠던 고전 책들을 링글 틴즈에서 10권 넘게 읽었어요!`,
      ``,
      't_landing_home_kr.review4.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`초등6학년 학생, 허OO 양`, ``, 't_landing_home_kr.review4.reviewer', ``, ``, ``, ``],
  },
  review5: {
    desc: [
      `아이의 미국 고등학교 입학을 위한 영어 인터뷰 및 에세이 준비를 앞두고 도움이 될만한 서비스가 있을까 찾다가 링글 틴즈를 만났습니다. 링글 틴즈 튜터들의 도움으로 아이가 원하는 학교에 진학할 수 있었습니다.`,
      ``,
      't_landing_home_kr.review5.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`미국 8학년 학부모, 김OO 님`, ``, 't_landing_home_kr.review5.reviewer', ``, ``, ``, ``],
  },
  review6: {
    desc: [
      `학원에서 단어만 외우다가 이렇게 선생님이랑 많이 얘기하고 내가 쓰고 싶은 글도 쓰니까 훨씬 재미있는 것 같아요. 어떤 생각을 말해도 다 잘 들어주고 친한 형, 누나랑 수다 떨듯이 영어 공부해서 좋아요.`,
      ``,
      't_landing_home_kr.review6.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`중학교1학년 학생, 권OO 양`, ``, 't_landing_home_kr.review6.reviewer', ``, ``, ``, ``],
  },
  review7: {
    desc: [
      `구글에서 인턴을 하고 있는 하버드 출신 튜터와 이야기를 나누더니 본인도 새로운 기술을 개발하는 엔지니어가 되고 싶다고 하네요. 우수한 튜터들에게 영어뿐만 아니라 다양하고 넓은 세상에 대한 긍정적인 영향을 받아요.`,
      ``,
      't_landing_home_kr.review7.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`미국 9학년 학부모, 권OO 님`, ``, 't_landing_home_kr.review7.reviewer', ``, ``, ``, ``],
  },
  review8: {
    desc: [
      `튜터 쌤이 다니고 있는 하버드 대학교 생활이 어떤지 얘기를 들을 수 있어서 좋았어요. 저도 제가 좋아하는 공부도 하고 미식축구도 하고, 영자 신문 클럽에도 들어갈 수 있는 아이비리그 대학교에 가보고 싶어요.`,
      ``,
      't_landing_home_kr.review8.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    reviewer: [`고등학교 1학년 학생, 김OO 군`, ``, 't_landing_home_kr.review8.reviewer', ``, ``, ``, ``],
  },
  subtitle1: [`원어민이라고 다 좋은 영어 선생님일까요?`, ``, 't_landing_home_kr.subtitle1', ``, ``, ``, ``],
  title1: [
    <>
      차별화된 원어민 선생님.
      <br />
      아이비리그 출신의 똑똑한 튜터
    </>,
    ``,
    't_landing_home_kr.title1',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [
    <>
      영∙미 Top 30 대학교의 우수한 교육 환경에서 높은 성취를 경험한 링글 틴즈 튜터들은 아이들에게 영어 그 이상을
      가르칩니다.
      <br />
      <br />
      검증된 역량과 인성을 갖춘 멘토로서 아이가 넓은 세상에 대한 호기심을 가지고 긍정적인 마인드를 가진 인재로 성장할 수
      있도록 도와줍니다.
    </>,
    ``,
    't_landing_home_kr.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  more_tutor: [`더 많은 튜터들을 만나보고 싶다면?`, ``, 't_landing_home_kr.more_tutor', ``, ``, ``, ``],
  text_btn1: [`지금 링글 틴즈 앱을 다운받으세요!`, ``, 't_landing_home_kr.text_btn1', ``, ``, ``, ``],
  subtitle2: [`링글에서는 어떻게 영어를 배우나요?`, ``, 't_landing_home_kr.subtitle2', ``, ``, ``, ``],
  title2: [
    <>
      영어 원서 읽기부터 멘토링까지.
      <br />
      진짜 영어 실력을 위한
      <br />
      1:1 맞춤 영어 수업
    </>,
    ``,
    't_landing_home_kr.title2',
    ``,
    ``,
    ``,
    ``,
  ],
  desc2: [
    `링글은 함께 읽기, 대화/토론하기, 쓰기, 멘토링까지 영어 실력 향상에 꼭 필요한 다양한 학습 활동을 제공합니다. 아이의 영어 실력에 가장 적합한 학습활동을 중심으로 최적화된 수업을 해보세요.`,
    ``,
    't_landing_home_kr.desc2',
    ``,
    ``,
    ``,
    ``,
  ],
  tab1: {
    btn: [`초6 이하`, ``, 't_landing_home_kr.tab1.btn', ``, ``, ``, ``],
    title1: [`함께 읽기 - 발음·단어 익히기`, ``, 't_landing_home_kr.tab1.title1', ``, ``, ``, ``],
    desc_1: [
      `아이가 좋아하는 영어책을 가져와 튜터와 함께 읽으며 영어 발음과 읽기에 익숙해집니다.`,
      ``,
      't_landing_home_kr.tab1.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title2: [`대화하기 - 원어민과 대화 익숙해지기`, ``, 't_landing_home_kr.tab1.title2', ``, ``, ``, ``],
    desc_2: [
      `자녀의 영어 실력에 맞는 대화하기 교재를 통해 원어민과의 대화에 익숙해 집니다.`,
      ``,
      't_landing_home_kr.tab1.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  tab2: {
    btn: [`초6 - 중2`, ``, 't_landing_home_kr.tab2.btn', ``, ``, ``, ``],
    title1: [`함께 읽기 - 문장 구성력과 이해력 높이기`, ``, 't_landing_home_kr.tab2.title1', ``, ``, ``, ``],
    desc_1: [
      `아이가 좋아하는 원서를 가져와 읽으며 영어 문장에 대한 구성과 이해력을 향상시킵니다.`,
      ``,
      't_landing_home_kr.tab2.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title2: [`토론하기 - 자신의 생각 정리하기`, ``, 't_landing_home_kr.tab2.title2', ``, ``, ``, ``],
    desc_2: [
      `직접 고른 토론하기 교재를 통해 자신의 생각을 정리하고 튜터에게 전달하는 연습을 합니다.`,
      ``,
      't_landing_home_kr.tab2.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    title3: [`쓰기 - 일기·가벼운 에세이 쓰기`, ``, 't_landing_home_kr.tab2.title3', ``, ``, ``, ``],
    desc_3: [
      `일기, 가벼운 에세이를 쓰고, 튜터에게 첨삭 받거나 "함께 글쓰기” 수업을 통해 한 편의 글을 같이 완성합니다.`,
      ``,
      't_landing_home_kr.tab2.desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  tab3: {
    btn: [`중2 +`, ``, 't_landing_home_kr.tab3.btn', ``, ``, ``, ``],
    title1: [`함께 읽기 -  원어민 수준의 어휘력 익히기`, ``, 't_landing_home_kr.tab3.title1', ``, ``, ``, ``],
    desc_1: [
      `관심있는 신문 기사 혹은 원서를 가져와 튜터와 함께 읽으며 원어민 수준의 어휘와 표현을 배웁니다.`,
      ``,
      't_landing_home_kr.tab3.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title2: [`토론하기 - 논리적으로 설득하기`, ``, 't_landing_home_kr.tab3.title2', ``, ``, ``, ``],
    desc_2: [
      `직접 정한 토론하기 교재를 통해 자신의 생각을 논리적으로 전달하고 상대방을 설득하는 법을 배웁니다.`,
      ``,
      't_landing_home_kr.tab3.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    title3: [`쓰기 - 긴 에세이·교재 쓰기`, ``, 't_landing_home_kr.tab3.title3', ``, ``, ``, ``],
    desc_3: [
      `긴 에세이를 직접 쓰고, 첨삭 받거나 "함께 글쓰기” 수업을 통해 튜터와 한편의 교재를 완성합니다.`,
      ``,
      't_landing_home_kr.tab3.desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    title4: [`멘토링 - 자신의 미래 설계하기`, ``, 't_landing_home_kr.tab3.title4', ``, ``, ``, ``],
    desc_4: [
      `다양한 튜터의 대입 노하우, 인턴 경험들을 듣고 자신의 미래를 구체화합니다.`,
      ``,
      't_landing_home_kr.tab3.desc_4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  fit: {
    default: [`어떤 아이에게 적합한가요?`, ``, 't_landing_home_kr.fit.default', ``, ``, ``, ``],
    desc1: [
      `영어 유치원, 학습지 등을 통해 영어에 익숙한 초등학생에게 필요한 수업 구성입니다. (Lexile 400 - 800 수준)`,
      ``,
      't_landing_home_kr.fit.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2: [
      `다양한 영어 학습을 해 온 초등학교 고학년, 중학생 아이들에게 필요한 수업 구성입니다.  (Lexile 600 - 1,000 수준)`,
      ``,
      't_landing_home_kr.fit.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      `난이도가 있는 영어 학습을 경험한 중학교 이상 아이들에게 적합한 수업 구성입니다. (Lexile 900+ 수준)`,
      ``,
      't_landing_home_kr.fit.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc1_pc: [
      <>
        영어 유치원, 학습지 등을 통해 영어에 익숙한 초등학생에게 필요한 수업 구성입니다.
        <br />
        (Lexile 400 - 800 수준)
      </>,
      ``,
      't_landing_home_kr.fit.desc1_pc',
      ``,
      ``,
      ``,
      ``,
    ],
    desc2_pc: [
      <>
        다양한 영어 학습을 해 온 초등학교 고학년, 중학생 아이들에게 필요한 수업 구성입니다.
        <br />
        (Lexile 600 - 1,000 수준)
      </>,
      ``,
      't_landing_home_kr.fit.desc2_pc',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3_pc: [
      <>
        난이도가 있는 영어 학습을 경험한 중학교 이상 아이들에게 적합한 수업 구성입니다.
        <br />
        (Lexile 900+ 수준)
      </>,
      ``,
      't_landing_home_kr.fit.desc3_pc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  subtitle3: [`어떤 교재로 수업하게 되나요?`, ``, 't_landing_home_kr.subtitle3', ``, ``, ``, ``],
  title3: [
    <>
      명문대 출신 에디터들이
      <br />
      만드는 1,500개 이상의 영어 교재
    </>,
    ``,
    't_landing_home_kr.title3',
    ``,
    ``,
    ``,
    ``,
  ],
  desc3: [
    `링글 틴즈팀이 매달 새롭게 발행하는 교재들은 대화부터 멘토링까지, 학습 방법에 맞춰 내용이 구성되어 있으며 각 연령별 아이들이 가장 흥미를 느낄만한 주제를 선정하여 쓰여집니다.`,
    ``,
    't_landing_home_kr.desc3',
    ``,
    ``,
    ``,
    ``,
  ],
  tab4: [`대화`, ``, 't_landing_home_kr.tab4', ``, ``, ``, ``],
  tab5: [`토론`, ``, 't_landing_home_kr.tab5', ``, ``, ``, ``],
  tab6: [`읽기/쓰기`, ``, 't_landing_home_kr.tab6', ``, ``, ``, ``],
  tab7: [`멘토링`, ``, 't_landing_home_kr.tab7', ``, ``, ``, ``],
  subtitle4: [`무엇을 더 얻을 수 있나요?`, ``, 't_landing_home_kr.subtitle4', ``, ``, ``, ``],
  title4: [
    <>
      사고력부터 집중력까지
      <br />
      영어 그 이상을 배우는 수업
    </>,
    ``,
    't_landing_home_kr.title4',
    ``,
    ``,
    ``,
    ``,
  ],
  desc4: [
    `사고력, 창의력, 집중력, 커뮤니케이션 능력을 요약하여 4C라고 말합니다. 미국 등의 교육 선진국에서는 4C를 미래 인재의 핵심 역량으로 정의하고 교육 방법에 적용하고 있습니다. 링글 틴즈는 학생들이 영어를 넘어 4C를 기를 수 있도록 수업을 이끌고 이에 맞춰 피드백을 주고 있습니다.`,
    ``,
    't_landing_home_kr.desc4',
    ``,
    ``,
    ``,
    ``,
  ],
  four_c: {
    title1: [`Critical Thinking`, ``, 't_landing_home_kr.four_c.title1', ``, ``, ``, ``],
    subtitle1: [`(사고력)`, ``, 't_landing_home_kr.four_c.subtitle1', ``, ``, ``, ``],
    desc1: [
      `교재를 통해서 자신의 생각을 논리적으로 발전시키고 이에 대한 심도 있는 토론이 가능하도록 연습합니다.`,
      ``,
      't_landing_home_kr.four_c.desc1',
      ``,
      ``,
      ``,
      ``,
    ],
    title2: [`Creative Thinking`, ``, 't_landing_home_kr.four_c.title2', ``, ``, ``, ``],
    subtitle2: [`(창의력)`, ``, 't_landing_home_kr.four_c.subtitle2', ``, ``, ``, ``],
    desc2: [
      `다양한 배경의 튜터들과 생각을 나누며 넓은 세상에 대한 이해를 돕고, 폭넓게 사고하는 것을 연습합니다.`,
      ``,
      't_landing_home_kr.four_c.desc2',
      ``,
      ``,
      ``,
      ``,
    ],
    title3: [`Concentration`, ``, 't_landing_home_kr.four_c.title3', ``, ``, ``, ``],
    subtitle3: [`(집중력)`, ``, 't_landing_home_kr.four_c.subtitle3', ``, ``, ``, ``],
    desc3: [
      `튜터와 1:1로 대면하여 대화하고 적극적으로 수업에 참여하면서 집중력을 기를 수 있습니다.`,
      ``,
      't_landing_home_kr.four_c.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
    title4: [`Communication`, ``, 't_landing_home_kr.four_c.title4', ``, ``, ``, ``],
    subtitle4: [`(커뮤니케이션 능력)`, ``, 't_landing_home_kr.four_c.subtitle4', ``, ``, ``, ``],
    desc4: [
      `말하고, 쓰는 것을 반복하고, 튜터의 실시간 피드백을 통해 한 단계 높은 커뮤니케이션 방식을 연습합니다.`,
      ``,
      't_landing_home_kr.four_c.desc4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  subtitle5: [`링글 틴즈 수업은 어떻게 진행되나요?`, ``, 't_landing_home_kr.subtitle5', ``, ``, ``, ``],
  title5: [
    <>
      예습부터 복습까지.
      <br />
      체계적인 4단계 학습 시스템
    </>,
    ``,
    't_landing_home_kr.title5',
    ``,
    ``,
    ``,
    ``,
  ],
  desc5: [
    `링글 틴즈의 모든 수업은 4단계를 거쳐 진행됩니다. 이를 통해 원어민과의 수업 시간 전후로 밀도 있는 영어 학습이 가능합니다.`,
    ``,
    't_landing_home_kr.desc5',
    ``,
    ``,
    ``,
    ``,
  ],
  tab8: {
    btn: [`예습`, ``, 't_landing_home_kr.tab8.btn', ``, ``, ``, ``],
    title1: [`교재 혹은 함께 읽을 책 미리 읽기`, ``, 't_landing_home_kr.tab8.title1', ``, ``, ``, ``],
    title2: [`교재용 오디오 파일 듣기`, ``, 't_landing_home_kr.tab8.title2', ``, ``, ``, ``],
    title3: [`교재용 비디오 시청하기`, ``, 't_landing_home_kr.tab8.title3', ``, ``, ``, ``],
    title4: [`수업 중 답변할 3가지 질문 선택하기`, ``, 't_landing_home_kr.tab8.title4', ``, ``, ``, ``],
  },
  tab9: {
    btn: [`수업`, ``, 't_landing_home_kr.tab9.btn', ``, ``, ``, ``],
    title1: [`수업 예약시 20분 혹은 40분 수업 중 선택`, ``, 't_landing_home_kr.tab9.title1', ``, ``, ``, ``],
    title2: [`튜터와 교재에 대하여 대화/토론하기`, ``, 't_landing_home_kr.tab9.title2', ``, ``, ``, ``],
    title3: [`실시간으로 튜터에게 피드백 받기`, ``, 't_landing_home_kr.tab9.title3', ``, ``, ``, ``],
    title4: [`튜터에게 질문하며 궁금한 점 해소하기`, ``, 't_landing_home_kr.tab9.title4', ``, ``, ``, ``],
  },
  tab10: {
    btn: [`숙제`, ``, 't_landing_home_kr.tab10.btn', ``, ``, ``, ``],
    title1: [`오늘 수업과 관련한 영어 숙제 하기`, ``, 't_landing_home_kr.tab10.title1', ``, ``, ``, ``],
    title2: [`튜터의 숙제 피드백 확인 하기`, ``, 't_landing_home_kr.tab10.title2', ``, ``, ``, ``],
  },
  tab11: {
    btn: [`복습`, ``, 't_landing_home_kr.tab11.btn', ``, ``, ``, ``],
    title1: [`수업 녹음 파일 듣고 수업 복습하기`, ``, 't_landing_home_kr.tab11.title1', ``, ``, ``, ``],
    title2: [`단어 사용 분석, 자주 쓰는 표현 확인하기`, ``, 't_landing_home_kr.tab11.title2', ``, ``, ``, ``],
    title3: [`튜터 피드백 리포트, 수업 노트 확인하기`, ``, 't_landing_home_kr.tab11.title3', ``, ``, ``, ``],
  },
  subtitle6: [`수업 가격은 얼마인가요?`, ``, 't_landing_home_kr.subtitle6', ``, ``, ``, ``],
  title6: [
    <>
      원어민과의 1:1 영어 학습을
      <br />
      합리적인 가격에 진행하세요.
    </>,
    ``,
    't_landing_home_kr.title6',
    ``,
    ``,
    ``,
    ``,
  ],
  title7: [
    <>
      아직 망설여지나요?
      <br />
      하버드·MIT 출신의
      <br />
      틴즈팀이 직접 만나러 갑니다.
    </>,
    ``,
    't_landing_home_kr.title7',
    ``,
    ``,
    ``,
    ``,
  ],
  desc6: [
    <>
      아이 상황별로 어떻게 틴즈를 활용하면 되는지,
      <br />
      어떻게 영어를 공부해야 진로에 도움이 되는지.
      <br />
      궁금하신가요?
    </>,
    ``,
    't_landing_home_kr.desc6',
    ``,
    ``,
    ``,
    ``,
  ],
  desc7: [`링글 틴즈 팀과 이야기 나눠보세요.`, ``, 't_landing_home_kr.desc7', ``, ``, ``, ``],
  btn10: [`카카오 채팅으로 상담하기`, ``, 't_landing_home_kr.btn10', ``, ``, ``, ``],
  text_btn2: [`카카오가 없다면?`, ``, 't_landing_home_kr.text_btn2', ``, ``, ``, ``],
  title8: [
    <>
      앱 다운 받고
      <br />
      무료 체험 신청하기
    </>,
    ``,
    't_landing_home_kr.title8',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle7: [
    <>
      링글 틴즈 앱에서
      <br />
      교재부터 튜터까지 확인하고
      <br />
      무료 체험까지 놓치지 마세요!
    </>,
    ``,
    't_landing_home_kr.subtitle7',
    ``,
    ``,
    ``,
    ``,
  ],
  btn11: [`링글 틴즈 앱 다운 받기`, ``, 't_landing_home_kr.btn11', ``, ``, ``, ``],
  faq: {
    title: [`자주 묻는 질문`, ``, 't_landing_home_kr.faq.title', ``, ``, ``, ``],
  },
  faq1: {
    default: [`링글 틴즈는 몇 학년 학생에게 적합한가요?`, ``, 't_landing_home_kr.faq1.default', ``, ``, ``, ``],
    desc: [
      `초등학생 부터 고등학생까지 대상으로 하고 있지만, 더 어리거나 나이가 많아도 본인에게 필요한 학습 활동에 맞추어 링글 틴즈의 수업을 들으실 수 있습니다.`,
      ``,
      't_landing_home_kr.faq1.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq2: {
    default: [`제 아이에게 맞을지 상담하고 시작할 수 있나요?`, ``, 't_landing_home_kr.faq2.default', ``, ``, ``, ``],
    desc: [
      `물론입니다. 링글 틴즈는 상담을 진행한 경우 학부모님과 아이의 만족도가 더 높은 수업 진행이 가능합니다. 카카오 채팅 혹은 이메일을 통해 편한 마음으로 1:1 상담을 신청해주세요.`,
      ``,
      't_landing_home_kr.faq2.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq3: {
    default: [`수업 가격은 얼마인가요?`, ``, 't_landing_home_kr.faq3.default', ``, ``, ``, ``],
    desc: [
      `링글 틴즈 수업권은 패키지로 판매 되며, 16만 4천원부터 시작합니다. 다양한 상황과  학생의 편의에 맞게 요금제를 선택하실 수 있습니다. 자세한 내용은 링글 틴즈에 문의주세요!`,
      ``,
      't_landing_home_kr.faq3.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq4: {
    default: [`결제하기 전에 무료 체험 수업이 가능한가요?`, ``, 't_landing_home_kr.faq4.default', ``, ``, ``, ``],
    desc: [
      `네, 무료 체험 수업이 가능합니다. 회원가입만 하면 지급되는 무료 수업권을 확인해보세요.`,
      ``,
      't_landing_home_kr.faq4.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq5: {
    default: [`환불은 어떻게 진행되나요?`, ``, 't_landing_home_kr.faq5.default', ``, ``, ``, ``],
    desc: [
      <>
        환불을 원하실 경우 상담하기 혹은 이메일을 통해 링글팀에 연락주세요.
        <br />
        <br />
        1. 사용 이력이 없는 경우, 전액환불 가능합니다.
        <br />
        2. 수강기간 또는 수업 횟수가 절반 이하 남은 수업권은 환불이 불가합니다.
        <br />
        3. 환불금액 산정 시, 결제 수단에 대한 수수료가 차감될 수 있습니다.
      </>,
      ``,
      't_landing_home_kr.faq5.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq6: {
    default: [`링글 틴즈 튜터는 어떻게 선발하나요?`, ``, 't_landing_home_kr.faq6.default', ``, ``, ``, ``],
    desc: [
      <>
        링글 틴즈 튜터는 1,000명 이상의 튜터를 운영하고 있는 링글 플러스 튜터 팀의 노하우를 받아 다음의 프로세스를 거쳐
        선발 및 관리 됩니다.
        <br />
        <br />
        1. <b>이력 검증</b> : 아이비리그 포함 영∙미 Top30 대학교 재/졸업 증명서, 원어민 여부 등을 서류로 검증합니다.
        <br />
        2. <b>인터뷰</b> : 링글 팀이 실제 수업과 동일하게 수업을 진행하여 튜터 합격 여부 결정합니다.
        <br />
        3. <b>튜터 트레이닝</b> : 수업 운영, 피드백 등 세부적인 수업 트레이닝을 진행합니다.
        <br />
        4. <b>튜터 관리</b> : 지속적인 고객 리뷰 모니터링를 통해 컴플레인 삼진아웃제를 적용하고 튜터들의 주기적인
        재교육을 진행합니다.
      </>,
      ``,
      't_landing_home_kr.faq6.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq7: {
    default: [`수업을 위해 어떤 준비가 필요한가요?`, ``, 't_landing_home_kr.faq7.default', ``, ``, ``, ``],
    desc: [
      <>
        1. Windows 혹은 Mac OS가 실행되며 마이크, 스피커, 웹캠이 탑재된 컴퓨터/노트북을 준비해주세요.
        <br />
        2. Chrome browser 와 Zoom Client를 설치해주세요.
        <br />
        3. 인터넷 연결을 확인해주세요.
      </>,
      ``,
      't_landing_home_kr.faq7.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq8: {
    default: [
      `효과적인 수업을 위해서 일주일 중 몇 시간을 써야 하나요?`,
      ``,
      't_landing_home_kr.faq8.default',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      <>
        학생의 스케줄에 따라 일주일에도 여러번 수업을 진행하실 수 있습니다. 각 수업은 예습/수업/숙제/복습으로 구성되며,
        한 수업에 총 2-3시간 정도가 소요됩니다.
        <br />
        <br />
        1. <b>예습</b> : 교재 읽기 / 오디오 듣기 / 영상 시청/ 세가지 질문 선택하기
        <br />
        2. <b>수업</b> : 20분 혹은 40분 수업 진행하기
        <br />
        3. <b>숙제</b> : 수업 관련 숙제 완성 후 제출하기.
        <br />
        4. <b>복습</b> : 수업 녹화본, 수업 분석, 튜터의 피드백 복습하기
      </>,
      ``,
      't_landing_home_kr.faq8.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  faq9: {
    default: [`4C가 무엇인가요?`, ``, 't_landing_home_kr.faq9.default', ``, ``, ``, ``],
    desc: [
      `4Cs는 각각 Communication 의사소통 능력, Critical Thinking 비판적 사고력, Creative Thinking 창의적 사고 능력, Concentration Skill 집중력을 의미합니다. 현재 미국을 포함한 교육 선진국에서 꼽는 미래 인재의 핵심 역량입니다. 링글 틴즈는 튜터들이 4Cs을 기반으로 수업을 진행하고 피드백을 주도록 트레이닝하고 있습니다.`,
      ``,
      't_landing_home_kr.faq9.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  credit: {
    normal: [`회당`, ``, 't_landing_home_kr.credit.normal', ``, ``, ``, ``],
    discount: [`할인가`, ``, 't_landing_home_kr.credit.discount', ``, ``, ``, ``],
    won: [`원`, ``, 't_landing_home_kr.credit.won', ``, ``, ``, ``],
    info_min: [`1회 수업시간`, ``, 't_landing_home_kr.credit.info_min', ``, ``, ``, ``],
    info_count: [`수업 횟수`, ``, 't_landing_home_kr.credit.info_count', ``, ``, ``, ``],
    info_day: [`수강 기간`, ``, 't_landing_home_kr.credit.info_day', ``, ``, ``, ``],
    desc1: [`수업권은 수강기간 내 자유롭게 이용할 수 있습니다.`, ``, 't_landing_home_kr.credit.desc1', ``, ``, ``, ``],
    desc2: [`수업권을 여러번 결제해도 매번 할인이 적용됩니다.`, ``, 't_landing_home_kr.credit.desc2', ``, ``, ``, ``],
  },
  btn12: [`링글 틴즈 시작하기`, ``, 't_landing_home_kr.btn12', ``, ``, ``, ``],
  title6_pc: [
    `원어민과의 1:1 영어 학습을 합리적인 가격에 진행하세요.`,
    ``,
    't_landing_home_kr.title6_pc',
    ``,
    ``,
    ``,
    ``,
  ],
  title9: [`링글 틴즈 가입하고 무료 체험 신청하기`, ``, 't_landing_home_kr.title9', ``, ``, ``, ``],
  desc8: [
    <>
      지금 링글 틴즈에서 교재부터 튜터까지 확인하고
      <br />
      무료 체험까지 놓치지 마세요!
    </>,
    ``,
    't_landing_home_kr.desc8',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_content = {
  mobile_web: {
    access_on_app: [
      `링글 틴즈 앱을 다운 받고, 무료 체험을 시작해보세요.`,
      `Download Ringle Teens App to book your lessons NOW!`,
      't_content.mobile_web.access_on_app',
      ``,
      ``,
      ``,
      ``,
    ],
    view_in_app: [`앱으로 보기`, `Open app`, 't_content.mobile_web.view_in_app', ``, ``, ``, ``],
  },
}
export const s_purcahse = {
  note_2card: [`2개 카드로 분할 결제시 일시불 결제만 가능`, ``, 's_purcahse.note_2card', ``, ``, ``, ``],
}
export const s_coupon_box = {
  subtitle: [`각종 혜택을 누릴 수 있는 쿠폰을 확인하세요`, ``, 's_coupon_box.subtitle', ``, ``, ``, ``],
  coupon_count: (count) => [
    <>
      쿠폰 <span className="text-primary">{count}개</span>
    </>,
    <>
      You have <span className="text-primary">{count} coupons</span>
    </>,
    's_coupon_box.coupon_count',
    ``,
    ``,
    ``,
    ``,
  ],
  redeem: [`사용하기`, `Redeem`, 's_coupon_box.redeem', ``, ``, ``, ``],
  used: [`사용완료`, `Used`, 's_coupon_box.used', ``, ``, ``, ``],
  bonus_coupon_type_1_title: [
    `3회 수업권 45% 할인 쿠폰`,
    `45% off 3 lessons credits`,
    's_coupon_box.bonus_coupon_type_1_title',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_1: [
    <>
      <ul>
        <li>본 쿠폰은 2021년 1월 28일까지 사용 가능합니다.</li>
        <li>
          본 쿠폰은 <b>3회 수업권</b> 구매 시 첫 구매 가격으로 할인해드리는 쿠폰입니다. (정가 대비 45% 할인)
        </li>
        <li>본 쿠폰은 20분, 40분 수업 모두 사용 가능합니다.</li>
        <li>
          본 쿠폰을 적용하여 20분 또는 40분 <b>3회 수업권</b> 구매 시, 보유하고 있는 포인트와 중복 사용 가능합니다.
        </li>
        <li>쿠폰 관련 문의는 링글 홈페이지 내 1:1 문의 또는 카카오톡채널 @Ringle로 문의 바랍니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is valid until January 28th, 2021.</li>
        <li>
          Redeeming this coupon applies a 45% discount from the regular price for the <b>First 3 Lessons package</b>.
        </li>
        <li>Coupon is redeemable for both 20-minute and 40-minute lesson credits.</li>
        <li>You can pay with your points.</li>
        <li>
          If you have further questions, please contact us via chat below or through @Ringle on Kakao Talk channel.
        </li>
      </ul>
    </>,
    's_coupon_box.bonus_coupon_type_1',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_2_title: (amount) => [
    `재등록 할인 쿠폰 (${currencyFormat(amount)}원)`,
    `Returning Customer Coupon (${financial(amount / DOLLARS_TO_KRW)} off)`,
    's_coupon_box.bonus_coupon_type_2_title',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_2: (amount) => [
    <>
      <ul>
        <li>본 쿠폰은 2021년 1월 28일까지 사용 가능합니다.</li>
        {amount === 40000 ? (
          <li>
            본 쿠폰은 20분, 40분 <b>장기권 수업권</b> 구매 시 사용 가능한 쿠폰입니다.
          </li>
        ) : (
          <li>
            본 쿠폰은 수업기간 <b>1년 미만</b> 20분, 40분 수업권 구매 시 사용 가능한 쿠폰입니다.
          </li>
        )}
        <li>본 쿠폰을 적용하여 신년 프로모션 패키지 구매 시, 보유하고 있는 포인트와 중복 사용 가능합니다.</li>
        <li>쿠폰 관련 문의는 링글 홈페이지 내 1:1 문의 또는 카카오톡채널 @Ringle로 문의 바랍니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until January 28th, 2021.</li>
        <li>Coupon is redeemable for both 20-minute and 40-minute lesson credits.</li>
        <li>You can pay with your points.</li>
        <li>If you have further questions, please contact us via chat below or @Ringle on Kakao Talk.</li>
      </ul>
    </>,
    's_coupon_box.bonus_coupon_type_2',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_3_title: (amount) => [
    `신년 특가 추가 할인 쿠폰 (${currencyFormat(amount)}원)`,
    `New Year’s Resolution Coupon (${financial(amount / DOLLARS_TO_KRW)} off)`,
    's_coupon_box.bonus_coupon_type_3_title',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_3: (amount) => [
    <>
      <ul>
        <li>본 쿠폰은 2021년 1월 28일까지 사용 가능합니다.</li>
        {amount === 40000 ? (
          <li>
            본 쿠폰은 20분, 40분 <b>장기권 수업권</b> 구매 시 사용 가능한 쿠폰입니다.
          </li>
        ) : (
          <li>
            본 쿠폰은 수업기간 <b>1년 미만</b> 20분, 40분 수업권 구매 시 사용 가능한 쿠폰입니다.
          </li>
        )}
        <li>본 쿠폰을 적용하여 신년 프로모션 패키지 구매 시, 보유하고 있는 포인트와 중복 사용 가능합니다.</li>
        <li>쿠폰 관련 문의는 링글 홈페이지 내 1:1 문의 또는 카카오톡채널 @Ringle로 문의 바랍니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until January 28th, 2021.</li>
        <li>Coupon is redeemable for both 20-minute and 40-minute lesson credits.</li>
        <li>You can pay with your points.</li>
        <li>If you have further questions, please contact us via chat below or @Ringle on Kakao Talk.</li>
      </ul>
    </>,
    's_coupon_box.bonus_coupon_type_3',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_4_title: [
    `재구매 쿠폰`,
    `Next Purchase Coupon`,
    's_coupon_box.bonus_coupon_type_4_title',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_4: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number}회 수업권 구매 시 적용 가능한 쿠폰입니다.
          <br />
          • 쿠폰은 발급일로부터 60일 이내 사용 가능합니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 35 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.bonus_coupon_type_4',
    ``,
    ``,
    ``,
    ``,
  ],
  use_confirm: [`사용하시겠습니까?`, `Submit?`, 's_coupon_box.use_confirm', ``, ``, ``, ``],
  expired: [`기간만료`, `Expired`, 's_coupon_box.expired', ``, ``, ``, ``],
  bonus_coupon_type_5_title: [
    `재구매 할인쿠폰`,
    `Next Purchase Coupon`,
    's_coupon_box.bonus_coupon_type_5_title',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_5: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number}회 수업권 구매 시 적용 가능한 쿠폰입니다.
          <br />
          • 쿠폰 발급 후 7일 이내에 사용 가능합니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {number} lesson credits ({duration}-min)
          <br />
          • Expires in 7 days after issuance
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.bonus_coupon_type_5',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_11_title: [`웰컴쿠폰`, `Welcome Coupon`, 's_coupon_box.bonus_coupon_type_11_title', ``, ``, ``, ``],
  bonus_coupon_type_11: [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 쿠폰 발급 후 7일 이내 사용 가능합니다
          <br />
          • 타 쿠폰과 중복하여 사용할 수 없습니다.
          <br />
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Valid for 7 days after issuance
          <br />
          • Cannot be combined with any other coupons
          <br />
        </Div>
      </Div>
    </>,
    's_coupon_box.bonus_coupon_type_11',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_12_title: (duration, number) => [
    <>
      시크릿 할인 쿠폰 ({duration}분/{number}회)
    </>,
    <>
      Secret discount coupon ({duration}min/{number}credits)
    </>,
    's_coupon_box.bonus_coupon_type_12_title',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_12: (duration, number) => [
    <>
      <ul>
        <li>본 쿠폰은 2022년 6월 21일까지 사용 가능합니다.</li>
        <li>
          본 쿠폰은{' '}
          <b>
            {duration}분/{number}회 정규 수업권
          </b>{' '}
          구매 시 적용 가능합니다.{' '}
        </li>
        <li>타 쿠폰과 중복하여 사용할 수 없습니다. </li>
        <li>쿠폰 관련 문의는 링글 홈페이지 내 1:1 문의 또는 카카오톡채널 @Ringle로 문의 바랍니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until June 21st, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    's_coupon_box.bonus_coupon_type_12',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_15_title: (duration, number) => [
    <>
      블랙프라이데이 쿠폰 ({duration}분/{number}회)
    </>,
    <>
      Black Friday Coupon ({duration}min/{number}credits)
    </>,
    's_coupon_box.bonus_coupon_type_15_title',
    ``,
    ``,
    ``,
    ``,
  ],
  bonus_coupon_type_15: (duration, number) => [
    <>
      <ul>
        <li>
          {duration}분 {number}회 수업권 구매 시 적용 가능합니다.{' '}
        </li>
        <li>2022년 12월 2일 오후 14:00(KST)에 만료됩니다.</li>
        <li>타 쿠폰과 중복하여 사용할 수 없습니다. </li>
        <li>쿠폰 관련 문의는 링글 홈페이지 내 1:1 문의 또는 카카오톡채널 @Ringle로 문의 바랍니다.</li>
      </ul>
    </>,
    <>
      <ul>
        <li>This coupon is redeemable until December 2nd, 2022.</li>
        <li>
          Coupon is redeemable for{' '}
          <b>
            {duration}-minute / {number} lesson credits{' '}
          </b>{' '}
        </li>
        <li>This coupon cannot be used with any other coupons.</li>
        <li>If you have any inquiries regarding coupons, please contact us through Help Chat.</li>
      </ul>
    </>,
    's_coupon_box.bonus_coupon_type_15',
    ``,
    ``,
    ``,
    ``,
  ],
  title_secret: [`시크릿 쿠폰`, `Secret Coupon`, 's_coupon_box.title_secret', ``, ``, ``, ``],
  title_welcome: [`웰컴 쿠폰`, `Welcome Coupon`, 's_coupon_box.title_welcome', ``, ``, ``, ``],
  title_next_1: [`재구매 쿠폰`, `Next Purchase Coupon`, 's_coupon_box.title_next_1', ``, ``, ``, ``],
  title_next_2: [`재구매 쿠폰`, `Next Purchase Coupon`, 's_coupon_box.title_next_2', ``, ``, ``, ``],
  title2: (duration, number) => [
    <>
      {duration}분 {number}회 수업권 구매 시 적용 가능
    </>,
    <>
      Redeemable for {duration}-min {number} credits
    </>,
    's_coupon_box.title2',
    ``,
    ``,
    ``,
    ``,
  ],
  expiration: (date) => [<>{date}까지</>, <>Valid till {date}</>, 's_coupon_box.expiration', ``, ``, ``, ``],
  discount: (amount) => [<>{amount} 할인</>, <>${amount} off</>, 's_coupon_box.discount', ``, ``, ``, ``],
  desc_secret: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number}회 수업권 구매 시 적용 가능합니다.
          <br />
          • 2022년 12월 22일 저녁 23시 59분에 만료됩니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Expires on Dec 22nd, 2022 at 23:59PM
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_secret',
    ``,
    ``,
    ``,
    ``,
  ],
  desc_next_1: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number} 수업권 구매 시 적용 가능합니다.
          <br />
          • 쿠폰 발급 후 35일 이내에 사용 가능합니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 35 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_next_1',
    ``,
    ``,
    ``,
    ``,
  ],
  desc_next_2: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number} 수업권 구매 시 적용 가능합니다.
          <br />
          • 쿠폰 발급 후 7일 이내에 사용 가능합니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_next_2',
    ``,
    ``,
    ``,
    ``,
  ],
  desc_welcome: (duration, number) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {number} 수업권 구매 시 적용 가능합니다.
          <br />
          • 쿠폰 발급 후 7일 이내에 사용 가능합니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeemable for {duration}-min {number} credits.
          <br />
          • Valid for 7 days after issuance.
          <br />• Cannot be combined with other coupons
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_welcome',
    ``,
    ``,
    ``,
    ``,
  ],
  title_new: [`신규가입 쿠폰`, `Welcome Coupon`, 's_coupon_box.title_new', ``, ``, ``, ``],
  title2_new: (min, credits) => [
    <>
      {min}분 {credits}회 수업권 구매 시 적용 가능
    </>,
    <>
      Redeemable for {min}-min {credits} credits
    </>,
    's_coupon_box.title2_new',
    ``,
    ``,
    ``,
    ``,
  ],
  desc_new: (duration, credits, year, month, day, hour, min) => [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • {duration}분 {credits}회 수업권 구매 시 자동 적용됩니다.
          <br />• {year}년 {month}월 {day}일 {hour}:{min}에 만료됩니다.
          <br />• 타 쿠폰과 중복하여 사용할 수 없습니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Redeems automatically when purchasing {duration}-min {credits} credits.
          <br />• Expires on {month} {day}, {year} at {hour}:{min}
          <br />• Cannot be combined with other coupons.
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_new',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit_new: (min, credits) => [
    <>
      {min}분 수업권 {credits}회 증정
    </>,
    <>
      Free {min}-min {credits} credits
    </>,
    's_coupon_box.benefit_new',
    ``,
    ``,
    ``,
    ``,
  ],
  title_extend: [`수강기간 연장 쿠폰`, `Extension Coupon`, 's_coupon_box.title_extend', ``, ``, ``, ``],
  title2_extend: [
    `보유한 수업권의 수강기간 연장 가능`,
    `For extending lesson days`,
    's_coupon_box.title2_extend',
    ``,
    ``,
    ``,
    ``,
  ],
  desc_extend: [
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • 수강기간이 남아 있는 수업권에만 적용 가능합니다. (만료된 수업권에 사용할 수 없습니다.)
          <br />
          • 쿠폰은 발급일로부터 60일 이내 사용 가능합니다.
          <br />• 쿠폰 사용 후, 수업권 환불 시 수강기간 연장에 대한 비용 차감 후 환불됩니다.
        </Div>
      </Div>
    </>,
    <>
      <Div ulTag listDisc>
        <Div liTag>
          • Can be used to extend the lesson days of a chosen credit.
          <br />
          • Expires in 60 days after issuance
          <br />• The regular price for extending lesson days will be deducted if you choose to refund this credit in
          the future after using the extension coupon.
        </Div>
      </Div>
    </>,
    's_coupon_box.desc_extend',
    ``,
    ``,
    ``,
    ``,
  ],
  time: (hh, mm) => [
    <>
      {hh}시간 {mm}분 남음
    </>,
    <>
      {hh}:{mm} left
    </>,
    's_coupon_box.time',
    ``,
    ``,
    ``,
    ``,
  ],
  use_new: [`신규가입 쿠폰 사용`, `Redeem Welcome Coupon`, 's_coupon_box.use_new', ``, ``, ``, ``],
  btn_new: [`구매페이지 이동`, `Go to purchase page`, 's_coupon_box.btn_new', ``, ``, ``, ``],
  use_extend: [`수강기간 연장 쿠폰 사용`, `Redeem Extension Coupon`, 's_coupon_box.use_extend', ``, ``, ``, ``],
  notice1_extend: [
    <>
      수강기간을 연장할 수업권을 선택해 주세요.
      <br />
      유의사항 모두 체크 후 [수강기간 연장하기]를 클릭해 주세요.{' '}
    </>,
    <>
      Please select the credit you wish to extend.
      <br />
      Please check all terms then click on [Extend].
    </>,
    's_coupon_box.notice1_extend',
    ``,
    ``,
    ``,
    ``,
  ],
  select_credit: [`수업권을 선택하세요.`, `Please select a credit.`, 's_coupon_box.select_credit', ``, ``, ``, ``],
  terms: [`유의사항`, `Terms`, 's_coupon_box.terms', ``, ``, ``, ``],
  notice2_extend: [
    `수강기간을 연장할 수업권 정보를 확인했습니다.`,
    `I have checked the information on the credit I chose to extend using the coupon.`,
    's_coupon_box.notice2_extend',
    ``,
    ``,
    ``,
    ``,
  ],
  notice3_extend: [
    <>연장 후 해당 수업권 환불 시, 수강기간 연장에 대한 비용 차감 후 환불됩니다.</>,
    `I understand that the regular price for extending lesson days will be deducted if I choose to refund this credit in the future after applying the coupon.`,
    's_coupon_box.notice3_extend',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_extend: [`수강기간 연장`, `Extend`, 's_coupon_box.btn_extend', ``, ``, ``, ``],
  toast_extend: [
    `수강기간이 연장되었습니다.`,
    `Lesson days have been extended.`,
    's_coupon_box.toast_extend',
    ``,
    ``,
    ``,
    ``,
  ],
  left_credit: [`남은 수업권`, `Remaining`, 's_coupon_box.left_credit', ``, ``, ``, ``],
  left_date: (n) => [<>수강기간 {n}일 남음</>, <>{n} days left</>, 's_coupon_box.left_date', ``, ``, ``, ``],
  extend_date: (n) => [<>+{n}일 연장</>, <>+add {n} days</>, 's_coupon_box.extend_date', ``, ``, ``, ``],
  add: (n) => [
    <div className="flex items-center">
      추가 연장 <div className="ml-[4px] text-[#FF3051]">+{n}일</div>
    </div>,
    <div className="flex items-center">
      add <div className="text-[#FF3051] ml-[4px]">+{n} days</div>
    </div>,
    's_coupon_box.add',
    ``,
    ``,
    ``,
    ``,
  ],
  title_next: [`재구매 쿠폰`, `Next Purchase Coupon`, 's_coupon_box.title_next', ``, ``, ``, ``],
  benefit_price: (price) => [<>{price} 할인</>, <>Extra {price} off</>, 's_coupon_box.benefit_price', ``, ``, ``, ``],
  use_next: [`재구매 쿠폰 사용`, `Redeem Next Purchase Coupon`, 's_coupon_box.use_next', ``, ``, ``, ``],
}
export const s_notices = {
  notice: [`공지사항`, `Updates`, 's_notices.notice', ``, ``, ``, ``],
  notice_list: [`공지사항 목록`, `Updates List`, 's_notices.notice_list', ``, ``, ``, ``],
}
export const s_convert_credit = {
  change_credit: [`수업권 변경`, `Exchange Lesson Credits`, 's_convert_credit.change_credit', ``, ``, ``, ``],
  select_the_package_to_change: [
    `변경 할 패키지를 선택해주세요`,
    `Select lesson credits to exchange`,
    's_convert_credit.select_the_package_to_change',
    ``,
    ``,
    ``,
    ``,
  ],
  select_more_than_zero: [
    `수업권 종류 및 0개 이상의 갯수를 선택해주세요`,
    `Please select at least one lesson credit to exchange.`,
    's_convert_credit.select_more_than_zero',
    ``,
    ``,
    ``,
    ``,
  ],
  plz_agree_to_terms: [
    `변경 이용 약관 동의를 해주세요`,
    `Please agree to the exchange terms of service.`,
    's_convert_credit.plz_agree_to_terms',
    ``,
    ``,
    ``,
    ``,
  ],
  the_change_has_been_successful: [
    `변환이 완료되었습니다. 수업권 관리 페이지에서 확인해보세요!`,
    `We have successfully exchanged your lesson credits. You can see the change under My Account.`,
    's_convert_credit.the_change_has_been_successful',
    ``,
    ``,
    ``,
    ``,
  ],
  u_cannot_change_to_the_same_type: [
    `같은 타입으로 변경이 불가능합니다.`,
    `You cannot exchange to the same lesson type.`,
    's_convert_credit.u_cannot_change_to_the_same_type',
    ``,
    ``,
    ``,
    ``,
  ],
  u_cannot_change_to_the_same_target: [
    `같은 시간으로 변경이 불가능합니다.`,
    `You cannot exchange to the same duration.`,
    's_convert_credit.u_cannot_change_to_the_same_target',
    ``,
    ``,
    ``,
    ``,
  ],
  u_cannot_change_to_the_zero_credit: [
    `변경 되는 수업권 수가 0개 입니다. 변경 할 수업권 갯수를 다시 선택해주세요.`,
    `Please select at least one lesson credit to exchange.`,
    's_convert_credit.u_cannot_change_to_the_zero_credit',
    ``,
    ``,
    ``,
    ``,
  ],
  notice: [`수업권 변경 유의사항`, `Terms`, 's_convert_credit.notice', ``, ``, ``, ``],
  strong_1: [
    `1. 변경 할 패키지를 선택해주세요.`,
    `Select a package to exchange`,
    's_convert_credit.strong_1',
    ``,
    ``,
    ``,
    ``,
  ],
  dropdown_1: [`패키지를 선택해주세요`, `Select a package`, 's_convert_credit.dropdown_1', ``, ``, ``, ``],
  strong_2: [
    `2. 변경 할 수업권 개수를 선택해주세요`,
    `Select number of credits`,
    's_convert_credit.strong_2',
    ``,
    ``,
    ``,
    ``,
  ],
  strong_3: [
    `3. 변경 할 수업권 타입을 선택해주세요`,
    `Select credit type`,
    's_convert_credit.strong_3',
    ``,
    ``,
    ``,
    ``,
  ],
  dropdown_3: [`변경할 패키지`, `Package to be converted`, 's_convert_credit.dropdown_3', ``, ``, ``, ``],
  confirm_convert: [
    `변경 수업권 확인`,
    `Check the exchanged credit`,
    's_convert_credit.confirm_convert',
    ``,
    ``,
    ``,
    ``,
  ],
  count: [`개`, `Credits`, 's_convert_credit.count', ``, ``, ``, ``],
  point: [`포인트`, `Points`, 's_convert_credit.point', ``, ``, ``, ``],
  no_revert: [`TO_BE_DELETED`, `TO_BE_DELETED`, 's_convert_credit.no_revert', ``, ``, ``, ``],
  understood: [
    `이를 숙지 및 동의하였고, 변경을 진행하겠습니다.`,
    `I have understood and agree to this, and I would like to exchange my credits.`,
    's_convert_credit.understood',
    ``,
    ``,
    ``,
    ``,
  ],
  convert: [`변경 진행`, `Exchange`, 's_convert_credit.convert', ``, ``, ``, ``],
  list_1: [
    `유료로 구매한 수업권만 수업권 변경 가능합니다. (예외: 기업맞춤 수업권, 시간한정 수업권 변경 불가)`,
    `Exchanges between lesson credits is only available for paid credits. (but not for B2B partner credits and limited window credits.)`,
    's_convert_credit.list_1',
    ``,
    ``,
    ``,
    ``,
  ],
  list_2: [
    <Span danger>수업권 변경은 패키지 당 1회만 가능하며, 변경한 수업권은 원상복구 불가합니다.</Span>,
    <Span danger>Credit exchange is available only once per package and cannot be reversed.</Span>,
    's_convert_credit.list_2',
    ``,
    ``,
    ``,
    ``,
  ],
  list_3: [
    <Span danger>변경된 수업권은 환불 불가합니다.</Span>,
    <Span danger>No refund is available for exchanged credits.</Span>,
    's_convert_credit.list_3',
    ``,
    ``,
    ``,
    ``,
  ],
  list_4: [
    <Span danger>수업권 변경 이력이 있는 수업권은 증명서 발급이 불가합니다.</Span>,
    <Span danger>Certificates cannot be issued for exchanged credits.</Span>,
    's_convert_credit.list_4',
    ``,
    ``,
    ``,
    ``,
  ],
  list_5: [
    `증명서 발급 이력이 있는 수업권은 수업권 변경이 불가합니다.`,
    `Credit exchange is not available for credits for which certificate(s) have been issued in the past.`,
    's_convert_credit.list_5',
    ``,
    ``,
    ``,
    ``,
  ],
  complete_converted: [
    `이관이 완료되었습니다. 수업권/증명서류 페이지에서 확인해보세요`,
    `Your credits have been transferred. You can check it on your "Credits” page!`,
    's_convert_credit.complete_converted',
    ``,
    ``,
    ``,
    ``,
  ],
  list_6: [
    `장기권 혜택으로 제공되는 당일수업권은 수업권 변경 시 일괄 제공됩니다. (수업권 변경 전 수업 길이와 동일한 당일수업권 제공 | 수강기간: 1년)`,
    `Upon credit exchange, all Last-minute Credits included in the credit package's Size Benefit will be issued in full at once, instead of issuing one Last-minute Credit after completing four lessons. (All Last-minute Credits will expire after one year from issuance.)`,
    's_convert_credit.list_6',
    ``,
    ``,
    ``,
    ``,
  ],
  popup: {
    title: [`유의사항`, `Terms`, 's_convert_credit.popup.title', ``, ``, ``, ``],
    content_1: [
      `변경된 수업권은 원상복구 불가합니다.`,
      `Credit exchange is available only once per package and cannot be reversed.`,
      's_convert_credit.popup.content_1',
      ``,
      ``,
      ``,
      ``,
    ],
    content_2: [
      `수업권 변경 후 증명서 발급이 불가합니다.`,
      `Once exchanged, the credit(s) will not be eligible for certificate issuance.`,
      's_convert_credit.popup.content_2',
      ``,
      ``,
      ``,
      ``,
    ],
    content_3: [
      `장기권 혜택으로 제공되는 당일수업권은 수업권 변경 시 일괄 제공됩니다.`,
      `Upon credit exchange, all Last-minute Credits included in the credit package's Size Benefit will be issued in full at once.`,
      's_convert_credit.popup.content_3',
      ``,
      ``,
      ``,
      ``,
    ],
    cancel: [`취소`, `Cancel`, 's_convert_credit.popup.cancel', ``, ``, ``, ``],
    confirm: [`확인`, `Confirm`, 's_convert_credit.popup.confirm', ``, ``, ``, ``],
  },
}
export const s_faq = {
  enter_your_keyword: [
    `검색어를 입력하세요`,
    `Enter keyword(s) to search.`,
    's_faq.enter_your_keyword',
    ``,
    ``,
    ``,
    ``,
  ],
  search: [`검색`, `Search`, 's_faq.search', ``, ``, ``, ``],
  search_result: [`검색 결과`, `Search Results`, 's_faq.search_result', ``, ``, ``, ``],
  frequently_asked_questions: [`자주 묻는 질문`, `FAQ`, 's_faq.frequently_asked_questions', ``, ``, ``, ``],
  how_can_we_help_you: [`무엇을 도와드릴까요?`, `How can we help?`, 's_faq.how_can_we_help_you', ``, ``, ``, ``],
  channel_talk: [`1:1 문의하기`, `Contact Us`, 's_faq.channel_talk', ``, ``, ``, ``],
}
export const s_ip_policy = {
  heading: [
    `RINGLE COPYRIGHT & INTELLECTUAL PROPERTY POLICY`,
    `RINGLE COPYRIGHT & INTELLECTUAL PROPERTY POLICY`,
    's_ip_policy.heading',
    ``,
    ``,
    ``,
    ``,
  ],
  text: [
    `㈜링글잉글리시에듀케이션서비스(이하 "회사” "링글”)은 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 "회원” 권익 보호에 최선을 다하고 있습니다.

"링글"은 "회원”의 개인정보를 보호하고 개인정보와 관련한 "회원"의 고충을 원활하게 처리할 수 있도록 개인정보처리방침을 제정∙시행하며, "링글"이 개인정보처리방침을 개정하는 경우 링글의 홈페이지(ringleplus.com)에 변경 사항을 즉시 게재하거나 "회원” 개별 이메일(ID)로 공지합니다.

제1조(개인정보 수집 항목 및 이용 목적 및 수집방법)
"링글"는 "회원"의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 본 조에서 고지한 범위를 넘어 "회원"의 개인정보를 활용하지 않습니다. 수집한 개인정보 활용의 목적은 아래와 같습니다.

1. 회원 관리: 회원제 서비스 이용에 따른 본인확인, 가입 의사 확인, 개인 식별, 불량 회원의 부정 이용 및 비인가 사용 방지, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 연령확인, 불만처리 등 민원 상담 처리, 고지사항 등 최신 정보 전달
2. 서비스 제공에 관한 계약 이행 및 요금정산: 학습 서비스 및 콘텐츠 일체 제공, 서비스 요금 결제, 상품 배송, 금융서비스 제공, 요금추심 등
3. 마케팅 및 광고에 활용: 신규 서비스 개발 및 고도화, 이벤트 등 광고성 정보 전달, 인구 통계학적 특성에 따른 서비스 제공 및 광고 게재
4. 사고발생시 원인 규명 및 처리를 위한 수집 및 이용

제2조(개인정보 수집 항목)
최초 회원가입 시 회원식별을 위해 아래와 같은 정보를 수집합니다.
- 항목: 성명, 이메일(ID), 비밀번호, 가입인증정보(메일주소, 휴대폰 번호), 만 14세 미만은 법정대리인 정보

원활한 고객상담과 맞춤 서비스의 제공을 위해 아래와 같은 추가적인 정보를 수집할 수 있습니다.
- 항목: 영어 이름, 연락처(메일주소, 휴대폰 번호), 인증정보(메일주소, 휴대폰 번호), 직장 및 직무, 거주지의 시간대, 메일 수신 여부, 수강정보 및 이벤트 소식 이메일 수신 여부,

부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 이용자의 동의를 얻은 후, 다음과 같은 별도의 정보가 추가적으로 수집될 수 있습니다.
- 대학생, 대학원생 할인: 재학증명서 또는 학생증,
- 고객 학습 종료 후 제공되는 피드백과 녹음 및 교정 파일

서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
- 접속 IP 정보, 쿠키, 결제기록, 접속로그, 서비스 이용 기록, 불량 이용 기록

유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
- 신용카드 결제 시: 카드사명, 카드번호, 카드 소유주 생년월일, 유효기간 등
- 계좌 이체 시: 은행 명, 입금자 명, 계좌번호 등

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
1) 회원 가입 및 정보 수정
2) 전화, 메신저, 오프라인 개인 상담
3) 제휴사로부터의 제공
4) 생성정보 수집 툴을 통한 자동 수집

제3조(개인정보의 보유 및 이용기간)
"링글"은 "회원"이 회원자격을 유지하고 있는 동안 수집된 "회원"의 개인 및 수강정보를 보유∙이용할 수 있으며, "회원"이 탈퇴하거나 자격을 상실할 경우에는 "회원"의 별도 요청이 없더라도 수집된 회원정보를 삭제 및 파기합니다. 단, "회원"의 탈퇴 또는 자격상실에도 불구하고 다음의 정보는 아래의 이유로 명시한 기간 동안 보존 합니다.

1. 링글 내부 방침에 의한 정보 보유
- 보존항목: 이름, 이메일 주소(ID), 전화번호, 수업 로그
- 보존 근거: 재가입 제한 및 서비스 중복 신청 확인
- 보존기간: 회원 탈퇴 후 1년,

2. 관련법령에 의한 정보보유 사유
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

계약 또는 청약철회 등에 관한 기록 (소비자 식별정보, 계약∙청약철회 기록 등) - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
대금결제 및 재화 등의 공급에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
소비자의 불만 및 분쟁처리에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 3년
신용정보의 수집∙처리 및 이용 등에 관한 기록 - 신용정보의 이용 및 보호에 관한 법률 - 3년
웹사이트 방문기록 (로그기록, IP 등) - 통신비밀보호법 - 3개월

3. 휴면회원의 정보 보유
"회원"이 마지막 접속일로부터 1년(365일) 이상 로그인을 하지 않은 경우 해당 "회원"의 아이디는 "휴면회원”이 되어 회원 로그인을 비롯한 모든 서비스에 대한 이용이 정지되고, "링글"은 "휴면회원”의 개인정보를 다른 아이디와 별도로 관리합니다. "링글"은 "휴면회원” 처리 예정일 30일 전 해당 사실을 이메일을 통하여 사전 안내하며, "회원"은 "휴면회원” 처리일 이후에 링글 사이트(ringleplus.com)” 및 링글 앱 상에서 직접 본인 확인을 거쳐 휴면상태 해지신청을 하는 즉시 다시 정상적으로 서비스를 이용할 수 있습니다.

제4조(동의의 거부권 등)
"회원"은 개인정보 수집∙이용에 관한 동의를 거부할 권리가 있습니다. 다만, 계약 체결 등을 위해 필요한 최소한의 개인정보 수집∙이용에 관한 동의를 거부하는 경우에는 링글 콘텐츠 및 서비스 이용이 불가(본인 여부 및 계약 체결 의사 불명 이유)하고, 마케팅 활동 및 홍보를 위한 개인정보 수집∙이용 또는 선택적 수집∙이용에 관한 동의를 거부하는 경우에는 이벤트, 혜택에 대한 정보를 제공받지 못하거나 사은품∙판촉물 제공, 할인 혜택 적용 등의 불이익이 발생할 수 있습니다.

제5조(개인정보의 파기절차 및 방법)
링글은 원칙적으로 이용자 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 링글의 개인정보 파기절차 및 방법은 다음과 같습니다.

파기절차
- "회원”이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.

파기기한
개인정보의 보유기간이 경과한 경우에는 그 경과한 날로부터, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 된 경우에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 각 지체 없이(정당한 사유가 없는 한 5일 이내) 해당 개인정보를 파기합니다.

파기방법
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
- 전자파일 형태의 개인정보는 복구 및 재생이 불가능한 기술적 방법을 사용하여 삭제합니다.

제6조(회원 및 법정대리인의 권리와 그 행사방법)
1. "회원"은 언제든지 "회원"의 개인정보를 열람하거나 수정할 수 있으며, "링글"의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청할 수 있습니다.

2. "회원"의 개인정보 조회 및 수정을 위해서는 "나의 정보 수정하기”를, 가입해지(동의철회)를 위해서는 "탈퇴하기(Close Account)"를 클릭하여 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 보호 업무 담당부서에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.

3. 만 14세 이상인 경우에만 회원 가입이 가능하며, 개인정보의 수집∙이용에 법정 대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다.

제7조(개인정보 자동수집 장치의 설치, 운영 및 거부에 관한 사항)
링글은 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이 정보는 반드시 "회원"이 정보를 제공하였을 때만 저장되고, "회원"이 제공하지 않은 정보를 얻을 수 없으며, 컴퓨터에 저장되어 있는 다른 파일들에 접근할 수 없습니다. 쿠키의 사용 목적 및 쿠키 설정 거부 방법은 다음과 같습니다.

쿠키의 사용 목적
링글 회원 및 사이트 방문객들의 이용형태(접속빈도, 방문시간, 서비스 관심도)를 분석해 이용자에게 최적화된 정보를 제공하기 위하여 사용합니다.

쿠키의 설치/운영 및 거부
1. "회원"은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, "회원"은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, "회원”이 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 제공에 어려움이 있을 수 있습니다.

2. 쿠키 설치 허용 여부를 설정하는 방법은 다음과 같습니다.
- Internet Explorer: 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
- Google Chrome: 웹 브라우저 상단의 설정 &gt; 고급 설정 &gt; 개인정보

제8조(개인정보의 기술적/관리적 보호 대책)
회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

1. 비밀번호 관리
"회원” 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 비밀번호의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

2. 해킹 등에 대비한 대책
"링글"은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신・점검을 하며 시스템을 기술적 ・ 물리적으로 감시 및 차단하고 있습니다.
3. 취급 직원의 최소화 및 교육
회사의 개인정보관련 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 관리자 수시 교육을 통하여 링글 개인정보처리방침의 준수를 항상 강조하고 있습니다.

4. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

5. 개인정보처리시스템 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

제9조(개인정보관리책임자 및 담당자의 연락처)
링글의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자께 신고하실 수 있습니다. 링글은 "회원”의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

개인정보 관리책임자
이름: 이성파
이메일: contact@ringleplus.com

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
- 개인정보침해신고센터 (www.118.or.kr / 118)
- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)
- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)
- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)

제10조(고지의 의무)
현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 고지할 것입니다.

-공지일자: 2017년 7월 26일
-시행일자: 2017년 7월 26일`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), respects copyrights and other intellectual property rights, and expects our users to do the same.

This policy is meant to inform you of our policies and procedures regarding the infringement of copyrighted material which we make available through our services.

NOTE TO RINGLE USERS

If you are an RINGLE user, be advised that this policy is a part of our terms of service with you regarding Content. We do not permit our users to upload, store, save, make copies of, distribute, display, perform, or make derivative works of, any copyrighted material, such as images, video, text, or other works of authorship which the user does not own, or which the user does not have the authority to use. Doing so is a breach of your agreement with us, and we reserve the right to terminate your account or agreement with us for these reasons.

COPYRIGHT INFRINGEMENT NOTIFICATIONS

If you believe that there is any infringement of your copyrighted materials on our website(s), services, or other systems, you may provide us with a notice of copyright infringement. Pursuant to United States copyright law, all notifications of claimed copyright infringement should be sent to our designated agent.

We have provided the following information for the exclusive purpose of notifying us that you believe your copyrights are being infringed. Please do not send other inquiries or notices to this contact, since you will not receive a response to any inquiries or notices that are not related to copyright infringement.

To file a copyright infringement notification with us, you must send us a written notice that includes all of the following requirements. You should consult your own attorney or refer to 17 U.S.C.§512(c)(3), Section 512(c)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Your address, telephone number, and/or email address, so that we may contact you regarding the notification.

A clear and complete identification of the work or works you claim is being infringed upon. If your notice covers multiple copyrighted works, you may include a representative list of the works.

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials. The identification should include enough specific information, such as a URL, so that we can reasonably locate the allegedly infringing material. General statements about the material, such as stating "the infringing material is on your website”, is not sufficient for us to identify the allegedly infringing material or its location.

The statement "I have a good-faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.”

The statement "The information in this notice is accurate, and, under penalty of perjury, I am the copyright owner, or authorized to act on behalf of the owner of the exclusive right that is allegedly infringed.”

An electronic or physical signature of the copyright owner or the person authorized to act on behalf of the owner. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your notice.

If you are submitting a copyright infringement notice to remove allegedly infringing materials originating from a third-party website which is cached on our websites, services, or supporting systems, your notice (a) may only be sent after the material has been taken down from the originating website, or where a court has ordered the takedown from that website, and (b) must include a statement confirming that the material has been taken down, or that a court has ordered that the material be taken down from the originating website.

If we determine that your notice complies with our requirements, we will act promptly to take down, or disable access to, the allegedly infringing material and provide the subscriber with notice that we have taken down the material.

LEGAL PROCESS & FAIR USE

You are initiating a legal process by sending us a copyright infringement notice. Do not submit false claims. Please consider whether any disputed use constitutes fair use or fair dealing which does not require your authorization for use (or any other exception to copyright) before you submit a copyright infringement notice.

You can learn more about fair use by reading the Digital Media Law Project’s guidelines, Stanford University Library’s fair use website, or by visiting the U.S. Copyright Office’s Fair Use Index. We have provided these resources for your informational purposes only, and they are not a substitute for legal advice from a lawyer. RINGLE is not sponsored or endorsed by the owners or operators of these websites.

If you are unsure whether materials infringe upon your copyrights, please consult a lawyer before proceeding with any notice to us.

You may be subject to severe legal consequences if you knowingly make a false representation or statement that material is infringing. These consequences include damages, including court costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations to remove or disable access to the material. Those parties include the alleged infringer, the copyright owner or its licensees, and us.

If you are an RINGLE user, we may suspend or terminate your account or access to our app or website(s) or services for submitting false claims of copyright infringement.

Please note that the information you provide in a notice may be forwarded to the person who provided the allegedly infringing material to us, and that we may publish your information in place of disabled content.

RETRACTIONS

If you submitted a notice of copyright infringement by mistake, or would like to retract your notice, please provide us with the following:

The statement "I hereby retract my copyright infringement notification.”

An identification of the allegedly infringing materials, and if applicable, the reference or link to the allegedly infringing materials which you identified in the original copyright infringement notification

Your electronic or physical signature (including first and last names; no company names). If you sent your original notification by email, send your retraction from the same email address, otherwise we may not be able to process your retraction.

If you are an RINGLE user and your account or any material you uploaded or submitted to any part of the RINGLE site has been affected by a copyright infringement notification, you may reach out directly to the copyright owner for a retraction of the original notice.

COUNTER-NOTIFICATIONS

When we receive a notice of copyright infringement, we will remove or disable access to the allegedly infringing material and notify the alleged infringer. If any of your material is removed for this reason, and you believe your material is not infringing, or, that you have authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to use the material in the manner complained about, you may send a counter-notice to our Designated Agent.

We have provided the following information for the exclusive purposes of notifying us that you dispute a copyright infringement claim. Only parties who have all the necessary rights to post, distribute, or otherwise submit the disputed material, or their authorized agents, may submit a counter-notification, and this should only be done if you believe the material was removed or disabled by mistake or misidentification. Do not submit a counter-notification if this does not apply to you.

To submit a counter-notification to us, you must send us a written notice that includes substantially all of the following requirements. We will not be able to take action on incomplete submissions. You may consult your own attorney or refer to 17 U.S.C.§512(g)(3), Section 512(g)(3) of the Digital Millennium Copyright Act, to confirm the following requirements:

Identification of the material that was removed, and the location from where the material appeared before removal (e.g. the specific URL for the material). General information about the material, such as "my content was removed”, without including your username or notice number will not be sufficient for us to identify the material or its location.

The statement: "I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.”

The statement: "I consent to the jurisdiction of the Federal District Court for the district in which my address is located, or if my address is outside the United States, the judicial district in which Ringle, Inc. is located, and will accept service of process from the claimant or claimant’s agent.”

Your name, address, email address, and username(s) for your account.

A physical or valid electronic signature. You may provide your signature by typing your own full legal name (including first and last names; no company names) at the bottom of your counter-notification.

You may be subject to severe legal consequences if you knowingly materially misrepresent that material was removed or disabled by mistake or misidentification. Those consequences include damages, including costs and attorneys’ fees, incurred by anyone who is injured by our reliance on those misrepresentations. Those parties include the alleged infringer, the copyright owner or its licensees, or us.

Once we receive a counter-notification, we will forward a copy of the counter-notification to the party who submitted the original claim of copyright infringement. When we forward the counter-notification, it will include any personal information you provide. Please keep in mind that the original claimant may use this personal information to file a lawsuit against you to keep the material from being restored. By submitting a counter-notification to us, you consent to having your information revealed in this way. We will not forward your counter-notification to any party other than the original claimant.

We can only accept a counter-notification directly from the user from whose account the allegedly infringing content has been removed or has had its access disabled. For our verification purposes, counter-notifications sent by email should be submitted from the same email address associated with the account.

RESTORING MATERIAL

Once a counter-notification is sent to the original party who submitted the copyright infringement notice, that party has ten (10) business days to respond with evidence that they have initiated a court action to keep the material from being restored. We are required by law to wait for this period before we may restore the disputed material. If we do not receive this response from the original claimant, we may, in our sole discretion, reinstate the removed material or cease disabling access to it within ten (10) to fourteen (14) business days from the day we received a counter-notification.

RINGLE DESIGNATED AGENT

All notices under this policy, including notices of copyright infringement and counter-notifications, must be sent to our Designated Agent to be effective. You may submit notices by mail, fax, or email to:

Ringle Inc.
Attn: Copyright Agent
400 Concar Dr
San Mateo, CA 94402
USA
Email: contact@ringleplus.com

TERMINATION POLICY

Please be advised that we have adopted and enforces a policy of termination in appropriate circumstances of users who are repeat infringers.

DISCLAIMER

Please be advised that under 17 U.S.C. §512(f) any person who knowingly materially misrepresents that material is infringing, or who knowingly materially misrepresents that material or activity was removed or disabled by mistake or misidentification may be subject to liability for damages. If you are unsure whether materials infringe upon the copyrights of others, please consult a lawyer before proceeding with any notice to us.`,
    's_ip_policy.text',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_lesson_policy = {
  heading: [`RINGLE LESSONS POLICY`, `RINGLE LESSONS POLICY`, 's_lesson_policy.heading', ``, ``, ``, ``],
  text: [
    `㈜링글잉글리시에듀케이션서비스(이하 "회사” "링글”)은 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 "회원” 권익 보호에 최선을 다하고 있습니다.

"링글"은 "회원”의 개인정보를 보호하고 개인정보와 관련한 "회원"의 고충을 원활하게 처리할 수 있도록 개인정보처리방침을 제정∙시행하며, "링글"이 개인정보처리방침을 개정하는 경우 링글의 홈페이지(ringleplus.com)에 변경 사항을 즉시 게재하거나 "회원” 개별 이메일(ID)로 공지합니다.

제1조(개인정보 수집 항목 및 이용 목적 및 수집방법)
"링글"는 "회원"의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 본 조에서 고지한 범위를 넘어 "회원"의 개인정보를 활용하지 않습니다. 수집한 개인정보 활용의 목적은 아래와 같습니다.

1. 회원 관리: 회원제 서비스 이용에 따른 본인확인, 가입 의사 확인, 개인 식별, 불량 회원의 부정 이용 및 비인가 사용 방지, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 연령확인, 불만처리 등 민원 상담 처리, 고지사항 등 최신 정보 전달
2. 서비스 제공에 관한 계약 이행 및 요금정산: 학습 서비스 및 콘텐츠 일체 제공, 서비스 요금 결제, 상품 배송, 금융서비스 제공, 요금추심 등
3. 마케팅 및 광고에 활용: 신규 서비스 개발 및 고도화, 이벤트 등 광고성 정보 전달, 인구 통계학적 특성에 따른 서비스 제공 및 광고 게재
4. 사고발생시 원인 규명 및 처리를 위한 수집 및 이용

제2조(개인정보 수집 항목)
최초 회원가입 시 회원식별을 위해 아래와 같은 정보를 수집합니다.
- 항목: 성명, 이메일(ID), 비밀번호, 가입인증정보(메일주소, 휴대폰 번호), 만 14세 미만은 법정대리인 정보

원활한 고객상담과 맞춤 서비스의 제공을 위해 아래와 같은 추가적인 정보를 수집할 수 있습니다.
- 항목: 영어 이름, 연락처(메일주소, 휴대폰 번호), 인증정보(메일주소, 휴대폰 번호), 직장 및 직무, 거주지의 시간대, 메일 수신 여부, 수강정보 및 이벤트 소식 이메일 수신 여부,

부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 이용자의 동의를 얻은 후, 다음과 같은 별도의 정보가 추가적으로 수집될 수 있습니다.
- 대학생, 대학원생 할인: 재학증명서 또는 학생증,
- 고객 학습 종료 후 제공되는 피드백과 녹음 및 교정 파일

서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
- 접속 IP 정보, 쿠키, 결제기록, 접속로그, 서비스 이용 기록, 불량 이용 기록

유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
- 신용카드 결제 시: 카드사명, 카드번호, 카드 소유주 생년월일, 유효기간 등
- 계좌 이체 시: 은행 명, 입금자 명, 계좌번호 등

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
1) 회원 가입 및 정보 수정
2) 전화, 메신저, 오프라인 개인 상담
3) 제휴사로부터의 제공
4) 생성정보 수집 툴을 통한 자동 수집

제3조(개인정보의 보유 및 이용기간)
"링글"은 "회원"이 회원자격을 유지하고 있는 동안 수집된 "회원"의 개인 및 수강정보를 보유∙이용할 수 있으며, "회원"이 탈퇴하거나 자격을 상실할 경우에는 "회원"의 별도 요청이 없더라도 수집된 회원정보를 삭제 및 파기합니다. 단, "회원"의 탈퇴 또는 자격상실에도 불구하고 다음의 정보는 아래의 이유로 명시한 기간 동안 보존 합니다.

1. 링글 내부 방침에 의한 정보 보유
- 보존항목: 이름, 이메일 주소(ID), 전화번호, 수업 로그
- 보존 근거: 재가입 제한 및 서비스 중복 신청 확인
- 보존기간: 회원 탈퇴 후 1년,

2. 관련법령에 의한 정보보유 사유
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

계약 또는 청약철회 등에 관한 기록 (소비자 식별정보, 계약∙청약철회 기록 등) - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
대금결제 및 재화 등의 공급에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
소비자의 불만 및 분쟁처리에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 3년
신용정보의 수집∙처리 및 이용 등에 관한 기록 - 신용정보의 이용 및 보호에 관한 법률 - 3년
웹사이트 방문기록 (로그기록, IP 등) - 통신비밀보호법 - 3개월

3. 휴면회원의 정보 보유
"회원"이 마지막 접속일로부터 1년(365일) 이상 로그인을 하지 않은 경우 해당 "회원"의 아이디는 "휴면회원”이 되어 회원 로그인을 비롯한 모든 서비스에 대한 이용이 정지되고, "링글"은 "휴면회원”의 개인정보를 다른 아이디와 별도로 관리합니다. "링글"은 "휴면회원” 처리 예정일 30일 전 해당 사실을 이메일을 통하여 사전 안내하며, "회원"은 "휴면회원” 처리일 이후에 링글 사이트(ringleplus.com)” 및 링글 앱 상에서 직접 본인 확인을 거쳐 휴면상태 해지신청을 하는 즉시 다시 정상적으로 서비스를 이용할 수 있습니다.

제4조(동의의 거부권 등)
"회원"은 개인정보 수집∙이용에 관한 동의를 거부할 권리가 있습니다. 다만, 계약 체결 등을 위해 필요한 최소한의 개인정보 수집∙이용에 관한 동의를 거부하는 경우에는 링글 콘텐츠 및 서비스 이용이 불가(본인 여부 및 계약 체결 의사 불명 이유)하고, 마케팅 활동 및 홍보를 위한 개인정보 수집∙이용 또는 선택적 수집∙이용에 관한 동의를 거부하는 경우에는 이벤트, 혜택에 대한 정보를 제공받지 못하거나 사은품∙판촉물 제공, 할인 혜택 적용 등의 불이익이 발생할 수 있습니다.

제5조(개인정보의 파기절차 및 방법)
링글은 원칙적으로 이용자 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 링글의 개인정보 파기절차 및 방법은 다음과 같습니다.

파기절차
- "회원”이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.

파기기한
개인정보의 보유기간이 경과한 경우에는 그 경과한 날로부터, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 된 경우에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 각 지체 없이(정당한 사유가 없는 한 5일 이내) 해당 개인정보를 파기합니다.

파기방법
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
- 전자파일 형태의 개인정보는 복구 및 재생이 불가능한 기술적 방법을 사용하여 삭제합니다.

제6조(회원 및 법정대리인의 권리와 그 행사방법)
1. "회원"은 언제든지 "회원"의 개인정보를 열람하거나 수정할 수 있으며, "링글"의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청할 수 있습니다.

2. "회원"의 개인정보 조회 및 수정을 위해서는 "나의 정보 수정하기”를, 가입해지(동의철회)를 위해서는 "탈퇴하기(Close Account)"를 클릭하여 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 보호 업무 담당부서에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.

3. 만 14세 이상인 경우에만 회원 가입이 가능하며, 개인정보의 수집∙이용에 법정 대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다.

제7조(개인정보 자동수집 장치의 설치, 운영 및 거부에 관한 사항)
링글은 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이 정보는 반드시 "회원"이 정보를 제공하였을 때만 저장되고, "회원"이 제공하지 않은 정보를 얻을 수 없으며, 컴퓨터에 저장되어 있는 다른 파일들에 접근할 수 없습니다. 쿠키의 사용 목적 및 쿠키 설정 거부 방법은 다음과 같습니다.

쿠키의 사용 목적
링글 회원 및 사이트 방문객들의 이용형태(접속빈도, 방문시간, 서비스 관심도)를 분석해 이용자에게 최적화된 정보를 제공하기 위하여 사용합니다.

쿠키의 설치/운영 및 거부
1. "회원"은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, "회원"은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, "회원”이 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 제공에 어려움이 있을 수 있습니다.

2. 쿠키 설치 허용 여부를 설정하는 방법은 다음과 같습니다.
- Internet Explorer: 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
- Google Chrome: 웹 브라우저 상단의 설정 &gt; 고급 설정 &gt; 개인정보

제8조(개인정보의 기술적/관리적 보호 대책)
회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

1. 비밀번호 관리
"회원” 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 비밀번호의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

2. 해킹 등에 대비한 대책
"링글"은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신・점검을 하며 시스템을 기술적 ・ 물리적으로 감시 및 차단하고 있습니다.
3. 취급 직원의 최소화 및 교육
회사의 개인정보관련 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 관리자 수시 교육을 통하여 링글 개인정보처리방침의 준수를 항상 강조하고 있습니다.

4. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

5. 개인정보처리시스템 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

제9조(개인정보관리책임자 및 담당자의 연락처)
링글의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자께 신고하실 수 있습니다. 링글은 "회원”의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

개인정보 관리책임자
이름: 이성파
이메일: contact@ringleplus.com

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
- 개인정보침해신고센터 (www.118.or.kr / 118)
- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)
- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)
- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)

제10조(고지의 의무)
현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 고지할 것입니다.

-공지일자: 2017년 7월 26일
-시행일자: 2017년 7월 26일`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Lessons Policy as a part of our Terms of Service to inform you of our policies regarding tutoring lessons accessible through the RINGLE Site and Services.

RINGLE does not provide tutoring services directly. It is up to you as a "Student” to purchase tutoring lessons (each, a "Lesson”) with independent tutors ("Tutors”) who offer their services through the RINGLE Site. It is up to each Tutor to agree to provide their tutoring services to you in each Lesson you purchase.

While RINGLE does not provide tutoring services, RINGLE facilitates Lessons between you and Tutors by establishing a system for Students to request, schedule, and purchase Lessons, providing an online platform to connect Students with Tutors, and collecting and processing payments between Students and Tutors for any Lessons conducted.

Each confirmed Lesson represents a binding agreement formed directly between you as the Student, the Tutor you hire for tutoring services, and RINGLE as your service provider.

In order to provide all Students and Tutors with a consistent, reliable, and easy to understand experience for Lessons conducted through RINGLE’s Services, we have standardized the terms and conditions for Lessons in this Lessons Policy.

All Lessons will be subject to the following terms and conditions that apply to you. Each Tutor will be subject to corresponding terms and conditions in their agreement with RINGLE. Each Tutor agrees to provide tutoring services to Students in consideration of your agreement to these terms and conditions as a Student.

This Lessons Policy may change from time to time. We will notify you of any material changes by posting the new Lessons Policy wherever it may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to the Lessons Policy will be considered your acceptance to those changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

PURCHASING CREDITS FOR LESSONS

You must have credits for Lessons before you can hire any Tutor or start any Lessons.

Lesson credits are only available through the Ringle Site. Credits may be purchased on a per-Lesson basis, by "package” for a fixed number of Lessons, and/or may be term-based for Lessons conducted over a fixed period of time. From time to time, RINGLE may also offer promotional offers for free or discounted Lesson credits.

Any credits are applied towards payments to Tutors for each Lesson, and for payment to RINGLE for the Service provided for each Lesson.

REQUESTS FOR LESSONS

Once you have Lesson credits, you may request and schedule Lessons based on your desired dates for Lessons, select preferred Tutors, and select preferred Lesson materials. Lesson scheduling, Tutors, and requested Lessons materials are subject to availability at the time of selection.

CREDIT AVAILABILITY & VALIDITY

Credits are applied towards payments to Tutors for each Lesson, including to guarantee their continued availability for any fixed period of time you want to schedule your Lessons. For that reason, when you purchase term-based credits for Lessons over a fixed period of time, your credits may expire even if you don’t complete any Lessons, and these credits will no longer be valid unless you request, and we grant, an extension of credit validity period. The validity period for any term-based credits will begin from the date your first Lesson begins. Extensions of time to use your credits may be subject to additional fees, terms, and conditions in effect at that time, and Tutor selection will be subject to availability at such time.

CHANGES & CANCELLATIONS

You may reschedule or cancel any Lesson with an assigned Tutor at any time at least twenty-four (24) hours before an accepted Lesson is scheduled to begin, at no cost to you.

If a requested Lesson is accepted by a Tutor, you may reschedule or cancel the Lesson less than twenty-four (24) hours before the Lesson is scheduled to begin, but your credits for the Lesson will be deducted from your account with no right to refund or additional credits.

However, if no Tutor has accepted your requested Lesson, you may reschedule or cancel a Lesson at any time at no cost to you.

You are solely responsible for use of your Lesson credits. RINGLE and Tutors will not be responsible or liable to you for any mistakes or errors you may make when scheduling Lessons.

TARDINESS & DELAY

You agree to be on time for any Lesson, and be prepared to engage in the Lesson according to the Lesson material selected. You may enter any virtual online Lesson room at least fifteen (15) minutes before a Lesson is scheduled to begin. Lessons will begin and end according to their scheduled times, no matter if you are late or your attendance is delayed for reasons beyond a Tutor’s or RINGLE’s control.

However, if the start of a Lesson is delayed or cancelled by a Tutor or due to causes within RINGLE’s control, you will be entitled to have the Lesson conducted for at least forty (40) minutes from when it is possible to begin the Lesson, or else have a full refund or credit issued for the Lesson at no cost to you.

RINGLE RIGHTS & RESPONSIBILITIES

RINGLE is solely responsible for the operation and availability of its Site and Services for each Lesson scheduled.

RINGLE shall use all commercially reasonable measures to screen and vet each Tutor, including by conducting background and credential checks to verify their qualifications.

From time to time, and/or upon a Student’s request, Tutors may need to be substituted for Lessons. If you request any substitution of a Tutor after a Lesson is scheduled, RINGLE will use commercially reasonable efforts to fulfill your request, but cannot guarantee that any substitute Tutor will meet your expectations or requirements.

Because Lessons are conducted by independent Tutors, except as expressly agreed to by RINGLE in writing, RINGLE will have no responsibility or liability to you for a Tutor’s conduct or performance during a Lesson.

Tutors have authorized RINGLE to accept and process payments on their behalf, which are made through your use of Lesson credits. RINGLE reserves the right to disable your account and/or suspend or terminate your access to the Site or Services for nonpayment of any amounts due to RINGLE or Tutor for your use of the Services.

Within thirty (30) days of completion of any purchased Services or following a Student request, RINGLE will provide a written statement of the Student’s account, including any credits used, credits remaining, and/or an accounting of any fees charged or applied by RINGLE or by a Tutor for any Lessons conducted on the Student’s behalf.

TUTOR RESPONSIBILITIES

While RINGLE will use all commercially reasonable efforts to ensure Tutors on the RINGLE platform are qualified and capable of conducting satisfactory Lessons, Tutors are solely responsible and liable for their own conduct and performance during a Lesson.

Tutors are independent contractors, and have no power or authority to bind RINGLE to any agreement or obligation on RINGLE’s behalf, including but not limited to any offer, discount, or accommodation that a Student may request.`,
    's_lesson_policy.text',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_community_guide = {
  heading: [
    `RINGLE COmmUNITY GUIDELINES POLICY`,
    `RINGLE COmmUNITY GUIDELINES POLICY`,
    's_community_guide.heading',
    ``,
    ``,
    ``,
    ``,
  ],
  text: [
    `㈜링글잉글리시에듀케이션서비스(이하 "회사” "링글”)은 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 "회원” 권익 보호에 최선을 다하고 있습니다.

"링글"은 "회원”의 개인정보를 보호하고 개인정보와 관련한 "회원"의 고충을 원활하게 처리할 수 있도록 개인정보처리방침을 제정∙시행하며, "링글"이 개인정보처리방침을 개정하는 경우 링글의 홈페이지(ringleplus.com)에 변경 사항을 즉시 게재하거나 "회원” 개별 이메일(ID)로 공지합니다.

제1조(개인정보 수집 항목 및 이용 목적 및 수집방법)
"링글"는 "회원"의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 본 조에서 고지한 범위를 넘어 "회원"의 개인정보를 활용하지 않습니다. 수집한 개인정보 활용의 목적은 아래와 같습니다.

1. 회원 관리: 회원제 서비스 이용에 따른 본인확인, 가입 의사 확인, 개인 식별, 불량 회원의 부정 이용 및 비인가 사용 방지, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 연령확인, 불만처리 등 민원 상담 처리, 고지사항 등 최신 정보 전달
2. 서비스 제공에 관한 계약 이행 및 요금정산: 학습 서비스 및 콘텐츠 일체 제공, 서비스 요금 결제, 상품 배송, 금융서비스 제공, 요금추심 등
3. 마케팅 및 광고에 활용: 신규 서비스 개발 및 고도화, 이벤트 등 광고성 정보 전달, 인구 통계학적 특성에 따른 서비스 제공 및 광고 게재
4. 사고발생시 원인 규명 및 처리를 위한 수집 및 이용

제2조(개인정보 수집 항목)
최초 회원가입 시 회원식별을 위해 아래와 같은 정보를 수집합니다.
- 항목: 성명, 이메일(ID), 비밀번호, 가입인증정보(메일주소, 휴대폰 번호), 만 14세 미만은 법정대리인 정보

원활한 고객상담과 맞춤 서비스의 제공을 위해 아래와 같은 추가적인 정보를 수집할 수 있습니다.
- 항목: 영어 이름, 연락처(메일주소, 휴대폰 번호), 인증정보(메일주소, 휴대폰 번호), 직장 및 직무, 거주지의 시간대, 메일 수신 여부, 수강정보 및 이벤트 소식 이메일 수신 여부,

부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 이용자의 동의를 얻은 후, 다음과 같은 별도의 정보가 추가적으로 수집될 수 있습니다.
- 대학생, 대학원생 할인: 재학증명서 또는 학생증,
- 고객 학습 종료 후 제공되는 피드백과 녹음 및 교정 파일

서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
- 접속 IP 정보, 쿠키, 결제기록, 접속로그, 서비스 이용 기록, 불량 이용 기록

유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
- 신용카드 결제 시: 카드사명, 카드번호, 카드 소유주 생년월일, 유효기간 등
- 계좌 이체 시: 은행 명, 입금자 명, 계좌번호 등

회사는 다음과 같은 방법으로 개인정보를 수집합니다.
1) 회원 가입 및 정보 수정
2) 전화, 메신저, 오프라인 개인 상담
3) 제휴사로부터의 제공
4) 생성정보 수집 툴을 통한 자동 수집

제3조(개인정보의 보유 및 이용기간)
"링글"은 "회원"이 회원자격을 유지하고 있는 동안 수집된 "회원"의 개인 및 수강정보를 보유∙이용할 수 있으며, "회원"이 탈퇴하거나 자격을 상실할 경우에는 "회원"의 별도 요청이 없더라도 수집된 회원정보를 삭제 및 파기합니다. 단, "회원"의 탈퇴 또는 자격상실에도 불구하고 다음의 정보는 아래의 이유로 명시한 기간 동안 보존 합니다.

1. 링글 내부 방침에 의한 정보 보유
- 보존항목: 이름, 이메일 주소(ID), 전화번호, 수업 로그
- 보존 근거: 재가입 제한 및 서비스 중복 신청 확인
- 보존기간: 회원 탈퇴 후 1년,

2. 관련법령에 의한 정보보유 사유
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

계약 또는 청약철회 등에 관한 기록 (소비자 식별정보, 계약∙청약철회 기록 등) - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
대금결제 및 재화 등의 공급에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 5년
소비자의 불만 및 분쟁처리에 관한 기록 - 전자상거래 등에서의 소비자보호에 관한 법률 - 3년
신용정보의 수집∙처리 및 이용 등에 관한 기록 - 신용정보의 이용 및 보호에 관한 법률 - 3년
웹사이트 방문기록 (로그기록, IP 등) - 통신비밀보호법 - 3개월

3. 휴면회원의 정보 보유
"회원"이 마지막 접속일로부터 1년(365일) 이상 로그인을 하지 않은 경우 해당 "회원"의 아이디는 "휴면회원”이 되어 회원 로그인을 비롯한 모든 서비스에 대한 이용이 정지되고, "링글"은 "휴면회원”의 개인정보를 다른 아이디와 별도로 관리합니다. "링글"은 "휴면회원” 처리 예정일 30일 전 해당 사실을 이메일을 통하여 사전 안내하며, "회원"은 "휴면회원” 처리일 이후에 링글 사이트(ringleplus.com)” 및 링글 앱 상에서 직접 본인 확인을 거쳐 휴면상태 해지신청을 하는 즉시 다시 정상적으로 서비스를 이용할 수 있습니다.

제4조(동의의 거부권 등)
"회원"은 개인정보 수집∙이용에 관한 동의를 거부할 권리가 있습니다. 다만, 계약 체결 등을 위해 필요한 최소한의 개인정보 수집∙이용에 관한 동의를 거부하는 경우에는 링글 콘텐츠 및 서비스 이용이 불가(본인 여부 및 계약 체결 의사 불명 이유)하고, 마케팅 활동 및 홍보를 위한 개인정보 수집∙이용 또는 선택적 수집∙이용에 관한 동의를 거부하는 경우에는 이벤트, 혜택에 대한 정보를 제공받지 못하거나 사은품∙판촉물 제공, 할인 혜택 적용 등의 불이익이 발생할 수 있습니다.

제5조(개인정보의 파기절차 및 방법)
링글은 원칙적으로 이용자 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 링글의 개인정보 파기절차 및 방법은 다음과 같습니다.

파기절차
- "회원”이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.

파기기한
개인정보의 보유기간이 경과한 경우에는 그 경과한 날로부터, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 된 경우에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 각 지체 없이(정당한 사유가 없는 한 5일 이내) 해당 개인정보를 파기합니다.

파기방법
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
- 전자파일 형태의 개인정보는 복구 및 재생이 불가능한 기술적 방법을 사용하여 삭제합니다.

제6조(회원 및 법정대리인의 권리와 그 행사방법)
1. "회원"은 언제든지 "회원"의 개인정보를 열람하거나 수정할 수 있으며, "링글"의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청할 수 있습니다.

2. "회원"의 개인정보 조회 및 수정을 위해서는 "나의 정보 수정하기”를, 가입해지(동의철회)를 위해서는 "탈퇴하기(Close Account)"를 클릭하여 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 보호 업무 담당부서에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.

3. 만 14세 이상인 경우에만 회원 가입이 가능하며, 개인정보의 수집∙이용에 법정 대리인의 동의가 필요한 만 14세 미만 아동의 개인정보는 원칙적으로 수집하지 않습니다.

제7조(개인정보 자동수집 장치의 설치, 운영 및 거부에 관한 사항)
링글은 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이 정보는 반드시 "회원"이 정보를 제공하였을 때만 저장되고, "회원"이 제공하지 않은 정보를 얻을 수 없으며, 컴퓨터에 저장되어 있는 다른 파일들에 접근할 수 없습니다. 쿠키의 사용 목적 및 쿠키 설정 거부 방법은 다음과 같습니다.

쿠키의 사용 목적
링글 회원 및 사이트 방문객들의 이용형태(접속빈도, 방문시간, 서비스 관심도)를 분석해 이용자에게 최적화된 정보를 제공하기 위하여 사용합니다.

쿠키의 설치/운영 및 거부
1. "회원"은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, "회원"은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, "회원”이 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 제공에 어려움이 있을 수 있습니다.

2. 쿠키 설치 허용 여부를 설정하는 방법은 다음과 같습니다.
- Internet Explorer: 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보
- Google Chrome: 웹 브라우저 상단의 설정 &gt; 고급 설정 &gt; 개인정보

제8조(개인정보의 기술적/관리적 보호 대책)
회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

1. 비밀번호 관리
"회원” 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 비밀번호의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

2. 해킹 등에 대비한 대책
"링글"은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신・점검을 하며 시스템을 기술적 ・ 물리적으로 감시 및 차단하고 있습니다.
3. 취급 직원의 최소화 및 교육
회사의 개인정보관련 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 관리자 수시 교육을 통하여 링글 개인정보처리방침의 준수를 항상 강조하고 있습니다.

4. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

5. 개인정보처리시스템 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

제9조(개인정보관리책임자 및 담당자의 연락처)
링글의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자께 신고하실 수 있습니다. 링글은 "회원”의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

개인정보 관리책임자
이름: 이성파
이메일: contact@ringleplus.com

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
- 개인정보침해신고센터 (www.118.or.kr / 118)
- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)
- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)
- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)

제10조(고지의 의무)
현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 고지할 것입니다.

-공지일자: 2017년 7월 26일
-시행일자: 2017년 7월 26일`,
    `Last Updated Date: December 04, 2020


Ringle, Inc., including our Ringle affiliate companies ("we”, "us”, "our”, or "RINGLE”), provides this Community Guidelines Policy (the "Community Guidelines”) as a part of our Terms of Service to inform you of our policies and procedures for interacting with us and others RINGLE Site and Services.

The purpose of this policy is to set community standards for all of users to communicate, collaborate, teach, and learn within a safe and respectful environment. This policy is meant to provide a firm set of standards to define what kinds of conduct and content we will allow on our platform.

Keep in mind that we will enforce a strict "zero tolerance” policy for conduct we believe may damage or harm our community. Although you may not appreciate or agree with all of our policies, we must comply with all applicable laws and regulations to create an environment where every user can be heard and appreciated equally, and we expect you to respect these guidelines for this reason.

We may change our Community Guidelines from time to time. The most updated version of the Community Guidelines is available at https://www.ringleplus.com/en/student/policy/community-guidelines.

We will notify you of any material changes by posting the new Community Guidelines wherever they may appear on our Site, and give you notice that they have been updated according to our Terms of Service. Your continued use of any Services following any changes to these Community Guidelines will be considered your acceptance to our changes.

You should consult this policy regularly for any changes, and it is your responsibility to become aware of and understand them.

Unless otherwise defined in this policy, capitalized terms will have the same meanings as in our Terms of Service.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or conduct is in violation of the letter or spirit of any of these Community Guidelines.

BULLYING & HARASSMENT

We strictly prohibit any Content or conduct which we believe, in our sole discretion, constitutes harassment, stalking, intimidation, or abuse towards others, including any of which is based on a user’s race, ethnicity, or country of origin. We will remove Content, and may suspend or terminate users from accessing our Site if we determine any Content or user conduct willfully or knowingly targets users or private, non-public figures for shame or ridicule.

Violating Content and conduct includes unsolicited feedback, messages, or submitted Content which could be seen as offensive, degrading, or humiliating to another user of the Services, according to reasonable community standards. Offending conduct also includes submitting frivolous or false claims of violations of our Community Guidelines by a user.

We reserve the right to remove any Content or disable, suspend, or terminate user accounts if we determine, in our sole discretion, the Content or a user’s conduct is meant to harass, annoy, stalk, intimidate, shame, or abuse other people.

PUBLIC SAFETY

We will remove Content, disable user accounts, and cooperate with law enforcement and private parties if we have a reasonable belief there is a credible and genuine risk of harm or threats of harm to the public, us, or our user community.

If any threatening language or conduct is reported to us, we will review any such reports to determine whether they affect public or personal safety of our users or any member of the public, and we reserve the right to report that language or conduct to relevant authorities as permitted or required under applicable law.

HATE GROUPS & CRIMINAL ACTIVITY

We would like to support a diverse community of users of all beliefs and backgrounds, and while we support free speech for all our users, we will not permit any persons or organizations to use our Site or Services to engage in what we reasonably believe is objectively violent, hateful, discriminatory, or criminal, or any other activity which may be physically, mentally, or financially harmful to people, animals, or businesses, or which support or celebrate any persons or organizations that promote any of these activities.

We prohibit hate speech on our Site and Services. Hate speech includes any language, Content, or other communications or information that attacks users or other people on the basis of their race, ethnicity, national origin, religion, sexual orientation, sex, gender, gender identity, disability or disease, or any other characteristic that is protected from discrimination under applicable law.

However, users should understand that people may discuss hate speech, or even repeat hate speech as part of a constructive educational conversation during Lessons. We reserve the right to permit such Content if we reasonably determine that it is used to raise awareness or to educate other people about hate speech.

PERSONAL PRIVACY

We respect the privacy of our users and the public, and we expect you to do the same. We strictly prohibit our users from collecting, storing, or using any information about other users, especially but not limited to their Personal Information, without their express, prior consent.

We strictly prohibit users from posting, submitting, or contributing any information or commentary about any other private person without that person’s express, prior consent. Private persons are people whose actions or profession are not newsworthy or of general public interest.

PUBLIC FIGURES

Even though public figures are newsworthy and in the public eye, we do not tolerate any Content or conduct which constitute credible threats or hate speech directed towards those persons and which does not promote objectively constructive discussion or conversation.

SEXUAL CONTENT AND EXPLOITATION

We prohibit any Content that threatens, promotes, or suggests sexual violence, assault, or exploitation. We have no tolerance for this type of Content or conduct, including but not limited to solicitation of sexual material, sexual Content involving minors, threats or offers to share overtly sexual images, or offers of sexual services.

We will not hesitate to refer any offending Content, or report any conduct to law enforcement as we see fit.

VIOLENCE AND GRAPHIC CONTENT

While we recognize that Content depicting or describing violence or graphic subject matter, such as acts of war, abuse, or similar may be used or discussed for public awareness, or as matters of public interest or concern, some persons may be particularly sensitive to this type of Content due to cultural or age concerns. For that reason, we may restrict violent or graphic Content as we see fit.

CONTROLLED GOODS AND SUBSTANCES

Although certain goods and substances, such as drugs, alcohol, or firearms may be legal to sell or promote outside of our platform, we do not permit our users to use our Services to buy, sell or trade legal or illegal drugs, alcohol, tobacco, firearms, ammunition or any other goods or services which are regulated and controlled under applicable law.

While we do not discourage users from engaging in conversation or providing feedback regarding controlled goods and substances (except when they otherwise violate our Community Guidelines), we do not support commercial activity which does not comply with our Terms and all applicable laws and regulations.

REPORTING VIOLATIONS TO US

We encourage you to report any suspected violations of these Community Guidelines to us. In order to take action about any offending Content or conduct on the Site or made available through the Services however, you must provide us with sufficient information to identify and address the alleged violation.

You may report violations to us by contacting us at contact@ringleplus.com, or through any reporting functionality we provide through the Site. We may ask you to provide information such as (1) the location and/or pages on the Site or other Services where offending Content may be found; (2) a summary or description of conduct which you believe violates the Community Guidelines; and, as applicable (3) your reason why you believe Content and/or a user should be suspended from using our Site and Services.

Please keep in mind that while we have a zero tolerance policy for abuses of our Community Guidelines, we ultimately decide, in our sole reasonable discretion, whether any alleged violations are legitimate or credible, and whether we will take any action in response to a report.

Do not submit false or frivolous reports of abuse. We reserve the right to take action against any user that submits false or frivolous reports of abuse regarding other users or any part of the Services.`,
    's_community_guide.text',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_memo = {
  doc: {
    example_3: {
      content: [
        <>"회사에서 집으로 오는 길에" 를 영어로 어떻게 표현할까?</>,
        <>Is there any other way to say "this is a good opportunity"?</>,
        's_memo.doc.example_3.content',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
}
export const s_trial_completed = {
  completed: (name) => [
    <>
      {' '}
      {name}님
      <br />
      <Div spanTag textPrimary>
        체험 수업 신청 완료
      </Div>
      되었습니다.
    </>,
    <>
      {' '}
      Congrats {name}
      <br />
      <Div spanTag textPrimary>
        You booked your trial lesson
      </Div>
    </>,
    's_trial_completed.completed',
    ``,
    ``,
    ``,
    ``,
  ],
  check_guide: [
    `자세한 체험 수업 가이드를 확인하고 싶다면?`,
    `How Ringle works`,
    's_trial_completed.check_guide',
    ``,
    ``,
    ``,
    ``,
  ],
  time_completed: [`시간`, `Date & Time`, 's_trial_completed.time_completed', ``, ``, ``, ``],
  material_completed: [`교재`, `Materials`, 's_trial_completed.material_completed', ``, ``, ``, ``],
  automaching: [
    `체험 수업 시 튜터는 자동으로 매칭됩니다.`,
    `We'll match you to a tutor`,
    's_trial_completed.automaching',
    ``,
    ``,
    ``,
    ``,
  ],
  checking_three: [
    `링글 체험 수업 전 준비 사항 3가지`,
    `Prep for your lesson in 3 steps`,
    's_trial_completed.checking_three',
    ``,
    ``,
    ``,
    ``,
  ],
  step_one: [
    `Step1. 교재 읽기 / 질문 선택`,
    `Step1. Read Materials & Select Questions`,
    's_trial_completed.step_one',
    ``,
    ``,
    ``,
    ``,
  ],
  step_two: [`Step2. 1:1 맞춤 수업 설정`, `Step2. Set Lesson Styles`, 's_trial_completed.step_two', ``, ``, ``, ``],
  step_three: [
    `Step3. 카메라가 설치된 PC로 수업을 입장해주세요`,
    `Step3. Enter Lesson with Camera On`,
    's_trial_completed.step_three',
    ``,
    ``,
    ``,
    ``,
  ],
  step_one_desc: [
    `교재를 미리 읽어보고, 수업에서 다룰 질문을 선택한 후 답변을 준비해보세요.`,
    `Read your materials and choose which questions you'd like your tutor to ask you.`,
    's_trial_completed.step_one_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  step_two_desc: [
    `집중적으로 교정 받고 싶은 영역을 선택하여 나만의 수업을 만들어보세요.`,
    `Customize your lesson by selecting your correction areas.`,
    's_trial_completed.step_two_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  step_one_detail: [
    `교재 듣기 MP3, 핵심표현 등 다양한 자료를 수업 전 확인 가능합니다.`,
    `Also check out key expressions and related videos & news!`,
    's_trial_completed.step_one_detail',
    ``,
    ``,
    ``,
    ``,
  ],
  step_two_detail: [
    `체험수업은 각 질문에 대한 대화가 끝난 후 교정받는 방식으로만 진행합니다.
정규수업에서는 토론 중심 수업 설정도 가능합니다.`,
    `Your trial lesson will be comprised of 15-minute discussion and 5-minute corrections.`,
    's_trial_completed.step_two_detail',
    ``,
    ``,
    ``,
    ``,
  ],
  step_three_detail: [
    `교재/실시간 교정화면은 모바일 환경에서 제한적으로 보일 수 있습니다.`,
    `You may not see real-time corrections if you join via mobile.`,
    's_trial_completed.step_three_detail',
    ``,
    ``,
    ``,
    ``,
  ],
  before_lesson: [`수업 전 알아두세요`, `Please note:`, 's_trial_completed.before_lesson', ``, ``, ``, ``],
  before_one: [
    `PC로 수업 입장을 권장드리며, Zoom 프로그램 설치가 필요합니다.`,
    `Please install Zoom prior to lesson and enter lesson via web browser.`,
    's_trial_completed.before_one',
    ``,
    ``,
    ``,
    ``,
  ],
  before_two: [
    `수업 후 제공되는 수업 녹음 파일 / 스크립트 / AI 분석 / 튜터 피드백으로 복습 가능합니다.`,
    `You can review lesson using lesson recording, audio transcript, AI analysis and tutor feedback.`,
    's_trial_completed.before_two',
    ``,
    ``,
    ``,
    ``,
  ],
  before_three: [
    `교재/ 체험 수업 시간 변경 및 취소는 수업 시작 24시간 이전까지 가능합니다.`,
    `You may reschedule or change materials up to 24 hours before the lesson.`,
    's_trial_completed.before_three',
    ``,
    ``,
    ``,
    ``,
  ],
  before_three_gray: [
    `*수업 시작 24시간 이내 변경 및 취소 희망 시 오른쪽 하단 1:1 문의를 통해 연락 바랍니다.`,
    `*If you need to reschedule or change materials in less than 24 hours before the lesson, please contact us.`,
    's_trial_completed.before_three_gray',
    ``,
    ``,
    ``,
    ``,
  ],
  go_home: [`홈으로 가기`, `Home`, 's_trial_completed.go_home', ``, ``, ``, ``],
  button: [`체험 수업 준비하기`, `Lesson Prep`, 's_trial_completed.button', ``, ``, ``, ``],
}
export const s_web_auth = {
  verify: [`인증`, `Verify`, 's_web_auth.verify', ``, ``, ``, ``],
}
export const s_home = {
  promotion: {
    head: (dday) => [
      <>하반기 프로모션 얼리버드 마감까지 D-{dday}</>,
      <>D-{dday} 2nd Half Early Bird Promotion</>,
      's_home.promotion.head',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [
      <>
        정규 할인 전, 기존 고객분들에게만
        <br />
        얼리버드 한정 혜택을 드려요!
      </>,
      <>
        Early Bird offers only for existing users,
        <br />
        before the regular discount
      </>,
      's_home.promotion.title',
      ``,
      ``,
      ``,
      ``,
    ],
    content: (amount) => [
      <>
        최대 <span style={{ color: '#b4caff' }}>{amount}만원</span> 추가 할인
        <br />
        <span style={{ color: '#b4caff' }}>미국 항공권</span> 지원 혜택을 놓치지 마세요!
      </>,
      <>
        Don’t miss out on the early bird
        <br />
        discount up to <span style={{ color: '#b4caff' }}>$344.83</span> +{' '}
        <span style={{ color: '#b4caff' }}>Int’l airline tickets</span>{' '}
      </>,
      's_home.promotion.content',
      ``,
      ``,
      ``,
      ``,
    ],
    button: [<>지금 바로 혜택 받기</>, <>Receive benefits now</>, 's_home.promotion.button', ``, ``, ``, ``],
  },
  bn_challenge: {
    banner: [
      `링글과 함께하는 세 번째 도전, 보스턴&뉴욕 챌린지가 1월 6일부터 시작됩니다!`,
      `Ringle's Boston & NYC Challenge begins on Jan 6!`,
      's_home.bn_challenge.banner',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [`보스턴&뉴욕 챌린지`, `Boston & NYC Challenge`, 's_home.bn_challenge.title', ``, ``, ``, ``],
    participants: (number) => [
      <>🚩{number}명이 함께하고 있어요.</>,
      <>🚩{number} Ringlers have joined the challenge.</>,
      's_home.bn_challenge.participants',
      ``,
      ``,
      ``,
      ``,
    ],
    view_more: [`더 보기`, `See more`, 's_home.bn_challenge.view_more', ``, ``, ``, ``],
    desc: [
      `무료 수업권부터 5박 6일 보스턴 & 뉴욕 투어까지! 지금 챌린지 혜택을 확인하세요.`,
      `Check out the benefits from a free credit to a 6-day trip to Boston & NYC!`,
      's_home.bn_challenge.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`챌린지 도전하기`, `Join immediately`, 's_home.bn_challenge.btn', ``, ``, ``, ``],
    title_monthly: [`월별 수업 미션`, `Monthly Mission`, 's_home.bn_challenge.title_monthly', ``, ``, ``, ``],
    timer_end: (day) => [
      <>{day}일 후 종료</>,
      <>Ends in {day} day(s)</>,
      's_home.bn_challenge.timer_end',
      ``,
      ``,
      ``,
      ``,
    ],
    tooltip: [
      `[나의 챌린지 현황]에서 포인트를 받으세요!`,
      `Receive points from [My Challenge Board]!`,
      's_home.bn_challenge.tooltip',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_point: [
      `무료 수업권부터 5박 6일 보스턴 & 뉴욕 투어까지! 지금 챌린지 혜택을 확인하세요.`,
      `무료 수업권부터 5박 6일 보스턴 & 뉴욕 투어까지! 지금 챌린지 혜택을 확인하세요.`,
      's_home.bn_challenge.desc_point',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  b2b: {
    title: {
      desktop: [
        `이미 많은 고객분들이 링글과 함께\n꿈을 현실로 만들고 있습니다.`,
        `Ringle has allowed countless dreams to come true`,
        's_home.b2b.title.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `이미 많은 고객분들이\n링글과 함께\n꿈을 현실로 만들고 있습니다.`,
        `Ringle has allowed countless\ndreams to come true`,
        's_home.b2b.title.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  footer_txt: {
    companyname: {
      desktop: [
        `(주)링글잉글리시에듀케이션서비스`,
        `ⓒRingle English Education Service`,
        's_home.footer_txt.companyname.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `(주)링글잉글리시에듀케이션서비스`,
        `ⓒRingle English Education Service`,
        's_home.footer_txt.companyname.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    address: {
      desktop: [
        `서울 서초구 서초대로78길 22 11층`,
        `22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
        's_home.footer_txt.address.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [
        `서울 서초구 서초대로78길 22 11층`,
        `22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
        's_home.footer_txt.address.mobile',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    businessnumber: {
      desktop: [`사업자등록번호: 876-81-00110`, ``, 's_home.footer_txt.businessnumber.desktop', ``, ``, ``, ``],
      mobile: [`사업자등록번호: 876-81-00110`, ``, 's_home.footer_txt.businessnumber.mobile', ``, ``, ``, ``],
    },
    purchasenumber: {
      desktop: [
        `통신판매신고번호: 2019-서울강남-04831`,
        ``,
        's_home.footer_txt.purchasenumber.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [`통신판매신고번호: 2019-서울강남-04831`, ``, 's_home.footer_txt.purchasenumber.mobile', ``, ``, ``, ``],
    },
    phonenumber: {
      desktop: [`대표전화: 02-6204-8880`, ``, 's_home.footer_txt.phonenumber.desktop', ``, ``, ``, ``],
      mobile: [`대표전화: 02-6204-8880`, ``, 's_home.footer_txt.phonenumber.mobile', ``, ``, ``, ``],
    },
    ceo: {
      desktop: [
        `대표이사: 이성파, 이승훈. 개인정보책임자: 이성파`,
        ``,
        's_home.footer_txt.ceo.desktop',
        ``,
        ``,
        ``,
        ``,
      ],
      mobile: [`대표이사: 이성파, 이승훈. 개인정보책임자: 이성파`, ``, 's_home.footer_txt.ceo.mobile', ``, ``, ``, ``],
    },
    email: {
      desktop: [`contact@ringleplus.com`, `contact@ringleplus.com`, 's_home.footer_txt.email.desktop', ``, ``, ``, ``],
      mobile: [`contact@ringleplus.com`, `contact@ringleplus.com`, 's_home.footer_txt.email.mobile', ``, ``, ``, ``],
    },
  },
}
export const t_schedule_enter = {
  sorry_no_timefirst: [
    <>
      아쉽게도 현재 버전에서는
      <br />
      ‘시간 먼저 선택’이 제공되지 않습니다.
      <br />
      불편을 드려 죄송합니다.
    </>,
    `Beta version does not provide 'Sort by time'.`,
    't_schedule_enter.sorry_no_timefirst',
    ``,
    ``,
    ``,
    ``,
  ],
  sorry_no_24: [
    <>
      아쉽게도 현재 버전에서는 ‘24시간 이내 수업 선택’이 제공되지 않습니다.
      <br />
      불편을 드려 죄송합니다.
    </>,
    `Beta version does not provide 'Last-minute Lessons'.`,
    't_schedule_enter.sorry_no_24',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_certificate = {
  number_of_completed_lessons: (lessonCount, purchaseCount) => [
    <>
      (수강한 수업 수: {lessonCount}회/ 구매한 수업 수: {purchaseCount}회)
    </>,
    <>
      (Completed lessons : {lessonCount} / Paid lessons : {purchaseCount})
    </>,
    's_certificate.number_of_completed_lessons',
    ``,
    ``,
    ``,
    ``,
  ],
  ringle_english_education_service: [
    `㈜링글잉글리시에듀케이션서비스`,
    `Ringle English Education Service (Ringle Inc.)`,
    's_certificate.ringle_english_education_service',
    ``,
    ``,
    ``,
    ``,
  ],
  select_the_issue_priod: (firstPeriod, secondPeriod) => [
    <>
      {firstPeriod}~{secondPeriod} 내 증빙이 필요하신 기간을 선택해주세요.
    </>,
    <>
      Select the issue period within {firstPeriod}~{secondPeriod}
    </>,
    's_certificate.select_the_issue_priod',
    ``,
    ``,
    ``,
    ``,
  ],
  set_the_beginning_date: [
    `1. 결제일 이후 시작일을 설정할 수 있습니다.`,
    `1. You can set the beginning date after the payment date.`,
    's_certificate.set_the_beginning_date',
    ``,
    ``,
    ``,
    ``,
  ],
  be_at_least_1_won: [
    `1. 증빙 금액은 최소 1원 이상 입력할 수 있습니다.`,
    `1. The issued amount should be at least ₩1.`,
    's_certificate.be_at_least_1_won',
    ``,
    ``,
    ``,
    ``,
  ],
  be_at_least_1_dollar: [
    `1. 증빙 금액은 최소 1$ 이상 입력할 수 있습니다.`,
    `1. The issued amount should be at least $1.`,
    's_certificate.be_at_least_1_dollar',
    ``,
    ``,
    ``,
    ``,
  ],
  one_hundred_thousand_won: [`+10만원`, `+ ₩100,000`, 's_certificate.one_hundred_thousand_won', ``, ``, ``, ``],
  ten_thousand_won: [`+1만원`, `+ ₩10,000`, 's_certificate.ten_thousand_won', ``, ``, ``, ``],
  cannot_set_been_issued: [
    `2. 이미 증빙했던 기간 재설정은 불가합니다.`,
    `2. You cannot set dates that overlap with periods that have been shown in previously issued certificates.`,
    's_certificate.cannot_set_been_issued',
    ``,
    ``,
    ``,
    ``,
  ],
  issue_certificate_once_lesson: [
    `2. 전체 금액 증빙은 수강증 분할 발급 횟수를 1회로 선택했을 시에만 가능합니다.`,
    `2. You can only issue certificate for total price if you issue once.`,
    's_certificate.issue_certificate_once_lesson ',
    ``,
    ``,
    ``,
    ``,
  ],
  issue_certificate_once_attend: [
    `2. 전체 금액 증빙은 출석증 분할 발급 횟수를 1회로 선택했을 시에만 가능합니다.`,
    `2. You can only issue certificate for total price if you issue once.`,
    's_certificate.issue_certificate_once_attend',
    ``,
    ``,
    ``,
    ``,
  ],
  set_atleast_more_than_two_days: [
    `3. 당일 증빙은 불가하며, 최소 2일 이상 증빙 기간을 설정해야 합니다.`,
    `3. You cannot use today's date and the period should span at least two days.`,
    's_certificate.set_atleast_more_than_two_days',
    ``,
    ``,
    ``,
    ``,
  ],
  fifty_thousand_won: [`+5만원`, `+ ₩50,000`, 's_certificate.fifty_thousand_won', ``, ``, ``, ``],
  subtotal: [`결제금액`, `Subtotal`, 's_certificate.subtotal', ``, ``, ``, ``],
  payment_date_small: [`결제일`, `Payment date`, 's_certificate.payment_date_small', ``, ``, ``, ``],
  payment_date_large: [`결제일자`, `Payment Date`, 's_certificate.payment_date_large', ``, ``, ``, ``],
  leave_the_possible_amount_for_the_next_issuance: (maxCount) => [
    <>
      고객님이 선택한 증빙 횟수는 {maxCount}회입니다. 매회 발급 시 최소 증빙 금액은 1원으로 다음 증빙을 위해 가능 금액을
      남겨주세요. (전체 금액 증빙은 발급 횟수를 1회 설정했을 시만 가능)
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1₩.
    </>,
    's_certificate.leave_the_possible_amount_for_the_next_issuance',
    ``,
    ``,
    ``,
    ``,
  ],
  leave_the_possible_amount_for_the_next_issuance_dollar: (maxCount) => [
    <>
      고객님이 선택한 증빙 횟수는 {maxCount}회입니다. 매회 발급 시 최소 증빙 금액은 $1으로 다음 증빙을 위해 가능 금액을
      남겨주세요. (전체 금액 증빙은 발급 횟수를 1회 설정했을 시만 가능)
    </>,
    <>
      You set your certificate count at {maxCount} times.To issue certificates for every lesson, please set the issue
      amount to least 1$.
    </>,
    's_certificate.leave_the_possible_amount_for_the_next_issuance_dollar',
    ``,
    ``,
    ``,
    ``,
  ],
  provider: [`공급자`, `Co.`, 's_certificate.provider', ``, ``, ``, ``],
  course: [`과정명`, `Course`, 's_certificate.course', ``, ``, ``, ``],
  education_service: [`교육서비스`, `Education Service`, 's_certificate.education_service', ``, ``, ``, ``],
  choose_in_the_price_of_purchased_credits: [
    `구매한 수업권 금액 만큼 증빙 금액을 선택할 수 있습니다.
1. 증빙 금액은 최소 1원 이상 입력할 수 있습니다.
2. 전체 금액 증빙은 분할 발급 횟수를 1회로 선택했을 시에만 가능합니다.`,
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least ₩1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
    's_certificate.choose_in_the_price_of_purchased_credits',
    ``,
    ``,
    ``,
    ``,
  ],
  choose_in_the_price_of_purchased_credits_dollar: [
    `구매한 수업권 금액 만큼 증빙 금액을 선택할 수 있습니다.
1. 증빙 금액은 최소 $1 이상 입력할 수 있습니다.
2. 전체 금액 증빙은 분할 발급 횟수를 1회로 선택했을 시에만 가능합니다.`,
    `Please choose an amount up to the total price of purchased credits. 1. The amount should be at least $1. 2. Certificate for the total price requires you to set Certificate count to 1.`,
    's_certificate.choose_in_the_price_of_purchased_credits_dollar',
    ``,
    ``,
    ``,
    ``,
  ],
  maximum_number_of_issuance_for_the_purchased_credits: [
    `구매한 수업권에 따라 최대 발급 횟수를 선택할 수 있습니다. 최초 설정한 발급 횟수는 변경이 불가하오니 신중히 결정해 주세요. (최대 분할 발급 횟수 = 수강기간/30일)`,
    `You can issue as many certificates as your lesson period divided by 30 days. Please choose carefully as this setting cannot be reversed.`,
    's_certificate.maximum_number_of_issuance_for_the_purchased_credits',
    ``,
    ``,
    ``,
    ``,
  ],
  can_choose_the_issued_period: [
    `구매한 수업권의 수강기간 만큼 증빙기간을 선택할 수 있습니다.`,
    `Please select a period up to the length of the lesson period.`,
    's_certificate.can_choose_the_issued_period',
    ``,
    ``,
    ``,
    ``,
  ],
  price: [`금 액`, `Price`, 's_certificate.price', ``, ``, ``, ``],
  enter_price: [`금액 입력`, `Enter`, 's_certificate.enter_price', ``, ``, ``, ``],
  issued_amount_large: [`금액 증빙`, `Issued Amount`, 's_certificate.issued_amount_large', ``, ``, ``, ``],
  number_of_remained_lessons: [
    `남은 수업 수 :`,
    `Remaining lessons :`,
    's_certificate.number_of_remained_lessons',
    ``,
    ``,
    ``,
    ``,
  ],
  cumulative_attendance: [`누적 출석률`, `Cumulative`, 's_certificate.cumulative_attendance', ``, ``, ``, ``],
  terms: [`링글 증명서 발급 유의사항`, `Terms`, 's_certificate.terms', ``, ``, ``, ``],
  not_included_in_the_attendance: [
    `마케팅 목적으로 제공된 수업권은 출석률에 포함되지 않습니다.`,
    `Lesson credits provided for marketing purposes are not included in the attendance)`,
    's_certificate.not_included_in_the_attendance',
    ``,
    ``,
    ``,
    ``,
  ],
  paid_lessons_or_completed_lessons: [
    `모든 증명서는 실제 결제한 수업권 및 정상 진행된 수업에 대해서만 제공됩니다.`,
    `All certificates are provided only for paid and completed lessons.`,
    's_certificate.paid_lessons_or_completed_lessons',
    ``,
    ``,
    ``,
    ``,
  ],
  preview_and_issue: [`미리보기 & 발급하기`, `Preview & Issue`, 's_certificate.preview_and_issue', ``, ``, ``, ``],
  issue_date_small: [`발급 일시`, `Issue date`, 's_certificate.issue_date_small', ``, ``, ``, ``],
  issuance_period_small: [`발급 증빙 기간`, `Issuance period`, 's_certificate.issuance_period_small', ``, ``, ``, ``],
  Issuance_number: [`발급 횟수`, `Certificate count`, 's_certificate.Issuance_number', ``, ``, ``, ``],
  set_Issuance_number: [`발급 횟수 설정`, `Set Certificate count`, 's_certificate.set_Issuance_number', ``, ``, ``, ``],
  issue_date_large: [`발급일자`, `Issue Date`, 's_certificate.issue_date_large', ``, ``, ``, ``],
  number_of_issuance_large: [`발급횟수`, `Certificate count`, 's_certificate.number_of_issuance_large', ``, ``, ``, ``],
  business_regristration_number: [
    `사업자 등록번호`,
    `Business Reg No.`,
    's_certificate.business_regristration_number',
    ``,
    ``,
    ``,
    ``,
  ],
  business_location: [`사업자 소재지`, `Business Location`, 's_certificate.business_location', ``, ``, ``, ``],
  name_of_company: [`상 호`, `Name of company`, 's_certificate.name_of_company', ``, ``, ``, ``],
  ringle_address: [
    `서울 서초구 서초대로78길 22 홍우 2빌딩 11층 링글`,
    `Hongwoo Building 11F, 22, Seocho-daero 78-gil, Seocho-gu, Seoul, Republic of Korea`,
    's_certificate.ringle_address',
    ``,
    ``,
    ``,
    ``,
  ],
  select: [`선택해 주세요.`, `Select`, 's_certificate.select', ``, ``, ``, ``],
  from_the_selected_beginning_date: (period) => [
    <>선택하신 시작일로부터 {period}일동안 증빙할 수 있습니다.</>,
    <>You can issue for {period} days from the selected beginning date.</>,
    's_certificate.from_the_selected_beginning_date',
    ``,
    ``,
    ``,
    ``,
  ],
  the_issuance_number_attendance_certificate: (maxCount, leftCount) => [
    <Div flex flexRow>
      선택한 발급 횟수는 총{' '}
      <Div textPrimary fontMedium>
        &nbsp;{maxCount}회
      </Div>
      이고, 남은 발급 횟수는 <Div fontMedium>&nbsp;{leftCount}회</Div>입니다.
    </Div>,
    <Div flex flexRow>
      You have{' '}
      <Div textPrimary fontMedium>
        &nbsp;{maxCount}&nbsp;certificates{' '}
      </Div>
      &nbsp; left to issue out of <Div fontMedium>&nbsp;{leftCount}&nbsp;total certificate</Div>
    </Div>,
    's_certificate.the_issuance_number_attendance_certificate',
    ``,
    ``,
    ``,
    ``,
  ],
  compared_completed_lessons: [
    `선택한 증빙 기간 내 수강한 수업 수 대비 선택한 출석 기준 수업 수를 기준으로 산정됩니다. (증빙 기간 내 출석률=수강한 수업 수/출석 기준 수업수*100)`,
    `Calculated using lessons completed within the selected issue period.`,
    's_certificate.compared_completed_lessons',
    ``,
    ``,
    ``,
    ``,
  ],
  calculated_based_on_the_total_lessons: [
    `선택한 증빙 기간과 관계없이 수강한 총 수업 수 대비 구매한 총 수업 수를 기준으로 산정됩니다. (누적 출석률=수강한 총 수업 수/구매한 총 수업 수*100)`,
    `Calculated using total number of lessons, regardless of the selected issue period.`,
    's_certificate.calculated_based_on_the_total_lessons',
    ``,
    ``,
    ``,
    ``,
  ],
  name_of_representative: [`성 명`, `Representative`, 's_certificate.name_of_representative', ``, ``, ``, ``],
  calculated_including_all_completed_lessons: [
    `수강 기간 내 진행한 모든 수업을 포함하여 출석률을 산정합니다.
포함되는 수업권의 종류: 구매한 유료 수업권에서 파생된 수업권 (당일수업권, 보너스 수업권, 보상 수업권 등) 
마케팅 목적으로 제공된 수업권은 출석률에 포함되지 않습니다.`,
    `Counts all lessons completed within the lesson period.
Includes lessons booked using paid lesson credits (e.g. Last-minute Credits, Bonus Credits, Excusal Credits)
Lessons booked with credits provided with marketing or advertisement purposes are not included.`,
    's_certificate.calculated_including_all_completed_lessons',
    ``,
    ``,
    ``,
    ``,
  ],
  lessons_arttendance_percentage: [
    `수강 횟수 (출석률)`,
    `Lessons (Attendance rate)`,
    's_certificate.lessons_arttendance_percentage',
    ``,
    ``,
    ``,
    ``,
  ],
  lesson_period: [`수강기간`, `Lesson Period`, 's_certificate.lesson_period', ``, ``, ``, ``],
  student: [`수강자`, `Student`, 's_certificate.student', ``, ``, ``, ``],
  lesson_certificate: [`수강증`, `Lesson Certificate`, 's_certificate.lesson_certificate', ``, ``, ``, ``],
  lesson_certificate_issuance_history: [
    `수강증 발급 내역`,
    `Certificate issuance history`,
    's_certificate.lesson_certificate_issuance_history',
    ``,
    ``,
    ``,
    ``,
  ],
  number_of_installations_for_lesson: [
    `수강증 분할 발급 횟수`,
    `Certificate Count`,
    's_certificate.number_of_installations_for_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  issuance_number_will_be_deducted_once_lesson: [
    `수강증을 발급하시면, 증빙 횟수가 1회 차감되며 해당 구매 상품은 환불이 되지 않습니다. 동의하고 출력하려면 'PDF다운로드/출력' 을 눌러주시고, 그렇지 않다면 '아니오'를 눌러주세요.`,
    `If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used to take lessons for which you have issued certificates will become non-refundable. If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.`,
    's_certificate.issuance_number_will_be_deducted_once_lesson',
    ``,
    ``,
    ``,
    ``,
  ],
  number_of_completed_lessons_without_func: [
    `수강한 수업 수`,
    `Completed Lessons`,
    's_certificate.number_of_completed_lessons_without_func',
    ``,
    ``,
    ``,
    ``,
  ],
  lessons_count: [`수강횟수`, `Lessons`, 's_certificate.lessons_count', ``, ``, ``, ``],
  credits: [`수업권 정보`, `Credits`, 's_certificate.credits', ``, ``, ``, ``],
  no: [`아니오`, `No`, 's_certificate.no', ``, ``, ``, ``],
  select_language: [`언어 선택`, `Select Language`, 's_certificate.select_language', ``, ``, ``, ``],
  type_of_business: [`업 태`, `Type of Business`, 's_certificate.type_of_business', ``, ``, ``, ``],
  receipt: [`영수증`, `Receipt`, 's_certificate.receipt', ``, ``, ``, ``],
  issue_receipt: [`영수증 발급 안내`, `Issue Receipt`, 's_certificate.issue_receipt', ``, ``, ``, ``],
  cannot_be_issued_in_installments: [
    `영수증은 카드사를 통해 발급되어 분할 발급은 불가합니다.`,
    `Receipt is issued through the credit card company and cannot be issued in installments.`,
    's_certificate.cannot_be_issued_in_installments',
    ``,
    ``,
    ``,
    ``,
  ],
  english: [`영어`, `English`, 's_certificate.english', ``, ``, ``, ``],
  online_english_education: [
    `온라인 어학교육`,
    `Online English Education`,
    's_certificate.online_english_education',
    ``,
    ``,
    ``,
    ``,
  ],
  certify_that_the_above_mentioned_fact: [
    `* 본 화면은 미리 보기 용으로 증빙 효력이 없습니다.`,
    `* This screen is a preview and has no effect as a certificate.`,
    's_certificate.certify_that_the_above_mentioned_fact',
    ``,
    ``,
    ``,
    ``,
  ],
  representatives_names: [
    `이성파, 이승훈`,
    `Hoon Lee, Sungpah Lee`,
    's_certificate.representatives_names',
    ``,
    ``,
    ``,
    ``,
  ],
  days: [`일`, `days`, 's_certificate.days', ``, ``, ``, ``],
  contact_number: [`전화번호`, `Contact Number`, 's_certificate.contact_number', ``, ``, ``, ``],
  regular_lessons: [`정규수업 수강일`, `Regular Lessons`, 's_certificate.regular_lessons', ``, ``, ``, ``],
  items_of_nusiness: [`종 목`, `Items of Business`, 's_certificate.items_of_nusiness', ``, ``, ``, ``],
  issue_certificate: [`증명서 발급`, `Certificate`, 's_certificate.issue_certificate', ``, ``, ``, ``],
  enter_all_information_required: [
    `증명서 발급에 필요한 모든 정보를 입력해주세요.`,
    `Please enter all information required to issue the certificate.`,
    's_certificate.enter_all_information_required',
    ``,
    ``,
    ``,
    ``,
  ],
  restricted_from_using_the_function: [
    `수강증/출석증이 발급된 수업권은 수업권 변경 기능 이용이 제한됩니다.`,
    `Credits that have been used to take lessons for which you have issued certificates cannot be changed into other types of credits.`,
    's_certificate.restricted_from_using_the_function',
    ``,
    ``,
    ``,
    ``,
  ],
  certificates_not_be_eligible_for_refunds: [
    `수강증/출석증이 발급된 수업권은 환불 대상에서 제외됩니다.`,
    `No refund is offered for credits that have been used to take lessons for which you have issued certificates.`,
    's_certificate.certificates_not_be_eligible_for_refunds',
    ``,
    ``,
    ``,
    ``,
  ],
  select_the_language: [
    `증명서의 언어를 선택해주세요`,
    `Select the language for the certificate.`,
    's_certificate.select_the_language',
    ``,
    ``,
    ``,
    ``,
  ],
  maximum_of_issued_amount: [
    `증빙 가능 금액 :`,
    `Maximum of Issued Amount :`,
    's_certificate.maximum_of_issued_amount',
    ``,
    ``,
    ``,
    ``,
  ],
  select_the_issuance_period_again: [
    `증빙 가능한 기간을 초과하여 선택하셨습니다. 증빙 기간을 다시 선택해주세요.`,
    `You have exceeded the valid issue period. Please select the issuance period again.`,
    's_certificate.select_the_issuance_period_again',
    ``,
    ``,
    ``,
    ``,
  ],
  you_cannot_issue_on_the_day: [
    `증빙 가능한 기간이 아닙니다. 증빙 기간을 다시 선택해주세요. 1회 발급 시 최소 증빙 기간은 2일이며, 당일 증빙은 불가합니다.`,
    `It's not a valid period to issue. You cannot use today's date and the period should span at least two days.`,
    's_certificate.you_cannot_issue_on_the_day',
    ``,
    ``,
    ``,
    ``,
  ],
  issued_amount: [`증빙 금액`, `Issued Amount`, 's_certificate.issued_amount', ``, ``, ``, ``],
  issuance_period: [`증빙 기간`, `Issue Period`, 's_certificate.issuance_period', ``, ``, ``, ``],
  attendance_percentage_during_the_issue_period: [
    `증빙 기간 내 출석률`,
    `During the issue period`,
    's_certificate.attendance_percentage_during_the_issue_period',
    ``,
    ``,
    ``,
    ``,
  ],
  include_all_completed_lessons: [
    `진행한 수업 모두 포함하기 (e.g.당일수업권, 보상수업권, 보너스 수업권 등)`,
    <>
      Includes all completed lessons
      <br /> (e.g. Last-minute Credits, Bonus Credits, Excusal Credits, etc.)
    </>,
    's_certificate.include_all_completed_lessons',
    ``,
    ``,
    ``,
    ``,
  ],
  extra_lessons: [`추가 수업 수강일`, `Extra Lessons`, 's_certificate.extra_lessons', ``, ``, ``, ``],
  attendance_standard_of_lessons: [
    `출석 기준 수업 수`,
    `Eligible Lessons`,
    's_certificate.attendance_standard_of_lessons',
    ``,
    ``,
    ``,
    ``,
  ],
  lessons_cannot_be_entered_zero: [
    <>
      출석 기준 수업 수는 [남은 증빙 가능 수업 수~0] 회 이상으로 입력해야 합니다. <br />
      출석 기준 수업 수는 0회는 입력 불가합니다.
    </>,
    `Must be entered at least over [Number of classes that can be verified~0].`,
    's_certificate.lessons_cannot_be_entered_zero',
    ``,
    ``,
    ``,
    ``,
  ],
  attendance_percentage: [`출석률`, `Attendance Rate`, 's_certificate.attendance_percentage', ``, ``, ``, ``],
  standard_number_of_lessons: [
    `출석률 산정 시 기준이 되는 수업 수 입니다. 수강생이 직접 입력 가능하며, 최대 입력 가능한 수업 수는 구매한 수업 횟수입니다. (출석률=수강 수업 수/출석 기준 수업 수) <br/> 마지막 회차 증빙은 남은 출석기준 수업 수가 자동으로 산정되며, 이는 변경이 불가합니다.`,
    `This is the number of lessons used to calculate the attendance rate (Attendance rate = Completed lessons/Eligible lessons). Students can enter themselves, and the maximum number of lessons is the number of paid lessons. <br/> The number of Eligible lessons that are left will be calculated automatically on your last issuance, which cannot be reversed.`,
    's_certificate.standard_number_of_lessons',
    ``,
    ``,
    ``,
    ``,
  ],
  attendance_certificate: [`출석증`, `Attendance Certificate`, 's_certificate.attendance_certificate', ``, ``, ``, ``],
  attendance_certificate_issuance_history: [
    `출석증 발급 내역`,
    `Attendance certificate issuance history`,
    's_certificate.attendance_certificate_issuance_history',
    ``,
    ``,
    ``,
    ``,
  ],
  select_attendance_percentage: [
    `출석증 발급 시, 표기될 출석률을 선택해주세요`,
    `Select the rate you'd like to show on the certificate.`,
    's_certificate.select_attendance_percentage',
    ``,
    ``,
    ``,
    ``,
  ],
  number_of_installations_for_attendance: [
    `출석증 분할 발급 횟수`,
    `Certificate Count`,
    's_certificate.number_of_installations_for_attendance',
    ``,
    ``,
    ``,
    ``,
  ],
  issuance_number_will_be_deducted_once_lesson_attendance: [
    <>
      출석증을 발급하시면, 증빙 횟수가 1회 차감되며 해당 구매 상품은 환불이 되지 않습니다. <br />
      동의하고 출력하려면 'PDF다운로드/출력' 을 눌러주시고, 그렇지 않다면 '아니오'를 눌러주세요.
    </>,
    <>
      If you issue the certificate, the certificate count will be deducted by one, and the credits that have been used
      to take lessons for which you have issued certificates will become non-refundable. <br />
      If you wish to continue please click 'Download/Print PDF'. Otherwise click 'No.'{' '}
    </>,
    's_certificate.issuance_number_will_be_deducted_once_lesson_attendance',
    ``,
    ``,
    ``,
    ``,
  ],
  cash_receipts_kakao_naver: [
    `카카오페이/네이버페이 결제 시 현금영수증은 해당 플랫폼에서 신청 가능합니다.`,
    `Cash receipts can be applied on the platform when using Kakao Pay/Naver Pay.`,
    's_certificate.cash_receipts_kakao_naver',
    ``,
    ``,
    ``,
    ``,
  ],
  types_of_classes_included: [
    `포함되는 수업의 종류: 구매한 유료 수업권에서 파생된 수업권 (당일수업권, 보너스 수업권, 보상 수업권 등)`,
    `Types of classes included: Lessons derived from paid lesson credits (Last-minute Credits, Bonus Credits, Excusal Credits)`,
    's_certificate.types_of_classes_included',
    ``,
    ``,
    ``,
    ``,
  ],
  korean: [`한글`, `Korean`, 's_certificate.korean', ``, ``, ``, ``],
  you_cannot_change_in_future: [
    `현재 설정한 발급 횟수로 [수강증/출석증] 발급을 완료하면 차후 변경이 불가하오니 신중히 결정해주세요.`,
    `Please choose carefully as this setting cannot be reversed.`,
    's_certificate.you_cannot_change_in_future',
    ``,
    ``,
    ``,
    ``,
  ],
  confirm: [`확인`, `Confirm`, 's_certificate.confirm', ``, ``, ``, ``],
  paypal_kakao_payment_receipts: [
    `Paypal/카카오페이 결제 영수증은 사용하시는 PayPal/카카오페이 계정을 통해 확인 가능합니다.`,
    `PayPal/Kakao Pay payment receipts can be checked through your PayPal/Kakao Pay account.`,
    's_certificate.paypal_kakao_payment_receipts',
    ``,
    ``,
    ``,
    ``,
  ],
  can_be_viewed_from_website: [
    `Paypal/카카오페이 결제 영수증은 Paypal/ 카카오페이에서 직접 확인 가능합니다.`,
    `Receipt on PayPal/Kakao Pay can be viewed on PayPal/ Kakao Pay website.`,
    's_certificate.can_be_viewed_from_website',
    ``,
    ``,
    ``,
    ``,
  ],
  download_print_pdf: [
    `PDF 다운로드 / 출력`,
    `Download / Print PDF`,
    's_certificate.download_print_pdf',
    ``,
    ``,
    ``,
    ``,
  ],
  lessons: [`수업횟수`, `Lessons`, 's_certificate.lessons', ``, ``, ``, ``],
  payment_receipt_first: [`첫번째 영수증`, `Payment Receipt 1`, 's_certificate.payment_receipt_first', ``, ``, ``, ``],
  payment_receipt_second: [
    `두번째 영수증`,
    `Payment Receipt 2`,
    's_certificate.payment_receipt_second',
    ``,
    ``,
    ``,
    ``,
  ],
  cumulative_lessons: [`누적 수강횟수`, `Cumulative Lessons`, 's_certificate.cumulative_lessons', ``, ``, ``, ``],
  cumulative_attendance_percentage: [
    `누적 출석률`,
    `Cumulative Percentage`,
    's_certificate.cumulative_attendance_percentage',
    ``,
    ``,
    ``,
    ``,
  ],
  dropdown_certificates: [`회`, `certificate`, 's_certificate.dropdown_certificates', ``, ``, ``, ``],
  notification: [`알림`, `Notification`, 's_certificate.notification', ``, ``, ``, ``],
  enter_full_name: [
    `증명서 출력 시 [수강자명]이 비어 있는 경우 기본 정보 항목에 국문 이름을 입력해 주세요.`,
    `When printing lesson certificate, if [Name] is empty, enter your full name in the Basic Info tab.`,
    's_certificate.enter_full_name',
    ``,
    ``,
    ``,
    ``,
  ],
  set_full_name: [
    `수강증/출석증 내 표기되는 수강자명은 계정관리에서 설정할 수 있습니다. [한글 이름 수정] / [영문 이름 수정]`,
    `Your name appearing on lesson/attendance certificates can be changed in [My Account] > [Korean Name] / [English Name].`,
    's_certificate.set_full_name',
    ``,
    ``,
    ``,
    ``,
  ],
  name: [`수강생 정보`, `Student Information`, 's_certificate.name', ``, ``, ``, ``],
  name_notice: [
    `이름이 정확하게 입력되어 있는지 확인해 주세요.`,
    `Please enter your name`,
    's_certificate.name_notice',
    ``,
    ``,
    ``,
    ``,
  ],
  name_english: [`영어 이름`, `English Name`, 's_certificate.name_english', ``, ``, ``, ``],
  name_english_placeholder_1: [`First Name`, `First Name`, 's_certificate.name_english_placeholder_1', ``, ``, ``, ``],
  name_english_placeholder_2: [`Last Name`, `Last Name`, 's_certificate.name_english_placeholder_2', ``, ``, ``, ``],
  preview: [`미리보기`, `Preview`, 's_certificate.preview', ``, ``, ``, ``],
  fact_cerify: [
    `위의 수강 사실을 증명합니다.`,
    `This is to certify that the above mentioned fact is true.`,
    's_certificate.fact_cerify',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const s_transfer_credit = {
  title: [`틴즈로 수업권 이관`, `Transfer Lesson Credits to Ringle Teens`, 's_transfer_credit.title', ``, ``, ``, ``],
  terms: [`유의사항`, `Terms`, 's_transfer_credit.terms', ``, ``, ``, ``],
  select_package: [
    `이관할 패키지를 선택해주세요.`,
    `Select a package to transfer`,
    's_transfer_credit.select_package',
    ``,
    ``,
    ``,
    ``,
  ],
  select_number: [
    `이관할 수업권 개수를 선택해주세요.`,
    `Select number of credits`,
    's_transfer_credit.select_number',
    ``,
    ``,
    ``,
    ``,
  ],
  check_credits: [
    `이관 수업권 확인`,
    `Check the transferred credits`,
    's_transfer_credit.check_credits',
    ``,
    ``,
    ``,
    ``,
  ],
  plus: [`플러스`, `Plus`, 's_transfer_credit.plus', ``, ``, ``, ``],
  view_terms: [
    `틴즈 이용 약관 및 개인정보 처리방침 보기`,
    `View Terms of Service and Privacy Policy for Ringle Teens`,
    's_transfer_credit.view_terms',
    ``,
    ``,
    ``,
    ``,
  ],
  terms_title: [`수업권 변경 유의 사항`, `Terms`, 's_transfer_credit.terms_title', ``, ``, ``, ``],
  terms_agree: [
    `유의 사항, 틴즈 이용약관 및 개인정보 처리방침을 숙지 및 동의하였고, 이관을 진행하겠습니다.`,
    `I have understood and agree to this, and I would like to transfer my credits.`,
    's_transfer_credit.terms_agree',
    ``,
    ``,
    ``,
    ``,
  ],
  transfer: [`이관 진행`, `Transfer`, 's_transfer_credit.transfer', ``, ``, ``, ``],
  terms_detail: [
    <>
      수업권 이관은 수업 패키지 별 1회만 가능합니다. 이미 이관된 수업권은 원상복구할 수 없으니 신중히 선택하시기
      바랍니다!
      <br />
      수업권 이관 후, 적립되는 수업 포인트는 기존 구매한 수업권의 회당 금액의 3%로 적립됩니다. (수업권 이관 이후,
      제공되는 총 포인트는 동일함) 이관 후 링글 틴즈 내에서 사용하신 수업권에 대한 포인트는 링글에서 사용 가능합니다.
      <br />
      정규수업권 4개 소진 시, 1회 당일수업권이 제공되는 수업권은 4개 이관할 때마다 1회 당일 수업권이 링글 계정으로
      지급됩니다.
    </>,
    <>
      IMPORTANT - You can transfer credits only once per purchase. You cannot undo transferring credits. <br />
      Lesson points will be awarded at a 3% of the original purchased price. Points for lesson credits used within
      Ringle Teens will be accumulated and used within Ringle.
      <br />
      For the long-term package that provides a Last-minute Credit for every four regular lesson credits used, you'll be
      awarded one Last-minute Lesson Credit for every four Regular Lesson Credits you transfer. The Last-minute Lesson
      Credits will be awarded to your Ringle account.{' '}
    </>,
    's_transfer_credit.terms_detail',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_unassigned_lessons = {
  credits: {
    regular_credit: [`정규 수업권`, `Regular Credit`, 't_unassigned_lessons.credits.regular_credit', ``, ``, ``, ``],
    makeup_credit: [
      `보충 수업권`,
      `Last-minutes Lesson Credit`,
      't_unassigned_lessons.credits.makeup_credit',
      ``,
      ``,
      ``,
      ``,
    ],
    urgent_credit: [
      `긴급편성 수업권`,
      `FastBook Lesson Credit`,
      't_unassigned_lessons.credits.urgent_credit',
      ``,
      ``,
      ``,
      ``,
    ],
    no_credits_available: [
      `사용할 수 있는 수업권이 없습니다.`,
      `No credits available`,
      't_unassigned_lessons.credits.no_credits_available',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const s_b2b_credit_attandance = {
  b2b_titile: [
    `B2B 수업권 출석률`,
    `B2B Lessons Attendance Rate`,
    's_b2b_credit_attandance.b2b_titile',
    ``,
    ``,
    ``,
    ``,
  ],
  b2b_card_title: (name) => [
    <>{name} B2B 수업</>,
    <>{name} B2B Lessons</>,
    's_b2b_credit_attandance.b2b_card_title',
    ``,
    ``,
    ``,
    ``,
  ],
  attandance: [`출석률`, `Attendance Rate`, 's_b2b_credit_attandance.attandance', ``, ``, ``, ``],
  rate: (percent) => [<>{percent}%</>, <>{percent}%</>, 's_b2b_credit_attandance.rate', ``, ``, ``, ``],
  off_track: [`출석률 미달`, `Off Track`, 's_b2b_credit_attandance.off_track', ``, ``, ``, ``],
  see_detail: [`상세현황`, `See detail`, 's_b2b_credit_attandance.see_detail', ``, ``, ``, ``],
  desc1: [`출석 수업 수`, `Number of lessons attended`, 's_b2b_credit_attandance.desc1', ``, ``, ``, ``],
  count_1: (count) => [<>{count}</>, <>{count}</>, 's_b2b_credit_attandance.count_1', ``, ``, ``, ``],
  complete: (count) => [<>회 완료/{count}회</>, <>/{count}</>, 's_b2b_credit_attandance.complete', ``, ``, ``, ``],
  desc2: [`이수 기준 수업 수`, `Minimum number of lessons required`, 's_b2b_credit_attandance.desc2', ``, ``, ``, ``],
  count_2: (count) => [<>{count}</>, `{count}`, 's_b2b_credit_attandance.count_2', ``, ``, ``, ``],
  desc3: [`남은 수업권`, `Available`, 's_b2b_credit_attandance.desc3', ``, ``, ``, ``],
  regular_credits: (count) => [
    <>정규{count}회</>,
    <>Regular {count}</>,
    's_b2b_credit_attandance.regular_credits',
    ``,
    ``,
    ``,
    ``,
  ],
  free_credits: (count) => [
    <>당일{count}회</>,
    <>Last-minute {count}</>,
    's_b2b_credit_attandance.free_credits',
    ``,
    ``,
    ``,
    ``,
  ],
  desc4: [`수강 기간`, `Expires in`, 's_b2b_credit_attandance.desc4', ``, ``, ``, ``],
  count_3: (day) => [<>{day}</>, <>{day}</>, 's_b2b_credit_attandance.count_3', ``, ``, ``, ``],
  days: [`일 남음`, `days`, 's_b2b_credit_attandance.days', ``, ``, ``, ``],
  modal_title: [
    `출석률 계산법`,
    `Calculation of attendance rate`,
    's_b2b_credit_attandance.modal_title',
    ``,
    ``,
    ``,
    ``,
  ],
  modal_desc: [
    <>
      1. 수업 시간 50% 이상을 진행 해야만 출석으로 인정됩니다.
      <br />
      예) 40분 수업의 경우 20분 진행
      <br />
      <br />
      2. 해당 과정의 정규 수업권 및 당일 수업권으로 진행한 수업만 출석률 계산에 반영합니다.
      <br />
      보상 수업권*, 이전 과정에서 발급된 수업권, 개인적으로 구매한 수업권 등을 이용해 진행한 수업은 해당 과정의 출석률
      계산에 반영하지 않습니다.
      <br />
      <br />
      3. 튜터가 불가피한 사유로 수업 시작 24시간 이내 취소한 수업과 노쇼한 수업은 출석 인정됩니다.
      <br />
      <span className="text-[14px]">
        *보상 수업권은 튜터가 노쇼하거나 24시간 이내 취소한 수업, 링글 시스템 이슈로 원활하게 진행이 안된 수업 등에 대해
        보상의 의미로 지급하는 무료 수업권입니다.
      </span>
    </>,
    <>
      1. At least 50% of the lesson must be fulfilled for it to be considered completed and counted as attendance.
      Example) Take 20 minutes of a 40-minute lesson 2. The attendance rate is calculated based on the lessons completed
      using regular and last-minute credits issued for this course. Lessons completed using excusal credits*, credits
      issued for a different course, and personally-purchased credits, etc., are not counted towards the attendance
      rate. 3. Lessons that were not conducted due to tutor no-shows or cancellations within 24 hours of the scheduled
      lesson by the tutor are counted as attendance.
      <br />
      *Excusal credits are free credits provided as compensation for tutor no-show or last-minute cancellations, lessons
      that were disrupted due to technical issues in the Ringle system, etc."
    </>,
    's_b2b_credit_attandance.modal_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  btn: [`확인`, `OK`, 's_b2b_credit_attandance.btn', ``, ``, ``, ``],
}
export const s_b2b_credit_attandance_detail = {
  b2b_title: (name) => [
    <>{name} B2B 수업</>,
    <>{name} B2B Lessons</>,
    's_b2b_credit_attandance_detail.b2b_title',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle_1: (week) => [
    <>{week}주차 출석률 현황</>,
    <>Attendance rate as of {week} </>,
    's_b2b_credit_attandance_detail.subtitle_1',
    ``,
    ``,
    ``,
    ``,
  ],
  label_1: [`OFF Track`, `OFF Track`, 's_b2b_credit_attandance_detail.label_1', ``, ``, ``, ``],
  label_2: [`On Track`, `On Track`, 's_b2b_credit_attandance_detail.label_2', ``, ``, ``, ``],
  label_3: [`Complete!`, `Complete!`, 's_b2b_credit_attandance_detail.label_3', ``, ``, ``, ``],
  comment_1: (count) => [
    <>수강 첫 주입니다! 매주 {count}회 이상의 수업을 권장하고 있어요.</>,
    <>This is the first week of the course! We recommend you to take at least {count} lesson(s) each week.</>,
    's_b2b_credit_attandance_detail.comment_1',
    ``,
    ``,
    ``,
    ``,
  ],
  comment_2: [
    `잘하고 있어요!  꾸준히 수업하는 것이 영어 실력 향상의 지름길이에요.`,
    `You're doing great! Consistency is the key to improving your English.`,
    's_b2b_credit_attandance_detail.comment_2',
    ``,
    ``,
    ``,
    ``,
  ],
  comment_3: (count) => [
    <>기간 내 완강을 위해 {count}회 이상의 권장 수업 횟수를 채워주세요. </>,
    <>You should take at least {count} lesson(s) a week to complete all lessons in time.</>,
    's_b2b_credit_attandance_detail.comment_3',
    ``,
    ``,
    ``,
    ``,
  ],
  comment_4: [
    `완강을 축하드립니다! 노력한 만큼 영어 실력이 성장했을 거예요.`,
    `Congratulations on completing all of the lessons! We're sure your hard work has paid off.`,
    's_b2b_credit_attandance_detail.comment_4',
    ``,
    ``,
    ``,
    ``,
  ],
  bar_title: [
    `수업 완료 횟수`,
    `Number of lessons completed`,
    's_b2b_credit_attandance_detail.bar_title',
    ``,
    ``,
    ``,
    ``,
  ],
  my_attandance: (percent) => [
    <>현재 출석률 {percent}%</>,
    <>Current attendance rate: {percent}%</>,
    's_b2b_credit_attandance_detail.my_attandance',
    ``,
    ``,
    ``,
    ``,
  ],
  b2b_attandance: (week, percent) => [
    <>
      {week}주차 권장 출석률 {percent}%
    </>,
    <>
      Recommended attendance rate for Week {week}: {percent}%
    </>,
    's_b2b_credit_attandance_detail.b2b_attandance',
    ``,
    ``,
    ``,
    ``,
  ],
  bar_complete: (complete_count, count) => [
    <>
      {complete_count}회 완료/{count}회
    </>,
    <>
      {complete_count}/{count}Credits
    </>,
    's_b2b_credit_attandance_detail.bar_complete',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle_2: [`수업 예약하기`, `Book a Lesson`, 's_b2b_credit_attandance_detail.subtitle_2', ``, ``, ``, ``],
  desc1: (count) => [
    <>주당 {count}회 이상 수업 권장</>,
    <>You should complete at least {count} lessons per week</>,
    's_b2b_credit_attandance_detail.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  expire_day: (exp_days, deadline) => [
    <>
      수강기간: {exp_days} - {deadline}
    </>,
    <>
      Expires in: {exp_days} - {deadline}
    </>,
    's_b2b_credit_attandance_detail.expire_day',
    ``,
    ``,
    ``,
    ``,
  ],
  b2b_lessons_credit: (name) => [
    <>{name} 정규 수업권</>,
    <>{name}</>,
    's_b2b_credit_attandance_detail.b2b_lessons_credit',
    ``,
    ``,
    ``,
    ``,
  ],
  available_1: [`남은 횟수`, `Available`, 's_b2b_credit_attandance_detail.available_1', ``, ``, ``, ``],
  count_1: (count) => [<>{count}회</>, <>{count}</>, 's_b2b_credit_attandance_detail.count_1', ``, ``, ``, ``],
  btn_1: [`시간 먼저 선택`, `Sort by time`, 's_b2b_credit_attandance_detail.btn_1', ``, ``, ``, ``],
  btn_2: [`튜터 먼저 선택`, `Sort by tutor`, 's_b2b_credit_attandance_detail.btn_2', ``, ``, ``, ``],
  b2b_lessons_free_credit: [
    `B2B 당일 수업권(수업취소)`,
    `B2B Last-minute Credit(Student Cancellation)`,
    's_b2b_credit_attandance_detail.b2b_lessons_free_credit',
    ``,
    ``,
    ``,
    ``,
  ],
  available_2: [`남은 횟수`, `Available`, 's_b2b_credit_attandance_detail.available_2', ``, ``, ``, ``],
  count_2: (count) => [<>{count}회</>, <>{count}</>, 's_b2b_credit_attandance_detail.count_2', ``, ``, ``, ``],
  btn_3: [`당일 수업 예약`, `Last-minute Lessons`, 's_b2b_credit_attandance_detail.btn_3', ``, ``, ``, ``],
}
export const s_daily_brief = {
  notice: [
    `Daily Brief가 ‘새로나온 교재'로 병합되었습니다. 홈 화면의 '새로나온 교재'로 매일 신규 콘텐츠를 만나보세요!`,
    `Daily Brief has been merged with Latest Materials. Go to [Latest Materials] on the homepage to access new contents every day!`,
    's_daily_brief.notice',
    ``,
    ``,
    ``,
    ``,
  ],
  button: [`안내문 보기`, `Learn more`, 's_daily_brief.button', ``, ``, ``, ``],
}
export const s_landing_promo = {
  hero_promo: [
    `23년 영어 공부, 지금 준비하세요.`,
    `For you to get a jump-start on studying English`,
    's_landing_promo.hero_promo',
    ``,
    ``,
    ``,
    ``,
  ],
  promo_date1: [
    `22.12.23 (금) - 23.02.02 (목)`,
    `Dec 23, 2022 (Fri) - Feb 2, 2023 (Thu)`,
    's_landing_promo.promo_date1',
    ``,
    ``,
    ``,
    ``,
  ],
  promo_date2: [
    `23.01.06 (금) - 23.02.02 (목)`,
    `Jan 06, 2023 (Fri) - Feb 2, 2023 (Thu)`,
    's_landing_promo.promo_date2',
    ``,
    ``,
    ``,
    ``,
  ],
  label_earlybird: [`얼리버드 혜택`, `Early bird`, 's_landing_promo.label_earlybird', ``, ``, ``, ``],
  label_regular: [`신년 프로모션`, `New Year Promotion`, 's_landing_promo.label_regular', ``, ``, ``, ``],
  label_firstcome: [`선착순 마감`, `First come, first served`, 's_landing_promo.label_firstcome', ``, ``, ``, ``],
  label_recruiting: [`250명 선착순`, `250 spots only`, 's_landing_promo.label_recruiting', ``, ``, ``, ``],
  label_comingsoon: [`모집 전`, `Coming Soon`, 's_landing_promo.label_comingsoon', ``, ``, ``, ``],
  label_closed: [`모집 마감`, `Closed`, 's_landing_promo.label_closed', ``, ``, ``, ``],
  title1_mobile: [
    <>
      신년, 영어 정복을 위해
      <br />
      링글이 준비했습니다.
    </>,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
    's_landing_promo.title1_mobile',
    ``,
    ``,
    ``,
    ``,
  ],
  title1_pc: [
    `신년, 영어 정복을 위해 링글이 준비했습니다.`,
    <>
      Start Ringle
      <br />
      Stick with Your New Year's Resolutions
    </>,
    's_landing_promo.title1_pc',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle1: [
    `원어민과의 대화가 두렵다면?`,
    `Do you have English anxiety?`,
    's_landing_promo.subtitle1',
    ``,
    ``,
    ``,
    ``,
  ],
  desc1: [
    <>
      외국인과 편하게 대화하는 것이 모두의 목표죠.
      <br />
      영어 표현만 외우기보다는 원어민과 <br />
      실전을 연습해야 진짜 실력이 됩니다.
    </>,
    <>
      We all want to speak fluently with confidence.
      <br />
      Memorizing expressions by yourself doesn't help.
      <br />
      Practicing with a native speaker does.
    </>,
    's_landing_promo.desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  vedio_desc: [
    `소리를 켜고 실제 링글 수업을 확인해보세요!`,
    `Turn on the sound to watch how a Ringle class is taught!`,
    's_landing_promo.vedio_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  title3_pc: [
    `신년 영어 공부, 가장 저렴하게 준비하세요.`,
    `The Best Deals For Studying English Available Now.`,
    's_landing_promo.title3_pc',
    ``,
    ``,
    ``,
    ``,
  ],
  title3_mobile: [
    <>
      신년 영어 공부, <br />
      가장 저렴하게
      <br />
      준비하세요.
    </>,
    <>
      The Best Deals
      <br />
      For Studying English
      <br />
      Avalible Now.
    </>,
    's_landing_promo.title3_mobile',
    ``,
    ``,
    ``,
    ``,
  ],
  month: [`/월`, `/Month`, 's_landing_promo.month', ``, ``, ``, ``],
  nomal: [`정가`, `Regular:`, 's_landing_promo.nomal', ``, ``, ``, ``],
  won: [`원`, `KRW`, 's_landing_promo.won', ``, ``, ``, ``],
  discount: [`프로모션 할인가`, `Discounted:`, 's_landing_promo.discount', ``, ``, ``, ``],
  info_min: [`1회 수업 시간`, `Time per Lesson`, 's_landing_promo.info_min', ``, ``, ``, ``],
  info_count: [`수업 횟수`, `No. of credits`, 's_landing_promo.info_count', ``, ``, ``, ``],
  info_day: [`수강 기간`, `Expiration dates`, 's_landing_promo.info_day', ``, ``, ``, ``],
  min: [`분`, `Min`, 's_landing_promo.min', ``, ``, ``, ``],
  count: [`회`, ``, 's_landing_promo.count', ``, ``, ``, ``],
  day: [`일`, `Days`, 's_landing_promo.day', ``, ``, ``, ``],
  credit_desc: [
    `수업권은 수강기간 내 자유롭게 이용할 수 있습니다.`,
    `You can freely book the lesson at any date and time before the credits expire.`,
    's_landing_promo.credit_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  credit_desc2: [
    `100회 수업권은 25회 당일수업권이 추가 제공됩니다.`,
    `25 extra last-minute credits will be given`,
    's_landing_promo.credit_desc2',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit4_subtitle: [`링글이 처음이라면?`, `New to Ringle?`, 's_landing_promo.benefit4_subtitle', ``, ``, ``, ``],
  benefit4_title: [
    `신규회원 수업권 최대 3회 추가 증정`,
    <>
      Up to 3 free credits
      <br />
      for Newcomers
    </>,
    's_landing_promo.benefit4_title',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit5_subtitle: [
    `이미 링글 고객이라면?`,
    `Already a Ringler?`,
    's_landing_promo.benefit5_subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit5_title: [
    `보유 수업권 수강기간 연장 혜택`,
    <>
      Special coupon
      <br />
      for extending validity date
    </>,
    's_landing_promo.benefit5_title',
    ``,
    ``,
    ``,
    ``,
  ],
  title4_pc: [
    <>
      목표달성,
      <br />
      혼자서는 힘들어도 함께하면 가능해요.
    </>,
    <>
      Studying English
      <br />
      is not easy on your own.
      <br />
      Let's do it together.
    </>,
    's_landing_promo.title4_pc',
    ``,
    ``,
    ``,
    ``,
  ],
  title4_mobile: [
    <>
      목표달성,
      <br />
      혼자서는 힘들어도
      <br />
      함께하면 가능해요.
    </>,
    <>
      Studying English is not easy on your own.
      <br />
      Let's do it together.
    </>,
    's_landing_promo.title4_mobile',
    ``,
    ``,
    ``,
    ``,
  ],
  studyclub: [`추가혜택 1. 스터디클럽 2기`, `Study Club Round 2`, 's_landing_promo.studyclub', ``, ``, ``, ``],
  subtitle3: [
    <>
      실력을 만드는 영어 공부 습관,
      <br />
      4주 동안 함께 만들어요.
    </>,
    <>
      Study with the Ringle community
      <br />
      for 4 weeks, and create a lasting habit.
    </>,
    's_landing_promo.subtitle3',
    ``,
    ``,
    ``,
    ``,
  ],
  desc3: [
    <>
      1. 매주 1시간 다른 수강생들과 영어 스피킹 연습
      <br />
      2. 매주 1회 이상 링글 수업 의무 수강
      <br />
      3. 링글팀의 1:1 목표관리
    </>,
    <>
      1. One-hour practice sessions every week
      <br />
      2. One Ringle class every week
      <br />
      3. Personalized management will be provided by the Ringle Team
    </>,
    's_landing_promo.desc3',
    ``,
    ``,
    ``,
    ``,
  ],
  info1: [
    <>
      <b>참여 방법:</b>
      <br />
      프로모션 기간 내 정규수업권 구매 시 신청 가능
      <br />
      선착순 250명씩 총 4개 팀 운영 (총 1,000명)
    </>,
    <>
      <b>To join:</b>
      <br />
      Purchase a regular package during the event period <br />
      1000 spots (4 groups)available, first-come, first-served.
    </>,
    's_landing_promo.info1',
    ``,
    ``,
    ``,
    ``,
  ],
  info2: [
    <>
      <b>운영 기간:</b>
      <br />
      각 팀별 운영 기간 하단 참고
      <br />
    </>,
    <>
      <b>Program duration:</b>
      <br />
      See program duration for each group.
      <br />
    </>,
    's_landing_promo.info2',
    ``,
    ``,
    ``,
    ``,
  ],
  info3: [
    <>
      <b>운영 방식:</b>
      <br />
      주 1회 1시간씩 줌 화상 미팅으로
      <br />
      소그룹 영어 스피킹 연습 진행 (총 4주)
    </>,
    <>
      <b>Participation:</b>
      <br />
      1 hour weekly virtual sessions for four weeks.
      <br />
      Participants will be divided into small groups to practice speaking.
    </>,
    's_landing_promo.info3',
    ``,
    ``,
    ``,
    ``,
  ],
  info4: [
    <>
      <b>완주 조건:</b>
      <br />
      스터디 모임 3회 이상 참석, 수업 3회 이상 수강
      <br />
      미완주시 다음 스터디클럽 신청 불가
    </>,
    <>
      <b>Rules:</b>
      <br />
      Participants should attend at least 3 Study Club sessions and 3 Ringle 1:1 classes.
      <br />
      For those who don't meet these requirements won't be eligible for future Study Clubs.
    </>,
    's_landing_promo.info4',
    ``,
    ``,
    ``,
    ``,
  ],
  info5: [
    <>
      <b>완주자 혜택:</b>
      <br />
      40분 정규 수업 1년 패키지 (1명 추첨)
      <br />
      10만원 상당의 깜짝 선물 (10명 추첨)
      <br />
      오피스 이벤트 초대
      <br />
      수강생 네트워킹 오픈채팅방 초대
    </>,
    <>
      <b>Prize:</b>
      <br />
      One year plan (1 person)
      <br />
      A gift worth ₩100,000 (10 people)
      <br />
      Invitation to company events
      <br />
      Invitation to a networking group chat
    </>,
    's_landing_promo.info5',
    ``,
    ``,
    ``,
    ``,
  ],
  studyclub_apply: [`모집:`, `Apply:`, 's_landing_promo.studyclub_apply', ``, ``, ``, ``],
  studyclub_meetings: [`스터디 기간:`, `Meetings:`, 's_landing_promo.studyclub_meetings', ``, ``, ``, ``],
  btn_txt1: [
    `지금이 신년 영어 공부 준비하기 딱 좋은 타이밍 ⏰`,
    `The Perfect Time to Start Ringle! ⏰`,
    's_landing_promo.btn_txt1',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_txt2: [`링글 신년 프로모션`, `New Year Promotion`, 's_landing_promo.btn_txt2', ``, ``, ``, ``],
  desc5_pc: [
    <>
      상황별로 어떻게 링글을 활용하면 되는지, 어떻게 영어공부 해야하는지 궁금하신가요?
      <br />
      <b>링글팀과 이야기 나눠보세요.</b>
    </>,
    ``,
    's_landing_promo.desc5_pc',
    ``,
    ``,
    ``,
    ``,
  ],
  title6_mobile: [
    <>
      아직 망설여지나요?
      <br />
      링글팀과 직접 상담하세요.
    </>,
    ``,
    's_landing_promo.title6_mobile',
    ``,
    ``,
    ``,
    ``,
  ],
  desc5_mobile: [
    <>
      상황별로 어떻게 링글을 활용하면 되는지,
      <br />
      어떻게 영어공부 해야하는지 궁금하신가요?
      <br />
      <br />
      <b>링글팀과 이야기 나눠보세요.</b>
    </>,
    ``,
    's_landing_promo.desc5_mobile',
    ``,
    ``,
    ``,
    ``,
  ],
  btn8: [`궁금한 점을 남겨주세요`, ``, 's_landing_promo.btn8', ``, ``, ``, ``],
  tab1: [`23년 최저가`, `Best Deal`, 's_landing_promo.tab1', ``, ``, ``, ``],
  tab2: [`스터디클럽`, `Study Club`, 's_landing_promo.tab2', ``, ``, ``, ``],
  tab3: [`보스턴 챌린지`, `Challenge`, 's_landing_promo.tab3', ``, ``, ``, ``],
  benefit1_subtitle: [
    `가장 저렴하게 신년 영어공부 준비`,
    `Our Lowest Prices in 2023`,
    's_landing_promo.benefit1_subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit1_title: [`최대 38% 할인`, `Up to 38% off`, 's_landing_promo.benefit1_title', ``, ``, ``, ``],
  benefit2_subtitle: [
    `신규회원 구매 시`,
    `Special Offer for Newcomers`,
    's_landing_promo.benefit2_subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit2_title: [
    `수업권 최대 3회 추가 증정`,
    `Up to 3 free credits`,
    's_landing_promo.benefit2_title',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit3_subtitle: [
    `링글러들과 함께 목표 달성`,
    `Learn with Ringlers`,
    's_landing_promo.benefit3_subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit3_title: [`스터디클럽 2기`, `Study Club Season 2`, 's_landing_promo.benefit3_title', ``, ``, ``, ``],
  d_day_early: [`프로모션 마감까지`, `Early Bird Ends In:`, 's_landing_promo.d_day_early', ``, ``, ``, ``],
  regular: [`신년 프로모션 할인 마감까지`, `Promotion Ends In:`, 's_landing_promo.regular', ``, ``, ``, ``],
  cowntdown: [`마감까지`, `Ends in`, 's_landing_promo.cowntdown', ``, ``, ``, ``],
  hrs: [`시간`, `Hours`, 's_landing_promo.hrs', ``, ``, ``, ``],
  mins: [`분`, `Minutes`, 's_landing_promo.mins', ``, ``, ``, ``],
  secs: [`초`, `Seconds`, 's_landing_promo.secs', ``, ``, ``, ``],
  d_day_txt1: [
    <>
      오직 프로모션에서만 만나볼 수 있는
      <br />
      전체 수업권 할인 프로모션
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
    's_landing_promo.d_day_txt1',
    ``,
    ``,
    ``,
    ``,
  ],
  d_day_txt2: [
    <>
      오직 프로모션에서만 만나볼 수 있는
      <br />
      전체 수업권 할인 프로모션
    </>,
    <>
      Our Special New Year's Promotion <br />
      Discounts on All Credits
    </>,
    's_landing_promo.d_day_txt2',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle_1: [`초중급자를 위한 링글`, `For Beginners/Intermediates`, 's_landing_promo.subtitle_1', ``, ``, ``, ``],
  subtitle_2: [`중상급자를 위한 링글`, `For Advanced Learners`, 's_landing_promo.subtitle_2', ``, ``, ``, ``],
  notice: {
    title3: [
      `[영어공부 목표 공유하기 이벤트]`,
      `[New Year's Resolution Event]`,
      's_landing_promo.notice.title3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc3: [
      <>
        1. 이벤트 기간: 22.12.28 ~ 23.02.02
        <br />
        2. 혜택 대상자 안내: 23.02.10(금)에 스타벅스 기프티콘 일괄 지급 / 링글 수업권 4회 당첨자는 개별 연락
        <br />
        3. 참여 방법: 프로모션 기간 중 카톡으로 목표 공유하기 버튼으로 카카오 채팅방에 목표를 공유 하면 성공 / 공유한
        횟수에 따라 혜택 차등 지급
        <br />
        4. 유의사항: 동일한 채팅방에 2회 이상 공유시 1회만 횟수로 인정됩니다. / 나와의 채팅방에 공유시 인정되지
        않습니다. / 부정한 방법으로 이벤트 참여 이력이 확인되는 경우 선물이 지급되지 않습니다.
      </>,
      <>
        1. Period: Dec 28, 2022 - Feb 02, 2023
        <br />
        2. Rewards: Starbucks gift coupons will be sent to all eligible users at the end of the month / Winner of 4
        lesson credits will be notified after Feb 5, 2023.
        <br />
        3. How to Participate: Send your study goals to a KakaoTalk chatroom during the event period using the 'Share'
        button / Rewards given based on number of shares <br />
        4. Terms: Study goals must be sent to a different chatroom each time to be counted toward the event. / Sharing
        to the same chatroom will only be counted once./ Sharing to yourself on KakaoTalk will not be counted.
      </>,
      's_landing_promo.notice.desc3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  title7_pc: [
    <>
      영어 공부 목표를
      <br />
      카톡으로 소문내면 선물이 커져요!
    </>,
    <>
      The More You Share,
      <br />
      The Bigger Your Rewards!
    </>,
    's_landing_promo.title7_pc',
    ``,
    ``,
    ``,
    ``,
  ],
  title7_mobile: [
    <>
      영어 공부 목표를
      <br />
      카톡으로 소문내면
      <br />
      선물이 커져요!
    </>,
    <>
      Spread the Word
      <br />
      About Your Study Goals
      <br />
      And Get Rewards!
    </>,
    's_landing_promo.title7_mobile',
    ``,
    ``,
    ``,
    ``,
  ],
  btn9: [`목표 공유하기`, `Share Your Study Goals`, 's_landing_promo.btn9', ``, ``, ``, ``],
  subtitle7: [`참여 혜택`, `Rewards`, 's_landing_promo.subtitle7', ``, ``, ``, ``],
  share: {
    three_times: [
      <>
        <span className="text-[#FF6712]">3회</span> 이상 공유 시
      </>,
      <>
        Share <span className="text-[#FF6712]">3 times</span>
      </>,
      's_landing_promo.share.three_times',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle1: [
      `추운 겨울에 딱 좋은`,
      `To fend off the winter cold`,
      's_landing_promo.share.subtitle1',
      ``,
      ``,
      ``,
      ``,
    ],
    title1: [
      <>
        스타벅스
        <br />
        기프티콘 1장
      </>,
      <>
        Starbucks
        <br />1 Gift Coupon
      </>,
      's_landing_promo.share.title1',
      ``,
      ``,
      ``,
      ``,
    ],
    six_times: [
      <>
        <span className="text-[#FF6712]">6회</span> 이상 공유 시
      </>,
      <>
        Share <span className="text-[#FF6712]">6 times</span>
      </>,
      's_landing_promo.share.six_times',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle2: [`이미 받은 1장에`, `Let's double the stakes!`, 's_landing_promo.share.subtitle2', ``, ``, ``, ``],
    title2: [
      <>
        스타벅스
        <br />
        기프티콘 추가로 2장 더
      </>,
      <>
        Starbucks
        <br />
        1+2 Gift Coupons
      </>,
      's_landing_promo.share.title2',
      ``,
      ``,
      ``,
      ``,
    ],
    ten_times: [
      <>
        <span className="text-[#FF6712]">10회</span> 이상 공유 시
      </>,
      <>
        Share <span className="text-[#FF6712]">10 times</span>
      </>,
      's_landing_promo.share.ten_times',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle3: [`위 모든 혜택에`, `All the rewards above PLUS`, 's_landing_promo.share.subtitle3', ``, ``, ``, ``],
    title3: [
      <>
        링글 수업 4회권
        <br />
        당첨 기회까지
        <br />
      </>,
      <>
        A Chance to Win
        <br />
        4 Lesson Credits
        <br />
      </>,
      's_landing_promo.share.title3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [`(총 2명)`, `(Limited to two winners)`, 's_landing_promo.share.desc', ``, ``, ``, ``],
  },
  card_subtitle: [`2023년 영어 공부 목표`, `Study Goals for 2023`, 's_landing_promo.card_subtitle', ``, ``, ``, ``],
  card_title1: [
    <>
      umm...
      <br />
      나 올훼 한국말
      <br />몬 해도 놀라지 뫄
    </>,
    <>
      I will format my brain
      <br />
      and install a native
      <br />
      English speaker OS.
    </>,
    's_landing_promo.card_title1',
    ``,
    ``,
    ``,
    ``,
  ],
  card_title2: [
    <>
      한 30년 한국말 하니까
      <br />
      좀 지겹더라구...
      <br />
      영어로 넘어가게
    </>,
    <>
      English will become
      <br />
      the new mother of
      <br />
      my tongue this year.
    </>,
    's_landing_promo.card_title2',
    ``,
    ``,
    ``,
    ``,
  ],
  card_title3: [
    <>
      영유 다니는 조카보다는
      <br />
      잘해보려구
    </>,
    <>
      I will speak English
      <br />
      better than my
      <br />
      5-year-old nephew.
    </>,
    's_landing_promo.card_title3',
    ``,
    ``,
    ``,
    ``,
  ],
  card_title4: [
    <>
      보여줄게
      <br />
      완전히 달라진
      <br />
      (영어 잘하는) 나
    </>,
    <>
      I'll take my English <br />
      to the next level.
    </>,
    's_landing_promo.card_title4',
    ``,
    ``,
    ``,
    ``,
  ],
  card_title5: [
    <>
      실리콘밸리에 먼저
      <br />
      자리 잡고 있을게
    </>,
    <>
      I'll be waiting <br />
      in Silicon Valley <br />
      if you need me.
    </>,
    's_landing_promo.card_title5',
    ``,
    ``,
    ``,
    ``,
  ],
  card_title6: [
    <>
      이제 파X고랑
      <br />
      헤어지려고
    </>,
    <>
      I'm gonna end
      <br />
      my relationship with
      <br />
      translation apps.
    </>,
    's_landing_promo.card_title6',
    ``,
    ``,
    ``,
    ``,
  ],
  label_earlybird_2: [`할인 혜택`, `Discounts`, 's_landing_promo.label_earlybird_2', ``, ``, ``, ``],
  label_earlybird_3: [`프로모션 특가`, `Promotional Deal`, 's_landing_promo.label_earlybird_3', ``, ``, ``, ``],
  challenge_info1: [
    <>
      <b>투어 내용:</b>
      <br />
      - 항공권 + 숙박 + 프로그램 비용 포함
      <br />
      - 글로벌 테크/서비스 기업 오피스 투어 및 현직자 네트워킹
      <br />- Harvard 등에서 재학 중인 튜터와의 만남
    </>,
    <>
      <b>Program Details:</b>
      <br />
      - A round-trip plane ticket, accommodation, and other program costs
      <br />
      - A guided tour of global companies and networking opportunities with their employees
      <br />- Meet-and-greets with Harvard tutors
    </>,
    's_landing_promo.challenge_info1',
    ``,
    ``,
    ``,
    ``,
  ],
  challenge_info2: [
    <>
      <b>챌린지 기간:</b> 23.01.06 ~ 23.04.30
    </>,
    <>
      <b>Duration:</b> Jan 6 (Fri) ~ Apr 30 (Sun)
    </>,
    's_landing_promo.challenge_info2',
    ``,
    ``,
    ``,
    ``,
  ],
  challenge_info3: [
    <>
      <b>참여 방법:</b>
      <br />
      - 챌린지 기간 내 20분 정규수업 32회, 40분 정규수업 24회 이상 정상 수강 시 챌린지 완주
      <br />
      - 챌린지 완주자에 한해 보스턴&뉴욕 여행 지원 자격 부여
      <br />
      <br />
      자세한 내용은 하단 링크 통해서 상세 페이지 확인
    </>,
    <>
      <b>Eligibility:</b>
      <br />
      - Complete 32 lessons (20-min) or 24 lessons (40-min)
      <br />
      - Only those who complete the challenge are eligible to apply for the Boston&NY trip
      <br />
      <br />
      For more details, please refer to the link below
    </>,
    's_landing_promo.challenge_info3',
    ``,
    ``,
    ``,
    ``,
  ],
  hero_promo_moW: [
    `23년 영어 공부, 지금 준비하세요.`,
    `Get Ready to Upgrade Your English`,
    's_landing_promo.hero_promo_moW',
    ``,
    ``,
    ``,
    ``,
  ],
  btn_update: [`새로고침`, `Update`, 's_landing_promo.btn_update', ``, ``, ``, ``],
  subtitle6_desc1: [
    `[목표 공유하기 버튼]으로 공유한 경우만 인정돼요.`,
    `Only shares via the [Share Your Study Goals] button are counted.`,
    's_landing_promo.subtitle6_desc1',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle6_desc2: [
    `나와의 채팅방/같은 채팅방에 여러 번 공유는 안 돼요.`,
    `Sharing to yourself or to the same chatroom do not count.`,
    's_landing_promo.subtitle6_desc2',
    ``,
    ``,
    ``,
    ``,
  ],
  subtitle6_desc3: [
    `횟수가 안 올라간다면 새로고침 버튼을 눌러주세요.`,
    `To update share count, click the [Update] button.`,
    's_landing_promo.subtitle6_desc3',
    ``,
    ``,
    ``,
    ``,
  ],
  label_endingsoon: [`마감 임박`, `Ending Soon`, 's_landing_promo.label_endingsoon', ``, ``, ``, ``],
  benefit6_subtitle: [
    `튜터도 만나고 글로벌 회사 탐방까지`,
    `Meet Tutors and Visit Global Companies`,
    's_landing_promo.benefit6_subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit6_title: [`보스턴&뉴욕 챌린지 3기`, `Boston&NYC Challenge`, 's_landing_promo.benefit6_title', ``, ``, ``, ``],
  benefit7_subtitle: [
    `공유할수록 선물이 더 커지는`,
    `Share Your Study Goals`,
    's_landing_promo.benefit7_subtitle',
    ``,
    ``,
    ``,
    ``,
  ],
  benefit7_title: [`영어 목표 공유 이벤트`, `New Year's Event`, 's_landing_promo.benefit7_title', ``, ``, ``, ``],
  title8: {
    mobile: [
      <>
        링글이 처음이라면? <br />
        수업권 최대 3회 <br />
        받아가세요.
      </>,
      <>
        New to Ringle? <br />
        Up to 3 free credits
        <br />
        for Newcomers
      </>,
      's_landing_promo.title8.mobile',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  desc6: [
    <>
      <li className="li-tag-inside">가입 즉시 수업권 추가 증정 쿠폰 발급</li>
      <li className="li-tag-inside">12회 이상 수업권부터 적용 가능</li>
      <li className="li-tag-inside">수업권 별로 차등 적용</li>
    </>,
    <>
      <li className="li-tag-inside">Join Now and Immediately Get an Extra Credit Coupon</li>
      <li className="li-tag-inside">Redeemable for packages including 12 credits or more</li>
      <li className="li-tag-inside">Number of extra credits vary by credit package</li>
    </>,
    's_landing_promo.desc6',
    ``,
    ``,
    ``,
    ``,
  ],
  label_regular_2: [`기간 한정`, `Season`, 's_landing_promo.label_regular_2', ``, ``, ``, ``],
  welcome_btn: [`지금 신규회원 쿠폰 확인하기`, `View more`, 's_landing_promo.welcome_btn', ``, ``, ``, ``],
  welcome_title: [`신규회원 한정 혜택`, `Special Offer for Newcomers`, 's_landing_promo.welcome_title', ``, ``, ``, ``],
}
export const s_bn_challenge = {
  hero: {
    title: [`링글 보스턴&뉴욕 챌린지`, `Ringle Boston & NYC Challenge`, 's_bn_challenge.hero.title', ``, ``, ``, ``],
    subtitle_1: [
      `더 이상 영어 공부를 미룰 수 없는 당신을 위해`,
      `For you to get a jump-start on studying English`,
      's_bn_challenge.hero.subtitle_1',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_2: [
      `링글과 함께하는 세 번째 도전`,
      `The 3rd Challenge with Ringle`,
      's_bn_challenge.hero.subtitle_2',
      ``,
      ``,
      ``,
      ``,
    ],
    date_start: [`1. 6(금)`, `Jan 6 (Fri)`, 's_bn_challenge.hero.date_start', ``, ``, ``, ``],
    date_end: [`4. 30 (일)`, `Apr 30 (Sun)`, 's_bn_challenge.hero.date_end', ``, ``, ``, ``],
    participants: (number) => [
      <>{number}명 참여중</>,
      <>{number} challengers</>,
      's_bn_challenge.hero.participants',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_1: [`챌린지 참여하기`, `Join the Challenge`, 's_bn_challenge.hero.btn_1', ``, ``, ``, ``],
    btn_2: [`챌린지 참여 중`, `Challenge in Progress`, 's_bn_challenge.hero.btn_2', ``, ``, ``, ``],
    btn_3: [`챌린지 종료`, `Challenge Closed`, 's_bn_challenge.hero.btn_3', ``, ``, ``, ``],
  },
  modal: {
    title: [`보스턴&뉴욕 챌린지 참여하기`, `Join the Challenge`, 's_bn_challenge.modal.title', ``, ``, ``, ``],
    desc: [
      `[챌린지 참여하기]를 클릭하면 챌린지 관련 정보 전달을 위해 [이벤트/혜택] 알림 수신이 켜집니다.`,
      `Clicking [Join the Challenge] will turn on [Event & offers] notifications to send you information about the challenge.`,
      's_bn_challenge.modal.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_1: [`취소`, `Cancel`, 's_bn_challenge.modal.btn_1', ``, ``, ``, ``],
    btn_2: [`챌린지 참여하기`, `Join the Challenge`, 's_bn_challenge.modal.btn_2', ``, ``, ``, ``],
  },
  progress: {
    title: [`나의 챌린지 현황`, `My Challenge Board`, 's_bn_challenge.progress.title', ``, ``, ``, ``],
    toggle_40: [`40분`, `40 min`, 's_bn_challenge.progress.toggle_40', ``, ``, ``, ``],
    toggle_20: [`20분`, `20 min`, 's_bn_challenge.progress.toggle_20', ``, ``, ``, ``],
    subtitle_1: [`챌린지 목표`, `The Challenge`, 's_bn_challenge.progress.subtitle_1', ``, ``, ``, ``],
    number_1_40: (number) => [
      <>{number} /24회 수업</>,
      <>{number} /24 lessons</>,
      's_bn_challenge.progress.number_1_40',
      ``,
      ``,
      ``,
      ``,
    ],
    number_1_20: (number) => [
      <>{number} /32회 수업</>,
      <>{number} /32 lessons</>,
      's_bn_challenge.progress.number_1_20',
      ``,
      ``,
      ``,
      ``,
    ],
    number_1_complete: [`챌린지 달성!`, `Completed!`, 's_bn_challenge.progress.number_1_complete', ``, ``, ``, ``],
    number_2_40: (number) => [
      <>{number} /6회 수업</>,
      <>{number} /6 lessons</>,
      's_bn_challenge.progress.number_2_40',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_2: [`월별 수업 미션`, `Monthly Mission`, 's_bn_challenge.progress.subtitle_2', ``, ``, ``, ``],
    number_2_20: (number) => [
      <>{number} /8회 수업</>,
      <>{number} /8 lessons</>,
      's_bn_challenge.progress.number_2_20',
      ``,
      ``,
      ``,
      ``,
    ],
    number_2_complete: [`미션 성공`, `Completed!`, 's_bn_challenge.progress.number_2_complete', ``, ``, ``, ``],
    subtitle_3: [`함께 도전중인 링글러`, `Total Challengers`, 's_bn_challenge.progress.subtitle_3', ``, ``, ``, ``],
    number_3: (number) => [<>{number}명</>, <>{number} Ringlers</>, 's_bn_challenge.progress.number_3', ``, ``, ``, ``],
    point_gained: [`받은 포인트`, `Points received`, 's_bn_challenge.progress.point_gained', ``, ``, ``, ``],
    point_40: (point) => [
      <>{point}/35,000 P</>,
      <>{point}/26.00P</>,
      's_bn_challenge.progress.point_40',
      ``,
      ``,
      ``,
      ``,
    ],
    point_20: (point) => [
      <>{point}/35,000 P</>,
      <>{point}/26.00P</>,
      's_bn_challenge.progress.point_20',
      ``,
      ``,
      ``,
      ``,
    ],
    date_end: [
      `챌린지 종료일 2023년 4월 30일 KST`,
      `Challenge closes on Apr 30, 2023 (KST)`,
      's_bn_challenge.progress.date_end',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_1: [`포인트 받기`, `Get points`, 's_bn_challenge.progress.btn_1', ``, ``, ``, ``],
    btn_2: [`적립 완료`, `Awarded`, 's_bn_challenge.progress.btn_2', ``, ``, ``, ``],
    btn_500: [`500P 받기`, `Get points`, 's_bn_challenge.progress.btn_500', ``, ``, ``, ``],
    btn_1000: [`1000P 받기`, `Get points`, 's_bn_challenge.progress.btn_1000', ``, ``, ``, ``],
    btn_1500: [`1500P 받기`, `Get points`, 's_bn_challenge.progress.btn_1500', ``, ``, ``, ``],
    btn_2000: [`2000P 받기`, `Get points`, 's_bn_challenge.progress.btn_2000', ``, ``, ``, ``],
    btn_2500: [`2500P 받기`, `Get points`, 's_bn_challenge.progress.btn_2500', ``, ``, ``, ``],
    btn_3000: [`3000P 받기`, `Get points`, 's_bn_challenge.progress.btn_3000', ``, ``, ``, ``],
    btn_3500: [`3500P 받기`, `Get points`, 's_bn_challenge.progress.btn_3500', ``, ``, ``, ``],
    btn_4000: [`4000P 받기`, `Get points`, 's_bn_challenge.progress.btn_4000', ``, ``, ``, ``],
    complete: [`신청서 제출`, `Submit Form`, 's_bn_challenge.progress.complete', ``, ``, ``, ``],
    btn_point: (point) => [<>{point}P 받기</>, `Get points`, 's_bn_challenge.progress.btn_point', ``, ``, ``, ``],
  },
  extra_mission: {
    title: [`추가 미션도 놓치지 마세요!`, `Extra missions!`, 's_bn_challenge.extra_mission.title', ``, ``, ``, ``],
    subtitle_1: [
      `2023 영어 목표 남기기`,
      `Submit Your 2023 Study Goals`,
      's_bn_challenge.extra_mission.subtitle_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_1: [
      `전원) 5,000 포인트 100% 증정, 추첨 100명) 스타벅스 기프티콘 증정`,
      `All) $4.00P, Raffle) Starbucks gift card for 100 winners`,
      's_bn_challenge.extra_mission.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_2: [
      `SNS 후기 인증하기`,
      `Post a Review on SNS`,
      's_bn_challenge.extra_mission.subtitle_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_2: [
      `최대 100명) 2만원 교보문고 상품권 증정, 추첨 5명) 20분 수업권 3회 증정`,
      `100 Reviews) 20,000 won Kyobobook gift card, Raffle) 3 free 20-min credits`,
      's_bn_challenge.extra_mission.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_1: [`남기기`, `Submit`, 's_bn_challenge.extra_mission.btn_1', ``, ``, ``, ``],
    btn_2: [`인증하기`, `Upload`, 's_bn_challenge.extra_mission.btn_2', ``, ``, ``, ``],
    btn3: [`완료`, `Complete`, 's_bn_challenge.extra_mission.btn3', ``, ``, ``, ``],
    btn4: [`인증완료`, `Complete`, 's_bn_challenge.extra_mission.btn4', ``, ``, ``, ``],
  },
  goal: {
    title: [
      `2023년 영어를 정복한다면 무엇을 하고 싶나요?`,
      `What are your dreams after mastering English in 2023?`,
      's_bn_challenge.goal.title',
      ``,
      ``,
      ``,
      ``,
    ],
    title_input: [
      `지금 영어 목표를 남기고 현실로 만들어봐요!`,
      `Submit your study goals and make it come true!`,
      's_bn_challenge.goal.title_input',
      ``,
      ``,
      ``,
      ``,
    ],
    placeholder: [
      `한 번 남긴 다짐은 수정할 수 없어요. (최소 10자)`,
      `Please note that you cannot edit or delete after submitting. (Min. 10 characters)`,
      's_bn_challenge.goal.placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_1: [`남기기`, `Submit`, 's_bn_challenge.goal.btn_1', ``, ``, ``, ``],
    title_participate: [
      `챌린지 참여 후 나의 영어 목표를 남길 수 있어요.`,
      `You can submit your study goals after joining the challenge.`,
      's_bn_challenge.goal.title_participate',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_2: [`챌린지 참여하기`, `Join the Challenge`, 's_bn_challenge.goal.btn_2', ``, ``, ``, ``],
    reward_1: [
      `영어 목표를 남기면 5,000 포인트를 드려요.`,
      `Get 4.00P by submitting your study goals.`,
      's_bn_challenge.goal.reward_1',
      ``,
      ``,
      ``,
      ``,
    ],
    reward_2: [
      `100분을 추첨하여 스타벅스 기프티콘도 드려요.`,
      `And enter the Starbucks gift card raffle (100 winners).`,
      's_bn_challenge.goal.reward_2',
      ``,
      ``,
      ``,
      ``,
    ],
    title_my_box: [`나의 영어 목표`, `My Study Goals`, 's_bn_challenge.goal.title_my_box', ``, ``, ``, ``],
    title_box: (number) => [
      <>{number}번째 영어 목표</>,
      <>Study Goal {number}</>,
      's_bn_challenge.goal.title_box',
      ``,
      ``,
      ``,
      ``,
    ],
    title_admin: [
      `링글팀이 선정한 최고의 영어 목표!`,
      `Ringle Team's Choice`,
      's_bn_challenge.goal.title_admin',
      ``,
      ``,
      ``,
      ``,
    ],
    view_more: [`더 보기`, `See more`, 's_bn_challenge.goal.view_more', ``, ``, ``, ``],
    msg_error: [
      `10자 이상 200자 이내로 입력해 주세요.`,
      `Please enter between 10 and 200 characters.`,
      's_bn_challenge.goal.msg_error',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  last_challenge: {
    title: [
      `링글 실리콘밸리 챌린지 1기`,
      `Ringle Silicon Valley Challenge`,
      's_bn_challenge.last_challenge.title',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle: [
      `이전 챌린지 돌아보기`,
      `Take a look at previous challenges`,
      's_bn_challenge.last_challenge.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_1: [`챌린지 영상 더 보기`, `View more`, 's_bn_challenge.last_challenge.btn_1', ``, ``, ``, ``],
    btn_2: [`챌린지 1기 인터뷰 읽기`, `Read more interviews`, 's_bn_challenge.last_challenge.btn_2', ``, ``, ``, ``],
  },
  share: {
    title: [
      <>
        혼자 공부하기는 외롭잖아요.
        <br />
        지금 친구와 같이 도전해 볼까요?
      </>,
      <>
        It can get lonely studying alone.
        <br />
        Invite a friend to join the challenge!
      </>,
      's_bn_challenge.share.title',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle: [
      `링글 보스턴&뉴욕 챌린지 공유하기`,
      `Spread the word about the Boston & NYC Challenge`,
      's_bn_challenge.share.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  floating: {
    title_1: [
      `챌린지에 참여만 해도 100% 혜택을 드려요.`,
      `Get rewards just by joining the challenge.`,
      's_bn_challenge.floating.title_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title_2: [
      `꾸준함은 영어를 극복하는 가장 빠른 길입니다!`,
      `Consistency is the key to mastering English!`,
      's_bn_challenge.floating.title_2',
      ``,
      ``,
      ``,
      ``,
    ],
    title_3: [
      `지금이 영어 공부하기 가장 좋은 타이밍!`,
      `Now is the best time to study English!`,
      's_bn_challenge.floating.title_3',
      ``,
      ``,
      ``,
      ``,
    ],
    title_4: [
      `챌린지 달성을 축하드려요! 🎉`,
      `Congrats on completing the challenge! 🎉`,
      's_bn_challenge.floating.title_4',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_1: [`챌린지 참여하기`, `Join the Challenge`, 's_bn_challenge.floating.btn_1', ``, ``, ``, ``],
    btn_2: [`수업 예약하기`, `Book a lesson`, 's_bn_challenge.floating.btn_2', ``, ``, ``, ``],
    btn_3: [`수업권 구매하기`, `Purchase credits`, 's_bn_challenge.floating.btn_3', ``, ``, ``, ``],
    subtitle: [
      `[나의 챌린지 현황] > [신청서 제출]을 클릭해 2023년 5월 10일 (수) 23:59PM까지 신청서를 작성해 주세요.`,
      `Please submit the form for the Boston & NYC trip by 23:39PM, May 10, 2023 (Wed) via [My Challenge Board] > [Submit Form].`,
      's_bn_challenge.floating.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  terms: {
    title: [`보스턴&뉴욕 챌린지 안내 및 유의 사항`, `Terms`, 's_bn_challenge.terms.title', ``, ``, ``, ``],
    subtitle_1: [`[공통 안내]`, `[General Notice]`, 's_bn_challenge.terms.subtitle_1', ``, ``, ``, ``],
    desc_1_1: [
      `모든 일정은 한국 시간 기준입니다.`,
      `All dates and times are in Korean Standard Time (KST).`,
      's_bn_challenge.terms.desc_1_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_1_2: [
      `혜택 당첨자 공지는 발표일에 링글 계정에 등록된 연락처 및 이메일로 개별 안내해 드립니다.`,
      `Those receiving rewards will be notified individually on the given date via their registered contact information. (Only if you have agreed to receive [Events & offers] notifications)`,
      's_bn_challenge.terms.desc_1_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_1_3: [
      `잘못된 개인 정보 기재로 당첨자 발표 연락을 받지 못한 경우, 혜택 수령이 불가할 수 있습니다.`,
      `You may not be able to receive rewards if you cannot be contacted due to your entry of incorrect personal information.`,
      's_bn_challenge.terms.desc_1_3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_1_4: [
      `당첨자 발표 후 7일 이내에 당첨자와 연락이 닿지 않는 경우 혜택 당첨이 취소되며 다른 사람에게 이관될 수 있습니다.`,
      `If you cannot be reached within 7 days after the announcement date, your rewards may be cancelled and reallocated to another person.`,
      's_bn_challenge.terms.desc_1_4',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_1_5: [
      `제공된 혜택은 양도 및 다른 상품으로 교환이 불가합니다.`,
      `Rewards are not exchangeable, nor can they be passed on to another user.`,
      's_bn_challenge.terms.desc_1_5',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_1_6: [
      `해당 이벤트는 사정에 의해 변경 및 조기 중단될 수 있으며 관련 내용은 사전 고지됩니다.`,
      `Ringle may modify or cancel the event before the given period, in which case prior notice will be given.`,
      's_bn_challenge.terms.desc_1_6',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_2: [`[보스턴&뉴욕 챌린지]`, `[Boston & NYC Challenge]`, 's_bn_challenge.terms.subtitle_2', ``, ``, ``, ``],
    desc_2_1: [
      `챌린지 기간 내 40분 24회 또는 20분 32회 수업을 완료한 분들은 [나의 챌린지 현황] > [신청서 제출]을 통해 보스턴/뉴욕 투어를 신청할 수 있습니다.`,
      `Challengers who have completed 40-min lessons 24 times or 20-min lessons 32 times within the event period can apply for the Boston & NYC trip via [My Challenge Board] > [Submit Form].`,
      's_bn_challenge.terms.desc_2_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_2_2: [
      `챌린지 기간 1/6(금) 00:00부터 4/30(일) 23:59까지 진행한 모든 수업이 포함됩니다. (수업권 종류 제한 없음, 4/30(금) 저녁 23:30 시작하는 수업 포함)`,
      `All lessons completed between Jan 6 (Fri) 00:00 to Apr 30 (Sun) 23:59 are counted toward the challenge. (Valid for all credit types; includes lesson starting at 23:30 on Jan 30)`,
      's_bn_challenge.terms.desc_2_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_2_3: [
      `수업 시작 시간에 튜터 노쇼 또는 미배정이 발생한 경우도 수업 진행으로 인정됩니다.`,
      `Tutor no-shows or lessons for which Ringle failed to assign tutors before the lesson was scheduled to start are also counted as completed lessons.`,
      's_bn_challenge.terms.desc_2_3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_2_4: [
      `보스턴&뉴욕 투어 신청서는 챌린지 기간 내 챌린지 조건 달성 후 5/10(수) 23:59까지 제출할 수 있습니다.`,
      `The Form for the Boston & NYC trip must be submitted after completing the challenge by May 10 (Wed) 23:59.`,
      's_bn_challenge.terms.desc_2_4',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_2_5: [
      `보스턴&뉴욕 투어 최종 선발자 5명은 5/31(수)에 별도 연락 후 링글 공식 SNS 채널을 통해 공지됩니다.`,
      `The final 5 winners of the Boston & NYC trip will be notified individually on May 31 (Thu) and announced on official Ringle channels afterwards.`,
      's_bn_challenge.terms.desc_2_5',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_2_6: [
      `보스턴&뉴욕 투어는 2023년 하반기에 예정되어 있습니다.`,
      `The Boston & NYC trip will be held in the latter half of 2023.`,
      's_bn_challenge.terms.desc_2_6',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_3: [`[포인트 혜택]`, `[Bonus Points]`, 's_bn_challenge.terms.subtitle_3', ``, ``, ``, ``],
    desc_3_1: [
      `수업 완료 후 [나의 챌린지 현황] > [포인트 받기]를 누르면 포인트가 적립됩니다.`,
      `You can receive bonus points by clicking the [Get points] button on [My Challenge Board] after completing a lesson. (Valid for 1 year from date of receipt.)`,
      's_bn_challenge.terms.desc_3_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_3_2: [
      `챌린지 참여 시점과 무관하게 챌린지 기간 내 진행한 수업이 있다면 혜택은 소급 적용됩니다.`,
      `Lessons completed within the event period, regardless of when you joined the challenge, will be counted toward the event.`,
      's_bn_challenge.terms.desc_3_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_3_3: [
      `40분/20분 수업권을 교차 수강할 경우, 40분/20분 포인트 혜택이 개별 적용됩니다.`,
      `Bonus points will be given separately for 40-min and 20-min lessons.`,
      's_bn_challenge.terms.desc_3_3',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_4: [
      `[영어 목표 남기기 미션]`,
      `[Submit Your 2023 Study Goals Mission]`,
      's_bn_challenge.terms.subtitle_4',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_4_1: [
      `영어 목표는 챌린지 참여 후 남길 수 있습니다.`,
      `You can submit your study goals after joining the challenge.`,
      's_bn_challenge.terms.desc_4_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_4_2: [
      `한번 남긴 의견은 수정 또는 삭제할 수 없으니 주의해 주시기 바랍니다.`,
      `Please note that you cannot edit or delete the study goal after submission.`,
      's_bn_challenge.terms.desc_4_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_4_3: [
      `이벤트와 무관한 내용, 욕설 등이 포함된 영어 목표는 사전 안내 없이 삭제될 수 있습니다.`,
      `Text unrelated to the event or containing derogatory terms may be deleted without prior notice.`,
      's_bn_challenge.terms.desc_4_3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_4_4: [
      `10자에서 80자 내 영어 목표를 남기는 즉시 전원 5,000 포인트를 드립니다.`,
      `All participants that submit study goals (10-80 characters) will be given 5,000 points. (Valid for 1 year from date of receipt.)`,
      's_bn_challenge.terms.desc_4_4',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_4_5: [
      `영어 목표를 남겨주신 분들 중 100명을 추첨하여 5/10(수)에 스타벅스 기프티콘을 보내드립니다.`,
      `All participants will be eligible for a lucky draw, and a Starbucks gift card will be sent to 100 winners on May 10 (Wed).`,
      's_bn_challenge.terms.desc_4_5',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_5: [
      `[SNS 후기 인증 미션]`,
      `[Post a Review on SNS Mission]`,
      's_bn_challenge.terms.subtitle_5',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_5_1: [
      `SNS 후기 인증 미션은 1/6(금)부터 4/30(일)까지 진행됩니다.`,
      `This mission will be held from Jan 6 (Fri) to Apr 30 (Sun).`,
      's_bn_challenge.terms.desc_5_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_5_2: [
      `SNS 후기를 남겨주신 분들 중 최대 100명에게 20,000원 교보문고 상품권을 증정합니다.`,
      `Up to 100 participants will be rewarded with a 20,000 won Kyobobook gift card.`,
      's_bn_challenge.terms.desc_5_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_5_3: [
      `정성스럽게 쓴 SNS 후기 5명을 추첨하여 20분 수업권 3회를 증정합니다.`,
      `Five winners will be chosen from well-written reviews through a lucky draw and rewarded with three 20-min credits.`,
      's_bn_challenge.terms.desc_5_3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_5_4: [
      `SNS 후기 인증 혜택은 5/10(수)에 일괄 발송됩니다.`,
      `All rewards for this mission will be issued on May 10 (Wed).`,
      's_bn_challenge.terms.desc_5_4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const r_auth = {
  signin: {
    password_space: [
      `띄어쓰기 없이 입력해 주세요.`,
      `Please enter without spaces.`,
      'r_auth.signin.password_space',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const t_review = {
  title_1: [`부모님께`, `Messages to Parent`, 't_review.title_1', ``, ``, ``, ``],
  title_2: [`오늘 수업 내용`, `What we have covered today`, 't_review.title_2', ``, ``, ``, ``],
  title_3: [`오늘 수업에서 잘한 것`, `What you did great`, 't_review.title_3', ``, ``, ``, ``],
  title_4: [`노력이 필요한 부분`, `What you can improve`, 't_review.title_4', ``, ``, ``, ``],
  title_5: [`핵심 표현과 어휘`, `Key Expressions & Vocabs`, 't_review.title_5', ``, ``, ``, ``],
  translate: [`한국어로 번역`, ``, 't_review.translate', ``, ``, ``, ``],
  translate_complete: [
    `Google로 번역되어 튜터의 실제 의도와 다를 수 있음`,
    ``,
    't_review.translate_complete',
    ``,
    ``,
    ``,
    ``,
  ],
  concentrate_msg_1: (studentName) => [
    <span>
      {studentName} 은(는) <span className="font-medium ml-[4px] text-primary">깊이 집중하여</span>, 수업 중에
      광범위하게 참여했습니다.
    </span>,
    <span>
      {studentName} was <span className="font-medium ml-[4px] text-primary">Deeply Focused</span>, engaging extensively
      during the lesson.
    </span>,
    't_review.concentrate_msg_1',
    ``,
    ``,
    ``,
    ``,
  ],
  concentrate_msg_2: (studentName) => [
    <span>
      {studentName} 은(는) <span className="font-medium ml-[4px] text-primary">주제에 집중하고 있었고</span>, 수업에
      집중할 수 있었고, 주제에 머물렀습니다.
    </span>,
    <span>
      {studentName} was <span className="font-medium ml-[4px] text-primary">Focusing on the topic</span>, able to focus
      on the lesson, and stayed on topic.
    </span>,
    't_review.concentrate_msg_2',
    ``,
    ``,
    ``,
    ``,
  ],
  concentrate_msg_3: (studentName) => [
    <span>
      {studentName} 은(는) <span className="font-medium ml-[4px] text-primary">산만했고</span>, 수업 중에 집중할 수
      없었고, 대화가 주제에 머물 수 없었습니다.
    </span>,
    <span>
      {studentName} was <span className="font-medium ml-[4px] text-primary">Distracted</span>, unable to concentrate
      during the class, the conversation couldn’t stay on topic.
    </span>,
    't_review.concentrate_msg_3',
    ``,
    ``,
    ``,
    ``,
  ],
  concentrate_msg_4: (studentName) => [
    <span>
      {studentName} 은(는) <span className="text-info font-medium ml-[4px]">매우 산만했고</span>, 자리에 머물 수 없었고,
      뛰어 다니며 활발하게 움직였습니다.
    </span>,
    <span>
      {studentName} was <span className="text-info font-medium ml-[4px]">Very distracted</span>, unable to stay on the
      seat, running around and actively moving.
    </span>,
    't_review.concentrate_msg_4',
    ``,
    ``,
    ``,
    ``,
  ],
  script_being_delayed: [
    <>
      수업 녹음 파일은 하단 재생 버튼을 클릭 시 들으실 수 있습니다.
      <br />
      수업 스크립트 생성은 지연되고 있어 아래 문의 버튼을 통해 연락 주시면 빠르게 확인 후 조치하겠습니다.
      <br />
      복습 자료 제공이 늦어진 점에 사과드립니다.
    </>,
    <>
      You can listen to your lesson audio by pressing the play button.
      <br />
      However we're experiencing a slight delay in generating your lesson transcripts.
      <br />
      We apologize for the inconvenience.
    </>,
    't_review.script_being_delayed',
    ``,
    ``,
    ``,
    ``,
  ],
  banner: {
    title: [
      <>
        튜터의 평가 내용에 궁금한 것이 있나요?
        <br />
        링글 틴즈 고객 경험팀이 자세히 알려드려요.
      </>,
      ``,
      't_review.banner.title',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`1:1 문의하기`, ``, 't_review.banner.btn', ``, ``, ``, ``],
  },
}
export const t_landing = {
  btn_sign_up: [`회원가입`, `Sign-up`, 't_landing.btn_sign_up', ``, ``, ``, ``],
  floating: {
    btn_txt: [
      `무료 체험하고 레벨 진단 받기`,
      `Sign up and get an AI level test`,
      't_landing.floating.btn_txt',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  menu_1: [`홈`, ``, 't_landing.menu_1', ``, ``, ``, ``],
  menu_2: [`베이직`, ``, 't_landing.menu_2', ``, ``, ``, ``],
  menu_3: [`플러스`, ``, 't_landing.menu_3', ``, ``, ``, ``],
  menu_4: [`집중반`, ``, 't_landing.menu_4', ``, ``, ``, ``],
  menu_5: [`가격`, ``, 't_landing.menu_5', ``, ``, ``, ``],
}
export const s_blog = {
  title: {
    blog: [`블로그`, `Blog`, 's_blog.title.blog', `部落格`, `部落格`, `Ringleブログ`, `Blog`],
    help_center: [
      `자주 묻는 질문`,
      `FAQs`,
      's_blog.title.help_center',
      `客服中心`,
      `客服中心`,
      `ヘルプセンター`,
      `Trợ giúp`,
    ],
  },
  search_bar: {
    placeholder_default: [
      `궁금한 점을 검색해보세요.`,
      `Enter a keyword`,
      's_blog.search_bar.placeholder_default',
      `请输入问题关键字`,
      `請輸入問題關鍵字`,
      `気になる点を検索してみてください`,
      `Tìm kiếm những gì bạn tò mò.`,
    ],
    placeholder_loading: [
      `불러오는 중...`,
      `Loading...`,
      's_blog.search_bar.placeholder_loading',
      `正在为您准备答案`,
      `正在為您準備答案`,
      `読み込み中...`,
      `Đang tải...`,
    ],
    placeholder_no_result: [
      `검색 결과가 없습니다.`,
      `No results`,
      's_blog.search_bar.placeholder_no_result',
      `没有找到适合的答案`,
      `沒有找到適合的答案`,
      `検索結果はありません。`,
      `Không có kết quả`,
    ],
  },
  search_reult: {
    title: [`게시물 검색`, `Search`, 's_blog.search_reult.title', `搜索`, `搜尋`, `検索`, `Tìm kiếm`],
    text_btn: [
      `이전 페이지로 돌아가기`,
      `Back to list`,
      's_blog.search_reult.text_btn',
      `返回上一页`,
      `返回上一頁`,
      `前のページに戻る`,
      `Trở về trang trang trước`,
    ],
    msg_result: (search_keyword, n) => [
      <>
        “{search_keyword}”에 대한 {n}개의 검색 결과
      </>,
      <>
        {n} result for “{search_keyword}”
      </>,
      's_blog.search_reult.msg_result',
      <>
        {n} 结果 “{search_keyword}”
      </>,
      <>
        {n} 結果 “{search_keyword}”
      </>,
      <>
        “{search_keyword}”について{n}個の検索結果
      </>,
      `{n} kết quả cho A “{search_keyword}”`,
    ],
    btn: [`자세히 보기`, `View detail`, 's_blog.search_reult.btn', `了解详情`, `瞭解詳情`, `詳しく見る`, `Nhìn kĩ`],
    desc_no_result: (search_keyword) => [
      <>
        “{search_keyword}”에 대한 검색 결과가 없어요.
        <br />
        단어의 철자를 확인 하거나 일반적인 검색어로 다시 검색해보세요.
      </>,
      <>There are no results for “{search_keyword}”. Please enter a different keyword.</>,
      's_blog.search_reult.desc_no_result',
      <>没有关于 “{search_keyword}” 的答案 请用其他关键字重新搜索</>,
      <>沒有關於 “{search_keyword}” 的答案 請用其他關鍵字重新搜尋</>,
      <>
        “{search_keyword}”についた検索結果がありません。
        単語のスペルを確認していただくか、一般的に使われる検索語で再度検索してください。
      </>,
      <>
        Không có kết quả tìm kiếm cho “{search_keyword}”. Kiểm tra chính tả của từ hoặc thử tìm kiếm lại bằng cụm từ tìm
        kiếm chung.
      </>,
    ],
    cateory_1: [`전체`, `All`, 's_blog.search_reult.cateory_1', `全部`, `全部`, `すべて`, `Tất cả`],
    cateory_2: [
      `자주 묻는 질문`,
      `FAQ`,
      's_blog.search_reult.cateory_2',
      `常见问题`,
      `常見問題`,
      `よくある質問`,
      `Những câu hỏi thường gặp`,
    ],
    cateory_3: [
      `공지사항`,
      `Announcements`,
      's_blog.search_reult.cateory_3',
      `通知事项`,
      `公告事項`,
      `お知らせ`,
      `Cập nhật`,
    ],
  },
  section_contact_us: {
    title: [
      `더 궁금한 것이 있다면
링글 틴즈 고객센터로 연락해주세요.`,
      `Need help or want to share feedback
with our team?`,
      's_blog.section_contact_us.title',
      `Need help or want to share feedback
with our team?`,
      `Need help or want to share feedback
with our team?`,
      `Need help or want to share feedback
with our team?`,
      `Need help or want to share feedback
with our team?`,
    ],
    btn: [
      `링글 틴즈팀에 문의하기`,
      `Contact Us`,
      's_blog.section_contact_us.btn',
      `Contact Us`,
      `Contact Us`,
      `Contact Us`,
      `Contact Us`,
    ],
    caption_1: [
      `대표전화`,
      `Call Us`,
      's_blog.section_contact_us.caption_1',
      `Call Us`,
      `Call Us`,
      `Call Us`,
      `Call Us`,
    ],
    number: [
      `02-588-3431`,
      `02-588-3431`,
      's_blog.section_contact_us.number',
      `02-588-3431`,
      `02-588-3431`,
      `02-588-3431`,
      `02-588-3431`,
    ],
    caption_2: [
      `운영시간`,
      `Operating Hours`,
      's_blog.section_contact_us.caption_2',
      `Operating Hours`,
      `Operating Hours`,
      `Operating Hours`,
      `Operating Hours`,
    ],
    desc_1: [
      `평일 오전 10:00 - 오후 6:00 KST`,
      `Mon to Fri 10:00 AM - 6:00 PM KST`,
      's_blog.section_contact_us.desc_1',
      `Mon to Fri 10:00 AM - 6:00 PM KST`,
      `Mon to Fri 10:00 AM - 6:00 PM KST`,
      `Mon to Fri 10:00 AM - 6:00 PM JST`,
      `Mon to Fri 10:00 AM - 6:00 PM KST`,
    ],
    desc_2: [
      `점심시간 : 오후 1:00 - 오후 2:00 KST`,
      `Lunchtime : 1:00 - 2:00 PM KST`,
      's_blog.section_contact_us.desc_2',
      `Lunchtime : 1:00  - 2:00 PM KST`,
      `Lunchtime : 1:00 - 2:00 PM KST`,
      `Lunchtime : 1:00 - 2:00 PM JST`,
      `Lunchtime : 1:00 - 2:00 PM KST`,
    ],
  },
  detail_page: {
    text_btn: [`이전`, `Back`, 's_blog.detail_page.text_btn', `上一步`, `上一步`, `戻る`, `Trước`],
    btn: [
      `목록으로 돌아가기`,
      `Back to the list`,
      's_blog.detail_page.btn',
      `回到主页`,
      `回到主頁`,
      `一覧に戻る`,
      `Đi đến danh sách`,
    ],
  },
  header_title: {
    search: [`검색`, `Search`, 's_blog.header_title.search', `搜索`, `搜尋`, `検索する`, `Tìm kiếm`],
    announcements: [
      `공지사항`,
      `Announcements`,
      's_blog.header_title.announcements',
      `通知事项`,
      `公告事項`,
      `お知らせ`,
      `Cập nhật`,
    ],
  },
  category: {
    ringle_guide: [
      `링글 가이드`,
      `How to Ringle`,
      's_blog.category.ringle_guide',
      `Ringle课程介绍`,
      `Ringle課程介紹`,
      `Ringleレッスンガイド`,
      `và hướng dẫn buổi học`,
    ],
    ringle_use: [
      `링글 활용법`,
      `Ringle Tips`,
      's_blog.category.ringle_use',
      `Ringle使用方法`,
      `Ringle使用方法`,
      `RingleのTips`,
      `Ringle Tips`,
    ],
    see_more: [`더보기`, `See more`, 's_blog.category.see_more', `查看更多`, `查看更多`, `もっと見る`, `Xem thêm`],
    home: [`홈`, `Home`, 's_blog.category.home', `主页`, `主頁`, `Home`, `Trang chủ`],
  },
  recommen_post: [
    `추천 포스트`,
    `Featured Posts`,
    's_blog.recommen_post',
    `推荐文章`,
    `推薦文章`,
    `おすすめ記事`,
    `Nổi bật`,
  ],
}
export const t_welcome = {
  section_title: {
    caption: [`회원가입 완료`, `Complete`, 't_welcome.section_title.caption', ``, ``, ``, ``],
    title: (parents_name) => [
      <>
        {parents_name}님,
        <br />
        링글 틴즈 가입을 환영해요!
      </>,
      <>
        {parents_name},<br />
        Welcome to Ringle Teens!
      </>,
      't_welcome.section_title.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      <>
        학습 담당자가 1~2 영업일 내로 연락하여
        <br />
        링글 틴즈의 전반에 대해 안내드릴게요.
      </>,
      <>
        Our manager will contact you <br /> in 1-2 business days to assist you with the trial lesson.
      </>,
      't_welcome.section_title.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    title_2: (parents_name) => [
      <>
        {parents_name}님, 환영해요!
        <br />
        이제 체험수업을
        <br />
        무료로 받을 수 있어요 :)
      </>,
      <>
        Welcome, {parents_name}!<br />
        You are now eligible for
        <br />
        the free trial lesson :)
      </>,
      't_welcome.section_title.title_2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  card_1: {
    title: (parent_name) => [
      <>
        {parent_name}님의 자녀를 위한
        <br />
        학습 담당자를 배정하고 있어요.
      </>,
      <>
        {parent_name}, we are now matching
        <br />
        the best manager for your child.
      </>,
      't_welcome.card_1.title',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_1: [
      `체험 수업부터 100% 만족하도록!`,
      `Ensuring 100% satisfaction from the first lesson.`,
      't_welcome.card_1.subtitle_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_1: [
      `SKY, 아이비리그 출신 학습 담당자가 직접 상담을 진행합니다.`,
      `Our managers from SKY and Ivy league backgrounds will assist you personally.`,
      't_welcome.card_1.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_2: [
      `자녀의 객관적인 영어 레벨은?`,
      `Evaluate your child's objective English skills`,
      't_welcome.card_1.subtitle_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_2: [
      `첫 수업이 끝나면 KAIST 연구팀과 함께 개발한 AI 진단 보고서를 드려요.`,
      `An AI report will be provided after the first class.`,
      't_welcome.card_1.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_btn: [`자세히 보기`, `View more`, 't_welcome.card_1.desc_btn', ``, ``, ``, ``],
    modal_title: [
      `AI 진단 보고서는 무엇인가요?`,
      `What is an AI report?`,
      't_welcome.card_1.modal_title',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_desc: [
      `자녀의 체험 수업이 끝나면 AI 진단 보고서를 전달합니다.
AI 분석을 통해 복잡성, 정확성, 유창성을 진단 받고 자녀의 영어 실력에 맞는 학습 방법을 알아보세요.`,
      `After the child's trial class, an AI report will be provided.
\bDiagnose complexity, accuracy, and fluency, and discover appropriate learning methods for your child.`,
      't_welcome.card_1.modal_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_btn: [`확인`, `OK`, 't_welcome.card_1.modal_btn', ``, ``, ``, ``],
    btn: [`직접 체험 수업 예약하기`, `Book a Trial Lesson Now`, 't_welcome.card_1.btn', ``, ``, ``, ``],
  },
  card_2: {
    title: [
      <>
        체험 수업 전, 링글 틴즈의
        <br />
        수업 과정에 대해 알아보세요.
      </>,
      <>
        Help your child grow
        <br />
        with Ringle Teens.
      </>,
      't_welcome.card_2.title',
      ``,
      ``,
      ``,
      ``,
    ],
    seg_1: [`Step_1`, `Step_1`, 't_welcome.card_2.seg_1', ``, ``, ``, ``],
    seg_2: [`Step_2`, `Step_2`, 't_welcome.card_2.seg_2', ``, ``, ``, ``],
    seg_3: [`Step_3`, `Step_3`, 't_welcome.card_2.seg_3', ``, ``, ``, ``],
    seg_1_title: [`예약 및 예습`, `Book & Prep for Lessons`, 't_welcome.card_2.seg_1_title', ``, ``, ``, ``],
    seg_1_subtitle_1: [`수업 예약하기`, `Book a lesson`, 't_welcome.card_2.seg_1_subtitle_1', ``, ``, ``, ``],
    seg_1_desc_1: [
      `시간 먼저 예약, 튜터 먼저 예약 두 가지 방식 중 선택해 주세요.`,
      `You can either select the time slot or tutor first.`,
      't_welcome.card_2.seg_1_desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    seg_1_subtitle_2: [
      `맞춤 수업을 위한 수업 방식 설정`,
      `Set the lesson style to your preference.`,
      't_welcome.card_2.seg_1_subtitle_2',
      ``,
      ``,
      ``,
      ``,
    ],
    seg_1_subtitle_3: [
      `자녀의 수업 준비 돕기`,
      `Help your child prep for the lesson`,
      't_welcome.card_2.seg_1_subtitle_3',
      ``,
      ``,
      ``,
      ``,
    ],
    seg_1_desc_3: [
      `교재 읽기, 오디오 파일 듣기, 답변할 질문 3개 이상 선택해 주세요.`,
      `Read the material, listen to the audio, and choose at least 3 questions.`,
      't_welcome.card_2.seg_1_desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    seg_2_title: [`수업 & 숙제`, `Take the Lesson & Do Homework`, 't_welcome.card_2.seg_2_title', ``, ``, ``, ``],
    seg_2_subtitle_1: [`수업 입장`, `Join the lesson`, 't_welcome.card_2.seg_2_subtitle_1', ``, ``, ``, ``],
    seg_2_desc_1: [
      `자녀가 시간에 맞춰 수업에 입장할 수 있도록 지도해 주세요.`,
      `Make sure your child joins the lesson on time.`,
      't_welcome.card_2.seg_2_desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    seg_2_subtitle_2: [`숙제 제출`, `Submit homework`, 't_welcome.card_2.seg_2_subtitle_2', ``, ``, ``, ``],
    seg_2_desc_2: [
      `학생은 수업이 끝나고 한 시간 이내로 숙제를 제출해야 합니다.`,
      `Students are expected to submit their homework within one hour after the lesson.`,
      't_welcome.card_2.seg_2_desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    seg_3_title: [`수업 복습`, `Lesson Review`, 't_welcome.card_2.seg_3_title', ``, ``, ``, ``],
    seg_3_subtitle_1: [
      `수업 피드백 확인`,
      `Check the tutor's feedback`,
      't_welcome.card_2.seg_3_subtitle_1',
      ``,
      ``,
      ``,
      ``,
    ],
    seg_3_desc_1: [
      `튜터의 수업 피드백을 확인하세요 (부모님을 위한 안내문 포함)`,
      `Go over the tutor's feedback (including notes for parents).`,
      't_welcome.card_2.seg_3_desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    seg_3_subtitle_2: [
      `글쓰기 숙제 피드백 확인`,
      `Check the feedback on homework`,
      't_welcome.card_2.seg_3_subtitle_2',
      ``,
      ``,
      ``,
      ``,
    ],
    seg_3_subtitle_3: [
      `자녀의 학습 돕기`,
      `Boost your child's learning`,
      't_welcome.card_2.seg_3_subtitle_3',
      ``,
      ``,
      ``,
      ``,
    ],
    seg_2_desc_3: [
      `자녀가 수업 분석과 피드백을 통해 지난 수업을 복습할 수 있도록 격려해주세요`,
      `Encourage your child to review lessons through Ringle's AI analysis and feedback report.`,
      't_welcome.card_2.seg_2_desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  card_3: {
    title: [
      <>
        명문대 출신 에디터들이
        <br />
        직접 만드는 교재를 확인하세요.
      </>,
      <>
        Check out our learning materials
        <br />
        made by Ivy League editors.
      </>,
      't_welcome.card_3.title',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_1: [
      `일상 대화부터 시사토론 주제까지`,
      `From small talk to in-depth discussions`,
      't_welcome.card_3.subtitle_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_1: [
      `다양한 내용으로 영어 읽기부터 말하기, 쓰기까지 진행해 보세요.`,
      `Choose from a multitude of topics for reading, speaking, and writing in English.`,
      't_welcome.card_3.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_2: [
      `상황과 실력에 맞춘 교재`,
      `Materials for all situations and levels`,
      't_welcome.card_3.subtitle_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_2: [
      `학습 담당자가 직접 교재를 추천하여 수업을 편성해 드립니다.`,
      `Receive material recommendations from our representatives for each lesson.`,
      't_welcome.card_3.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`교재 살펴보기`, `View more Materials`, 't_welcome.card_3.btn', ``, ``, ``, ``],
  },
  card_4: {
    title: [
      <>
        차별화된 원어민 선생님,
        <br />
        아이비리그 출신 튜터들이에요.
      </>,
      <>
        Learning beyond English
        <br />
        with Ivy League Tutors
      </>,
      't_welcome.card_4.title',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_1: [`영어 선생님을 넘어`, `Learning beyond English`, 't_welcome.card_4.subtitle_1', ``, ``, ``, ``],
    desc_1: [
      `우수한 교육 환경에서 높은 성취를 경험한 링글 틴즈 튜터들은 아이들에게 영어 그 이상을 가르칩니다.`,
      `Ringle Teens tutors are educated and well-accomplished high achievers who can teach your child more than just English.`,
      't_welcome.card_4.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_2: [`검증된 멘토`, `Mentorship you can trust`, 't_welcome.card_4.subtitle_2', ``, ``, ``, ``],
    desc_2: [
      `검증된 역량과 인성을 갖춘 멘토로서 아이가 더 넓은 세상으로 나아갈 수 있도록 도와줍니다.`,
      `Our capable and caring tutors will help your child to dream bigger and reach higher.`,
      't_welcome.card_4.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`튜터 살펴보기`, `View more Tutors`, 't_welcome.card_4.btn', ``, ``, ``, ``],
  },
  cta: {
    btn: [`링글 틴즈 시작하기`, `Go to Dashboard`, 't_welcome.cta.btn', ``, ``, ``, ``],
  },
  mobile_web: {
    caption: [
      `링글 틴즈 앱에서 아래 혜택을 모두 받을 수 있습니다!`,
      `Find out more on Ringle Teens app!`,
      't_welcome.mobile_web.caption',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_1: [
      <>
        1회 무료
        <br />
        20분 체험 수업
      </>,
      <>
        1 free 20-min
        <br />
        trial lesson
      </>,
      't_welcome.mobile_web.benefit_1',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_2: [
      <>
        자녀의 영어 실력
        <br />
        AI 레벨테스트
      </>,
      <>
        AI-based
        <br />
        English level test
      </>,
      't_welcome.mobile_web.benefit_2',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_3: [
      <>
        학년/주제로 맞춰진
        <br />
        1,500+개의 교재
      </>,
      <>
        1,500+ materials
        <br />
        to choose from
      </>,
      't_welcome.mobile_web.benefit_3',
      ``,
      ``,
      ``,
      ``,
    ],
    benefit_4: [
      <>
        영미권 Top 20
        <br />
        400+명의 튜터
      </>,
      <>
        400+ tutors
        <br />
        from top universities
      </>,
      't_welcome.mobile_web.benefit_4',
      ``,
      ``,
      ``,
      ``,
    ],
    note: [
      `링글 틴즈 수업을 위해 노트북 또는 모바일 앱을 준비해 주세요. 링글 틴즈는 크롬 웹 브라우저에서 가장 잘 활용할 수 있어요`,
      `You will need a PC or our mobile app to take Ringle Teens lessons. We recommend using Chrome as the browser when taking lessons on a PC.`,
      't_welcome.mobile_web.note',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`앱 다운로드하기`, `Download App`, 't_welcome.mobile_web.btn', ``, ``, ``, ``],
  },
  section_input: {
    title: (child_name) => [
      <>
        {child_name}학생에게
        <br />
        꼭 맞는 체험수업을 위해 <br />두 가지만 알려주세요!
      </>,
      <>
        Please tell us two things
        <br />
        about {child_name} so that
        <br />
        we can set up the trial lesson!
      </>,
      't_welcome.section_input.title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  input_1: {
    title: (child_name) => [
      <>
        {child_name}학생의
        <br />
        영어 실력에 대해 알려주세요.
      </>,
      <>
        How would you descirbe
        <br />
        {child_name}'s English proficiency?
      </>,
      't_welcome.input_1.title',
      ``,
      ``,
      ``,
      ``,
    ],
    option_1: [
      <>
        기초 어휘와 문장 구조를
        <br />
        배우기 시작하는 단계에요.
      </>,
      <>
        He/she just started learning
        <br />
        basic words and sentences.
      </>,
      't_welcome.input_1.option_1',
      ``,
      ``,
      ``,
      ``,
    ],
    option_2: [
      <>
        영어로 자기소개 수준의
        <br />
        간단한 일상 대화를 나눌 수 있어요.
      </>,
      <>
        He/she can hold simple and basic <br />
        conversations in English.
      </>,
      't_welcome.input_1.option_2',
      ``,
      ``,
      ``,
      ``,
    ],
    option_3: [
      <>
        기본 의사소통이 가능하지만,
        <br />긴 대화나 어려운 주제의 대화에 어려움이 있어요.
      </>,
      <>
        He/she can communicate in English
        <br />
        but not for long or about difficult topics.
      </>,
      't_welcome.input_1.option_3',
      ``,
      ``,
      ``,
      ``,
    ],
    option_4: [
      <>
        어렵고 낯선 주제에 대해서도
        <br />
        원어민과 막힘없이 대화할 수 있어요.
      </>,
      <>
        He/she can talk smoothly in English
        <br />
        even on difficult and unfamiliar topics.
      </>,
      't_welcome.input_1.option_4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  input_2: {
    title: (child_name) => [
      <>
        {child_name}학생의
        <br />
        영어 학습 경험을 <span className="text-[#4495FF]">모두 선택</span>해 주세요.
      </>,
      <>
        Please <span className="text-[#4495FF]">choose all</span> that describe
        <br />
        how {child_name} learned English.
      </>,
      't_welcome.input_2.title',
      ``,
      ``,
      ``,
      ``,
    ],
    option_1: [`영어 유치원`, `English kindergarten`, 't_welcome.input_2.option_1', ``, ``, ``, ``],
    option_2: [
      `원어민 수업 (회화 중심)`,
      <>
        Lessons with native speakers
        <br />
        (Conversation focused)
      </>,
      't_welcome.input_2.option_2',
      ``,
      ``,
      ``,
      ``,
    ],
    option_3: [
      `사립초/국제학교`,
      `Private elementary/international school`,
      't_welcome.input_2.option_3',
      ``,
      ``,
      ``,
      ``,
    ],
    option_4: [`해외거주`, `Living abroad`, 't_welcome.input_2.option_4', ``, ``, ``, ``],
    option_5: [
      `영어 학원 (문법 중심)`,
      <>
        English language school/academy
        <br />
        (Grammar focused)
      </>,
      't_welcome.input_2.option_5',
      ``,
      ``,
      ``,
      ``,
    ],
    option_6: [`해당 없음`, `None of the above`, 't_welcome.input_2.option_6', ``, ``, ``, ``],
  },
  input_searching: {
    caption: [`잠시만 기다려주세요!`, `Please wait a moment!`, 't_welcome.input_searching.caption', ``, ``, ``, ``],
    title: [
      <>
        체험수업에 꼭 맞는
        <br />
        추천 코스를 찾고 있어요.
      </>,
      <>
        We're putting together
        <br />
        our recommended course.
      </>,
      't_welcome.input_searching.title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn: {
    done: [`추천 코스 보기`, ``, 't_welcome.btn.done', ``, ``, ``, ``],
  },
  input_3: {
    title: [
      <>
        <span className="text-[#4495FF]">[선택]</span>
        <br />
        튜터에게 전달하고 싶은
        <br />
        학생의 특징과 영어 공부 목적을
        <br />
        알려주세요.
      </>,
      ``,
      't_welcome.input_3.title',
      ``,
      ``,
      ``,
      ``,
    ],
    placeholder: [`한국어로 작성해도 괜찮아요.`, ``, 't_welcome.input_3.placeholder', ``, ``, ``, ``],
    caption: [
      `[대시보드] > [자녀 계정 관리] > [프로필]에서 언제든지 수정할 수 있어요.`,
      ``,
      't_welcome.input_3.caption',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_done: [`작성 완료`, ``, 't_welcome.input_3.btn_done', ``, ``, ``, ``],
  },
  event_card_1: {
    label_title: [`EVENT`, `EVENT`, 't_welcome.event_card_1.label_title', ``, ``, ``, ``],
    label_exp_timer: (count) => [
      `포인트 사용기한 ${count}일 HH:mm:SS`,
      `Points expire in ${count}days HH:mm:SS`,
      't_welcome.event_card_1.label_exp_timer',
      ``,
      ``,
      ``,
      ``,
    ],
    title: (name) => [
      <>
        {name}님, 반가운 마음을 담아
        <br />
        <span className="text-[#F9523B]">15만 포인트</span>를 넣어드렸어요.
      </>,
      <>
        <span className="text-[#F9523B]">Enjoy bonus points,</span>
        <br />
        added just for you!
      </>,
      't_welcome.event_card_1.title',
      ``,
      ``,
      ``,
      ``,
    ],
    cta: [`지금 포인트 사용하기`, `Buy now with your points`, 't_welcome.event_card_1.cta', ``, ``, ``, ``],
  },
}
export const t_trial_lesson = {
  page_title: [`체험 수업 예약`, `Book a Trial`, 't_trial_lesson.page_title', ``, ``, ``, ``],
  select_time: {
    title: [
      `수업 시간을 선택하세요.`,
      `Choose the date/time slot.`,
      't_trial_lesson.select_time.title',
      ``,
      ``,
      ``,
      ``,
    ],
    info_duration: [
      `체험 수업은 20분 동안 진행됩니다.`,
      `The trial lesson is 20 minutes.`,
      't_trial_lesson.select_time.info_duration',
      ``,
      ``,
      ``,
      ``,
    ],
    caption_1: [`오전`, `AM`, 't_trial_lesson.select_time.caption_1', ``, ``, ``, ``],
    caption_2: [`오후`, `PM`, 't_trial_lesson.select_time.caption_2', ``, ``, ``, ``],
    info_slot: [
      `14:00 - 19:00(KST)는 서비스 운영 시간이 아닙니다.`,
      `The service is not available from 14:00 to 19:00(KST).`,
      't_trial_lesson.select_time.info_slot',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_disabled: [
      `시간을 선택하세요`,
      `Choose a time slot.`,
      't_trial_lesson.select_time.btn_disabled',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_title: [
      `체험 수업 예약을 그만할까요?`,
      `Would you like to quit booking?`,
      't_trial_lesson.select_time.modal_title',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_desc: (remain_date) => [
      `지금까지 저장한 내용은 사라집니다.`,
      <>
        You can continue booking a trial lesson within {remain_date} day(s).
        <br />
        Please contact the help center if you have any difficulties booking a trial lesson.
      </>,
      't_trial_lesson.select_time.modal_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_btn: [`이어서 하기`, `Continue`, 't_trial_lesson.select_time.modal_btn', ``, ``, ``, ``],
    modal_cta: [`그만하기`, `Quit`, 't_trial_lesson.select_time.modal_cta', ``, ``, ``, ``],
    select_time: [
      `표시된 수업 시간은 한국 표준시(KST)입니다.`,
      `The displayed lesson time is in Korean Standard Time (KST).`,
      't_trial_lesson.select_time.select_time',
      ``,
      ``,
      ``,
      ``,
    ],
    date_btn: [`변경`, ``, 't_trial_lesson.select_time.date_btn', ``, ``, ``, ``],
  },
  select_tutor: {
    title: [`튜터를 선택하세요.`, `Choose a tutor.`, 't_trial_lesson.select_tutor.title', ``, ``, ``, ``],
    image_btn: [`프로필 보기`, `View profile`, 't_trial_lesson.select_tutor.image_btn', ``, ``, ``, ``],
    caption: [`추천 이유`, `Teaching style`, 't_trial_lesson.select_tutor.caption', ``, ``, ``, ``],
    btn_card: [`튜터 선택`, `Choose this tutor`, 't_trial_lesson.select_tutor.btn_card', ``, ``, ``, ``],
    btn_card_selected: (tutor_name) => [
      <>{tutor_name}을(를) 선택했어요</>,
      <>You chose {tutor_name}.</>,
      't_trial_lesson.select_tutor.btn_card_selected',
      ``,
      ``,
      ``,
      ``,
    ],
    info_tutor: [
      `정규 수업에서는 더 많은 튜터를 만날 수 있어요.`,
      `More tutors are available for regular lessons.`,
      't_trial_lesson.select_tutor.info_tutor',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_disabled: [`튜터를 선택하세요`, `Choose a tutor`, 't_trial_lesson.select_tutor.btn_disabled', ``, ``, ``, ``],
  },
  select_lesson_style: {
    title: [
      <>
        자녀의 영어 수준을
        <br />
        알려주세요.
      </>,
      <>
        How would you describe
        <br />
        your child's English proficiency?
      </>,
      't_trial_lesson.select_lesson_style.title',
      ``,
      ``,
      ``,
      ``,
    ],
    option_1: [
      <>
        자기소개를 영어로 할 수 있고
        <br />
        어느 정도 알아들을 수 있어요.
      </>,
      <>
        Can understand some English
        <br />
        and make self-introduction in English.
      </>,
      't_trial_lesson.select_lesson_style.option_1',
      ``,
      ``,
      ``,
      ``,
    ],
    option_2: [
      <>
        더듬거리지만 영어로
        <br />
        간단한 일상 대화를 나눌 수 있어요.
      </>,
      <>
        Can engage in limited small talk
        <br />
        in English.
      </>,
      't_trial_lesson.select_lesson_style.option_2',
      ``,
      ``,
      ``,
      ``,
    ],
    option_3: [
      <>
        큰 어려움 없이 자신의 의견을
        <br />
        영어로 표현할 수 있어요.
      </>,
      <>
        Can express opinions in English
        <br />
        without too much difficulty.
      </>,
      't_trial_lesson.select_lesson_style.option_3',
      ``,
      ``,
      ``,
      ``,
    ],
    option_4: [
      <>
        영어로 원활히 소통할 수 있어
        <br />
        고급 영어를 배우고 싶어요.
      </>,
      <>
        Can communicate freely
        <br />
        and is ready to learn higher level English.
      </>,
      't_trial_lesson.select_lesson_style.option_4',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle: [
      `수업 방식 선택`,
      `Choose a lesson style.`,
      't_trial_lesson.select_lesson_style.subtitle',
      ``,
      ``,
      ``,
      ``,
    ],
    label_rec: [`추천`, `Recommended`, 't_trial_lesson.select_lesson_style.label_rec', ``, ``, ``, ``],
    label_adv: [`심화 과정`, `Advanced program`, 't_trial_lesson.select_lesson_style.label_adv', ``, ``, ``, ``],
    style_1: [`발언 유도형`, `Speaking confidence`, 't_trial_lesson.select_lesson_style.style_1', ``, ``, ``, ``],
    desc_1: [
      `영어에 대한 자신감을 높여주고 싶어요. 아이가 말할 수 있는 기회를 많이 주세요.`,
      <>
        Encourage speaking in English
        <br />
        Build confidence in English
      </>,
      't_trial_lesson.select_lesson_style.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    style_2: [`교정형`, `Paraphrasing`, 't_trial_lesson.select_lesson_style.style_2', ``, ``, ``, ``],
    desc_2: [
      `표현력/어휘력을 키워주고 싶어요. 아이의 틀린/어색한 영어 표현을 적극 교정해 주세요.`,
      <>
        Focus on expressions and vocabulary
        <br />
        Actively correct awkward English
      </>,
      't_trial_lesson.select_lesson_style.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    style_3: [`토론형`, `Discussion`, 't_trial_lesson.select_lesson_style.style_3', ``, ``, ``, ``],
    desc_3: [
      `영어 토론 능력을 키우고 싶어요. 비판적 사고력이 향상될 수 있도록 적극 대화에 참여해 주세요.`,
      <>
        Engage in discussions with tutor in English
        <br />
        Strengthen critical thinking ability
      </>,
      't_trial_lesson.select_lesson_style.desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    info: [
      `수업 방식은 언제든지 변경할 수 있어요.`,
      `You can change the lesson style any time.`,
      't_trial_lesson.select_lesson_style.info',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`선택`, `Confirm selection`, 't_trial_lesson.select_lesson_style.btn', ``, ``, ``, ``],
    subtitle_1: [`자녀의 영어 수준은`, ``, 't_trial_lesson.select_lesson_style.subtitle_1', ``, ``, ``, ``],
    placeholder: [
      <>
        자녀의 영어 수준에 맞는
        <br />
        수업 방식을 추천해드려요.
      </>,
      ``,
      't_trial_lesson.select_lesson_style.placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    subtitle_2: [`수업 방식 선택`, ``, 't_trial_lesson.select_lesson_style.subtitle_2', ``, ``, ``, ``],
  },
  select_material: {
    title: [`교재를 선택하세요.`, `Choose a material.`, 't_trial_lesson.select_material.title', ``, ``, ``, ``],
    image_btn: [`자세히 보기`, `View details`, 't_trial_lesson.select_material.image_btn', ``, ``, ``, ``],
    btn_card: [`교재 선택`, `Choose`, 't_trial_lesson.select_material.btn_card', ``, ``, ``, ``],
    btn_card_selected: [`선택 취소`, `Cancel`, 't_trial_lesson.select_material.btn_card_selected', ``, ``, ``, ``],
    info_tutor: [
      `정규 수업에서는 더 많은 교재를 선택할 수 있어요.`,
      `More materials are available for regular lessons.`,
      't_trial_lesson.select_material.info_tutor',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_disabled: [
      `교재를 선택하세요`,
      `Choose a material`,
      't_trial_lesson.select_material.btn_disabled',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  check: {
    title: [`예약 내역 확인`, `Lesson Booking Details`, 't_trial_lesson.check.title', ``, ``, ``, ``],
    btn_edit: [`변경`, `Change`, 't_trial_lesson.check.btn_edit', ``, ``, ``, ``],
    info_tutor: [
      `튜터의 부득이한 사정으로 매칭이 어려울 경우 평점이 높은 다른 튜터가 매칭됩니다.`,
      `If the tutor you chose is unable to teach the lesson, you will be matched with another highly rated tutor.`,
      't_trial_lesson.check.info_tutor',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`수업 예약 완료`, `Confirm booking`, 't_trial_lesson.check.btn', ``, ``, ``, ``],
    modal_title_1: [
      `시간을 변경할까요?`,
      `Would you like to change the schedule?`,
      't_trial_lesson.check.modal_title_1',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_title_2: [
      `튜터를 변경할까요?`,
      `Would you like to change the tutor?`,
      't_trial_lesson.check.modal_title_2',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_title_3: [
      `교재를 변경할까요?`,
      `bWould you like to change the material?`,
      't_trial_lesson.check.modal_title_3',
      ``,
      ``,
      ``,
      ``,
    ],
    modal_desc: [
      `지금까지 저장한 내용은 사라집니다.`,
      `All of your selections will be reset.`,
      't_trial_lesson.check.modal_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `수업 정보 확인 후 예약을 완료해 주세요!`,
      `Please check your booking details.`,
      't_trial_lesson.check.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  done: {
    title: [
      `체험 수업을 예약했어요.`,
      `Your trial lesson has been booked.`,
      't_trial_lesson.done.title',
      ``,
      ``,
      ``,
      ``,
    ],
    info: [
      `체험 수업 전 학습 담당자가 연락드릴 예정입니다. 변경 또는 요청 사항이 있으시다면 학습 담당자에게 말씀해 주세요.`,
      `Our representative will be contacting you before the lesson to assist you and check for any requests or changes.`,
      't_trial_lesson.done.info',
      ``,
      ``,
      ``,
      ``,
    ],
    note_title: [`수업 취소 정책`, `Lesson Cancellation Policy`, 't_trial_lesson.done.note_title', ``, ``, ``, ``],
    note_desc_1: [
      `체험 수업 시작 기준 24시간 이전 취소 시, 7일간 사용 가능한 1회 수업권이 복구됩니다.`,
      `A trial lesson credit with a 7-day validity period will be issued for cancellations up to 24 hours before the trial lesson is scheduled to start.`,
      't_trial_lesson.done.note_desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    note_desc_2: [
      `체험 수업 시작 기준 24~2시간 이전 취소 시, 7일간 사용 가능한 [당일수업권]이 제공됩니다. [당일수업권]은 예약 시점 기준 24시간 이내에 시작하는 수업을 예약할 수 있는 수업권입니다.`,
      <>
        A [Last-Minute Credit] with a 7-day validity period will be issued for cancellations between 2 to 24 hours
        before the trial lesson is scheduled to start.
        <br />A [Last-Minute Credit] is a credit that can be used for booking lessons that begin within 24 hours.
      </>,
      't_trial_lesson.done.note_desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    note_desc_3: [
      `체험 수업 시작 전 2시간 이내 취소 시, 1회 수업권은 차감되며 [당일수업권]은 지급되지 않습니다.`,
      `Neither a trial lesson credit or a [Last-Minute Credit] will be issued for cancellations within 2 hours before the trial lesson is scheduled to start.`,
      't_trial_lesson.done.note_desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  guide: {
    title: [
      `링글 틴즈 수업 입장 안내`,
      `Classroom Entrance Instructions`,
      't_trial_lesson.guide.title',
      ``,
      ``,
      ``,
      ``,
    ],
    info: [
      `교재 및 실시간 교정 화면 확인을 위해 PC/노트북 사용을 권장해요.`,
      `For materials and real-time correction screen checking, we recommend using a PC/laptop.`,
      't_trial_lesson.guide.info',
      ``,
      ``,
      ``,
      ``,
    ],
    step_1: [
      `링글 틴즈 웹사이트에 로그인하세요.`,
      `Please log in to the Ringle Teens website.`,
      't_trial_lesson.guide.step_1',
      ``,
      ``,
      ``,
      ``,
    ],
    step_2: [
      `학부모 대시보드에서 자녀 학습 포털로 입장하세요.`,
      `Please enter the child's learning portal from the parent dashboard.`,
      't_trial_lesson.guide.step_2',
      ``,
      ``,
      ``,
      ``,
    ],
    step_3: [
      `1:1 수업 메뉴의 예습 & 수업 탭에서 수업에 입장하세요.`,
      `Enter the lesson from the [Preparation & Lesson] tab in the [1:1 lesson] menu.`,
      't_trial_lesson.guide.step_3',
      ``,
      ``,
      ``,
      ``,
    ],
    step_3_desc: [
      `*수업 시작 15분 전부터 [수업 입장] 버튼을 클릭하면 수업에 입장할 수 있어요.`,
      `You can enter the lesson by clicking the [Enter Lesson] button starting 15 minutes before the lesson begins.`,
      't_trial_lesson.guide.step_3_desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  lesson_style: {
    title: [
      <>
        1:1 맞춤 수업을 위해
        <br />
        <span className="text-[#4495FF]">수업 방식</span>을 설정해 주세요.
      </>,
      <>
        Choose your preferred <span className="text-[#4495FF]">lesson style</span>
        <br />
        for personalized 1:1 lessons.
      </>,
      't_trial_lesson.lesson_style.title',
      ``,
      ``,
      ``,
      ``,
    ],
    label: [`링글 추천`, `Recommended`, 't_trial_lesson.lesson_style.label', ``, ``, ``, ``],
    btn_1: [`모드 선택`, `Select`, 't_trial_lesson.lesson_style.btn_1', ``, ``, ``, ``],
    btn_2: [`모드 선택됨`, `Selected`, 't_trial_lesson.lesson_style.btn_2', ``, ``, ``, ``],
    option_1: [`발언 유도형`, `Speaking confidence`, 't_trial_lesson.lesson_style.option_1', ``, ``, ``, ``],
    desc_1: [
      `학생이 말할 기회가 많아 영어에 대한 자신감을 높여요.`,
      `Boost confidence in speaking English`,
      't_trial_lesson.lesson_style.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    option_2: [`이해력 증진형`, `Comprehension`, 't_trial_lesson.lesson_style.option_2', ``, ``, ``, ``],
    desc_2: [
      `튜터와 함께 교재를 분석하며 텍스트 이해력을 높여요.`,
      `Improve the ability to understand texts`,
      't_trial_lesson.lesson_style.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    option_3: [`교정형`, `Paraphrasing`, 't_trial_lesson.lesson_style.option_3', ``, ``, ``, ``],
    desc_3: [
      `적극적인 교정을 통해 표현력과 어휘력을 키워요.`,
      `Correct mistakes and learn new expressions`,
      't_trial_lesson.lesson_style.desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    option_4: [`토론형`, `Discussion`, 't_trial_lesson.lesson_style.option_4', ``, ``, ``, ``],
    desc_4: [
      `다양하고 창의적인 질문으로 비판적 사고력을 키워요.`,
      `Develop logical and critical thinking`,
      't_trial_lesson.lesson_style.desc_4',
      ``,
      ``,
      ``,
      ``,
    ],
    caption: [
      `상세한 수업 설정은 [수업] > [예정된 수업] > [수업 방식 설정]에서 할 수 있어요.`,
      `Detailed lesson settings can be made in [Lessons] > [Upcoming Lessons] > [Set Lesson Style].`,
      't_trial_lesson.lesson_style.caption',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  banner: {
    title: [
      `지금 딱 맞는 교재와 튜터로 체험해보세요.`,
      <>
        Try out a lesson now
        <br />
        with the ideal material and tutor.
      </>,
      't_trial_lesson.banner.title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const n_tutor_lists = {
  title: [`튜터`, `Tutor`, 'n_tutor_lists.title', `教师`, `教師`, `チューター`, ``],
  search_placehoder: [
    `키워드로 검색해보세요.`,
    `Enter a keyword`,
    'n_tutor_lists.search_placehoder',
    `请输入关键字`,
    `請輸入關鍵字`,
    `キーワードを入力してください。`,
    ``,
  ],
  featured_desc_multi: [
    `최근 한 달간 높은 수업 평점과 긍정적인 후기를 받은 튜터들이에요.`,
    ``,
    'n_tutor_lists.featured_desc_multi',
    ``,
    ``,
    ``,
    ``,
  ],
  featured_desc: [
    <>
      최근 한 달간 높은 수업 평점과 긍정적인 후기를 받은 튜터들이에요. <br />
      추천 튜터와의 수업이 만족스럽지 않으면, 수업권을 복구해 드립니다. (유료 수업권으로 예약 후 튜터 평가 3점 이하 및
      사유 제출, 월 2회 한정)
    </>,
    <>
      These tutors have received high ratings and positive reviews.
      <br />
      Credit restored if unsatisfied with the Rookie/Featured Tutor. (Rate as 3 stars or less after a paid lesson with
      detailed reason, twice per month limit)
    </>,
    'n_tutor_lists.featured_desc',
    <>
      对新教师或推荐教师的课程不满意时，将退还课程券。
      （仅限用付费课程券预约之课程，教师的课后评分不足3分时并提交原因，每月限2次）
    </>,
    <>
      對新教師或推薦教師的課程不滿意時，將退還課程券。
      （僅限用付費課程券預約之課程，教師的課後評分不足3分時並提交原因，每月限2次）
    </>,
    `おすすめの/新規チューターのレッスンが満足できなかったら、レッスンチケットを復旧いたします。（有料レッスンチケットで予約、評価３点以下および理由提出、月２回限定）`,
    `Nếu không hài lòng với buổi học của gia sư mới/ gia sư đề cử, phiếu học sẽ được hoàn. (Tối đa 2 lần/ tháng cho buổi học trả phí, đánh giá gia sư 3 sao kèm lý do)`,
  ],
}
export const n_book_lessons = {
  total_tutor_number: (number) => [
    `총 ${number}명`,
    `${number} tutors`,
    'n_book_lessons.total_tutor_number',
    `共 ${number}名教师`,
    `共 ${number}名教師`,
    `合計${number}人`,
    ``,
  ],
  filter: {
    title: [`필터`, `Filter`, 'n_book_lessons.filter.title', `筛选`, `篩選`, `フィルター`, ``],
    empty_desc_title: [
      `필터에 맞는 튜터가 없습니다.`,
      `No tutors match this criteria.`,
      'n_book_lessons.filter.empty_desc_title',
      `没有符合关键字的教师`,
      `沒有符合關鍵字的教師`,
      `希望に合うチューターがいません。`,
      ``,
    ],
    empty_desc_subtitle: [
      `필터 변경 후 다시 시도해 보세요.`,
      `Please adjust the filter and try again.`,
      'n_book_lessons.filter.empty_desc_subtitle',
      `请调整删选条件并再试一次`,
      `請調整刪選條件並再試一次`,
      `絞込条件を変更して再度お試しください。`,
      ``,
    ],
  },
}
export const n_lesson_prep = {
  complete: {
    btn_back: [
      `수업준비로 돌아가기`,
      `Return to lesson prep`,
      'n_lesson_prep.complete.btn_back',
      `回到课程准备页面`,
      `回到課程準備頁面`,
      `レッスン準備に戻る`,
      ``,
    ],
    btn_lesson_style: [
      `수업방식 설정`,
      `Set lesson style`,
      'n_lesson_prep.complete.btn_lesson_style',
      `设定课程模式`,
      `設定課程模式`,
      `レッスン方式の設定`,
      ``,
    ],
    title: [
      `수업준비 모두 완료`,
      `End lesson prep`,
      'n_lesson_prep.complete.title',
      `结束课程准备`,
      `結束課程準備`,
      `レッスン準備完了`,
      ``,
    ],
    desc: (name) => [
      <>
        {name}님,
        <br />
        수업시간에 만나요!
      </>,
      <>
        {name},<br />
        we'll see you in class!
      </>,
      'n_lesson_prep.complete.desc',
      <>
        {name},<br />
        我们课堂见!
      </>,
      <>
        {name},<br />
        我們課堂見!
      </>,
      <>
        {name}さん、
        <br />
        レッスンで会いましょう！
      </>,
      ``,
    ],
    tab_video: [
      `영상/기사`,
      `Video Clip/Article`,
      'n_lesson_prep.complete.tab_video',
      `影片/报导`,
      `影片/報導`,
      `動画/記事`,
      ``,
    ],
    tab_contents: [
      `링글 관련 콘텐츠`,
      `More from Ringle`,
      'n_lesson_prep.complete.tab_contents',
      `相关教材`,
      `相關教材`,
      `Ringl関連コンテンツ`,
      ``,
    ],
    tab_expression: [
      `핵심표현`,
      `Expressions`,
      'n_lesson_prep.complete.tab_expression',
      `重点生词`,
      `重點單字`,
      `表現集`,
      ``,
    ],
    btn_insight: [
      `인사이트 읽기`,
      `Insight`,
      'n_lesson_prep.complete.btn_insight',
      `阅读文章`,
      `閱讀文章`,
      `インサイトを読む`,
      ``,
    ],
    related_video: [
      `관련 영상`,
      `Video clip(s)`,
      'n_lesson_prep.complete.related_video',
      `相关视频`,
      `相關影片`,
      `関連動画`,
      ``,
    ],
    related_news: [
      `관련 기사`,
      `Article(s)`,
      'n_lesson_prep.complete.related_news',
      `相关报导`,
      `相關報導`,
      `関連記事`,
      ``,
    ],
    related_material: [
      `관련 교재`,
      `More from Ringle`,
      'n_lesson_prep.complete.related_material',
      `相关教材`,
      `相關教材`,
      `関連教材`,
      ``,
    ],
  },
  view_kr: [
    `질문 한글로 보기`,
    `Korean translation`,
    'n_lesson_prep.view_kr',
    `韩文翻译`,
    `韓文翻譯`,
    `質問を日本語で確認する`,
    ``,
  ],
}
export const n_signup = {
  referral_code: {
    code: [
      `[선택] 친구초대코드`,
      `Referral Code (optional)`,
      'n_signup.referral_code.code',
      `朋友代码(选择)`,
      `朋友代碼（選填）`,
      `招待コード(任意)`,
      `Mã giới thiệu (không bắt buộc)`,
    ],
    verified: (name) => [
      <>{name}님의 친구 초대코드가 확인되었습니다.</>,
      <>{name}'s friend invitation code has been confirmed.</>,
      'n_signup.referral_code.verified',
      ``,
      ``,
      ``,
      ``,
    ],
    special: (name) => [
      <>
        {name}님의 친구 초대코드가 확인되었습니다.
        <br />
        무료 체험 수업 1회와 100,000P가 지급 되었습니다.
      </>,
      <>{name}'s friend invitation code has been confirmed.</>,
      'n_signup.referral_code.special',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  invalid_code: [
    `존재하지 않는 친구추천코드입니다.`,
    `Invalid referral code.`,
    'n_signup.invalid_code',
    `无效的朋友邀请码`,
    `無效的朋友邀請碼`,
    `存在しない友達紹介コードです`,
    ``,
  ],
}
export const t_referral = {
  menu: [`초대하기`, `Invite`, 't_referral.menu', ``, ``, ``, ``],
  toast: [`초대 링크를 복사했어요.`, `Copied the invite link.`, 't_referral.toast', ``, ``, ``, ``],
  invite: {
    section_1: {
      caption: [`나의 초대 코드`, `My referral code`, 't_referral.invite.section_1.caption', ``, ``, ``, ``],
      btn_1: [`초대 링크 복사하기`, `Copy link`, 't_referral.invite.section_1.btn_1', ``, ``, ``, ``],
      btn_2: [`카카오로 초대하기`, `Share link via Kakaotalk`, 't_referral.invite.section_1.btn_2', ``, ``, ``, ``],
    },
    section_2: {
      title: [
        `친구에게 5만 포인트 선물하는 방법`,
        `Gift $38 points to your friends`,
        't_referral.invite.section_2.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc_1: [
        `친구에게 초대장을 공유해요.`,
        `Share the invite to your friends.`,
        't_referral.invite.section_2.desc_1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc_2: [
        `초대 받은 친구가 링크로 가입해요.`,
        `If your friends join Ringle Teens via the invite link`,
        't_referral.invite.section_2.desc_2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc_3: [
        `자동으로 친구는 5만 포인트를 받아요.`,
        `Your friends will automatically receive $38 points.`,
        't_referral.invite.section_2.desc_3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    section_3: {
      title: [
        `친구에게 선물하면, 나도 혜택을 받아요`,
        `Get rewarded for inviting your friends`,
        't_referral.invite.section_3.title',
        ``,
        ``,
        ``,
        ``,
      ],
      desc_1: [
        `초대받은 친구가 수업권을 30만원 이상 결제해요.`,
        `If your invited friend purchases credits worth $231 or above`,
        't_referral.invite.section_3.desc_1',
        ``,
        ``,
        ``,
        ``,
      ],
      desc_2: [
        `내 초대 코드로 가입한 친구가 첫 수업을 완료하면 5만 링글 틴즈 포인트를 받아요.`,
        `You get $38 points when they complete their first lesson on Ringle Teens.`,
        't_referral.invite.section_3.desc_2',
        ``,
        ``,
        ``,
        ``,
      ],
      desc_3: [
        `내가 받은 포인트를 선물로 교활할 수 있어요.`,
        `You can redeem the points for gifts.`,
        't_referral.invite.section_3.desc_3',
        ``,
        ``,
        ``,
        ``,
      ],
      label: [
        `신세계, 스타벅스 상품권 또는 링글 수업권으로 교환 가능`,
        `Can be exchanged for Shinsegae/Starbucks gift card or Ringle credits.`,
        't_referral.invite.section_3.label',
        ``,
        ``,
        ``,
        ``,
      ],
    },
  },
  terms_1: {
    title: [`추천인 유의사항`, `Event Terms & Conditions`, 't_referral.terms_1.title', ``, ``, ``, ``],
    desc_1: [
      `친구가 30만원 이상 결제하고 첫 수업을 완료한 해당 월 말에 일괄 지급됩니다.`,
      `Points will be rewarded at the end of the month your invited friend makes a purchase in the amount of $231 or above and completes their first lesson.`,
      't_referral.terms_1.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_2: [
      `비정상적인 친구 초대로 판단되는 경우 포인트가 지급되지 않거나 회수될 수 있습니다.`,
      `Any abnormal participation will result in disqualification or withdrawal of rewarded points.`,
      't_referral.terms_1.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_3: [
      `친구 초대 포인트는 신세계 상품권, 스타벅스 쿠폰으로 교환할 수 있습니다.`,
      `Points rewarded for inviting friends can be exchanged for Shinsegae/Startbucks gift cards.`,
      't_referral.terms_1.desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_4: [
      `지급된 포인트의 유효기간은 30일입니다.`,
      `The rewarded points will be valid for 30 days.`,
      't_referral.terms_1.desc_4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  terms_2: {
    title: [`추천 혜택 유의사항`, `Terms for Referral Benefits`, 't_referral.terms_2.title', ``, ``, ``, ``],
    desc_1: [
      `친구 초대코드로 가입한 후 7일 이내 체험 수업을 신청할 수 있습니다.`,
      `The free trial lesson is available for 7 days after joining Ringle Teens using the invite link (referral code).`,
      't_referral.terms_2.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_3: [
      `친구의 초대코드로 가입한 직후 5만 포인트가 지급됩니다.`,
      `$38 points will be rewarded immediately after joining Ringle Teens using the invite link (referral code).`,
      't_referral.terms_2.desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_4: [
      `지급된 포인트의 유효기간은 30일입니다.`,
      `Points issued are valid for 30 days after the issue date.`,
      't_referral.terms_2.desc_4',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_5: [
      `지급된 포인트는 30만원 이상 수업권 구매시 사용 가능합니다.`,
      `The rewarded points can be used to purchase credits worth $231 or more.`,
      't_referral.terms_2.desc_5',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const n_event = {
  title: [`이벤트`, `Events`, 'n_event.title', `活动`, `活動`, `イベント`, ``],
  section_event: {
    title: [
      `진행중인 이벤트`,
      `Events`,
      'n_event.section_event.title',
      `进行中的活动`,
      `進行中的活動`,
      `開催中のイベント`,
      ``,
    ],
  },
  section_blog: {
    title: [
      `링글 블로그`,
      `Ringle Blog`,
      'n_event.section_blog.title',
      `Ringle部落格`,
      `Ringle部落格`,
      `Ringleブログ`,
      ``,
    ],
    btn: [
      `링글 블로그에서 더보기`,
      `Read more on the Ringle Blog`,
      'n_event.section_blog.btn',
      `到Ringle部落格看更多`,
      `到Ringle部落格看更多`,
      `Ringleブログをもっと見る`,
      ``,
    ],
  },
  section_upcoming: {
    title: (month) => [
      `${month}월의 이벤트`,
      `This Month's Events`,
      'n_event.section_upcoming.title',
      `${month}月的活动`,
      `${month}月的活動`,
      `${month}月のイベント`,
      ``,
    ],
    value: (number) => [
      `${number}개`,
      `${number}`,
      'n_event.section_upcoming.value',
      `${number}个`,
      `${number}個`,
      `${number}個`,
      ``,
    ],
    label_1: [
      `함께&혼자 읽기`,
      `Reading Program`,
      'n_event.section_upcoming.label_1',
      `阅读活动`,
      `閱讀活動`,
      `リーディングプログラム`,
      ``,
    ],
    label_2: [
      `링글 LIVE`,
      `Ringle LIVE`,
      'n_event.section_upcoming.label_2',
      `Ringle LIVE`,
      `Ringle LIVE`,
      `RingleLIVE`,
      ``,
    ],
    label_3: [`프로모션`, `Promotion`, 'n_event.section_upcoming.label_3', `促销活动`, `促銷活動`, `キャンペーン`, ``],
    label_4: [`이벤트`, `Event`, 'n_event.section_upcoming.label_4', `活动`, `活動`, `イベント`, ``],
    label_5: [`챌린지`, `Challenge`, 'n_event.section_upcoming.label_5', `挑战`, `挑戰`, `チャレンジ`, ``],
    hide: [`접기`, `Hide`, 'n_event.section_upcoming.hide', `隐藏`, `隱藏`, `隠す`, ``],
    see_more: (number) => [
      `${number}개의 이벤트 전체보기`,
      `View ${number} more`,
      'n_event.section_upcoming.see_more',
      `多看${number}个`,
      `多看${number}個`,
      `もっと見る ${number}個`,
      ``,
    ],
  },
  banner: {
    title: [
      `이벤트 웨비나 진행 안내`,
      `Event Webinar`,
      'n_event.banner.title',
      `研讨会活动`,
      `研討會活動`,
      `イベントウェビナー開催のご案内`,
      ``,
    ],
    desc_1: (event_webinar_name) => [
      `${event_webinar_name}이(가) 진행 중입니다. 지금 입장해 주세요!`,
      `${event_webinar_name} is currently in progress. Please join now!`,
      'n_event.banner.desc_1',
      `${event_webinar_name}正在进行中，请入场！`,
      `${event_webinar_name}正在進行中，請入場！`,
      `${event_webinar_name}が開催中です。今すぐご参加ください！`,
      ``,
    ],
    desc_2: (event_webinar_name) => [
      `${event_webinar_name}이(가) 곧 시작합니다! 지금 입장해 주세요.`,
      `${event_webinar_name} will start shortly! Join now.`,
      'n_event.banner.desc_2',
      `${event_webinar_name}即将开始，请入场！`,
      `${event_webinar_name}即將開始，請入場！`,
      `只今${event_webinar_name}が開催中です！今すぐご参加ください。`,
      ``,
    ],
    desc_3: (event_webinar_name, time) => [
      `${event_webinar_name}이(가) ${time} 후 시작할 예정입니다.`,
      `${event_webinar_name} will begin in ${time}.`,
      'n_event.banner.desc_3',
      `${event_webinar_name}将在 ${time} 开始。`,
      `${event_webinar_name}將在 ${time} 開始。`,
      `${event_webinar_name}が${time}後に開始する予定です。`,
      ``,
    ],
    btn_1: [`입장하기`, `Join`, 'n_event.banner.btn_1', `入场`, `入場`, `参加する`, ``],
    btn_2: [`자세히 보기`, `View detail`, 'n_event.banner.btn_2', `看更多`, `看更多`, `詳細を見る`, ``],
  },
  list: {
    tap_1: [
      `진행중인 이벤트`,
      `In Progress`,
      'n_event.list.tap_1',
      `进行中的活动`,
      `進行中的活動`,
      `開催中のイベント`,
      ``,
    ],
    tap_2: [`종료된 이벤트`, `Closed`, 'n_event.list.tap_2', `已结束的活动`, `已結束的活動`, `終了したイベント`, ``],
    menu_1: [`전체`, `All`, 'n_event.list.menu_1', `全部`, `全部`, `すべて`, ``],
    menu_2: [`라이브`, `LIVE`, 'n_event.list.menu_2', `LIVE`, `LIVE`, `ライブ`, ``],
    menu_3: [`다시보기`, `VOD`, 'n_event.list.menu_3', `重新观看`, `重新觀看`, `録画`, ``],
    menu_4: [`할인/혜택`, `Benefits`, 'n_event.list.menu_4', `折扣/优惠`, `折扣/優惠`, `割引/特典`, ``],
    menu_5: [`기타`, `Others`, 'n_event.list.menu_5', `其他`, `其他`, `その他`, ``],
    menu_6: [`프로모션`, `Promotion`, 'n_event.list.menu_6', `促销`, `促銷`, `キャンペーン`, ``],
  },
  card: {
    label_1: [`LIVE`, `LIVE`, 'n_event.card.label_1', `LIVE`, `LIVE`, `ライブ`, ``],
    label_2: [`이벤트`, `Event`, 'n_event.card.label_2', `活动`, `活動`, `イベント`, ``],
    label_3: [`진행중`, `In Progress`, 'n_event.card.label_3', `进行中`, `進行中`, `進行中`, ``],
    label_4: [`종료`, `Closed`, 'n_event.card.label_4', `结束`, `結束`, `終了`, ``],
    label_5: [`다시보기`, `VOD`, 'n_event.card.label_5', `重新观看`, `重新觀看`, `録画`, ``],
    label_6: [`신청 마감`, `Closed`, 'n_event.card.label_6', `申请截止`, `申請截止`, `申し込み締め切り`, ``],
    label_7: [`오늘 마감`, `ENDS Today`, 'n_event.card.label_7', `今天截止`, `今天截止`, `本日締め切り`, ``],
    label_8: (date) => [
      `마감 임박 D-${date}`,
      `Ending Soon D-${date}`,
      'n_event.card.label_8',
      `即将结束 D-${date}`,
      `即將結束 D-${date}`,
      `締切間近 D-${date}`,
      ``,
    ],
    label_9: [`신청 가능`, `Now open`, 'n_event.card.label_9', `接受申请中`, `接受申請中`, `申請可能`, ``],
    caption_1: (date) => [
      `신청마감 : ${date}까지`,
      `Deadline : ${date}`,
      'n_event.card.caption_1',
      `申请截止 : 到${date}为止`,
      `申請截止 : 到${date}為止`,
      `申し込み締め切り : ${date} まで`,
      ``,
    ],
    caption_2: (date) => [
      `진행일시 : ${date}`,
      `Date : ${date}`,
      'n_event.card.caption_2',
      `活动日期 : ${date}`,
      `活動日期 : ${date}`,
      `開催日時 : ${date}`,
      ``,
    ],
    caption_3: [`상시진행`, `Ongoing`, 'n_event.card.caption_3', `进行中`, `進行中`, `進行中`, ``],
  },
  button: {
    label_1: [
      `이벤트가 종료되었습니다.`,
      `The event has ended.`,
      'n_event.button.label_1',
      `活动已经结束`,
      `活動已經結束`,
      `イベントは終了しました。`,
      ``,
    ],
    label_2: [`입장하기`, `Join`, 'n_event.button.label_2', `入场`, `入場`, `参加する`, ``],
    label_3: [
      `신청이 완료되었습니다.`,
      `Your application has been submitted.`,
      'n_event.button.label_3',
      `已送出申请`,
      `已送出申請`,
      `応募が完了しました。`,
      ``,
    ],
    label_4: [
      `모집이 마감되었습니다`,
      `The application period has ended.`,
      'n_event.button.label_4',
      `申请时间已结束`,
      `申請時間已結束`,
      `募集は終了しました`,
      ``,
    ],
    label_5: [`신청하기`, `Sign up`, 'n_event.button.label_5', `申请`, `申請`, `お申し込みはこちら`, ``],
  },
  toast: {
    lable_1: [
      `신청했어요.`,
      `Successfully signed up.`,
      'n_event.toast.lable_1',
      `已申请`,
      `已申請`,
      `お申し込み完了`,
      ``,
    ],
  },
  detail_menu: {
    title_1: [`웨비나 소개`, `Introduction`, 'n_event.detail_menu.title_1', `介绍`, `介紹`, `紹介`, ``],
    title_2: [`이벤트 안내`, `Information`, 'n_event.detail_menu.title_2', `活动介绍`, `活動介紹`, `イベント案内`, ``],
    title_3: [`FAQ`, `FAQ`, 'n_event.detail_menu.title_3', `常见问题`, `常見問題`, `注意事項`, ``],
    title_4: [
      `추천 컨텐츠`,
      `Contents`,
      'n_event.detail_menu.title_4',
      `推荐教材`,
      `推薦教材`,
      `おすすめコンテンツ`,
      ``,
    ],
  },
}
export const t_signup = {
  password_length: [
    `8자 이상의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요.`,
    `Enter at least 8 characters, with uppercase, lowercase, numbers, and special characters.`,
    't_signup.password_length',
    ``,
    ``,
    ``,
    ``,
  ],
}
export const t_tutor_profile = {
  summary: {
    title: [`튜터 자기소개 요약`, `Summary`, 't_tutor_profile.summary.title', `摘要`, `摘要`, `要約`, ``],
    tooltip: [
      `튜터가 남긴 자기소개를 GPT로 요약한 내용입니다.`,
      `This is a summary of the tutor's profile generated by GPT.`,
      't_tutor_profile.summary.tooltip',
      `这是由GPT生成的导师个人资料信息摘要。`,
      `這是由GPT生成的導師個人資料信息摘要。`,
      `これはGPTによって生成されたチューターのプロフィール情報の要約です。`,
      ``,
    ],
  },
}
export const t_prep = {
  expression: {
    tab: [`필수 단어`, `Key Expressions`, 't_prep.expression.tab', ``, ``, ``, ``],
    pronunciation_btn: [`발음 듣기`, `Listen`, 't_prep.expression.pronunciation_btn', ``, ``, ``, ``],
    example_btn: [`예문 보기`, `Examples`, 't_prep.expression.example_btn', ``, ``, ``, ``],
    en_definition: [`English Definition`, `English Definition`, 't_prep.expression.en_definition', ``, ``, ``, ``],
    example: [`예문`, `Example Sentences`, 't_prep.expression.example', ``, ``, ``, ``],
  },
}
export const t_material = {
  previous: {
    label: [`수업한 교재`, `Learned`, 't_material.previous.label', ``, ``, ``, ``],
  },
  btn_select: [`교재 선택`, `Select`, 't_material.btn_select', ``, ``, ``, ``],
  btn_cancel: [`선택 취소`, `Cancel`, 't_material.btn_cancel', ``, ``, ``, ``],
  numbers: {
    title: (number) => [`${number}개의 교재`, `${number} materials`, 't_material.numbers.title', ``, ``, ``, ``],
  },
  sorting: {
    title: [`정렬`, ``, 't_material.sorting.title', ``, ``, ``, ``],
    option_1: [`인기순`, ``, 't_material.sorting.option_1', ``, ``, ``, ``],
    option_2: [`최신순`, ``, 't_material.sorting.option_2', ``, ``, ``, ``],
  },
  category: {
    favorite: [`찜한 교재`, `Favorite`, 't_material.category.favorite', ``, ``, ``, ``],
    grade: [`학년`, `Grades`, 't_material.category.grade', ``, ``, ``, ``],
    level: [`레벨`, `Level`, 't_material.category.level', ``, ``, ``, ``],
    style: [`수업 방식`, `Lesson style`, 't_material.category.style', ``, ``, ``, ``],
    topic: [`주제`, `Topics`, 't_material.category.topic', ``, ``, ``, ``],
    top: [`인기 교재`, `Popular`, 't_material.category.top', ``, ``, ``, ``],
  },
  grade: {
    option_1: [`1-3학년`, `Grade 1-3`, 't_material.grade.option_1', ``, ``, ``, ``],
    option_2: [`4-6학년`, `Grade 4-6`, 't_material.grade.option_2', ``, ``, ``, ``],
    option_3: [`7학년 이상`, `Grade 7+`, 't_material.grade.option_3', ``, ``, ``, ``],
    option_default: [`전체`, `All`, 't_material.grade.option_default', ``, ``, ``, ``],
    option_4: [`전체`, `All`, 't_material.grade.option_4', ``, ``, ``, ``],
  },
  level: {
    option_1: [`초급`, `Basic`, 't_material.level.option_1', ``, ``, ``, ``],
    option_2: [`중급`, `Intermediate`, 't_material.level.option_2', ``, ``, ``, ``],
    option_3: [`고급`, `Advanced`, 't_material.level.option_3', ``, ``, ``, ``],
  },
  style: {
    option_1: [`대화 연습`, `Conversation Practice`, 't_material.style.option_1', ``, ``, ``, ``],
    option_2: [`토론`, `Debate`, 't_material.style.option_2', ``, ``, ``, ``],
    option_3: [`글쓰기`, `Writing`, 't_material.style.option_3', ``, ``, ``, ``],
    option_4: [`진학·멘토링`, `Academic Mentoring`, 't_material.style.option_4', ``, ``, ``, ``],
    option_5: [`내 교재로 수업`, `Custom Class`, 't_material.style.option_5', ``, ``, ``, ``],
  },
  topic: {
    option_1: [`일상생활`, `Daily Life`, 't_material.topic.option_1', ``, ``, ``, ``],
    option_2: [`원서 읽기`, `Book Discussions`, 't_material.topic.option_2', ``, ``, ``, ``],
    option_3: [`사회·시사`, `Current Events`, 't_material.topic.option_3', ``, ``, ``, ``],
    option_4: [`역사·정치`, `History·Politics`, 't_material.topic.option_4', ``, ``, ``, ``],
    option_5: [`과학·테크`, `Science·Tech`, 't_material.topic.option_5', ``, ``, ``, ``],
    option_6: [`환경·동물`, `Environment·Animals`, 't_material.topic.option_6', ``, ``, ``, ``],
    option_7: [`예술·스포츠·미디어`, `Arts·Sports`, 't_material.topic.option_7', ``, ``, ``, ``],
  },
  filter: {
    btn_reset: [`초기화`, `Reset`, 't_material.filter.btn_reset', ``, ``, ``, ``],
    btn_apply: [`적용하기`, `Apply`, 't_material.filter.btn_apply', ``, ``, ``, ``],
  },
  empty: {
    title: [
      `조건을 만족하는 교재가 없어요.`,
      `No materials match this criteria.`,
      't_material.empty.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `필터를 다시 설정해보세요.`,
      `Please adjust the filter and try again.`,
      't_material.empty.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`필터 초기화`, `Refresh`, 't_material.empty.btn', ``, ``, ``, ``],
  },
  profile: {
    banner: [
      `나이에 맞는 교재를 먼저 보고싶다면?`,
      `Want to see materials suitable for your age?`,
      't_material.profile.banner',
      ``,
      ``,
      ``,
      ``,
    ],
    banner_btn: [`생년월일 입력하기`, `Enter your date of birth`, 't_material.profile.banner_btn', ``, ``, ``, ``],
  },
  tab_1: [`전체 교재`, `All`, 't_material.tab_1', ``, ``, ``, ``],
  booked: {
    label: [`예약된 교재`, `Selected material`, 't_material.booked.label', ``, ``, ``, ``],
  },
  tab_3: [`내 코스`, `My Courses`, 't_material.tab_3', ``, ``, ``, ``],
  course: {
    recommende_title: [`추천 코스`, `Curated Courses`, 't_material.course.recommende_title', ``, ``, ``, ``],
    btn_all: [`코스 전체보기`, `View all courses`, 't_material.course.btn_all', ``, ``, ``, ``],
  },
}
export const t_tutor = {
  strength: {
    option_1: [`대화 유도`, `Conversation`, 't_tutor.strength.option_1', ``, ``, ``, ``],
    option_2: [`교정`, `Correction`, 't_tutor.strength.option_2', ``, ``, ``, ``],
    option_3: [`토론`, `Debate`, 't_tutor.strength.option_3', ``, ``, ``, ``],
    option_4: [`멘토링`, `Mentoring`, 't_tutor.strength.option_4', ``, ``, ``, ``],
    tooltip: [
      <>
        [대화 유도 강점] 초급 영어 학생을 위한 튜터로 수업 중 활발하게 대화 유도를 진행할 수 있습니다.
        <br />
        [교정 강점] 중고급 영어 학생을 위한 튜터로 고급 어휘 지도와 문장 구조 교정 수업에 적합합니다.
        <br />
        [토론 강점] 중고급 영어 학생을 위한 튜터로 토론, 모의 UN, 대중 연설 등의 경험을 갖고 있습니다.
        <br />
        [멘토링 강점] 아이비리그, 옥스브리지, 의학/법학 전공 출신 튜터로 입시 및 진로 지도가 가능합니다.{' '}
      </>,
      <>
        [Conversation Facilitation] Recommended for beginner English students. Posses the ability to actively encourage
        and lead conversations.
        <br />
        [Correction] Recommended for intermediate to advanced English students. Skilled in correcting advanced
        vocabulary and sentence structures.
        <br />
        [Debate] Recommended for intermediate to advanced English students. Has experience in debate, Model UN, public
        speaking, and similar activities.
        <br />
        [Mentoring] Tutors from Ivy League, Oxbridge, medical/law background. Capable of providing guidance for college
        admissions and career paths.
      </>,
      't_tutor.strength.tooltip',
      ``,
      ``,
      ``,
      ``,
    ],
    filter_1: [`대화 유도 강점`, `Conversation Facilitation`, 't_tutor.strength.filter_1', ``, ``, ``, ``],
    filter_2: [`교정 강점`, `Correction`, 't_tutor.strength.filter_2', ``, ``, ``, ``],
    filter_3: [`토론 강점`, `Debate`, 't_tutor.strength.filter_3', ``, ``, ``, ``],
    filter_4: [`멘토링 강점`, `Mentoring`, 't_tutor.strength.filter_4', ``, ``, ``, ``],
  },
  list_number: (number) => [`${number}명의 튜터`, `${number} tutors`, 't_tutor.list_number', ``, ``, ``, ``],
  search_placehoder: [
    `튜터의 이름 또는 전공으로 검색하세요.`,
    `Enter a keyword`,
    't_tutor.search_placehoder',
    ``,
    ``,
    ``,
    ``,
  ],
  category: {
    strength: [`수업 강점`, `Teaching Strength`, 't_tutor.category.strength', ``, ``, ``, ``],
    dep: [`전공`, `Major`, 't_tutor.category.dep', ``, ``, ``, ``],
    gen: [`성별`, `Gender`, 't_tutor.category.gen', ``, ``, ``, ``],
    acc: [`발음`, `Accent`, 't_tutor.category.acc', ``, ``, ``, ``],
  },
  dep: {
    filter_1: [`사회과학계열`, `Social Sciences`, 't_tutor.dep.filter_1', ``, ``, ``, ``],
    filter_2: [`인문계열`, `Liberal Arts`, 't_tutor.dep.filter_2', ``, ``, ``, ``],
    filter_3: [`상경계열`, `Business Administration`, 't_tutor.dep.filter_3', ``, ``, ``, ``],
    filter_4: [`자연과학계열`, `Natural Science`, 't_tutor.dep.filter_4', ``, ``, ``, ``],
    filter_5: [`공학계열`, `Engineering`, 't_tutor.dep.filter_5', ``, ``, ``, ``],
    filter_6: [`의학계열`, `Medical Sciences`, 't_tutor.dep.filter_6', ``, ``, ``, ``],
    filter_7: [`법학계열`, `Law`, 't_tutor.dep.filter_7', ``, ``, ``, ``],
    filter_8: [`예체능계열`, `Fine Arts and Athletics`, 't_tutor.dep.filter_8', ``, ``, ``, ``],
  },
  gen: {
    filter_1: [`남자`, `Male`, 't_tutor.gen.filter_1', ``, ``, ``, ``],
    filter_2: [`여자`, `Female`, 't_tutor.gen.filter_2', ``, ``, ``, ``],
  },
  acc: {
    filter_1: [`미국식 발음`, `American`, 't_tutor.acc.filter_1', ``, ``, ``, ``],
    filter_2: [`영국식 발음`, `British`, 't_tutor.acc.filter_2', ``, ``, ``, ``],
  },
  btn: {
    reset: [`초기화`, `Reset`, 't_tutor.btn.reset', ``, ``, ``, ``],
    reset_filter: [`필터 초기화`, `Refresh`, 't_tutor.btn.reset_filter', ``, ``, ``, ``],
  },
  filter: {
    empty_desc_title: [
      `조건을 만족하는 튜터가 없어요.`,
      `No tutors match this criteria.`,
      't_tutor.filter.empty_desc_title',
      ``,
      ``,
      ``,
      ``,
    ],
    empty_desc_sub: [
      `필터를 다시 설정해보세요.`,
      `Please adjust the filter and try again.`,
      't_tutor.filter.empty_desc_sub',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const t_speciallecture = {
  mainmenu: {
    title: [`특강`, `Webinars`, 't_speciallecture.mainmenu.title', ``, ``, ``, ``],
  },
  menu: {
    option_1: [`신청 가능한 특강`, `Available Webinars`, 't_speciallecture.menu.option_1', ``, ``, ``, ``],
    option_2: [`종료된 특강`, `Past Webinars`, 't_speciallecture.menu.option_2', ``, ``, ``, ``],
    option_3: [`내 특강`, `My Webinars`, 't_speciallecture.menu.option_3', ``, ``, ``, ``],
  },
  month: {
    option_1: (yyyy, m) => [
      <>
        {yyyy}년 {m}월
      </>,
      <>
        {yyyy}.{m}
      </>,
      't_speciallecture.month.option_1',
      ``,
      ``,
      ``,
      ``,
    ],
    option_2: (amount) => [
      <>{amount}개</>,
      <>{amount} Webinar(s)</>,
      't_speciallecture.month.option_2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  grade: {
    option_1: [`Grade 1-3`, `Grade 1-3`, 't_speciallecture.grade.option_1', ``, ``, ``, ``],
    option_2: [`Grade 4-6`, `Grade 4-6`, 't_speciallecture.grade.option_2', ``, ``, ``, ``],
    option_3: [`Grade 7+`, `Grade 7+`, 't_speciallecture.grade.option_3', ``, ``, ``, ``],
  },
  category: {
    label_1: [`원서읽기`, `Read Together: Book`, 't_speciallecture.category.label_1', ``, ``, ``, ``],
    label_2: [`영자신문읽기`, `Read Together: News`, 't_speciallecture.category.label_2', ``, ``, ``, ``],
    label_3: [`강의`, `Seminar`, 't_speciallecture.category.label_3', ``, ``, ``, ``],
    label_4: [`멘토링`, `Mentoring`, 't_speciallecture.category.label_4', ``, ``, ``, ``],
    label_5: [`설명회`, `Orientation`, 't_speciallecture.category.label_5', ``, ``, ``, ``],
    label_6: [`다시보기`, `Replay`, 't_speciallecture.category.label_6', ``, ``, ``, ``],
  },
  status: {
    label_1: [`다시보기`, `Replay`, 't_speciallecture.status.label_1', ``, ``, ``, ``],
    label_2: [`오늘예정`, `Today`, 't_speciallecture.status.label_2', ``, ``, ``, ``],
  },
  filter: {
    option_1: [`전체`, `All`, 't_speciallecture.filter.option_1', ``, ``, ``, ``],
    option_2: [`원서읽기`, `Read Together: Book`, 't_speciallecture.filter.option_2', ``, ``, ``, ``],
    option_3: [`강의`, `Seminar`, 't_speciallecture.filter.option_3', ``, ``, ``, ``],
    option_4: [`영자신문읽기`, `Read Together: News`, 't_speciallecture.filter.option_4', ``, ``, ``, ``],
    option_5: [`설명회`, `Orientation`, 't_speciallecture.filter.option_5', ``, ``, ``, ``],
    option_6: [`멘토링`, `Mentoring`, 't_speciallecture.filter.option_6', ``, ``, ``, ``],
    option_7: [`종료된 특강`, `Past Webinars`, 't_speciallecture.filter.option_7', ``, ``, ``, ``],
  },
  notice: {
    option_7: [
      `원서읽기 특강은 같은 달에 최대 2개 참여할 수 있습니다.`,
      `You can participate in up to two Read Together: Book series per month.`,
      't_speciallecture.notice.option_7',
      ``,
      ``,
      ``,
      ``,
    ],
    option_1: [
      <>
        온라인 특강 이용권이 없어요!
        <br />
        특강 이용권은 수업권을 구매하면 받을 수 있어요.
      </>,
      <>
        You have 0 webinar passes!
        <br />
        You can get webinar passes by buying credits.
      </>,
      't_speciallecture.notice.option_1',
      ``,
      ``,
      ``,
      ``,
    ],
    option_2: [
      <>
        아직 특강을 신청하지 않았어요!
        <br />
        링글 틴즈만 수강할 수 있는 특강을 신청해 보세요.
      </>,
      <>
        You are not enrolled in any webinars yet!
        <br />
        Enhance your learning through Ringle Teens exclusive webinars.
      </>,
      't_speciallecture.notice.option_2',
      ``,
      ``,
      ``,
      ``,
    ],
    option_3: [
      `신청한 특강 중 종료된 특강이 없어요.`,
      `bThere are no past webinars among the ones you've enrolled.`,
      't_speciallecture.notice.option_3',
      ``,
      ``,
      ``,
      ``,
    ],
    option_4: [
      `종료된 특강이 없어요.`,
      `There are no past webinars.`,
      't_speciallecture.notice.option_4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  history_year: {
    title_1: (yyyy) => [<>{yyyy}년</>, <>{yyyy}</>, 't_speciallecture.history_year.title_1', ``, ``, ``, ``],
  },
  history_month: {
    title_1: (mm) => [<>{mm}월</>, <>{mm}</>, 't_speciallecture.history_month.title_1', ``, ``, ``, ``],
  },
  history: {
    title_1: [`2023년`, ``, 't_speciallecture.history.title_1', ``, ``, ``, ``],
    title_2: [`2024년`, ``, 't_speciallecture.history.title_2', ``, ``, ``, ``],
  },
  my: {
    title_1: [`남은 특강 이용권`, `Webinar passes`, 't_speciallecture.my.title_1', ``, ``, ``, ``],
  },
  mynotice: {
    caption: (event_webinar_name) => [
      `${event_webinar_name}이(가) 오늘 진행될 예정입니다.`,
      `${event_webinar_name} is scheduled to be held today.`,
      't_speciallecture.mynotice.caption',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  myfilter: {
    option_1: [`신청한 특강`, `Enrolled`, 't_speciallecture.myfilter.option_1', ``, ``, ``, ``],
    option_2: [`종료된 특강`, `Concluded`, 't_speciallecture.myfilter.option_2', ``, ``, ``, ``],
  },
  mycta: {
    option_1: [`수업권 구매하러 가기`, `Buy credits`, 't_speciallecture.mycta.option_1', ``, ``, ``, ``],
    option_2: [
      `신청 가능한 특강 둘러보기`,
      `View available webinars`,
      't_speciallecture.mycta.option_2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  details_menu: {
    option_1: [`상세 정보`, `Overview`, 't_speciallecture.details_menu.option_1', ``, ``, ``, ``],
    option_2: [`자료 및 다시보기`, `Materials & Replay`, 't_speciallecture.details_menu.option_2', ``, ``, ``, ``],
    option_3: [`자주 묻는 질문`, `FAQs`, 't_speciallecture.details_menu.option_3', ``, ``, ``, ``],
  },
  details_title: {
    option_1: [`상세 정보`, `Overview`, 't_speciallecture.details_title.option_1', ``, ``, ``, ``],
    option_2: [`자료 및 다시보기`, `Materials & Replay`, 't_speciallecture.details_title.option_2', ``, ``, ``, ``],
    option_3: [`자주 묻는 질문`, `FAQs`, 't_speciallecture.details_title.option_3', ``, ``, ``, ``],
  },
  details_fixed: {
    title_1: [`라이브 기간`, `Duration`, 't_speciallecture.details_fixed.title_1', ``, ``, ``, ``],
    title_2: [`회차 안내`, `Sessions`, 't_speciallecture.details_fixed.title_2', ``, ``, ``, ``],
    title_3: (n) => [<>{n}회차</>, <>Session {n}</>, 't_speciallecture.details_fixed.title_3', ``, ``, ``, ``],
    discription_1: (dd, time) => [
      <>
        {dd}일 {time} 남음
      </>,
      <>
        {dd}day(s) {time} left
      </>,
      't_speciallecture.details_fixed.discription_1',
      ``,
      ``,
      ``,
      ``,
    ],
    cta_1: [`신청하기`, `Enroll`, 't_speciallecture.details_fixed.cta_1', ``, ``, ``, ``],
    cta_2: [
      `시작 15분 전부터 입장 가능`,
      `Join 15 mins before start time.`,
      't_speciallecture.details_fixed.cta_2',
      ``,
      ``,
      ``,
      ``,
    ],
    cta_3: [`입장하기`, `Enter`, 't_speciallecture.details_fixed.cta_3', ``, ``, ``, ``],
    cta_4: [`종료된 특강`, `Concluded`, 't_speciallecture.details_fixed.cta_4', ``, ``, ``, ``],
    cta_5: [
      `지금은 다시보기만 가능합니다`,
      `Only replay is available.`,
      't_speciallecture.details_fixed.cta_5',
      ``,
      ``,
      ``,
      ``,
    ],
    notice_title: [
      `꼭 확인해주세요!`,
      `Important notice!`,
      't_speciallecture.details_fixed.notice_title',
      ``,
      ``,
      ``,
      ``,
    ],
    notice_1: [
      `특강 시작 15분 전부터 이 페이지 [입장하기] 버튼을 통해 특강에 입장할 수 있어요.`,
      `You can enter the webinar from 15 mins before it is scheduled to start by clicking the [Enter] button on this page.`,
      't_speciallecture.details_fixed.notice_1',
      ``,
      ``,
      ``,
      ``,
    ],
    notice_2: (time) => [
      <>학습 자료와 다시보기는 특강 종료 후에도 {time}까지 확인할 수 있어요.</>,
      <>You will be able to access the materials and replay until {time}.</>,
      't_speciallecture.details_fixed.notice_2',
      ``,
      ``,
      ``,
      ``,
    ],
    notice_3: [
      `특강은 원어민 튜터와 함께 영어로 진행됩니다.`,
      `The webinar is held in English with native tutors.`,
      't_speciallecture.details_fixed.notice_3',
      ``,
      ``,
      ``,
      ``,
    ],
    notice_4: [
      `특강시작 1시간 전, 10분 전 알림을 보내드립니다.`,
      `You will receive a reminder 1 hour and 10 minutes before the webinar starts.`,
      't_speciallecture.details_fixed.notice_4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  Notification: {
    option_1: [
      `특강을 신청했어요.`,
      `You have been successfully enrolled.`,
      't_speciallecture.Notification.option_1',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  modal_1: {
    title: (seminar_credit_name) => [
      `${seminar_credit_name}으로 특강을 신청하시겠어요?`,
      `Do you want to enroll for the webinar using ${seminar_credit_name}?`,
      't_speciallecture.modal_1.title',
      ``,
      ``,
      ``,
      ``,
    ],
    Description: [
      `한번 신청한 특강은 취소할 수 없어요.`,
      `You cannot drop out of the webinar once you have enrolled.`,
      't_speciallecture.modal_1.Description',
      ``,
      ``,
      ``,
      ``,
    ],
    cta_1: [`취소`, `Cancel`, 't_speciallecture.modal_1.cta_1', ``, ``, ``, ``],
    cta_2: [`신청`, `Enroll`, 't_speciallecture.modal_1.cta_2', ``, ``, ``, ``],
  },
  modal_2: {
    title: [
      `신청에 필요한 특강 이용권이 없어요. 이용권은 수업권 구매 시 받을 수 있어요.`,
      `You do not have the pass you need to enroll for the webinar. Passes can be obtained by buying credits.`,
      't_speciallecture.modal_2.title',
      ``,
      ``,
      ``,
      ``,
    ],
    cta_1: [`수업권 구매`, `Buy credits`, 't_speciallecture.modal_2.cta_1', ``, ``, ``, ``],
  },
  modal_3: {
    title: (seminar_credit_name) => [
      `${seminar_credit_name}으로 특강을 신청하시겠어요?`,
      `Do you want to enroll for the webinar using ${seminar_credit_name}?`,
      't_speciallecture.modal_3.title',
      ``,
      ``,
      ``,
      ``,
    ],
    Description: [
      `한번 신청한 특강은 취소할 수 없으며, 원서 읽기는 같은 달에 최대 2개까지 참여할 수 있어요.`,
      ``,
      't_speciallecture.modal_3.Description',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  modal_4: {
    title: [
      `이 달엔 이미 2개의 원서 읽기 특강에 참여 신청 했어요.`,
      `You are already enrolled in two Read Together:Book series this month.`,
      't_speciallecture.modal_4.title',
      ``,
      ``,
      ``,
      ``,
    ],
    Description: [
      `원서 읽기는 같은 달에 최대 2개까지 참여할 수 있어요. 다음 달 원서 읽기 특강을 신청해 주세요.`,
      `You can participate in up to two Read Together: Book series per month. Please try enrolling for next month's Read Together:Book series.`,
      't_speciallecture.modal_4.Description',
      ``,
      ``,
      ``,
      ``,
    ],
    cta_1: [`신청 가능한 특강 보기`, `View all available webinars`, 't_speciallecture.modal_4.cta_1', ``, ``, ``, ``],
  },
  student_1: {
    enter_title: [`예정된 특강`, `Upcoming Webinars`, 't_speciallecture.student_1.enter_title', ``, ``, ``, ``],
  },
  student_2: {
    enter_title: [`예정된 수업`, `Upcoming Lessons`, 't_speciallecture.student_2.enter_title', ``, ``, ``, ``],
  },
  student: {
    cta: [`입장하기`, `Enter`, 't_speciallecture.student.cta', ``, ``, ``, ``],
    countdown: (time) => [
      <>{time} 후 시작</>,
      <>Starts in {time}</>,
      't_speciallecture.student.countdown',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  parents: {
    title: [`신청한 특강`, `Enrolled Webinars`, 't_speciallecture.parents.title', ``, ``, ``, ``],
    description: [
      `신청한 특강이 없어요.`,
      `You have not enrolled in any webinars.`,
      't_speciallecture.parents.description',
      ``,
      ``,
      ``,
      ``,
    ],
    cta: [`전체 특강 보기`, `View all webinars`, 't_speciallecture.parents.cta', ``, ``, ``, ``],
  },
  payment_completed: {
    title: [`추가 혜택`, `Bonus`, 't_speciallecture.payment_completed.title', ``, ``, ``, ``],
    banner_title: [
      <>
        링글 틴즈 회원 한정!
        <br />
        온라인 특강 이용권이 제공되었어요!
      </>,
      <>
        Ringle Teens members only!
        <br />
        You received free Webinar Passes
      </>,
      't_speciallecture.payment_completed.banner_title',
      ``,
      ``,
      ``,
      ``,
    ],
    banner_dis: [
      `학부모 대시보드에서 확인할 수 있어요`,
      `Check it out on the Parent Dashboard`,
      't_speciallecture.payment_completed.banner_dis',
      ``,
      ``,
      ``,
      ``,
    ],
    cta: [`수업 예약 하러가기`, `Book a lesson`, 't_speciallecture.payment_completed.cta', ``, ``, ``, ``],
    notice: [
      `특강 이용권으로 약속드린 특강 혜택을 모두 참여할 수 있어요.`,
      `With your Webinar Pass, you can participate in all the promised webinar benefits.`,
      't_speciallecture.payment_completed.notice',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  replay: {
    title_1: [`상세 정보`, `Overview`, 't_speciallecture.replay.title_1', ``, ``, ``, ``],
    title_2: [`자료 및 라이브`, `Materials & Replay`, 't_speciallecture.replay.title_2', ``, ``, ``, ``],
    title_3: [`자주 묻는 질문`, `FAQs`, 't_speciallecture.replay.title_3', ``, ``, ``, ``],
  },
  replay_in: {
    title_1: [`다시보기`, `Replay`, 't_speciallecture.replay_in.title_1', ``, ``, ``, ``],
    title_2: [`학습자료`, `Materials`, 't_speciallecture.replay_in.title_2', ``, ``, ``, ``],
    disc_1: [
      `다시보기는 라이브 후 일주일 내로 업데이트 됩니다.`,
      `Replay videos will be uploaded within one week after the live session ends.`,
      't_speciallecture.replay_in.disc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    cta_1: [`목록보기`, `View List`, 't_speciallecture.replay_in.cta_1', ``, ``, ``, ``],
    cta_2: [`다음 학습`, `Next`, 't_speciallecture.replay_in.cta_2', ``, ``, ``, ``],
    disc_2: [
      `특강 자료와 특강 다시보기 영상은 신청자에 한해 제공됩니다.`,
      `Webinar materials and replay videos are provided exclusively to enrolled participants.`,
      't_speciallecture.replay_in.disc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    disc_3: [
      `특강 자료는 첫 회차 시작일에 업로드되며, 특강 다시보기 영상은 실시간 강의 종료 후 일주일 이내에 업로드됩니다.`,
      `Webinar materials will be uploaded on the start date of the first session, and replay videos will be uploaded within one week after the live session ends.`,
      't_speciallecture.replay_in.disc_3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  pass_title: {
    label_1: [`원서읽기 이용권`, `Read Together Book Pass`, 't_speciallecture.pass_title.label_1', ``, ``, ``, ``],
    label_2: [`영자신문읽기 이용권`, `Read Together News Pass`, 't_speciallecture.pass_title.label_2', ``, ``, ``, ``],
    label_3: [`세미나 이용권`, `Seminar Pass`, 't_speciallecture.pass_title.label_3', ``, ``, ``, ``],
    label_4: [`멘토링 이용권`, `Mentoring Pass`, 't_speciallecture.pass_title.label_4', ``, ``, ``, ``],
    label_5: [`설명회 이용권`, `Orientation Pass`, 't_speciallecture.pass_title.label_5', ``, ``, ``, ``],
  },
  already: {
    label: [`신청한 특강`, ``, 't_speciallecture.already.label', ``, ``, ``, ``],
  },
}
export const t_lecture = {
  title: {
    title: [`특강`, `Webinars`, 't_lecture.title.title', ``, ``, ``, ``],
  },
}
export const t_curriculum = {
  section_title: {
    caption: [`신규회원 선물 도착!`, `Gift for newcomers!`, 't_curriculum.section_title.caption', ``, ``, ``, ``],
    title: [
      <>
        딱 한번만 받을 수 있는
        <br />
        무료 체험수업을 신청해 보세요!
      </>,
      <>
        Book your one-time only
        <br />
        free trial lesson!
      </>,
      't_curriculum.section_title.title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  timer_banner: {
    time: (days, hours, minutes) => [
      <>
        {days <= 0 ? '' : days + '일 '}
        {hours <= 0 ? '' : hours + '시간 '}
        {minutes <= 0 ? '' : minutes + '분 '}
      </>,
      <>
        {days <= 0 ? '' : days + 'd '}
        {hours <= 0 ? '' : hours + 'h '}
        {minutes <= 0 ? '' : minutes + 'm '}
      </>,
      't_curriculum.timer_banner.time',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `가 지나면 기회가 사라져요.`,
      `left before this offer ends.`,
      't_curriculum.timer_banner.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  section_head: {
    title: (child_name) => [
      <>
        {child_name} 학생을 위한
        <br />
        추천 코스
      </>,
      <>
        Our recommended course
        <br />
        for {child_name}
      </>,
      't_curriculum.section_head.title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  section_material: {
    title: [
      `링글에서는 이 코스로 수업할 수 있어요.`,
      `Browse recommended course.`,
      't_curriculum.section_material.title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  section_tutor: {
    title: [
      `링글 튜터들이 기다리고 있어요.`,
      `Check out these Ringle tutors.`,
      't_curriculum.section_tutor.title',
      ``,
      ``,
      ``,
      ``,
    ],
    caption: [
      `*체험 신청 단계에서 시간을 선택하면 예약 가능한 튜터를 확인할 수 있어요.`,
      `*You can see who's available when you select the time for the trial lesson.`,
      't_curriculum.section_tutor.caption',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  btn: [`무료 체험수업 신청하기`, `Book a free trial \blesson`, 't_curriculum.btn', ``, ``, ``, ``],
}
export const t_event = {
  chuseok_bannner: {
    title_pc: (name) => [
      <>
        {name}님이 <span className="text-[#F06C00]">특별한 추석 선물을 보냈어요!</span>
      </>,
      <>
        {name} has sent you <span className="text-[#F06C00]">a special Chuseok gift!</span>
      </>,
      't_event.chuseok_bannner.title_pc',
      ``,
      ``,
      ``,
      ``,
    ],
    title_mo: (name) => [
      <>
        {name}님이
        <br />
        <span className="text-[#F06C00]">특별한 추석 선물을 보냈어요!</span>
      </>,
      <>
        {name} has sent you
        <br />
        <span className="text-[#F06C00]">a special Chuseok gift!</span>
      </>,
      't_event.chuseok_bannner.title_mo',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  chuseok_banner: {
    desc_pc: [
      `지금 바로 가입하고 이번 가을, 자녀의 영어 실력을 키워보세요!`,
      `Sign up now and start building your child's English skills this fall!`,
      't_event.chuseok_banner.desc_pc',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_mo: [
      <>
        지금 바로 가입하고
        <br />
        이번 가을, 자녀의 영어 실력을 키워보세요! 
      </>,
      <>
        Sign up now
        <br />
        and start building your child's English skills this fall! 
      </>,
      't_event.chuseok_banner.desc_mo',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`선물 확인하기`, `Check your gift`, 't_event.chuseok_banner.btn', ``, ``, ``, ``],
  },
  chuseok_title: [`추석 선물 코드`, `Chuseok Gift Code`, 't_event.chuseok_title', ``, ``, ``, ``],
  chuseok_desc: [
    `선물 받은 틴즈 패키지 코드를 입력하세요.`,
    `Enter the code of the Ringle Teens gift package.`,
    't_event.chuseok_desc',
    ``,
    ``,
    ``,
    ``,
  ],
  chuseok_placeholder: [
    `추석 선물 코드 입력`,
    `Enter your Chuseok gift code`,
    't_event.chuseok_placeholder',
    ``,
    ``,
    ``,
    ``,
  ],
  toast: [`수업권과 포인트를 받았어요.`, `You have received your Chuseok gift.`, 't_event.toast', ``, ``, ``, ``],
}
export const t_common = {
  btn_close: [`닫기`, `Close`, 't_common.btn_close', ``, ``, ``, ``],
  btn_confirm: [`확인`, `Confirm`, 't_common.btn_confirm', ``, ``, ``, ``],
  btn_next: [`다음`, `Next`, 't_common.btn_next', ``, ``, ``, ``],
  btn_stop: [`그만하기`, `Leave`, 't_common.btn_stop', ``, ``, ``, ``],
  btn_book: [`예약하기`, `Book lessons`, 't_common.btn_book', ``, ``, ``, ``],
  btn_book_full: [`수업 예약하기`, `Book lessons`, 't_common.btn_book_full', ``, ``, ``, ``],
  btn_back: [`이전`, `Back`, 't_common.btn_back', ``, ``, ``, ``],
  btn_cancel: [`취소`, `Cancel`, 't_common.btn_cancel', ``, ``, ``, ``],
  btn_cancel_full: [`취소하기`, `Cancel`, 't_common.btn_cancel_full', ``, ``, ``, ``],
  btn_ok: [`확인`, `Ok`, 't_common.btn_ok', ``, ``, ``, ``],
  btn_ok_full: [`확인하기`, `Ok`, 't_common.btn_ok_full', ``, ``, ``, ``],
  btn_confirm_full: [`확인하기`, `Confirm`, 't_common.btn_confirm_full', ``, ``, ``, ``],
  btn_agree: [`동의`, `Agree`, 't_common.btn_agree', ``, ``, ``, ``],
  btn_agree_full: [`동의하기`, `Agree`, 't_common.btn_agree_full', ``, ``, ``, ``],
  btn_leave: [`나가기`, `Leave`, 't_common.btn_leave', ``, ``, ``, ``],
  btn_continue: [`계속하기`, `Continue`, 't_common.btn_continue', ``, ``, ``, ``],
  btn_refresh: [`초기화`, `Refresh`, 't_common.btn_refresh', ``, ``, ``, ``],
  btn_back_list: [`뒤로 가기`, `Back`, 't_common.btn_back_list', ``, ``, ``, ``],
  btn_more: [`더보기`, `View more`, 't_common.btn_more', ``, ``, ``, ``],
  btn_all: [`전체보기`, `View all`, 't_common.btn_all', ``, ``, ``, ``],
  btn_detail: [`자세히 보기`, `View detail`, 't_common.btn_detail', ``, ``, ``, ``],
  btn_share: [`공유하기`, `Share`, 't_common.btn_share', ``, ``, ``, ``],
  btn_delete: [`삭제`, `Delete`, 't_common.btn_delete', ``, ``, ``, ``],
  btn_filter: [`필터 적용`, `Apply Filter`, 't_common.btn_filter', ``, ``, ``, ``],
  btn_save: [`저장`, `Save`, 't_common.btn_save', ``, ``, ``, ``],
  btn_save_full: [`저장하기`, `Save`, 't_common.btn_save_full', ``, ``, ``, ``],
  btn_save_img: [`이미지 저장`, `Save image`, 't_common.btn_save_img', ``, ``, ``, ``],
  btn_copy_link: [`링크 복사`, `Copy link`, 't_common.btn_copy_link', ``, ``, ``, ``],
  btn_sign_up_full: [`회원가입 하기`, `Sign up`, 't_common.btn_sign_up_full', ``, ``, ``, ``],
  dont_show_again: [`다시 보지 않기`, `Don't show again`, 't_common.dont_show_again', ``, ``, ``, ``],
  btn_done: [`완료`, `Done`, 't_common.btn_done', ``, ``, ``, ``],
}
export const t_evaluation = {
  tutor_1: {
    title: (tutor_name) => [
      <>{tutor_name} 튜터와 다시 만나고 싶나요?</>,
      <>Do you want to have a lesson with {tutor_name} again?</>,
      't_evaluation.tutor_1.title',
      ``,
      ``,
      ``,
      ``,
    ],
    disc: [
      `걱정하지 마세요! 남긴 의견은 튜터에게 알려지지 않아요.`,
      `Don’t worry! Your feedback won’t be shared with the tutor.`,
      't_evaluation.tutor_1.disc',
      ``,
      ``,
      ``,
      ``,
    ],
    word_1: [
      `너무 좋았어요. 다음에 또 만나고 싶어요!`,
      <>
        The tutor was amazing!
        <br />
         I want to have a lesson with this tutor again.
      </>,
      't_evaluation.tutor_1.word_1',
      ``,
      ``,
      ``,
      ``,
    ],
    word_2: [
      `보통이었어요. 다른 튜터를 만나보고 싶어요.`,
      `The tutor was fine, but I’d like to try other tutors.`,
      't_evaluation.tutor_1.word_2',
      ``,
      ``,
      ``,
      ``,
    ],
    word_3: [
      `다음 수업에서는 만나고 싶지 않아요.`,
      `I don’t want to have another lesson with this tutor.`,
      't_evaluation.tutor_1.word_3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  stop: {
    title: [
      `수업 평가를 그만 할까요?`,
      `Do you want to end the evaluation?`,
      't_evaluation.stop.title',
      ``,
      ``,
      ``,
      ``,
    ],
    disc: [
      `수업 평가를 완료하지 않으면 분석 결과와 튜터 피드백을 확인할 수 없어요. [레슨] > [지난 수업]에서 수업 평가를 완료해 주세요.`,
      `If you don’t complete the lesson evaluation, you won’t be able to access the analysis results and tutor feedback. Please complete the evaluation under [Lesson] > [Past Lessons].`,
      't_evaluation.stop.disc',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_1: [`계속하기`, `Continue`, 't_evaluation.stop.btn_1', ``, ``, ``, ``],
  },
  class_2: {
    title: [
      `내가 원하던 수업이었나요?`,
      `Did this lesson meet your expectations?`,
      't_evaluation.class_2.title',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_1: [
      `링글 팀에게 문제 상황 알리기`,
      `Please let us know if the lesson didn’t take place.`,
      't_evaluation.class_2.btn_1',
      ``,
      ``,
      ``,
      ``,
    ],
    word_1: [`전혀 아니었어요`, `Not at all`, 't_evaluation.class_2.word_1', ``, ``, ``, ``],
    word_2: [`아쉬웠어요`, `Disappointing`, 't_evaluation.class_2.word_2', ``, ``, ``, ``],
    word_3: [`조금 아쉬웠어요`, `A bit disappointing`, 't_evaluation.class_2.word_3', ``, ``, ``, ``],
    word_4: [`좋았어요`, `Great`, 't_evaluation.class_2.word_4', ``, ``, ``, ``],
    word_5: [`딱 원하던 수업이예요!`, `Perfect!`, 't_evaluation.class_2.word_5', ``, ``, ``, ``],
  },
  matter: {
    title_1: (tutor_name) => [
      <>{tutor_name}의 수업에 어떤 문제가 있었나요?</>,
      <>Any issues with {tutor_name}’s lesson?</>,
      't_evaluation.matter.title_1',
      ``,
      ``,
      ``,
      ``,
    ],
    option_1: [
      `줌이 제대로 실행되지 않았어요.`,
      `Zoom didn't launch properly.`,
      't_evaluation.matter.option_1',
      ``,
      ``,
      ``,
      ``,
    ],
    option_2: [
      `비디오/오디오가 연결되지 않았어요.`,
      `Couldn't connect video/audio.`,
      't_evaluation.matter.option_2',
      ``,
      ``,
      ``,
      ``,
    ],
    option_3: [
      `튜터가 입장하지 않았어요.`,
      `The tutor didn't show up.`,
      't_evaluation.matter.option_3',
      ``,
      ``,
      ``,
      ``,
    ],
    option_4: [
      `정규 수업 시간을 충분히 채우지 못했어요.`,
      `The tutor was late.`,
      't_evaluation.matter.option_4',
      ``,
      ``,
      ``,
      ``,
    ],
    option_5: [
      `수업이 자꾸 끊겼어요.`,
      `The internet connection was bad.`,
      't_evaluation.matter.option_5',
      ``,
      ``,
      ``,
      ``,
    ],
    title_2: [
      `문제 상황을 자세히 설명해주세요.`,
      `Can you describe the problem(s) you experienced?`,
      't_evaluation.matter.title_2',
      ``,
      ``,
      ``,
      ``,
    ],
    placeholder: [
      `불편을 겪으신 상황을 자세히 알려주세요. 링글팀이 1 영업일 이내로 확인 후 연락드립니다.`,
      `Please tell us about the problem(s) you experienced in detail. We will get back to you via your registered email address within 1 business days.`,
      't_evaluation.matter.placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  matter_2: {
    title: [
      `링글팀에 전달했어요.`,
      `Your experience has been reported.`,
      't_evaluation.matter_2.title',
      ``,
      ``,
      ``,
      ``,
    ],
    disc: [
      `수업에 불편을 드려 죄송합니다. 제보해주신 내용은 1영업일 내로 처리 결과를 알려 드리겠습니다.`,
      `We apologize for the trouble you had. We will get back to you via your registered email address within 1 business days.`,
      't_evaluation.matter_2.disc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  overall_3: {
    title_1: [
      `이번 수업에 대해 더 알려주세요.`,
      `Please tell us more about this lesson.`,
      't_evaluation.overall_3.title_1',
      ``,
      ``,
      ``,
      ``,
    ],
    title_2: [
      `튜터는 어땠나요? (선택)`,
      `What did you think of the tutor? (Optional)`,
      't_evaluation.overall_3.title_2',
      ``,
      ``,
      ``,
      ``,
    ],
    title_3: [
      `교재가 수업에 도움이 되었나요? (선택)`,
      `Did you find this material helpful? (Optional)`,
      't_evaluation.overall_3.title_3',
      ``,
      ``,
      ``,
      ``,
    ],
    title_4: [
      `수업중 네트워크 환경은 어땠나요? (선택)`,
      `How was your internet connection during the lesson? (Optional)`,
      't_evaluation.overall_3.title_4',
      ``,
      ``,
      ``,
      ``,
    ],
    disc_1: [
      `자세한 튜터 후기는 튜터의 실력 상승과 더 만족스러운 수업을 도와줄 수 있습니다. 부적절한 단어/표현이 포함된 경우, 별도의 통지없이 삭제될 수 있습니다. (최소 20자 이상)`,
      `Your feedback helps us improve our tutors and the quality of our lessons.
Reviews containing inappropriate language may be removed without prior notice.`,
      't_evaluation.overall_3.disc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    disc_2: [`네트워크 환경`, `Network connection`, 't_evaluation.overall_3.disc_2', ``, ``, ``, ``],
    disc: [
      `의견을 남겨주세요. (선택)`,
      `Please share your thoughts. (Optional)`,
      't_evaluation.overall_3.disc',
      ``,
      ``,
      ``,
      ``,
    ],
    word_4: [
      `자세한 수업 후기는 20자 이상으로 입력해주세요.`,
      `The review must be at least 20 characters long.`,
      't_evaluation.overall_3.word_4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  overall_open: {
    option_1: [`전체공개`, `Share to public`, 't_evaluation.overall_open.option_1', ``, ``, ``, ``],
    option_2: [`링글팀에게만`, `Share to Ringle Team only`, 't_evaluation.overall_open.option_2', ``, ``, ``, ``],
  },
}
export const t_guide = {
  helpcenter: [`자주 묻는 질문`, `FAQs`, 't_guide.helpcenter', ``, ``, ``, ``],
  title: [`도움이 필요하신가요?`, `Need Help?`, 't_guide.title', ``, ``, ``, ``],
}
export const t_lesson_book = {
  booked_class: {
    tutor_subhead_1: [
      `최근에 선택한 교재로 수업한 튜터에요.`,
      `Tutors who have used the material you selected`,
      't_lesson_book.booked_class.tutor_subhead_1',
      ``,
      ``,
      ``,
      ``,
    ],
    tutor_subhead_2: [
      `이 교재로 수업을 잘할 수 있는 튜터에요.`,
      `Suggested tutor(s) for this material`,
      't_lesson_book.booked_class.tutor_subhead_2',
      ``,
      ``,
      ``,
      ``,
    ],
    tutor_subhead_3: [
      `이 교재 주제에 관심이 많은 튜터에요.`,
      `Tutor(s) interested in this topic`,
      't_lesson_book.booked_class.tutor_subhead_3',
      ``,
      ``,
      ``,
      ``,
    ],
    tutor_subhead_4: (child_name) => [
      <>{child_name} 학생이 찜한 튜터에요.</>,
      <>
        A tutor you added to favorites
        <br />
        is available for this time slot.
      </>,
      't_lesson_book.booked_class.tutor_subhead_4',
      ``,
      ``,
      ``,
      ``,
    ],
    tutor_subhead_5: [
      `바로 예약 가능한 튜터들이 더 있어요`,
      `Here are more available tutors.`,
      't_lesson_book.booked_class.tutor_subhead_5',
      ``,
      ``,
      ``,
      ``,
    ],
    title: [`예약 중인 수업`, `Selected Lesson`, 't_lesson_book.booked_class.title', ``, ``, ``, ``],
    remaining: [`추가 예약`, `Add Booking`, 't_lesson_book.booked_class.remaining', ``, ``, ``, ``],
    material: [`교재`, `Material`, 't_lesson_book.booked_class.material', ``, ``, ``, ``],
    material_placeholder: [
      `교재를 선택해 주세요.`,
      `Select the lesson material.`,
      't_lesson_book.booked_class.material_placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    time_tutor: [`시간 및 튜터`, `Time and tutor`, 't_lesson_book.booked_class.time_tutor', ``, ``, ``, ``],
    time_placeholder: [
      `시간을 선택해 주세요.`,
      `시간을 선택해 주세요.`,
      't_lesson_book.booked_class.time_placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    tutor_placeholder: [
      `튜터를 선택해 주세요.`,
      `튜터를 선택해 주세요.`,
      't_lesson_book.booked_class.tutor_placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    matching_title: [
      `튜터 매칭 옵션`,
      `Tutor matching options`,
      't_lesson_book.booked_class.matching_title',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_change: [`변경`, `Change`, 't_lesson_book.booked_class.btn_change', ``, ``, ``, ``],
    btn_delete: [`이 수업 삭제`, `Delete this class`, 't_lesson_book.booked_class.btn_delete', ``, ``, ``, ``],
    btn_confirm: [`예약 완료`, `Confirm booking`, 't_lesson_book.booked_class.btn_confirm', ``, ``, ``, ``],
  },
  confirm_popup: {
    title: [
      <>
        수업 정보와 튜터 매칭 옵션 확인 후<br />
        예약을 완료해 주세요.
      </>,
      <>
        Please review the lesson information
        <br />
        and tutor matching option.
      </>,
      't_lesson_book.confirm_popup.title',
      ``,
      ``,
      ``,
      ``,
    ],
    caption: [`선택한 수업`, `Selected Lesson`, 't_lesson_book.confirm_popup.caption', ``, ``, ``, ``],
  },
  cancel_popup: {
    title: [
      `수업 예약을 취소할까요?`,
      `Would you like to quit the booking?`,
      't_lesson_book.cancel_popup.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      <>
        현재까지 예약 중인 내역이 삭제돼요.
        <br />
         진행중인 수업 예약을 취소하시겠어요?
      </>,
      `Your booking info will be deleted if you quit here.`,
      't_lesson_book.cancel_popup.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_1: [`예약 이어하기`, `Continue`, 't_lesson_book.cancel_popup.btn_1', ``, ``, ``, ``],
    btn_2: [`예약 취소`, `Quit`, 't_lesson_book.cancel_popup.btn_2', ``, ``, ``, ``],
  },
  delete_popup: {
    title: [
      `이 수업을 삭제할까요?`,
      `Are you sure you want to delete this lesson?`,
      't_lesson_book.delete_popup.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `삭제한 수업은 되돌릴 수 없어요.`,
      `Deleted lessons cannot be undone.`,
      't_lesson_book.delete_popup.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  within_popup: {
    title: [
      `48시간 이내에 시작하는 수업이에요.`,
      `This lesson starts in less than 48 hours.`,
      't_lesson_book.within_popup.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `튜터와의 시차 및 응답 속도에 따라 매칭되지 않을 가능성이 높아요.`,
      `Tutor may not confirm in time.`,
      't_lesson_book.within_popup.desc',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  change_popup: {
    course_popup: [
      `이 코스로 변경하시겠어요?`,
      `Change to this course?`,
      't_lesson_book.change_popup.course_popup',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  course_popup: {
    course_popup: [
      `이전 코스의 기록은 남아있어요.`,
      `The work you did in your previous course will be saved.`,
      't_lesson_book.course_popup.course_popup',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  complete: {
    title: [`수업을 예약했어요`, `Lesson has been successfully booked`, 't_lesson_book.complete.title', ``, ``, ``, ``],
    caption: [
      `학습 효과 및 맞춤 수업을 위해 수업 전에 예습 및 수업 방식 설정을 추천합니다.`,
      `Set 'Lesson Styles' before the lesson for the best learning experience and customized lesson.`,
      't_lesson_book.complete.caption',
      ``,
      ``,
      ``,
      ``,
    ],
    lesson_style: [`수업 방식 확인`, `Check Lesson Style`, 't_lesson_book.complete.lesson_style', ``, ``, ``, ``],
    lesson_basic: [`수업 변경 및 취소`, `Class Changes`, 't_lesson_book.complete.lesson_basic', ``, ``, ``, ``],
  },
  lesson_basic: {
    info1: [
      `[1:1 수업] > [예정된 수업]에서 교재 변경 및 수업 취소가 가능합니다.`,
      `You can change materials or cancel lessons by going to [1:1 Lesson] > [Upcoming Lessons].`,
      't_lesson_book.lesson_basic.info1',
      ``,
      ``,
      ``,
      ``,
    ],
    info2: [
      `예정된 수업 카드 오른쪽 상단에 [변경/ 취소] 버튼을 선택하세요.`,
      `Please click on the [Change/Cancel] button on the upper right area or the upcoming lesson card.`,
      't_lesson_book.lesson_basic.info2',
      ``,
      ``,
      ``,
      ``,
    ],
    info3: [
      <>
        <b>수업 취소 정책</b>
        <li className="li-tag-inside">
          수업 시작 24시간 이전에 취소하면 예약했던 수업권은 복구되어 다시 사용할 수 있습니다.
        </li>
        <li className="li-tag-inside">
          수업 시작 기준 24시간 ~ 2시간 이전에 취소하면 예약했던 수업권은 차감되고 [예약 가능한 당일수업] 수업을 예약할
          수 있는 당일수업권(수업 취소)이 지급됩니다.
        </li>
        <li className="li-tag-inside">
          수업 시작 전 2시간 이내 취소 또는 수업에 참석하지 못한 경우 예약했던 수업권은 차감됩니다.
        </li>
        <li className="li-tag-inside">
          예약 후 튜터가 수업을 확정하면 시간 변경이 어렵습니다. 수업 시작 기준 24시간 이전에 취소 후 복구 받은
          수업권으로 다시 예약해 주세요.
        </li>
      </>,
      <>
        <b>Lesson cancellation policy</b>
        <li className="li-tag-inside">
          Credit will be restored for cancellations made 24 hours or more before the lesson is scheduled to start.
        </li>
        <li className="li-tag-inside">
          For cancellations made 2-24 hours before the scheduled lesson, the credit will not be restored but replaced
          with a Last-minute (lesson cancellation) credit.
        </li>
        <li className="li-tag-inside">
          You will lose the credit (no restoration) if you cancel within 2 hours from the scheduled lesson or miss the
          lesson.
        </li>
        <li className="li-tag-inside">
          Once the tutor confirms your booking, you cannot change the lesson schedule. To change the schedule, please
          cancel the lesson 24 hours or more before it is scheduled to start and book once more using the restored
          credit.
        </li>
      </>,
      't_lesson_book.lesson_basic.info3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  material: {
    btn_course: [`전체 코스 보기`, `View all courses`, 't_lesson_book.material.btn_course', ``, ``, ``, ``],
    btn_material: [`전체 교재 보기`, `View all materials`, 't_lesson_book.material.btn_material', ``, ``, ``, ``],
    caption: [
      `교재 선택이 고민된다면?`,
      `Can't decide on a material?`,
      't_lesson_book.material.caption',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_course_2: [
      `추천 코스로 수업하기`,
      `Try out curated course`,
      't_lesson_book.material.btn_course_2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  toast: {
    toast: [
      `교재를 먼저 선택해 주세요.`,
      `Please choose a material first.`,
      't_lesson_book.toast.toast',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  update_tooltip: {
    title: [`예약 안내`, `Booking tips`, 't_lesson_book.update_tooltip.title', ``, ``, ``, ``],
    desc_1: [
      `진행 중인 코스가 있는 경우, 다음 교재가 자동으로 선택돼요.`,
      `If you have a course in progress, the next material will be selected automatically.`,
      't_lesson_book.update_tooltip.desc_1',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_2: [
      `진행 중인 코스가 없는 경우, 해당 영역을 클릭해 직접 교재를 선택해 주세요.`,
      `If you don't have a course in progress, please click on the area to choose a material yourself.`,
      't_lesson_book.update_tooltip.desc_2',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_3: [
      `시간과 튜터를 선택해 예약을 완료해 주세요.`,
      `Please choose the time and tutor to complete the booking.`,
      't_lesson_book.update_tooltip.desc_3',
      ``,
      ``,
      ``,
      ``,
    ],
    desc_4: [
      `추가 예약으로 여러개의 수업을 한 번에 예약할 수 있어요.`,
      `You can book multiple lessons at once by clicking on the [Book more] button.`,
      't_lesson_book.update_tooltip.desc_4',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  again_popup: {
    body: [
      <>
        이미 완주한 코스입니다.
        <br />
        첫번째 교재부터 다시 시작하시겠어요?
      </>,
      <>
        You have already completed this course.
        <br />
        Would you like to redo it from the first material?
      </>,
      't_lesson_book.again_popup.body',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_1: [`아니오`, `No`, 't_lesson_book.again_popup.btn_1', ``, ``, ``, ``],
    btn_2: [`다시 수업하기`, `Redo course`, 't_lesson_book.again_popup.btn_2', ``, ``, ``, ``],
  },
}
export const t_course = {
  detail: {
    progress_caption_1: [`시작 전`, `Not started`, 't_course.detail.progress_caption_1', ``, ``, ``, ``],
    progress_caption_2: [`수업 완료 교재`, `Materials learned`, 't_course.detail.progress_caption_2', ``, ``, ``, ``],
    progress_caption_3: [`코스 완주`, `Course completed`, 't_course.detail.progress_caption_3', ``, ``, ``, ``],
    btn_1: [`이 코스로 수업하기`, `Take this course`, 't_course.detail.btn_1', ``, ``, ``, ``],
    btn_2: [`코스 진행중`, `Course in progress`, 't_course.detail.btn_2', ``, ``, ``, ``],
    container1_title: [`추천 수업 시간`, `Suggested lesson length`, 't_course.detail.container1_title', ``, ``, ``, ``],
    container2_title: [`해당 학년`, `Grade level`, 't_course.detail.container2_title', ``, ``, ``, ``],
    container3_title: [`해당 레벨`, `English level`, 't_course.detail.container3_title', ``, ``, ``, ``],
    week_1: [`1주차`, `Week 1`, 't_course.detail.week_1', ``, ``, ``, ``],
    week_2: [`2주차`, `Week 2`, 't_course.detail.week_2', ``, ``, ``, ``],
    week_3: [`3주차`, `Week 3`, 't_course.detail.week_3', ``, ``, ``, ``],
    week_4: [`4주차`, `Week 4`, 't_course.detail.week_4', ``, ``, ``, ``],
    caption: [
      `코스를 완주하면 영어 실력에 따라 다음 코스가 추천돼요!`,
      `We'll suggest another course based on your English level after you complete the course!`,
      't_course.detail.caption',
      ``,
      ``,
      ``,
      ``,
    ],
    week: (week) => [`${week}주차`, `Week ${week}`, 't_course.detail.week', ``, ``, ``, ``],
  },
  empty: {
    placeholder: [
      <>
        현재 진행 중인 코스가 없어요.
        <br />
        나이와 영어 실력에 맞는 코스를 찾아보세요.
      </>,
      <>
        You have no courses in progress.
        <br />
        Find courses tailored to your age and level.
      </>,
      't_course.empty.placeholder',
      ``,
      ``,
      ``,
      ``,
    ],
    placeholder_short: [
      `현재 진행 중인 코스가 없어요.`,
      `You have no courses in progress.`,
      't_course.empty.placeholder_short',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`코스 확인하기`, `View courses`, 't_course.empty.btn', ``, ``, ``, ``],
    placeholder_2: [
      `진행한 코스를 여기서 확인할 수 있어요.`,
      `You can view courses you have completed here.`,
      't_course.empty.placeholder_2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  onbording: {
    title: [
      <>
        나이와 영어 실력에 맞는
        <br />
        추천 코스로 수업해 보세요!
      </>,
      <>
        Try out curated courses
        <br />
        tailored to your age and level!
      </>,
      't_course.onbording.title',
      ``,
      ``,
      ``,
      ``,
    ],
    desc: [
      `코스에 따라 자동으로 교재가 선택되어 예약이 쉬워져요.`,
      `Taking courses simplifies booking lessons since materials are pre-selected.`,
      't_course.onbording.desc',
      ``,
      ``,
      ``,
      ``,
    ],
    btn_1: [`이 코스로 수업할게요`, `I will take this course.`, 't_course.onbording.btn_1', ``, ``, ``, ``],
    btn_2: [`교재를 직접 선택할게요`, `I will choose materials myself.`, 't_course.onbording.btn_2', ``, ``, ``, ``],
    btn: [`코스 추천받기`, `Show me`, 't_course.onbording.btn', ``, ``, ``, ``],
    btn_mobile: [`앱 다운로드 하기`, `Download App`, 't_course.onbording.btn_mobile', ``, ``, ``, ``],
  },
  onbording_input: {
    caption: [
      `코스 추천을 위해 두 가지만 알려주세요.`,
      `Please tell us two things first.`,
      't_course.onbording_input.caption',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  onbording_searching: {
    caption: [`잠시만 기다려주세요!`, `Please wait a moment!`, 't_course.onbording_searching.caption', ``, ``, ``, ``],
    title: (child_name) => [
      <>
        {child_name} 학생에게 꼭 맞는
        <br />
        추천 코스를 찾고 있어요.
      </>,
      <>
        We're creating the perfect course
        <br />
        for {child_name}. 
      </>,
      't_course.onbording_searching.title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  onbording_result: {
    title: [`한 달 추천 코스`, `Suggested course`, 't_course.onbording_result.title', ``, ``, ``, ``],
    caption: [
      `카드를 눌러 상세 내용을 확인할 수 있어요.`,
      `Click on the card to view details.`,
      't_course.onbording_result.caption',
      ``,
      ``,
      ``,
      ``,
    ],
    info_title: [`링글 틴즈 코스 안내`, `Helpful tips`, 't_course.onbording_result.info_title', ``, ``, ``, ``],
    info_desc: [
      <>
        <li className="li-tag-inside">예약 시 코스에 따라 교재가 자동으로 선택돼요.</li>
        <li className="li-tag-inside">코스는 언제든 변경할 수 있어요.</li> 
      </>,
      <>
        <li className="li-tag-inside">
          The materials will be pre-selected for the course when you book the lesson(s).
        </li>
        <li className="li-tag-inside">You can change the course at any time.</li> 
      </>,
      't_course.onbording_result.info_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    title_mobile: [
      <>
        링글 틴즈 앱에서
        <br />
        추천 코스로 수업을 예약해 보세요.
      </>,
      <>
        Try out curated courses
        <br />
        on Ringle Teens app.
      </>,
      't_course.onbording_result.title_mobile',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  next_material: [`다음 교재`, `Next`, 't_course.next_material', ``, ``, ``, ``],
  complete: {
    title: [
      `코스 완주를 축하해요! 다음 추천 코스를 확인해보세요.`,
      `Congratulations on completing the course! Here's the course we recommend next.`,
      't_course.complete.title',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  course: {
    btn: [`확인하기`, `View details`, 't_course.course.btn', ``, ``, ``, ``],
  },
  t_course: {
    popup_title: [
      `코스 추천을 그만할까요?`,
      `Quit course suggestion?`,
      't_course.t_course.popup_title',
      ``,
      ``,
      ``,
      ``,
    ],
    popup_desc: [
      `코스 추천은 언제든 다시 가능해요.`,
      `You can always come back later.`,
      't_course.t_course.popup_desc',
      ``,
      ``,
      ``,
      ``,
    ],
    popup_btn_1: [`이어서 하기`, `Continue`, 't_course.t_course.popup_btn_1', ``, ``, ``, ``],
    popup_btn_2: [`그만하기`, `Quit`, 't_course.t_course.popup_btn_2', ``, ``, ``, ``],
    title: [
      `코스 완주를 축하해요! 다음 추천 코스를 확인해보세요.`,
      `Congratulations on completing the course! Here's the course we recommend next.`,
      't_course.t_course.title',
      ``,
      ``,
      ``,
      ``,
    ],
    btn: [`확인하기`, `View details`, 't_course.t_course.btn', ``, ``, ``, ``],
  },
  next_course: {
    title: [`다음 한 달 추천 코스`, `Suggested course for next month`, 't_course.next_course.title', ``, ``, ``, ``],
  },
}
export const t_lessonprep = {
  lesson_style_guideline: {
    grade1: {
      basic_1: [
        `함께 텍스트를 읽고 수업을 시작해요.`,
        `Read the text together`,
        't_lessonprep.lesson_style_guideline.grade1.basic_1',
        ``,
        ``,
        ``,
        ``,
      ],
      basic_2: [
        `학생에게 더 많은 말하기 기회를 제공해요.`,
        `Give students more opportunities to speak`,
        't_lessonprep.lesson_style_guideline.grade1.basic_2',
        ``,
        ``,
        ``,
        ``,
      ],
      basic_3: [
        `간단한 동의어 또는 예문으로 새로운 단어를 가르쳐줘요.`,
        `Introduce new words with simple synonyms or examples`,
        't_lessonprep.lesson_style_guideline.grade1.basic_3',
        ``,
        ``,
        ``,
        ``,
      ],
      basic_4: [
        `많은 칭찬과 동기부여를 해줘요.`,
        `Offer praise and positive affirmation throughout the lesson`,
        't_lessonprep.lesson_style_guideline.grade1.basic_4',
        ``,
        ``,
        ``,
        ``,
      ],
      intermediate_1: [
        `완전한 문장으로 답하도록 가르쳐줘요.`,
        `Encourage full sentence responses`,
        't_lessonprep.lesson_style_guideline.grade1.intermediate_1',
        ``,
        ``,
        ``,
        ``,
      ],
      intermediate_2: [
        `후속 질문을 통해 더 많은 말하기 기회를 제공해요.`,
        `Ask follow-up questions for more speaking opportunities`,
        't_lessonprep.lesson_style_guideline.grade1.intermediate_2',
        ``,
        ``,
        ``,
        ``,
      ],
      intermediate_3: [
        `많은 칭찬과 동기부여를 줘요.`,
        `Offer praise and positive affirmation throughout the lesson`,
        't_lessonprep.lesson_style_guideline.grade1.intermediate_3',
        ``,
        ``,
        ``,
        ``,
      ],
      advanced_1: [
        `고급 표현과 문장 구조로 교정해줘요.`,
        `Correct using advanced language and sentence structures`,
        't_lessonprep.lesson_style_guideline.grade1.advanced_1',
        ``,
        ``,
        ``,
        ``,
      ],
      advanced_2: [
        `후속 질문을 통해 더 많은 말하기 기회를 제공해요.`,
        `Ask follow-up questions for more speaking opportunities`,
        't_lessonprep.lesson_style_guideline.grade1.advanced_2',
        ``,
        ``,
        ``,
        ``,
      ],
      advanced_3: [
        `많은 칭찬과 동기부여를 줘요.`,
        `Offer praise and positive affirmation throughout the lesson`,
        't_lessonprep.lesson_style_guideline.grade1.advanced_3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    grade4: {
      basic_1: [
        `함께 텍스트를 읽고 수업을 시작해요.`,
        `Give students more opportunities to speak`,
        't_lessonprep.lesson_style_guideline.grade4.basic_1',
        ``,
        ``,
        ``,
        ``,
      ],
      basic_2: [
        `학생에게 더 많은 말하기 기회를 제공해요.`,
        `Read the text together`,
        't_lessonprep.lesson_style_guideline.grade4.basic_2',
        ``,
        ``,
        ``,
        ``,
      ],
      basic_3: [
        `간단한 동의어 또는 예문으로 새로운 단어를 가르쳐줘요.`,
        `Introduce new words with simple synonyms or examples`,
        't_lessonprep.lesson_style_guideline.grade4.basic_3',
        ``,
        ``,
        ``,
        ``,
      ],
      basic_4: [
        `많은 칭찬과 동기부여를 해줘요.`,
        `Offer praise and positive affirmation throughout the lesson`,
        't_lessonprep.lesson_style_guideline.grade4.basic_4',
        ``,
        ``,
        ``,
        ``,
      ],
      intermediate_1: [
        `학생에게 더 많은 말하기 기회를 줘요.`,
        `Give students more opportunities to speak`,
        't_lessonprep.lesson_style_guideline.grade4.intermediate_1',
        ``,
        ``,
        ``,
        ``,
      ],
      intermediate_2: [
        `학생의 반응에 따라 흥미로운 후속 질문을 해줘요.`,
        `Ask interesting follow-up questions based on the student’s responses`,
        't_lessonprep.lesson_style_guideline.grade4.intermediate_2',
        ``,
        ``,
        ``,
        ``,
      ],
      advanced_1: [
        `고급 표현과 문장 구조로 교정해줘요.`,
        `Introduce advanced language and sentence structures`,
        't_lessonprep.lesson_style_guideline.grade4.advanced_1',
        ``,
        ``,
        ``,
        ``,
      ],
      advanced_2: [
        `생각을 자극하는 질문을 해줘요.`,
        `Ask thought-provoking questions`,
        't_lessonprep.lesson_style_guideline.grade4.advanced_2',
        ``,
        ``,
        ``,
        ``,
      ],
      advanced_3: [
        `숙제 제출을 독려해요.`,
        `Encourage homework submission`,
        't_lessonprep.lesson_style_guideline.grade4.advanced_3',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    grade7: {
      basic_1: [
        `완전한 문장으로 답하도록 가르쳐줘요.`,
        `Encourage full sentence responses`,
        't_lessonprep.lesson_style_guideline.grade7.basic_1',
        ``,
        ``,
        ``,
        ``,
      ],
      basic_2: [
        `학생에게 더 많은 말하기 기회를 제공해요.`,
        `Ask follow-up questions for more speaking opportunities`,
        't_lessonprep.lesson_style_guideline.grade7.basic_2',
        ``,
        ``,
        ``,
        ``,
      ],
      basic_3: [
        `많은 칭찬과 동기부여를 해줘요.`,
        `Offer praise and positive affirmation throughout the lesson`,
        't_lessonprep.lesson_style_guideline.grade7.basic_3',
        ``,
        ``,
        ``,
        ``,
      ],
      intermediate_1: [
        `학생에게 더 많은 말하기 기회를 주세요`,
        `Give students more opportunities to speak`,
        't_lessonprep.lesson_style_guideline.grade7.intermediate_1',
        ``,
        ``,
        ``,
        ``,
      ],
      intermediate_2: [
        `생각을 자극하는 질문을 해줘요.`,
        `Ask thought-provoking questions`,
        't_lessonprep.lesson_style_guideline.grade7.intermediate_2',
        ``,
        ``,
        ``,
        ``,
      ],
      intermediate_3: [
        `문법 오류를 알려주고 수정해줘요.`,
        `Provide feedback on grammatical errors and correct them`,
        't_lessonprep.lesson_style_guideline.grade7.intermediate_3',
        ``,
        ``,
        ``,
        ``,
      ],
      advanced_1: [
        `학생의 수업 목표에 맞게 수업을 진행해요.`,
        `Tailor a lesson to align with the student's goals`,
        't_lessonprep.lesson_style_guideline.grade7.advanced_1',
        ``,
        ``,
        ``,
        ``,
      ],
      advanced_2: [
        `생각을 자극하는 질문을 해줘요.`,
        `Ask thought-provoking questions`,
        't_lessonprep.lesson_style_guideline.grade7.advanced_2',
        ``,
        ``,
        ``,
        ``,
      ],
      advanced_3: [
        `고급 표현과 문장 구조로 교정해요.`,
        `Introduce advanced language and sentence structures`,
        't_lessonprep.lesson_style_guideline.grade7.advanced_3',
        ``,
        ``,
        ``,
        ``,
      ],
      advanced_4: [
        `숙제 제출을 독려해요.`,
        `Encourage homework submission`,
        't_lessonprep.lesson_style_guideline.grade7.advanced_4',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    material: {
      debate_1: [
        `학생의 주장에 반론을 해줘요.`,
        `Challenge the student’s arguments with counter-arguments`,
        't_lessonprep.lesson_style_guideline.material.debate_1',
        ``,
        ``,
        ``,
        ``,
      ],
      debate_2: [
        `학생이 논리적으로 뒷받침하도록 가르쳐 줘요.`,
        `Guide students in providing logical supporting details`,
        't_lessonprep.lesson_style_guideline.material.debate_2',
        ``,
        ``,
        ``,
        ``,
      ],
      debate_3: [
        `토론 표현과 고급 어휘를 가르쳐 줘요.`,
        `Introduce common debate expressions and advanced vocabulary`,
        't_lessonprep.lesson_style_guideline.material.debate_3',
        ``,
        ``,
        ``,
        ``,
      ],
      essay_1: [
        `에세이 구조(서론, 본론, 결론)를 갖추고 문법에 맞도록 교정해줘요.`,
        `Correct the essay’s structure and any grammar errors`,
        't_lessonprep.lesson_style_guideline.material.essay_1',
        ``,
        ``,
        ``,
        ``,
      ],
      essay_2: [
        `고급 표현과 문장 구조로 교정해줘요.`,
        `Correct using advanced language and sentence structures`,
        't_lessonprep.lesson_style_guideline.material.essay_2',
        ``,
        ``,
        ``,
        ``,
      ],
      essay_3: [
        `학생과 함께 내용을 검토하고 수정해요.`,
        `Review and correct the content with the student`,
        't_lessonprep.lesson_style_guideline.material.essay_3',
        ``,
        ``,
        ``,
        ``,
      ],
      essay_4: [
        `숙제 제출을 독려해요.`,
        `Encourage homework submission`,
        't_lessonprep.lesson_style_guideline.material.essay_4',
        ``,
        ``,
        ``,
        ``,
      ],
      customize_1: [
        `수업 시작 시간에 아이스브레이킹 시간을 최소화해요.`,
        `Minimize the ice-breaking time at the beginning`,
        't_lessonprep.lesson_style_guideline.material.customize_1',
        ``,
        ``,
        ``,
        ``,
      ],
      customize_2: [
        `학생에게 업로드된 파일의 목적을 확인해요.`,
        `Clarify the purpose of the uploaded file with the student`,
        't_lessonprep.lesson_style_guideline.material.customize_2',
        ``,
        ``,
        ``,
        ``,
      ],
      customize_3: [
        `학생과 함께 내용을 검토하고 수정해요.`,
        `Review and correct the content with the student`,
        't_lessonprep.lesson_style_guideline.material.customize_3',
        ``,
        ``,
        ``,
        ``,
      ],
      customize_4: [
        `수업 방향과 첨삭 스타일이 학생의 요구에 맞는지 확인해요.`,
        `Check in with the student about their preferences for lessons and corrections`,
        't_lessonprep.lesson_style_guideline.material.customize_4',
        ``,
        ``,
        ``,
        ``,
      ],
      intensive_1: [
        `20분 토론, 20분 에세이 첨삭으로 수업을 진행해요.`,
        `Split the 40-minute lesson: 20 minutes for debate, 20 minutes for essay correction`,
        't_lessonprep.lesson_style_guideline.material.intensive_1',
        ``,
        ``,
        ``,
        ``,
      ],
      intensive_2: [
        `초안을 준비하지 않은 경우 30분 토론, 10분 에세이 개요 작성을 해줘요.`,
        `If no draft, use 30 minutes for debate and 10 minutes for essay outlining`,
        't_lessonprep.lesson_style_guideline.material.intensive_2',
        ``,
        ``,
        ``,
        ``,
      ],
      intensive_3: [
        `고급 언어 소개하고 논리적 논증을 지도해줘요.`,
        `Introduce advanced language and guide logical arguments`,
        't_lessonprep.lesson_style_guideline.material.intensive_3',
        ``,
        ``,
        ``,
        ``,
      ],
      intensive_4: [
        `에세이 구조(서론, 본론, 결론)를 갖추고 문법에 맞도록 교정해줘요.`,
        `Correct the essay’s structure and any grammar errors`,
        't_lessonprep.lesson_style_guideline.material.intensive_4',
        ``,
        ``,
        ``,
        ``,
      ],
      intensive_5: [
        `숙제 제출을 독려해줘요.`,
        `Encourage 200-word essay homework based on feedback`,
        't_lessonprep.lesson_style_guideline.material.intensive_5',
        ``,
        ``,
        ``,
        ``,
      ],
      beginner_1: [
        `5분간 번갈아 지문을 읽고 발음을 교정하며 시작해줘요.`,
        `Start with a 5-minute reading of the passage: take turns and correct pronunciation`,
        't_lessonprep.lesson_style_guideline.material.beginner_1',
        ``,
        ``,
        ``,
        ``,
      ],
      beginner_2: [
        `완전한 문장으로 답하도록 가르쳐줘요.`,
        `Encourage full sentence responses`,
        't_lessonprep.lesson_style_guideline.material.beginner_2',
        ``,
        ``,
        ``,
        ``,
      ],
      beginner_3: [
        `새로운 단어에 예문을 제공하고, 학생들이 단어를 수업 중 반복하게 해요.`,
        `Provide example sentences for new words and have the student repeat them`,
        't_lessonprep.lesson_style_guideline.material.beginner_3',
        ``,
        ``,
        ``,
        ``,
      ],
      beginner_4: [
        `3분간 요약, 중요한 어휘를 강조하며 전체 피드백으로 마무리해요.`,
        `End with a 3-minute overall feedback: summarize key points and highlight important expressions`,
        't_lessonprep.lesson_style_guideline.material.beginner_4',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    correction_mode: {
      title: [
        `Correction Mode`,
        `Correction Mode`,
        't_lessonprep.lesson_style_guideline.correction_mode.title',
        ``,
        ``,
        ``,
        ``,
      ],
      info: [
        <>
          [첨삭 80% & 토론 20%] 심도 있는 토론보다 학생의 언어와 구조를 교정하는 데 중점을 두어 6~8개의 교정된
          단어/표현을 가르칩니다.{' '}
        </>,
        <>
          [80% Correction & 20% Discussion] Focus on improving the student’s language and structures with 6-8
          paraphrased points instead of in-depth discussion
        </>,
        't_lessonprep.lesson_style_guideline.correction_mode.info',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    balanced_mode: {
      title: [
        `Balanced Mode`,
        `Balanced Mode`,
        't_lessonprep.lesson_style_guideline.balanced_mode.title',
        ``,
        ``,
        ``,
        ``,
      ],
      info: [
        <>
          [첨삭 50% & 토론 50%] 첨삭과 토론 모두 고른 수준의 참여를 유도하는 수업이며, 최소 4~6개 교정된 단어/표현을
          가르칩니다.{' '}
        </>,
        <>
          [50% Correction & 50% Discussion] Focus on a balanced discussion and corrections with 4-6 paraphrased points
        </>,
        't_lessonprep.lesson_style_guideline.balanced_mode.info',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    discussion_mode: {
      title: [
        `Discussion Mode`,
        `Discussion Mode`,
        't_lessonprep.lesson_style_guideline.discussion_mode.title',
        ``,
        ``,
        ``,
        ``,
      ],
      info: [
        <>
          [첨삭 20% & 토론 80%] 심도 있는 분석을 위해 앞뒤로 대화하는 데 중점을 두되, 최소 2~4개 교정된 단어/표현을
          가르칩니다.{' '}
        </>,
        <>
          [20% Correction & 80% Discussion] Focus on prioritizing back-and-forth conversation for in-depth discussion
          with 2-4 paraphrased points
        </>,
        't_lessonprep.lesson_style_guideline.discussion_mode.info',
        ``,
        ``,
        ``,
        ``,
      ],
    },
    intro: [
      `자기소개 없이 수업을 바로 시작합니다.`,
      `Start the lesson without introductions`,
      't_lessonprep.lesson_style_guideline.intro',
      ``,
      ``,
      ``,
      ``,
    ],
    no_intro: [
      `3분 내로 자기소개 후 수업을 시작합니다.`,
      `Spend the first 3 minutes exchanging introductions`,
      't_lessonprep.lesson_style_guideline.no_intro',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  modal: {
    title: [`수업 방식 설정`, `Set Lesson Style`, 't_lessonprep.modal.title', ``, ``, ``, ``],
    info: [
      <>
        - 학생과 튜터 보호를 위해 모든 수업 영상은 녹화되고 영업일 기준 24시간 동안 저장합니다. 24시간이 지나면 자동으로
        삭제됩니다. <br />- 단, 수업 분석 확인과 수업 다시 듣기를 위해 수업 녹음 파일은 삭제되지 않습니다. (파일 제작은
        최대 24시간까지 소요될 수 있습니다.)
      </>,
      <>
        To protect both students and tutors, all class sessions will be recorded and stored for 24 hours on business
        days. After 24 hours, the recordings will be automatically deleted. <br />- However, class recording files will
        not be deleted for the purposes of class analysis and review. (File creation may take up to 24 hours.)
      </>,
      't_lessonprep.modal.info',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  mode: {
    title: [`수업 모드`, `Lesson Mode`, 't_lessonprep.mode.title', ``, ``, ``, ``],
    correction_mode: [`대화 모드`, `Discussion Mode`, 't_lessonprep.mode.correction_mode', ``, ``, ``, ``],
    correction_mode_info: [
      `영어에 대한 자신감을 높여주고 싶어요. 적극적 경청 및 질문을 통해, 아이가 말할 수 있는 기회를 많이 주세요.`,
      `I want to boost my student's confidence in English. Please give them plenty of opportunities to speak through active listening and asking questions.`,
      't_lessonprep.mode.correction_mode_info',
      ``,
      ``,
      ``,
      ``,
    ],
    balanced_mode: [`균형 모드`, `Balanced Mode`, 't_lessonprep.mode.balanced_mode', ``, ``, ``, ``],
    balanced_mode_info: [
      `아이가 말할 수 있는 기회를 주되, 틀린/어색한 영어 표현도 함께 교정해 주세요.`,
      `Please give opportunities to speak, but also correct any incorrect or awkward English expressions`,
      't_lessonprep.mode.balanced_mode_info',
      ``,
      ``,
      ``,
      ``,
    ],
    discussion_mode: [`교정 모드`, `Correction Mode`, 't_lessonprep.mode.discussion_mode', ``, ``, ``, ``],
    discussion_mode_info: [
      `언어 표현력과 어휘력을 키워주는 것이 목표예요. 아이의 틀린/어색한 영어 표현을 위주로 적극 교정해 주세요.`,
      `The goal is to develop the child's language expression and vocabulary. Please actively correct the child's incorrect or awkward English expressions.`,
      't_lessonprep.mode.discussion_mode_info',
      ``,
      ``,
      ``,
      ``,
    ],
  },
  request: {
    title: [`추가 요청 사항`, `Specific Request`, 't_lessonprep.request.title', ``, ``, ``, ``],
    title_2: [`학생 요청 사항`, `Specific Request from Student`, 't_lessonprep.request.title_2', ``, ``, ``, ``],
    info: [
      <>
        수업에서 만날 튜터에게 요청사항이 있다면 한글로 남겨주세요. <br />
        ex) 단답형으로 말하는 아이가 완전한 문장으로 이야기할 수 있게 지도해 주세요.
      </>,
      <>
        If you have any requests for the tutor you will meet in class, please leave them.
        <br />
        ex)Please guide the child, who speaks in short answers, to express themselves in complete sentences.
      </>,
      't_lessonprep.request.info',
      ``,
      ``,
      ``,
      ``,
    ],
    info_2: [
      `수업 중 자녀에게 알려지지 않습니다.`,
      `It will not be disclosed to the student during the class.`,
      't_lessonprep.request.info_2',
      ``,
      ``,
      ``,
      ``,
    ],
    info_3: [
      `튜터에게 원하는 수업을 더 자세히 알려줄 수 있어요.`,
      `You can provide more details about the class you want to the tutor.`,
      't_lessonprep.request.info_3',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
export const t_checklessonstyle = {
  title: {
    title: [`수업 방식 확인`, `Check Lesson Style`, 't_checklessonstyle.title.title', ``, ``, ``, ``],
  },
  Mode: {
    title: [`수업 모드`, `Lesson Mode`, 't_checklessonstyle.Mode.title', ``, ``, ``, ``],
    label: [`추천`, `recommended`, 't_checklessonstyle.Mode.label', ``, ``, ``, ``],
    graph_1: [`대화 유도`, `Discussion`, 't_checklessonstyle.Mode.graph_1', ``, ``, ``, ``],
    graph_2: [`교정`, `Correction`, 't_checklessonstyle.Mode.graph_2', ``, ``, ``, ``],
  },
  introduce: {
    title: [`자기 소개`, `Tutor & Student Introductions`, 't_checklessonstyle.introduce.title', ``, ``, ``, ``],
    info_1: [
      `서로 소개 3분 후 시작`,
      `Spend first 3 minutes exchanging introductions`,
      't_checklessonstyle.introduce.info_1',
      ``,
      ``,
      ``,
      ``,
    ],
    info_2: [
      `소개없이 바로 시작`,
      `Start lesson without introductions`,
      't_checklessonstyle.introduce.info_2',
      ``,
      ``,
      ``,
      ``,
    ],
  },
}
